import { ConfigModule } from "@/store/modules/config";
import axios, { AxiosRequestConfig } from "axios";
import { ITokenProvider } from "./../token-provider.interface";
import AccessTokenMissing from "./AccessTokenMissing";

export class Api {
  instance() {
    return axios.create({
      baseURL: ConfigModule.fahrzeugscheinBaseUrl,
      timeout: 20000, // indicates, 1000ms ie. 1 second
      headers: {
        "Content-type": "application/json"
      }
    });
  }

  // TODO: Refactor this into a token provider. Change the function to addToken().
  public static getToken(): string {
    if (ConfigModule.fahrzeugscheinToken) {
      return "?access_token=" + ConfigModule.fahrzeugscheinToken;
    }
    throw new AccessTokenMissing();
  }
}

// FIXME: Fixme this is not working as it is part of the query not the header :/
export class AccessTokenTokenProvider implements ITokenProvider {
  addTokenToRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    return Promise.resolve(config);
  }
}
