/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import {
  ThgGeojsonPointViewmodelGen,
  ThgPartnerControllerFindAllParamsGen,
  ThgPartnerViewModelGen
} from "./../thg/v1/data-contracts";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { ConfigModule } from "@/store/modules/config";
import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";
import { Partner as ThgPartner } from "../thg/v1/Partner";
import { Partner as MrfiktivPartner } from "../mrfiktiv/v1/Partner";
import { MrfiktivMultiplePartnersFindManyIdsGen, MrfiktivPartnerDocumentGen } from "../mrfiktiv/v1/data-contracts";

/**
 * shared service to communicate with `/partner`
 */
class PartnerService {
  /**
   * The proxy for mrfiktiv backend.
   */
  mrfiktivProxy: MrfiktivPartner;

  /**
   * The proxy for thg backend.
   */
  thgProxy: ThgPartner;

  /**
   * @class Initialize shared PartnerService
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivPartner(mrfiktivHttpClientProvider.client());
    this.thgProxy = new ThgPartner(thgHttpClientProvider.client());
  }

  async getAll(query: ThgPartnerControllerFindAllParamsGen): Promise<ThgPartnerViewModelGen[] | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT || ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      throw new Error("Not yet implemented");
    }

    if (ConfigModule.appContext === AppContextEnum.THG || ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.partnerControllerFindAll(query)).data;
    }
  }

  async getAnalyticsPartnersByIds(data: MrfiktivMultiplePartnersFindManyIdsGen): Promise<MrfiktivPartnerDocumentGen[]> {
    return (await this.mrfiktivProxy.partnerControllerFindAllPartnersForAnalyticsPermission(data)).data;
  }

  /**
   * Returns a geo object for a given location
   * @param searchstring Searchstring for the location. For the best results provide the full address with street name, city and zip
   * @returns
   */
  async geocodehelper(searchstring: string): Promise<ThgGeojsonPointViewmodelGen> {
    if (ConfigModule.appContext === AppContextEnum.THG || ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (
        await this.thgProxy.partnerControllerGetGeocode({
          searchstring: searchstring
        })
      ).data;
    }

    return (
      await this.mrfiktivProxy.partnerControllerGetGeocode({
        searchstring: searchstring
      })
    ).data;
  }
}

export default new PartnerService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
