






































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import TableWrapper from "@/components/utility/TableWrapper.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import { Component, Prop } from "vue-property-decorator";
import DocumentForm from "./DocumentForm.vue";
import DocumentFormDialog from "./DocumentFormDialog.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { DocumentModule } from "@/store/modules/document.store";

enum STEPS {
  CHOOSE_NEXT_STEP,
  SELECT_DOCUMENT,
  UPLOAD
}
@Component({
  components: { ConfirmActionDialog, DocumentForm, TableWrapper, PaginatedTable }
})
export default class DocumentSelectionDialog extends DocumentFormDialog {
  @Prop({
    default: () => []
  })
  documentSuggestions?: ISignDocument[];

  @Prop({ default: true })
  createActivityOnSelect!: boolean;

  readonly store = DocumentModule;

  readonly STEPS = STEPS;

  headers = [
    { text: this.$t("sign.DocumentTable.internalTitle"), align: "start", value: "title" },
    { text: this.$t("sign.DocumentTable.name"), align: "start", value: "name" },
    { text: this.$t("sign.DocumentTable.created"), align: "start", value: "timestamp.created", type: "date" }
  ];

  step = STEPS.CHOOSE_NEXT_STEP;

  isLoading = false;

  selectedItems: ISignDocument[] = [];

  get choices() {
    return [
      {
        icon: "mdi-upload",
        title: this.$t("sign.ReportDocumentSignRequestStepperChoice.upload"),
        subtitle: this.$t("sign.ReportDocumentSignRequestStepperChoice.uploadSubtitle"),
        buttonText: this.$t("sign.ReportDocumentSignRequestStepperChoice.upload"),
        onClick: () => {
          this.step = STEPS.UPLOAD;
        }
      },
      {
        icon: "mdi-file-document-multiple",
        title: this.$t("sign.ReportDocumentSignRequestStepperChoice.choose"),
        subtitle: this.$t("sign.ReportDocumentSignRequestStepperChoice.chooseSubtitle"),
        buttonText: this.$t("sign.ReportDocumentSignRequestStepperChoice.chooseButton"),
        onClick: () => {
          this.step = STEPS.SELECT_DOCUMENT;
        }
      }
    ];
  }

  previous() {
    switch (this.step) {
      case STEPS.CHOOSE_NEXT_STEP:
        this.dialogActive = false;
        break;
      case STEPS.SELECT_DOCUMENT:
        this.step = STEPS.CHOOSE_NEXT_STEP;
        break;
      case STEPS.UPLOAD:
        this.step = STEPS.CHOOSE_NEXT_STEP;
        break;
    }
  }

  get isRightDisabled() {
    if (STEPS.SELECT_DOCUMENT === this.step) {
      return !this.selectedItems.length;
    }
    if (STEPS.UPLOAD === this.step) {
      // rightDisabled comes from mixin used in conjunction wit the DocumentForm (set on updateValid)
      return this.rightDisabled;
    }

    return false;
  }

  next() {
    switch (this.step) {
      case STEPS.CHOOSE_NEXT_STEP:
        break;
      case STEPS.SELECT_DOCUMENT:
        this.$emit("created", this.selectedItems[0]);
        if (this.createActivityOnSelect) {
          this.saveActivity(this.selectedItems[0]);
        }
        this.dialogActive = false;
        break;
      case STEPS.UPLOAD:
        this.save();
        break;
    }
  }
}
