





























import Card from "@/components/utility/Card.vue";
import FileInput from "@/components/utility/FileInput.vue";
import { handleError } from "@/lib/utility/handleError";
import { default as DaService } from "@/services/mrfiktiv/services/daService";
import {
  MrfiktivDaCustomerViewModelGen,
  MrfiktivDAServiceEventDetailViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";
import { IReport } from "@/models/report.entity";
import { ReportType } from "@/store/enum/reportType";
import Debug from "@/components/utility/Debug.vue";

@Component({
  components: {
    Card,
    FileInput,
    Debug
  }
})
export default class ReportDocumentSignRequestStepperDa extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  page = SignRequestStepperPageEnum.DA_IMPORT;

  search = "";

  customers: MrfiktivDaCustomerViewModelGen[] = [];
  serviceEvents: MrfiktivDAServiceEventDetailViewModelGen[] = [];
  serviceEvent: MrfiktivDAServiceEventDetailViewModelGen | null = null;

  click(serviceEvent: MrfiktivDAServiceEventDetailViewModelGen) {
    this.serviceEvent = serviceEvent;

    this.setReportAndNext();
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.CHOICE;
  }

  async next() {
    this.currentStep = this.page;
  }

  get headers() {
    return [
      {
        text: "Vorname",
        value: "firstName"
      },
      { text: "Nachname", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Telefon", value: "phone" }
    ];
  }
  get serviceEventHeaders() {
    return [
      {
        text: "Vorname",
        value: "firstName"
      },
      { text: "Nachname", value: "lastName" },
      { text: "Email", value: "email" },
      { text: "Telefon", value: "phoneNumber" },
      { text: "Nummernschild", value: "numberPlate" },
      { text: "Externe Id", value: "externalId" }
    ];
  }

  get partnerId() {
    return PartnerModule.partner.id;
  }

  async findServiceEvent() {
    try {
      this.isLoading = true;
      const partnerId = this.partnerId;

      this.$log.debug("search", this.search);

      this.serviceEvents = await DaService.getServiceEvents({ partnerId, licensePlate: this.search });

      this.$log.debug("search", this.serviceEvents);

      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
  async findCustomer() {
    try {
      this.isLoading = true;
      const partnerId = this.partnerId;

      this.$log.debug("search", this.search);

      this.customers = await DaService.findCustomers({ partnerId, value: this.search, type: "name" });

      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  setReportAndNext() {
    if (!this.serviceEvent) {
      return;
    }

    const report: IReport = {
      customerAddress: {
        city: this.serviceEvent.city,
        country: "",
        street: this.serviceEvent.street,
        zip: this.serviceEvent.zip
      },
      customerContact: {
        email: this.serviceEvent.email,
        phone: this.serviceEvent.phoneNumber
      },
      customerName: this.serviceEvent.firstName + " " + this.serviceEvent.lastName,
      numberplate: this.serviceEvent.numberPlate,
      partnerId: this.partnerId,
      reportType: ReportType.CAR
    } as any;

    this.$emit("setReport", report);
    this.currentStep = SignRequestStepperPageEnum.FILE_SELECTION;
  }
}
