










































import { ibanRule } from "@/lib/rules/ibanRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBankingDtoGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import ProfileConfirmationRow from "./ProfileConfirmationRow.vue";

@Component({
  components: { ProfileConfirmationRow }
})
export default class ProfileBankingForm extends DarkModeHighlightMixin {
  @Prop()
  banking!: ThgBankingDtoGen;

  @Prop()
  disabled!: boolean;

  @Prop({ default: false })
  isLoading!: boolean;

  valid = false;

  get isDisabled() {
    return this.disabled;
  }

  set isDisabled(disabled: boolean) {
    this.$emit("update:disabled", disabled);
  }

  get ibanRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(ibanRule());

    return rules;
  }

  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  save() {
    this.$emit("save");
  }

  abort() {
    this.$emit("update:disabled", true);
  }
}
