import { IFilterListElement } from "../interfaces/filter/filterListElement.interface";
import { toISOString } from "./date-helper";

/**
 * Filters filterItems by values and conditions in filterList
 * @param filterItems the object that should be filtered
 * @param filterList list of conditions and values to filter by
 * @returns filtered list
 */
export function filterByFilter(filterItems: any[], filterList: IFilterListElement[]) {
  const passed: any[] = [];
  //go through items that should be filtered
  filterItems.forEach(el => {
    let passedAllConditions = true;
    //go throught conditions in filters
    filterList.forEach((condition: IFilterListElement) => {
      if (!passedAllConditions) {
        //some previously checked condition was not passed -> abort
        return;
      }
      if (!condition.value) {
        //if filter list has no given values just check condition without going through values
        if (!condition.conditionCheck(el)) {
          passedAllConditions = false;
        }
      } else if (condition.value.length !== 0) {
        let passedForAnyValue = false;
        //filter list has values. go through them and check them
        condition.value.forEach(value => {
          if (!passedForAnyValue) {
            //only check value for condition if condition didnt pass for any value yet
            passedForAnyValue = condition.conditionCheck(el, value);
          }
        });
        if (!passedForAnyValue) {
          // condition didnt pass for any value. Condition not satisfied.
          passedAllConditions = false;
        }
      }
    });
    if (passedAllConditions) {
      passed.push(el);
    }
  });
  return passed;
}

/**
 * Filters filterItems by string in search. a list of all items that contain the search string is returned
 * @param filterItems list of items that should be filtered
 * @param search string that items are filtered by
 * @returns all items from filterItems that contains search string
 */
export function filterBySearch(filterItems: any[], search: string) {
  if (!search) {
    return filterItems;
  }
  const passed: any[] = [];
  filterItems.forEach(el => {
    const elementString = JSON.stringify(el).toLowerCase();
    if (elementString.toLowerCase().includes(search.toLowerCase().trim())) {
      passed.push(el);
    }
  });
  return passed;
}

/**
 * checks if any values are given in filter list
 * @param filterList list of filters
 * @returns true if any values are set in filterlist, false otherwise
 */
export function isFilterListEmpty(filterList: IFilterListElement[]) {
  let empty = false;
  filterList.forEach(el => {
    if (el.value && el.value.length > 0) {
      empty = true;
    }
  });
  return !empty;
}

/**
 * The available filters for filtering dates
 */
export function getDateFilterList(propName: string): IFilterListElement[] {
  return [
    {
      multiple: false,
      value: [],
      label: "components.partner.PartnerReportList.filter.from",
      type: "date",
      conditionCheck: (obj: any, condition: string) => {
        if (!obj[propName]) {
          return false;
        }
        return toISOString(obj[propName].created) >= condition;
      }
    },
    {
      multiple: false,
      value: [],
      label: "components.partner.PartnerReportList.filter.to",
      type: "date",
      conditionCheck: (obj: any, condition: string) => {
        if (!obj[propName]) {
          return false;
        }
        return toISOString(obj[propName].created) <= condition;
      }
    }
  ];
}

/**
 * Filter an object by any property (using stringify) for a match with search
 */
export function filterJson(value: any, search: string, item: any) {
  return JSON.stringify(item)
    .toLowerCase()
    .includes(search.trim());
}
