import { Module, VuexModule, getModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store/VuexPlugin";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivCreateAdminTemplateDtoGen,
  MrfiktivRenderedTemplateViewModelGen,
  MrfiktivRenderTemplateDtoGen,
  MrfiktivUpdateAdminTemplateDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import adminTemplateService from "@/services/shared/adminTemplateService";
import {
  ThgAdminTemplateViewModelGen,
  ThgCreateAdminTemplateDtoGen,
  ThgRenderedTemplateViewModelGen,
  ThgRenderTemplateDtoGen,
  ThgUpdateAdminTemplateDtoGen
} from "@/services/thg/v1/data-contracts";

@Module({
  dynamic: true,
  namespaced: true,
  name: "admin-template",
  store
})
export class AdminTemplateStore extends VuexModule {
  private _rendered: MrfiktivRenderedTemplateViewModelGen | ThgRenderedTemplateViewModelGen | undefined = undefined;
  private _adminTemplate: MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined = undefined;
  private _adminTemplates: MrfiktivAdminTemplateViewModelGen[] | ThgAdminTemplateViewModelGen[] = [];

  get rendered(): MrfiktivRenderedTemplateViewModelGen | ThgRenderedTemplateViewModelGen | undefined {
    return this._rendered;
  }
  get adminTemplate(): MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined {
    return this._adminTemplate;
  }
  get adminTemplates(): MrfiktivAdminTemplateViewModelGen[] | ThgAdminTemplateViewModelGen[] {
    return this._adminTemplates;
  }

  @Mutation
  private _mutateRendered(rendered: MrfiktivRenderedTemplateViewModelGen | ThgRenderedTemplateViewModelGen): void {
    this._rendered = rendered;
  }
  @Mutation
  private _mutateAdminTemplate(_adminTemplate: MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen): void {
    this._adminTemplate = _adminTemplate;
  }
  @Mutation
  private _mutateAdminTemplates(
    _adminTemplates: MrfiktivAdminTemplateViewModelGen[] | ThgAdminTemplateViewModelGen[]
  ): void {
    this._adminTemplates = _adminTemplates;
  }

  @Action
  async createTemplate(
    data: MrfiktivCreateAdminTemplateDtoGen | ThgCreateAdminTemplateDtoGen
  ): Promise<MrfiktivCreateAdminTemplateDtoGen | ThgCreateAdminTemplateDtoGen> {
    const created = await adminTemplateService.create(data);
    this.context.commit("_mutateAdminTemplate", created);

    return data;
  }

  @Action
  async deleteTemplate(key: string): Promise<void> {
    const deleted = await adminTemplateService.delete(key);
    this.context.commit("_mutateAdminTemplate", deleted);
  }

  @Action
  async getAllTemplates(): Promise<void> {
    const all = await adminTemplateService.getAll();
    all?.sort((a, b) => (a.timestamp.created > b.timestamp.created ? -1 : 1));
    this.context.commit("_mutateAdminTemplates", all);
  }

  @Action
  replaceInList(data: ThgAdminTemplateViewModelGen): void {
    const templates = this.adminTemplates;
    const index = templates.findIndex(t => t.key === data.key);
    if (index > -1) {
      templates.splice(index, 1, data);
    }
    this.context.commit("_mutatePartnerTemplates", templates);
  }

  @Action
  async getTemplateByKey(key: string): Promise<void> {
    const template = await adminTemplateService.getByKey(key);
    this.context.commit("_mutateAdminTemplate", template);
  }

  @Action
  async renderTemplate(args: {
    key: string;
    data: MrfiktivRenderTemplateDtoGen | ThgRenderTemplateDtoGen;
  }): Promise<void> {
    const template = await adminTemplateService.render(args.key, args.data);
    this.context.commit("_mutateAdminTemplate", template);
  }

  @Action
  async updateTemplate(args: {
    key: string;
    data: MrfiktivUpdateAdminTemplateDtoGen | ThgUpdateAdminTemplateDtoGen;
  }): Promise<MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined> {
    const template = await adminTemplateService.update(args.key, args.data);
    this.context.commit("_mutateAdminTemplate", template);

    return template;
  }

  @Action
  clearAdminTemplate() {
    this.context.commit("_mutateAdminTemplate", undefined);
  }

  @Action
  clearAdminTemplates() {
    this.context.commit("_mutateAdminTemplates", []);
  }
}

export const AdminTemplateModule = getModule(AdminTemplateStore);
