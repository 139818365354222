/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { ConfigModule } from "@/store/modules/config";
import { AxiosInstance } from "axios";
import { AxiosInstanceProvider } from "../axios-instance.provider";
import { BearerTokenProvider } from "../bearer-token.provider";
import { EmptyTokenProvider } from "../empty-token.provider";
import { HttpClient } from "./v1/http-client";

/**
 * Provider to create a HttpClient or AxiosInstance.
 */
export class MrfiktivHttpClientProvider {
  /**
   * Creates a new instance of Axios.
   * @param isAuthenticated  if the instance should be using the BearerTokenProvider for authentication
   * @returns AxiosInstance to be used as HttpClient
   */
  instance(isAuthenticated = true): AxiosInstance {
    const baseUrl = ConfigModule.mrfiktivBaseUrl;
    const timeout = 60000;

    let instance = new AxiosInstanceProvider(new EmptyTokenProvider(), baseUrl, timeout).create();
    if (isAuthenticated) {
      instance = new AxiosInstanceProvider(new BearerTokenProvider(), baseUrl, timeout).create();
    }
    return instance;
  }

  /**
   * Creates a new HttpClient.
   * @param isAuthenticated  if the instance should be using the BearerTokenProvider for authentication
   * @returns HttpClient to be used as HttpClient
   */
  client(isAuthenticated = true): HttpClient {
    const client = new HttpClient();
    client.instance = this.instance(isAuthenticated);
    return client;
  }
}
