import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreateMessageDtoGen,
  MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen,
  MrfiktivPartnerMessageViewModelGen,
  MrfiktivUpdateMessageDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { PartnerMessage as MrfiktivPartnerMessage } from "../mrfiktiv/v1/PartnerMessage";
import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";
import { PartnerMessage as ThgPartnerMessage } from "../thg/v1/PartnerMessage";
import {
  ThgCreateMessageDtoGen,
  ThgPartnerMessageControllerFindAllByPartnerIdParamsGen,
  ThgPartnerMessageViewModelGen
} from "../thg/v1/data-contracts";

/**
 * Communicates with the message endpoints
 */
class MessageService {
  /**
   * The proxy.
   */
  mrfiktivProxy: MrfiktivPartnerMessage;

  /**
   * The proxy.
   */
  thgProxy: ThgPartnerMessage;

  /**
   * @class Initialize AccountService
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivPartnerMessage(mrfiktivHttpClientProvider.client());
    this.thgProxy = new ThgPartnerMessage(thgHttpClientProvider.client());
  }

  async sendMessage(
    partnerId: string,
    data: MrfiktivCreateMessageDtoGen | ThgCreateMessageDtoGen
  ): Promise<MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.partnerMessageControllerCreate(partnerId, data)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.partnerMessageControllerCreate(partnerId, data)).data;
    }
  }

  async sendMessages(
    partnerId: string,
    data: MrfiktivCreateMessageDtoGen[]
  ): Promise<MrfiktivPartnerMessageViewModelGen[] | ThgPartnerMessageViewModelGen[] | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.partnerMessageControllerCreateBatch(partnerId, data)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.partnerMessageControllerCreateBatch(partnerId, data)).data;
    }
  }

  async getMessage(
    partnerId: string,
    messageId: string
  ): Promise<MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.partnerMessageControllerFindOneByPartnerId(partnerId, messageId)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.partnerMessageControllerFindOneByPartnerId(partnerId, messageId)).data;
    }
  }

  async getAllPaginated(
    query:
      | MrfiktivPartnerMessageControllerFindAllByPartnerIdParamsGen
      | ThgPartnerMessageControllerFindAllByPartnerIdParamsGen
  ) {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.partnerMessageControllerFindAllByPartnerId(query)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.partnerMessageControllerFindAllByPartnerId(query)).data;
    }
  }

  async updateMessage(
    partnerId: string,
    messageId: string,
    data: MrfiktivUpdateMessageDtoGen
  ): Promise<MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.partnerMessageControllerUpdateOneById(partnerId, messageId, data)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.partnerMessageControllerUpdateOneById(partnerId, messageId, data)).data;
    }
  }
}

export default new MessageService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
