
import { BaseGoToHelper } from "@/lib/utility/goToHelper";
import { dottedSubString } from "@/lib/utility/string-helper";
import { MrfiktivProjectCustomViewViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ProjectModule } from "@/store/modules/project.store";
import { Component, Vue } from "vue-property-decorator";
import VueRouter from "vue-router";

export enum ProjectRouteNames {
  TICKET_TABLE = "TicketTable",
  TICKET_DETAIL = "TicketDetail",
  PROJECT_TABLE = "ProjectTable",
  PROJECT_SETTINGS = "ProjectSettings",
  PROJECT_DETAIL = "ProjectDetail",
  PROJECT_TICKET_DETAIL = "ProjectTicketDetail",
  PROJECT_CUSTOM_VIEW = "ProjectCustomView"
}

type Tab = { newTab?: boolean };
type Partner = Tab & { partnerId: string };
type Project = Tab & { projectId: string };
type Ticket = Tab & { number: number };
type View = Tab & { viewId: string };

export class ProjectGoToHelper extends BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    projectTable: (partnerId: string, projectId?: string) => {
      let query = {};
      if (projectId) {
        query = { projectId };
      }
      return {
        name: ProjectRouteNames.PROJECT_TABLE,
        params: { partnerId },
        query
      };
    },
    ticketTable: (partnerId: string, ticketNumber?: string) => {
      let query = {};
      if (ticketNumber) {
        query = { ticketNumber };
      }
      return {
        name: ProjectRouteNames.TICKET_TABLE,
        params: { partnerId },
        query
      };
    },
    projectCustomView: (partnerId: string, projectId: string, viewId: string, ticketNumber?: string) => {
      let query = {};
      if (ticketNumber) {
        query = { ticketNumber };
      }
      return {
        name: ProjectRouteNames.PROJECT_CUSTOM_VIEW,
        params: { partnerId, projectId, viewId },
        query
      };
    },
    ticketDetail: (partnerId: string, ticketNumber: string) => {
      return {
        name: ProjectRouteNames.TICKET_DETAIL,
        params: { partnerId, ticketNumber }
      };
    },
    projectSetting: (partnerId: string, projectId: string) => {
      return {
        name: ProjectRouteNames.PROJECT_SETTINGS,
        params: { partnerId, projectId }
      };
    },
    projectDetail: (partnerId: string, projectId: string) => {
      return {
        name: ProjectRouteNames.PROJECT_DETAIL,
        params: { partnerId, projectId }
      };
    },
    projectTicketDetail: (partnerId: string, projectId: string, viewId: string, ticketNumber: string) => {
      return {
        name: ProjectRouteNames.PROJECT_TICKET_DETAIL,
        params: { partnerId, projectId, ticketNumber, viewId }
      };
    }
  };
}

/**
 * Mixin with methods to fill all the (page) lists that are important for all the project views
 */
@Component({
  components: {},
  filters: {}
})
export default class ProjectGoToMixin extends Vue {
  private helper = new ProjectGoToHelper(this.$router);

  get goTo() {
    return {
      ticketTable: async (p: Partner) =>
        await this.helper.go(ProjectGoToHelper.locations.ticketTable(p.partnerId), p.newTab),
      ticketDetailSideCard: async (p: Partner & Ticket) =>
        await this.helper.go(ProjectGoToHelper.locations.ticketTable(p.partnerId, p.number.toString()), p.newTab),
      ticketDetail: async (p: Partner & Ticket) =>
        await this.helper.go(ProjectGoToHelper.locations.ticketDetail(p.partnerId, p.number.toString()), p.newTab),
      projectTicketDetail: async (p: Partner & Project & Ticket & View) =>
        await this.helper.go(
          ProjectGoToHelper.locations.projectTicketDetail(p.partnerId, p.projectId, p.viewId, p.number.toString()),
          p.newTab
        ),
      projectTable: async (p: Partner) =>
        await this.helper.go(ProjectGoToHelper.locations.projectTable(p.partnerId), p.newTab),
      projectSetting: async (p: Partner & Project) =>
        await this.helper.go(ProjectGoToHelper.locations.projectSetting(p.partnerId, p.projectId), p.newTab),
      projectDetail: async (p: Partner & Project) =>
        await this.helper.go(ProjectGoToHelper.locations.projectDetail(p.partnerId, p.projectId), p.newTab),
      projectCustomView: async (p: Partner & Project & Partial<Ticket> & View) =>
        await this.helper.go(
          ProjectGoToHelper.locations.projectCustomView(p.partnerId, p.projectId, p.viewId, p.number?.toString()),
          p.newTab
        ),
      ticketOrProjectTicketDetailSideCard: async (p: Partner & Partial<Project> & Partial<View> & Ticket) => {
        if (p.projectId) {
          await this.goTo.projectCustomView({
            partnerId: p.partnerId,
            projectId: p.projectId,
            viewId: p.viewId ?? "0",
            number: p.number
          });
        } else {
          await this.goTo.ticketDetailSideCard(p as Partner & Ticket);
        }
      },
      ticketOrProjectTicketDetail: async (p: Partner & Partial<Project> & View & Ticket) => {
        if (p.projectId) {
          await this.goTo.projectTicketDetail(p as Partner & Project & Ticket & View);
        } else {
          await this.goTo.ticketDetail(p as Partner & Ticket);
        }
      },
      ticketOrProjectTicketTable: async (p: Partner & Partial<Project> & Partial<View>) => {
        if (p.projectId) {
          await this.goTo.projectCustomView({
            partnerId: p.partnerId,
            projectId: p.projectId,
            viewId: p.viewId ?? "0"
          });
        } else {
          await this.goTo.ticketTable(p as Partner);
        }
      }
    };
  }

  get setUrlTo() {
    return {
      ticketTable: (p: Partner & Partial<Ticket>) =>
        this.helper.setUrl(ProjectGoToHelper.locations.ticketTable(p.partnerId, p.number?.toString())),
      projectCustomView: (p: Partner & Project & View & Partial<Ticket>) =>
        this.helper.setUrl(
          ProjectGoToHelper.locations.projectCustomView(
            p.partnerId,
            p.projectId,
            p.viewId.toString(),
            p.number?.toString()
          )
        )
    };
  }

  get breadCrumbs() {
    const locations = ProjectGoToHelper.locations;

    return {
      ProjectTable: (partnerId: string) => {
        return {
          text: this.$t("project.project.projects").toString(),
          exact: true,
          to: locations.projectTable(partnerId)
        };
      },
      ProjectDetail: (partnerId: string, projectId: string) => {
        return {
          text: (() => {
            if (!projectId) {
              return "";
            }
            const project = ProjectModule.paginationList.find(p => p.id === projectId);
            if (!project) {
              return projectId;
            }

            return dottedSubString(project.title, 20);
          })(),
          exact: true,
          to: locations.projectDetail(partnerId, projectId)
        };
      },
      ProjectDetailSetting: (partnerId: string, projectId: string) => {
        return {
          text: this.$t("common.nouns.detail").toString(),
          exact: true,
          to: locations.projectSetting(partnerId, projectId)
        };
      },
      TicketTable: (partnerId: string) => {
        return {
          text: this.$t("project.ticket.tickets").toString(),
          exact: true,
          to: locations.ticketTable(partnerId)
        };
      },
      ProjectCustomView: (partnerId: string, projectId: string, viewId: string) => {
        return {
          text: (() => {
            if (!projectId) {
              return "";
            }
            const project = ProjectModule.paginationList.find(p => p.id === projectId);
            if (!project) {
              return projectId;
            }

            const view: MrfiktivProjectCustomViewViewmodelGen = project.configuration.views[viewId];

            return dottedSubString(view?.title ?? this.$t("project.ticket.board").toString(), 20);
          })(),
          exact: true,
          to: locations.projectCustomView(partnerId, projectId, viewId)
        };
      },
      TicketDetail: (partnerId: string, number: number | string) => {
        return {
          text: `#${number}`,
          exact: true,
          to: locations.ticketDetail(partnerId, number?.toString())
        };
      },
      ProjectTicketDetail: (partnerId: string, projectId: string, viewId: string, number: string) => {
        return {
          text: `#${number}`,
          exact: true,
          to: locations.projectTicketDetail(partnerId, projectId, viewId, number.toString())
        };
      }
    };
  }
}
