/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivAcknowledgeDateDtoGen,
  MrfiktivArchiveEventsDtoGen,
  MrfiktivCreateBaseEventDtoGen,
  MrfiktivCreateEventDtoGen,
  MrfiktivEventControllerGetParamsGen,
  MrfiktivEventControllerListParamsGen,
  MrfiktivEventViewModelGen,
  MrfiktivFindManyDtoGen,
  MrfiktivInterruptSeriesDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateEventDtoGen,
  MrfiktivVehicleEventControllerGetParamsGen,
  MrfiktivVehicleEventControllerListParamsGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Event<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags event
   * @name EventControllerCreate
   * @summary (Event - CREATE) Create a event
   * @request POST:/partner/{partnerId}/event
   * @secure
   * @response `201` `MrfiktivEventViewModelGen` The created event
   */
  eventControllerCreate = (partnerId: string, data: MrfiktivCreateEventDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/event`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerGet
   * @summary (Event - READ) Get all events
   * @request GET:/partner/{partnerId}/event
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivEventViewModelGen)[] })`
   */
  eventControllerGet = ({ partnerId, ...query }: MrfiktivEventControllerGetParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivEventViewModelGen[] }, any>({
      path: `/partner/${partnerId}/event`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerDeleteMany
   * @summary (EVENT - DELETE) Deletes all events from a list for a partner
   * @request DELETE:/partner/{partnerId}/event
   * @secure
   * @response `200` `void`
   */
  eventControllerDeleteMany = (partnerId: string, data: MrfiktivFindManyDtoGen, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/partner/${partnerId}/event`,
      method: "DELETE",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerList
   * @summary (Event - READ) Get all events in a specific time frame
   * @request GET:/partner/{partnerId}/event/list
   * @secure
   * @response `200` `(MrfiktivEventViewModelGen)[]` The enumerated events from start to end date
   */
  eventControllerList = ({ partnerId, ...query }: MrfiktivEventControllerListParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivEventViewModelGen[], any>({
      path: `/partner/${partnerId}/event/list`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerOverdue
   * @summary (Event - READ) Lists all overdue events
   * @request GET:/partner/{partnerId}/event/overdue
   * @secure
   * @response `200` `(MrfiktivEventViewModelGen)[]` The overdue events
   */
  eventControllerOverdue = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivEventViewModelGen[], any>({
      path: `/partner/${partnerId}/event/overdue`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerGetOne
   * @summary (Event - READ) Get a event
   * @request GET:/partner/{partnerId}/event/{eventId}
   * @secure
   * @response `200` `MrfiktivEventViewModelGen` The event
   */
  eventControllerGetOne = (partnerId: string, eventId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/event/${eventId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerUpdate
   * @summary (Event - READ) Updates a event
   * @request PATCH:/partner/{partnerId}/event/{eventId}
   * @secure
   * @response `201` `MrfiktivEventViewModelGen` The updated event
   */
  eventControllerUpdate = (
    partnerId: string,
    eventId: string,
    data: MrfiktivUpdateEventDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/event/${eventId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerDelete
   * @summary (Event - READ) Deletes a event
   * @request DELETE:/partner/{partnerId}/event/{eventId}
   * @secure
   * @response `200` `MrfiktivEventViewModelGen` The deleted event
   */
  eventControllerDelete = (partnerId: string, eventId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/event/${eventId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerArchiveMany
   * @summary (EVENT - UPDATE) Soft deletes/archives all events from a list of events for a partner
   * @request PATCH:/partner/{partnerId}/event/archive
   * @secure
   * @response `200` `(MrfiktivEventViewModelGen)[]` The updated events
   */
  eventControllerArchiveMany = (partnerId: string, data: MrfiktivArchiveEventsDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivEventViewModelGen[], any>({
      path: `/partner/${partnerId}/event/archive`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerInterruptSeries
   * @summary (Event - UPDATE) Ends a series of events and restarts it to move parts of the series in time
   * @request PATCH:/partner/{partnerId}/event/{eventId}/interrupt
   * @secure
   * @response `201` `MrfiktivEventViewModelGen` The newly created series root
   */
  eventControllerInterruptSeries = (
    partnerId: string,
    eventId: string,
    data: MrfiktivInterruptSeriesDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/event/${eventId}/interrupt`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerDeleteVirtual
   * @summary (Event - DELETE) Deletes a event
   * @request DELETE:/partner/{partnerId}/event/{eventId}/{start}
   * @secure
   * @response `200` `MrfiktivEventViewModelGen` The deleted event
   */
  eventControllerDeleteVirtual = (partnerId: string, eventId: string, start: number, params: RequestParams = {}) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/event/${eventId}/${start}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerCreateVirtual
   * @summary (Event - CREATE) Create an updated event based on a virtual event
   * @request PATCH:/partner/{partnerId}/event/{eventId}/{start}
   * @secure
   * @response `201` `MrfiktivEventViewModelGen` The created event
   */
  eventControllerCreateVirtual = (
    partnerId: string,
    eventId: string,
    start: number,
    data: MrfiktivCreateBaseEventDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/event/${eventId}/${start}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name EventControllerAcknowledge
   * @summary (Event - READ) Updates a event
   * @request PATCH:/partner/{partnerId}/event/{eventId}/{start}/ack
   * @secure
   * @response `201` `MrfiktivEventViewModelGen` The updated event
   */
  eventControllerAcknowledge = (
    partnerId: string,
    eventId: string,
    start: number,
    data: MrfiktivAcknowledgeDateDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/event/${eventId}/${start}/ack`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name VehicleEventControllerCreate
   * @summary (Event - CREATE) Create a event
   * @request POST:/partner/{partnerId}/vehicle/{vehicleId}/event
   * @secure
   * @response `201` `MrfiktivEventViewModelGen` The created event
   */
  vehicleEventControllerCreate = (
    partnerId: string,
    vehicleId: string,
    data: MrfiktivCreateEventDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/event`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name VehicleEventControllerGet
   * @summary (Event - READ) Get all events
   * @request GET:/partner/{partnerId}/vehicle/{vehicleId}/event
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivEventViewModelGen)[] })`
   */
  vehicleEventControllerGet = (
    { partnerId, vehicleId, ...query }: MrfiktivVehicleEventControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivEventViewModelGen[] }, any>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/event`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name VehicleEventControllerList
   * @summary (Event - READ) Get all events in a specific time frame
   * @request GET:/partner/{partnerId}/vehicle/{vehicleId}/event/list
   * @secure
   * @response `200` `(MrfiktivEventViewModelGen)[]` The enumerated events from start to end date
   */
  vehicleEventControllerList = (
    { partnerId, vehicleId, ...query }: MrfiktivVehicleEventControllerListParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivEventViewModelGen[], any>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/event/list`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name VehicleEventControllerOverdue
   * @summary (Event - READ) Lists all overdue events
   * @request GET:/partner/{partnerId}/vehicle/{vehicleId}/event/overdue
   * @secure
   * @response `200` `(MrfiktivEventViewModelGen)[]` The overdue events
   */
  vehicleEventControllerOverdue = (partnerId: string, vehicleId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivEventViewModelGen[], any>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/event/overdue`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name VehicleEventControllerGetOne
   * @summary (Event - READ) Get a event
   * @request GET:/partner/{partnerId}/vehicle/{vehicleId}/event/{eventId}
   * @secure
   * @response `200` `MrfiktivEventViewModelGen` The event
   */
  vehicleEventControllerGetOne = (partnerId: string, vehicleId: string, eventId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/event/${eventId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name VehicleEventControllerDelete
   * @summary (Event - DELETE) Deletes a event
   * @request DELETE:/partner/{partnerId}/vehicle/{vehicleId}/event/{eventId}/{start}
   * @secure
   * @response `200` `MrfiktivEventViewModelGen` The deleted event
   */
  vehicleEventControllerDelete = (
    partnerId: string,
    vehicleId: string,
    eventId: string,
    start: number,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/event/${eventId}/${start}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags event
   * @name VehicleEventControllerCreateVirtual
   * @summary (Event - CREATE) Create an updated event based on a virtual event
   * @request PATCH:/partner/{partnerId}/vehicle/{vehicleId}/event/{eventId}/{start}
   * @secure
   * @response `201` `MrfiktivEventViewModelGen` The created event
   */
  vehicleEventControllerCreateVirtual = (
    partnerId: string,
    vehicleId: string,
    eventId: string,
    start: number,
    data: MrfiktivCreateBaseEventDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivEventViewModelGen, any>({
      path: `/partner/${partnerId}/vehicle/${vehicleId}/event/${eventId}/${start}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
