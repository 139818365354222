import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { ChargingStation } from "../v1/ChargingStation";
import {
  ThgChargingStationControllerFindAllParamsGen,
  ThgChargingStationControllerFindManyParamsGen,
  ThgUpdateChargingStationDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Service to communicate with backend.
 */
class ChargingStationAdminService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for communicating with backend.
   */
  chargingStationProxy: ChargingStation;

  /**
   * @class Initialize Service
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.chargingStationProxy = new ChargingStation(this.client);
  }

  /**
   * (ADMIN) Gets all charging stations.
   *
   * @returns the charging stations
   */
  async getAll(query?: ThgChargingStationControllerFindAllParamsGen) {
    const q = query || {};
    return this.chargingStationProxy.chargingStationControllerFindAll(q).then(value => value.data);
  }

  /**
   * (ADMIN) Gets many charging stations.
   *
   * @returns the charging stations
   */
  async getMany(query?: ThgChargingStationControllerFindManyParamsGen) {
    const q = query || {};
    return this.chargingStationProxy.chargingStationControllerFindMany(q).then(value => value.data);
  }

  /**
   * (ADMIN) Gets one charging station
   *
   * @param id the charging station id
   * @returns one charging station
   */
  async get(id: string) {
    return this.chargingStationProxy.chargingStationControllerFindOne(id).then(value => value.data);
  }

  /**
   * (ADMIN) Removes a charging station
   *
   * @param id the charging station id
   * @returns removed charging station
   */
  async remove(id: string) {
    return this.chargingStationProxy.chargingStationControllerRemove(id).then(value => value.data);
  }

  /**
   * (ADMIN) Updates a charging station
   *
   * @param id the charging station id
   * @returns updated charging station
   */
  async update(id: string, dto: ThgUpdateChargingStationDtoGen) {
    return this.chargingStationProxy.chargingStationControllerUpdate(id, dto).then(value => value.data);
  }
}

/**
 * Service to communicate with backend for admins.
 */
export default new ChargingStationAdminService(new ThgHttpClientProvider());
