import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IProject, Project } from "@/models/project.entity";
import projectService from "@/services/mrfiktiv/services/projectService";
import { MrfiktivProjectControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { UserModule } from "@/store/modules/me-user.store";
import Vue from "vue";
import { Action, Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ProjectDataAccessLayer } from "./access-layers/project.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { PartnerModule } from "./partner";

export class ProjectPageDataProvider extends AbstractPageDataProvider<IProject, MrfiktivProjectControllerGetParamsGen> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivProjectControllerGetParamsGen): Promise<IPageViewModel<IProject>> {
    const res = await projectService.getAll({ ...query });

    const meta = res.meta;
    const data = (res.data ?? []) as IProject[];

    return { meta, data };
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "project",
  store
})
export class ProjectStore extends PaginatedBaseStore<IProject, MrfiktivProjectControllerGetParamsGen> {
  _data = ProjectDataAccessLayer;
  _pageProvider = new ProjectPageDataProvider();
  _pager = new PageDataHandler(this._data, this._pageProvider);

  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: IProject[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = true;
  filterOptions: PaginationFilterListElement[] = Project.filterables;

  get projects() {
    return this._paginationList;
  }

  /**
   * Clears store
   * if user is permitted also repopulates store
   */
  @Action
  async reset(): Promise<void> {
    super.reset();

    const partnerId = PartnerModule.partner.id;
    if (partnerId && UserModule.abilities.can(ActionEnum.READ, ResourceEnum.PROJECT, partnerId)) {
      this.setFilters([]);
      await this.fetchAll({ partnerId }).catch(e => Vue.$log.error(e));
    }
  }
}

export const ProjectModule = getModule(ProjectStore);
