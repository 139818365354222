









import ProfileCard from "@/components/profile/ProfileCard.vue";
import Debug from "@/components/utility/Debug.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { TheLayoutPortal, ProfileCard, Debug }
})
export default class Profile extends Vue {
  get User() {
    return UserModule.user;
  }
}
