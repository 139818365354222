





















import { NavigationModule } from "@/store/modules/navigation.store";
import { Component, Vue } from "vue-property-decorator";
import QuickLinkList from "./QuickLinkList.vue";

@Component({
  components: { QuickLinkList }
})
export default class QuickLinkMenu extends Vue {
  get isQuicklinks() {
    return NavigationModule.quickLinks.length > 0;
  }
}
