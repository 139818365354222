








import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";

@Component({ components: { TheNavigationLayout } })
export default class TheThgBillingList extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get items(): IMenuListItem[] {
    return [
      {
        icon: "mdi-numeric-9-plus-box-multiple-outline",
        text: "Batch",
        link: "/batch",
        isEnabled: true
      },
      {
        icon: "mdi-cash-multiple",
        text: "navigation.ThePartnerList.setting.billingList",
        link: "/billing/list",
        isEnabled: true
      },
      {
        icon: "mdi-cash-multiple",
        text: "navigation.ThePartnerList.setting.billing",
        link: "/billing-batch/creation/",
        isEnabled: true
      },
      {
        icon: "mdi-bank-outline",
        text: "navigation.ThePartnerList.setting.accounting",
        link: "/billing/accounting",
        isEnabled: true
      },
      {
        icon: "mdi-credit-card-multiple-outline",
        text: "navigation.ThePartnerList.setting.creditor",
        link: "/billing/creditor",
        isEnabled: true
      }
    ];
  }
}
