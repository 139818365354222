import i18n from "../../plugins/I18nPlugin";

/*
 * Date should be of format YYYY-MM-DD
 */
export function dateRule(message = "components.thg.ThgChargingStationForm.dateRule"): (value: any) => boolean | string {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  return value => {
    if (value.match(regex) === null) {
      return String(i18n.tc(message));
    }

    const date = new Date(value);

    const timestamp = date.getTime();

    if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
      return String(i18n.tc(message));
    }

    return date.toISOString().startsWith(value) || String(i18n.tc(message));
  };
}

export function beforeCurrentDateRule(
  message = "components.thg.ThgChargingStationForm.beforeCurrentDateRule"
): (value: any) => boolean | string {
  return value => {
    const currentDate = new Date();
    const date = new Date(value);

    return currentDate > date || String(i18n.tc(message));
  };
}

export function timeFormatRule(message = "views.booking.ServiceForm.timeFormatRule"): (value: any) => boolean | string {
  return value => {
    if (!value.includes(":")) {
      return String(i18n.tc(message));
    }

    const [hours, minutes] = value.split(":", 2);

    if (isNaN(parseInt(hours, 10)) || isNaN(parseInt(minutes, 10))) {
      return String(i18n.tc(message));
    }

    return true;
  };
}
