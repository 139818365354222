var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%",
      "overflow-y": "auto",
      "overflow-x": "hidden"
    }
  }, [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "predefinedFilter": _vm.store.commonPredefinedFilter,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "headers": _vm.headers,
      "showSelect": true,
      "selectedItems": _vm.selectedItems
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.isTemplate",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-tooltip', {
          attrs: {
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v(" " + _vm._s(item.isSigned ? "mdi-signature-freehand" : item.isTemplate ? "mdi-file-multiple-outline" : "mdi-file-document-outline") + " ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(item.isSigned ? _vm.$t("sign.DocumentDetail.signed") : item.isTemplate ? _vm.$t("sign.DocumentDetail.template") : _vm.$t("sign.DocumentDetail.file")) + " ")])])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }