


























import { Component, Mixins } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";
import { LoginModule } from "@/store/modules/login.store";
import { MrfiktivConfirmPasswordDtoGen as ConfirmPasswordDto } from "@/services/mrfiktiv/v1/data-contracts";
import LoginMixin, { IFailureCodeContent } from "@/mixins/LoginMixin.vue";
import LoginNewPasswordMixin from "@/mixins/LoginNewPasswordMixin.vue";
import LoginComponentPasswordRequirementList from "@/components/login/LoginComponentPasswordRequirementList.vue";
import LoginComponentInputMail from "@/components/login/LoginComponentInputMail.vue";
import LoginComponentInputCode from "@/components/login/LoginComponentInputCode.vue";
import LoginComponentInputPassword from "@/components/login/LoginComponentInputPassword.vue";
import LoginComponentMessage from "@/components/login/LoginComponentMessage.vue";
import LoginComponentNavigationButton from "@/components/login/LoginComponentNavigationButton.vue";
import LoginComponentSubmitButton from "@/components/login/LoginComponentSubmitButton.vue";
import LoginComponentLayout from "@/components/login/LoginComponentLayout.vue";
import LoginErrorMixin from "@/mixins/LoginErrorMixin.vue";

@Component({
  components: {
    LoginComponentPasswordRequirementList,
    LoginComponentInputMail,
    LoginComponentInputCode,
    LoginComponentInputPassword,
    LoginComponentMessage,
    LoginComponentNavigationButton,
    LoginComponentSubmitButton,
    LoginComponentLayout
  }
})
export default class LoginCardReset extends Mixins(LoginMixin, LoginNewPasswordMixin, LoginErrorMixin) {
  beforeMount() {
    LoginModule.setPasswordNew("");
    LoginModule.setPasswordRepeat("");
  }

  /**
   * Failure codes to check in handleError in LoginErrorMixin
   */
  customFailureCodes = new Map<string, IFailureCodeContent>([
    ["ExpiredCodeError", { message: "components.login.LoginCardReset.failCodeExpired" }],
    ["CodeMismatchError", { message: "components.login.LoginCardReset.failCodeMismatch" }],
    [
      "LimitExceededError",
      { message: "components.login.LoginErrorMixin.failMaxRequestsReached", onFail: this.setFormInvalid }
    ]
  ]);

  mounted() {
    this.registerCustomFailureCodes(this.customFailureCodes);
  }

  /*
   * Send request to reset password when forgot password was used
   */
  async submit() {
    const confirmPasswordDto: ConfirmPasswordDto = {
      username: this.mail,
      password: this.passwordRepeat,
      code: this.code
    };
    try {
      this.isLoading = true;

      await UserModule.confirmPassword(confirmPasswordDto);
      this.isFormSubmitted = true;
      await this.handleSuccess("/login", () => {
        LoginModule.setPassword(LoginModule.passwordRepeat);
        LoginModule.setPasswordNew("");
        LoginModule.setPasswordRepeat("");
      });
    } catch (error) {
      this.handleFail(error);
    } finally {
      this.isLoading = false;
    }
  }
}
