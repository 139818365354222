/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * States of a vehicle
 */
export enum VehicleStateEnum {
  /**
   * Vehicle is going to be added to the fleet
   */
  UPCOMING = "upcoming",

  /**
   * Vehicle is active and drivable
   */
  ACTIVE = "active",

  /**
   * Vehicle is partially drivable
   */
  PARTIAL = "partial",

  /**
   * Vehicle is inactive and not drivable
   */
  INACTIVE = "inactive",

  /**
   * Vehicle is deleted
   */
  DELETED = "deleted"
}
