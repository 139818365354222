var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [!_vm.hasMultipleImages ? _c('v-img', {
    attrs: {
      "src": _vm.instructionPicturesImages[0],
      "height": "250"
    }
  }) : _vm._e(), _vm.hasMultipleImages ? _c('v-carousel', {
    attrs: {
      "height": "250",
      "continuous": "",
      "cycle": "",
      "delimiter-icon": "mdi-minus"
    }
  }, _vm._l(_vm.instructionPicturesImages, function (item, i) {
    return _c('v-carousel-item', {
      key: i,
      attrs: {
        "src": item,
        "reverse-transition": "fade-transition",
        "transition": "fade-transition"
      }
    });
  }), 1) : _vm._e(), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t(_vm.instructionTitle)) + " ")]), _c('v-card-text', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t(_vm.instructionText))
    }
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "p-3 mb-8",
    attrs: {
      "elevation": "0",
      "x-large": "",
      "data-test-instruction-understood": ""
    },
    on: {
      "click": _vm.clicked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("gotIt")) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }