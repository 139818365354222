/**
 * Checks wether a given file is a pdf.
 *
 * @param file to check
 * @returns if given file is an pdf.
 */
export function isPdf(file: File) {
  return file.type.indexOf("/pdf") >= 0;
}

/**
 * converts a file to a base64 string
 *
 * @param file the file to convert
 */
export async function convertFileToB64Async(file: File): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(String(reader.result));
    };
  });
}

/**
 * Converts a base64 string to a File object
 *
 * @param base64String the base64 string to convert
 * @param fileName the name to give the file (including extension)
 * @param mimeType the MIME type of the file (default: 'application/pdf')
 */
export function convertB64ToFile(base64String: string, fileName: string, mimeType = "application/pdf"): File {
  const bytes = convertB64ToBites(base64String);

  // Create a Blob from the binary data
  const blob = new Blob([bytes], { type: mimeType });

  const file = new File([blob], fileName, { type: mimeType });
  return file;
}

/**
 * Converts a base64 string to a byte array
 *
 * @param base64String the base64 string to convert
 */
export function convertB64ToBites(base64String: string) {
  // Remove the data URL prefix if it exists
  const base64Content = base64String.split(",")[1] || base64String;

  // Convert base64 to binary
  const binaryString = atob(base64Content);
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes;
}

/**
 * convert imagestring to file
 */
export async function convertUrlToFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], filename, { type: mimeType });
    });
}
