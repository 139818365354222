

















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { IDocumentPageMeta } from "./DocumentDetailEditorMixin.vue";

@Component({
  components: {},
  filters: {}
})
export default class DocumentTemplateDetailPageList extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop()
  pages!: IDocumentPageMeta[];

  @Prop()
  currentPageNumber!: number;

  @Prop({ default: true })
  outlined!: boolean;

  setCurrentPage(index: number) {
    DocumentTemplateModule.setHighlightedToken(-1);
    this.$emit("setCurrentPage", index);
  }
}
