var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "ma-auto card",
    attrs: {
      "flat": "",
      "align": "center"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.errors,
      "items-per-page": 10
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }