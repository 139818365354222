/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ThgExceptionViewmodelGen, ThgPartnerActiveOfferViewModelGen } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class PartnerActiveOfferService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Partner Active Offer Service
   * @name PartnerActiveOfferControllerFindActive
   * @summary Get the active promotions and prices
   * @request GET:/partner/{partnerId}/offers
   * @response `200` `ThgPartnerActiveOfferViewModelGen` The active promotions
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerActiveOfferControllerFindActive = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<ThgPartnerActiveOfferViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/offers`,
      method: "GET",
      format: "json",
      ...params
    });
}
