import {
  ThgChargingStationControllerFindAllMineParamsGen,
  ThgCreateChargingStationDtoGen,
  ThgChargingStationProofDtoGen,
  ThgUpdateMyChargingStationShortDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";
import { ThgHttpClientProvider } from "./../thg-http-client.provider";
import { ChargingStation } from "./../v1/ChargingStation";

/**
 * Service to communicate with backend.
 */
export class ChargingStationService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for communicating with backend.
   */
  chargingStationProxy: ChargingStation;

  /**
   * @class Initialize Service
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.chargingStationProxy = new ChargingStation(this.client);
  }

  /**
   * Creates a charging station.
   *
   * @param data the charging station dto
   */
  async create(data: ThgCreateChargingStationDtoGen) {
    return this.chargingStationProxy.chargingStationControllerCreate(data).then(value => value.data);
  }

  /**
   * Upload a pdf proof for a charging station's source of renewable energy
   * @param data the file
   */
  async uploadPdfProof(data: ThgChargingStationProofDtoGen) {
    return this.chargingStationProxy.chargingStationControllerUploadPdfProof(data).then(value => value.data);
  }

  /**
   * Upload an image proof for a charging station's source of renewable energy
   * @param data the file
   */
  async uploadImageProof(data: ThgChargingStationProofDtoGen) {
    return this.chargingStationProxy.chargingStationControllerUploadImageProof(data).then(value => value.data);
  }

  /**
   * Gets current users charging stations.
   *
   * @returns the charging stations of the current user
   */
  async getAllForUser(query?: ThgChargingStationControllerFindAllMineParamsGen) {
    const q = query || {};
    return this.chargingStationProxy.chargingStationControllerFindAllMine(q).then(value => value.data);
  }

  /**
   * Gets one charging station of current user.
   *
   * @param id the charging station id
   * @returns one charging station of the current user
   */
  async getOneForUser(id: string) {
    return this.chargingStationProxy.chargingStationControllerFindOneOfMine(id).then(value => value.data);
  }

  /**
   * Removes a charging station of current user.
   *
   * @param id the charging station id
   * @returns removed charging station of the current user
   */
  async removeForUser(id: string) {
    return this.chargingStationProxy.chargingStationControllerRemoveMine(id).then(value => value.data);
  }

  /**
   * Updates a charging station of current user.
   *
   * @param id the charging station id
   * @returns updated charging station of the current user
   */
  async updateForUser(id: string, dto: ThgUpdateMyChargingStationShortDtoGen) {
    return this.chargingStationProxy.chargingStationControllerUpdateMine(id, dto).then(value => value.data);
  }
}

/**
 * Service to communicate with backend for current user.
 */
export default new ChargingStationService(new ThgHttpClientProvider());
