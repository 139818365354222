/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateCustomerAccountDtoGen,
  MrfiktivCustomerAccountMeControllerFindAllParamsGen,
  MrfiktivCustomerAccountViewModelGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateCustomerAccountDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class MyCustomerAccountService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags My Customer Account Service
   * @name CustomerAccountMeControllerCreate
   * @summary Create a customer account for a registered user.
   * @request POST:/customer-account/me
   * @secure
   * @response `201` `MrfiktivCustomerAccountViewModelGen` The customer account created
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  customerAccountMeControllerCreate = (data: MrfiktivCreateCustomerAccountDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivCustomerAccountViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/customer-account/me`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags My Customer Account Service
   * @name CustomerAccountMeControllerFindAll
   * @summary Get all customer accounts for a registered user.
   * @request GET:/customer-account/me
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivCustomerAccountViewModelGen)[] })`
   * @response `201` `(MrfiktivCustomerAccountViewModelGen)[]` The customer accounts found
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  customerAccountMeControllerFindAll = (
    query: MrfiktivCustomerAccountMeControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivCustomerAccountViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/customer-account/me`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags My Customer Account Service
   * @name CustomerAccountMeControllerUpdate
   * @summary Update a customer account for a registered user.
   * @request PATCH:/customer-account/me/{id}
   * @secure
   * @response `201` `MrfiktivCustomerAccountViewModelGen` The customer account updated
   * @response `400` `void` General error if request is not working.
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  customerAccountMeControllerUpdate = (
    id: string,
    data: MrfiktivUpdateCustomerAccountDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivCustomerAccountViewModelGen, void | MrfiktivExceptionViewmodelGen>({
      path: `/customer-account/me/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags My Customer Account Service
   * @name CustomerAccountMeControllerFindOne
   * @summary Get customer account by partner.
   * @request GET:/customer-account/me/{partnerId}
   * @secure
   * @response `201` `MrfiktivCustomerAccountViewModelGen` The customer account found
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  customerAccountMeControllerFindOne = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCustomerAccountViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/customer-account/me/${partnerId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
