var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-5"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.login.LoginCardPasswordRequirementList.title")) + " "), _vm._l(_vm.requirementList, function (requirement, index) {
    return _c('div', {
      key: index
    }, [requirement.test && _vm.password ? _c('div', {
      staticClass: "satisfiedRequirement"
    }, [_vm._v(_vm._s(_vm.working) + " " + _vm._s(requirement.text))]) : _vm._e(), !requirement.test && _vm.password ? _c('div', {
      staticClass: "unsatisfiedRequirement"
    }, [_vm._v(_vm._s(_vm.fail) + " " + _vm._s(requirement.text))]) : _vm._e(), !requirement.test && _vm.password && index === 4 ? _c('div', {
      staticClass: "unsatisfiedRequirement smallfont"
    }, [_vm._v(" " + _vm._s(_vm.specialCharacters) + " ")]) : _vm._e(), !requirement.test && !_vm.password ? _c('div', [_vm._v("- " + _vm._s(requirement.text))]) : _vm._e()]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }