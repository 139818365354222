











































import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IVehicle } from "@/models/vehicle.entity";
import { Component, Prop } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { VehicleModule } from "@/store/modules/vehicle.store";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import Tooltip from "./tooltip.vue";
import FleetVehicleCrudMixin from "../fleet/FleetVehicleCrudMixin.vue";

@Component({
  components: { Tooltip },
  filters: { simpleDoubleDigitDate }
})
export default class RefsVehicle extends FleetVehicleCrudMixin implements IRefDetail<IVehicle> {
  @Prop()
  item!: IVehicle;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  get loadingConfirm() {
    return this.loadingUpdate;
  }

  get title() {
    return this.item.displayName || this.item.numberplate || this.item.identificationnumber;
  }

  get isConfirmable() {
    return Boolean(this.item.state !== VehicleStateEnum.ACTIVE);
  }

  goToDetail(openInNew = true) {
    new GoToHelper(this.$router).goToVehicleDetail(this.item.id, this.item.partnerId, undefined, openInNew);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  getState(item: IVehicle) {
    if (!item.state) {
      return { color: "Grey", label: "" };
    }

    return VehicleModule.stateMap.get(item.state);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  confirm() {
    this.$log.debug("confirm");
    this.confirmAsync();
    this.$emit("confirm", this.item);
  }

  async confirmAsync() {
    this.item.state = VehicleStateEnum.ACTIVE;

    await this.updateVehicle(this.item);
  }
}
