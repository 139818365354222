import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ISignRequest, SignRequest } from "@/models/sign-request.entity";

export const SignRequestDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ISignRequest> {
  protected mapEntity(entity: ISignRequest): ISignRequest {
    return new SignRequest(entity);
  }

  getIdentifier(entity: ISignRequest): string {
    return entity.id;
  }
})();
