import { Filter } from "@/lib/filterable";
import { MrfiktivByUserAtGen } from "@/services/mrfiktiv/v1/data-contracts";

class ByAtBase implements MrfiktivByUserAtGen {
  by: string;
  at: string;

  constructor(byAt?: Partial<MrfiktivByUserAtGen>) {
    this.by = byAt?.by ?? "";
    this.at = byAt?.at ?? "";
  }
}

type IByAt = ByAtBase;
const ByAt = Filter.createForClass(ByAtBase);

export { ByAt, IByAt };
