import { IPageViewModel } from "./page-view-model.interface";
import { IPaginationParams } from "./pagination-params.interface";
import { IPageDataProvider } from "./page-data-provider.interface";

/**
 * Defaults for pages
 */
export class PageDefaults {
  /**
   * Sets the first default page size
   */
  static DEFAULT_ITEMS_PER_PAGE = 25;

  /**
   * The default page sizes for the user
   */
  static DEFAULT_PAGE_SIZES = [5, 10, 25, 50, 100, 250];

  /**
   * The maximum items per page for admins
   */
  static MAX_ITEMS_PER_PAGE_ADMINS = 2500;
}

/**
 * An abstract data provider that handles the execution logic.
 * - The implementing class needs to specify how to fetch a page by implementing `getPage`
 * - The execution is done via `execute(q: Q)` which uses the `getPage` and returns the view model `T`.
 */
export abstract class AbstractPageDataProvider<T, Q extends IPaginationParams> implements IPageDataProvider<T, Q> {
  /**
   * @inheritdoc
   */
  isPageLoading = false;

  /**
   *  @inheritdoc
   */
  currentPage = 1;

  /**
   * @inheritdoc
   */
  totalPages = 0;

  /**
   * @inheritdoc
   */
  itemsPerPage = PageDefaults.DEFAULT_ITEMS_PER_PAGE;

  /**
   * @inheritdoc
   */
  totalItems = 0;

  /**
   * @inheritdoc
   */
  pageSizes = PageDefaults.DEFAULT_PAGE_SIZES;

  _lastKnownId: string | undefined;

  /**
   * @inheritdoc
   */
  abstract getPage(query: Q): Promise<IPageViewModel<T>>;

  /**
   * @inheritdoc
   */
  async execute(query: Q): Promise<T[] | undefined> {
    this.isPageLoading = true;

    if (!query.currentPage) {
      query.currentPage = this.currentPage;
    }

    if (!query.itemsPerPage) {
      query.itemsPerPage = this.itemsPerPage;
    }

    try {
      const res: IPageViewModel<T> = await this.getPage(query);

      this.currentPage = res.meta.currentPage;
      this.totalPages = res.meta.totalPages;
      this.totalItems = res.meta.totalItems;
      this.itemsPerPage = res.meta.itemsPerPage;

      return res.data;
    } finally {
      this.isPageLoading = false;
    }
  }
}
