/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateTicketDtoGen,
  MrfiktivFindManyDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivTicketControllerGetParamsGen,
  MrfiktivTicketViewModelGen,
  MrfiktivUpdateTicketDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Ticket<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ticket
   * @name TicketControllerCreate
   * @summary (Ticket - CREATE) Create a ticket
   * @request POST:/partner/{partnerId}/ticket
   * @secure
   * @response `201` `MrfiktivTicketViewModelGen` The created ticket
   */
  ticketControllerCreate = (partnerId: string, data: MrfiktivCreateTicketDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivTicketViewModelGen, any>({
      path: `/partner/${partnerId}/ticket`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ticket
   * @name TicketControllerGet
   * @summary (Ticket - READ) Get all tickets
   * @request GET:/partner/{partnerId}/ticket
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivTicketViewModelGen)[] })`
   */
  ticketControllerGet = ({ partnerId, ...query }: MrfiktivTicketControllerGetParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivTicketViewModelGen[] }, any>({
      path: `/partner/${partnerId}/ticket`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ticket
   * @name TicketControllerDeleteMany
   * @summary (Ticket - DELETE) Deletes all tickets from a list for a partner
   * @request DELETE:/partner/{partnerId}/ticket
   * @secure
   * @response `200` `void`
   */
  ticketControllerDeleteMany = (partnerId: string, data: MrfiktivFindManyDtoGen, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/partner/${partnerId}/ticket`,
      method: "DELETE",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
  /**
   * No description
   *
   * @tags ticket
   * @name TicketControllerGetOne
   * @summary (Ticket - READ) Get a ticket
   * @request GET:/partner/{partnerId}/ticket/{ticketId}
   * @secure
   * @response `200` `MrfiktivTicketViewModelGen` The ticket
   */
  ticketControllerGetOne = (partnerId: string, ticketId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivTicketViewModelGen, any>({
      path: `/partner/${partnerId}/ticket/${ticketId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ticket
   * @name TicketControllerUpdate
   * @summary (Ticket - UPDATE) Updates a ticket
   * @request PATCH:/partner/{partnerId}/ticket/{ticketId}
   * @secure
   * @response `201` `MrfiktivTicketViewModelGen` The updated ticket
   */
  ticketControllerUpdate = (
    partnerId: string,
    ticketId: string,
    data: MrfiktivUpdateTicketDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivTicketViewModelGen, any>({
      path: `/partner/${partnerId}/ticket/${ticketId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ticket
   * @name TicketControllerDelete
   * @summary (Ticket - DELETE) Deletes a ticket
   * @request DELETE:/partner/{partnerId}/ticket/{ticketId}
   * @secure
   * @response `200` `MrfiktivTicketViewModelGen` The deleted ticket
   */
  ticketControllerDelete = (partnerId: string, ticketId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivTicketViewModelGen, any>({
      path: `/partner/${partnerId}/ticket/${ticketId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ticket
   * @name TicketControllerGetOneByNumber
   * @summary (Ticket - READ) Get a ticket by number
   * @request GET:/partner/{partnerId}/ticket/{ticketNumber}/byNumber
   * @secure
   * @response `200` `MrfiktivTicketViewModelGen` The ticket
   */
  ticketControllerGetOneByNumber = (partnerId: string, ticketNumber: number, params: RequestParams = {}) =>
    this.http.request<MrfiktivTicketViewModelGen, any>({
      path: `/partner/${partnerId}/ticket/${ticketNumber}/byNumber`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ticket
   * @name TicketControllerArchiveMany
   * @summary (Ticket - UPDATE) Sets all tickets from a list to archived for a partner
   * @request PATCH:/partner/{partnerId}/ticket/archive
   * @secure
   * @response `200` `(MrfiktivTicketViewModelGen)[]` The updated tickets
   */
  ticketControllerArchiveMany = (partnerId: string, data: MrfiktivFindManyDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivTicketViewModelGen[], any>({
      path: `/partner/${partnerId}/ticket/archive`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
