import { ConfigModule } from "./../../store/modules/config";
import Vue from "vue";
import { HttpException, InternalServerErrorException } from "../exceptions/http/HttpException";
import VueI18n from "@/plugins/I18nPlugin";

export function handleError(error: any) {
  Vue.$log.error(error);
  if (error instanceof HttpException) {
    if (error instanceof InternalServerErrorException) {
      Vue.$toast.error(VueI18n.t("InternalServerErrorException", { email: ConfigModule.supportMail }), {
        onClick: () => {
          window.location.href = `mailto:${ConfigModule.supportMail}`;
        }
      });
    } else {
      Vue.$toast.error(error.message);
    }
  }
}
