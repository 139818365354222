var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isMobile ? _c('v-card', {
    class: _vm.cardClass,
    attrs: {
      "flat": "",
      "color": "#EEEEEE"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": _vm.color,
      "loading": _vm.loading,
      "outlined": ""
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(_vm._s(_vm.$t(_vm.prevBtnText)))])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "align": "right"
    }
  }, [_vm.showNext ? _c('v-btn', {
    attrs: {
      "elevation": "0",
      "disabled": _vm.disabled,
      "loading": _vm.loading,
      "color": _vm.color,
      "dark": !_vm.disabled
    },
    on: {
      "click": _vm.forward
    }
  }, [_vm._v(_vm._s(_vm.$t(_vm.nextBtnText)))]) : _vm._e()], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }