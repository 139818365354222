import { IUpdateThgMeterReadingDto } from "@/store/interface/thg/thg-update-status.interface";
import { ThgHttpClientProvider } from "../thg-http-client.provider";
import {
  ThgChargingStationViewModelGen,
  ThgCreateAnonymousChargingStationDtoGen,
  ThgCreateThgMeterReadingAdminDtoGen,
  ThgThgMeterReadingControllerFindAllParamsGen,
  ThgThgMeterReadingControllerUpdateMeterReadingParamsGen,
  ThgUpdateThgMeterReadingDtoGen,
  ThgTransferChargingStationDtoGen,
  ThgTransferThgMeterReadingDtoGen,
  ThgThgMeterReadingViewModelGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";
import { ThgMeterReading } from "../v1/ThgMeterReading";

/**
 * Admin service to communicate with backend.
 */
class MeterReadingAdminService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for communicating with backend.
   */
  meterReadingProxy: ThgMeterReading;

  /**
   * @class Initialize Service
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.meterReadingProxy = new ThgMeterReading(this.client);
  }

  /**
   * (ADMIN) Gets all meter readings.
   *
   * @returns the meter readings
   */
  async getAll(query?: ThgThgMeterReadingControllerFindAllParamsGen) {
    const q = query || {};

    return this.meterReadingProxy.thgMeterReadingControllerFindAll(q).then(value => value.data);
  }

  /**
   * (ADMIN) Gets one meter reading
   *
   * @param id the meter reading id
   * @returns one meter reading
   */
  async get(id: string) {
    return this.meterReadingProxy.thgMeterReadingControllerFindOne(id).then(value => value.data);
  }

  /**
   * (ADMIN) Gets all meter readings for charging station
   *
   * @param id the charging station  id
   * @returns the meter readings for a charging station
   */
  async getForChargingStation(id: string) {
    return this.meterReadingProxy
      .thgMeterReadingControllerFindAllMeterReadingsByChargingStation(id)
      .then(value => value.data);
  }

  /**
   * (ADMIN) Removes a meter reading
   *
   * @param id the meter reading id
   * @returns removed meter reading
   */
  async remove(id: string) {
    return this.meterReadingProxy.thgMeterReadingControllerRemove(id).then(value => value.data);
  }

  /**
   * (ADMIN) Updates a meter reading
   *
   * @param id the meter reading id
   * @returns updated meter reading
   */
  async update(id: string, dto: ThgUpdateThgMeterReadingDtoGen) {
    return this.meterReadingProxy.thgMeterReadingControllerUpdate(id, dto).then(value => value.data);
  }

  /**
   * (ADMIN) Updates a meter reading for a given partner, optionally send a notification
   *
   * @param meterReadingId the meter reading id
   * @param partnerId the partner id
   * @param dto the updated meter reading properties
   * @param userNotification send an email?
   * @returns updated meter reading
   */
  async updateByPartner(updateStatusStoreDto: IUpdateThgMeterReadingDto) {
    const params: ThgThgMeterReadingControllerUpdateMeterReadingParamsGen = {
      meterReadingId: updateStatusStoreDto.meterReadingId,
      partnerId: updateStatusStoreDto.partnerId,
      userNotification: updateStatusStoreDto.userNotification
    };
    return this.meterReadingProxy
      .thgMeterReadingControllerUpdateMeterReading(params, updateStatusStoreDto.dto)
      .then(value => value.data);
  }

  /**
   * (ADMIN)
   * @param partnerId
   * @param meterReadingId
   * @param transferDto
   */
  async transferMeterReading(
    partnerId: string,
    meterReadingId: string,
    transferDto: ThgTransferThgMeterReadingDtoGen
  ): Promise<ThgThgMeterReadingViewModelGen> {
    return (
      await this.meterReadingProxy.thgMeterReadingControllerTransferMeterReading(partnerId, meterReadingId, transferDto)
    ).data;
  }

  /**
   * (ADMIN)
   * @param chargingStationId
   * @param transferDto
   */
  async transferChargingStation(
    chargingStationId: string,
    transferDto: ThgTransferChargingStationDtoGen
  ): Promise<ThgChargingStationViewModelGen> {
    return (
      await this.meterReadingProxy.thgMeterReadingControllerTransferChargingStation(chargingStationId, transferDto)
    ).data;
  }

  /**
   * (ADMIN) Creates a charging station for a user.
   * @param partnerId the partner to create meter reading for
   * @param dto the dto to create a new charging station
   * @returns the created objects
   */
  async createAnonymous(
    partnerId: string,
    dto: ThgCreateAnonymousChargingStationDtoGen
  ): Promise<ThgChargingStationViewModelGen> {
    return this.meterReadingProxy.thgMeterReadingControllerCreateAnonymous(partnerId, dto).then(value => value.data);
  }

  /**
   * (ADMIN) Creates a meter reading on a charging station for a user.
   * @param partnerId the partner to create meter reading for
   * @param chargingStationId the charging station to create meter reading for
   * @param dto the dto to create a new charging station
   * @returns the created objects
   */
  async createMeterReading(
    partnerId: string,
    chargingStationId: string,
    dto: ThgCreateThgMeterReadingAdminDtoGen
  ): Promise<ThgChargingStationViewModelGen> {
    return this.meterReadingProxy
      .thgMeterReadingControllerCreateMeterReading(partnerId, chargingStationId, dto)
      .then(value => value.data);
  }
}

/**
 * Service to communicate with backend for admin
 */
export default new MeterReadingAdminService(new ThgHttpClientProvider());
