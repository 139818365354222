


































import ErrorCard from "@/components/utility/ErrorCard.vue";
import { detailedDateWithDay, formatHoursAndMinutes } from "@/lib/utility/date-helper";
import { BookingBookingUnauthenticatedViewModelGen } from "@/services/booking/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import BookingSuccess from "@/components/booking/customer/BookingSuccess.vue";

@Component({
  components: {
    ErrorCard,
    BookingSuccess
  }
})
export default class BookingCancel extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  @Prop()
  bookingId!: string;

  @Prop()
  booking!: BookingBookingUnauthenticatedViewModelGen;

  @Prop()
  serviceName!: string;

  @Prop()
  serviceLocation!: string;

  @Prop({ default: false })
  isBookingCancelled!: boolean;

  get selectedSlotDate() {
    if (this.booking) {
      return detailedDateWithDay(new Date(this.booking.start).toISOString(), this.$t("locale").toString());
    }

    return "";
  }

  get selectedSlotStartTime() {
    if (this.booking) {
      const from = formatHoursAndMinutes(new Date(this.booking.start));

      const to = formatHoursAndMinutes(new Date(this.booking.end));
      return this.$t("bookingForm.common.slot", { from, to });
    }

    return "";
  }

  cancel() {
    this.$emit("click");
  }
}
