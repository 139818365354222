/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Notification type
 */
export enum NotificationType {
  /**
   * Info notification
   */
  INFO = "info",

  /**
   * Success notification
   */
  SUCCESS = "success",

  /**
   * Warning notification that indicates a problem
   */
  WARNING = "warning",

  /**
   * ERROR notification that indicates any critical
   */
  ERROR = "error"
}
