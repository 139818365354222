











































import { TemplateTokenConfig } from "@/lib/configuration/template-token.configuration";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { TokenCategoriesEnum } from "@/lib/enum/templateEnums/tokenCategories.enum";
import { TemplateFormatterEnum } from "@/lib/enum/TemplateFormatters.enum";
import { ITemplateUrlContext } from "@/lib/interfaces/template/templateContext.interface";
import { PartnerEntity } from "@/models/partnerEntity";
import { IReport } from "@/models/report.entity";
import { MrfiktivFormDocumentGen, MrfiktivUserViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ISignRequest } from "@/models/sign-request.entity";
import { ThgBillingViewmodelGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";
import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { SignDigitalSignatureViewModelGen } from "@/services/sign/v1/data-contracts";

export const TokenUrls: ITemplateUrlContext = {
  request: `${TokenCategoriesEnum.URL}.request`,
  report: `${TokenCategoriesEnum.URL}.report`,
  sharing: `${TokenCategoriesEnum.URL}.sharing`
};

@Component({ components: {} })
export default class TemplateEditorPlaceholderDropdown extends Vue {
  @Prop()
  partnerId?: string;

  @Prop({ default: false })
  dense?: boolean;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: "" })
  initialOption?: string;

  /**
   * Optional selection of which placeholder types are available for selection
   */
  @Prop()
  context?: {
    report?: boolean;
    partner?: boolean;
    thg?: boolean;
    user?: boolean;
    billing?: boolean;
    form?: boolean;
    request?: boolean;
    url?: boolean;
    sign?: boolean;
  };

  @Prop({ default: false })
  persistentHint?: boolean;

  @Prop({ default: "" })
  label?: string;

  @Prop({ default: "" })
  hint?: string;

  formatter = "";

  isFormatter = false;

  option = this.initialOption ?? "";

  private readonly url = TokenUrls;

  private user: Partial<MrfiktivUserViewmodelGen> = {
    firstName: "user.firstName",
    lastName: "user.lastName",
    userName: "user.userName",
    address: {
      street: "user.address.street",
      zip: "user.address.zip",
      city: "user.address.city",
      state: "user.address.state",
      countryCode: "user.address.countryCode"
    },
    contact: { email: "user.contact.email", phone: "user.contact.phone" },
    _id: "user._id"
  };

  private partner: Partial<PartnerEntity> = {
    id: "partner.id",
    companyName: "partner.companyName",
    address: {
      street: "partner.address.street",
      zip: "partner.address.zip",
      city: "partner.address.city",
      state: "partner.address.state",
      countryCode: "user.address.countryCode",
      geo: {
        lat: "partner.address.geo.lat" as any,
        lng: "partner.address.geo.lng" as any
      }
    },
    contact: { email: "partner.contact.email", phone: "partner.contact.phone" },
    settings: {
      defaultUrl: "partner.settings.defaultUrl"
    } as any,
    companyUsername: "partner.companyUsername"
  };

  private report: Partial<IReport> = {
    id: "report.id",
    partnerId: "report.partnerId",
    customerName: "report.customerName",
    firstName: "report.firstName",
    lastName: "report.lastName",
    numberplate: "report.numberplate",
    customerAddress: {
      street: "report.customerAddress.street",
      zip: "report.customerAddress.zip",
      city: "report.customerAddress.city",
      state: "report.customerAddress.state"
    },
    customerContact: { email: "report.customerContact.email", phone: "report.customerContact.phone" },
    message: "report.message",
    datePreference: "report.datePreference",
    registrationResults: this.getRegistrationPaths("report"),
    progressStatus: "report.progressStatus" as ProgressStatusEnum
  };

  private thg: Partial<IThg> = {
    payoutConfiguration: {
      revenue: "thg.payoutConfiguration.revenue" as any,
      isFixed: "thg.payoutConfiguration.isFixed" as any
    },
    status: "thg.status" as any,
    bonus: "thg.bonus" as any,
    impactType: "thg.impactType" as any,
    impactFactor: "thg.impactFactor" as any,
    registration: this.getRegistrationPaths("thg"),
    campaign: "thg.campaign",
    code: "thg.code",
    year: "thg.year" as any,
    isMultiyear: "thg.isMultiyear" as any,
    numberplate: "thg.numberplate",
    kownFrom: "thg.knownFrom" as any,
    timestamp: this.getTimestampPaths("thg")
  };
  private billing: Partial<ThgBillingViewmodelGen> = {
    billingNumber: "billing.billingNumber" as any,
    type: "billing.type" as any,
    isPublished: "billing.isPublished" as any,
    userId: "billing.userId" as any,
    accountNumber: "billing.accountNumber" as any,
    timestamp: this.getTimestampPaths("billing")
  };

  private form: Partial<MrfiktivFormDocumentGen> = {
    partnerId: "form.partnerId" as any,
    name: "form.name",
    contact: { email: "form.contact.email", phone: "form.contact.phone" },
    title: "form.title",
    message: "form.message",
    registrationResults: this.getRegistrationResultsPaths("form"),
    timestamps: this.getTimestampsPaths("form")
  };

  private request: Partial<ISignRequest> = {
    title: "request.title",
    description: "request.description",
    recipient: this.getRecipientPaths("request"),
    validBy: "request.validBy",
    status: "request.status" as any,
    documents: "request.documents" as any,
    signatures: "request.signatures" as any,
    timestamp: this.getTimestampPaths("request") as any,
    id: "request.id",
    partnerId: "request.partnerId" as any
  };

  sign: Partial<SignDigitalSignatureViewModelGen> & { created: string } = {
    firstName: "sign.firstName",
    lastName: "sign.lastName",
    location: "sign.location",
    created: "sign.created"
  };

  get formatters() {
    return [TemplateFormatterEnum.DDMMYYYY, TemplateFormatterEnum.HHMM];
  }

  private getTimestampPaths(prefix: string) {
    return {
      created: (prefix + ".timestamp.created") as any,
      lastModified: (prefix + ".timestamp.lastModified") as any,
      modified: (prefix + ".timestamp.modified") as any
    };
  }

  private getTimestampsPaths(prefix: string) {
    return {
      created: (prefix + ".timestamps.created") as any,
      lastModified: (prefix + ".timestamps.lastModified") as any,
      modified: (prefix + ".timestamps.modified") as any
    };
  }

  private getRecipientPaths(prefix: string) {
    return {
      firstName: (prefix + ".recipient.firstName") as any,
      lastName: (prefix + ".recipient.lastName") as any,
      companyName: (prefix + ".recipient.companyName") as any,
      email: (prefix + ".recipient.email") as any
    };
  }

  private getRefPaths(prefix: string) {
    return {
      refType: (prefix + ".ref.type") as any,
      refId: (prefix + ".ref.id") as any
    };
  }

  private getRegistrationPaths(prefix: string) {
    return {
      city: (prefix + ".registration.city") as any,
      firstname: (prefix + ".registration.firstname") as any,
      firstregistrationDay: prefix + ".registration.firstregistrationDay",
      firstregistrationMonth: prefix + ".registration.firstregistrationMonth",
      firstregistrationYear: prefix + ".registration.firstregistrationYear",
      huMonth: prefix + ".registration.huMonth",
      huYear: prefix + ".registration.huYear",
      identificationnumber: prefix + ".registration.identificationnumber",
      manufacturerNameCode: prefix + ".registration.manufacturerNameCode",
      manufacturerTypeCode: prefix + ".registration.manufacturerTypeCode",
      name: prefix + ".registration.name",
      numberplate: prefix + ".registration.numberplate",
      zipCode: prefix + ".registration.zipCode",
      street: prefix + ".registration.street",
      driveTyp: prefix + ".registration.driveTyp",
      vehicleClass: prefix + ".registration.vehicleClass"
    } as any;
  }

  private getRegistrationResultsPaths(prefix: string) {
    return {
      city: (prefix + ".registrationResults.city") as any,
      firstname: (prefix + ".registrationResults.firstname") as any,
      firstregistrationDay: prefix + ".registrationResults.firstregistrationDay",
      firstregistrationMonth: prefix + ".registrationResults.firstregistrationMonth",
      firstregistrationYear: prefix + ".registrationResults.firstregistrationYear",
      huMonth: prefix + ".registrationResults.huMonth",
      huYear: prefix + ".registrationResults.huYear",
      identificationnumber: prefix + ".registrationResults.identificationnumber",
      manufacturerNameCode: prefix + ".registrationResults.manufacturerNameCode",
      manufacturerTypeCode: prefix + ".registrationResults.manufacturerTypeCode",
      name: prefix + ".registrationResults.name",
      numberplate: prefix + ".registrationResults.numberplate",
      zipCode: prefix + ".registrationResults.zipCode",
      street: prefix + ".registrationResults.street",
      driveTyp: prefix + ".registrationResults.driveTyp",
      vehicleClass: prefix + ".registrationResults.vehicleClass"
    } as any;
  }

  private getNestedKeys(object: Record<any, any>) {
    const keys: string[] = [];
    for (const val of Object.values(object)) {
      if (val instanceof Object) {
        keys.push(...this.getNestedKeys(val));
      } else {
        keys.push(val);
      }
    }

    return keys;
  }

  private get keysAndTranslations() {
    const context = this.getContext();

    this.$log.debug(context);

    const nestedKeys = this.getNestedKeys(context);

    const keysAndTranslations = nestedKeys.map(key => {
      return { key, translation: this.$t(`components.template.editor.placeholder.path.${key}`).toString() };
    });

    return keysAndTranslations;
  }

  get options() {
    return this.keysAndTranslations.map(k => k.translation);
  }

  private getKeyForTranslation(translation: string) {
    const key = this.keysAndTranslations.find(k => k.translation === translation)?.key || translation;

    return key;
  }

  onChangeSelection() {
    this.$emit("change", this.select());
  }

  private getContext() {
    const context: any = {};

    if (this.context) {
      if (this.context.report) {
        context.report = this.report;
      }
      if (this.context.partner) {
        context.partner = this.partner;
      }
      if (this.context.thg) {
        context.thg = this.thg;
      }
      if (this.context.user) {
        context.user = this.user;
      }
      if (this.context.billing) {
        context.billing = this.billing;
      }
      if (this.context.form) {
        context.form = this.form;
      }
      if (this.context.request) {
        context.request = this.request;
      }
      if (this.context.url) {
        context.url = this.url;
      }
      if (this.context.sign) {
        context.sign = this.sign;
      }

      return context;
    }

    // Admin Context
    if (!this.partnerId) {
      context.report = this.report;
      context.partner = this.partner;
      context.thg = this.thg;
      context.user = this.user;
      context.billing = this.billing;
      context.form = this.form;
      context.request = this.request;
      context.url = this.url;
      context.sign = this.sign;

      return context;
    }

    // REPORT_PORTAL Context
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      context.partner = this.partner;
      context.report = this.report;
      context.form = this.form;
      context.request = this.request;
      context.sign = this.sign;
      context.url = this.url;

      return context;
    }

    // THG_PORTAL Context
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      context.partner = this.partner;
      context.thg = this.thg;
      context.user = this.user;
      context.billing = this.billing;
      context.request = this.request;
      context.sign = this.sign;

      return context;
    }
  }

  /**
   * Gets the token category from the option
   *
   * @param option
   */
  private getTokenCategory(option: string): TokenCategoriesEnum {
    return option.split(".")[0] as TokenCategoriesEnum;
  }

  /**
   * Verifies that token categories like setting/custom that are not in the user categories are not nested
   *
   * @param option
   */
  private verifyNesting(option: string): void {
    const tokenCategory = this.getTokenCategory(option);

    if (!TemplateTokenConfig.userCategories.includes(tokenCategory)) {
      return;
    }

    if (this.option.split(".").length > 2) {
      const errorString = "User settings should not be nested!";

      this.$toast.error(errorString);
      throw new Error(errorString);
    }
  }

  /**
   * Verifies that the token category is known
   *
   * @param option
   */
  private verifyCategory(option: string) {
    const tokenCategory = this.getTokenCategory(option);

    if (!TemplateTokenConfig.predefinedCategories.includes(tokenCategory)) {
      const errorText =
        "A setting must have one of the following prefixes: " + TemplateTokenConfig.predefinedCategories.join(", ");

      this.$toast.error(errorText);
      throw new Error(errorText);
    }
  }

  private formatPlaceholder(placeholder: string, formatter: string) {
    placeholder = `{{${placeholder}}}`;
    let placeholderFormatterPrefix = "";
    let placeholderFormatterSuffix = "";
    if (this.isFormatter && formatter) {
      placeholderFormatterPrefix = `{{#${formatter}}} `;
      placeholderFormatterSuffix = ` {{/${formatter}}}`;
    }

    return `${placeholderFormatterPrefix}${placeholder}${placeholderFormatterSuffix}`;
  }

  /**
   * Verify the token category and return it formatted
   */
  select() {
    const token = this.getKeyForTranslation(this.option);

    this.verifyNesting(token);
    this.verifyCategory(token);

    return this.formatPlaceholder(token, this.formatter);
  }
}
