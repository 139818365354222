






































import { reportHomeQueryParams } from "@/lib/queryParams/reportHomeQueryParams";
import { thgQueryParams } from "@/lib/queryParams/thgQueryParams";
import { ConfigModule } from "@/store/modules/config";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class QueryParamsList extends Vue {
  get reportQueryParams() {
    return reportHomeQueryParams;
  }

  get thgQueryParams() {
    return thgQueryParams;
  }

  get isQueryParamsShowing() {
    return ConfigModule.debug;
  }
}
