var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    attrs: {
      "fullscreen": _vm.isMobile,
      "title": _vm.document.name,
      "height": _vm.sideCardHeight,
      "width": _vm.sideCardWidth
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return _vm._l(_vm.document.refs || [], function (ref) {
          return _c('go-to-reference-button', {
            key: 'reference' + ref.refId,
            attrs: {
              "reference": ref,
              "partnerId": _vm.partnerId
            }
          });
        });
      },
      proxy: true
    }, {
      key: "subtitle",
      fn: function fn() {
        return [_c('v-row', {
          staticClass: "subtitle mt-2",
          attrs: {
            "justify": "center",
            "align": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.subtitle) + " "), _c('v-spacer'), !_vm.edit ? _c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailInformation.isTemplate.".concat(_vm.document.isTemplate))) + " ")])]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }, [_c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-simple-table', [_c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("sign.DocumentTemplateDetailInformation.title")))]), _vm.edit ? _c('td', [_c('v-text-field', {
    staticClass: "mb-n1 mt-1",
    attrs: {
      "rules": _vm.requiredRule,
      "dense": ""
    },
    model: {
      value: _vm.document.title,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "title", $$v);
      },
      expression: "document.title"
    }
  })], 1) : _c('td', [_vm._v(_vm._s(_vm.document.title))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("sign.DocumentTemplateDetailInformation.path")))]), _vm.edit ? _c('td', [_c('v-text-field', {
    staticClass: "mb-n1 mt-1",
    attrs: {
      "dense": ""
    },
    on: {
      "blur": _vm.removeWhiteSpace
    },
    model: {
      value: _vm.document.folder,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "folder", $$v);
      },
      expression: "document.folder"
    }
  })], 1) : _c('td', [_vm._v(_vm._s(_vm.document.folder))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("sign.DocumentTemplateDetailInformation.tags")))]), _vm.edit ? _c('td', [_c('v-combobox', {
    staticClass: "mb-n1 mt-1",
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.document.tags,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "tags", $$v);
      },
      expression: "document.tags"
    }
  })], 1) : _c('td', [_vm.document.tags.length ? _c('span', _vm._l(_vm.document.tags, function (tag) {
    return _c('v-chip', {
      key: 'tag' + tag,
      staticClass: "ml-1",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), 1) : _c('span', [_vm._v(" - ")])])]), _vm.edit ? _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t("sign.SignDocumentForm.template")))]), _c('td', [_c('v-checkbox', {
    model: {
      value: _vm.document.isTemplate,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "isTemplate", $$v);
      },
      expression: "document.isTemplate"
    }
  })], 1)]) : _vm._e()])]), !_vm.edit && _vm.document.description ? _c('v-card-text', {
    staticClass: "mt-1"
  }, [_vm._v(" " + _vm._s(_vm.document.description) + " ")]) : _vm._e(), _vm.edit ? _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "label": _vm.$t('sign.DocumentTemplateDetailInformation.description')
    },
    model: {
      value: _vm.document.description,
      callback: function callback($$v) {
        _vm.$set(_vm.document, "description", $$v);
      },
      expression: "document.description"
    }
  })], 1) : _vm._e()], 1), _c('div', {
    staticClass: "ma-5 mb-3 mt-1"
  }, [!_vm.edit ? _c('v-btn', {
    attrs: {
      "block": "",
      "elevation": 0
    },
    on: {
      "click": function click($event) {
        _vm.edit = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailSave.edit")) + " ")]) : _vm._e(), _vm.edit ? _c('v-btn', {
    attrs: {
      "disabled": !_vm.isValid,
      "block": "",
      "color": "success",
      "elevation": 0
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailSave.save")) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }