import { generateCode } from "@/lib/utility/code-generator";
import { deepCopy } from "@/lib/utility/deep-copy";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IAddress } from "@/models/User/IAddress";
import { IContact } from "@/models/User/IContact";
import { IUser } from "@/models/User/IUser";
import authService from "@/services/mrfiktiv/services/authService";
import { MrfiktivVerifyAuthDtoGen as VerifyAuthDto } from "@/services/mrfiktiv/v1/data-contracts";
import thgService from "@/services/thg/services/thgService";
import {
  ThgCreateAnonymousThgDtoGen,
  ThgCreateThgAndUserDtoGen,
  ThgCreateThgMeDtoGen,
  ThgCreateThgVehicleDtoGen,
  ThgThgViewModelGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { IThgImpact } from "@/views/thg/interface/ThgImpact.interface";
import { electronicFormatIBAN } from "ibantools";
import { Vue } from "vue-property-decorator";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { CountryCodeEnum } from "../../lib/enum/country-code.enum";
import { ReportScreenEnum } from "../enum/partner/report-screen.enum";
import { KownFromEnum } from "../enum/thg/known-from.enum";
import { ThgVehicleTypes } from "../enum/thg/thg-vehicle-types.enum";
import { IBankingDto } from "../interface/thg/banking.interface";
import { PartnerActiveOfferModule } from "./partner-active-config.store";
import { PartnerModule } from "./partner";
import { ReportModule } from "./report.store";
import { ThgPortalModule } from "./thg.portal.store";
import { GtagModule } from "./gtag.store";
import { PayoutOptionEnum } from "@/views/thg/enum/payout-option.enum";

@Module({
  dynamic: true,
  namespaced: true,
  name: "thgCreate",
  store
})
export class ThgCreateStore extends VuexModule {
  /**
   * Local stored Campaign
   */
  LOCAL_CAMPAIGN = "campaign";

  /**
   * Local stored Code
   */
  LOCAL_CODE = "code";

  /**
   * did user confirm validity of registration images in quick checkout
   */
  private _isRegistrationValid = true;

  /**
   * ImpactTypeItems define which type of impacts are offered
   */
  private _impactTypeItems: IThgImpact[] = [
    {
      smiley: "💶",
      impactType: ImpactTypeEnum.payout,
      icon: "mdi-egg-outline",
      src: "https://static.mmmint.ai/wirkaufendeinethg/photo-1580048915913-4f8f5cb481c4-1.jpeg",
      title: "report.thgImpactTypeItems.payoutTitle",
      titleAlt: "report.thgImpactTypeItems.payout.title",
      subtitleAlt: "report.thgImpactTypeItems.payout.subtitle",
      impactTypeQuestion: "report.thgImpactTypeItems.payout.question",
      subtitle: "report.thgImpactTypeItems.payoutSubtitle",
      descriptionAlt: "report.thgImpactTypeItems.payoutDescriptionAlt",
      description: "report.thgImpactTypeItems.payoutDescription",
      showDialog: true,
      dialogTitle: "report.thgImpactTypeItems.payoutDialogTitle",
      dialogSubtitle: "report.thgImpactTypeItems.payoutDialogSubtitle",
      showImpactFactorSlider: false,
      baseImpactFactor: 0
    },
    {
      smiley: "🔋",
      impactType: ImpactTypeEnum.charging,
      icon: "mdi-ev-station",
      src: "https://static.mmmint.ai/wirkaufendeinethg/photo-1593941707882-a5bba14938c7-1.jpeg",
      title: "report.thgImpactTypeItems.chargingTitle",
      titleAlt: "report.thgImpactTypeItems.charging.title",
      subtitleAlt: "report.thgImpactTypeItems.charging.subtitle",
      impactTypeQuestion: "report.thgImpactTypeItems.charging.question",
      subtitle: "report.thgImpactTypeItems.chargingSubtitle",
      descriptionAlt: "report.thgImpactTypeItems.chargingDescriptionAlt",
      description: "report.thgImpactTypeItems.chargingDescription",
      showImpactFactorSlider: true,
      showDialog: false,
      dialogTitle: "report.thgImpactTypeItems.dialogTitle",
      dialogSubtitle: "report.thgImpactTypeItems.dialogSubtitle",
      baseImpactFactor: 30
    },
    {
      smiley: "🌳",
      impactType: ImpactTypeEnum.trees,
      icon: "mdi-tree-outline",
      src: "https://static.mmmint.ai/wirkaufendeinethg/photo-1615128266535-f39c88d641eb-1.jpeg",
      title: "report.thgImpactTypeItems.treesTitle",
      titleAlt: "report.thgImpactTypeItems.trees.title",
      subtitleAlt: "report.thgImpactTypeItems.trees.subtitle",
      impactTypeQuestion: "report.thgImpactTypeItems.trees.question",
      subtitle: "report.thgImpactTypeItems.treesSubtitle",
      descriptionAlt: "report.thgImpactTypeItems.treesDescriptionAlt",
      description: "report.thgImpactTypeItems.treesDescription",
      showImpactFactorSlider: true,
      showDialog: false,
      dialogTitle: "report.thgImpactTypeItems.dialogTitle",
      dialogSubtitle: "report.thgImpactTypeItems.dialogSubtitle",
      baseImpactFactor: 30
    },
    {
      smiley: "☀️",
      impactType: ImpactTypeEnum.renewable,
      icon: "mdi-wind-turbine",
      src: "https://static.mmmint.ai/wirkaufendeinethg/photo-1466611653911-95081537e5b7-1.jpeg",
      title: "report.thgImpactTypeItems.renewableTitle",
      titleAlt: "report.thgImpactTypeItems.renewable.title",
      subtitleAlt: "report.thgImpactTypeItems.renewable.subtitle",
      impactTypeQuestion: "report.thgImpactTypeItems.renewable.question",
      subtitle: "report.thgImpactTypeItems.renewableSubtitle",
      descriptionAlt: "report.thgImpactTypeItems.renewableDescriptionAlt",
      description: "report.thgImpactTypeItems.DescriptionAlt",
      showImpactFactorSlider: true,
      showDialog: false,
      dialogTitle: "report.thgImpactTypeItems.DialogTitle",
      dialogSubtitle: "report.thgImpactTypeItems.DialogSubtitle",
      baseImpactFactor: 30
    },
    {
      smiley: "🏦",
      impactType: ImpactTypeEnum.customerAccount,
      icon: "mdi-card-account-details-star-outline",
      src: "https://static.mmmint.ai/wirkaufendeinethg/customerAccount-1.jpg",
      title: "report.thgImpactTypeItems.customerAccountTitle",
      titleAlt: "report.thgImpactTypeItems.customerAccount.title",
      subtitleAlt: "report.thgImpactTypeItems.customerAccount.subtitle",
      impactTypeQuestion: "report.thgImpactTypeItems.customerAccount.question",
      subtitle: "report.thgImpactTypeItems.customerAccountSubtitle",
      descriptionAlt: "report.thgImpactTypeItems.customerAccountDescriptionAlt",
      description: "report.thgImpactTypeItems.customerAccountDescription",
      showImpactFactorSlider: false,
      showDialog: false,
      dialogTitle: "report.thgImpactTypeItems.customerAccountDialogTitle",
      dialogSubtitle: "report.thgImpactTypeItems.customerAccountDialogSubtitle",
      baseImpactFactor: 100
    },
    {
      smiley: "♻️",
      impactType: ImpactTypeEnum.retire,
      icon: "mdi-molecule-co2",
      src: "https://static.mmmint.ai/wirkaufendeinethg/retire-2.jpeg",
      title: "report.thgImpactTypeItems.retireTitle",
      titleAlt: "report.thgImpactTypeItems.retire.title",
      subtitleAlt: "report.thgImpactTypeItems.retire.subtitle",
      impactTypeQuestion: "report.thgImpactTypeItems.retire.question",
      subtitle: "report.thgImpactTypeItems.retireSubtitle",
      descriptionAlt: "report.thgImpactTypeItems.retireDescriptionAlt",
      description: "report.thgImpactTypeItems.retireDescription",
      showDialog: true,
      dialogTitle: "report.thgImpactTypeItems.retireDialogTitle",
      dialogSubtitle: "report.thgImpactTypeItems.retireDialogSubtitle",
      showImpactFactorSlider: false,
      baseImpactFactor: 100
    }
  ];

  /**
   * Possible Year Selections
   */
  private _yearSelections = [
    {
      value: [2023, 2024],
      title: "report.thgYearSelector.items.combi",
      text: "report.thgYearSelector.items.combiText",
      src: "https://static.mmmint.ai/wirkaufendeinethg/bothyears.jpg"
    },
    {
      value: [2023],
      title: "report.thgYearSelector.items.2023",
      text: "report.thgYearSelector.items.2023Text",
      src: "https://static.mmmint.ai/wirkaufendeinethg/thisyear.jpg"
    },
    {
      value: [2024],
      title: "report.thgYearSelector.items.2024",
      text: "report.thgYearSelector.items.2024Text",
      src: "https://static.mmmint.ai/wirkaufendeinethg/nexyear.jpg"
    }
  ];

  get yearSelections() {
    return this._yearSelections;
  }

  /**
   * the active impacttypes (defines which impactitems can be selected in the impactselection view.)
   */
  private _activeImpacttypes: ImpactTypeEnum[] = [ImpactTypeEnum.payout, ImpactTypeEnum.charging, ImpactTypeEnum.trees];

  /**
   * The active payout options to choose from
   */
  private _activePayoutOptions: PayoutOptionEnum[] = [PayoutOptionEnum.EXPRESS, PayoutOptionEnum.REGULAR];

  /**
   * set if the Application is loading
   */
  private _loading = false;

  /**
   * preview vehicle class
   */
  private _vehicleClassPreview = true;

  /**
   * set if the User is resubmitting banking information
   */
  private _isResubmitBankingInformation = false;

  /**
   * set if the User is resubmitting Tax number
   */
  private _isResubmitTaxnumber = false;

  /**
   * set if the user is resubmitting address details at a later point in time
   */
  private _isResubmitAddress = false;

  /**
   * Set campaign
   */
  private _impactType: ImpactTypeEnum = deepCopy(this._activeImpacttypes[0]);

  /**
   * Selected ImpactType Id. Defines which activity is to be invested in.
   */
  private _selectedImpactTypeId = 0;

  /**
   * Selected impactfactor (defines which part is invested or payed out).
   * Per impact type the impactfactor is set individually too @see _impactTypeItems
   */
  private _impactFactor = 0;

  /**
   * The payout option selected by the user.
   * - **Regular**: Payment 5 days after entity approval.
   * - **Express**: Immediate payment after processing.
   * - **Deferred**: Payment upon product carry over.
   *
   * This choice affects the payout configuration and purchasing price.
   * 	@default PayoutOptionEnum.REGULAR
   */
  private _payoutOption = PayoutOptionEnum.REGULAR;

  /**
   * Data is confirmed
   */
  private _isConfirmed = false;

  /**
   * Marketing OptIn
   */
  private _isMarketingOptIn = false;

  /**
   * Vehicle class
   */
  private _vehicleClass: ThgVehicleTypes | undefined = undefined;

  /**
   * Terms accepted
   */
  private _isTermsAccepted = false;

  private _EMPTY_USER: IUser = {
    firstName: "",
    lastName: ""
  };
  private _user = this._EMPTY_USER;

  /**
   * If user acts for a company this is true
   */
  private _isCompany = false;

  /**
   * If user is customer of whitelabelpartner
   */
  private _isExternalCustomer = true;

  /**
   * External customernumber of whitelabelpartner
   */
  private _externalCustomerNumber = "";

  /**
   * If user acts for a company
   */
  private _company = "";

  /**
   * If user acts for a company
   */
  private _taxnumber = "";

  /**
   * If the password is valid due to our password requirements
   */
  private _isValidPassoword = false;

  /**
   * Id of a thg that a new thg is created from
   */
  private _parentId = "";

  private _EMPTY_CONTACT: IContact = {
    phone: "",
    email: ""
  };

  private _contact = deepCopy(this._EMPTY_CONTACT);

  private _EMPTY_ADDRESS: IAddress = {
    street: "",
    state: "",
    city: "",
    zip: "",
    countryCode: ReportModule.partner.countryCode || CountryCodeEnum.germany
  };
  private _address = deepCopy(this._EMPTY_ADDRESS);

  private _EMPTY_BANKING: IBankingDto = {
    name: "",
    iban: "",
    bank: ""
  };
  private _banking = deepCopy(this._EMPTY_BANKING);

  private _numberplate = "";

  private _password = "";

  private _years: number[] = [];

  private _selectedYearId = 0;

  private _isTaxDeductible = true;

  /**
   * A unique id for tracking the checkout
   */
  private _transactionId = generateCode();

  //registrationImages
  registrationImages: string[] = [];
  registrationImageFront: IImageUploaded[] = [];
  registrationImageBack: IImageUploaded[] = [];

  get isTaxDeductible() {
    return this._isTaxDeductible;
  }

  /**
   * @returns the vehicleclass
   */
  get vehicleClass() {
    return this._vehicleClass;
  }

  /**
   * @returns the vehicleclass preview. Is true, only first 3 items are shown in vehicle class selector
   */
  get vehicleClassPreview() {
    return this._vehicleClassPreview;
  }

  /**
   * The amount of the payout in euro per BEV.
   */
  get bonus(): string {
    if (PartnerActiveOfferModule.loading) {
      return "...";
    }

    if (PartnerActiveOfferModule.currentPrice) {
      // TODO: Fix the vehicle factor by implementing the per vehicle type prices
      let vehicleFactorMWh = 2;

      if (ReportModule.partner.countryCode === CountryCodeEnum.austria) {
        vehicleFactorMWh = 1.5;
      }

      return `${PartnerActiveOfferModule.currentPrice.value * vehicleFactorMWh}`;
    } else if (ReportModule.partner?.settings?.cardTitle) {
      return ReportModule.partner?.settings?.cardTitle;
    } else if (ReportModule.partner) {
      Vue.$log.error(`${ReportModule.partner._id} cardTitle missing`);
      Vue.$toast.error(
        "Fehler - Preis Konfiguration fehlt. Bitte versuchen Sie es später erneut oder wenden Sie sich an info@mintfuture.de"
      );
    }

    return "Fehler";
  }

  /**
   * Returns the partner cardSubtitle if configured which acts as a descriptor,
   * could be "bis zu" or "mindestens" per partner.
   */
  get bonusPrefix(): string {
    if (!ReportModule.partner?.settings?.cardSubtitle) {
      return "";
    }

    if (ReportModule.partner?.settings?.cardSubtitle === "-") {
      return "";
    }

    return ReportModule.partner?.settings?.cardSubtitle;
  }

  get isRegistrationValid() {
    return this._isRegistrationValid;
  }

  get parentId() {
    return this._parentId;
  }

  get loading() {
    return this._loading;
  }

  get isValidPassword(): boolean {
    return this._isValidPassoword;
  }

  /**
   * returns the years of registration for the thg
   */
  get years(): number[] {
    return this._years;
  }

  /**
   * returns the selected Year id
   */
  get selectedYearId(): number {
    return this._selectedYearId;
  }

  /**
   * @returns if the user wants to resubmit the BankingInformation
   */
  get isResubmitBankingInformation() {
    return this._isResubmitBankingInformation;
  }

  /**
   * @returns if the user wants to resubmit the Taxnumber
   */
  get isResubmitTaxnumber() {
    return this._isResubmitTaxnumber;
  }
  /**
   * @returns if the user wants to resubmit the address details at a later point in time
   */
  get isResubmitAddress() {
    return this._isResubmitAddress;
  }

  /**
   * @returns the company of the user
   */
  get company() {
    return this._company;
  }

  /**
   * @returns the taxnumber
   */
  get taxnumber() {
    return this._taxnumber;
  }

  /**
   * @returns the taxnumber
   */
  get externalCustomerNumber() {
    return this._externalCustomerNumber;
  }

  /**
   * @returns the campaign from local storage
   */
  get campaign(): string {
    try {
      const campaign = localStorage.getItem(this.LOCAL_CAMPAIGN)?.toString() || "";

      return campaign;
    } catch (error) {
      Vue.$log.error(error);
    }

    return "";
  }

  /**
   * @returns the code from local storage
   */
  get code(): string {
    try {
      const code = localStorage.getItem(this.LOCAL_CODE)?.toString() || "";

      return code;
    } catch (error) {
      Vue.$log.error(error);
    }

    return "";
  }

  /**
   * returns the base impact factor item (frst factor of active impact type items)
   */
  get baseImpactFactor(): number {
    if (this.activeImpactTypeItems.length) {
      return this.activeImpactTypeItems[0].baseImpactFactor;
    }
    return 0;
  }

  /**
   * returns the base impact type item (frst item of active impact type items)
   */
  get baseImpactItemType(): ImpactTypeEnum {
    if (this.activeImpactTypeItems.length) {
      return this.activeImpactTypeItems[0].impactType;
    }
    return ImpactTypeEnum.payout;
  }

  /**
   * @returns the company of the user
   */
  get isCompany() {
    return this._isCompany;
  }

  /**
   * @returns the _isExternalCustomer
   */
  get isExternalCustomer() {
    return this._isExternalCustomer;
  }

  /**
   * @returns retruns true if we need the banking information of the customer
   */
  get showBankingInfo(): boolean {
    if (ThgCreateModule.impactFactor < 100) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @returns the selected impacttype as enum
   */
  get impactType(): ImpactTypeEnum {
    return this._impactType;
  }

  /**
   * @returns cashpayment item
   */
  get itemPayout() {
    return this._impactTypeItems.filter(item => item.impactType === ImpactTypeEnum.payout)[0];
  }

  /**
   * @returns the selected impactFactor
   */
  get impactFactor(): number {
    return this._impactFactor;
  }

  /**
   * The payout option selected by the user.
   * - **Regular**: Payment 5 days after entity approval.
   * - **Express**: Immediate payment after processing.
   * - **Deferred**: Payment upon product carry over.
   *
   * This choice affects the payout configuration and purchasing price.
   * 	@default PayoutOptionEnum.REGULAR
   */
  get payoutOption(): PayoutOptionEnum {
    return this._payoutOption;
  }

  /**
   * @returns if data is confirmed
   */
  get isMarketingOptIn(): boolean {
    return this._isMarketingOptIn;
  }

  /**
   * @returns if data is confirmed
   */
  get isConfirmed(): boolean {
    return this._isConfirmed;
  }

  /**
   * @returns if terms are accepted
   */
  get isTermsAccepted(): boolean {
    return this._isTermsAccepted;
  }

  /**
   * @returns if data is confimed
   */
  get password(): string {
    return this._password;
  }

  /**
   * @returns the selected impactTypeId
   */
  get impactTypeId() {
    return this._selectedImpactTypeId;
  }

  /**
   * @returns all impact type items
   */
  get impactTypeItems() {
    return this._impactTypeItems;
  }

  /**
   * @returns returns all active impacttypenums
   */
  get activeImpactTypes(): ImpactTypeEnum[] {
    return this._activeImpacttypes;
  }

  /**
   * @returns returns all active impacttypenums
   */
  get activePayoutOptions(): PayoutOptionEnum[] {
    return this._activePayoutOptions;
  }

  /**
   * @returns returns all active impacttyp items
   */
  get activeImpactTypeItems(): IThgImpact[] {
    return this._impactTypeItems.filter(value => this._activeImpacttypes.includes(value.impactType));
  }

  /**
   * @returns returns all active impacttyp items in given order
   */
  get activeImpactTypeItemsOrdered(): IThgImpact[] {
    const items = [];
    for (const impactType of this._activeImpacttypes) {
      const foundItems = this._impactTypeItems.filter(value => value.impactType === impactType);
      if (foundItems.length) {
        items.push(foundItems[0]);
      }
    }
    return items;
  }

  get isExternalCustomerScreenAvailable(): boolean {
    try {
      if (ReportModule.partner?.settings?.reportSettings?.screenOrder) {
        return ReportModule.partner.settings.reportSettings.screenOrder.includes(ReportScreenEnum.thgcustomerdetails);
      }
    } catch (error) {
      Vue.$log.error(error);
      return false;
    }
    return false;
  }

  /**
   * @returns the numberplate
   */
  get numberplate() {
    return this._numberplate;
  }

  /**
   * @returns the address
   */
  get address() {
    return this._address;
  }

  /**
   * @returns the banking
   */
  get banking() {
    return this._banking;
  }

  /**
   * @returns the contact
   */
  get contact() {
    return this._contact;
  }

  /**
   * @returns the contact
   */
  get user() {
    return this._user;
  }

  /**
   * @returns the selected impacttypeitem
   */
  get impactTypeItem() {
    return this._impactTypeItems.filter(item => item.impactType === this._impactType)[0];
  }

  /**
   * @returns the transaction id
   */
  get transactionId() {
    return this._transactionId;
  }

  @Mutation
  _mutateVehicleClassPreview(isPreview: boolean) {
    this._vehicleClassPreview = isPreview;
  }

  @Action
  setVehicleClassPreview(isPreview: boolean) {
    this.context.commit("_mutateVehicleClassPreview", isPreview);
  }

  @Mutation
  _mutateVehicleClass(vehicleClass: ThgVehicleTypes | undefined) {
    this._vehicleClass = vehicleClass;
  }

  @Action
  setVehicleClass(vehicleClass: ThgVehicleTypes | undefined) {
    this.context.commit("_mutateVehicleClass", vehicleClass);
  }

  @Mutation
  _mutateIsRegistrationValid(isRegistrationValid: boolean) {
    this._isRegistrationValid = isRegistrationValid;
  }

  @Action
  setIsRegistrationValid(isRegistrationValid: boolean) {
    this.context.commit("_mutateIsRegistrationValid", isRegistrationValid);
  }

  @Mutation
  _mutateTransactionId(transactionId: string) {
    this._transactionId = transactionId;
  }

  @Mutation
  _mutateYears(years: number[]) {
    this._years = years;
  }

  @Action
  setYears(years: number[]) {
    this.context.commit("_mutateYears", years);
  }

  @Mutation
  _mutateSelectedYearId(id: number) {
    this._selectedYearId = id;
  }

  @Action
  setSelectedYearId(id: number) {
    this.context.commit("_mutateSelectedYearId", id);
  }

  @Mutation
  _mutateActiveImpactTypeItems(impactTypes: ImpactTypeEnum[]) {
    this._activeImpacttypes = impactTypes;
  }

  @Action
  setActiveImpactTypeItems(impactTypes: ImpactTypeEnum[]) {
    this.context.commit("_mutateActiveImpactTypeItems", impactTypes);
  }

  @Mutation
  _mutateActivePayoutOptions(payoutOptions: PayoutOptionEnum[]) {
    this._activePayoutOptions = payoutOptions;
  }

  @Action
  setActivePayoutOptions(payoutOptions: PayoutOptionEnum[]) {
    this.context.commit("_mutateActivePayoutOptions", payoutOptions);
  }

  @Mutation
  _mutatePayoutOption(payoutOptions: PayoutOptionEnum) {
    this._payoutOption = payoutOptions;
  }

  @Action
  setPayoutOption(payoutOptions: PayoutOptionEnum) {
    this.context.commit("_mutatePayoutOption", payoutOptions);
  }

  @Mutation
  _mutateIsResubmitTaxnumber(isResubmitting: boolean) {
    this._isResubmitTaxnumber = isResubmitting;
  }

  @Action
  setIsResubmitTaxnumber(isResubmitting: boolean) {
    this.context.commit("_mutateIsResubmitTaxnumber", isResubmitting);
  }

  @Mutation
  _mutateisResubmitAddress(isResubmitting: boolean) {
    this._isResubmitAddress = isResubmitting;
  }

  @Action
  setisResubmitAddress(isResubmitting: boolean) {
    this.context.commit("_mutateisResubmitAddress", isResubmitting);
  }

  @Mutation
  _mutateIsResubmitBankingInformation(isResubmitting: boolean) {
    this._isResubmitBankingInformation = isResubmitting;
  }

  @Action
  setIsResubmitBankingInformation(isResubmitting: boolean) {
    this.context.commit("_mutateIsResubmitBankingInformation", isResubmitting);
  }

  @Mutation
  _mutateIsCompany(isCompany: boolean) {
    this._isCompany = isCompany;
  }

  @Mutation
  _mutateParentId(parentId: string) {
    this._parentId = parentId;
  }

  @Action
  setIsCompany(isCompany: boolean) {
    this.context.commit("_mutateIsCompany", isCompany);
  }

  @Mutation
  _mutateIsExternalCustomer(isExternalCustomer: boolean) {
    this._isExternalCustomer = isExternalCustomer;
  }

  @Action
  setIsExternalCustomer(isExternalCustomer: boolean) {
    this.context.commit("_mutateIsExternalCustomer", isExternalCustomer);
  }

  @Mutation
  _mutateIsValidPassword(isValid: boolean) {
    this._isValidPassoword = isValid;
  }

  @Action
  setIsValidPassword(isValid: boolean) {
    this.context.commit("_mutateIsValidPassword", isValid);
  }

  @Mutation
  _mutateCompany(company: string) {
    this._company = company;
  }

  @Action
  setImpactType(imopactType: ImpactTypeEnum) {
    this.context.commit("_mutateImpactType", imopactType);
  }

  @Mutation
  _mutateImpactType(imopactType: ImpactTypeEnum) {
    this._impactType = imopactType;
  }

  @Action
  setCompany(company: string) {
    this.context.commit("_mutateCompany", company);
  }

  @Mutation
  _mutateTaxnumber(taxnumber: string) {
    this._taxnumber = taxnumber;
  }

  @Action
  setTaxnumber(taxnumber: string) {
    this.context.commit("_mutateTaxnumber", taxnumber);
  }

  @Mutation
  _mutateIsTaxDeductible(isTaxDeductible: boolean) {
    this._isTaxDeductible = isTaxDeductible;
  }

  @Action
  setIsTaxDeductible(isTaxDeductible: boolean) {
    this.context.commit("_mutateIsTaxDeductible", isTaxDeductible);
  }

  @Mutation
  _mutateExternalCustomerNumber(externalCustomerNumber: string) {
    this._externalCustomerNumber = externalCustomerNumber;
  }

  @Action
  setExternalCustomerNumber(externalCustomerNumber: string) {
    this.context.commit("_mutateExternalCustomerNumber", externalCustomerNumber);
  }

  @Mutation
  _mutateIsConfirmed(isConfirmed: boolean) {
    this._isConfirmed = isConfirmed;
  }

  @Action
  setIsConfirmed(isConfirmed: boolean) {
    this.context.commit("_mutateIsConfirmed", isConfirmed);
  }

  @Mutation
  _mutateIsTermsAccepted(isTermsAccepted: boolean) {
    this._isTermsAccepted = isTermsAccepted;
  }

  @Action
  setIsTermsAccepted(loading: boolean) {
    this.context.commit("_mutateIsTermsAccepted", loading);
  }

  @Mutation
  _mutateIsMarketingOptIn(isMarketingOptIn: boolean) {
    this._isMarketingOptIn = isMarketingOptIn;
  }

  @Action
  setIsMarketingOptIn(loading: boolean) {
    this.context.commit("_mutateIsMarketingOptIn", loading);
  }

  @Action
  setParentId(parentId: string) {
    this.context.commit("_mutateParentId", parentId);
  }

  /**
   * Impacttype Id defines the id of the card which is displayed in the view Impactselector
   * @param selectedImpactTypeId
   */
  @Mutation
  _mutateImpactTypeId(selectedImpactTypeId: number) {
    this._selectedImpactTypeId = selectedImpactTypeId;
  }

  @Action
  setImpactTypeId(selectedImpactTypeId: number) {
    this.context.commit("_mutateImpactTypeId", selectedImpactTypeId);
  }

  @Mutation
  _mutateLoading(loading: boolean) {
    this._loading = loading;
  }

  @Action
  setLoading(loading: boolean) {
    this.context.commit("_mutateLoading", loading);
  }

  @Mutation
  _mutateImpactFactor(factor: number) {
    this._impactFactor = factor;
  }

  @Action
  setImpactFactor(factor: number) {
    this.context.commit("_mutateImpactFactor", factor);
  }

  @Action
  setNumberplate(numberplate: string) {
    this.context.commit("_mutateNumberplate", numberplate);
  }

  @Mutation
  _mutateNumberplate(numberplate: string) {
    this._numberplate = numberplate;
  }

  @Action
  setCampaign(code: string) {
    localStorage.setItem(this.LOCAL_CAMPAIGN, code);
  }

  @Action
  setCode(code: string) {
    localStorage.setItem(this.LOCAL_CODE, code);
  }

  @Action
  setPassword(password: string) {
    this.context.commit("_mutatePassword", password);
  }

  @Mutation
  _mutatePassword(password: string) {
    this._password = password;
  }

  @Action
  setUser(user: Partial<IUser>) {
    this.context.commit("_mutateUser", { ...this._user, ...user });
  }

  @Action
  setContact(contact: Partial<IContact>) {
    this.context.commit("_mutateContact", { ...this._contact, ...contact });
  }

  @Action
  setAddress(address: Partial<IAddress>) {
    this.context.commit("_mutateAddress", { ...this._address, ...address });
  }

  @Mutation
  _mutateUser(user: IUser) {
    this._user = user;
  }

  @Mutation
  _mutateAddress(address: IAddress) {
    this._address = address;
  }

  @Mutation
  _mutateContact(contact: IContact) {
    this._contact = contact;
  }

  @Action
  setBanking(banking: Partial<any>) {
    this.context.commit("_mutateBanking", { ...this._banking, ...banking });
    GtagModule.dispatchAddPaymentInfo();
  }

  @Mutation
  _mutateBanking(banking: any) {
    this._banking = banking;
  }

  @Action
  async addRegistrationFront(file: File) {
    const imageResult = await uploadReportImage(
      file,
      ReportImageType.registration,
      ReportModule.partner.id || ReportModule.partner._id
    );
    this.context.commit("addToRegistrationImageFront", imageResult);

    return { imageResult, file };
  }

  @Action
  setRegistrationFront(image: IImageUploaded) {
    this.context.commit("addToRegistrationImageFront", image);
  }

  /**
   *
   * Use This upload function to upload files for selected thg in ThgPortal
   * Is necessary since partenrId is not set in ReportModule if the app is ThgPortal
   *
   * @param file
   */
  @Action
  async updateRegistrationFront(file: File) {
    const imageResult = await uploadReportImage(
      file,
      ReportImageType.registration,
      ThgPortalModule.selectedThg.partnerId
    );
    this.context.commit("addToRegistrationImageFront", imageResult);

    return { imageResult, file };
  }

  /**
   *
   * Use This upload function to upload files for selected thg in ThgPortal
   * Is necessary since partenrId is not set in ReportModule if the app is ThgPortal
   *
   * @param file
   */
  @Action
  async updateRegistrationBack(file: File) {
    const imageResult = await uploadReportImage(
      file,
      ReportImageType.registrationBack,
      ThgPortalModule.selectedThg.partnerId
    );
    this.context.commit("addToRegistrationImageBack", imageResult);

    return { imageResult, file };
  }

  @Action
  async removeRegistrationFront(file: File): Promise<boolean> {
    this.context.commit("removeFromRegistrationImageFront", file);
    return true;
  }

  @Mutation
  addToRegistrationImageFront(file: IImageUploaded) {
    this.registrationImageFront.push(file);
  }

  @Mutation
  removeFromRegistrationImageFront(file: File) {
    const filtered = this.registrationImageFront.filter(x => x.file.name !== file.name);
    this.registrationImageFront = filtered;
  }

  @Action
  async addRegistrationBack(file: File) {
    const imageResult = await uploadReportImage(
      file,
      ReportImageType.registrationBack,
      ReportModule.partner.id || ReportModule.partner._id
    );
    this.context.commit("addToRegistrationImageBack", imageResult);

    return { imageResult, file };
  }

  @Action
  setRegistrationBack(image: IImageUploaded) {
    this.context.commit("addToRegistrationImageBack", image);
  }

  @Action
  async removeRegistrationBack(file: File): Promise<boolean> {
    this.context.commit("removeFromRegistrationImageBack", file);
    return true;
  }

  @Mutation
  addToRegistrationImageBack(file: IImageUploaded) {
    this.registrationImageBack.push(file);
  }

  @Mutation
  removeFromRegistrationImageBack(file: File) {
    const filtered = this.registrationImageBack.filter(x => x.file.name !== file.name);
    this.registrationImageBack = filtered;
  }

  @Action
  async resetRegistrationImageBackAction(): Promise<boolean> {
    this.context.commit("resetRegistrationImageBack");
    return true;
  }

  @Action
  async resetRegistrationImageFrontAction(): Promise<boolean> {
    this.context.commit("resetRegistrationImageFront");
    return true;
  }

  @Action
  resetRegistrationImages() {
    this.context.commit("resetRegistrationImageFront");
    this.context.commit("resetRegistrationImageBack");
  }

  @Mutation
  resetRegistrationImageFront() {
    this.registrationImageBack = [];
  }

  @Mutation
  resetRegistrationImageBack() {
    this.registrationImageFront = [];
  }

  @Action
  resetThg() {
    this._mutateTransactionId(generateCode());
    this._mutateContact(deepCopy(this._EMPTY_CONTACT));
    this._mutateAddress(deepCopy(this._EMPTY_ADDRESS));
    this._mutateBanking(deepCopy(this._EMPTY_BANKING));
    this._mutateUser(deepCopy(this._EMPTY_USER));
    this._mutateIsMarketingOptIn(false);
    this._mutateIsTermsAccepted(false);
    this._mutateIsConfirmed(false);
    this._mutateNumberplate("");
    this._mutateImpactTypeId(0);
    this._mutatePayoutOption(PayoutOptionEnum.REGULAR);
    this._mutateImpactType(this.baseImpactItemType);
    this._mutateImpactFactor(this.baseImpactFactor);
    this._mutateCompany("");
    this._mutateTaxnumber("");
    this._mutatePassword("");
    this._mutateIsCompany(false);
    this._mutateIsResubmitBankingInformation(false);
    this._mutateIsResubmitTaxnumber(false);
    this.resetRegistrationImages();
    this.setParentId("");
  }

  /**
   * Same as normal reset, but images are not reset
   */
  @Action
  resetThgForQuickCheckout() {
    this._mutateTransactionId(generateCode());
    this._mutateContact(deepCopy(this._EMPTY_CONTACT));
    this._mutateAddress(deepCopy(this._EMPTY_ADDRESS));
    this._mutateBanking(deepCopy(this._EMPTY_BANKING));
    this._mutateUser(deepCopy(this._EMPTY_USER));
    this._mutateIsMarketingOptIn(false);
    this._mutateIsTermsAccepted(false);
    this._mutateIsConfirmed(false);
    this._mutateNumberplate("");
    this._mutatePayoutOption(PayoutOptionEnum.REGULAR);
    this._mutateImpactTypeId(0);
    this._mutateImpactType(this.baseImpactItemType);
    this._mutateImpactFactor(this.baseImpactFactor);
    this._mutateCompany("");
    this._mutateTaxnumber("");
    this._mutatePassword("");
    this._mutateIsCompany(false);
    this._mutateIsResubmitBankingInformation(false);
    this._mutateIsResubmitTaxnumber(false);
    this.setParentId("");
  }

  /**
   * Submits a new THG and attempts to create a new user.
   * @throws ConflictException if the user exists
   * @throws HttpException if server response is non 2xx.
   */
  @Action
  async submit(): Promise<void> {
    this.address.countryCode = PartnerModule.partner.countryCode;

    const thgModel: ThgCreateThgAndUserDtoGen = {
      insurance: undefined,
      createUserDto: {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        // lowercase and precautiously replace all whitespace
        userName: this.contact.email.toLowerCase().replace(/\s/g, ""),
        contact: {
          email: this.contact.email.toLowerCase().replace(/\s/g, ""),
          phone: this.contact.phone || "-"
        },
        address: this.address,
        isMarketingOptIn: this.isMarketingOptIn,
        isTermsAccepted: this.isTermsAccepted,
        countryCode: ReportModule.partner.countryCode || CountryCodeEnum.germany
      },
      thg: {
        numberplate: this.numberplate,
        impactType: this.impactType,
        impactFactor: this.impactFactor,
        payoutOption: this.payoutOption,
        registrationImages: [],
        isConfirmed: this._isConfirmed,
        years: this._years
      },
      campaign: this.campaign,
      kownFrom: KownFromEnum.OTHER,
      password: this._password
    };

    if (this.isExternalCustomer && ReportModule.partner.companyName && this.isExternalCustomerScreenAvailable) {
      thgModel.insurance = {
        name: ReportModule.partner.companyName,
        number: this.externalCustomerNumber || "",
        isActive: true
      };
    }

    for (const image of this.registrationImageFront) {
      thgModel.thg.registrationImages.push(image.uploadId);
    }

    for (const image of this.registrationImageBack) {
      thgModel.thg.registrationImages.push(image.uploadId);
    }

    if (!this.isResubmitAddress && this.address.street && this.address.zip && this.address.city) {
      thgModel.createUserDto.address = this.address;
    }

    if (this.showBankingInfo && !this.isResubmitBankingInformation) {
      thgModel.banking = this.banking;
      const formatedIban = electronicFormatIBAN(this.banking.iban);
      thgModel.banking.iban = formatedIban || this.banking.iban;
    }

    if (this.isCompany) {
      thgModel.createUserDto.isCompany = this.isCompany;
      thgModel.createUserDto.company = this._company;

      if (this.isTaxDeductible) {
        thgModel.createUserDto.isTaxDeductible = this.isTaxDeductible;

        if (!this.isResubmitTaxnumber) {
          thgModel.createUserDto.taxnumber = this.taxnumber;
        } else {
          thgModel.createUserDto.taxnumber = "";
        }
      } else {
        thgModel.createUserDto.isTaxDeductible = false;
        thgModel.createUserDto.taxnumber = "";
      }
    } else {
      thgModel.createUserDto.isTaxDeductible = false;
      thgModel.createUserDto.isCompany = false;
      thgModel.createUserDto.company = "";
    }

    if (this.code) {
      thgModel.thg.code = this.code;
    }

    await thgService.createNewThgAndUserRegistration(ReportModule.partner.id || ReportModule.partner._id, thgModel);

    await GtagModule.setUserData();
    GtagModule.dispatchSignupEvent();
    GtagModule.dispatchPurchaseEventVehicle();

    this.resetRegistrationImages();
  }

  @Action
  async submitIfAuthenticated() {
    const thg: ThgCreateThgVehicleDtoGen = {
      numberplate: this.numberplate,
      impactType: this.impactType,
      impactFactor: this.impactFactor,
      payoutOption: this.payoutOption,
      registrationImages: [],
      isConfirmed: this._isConfirmed,
      years: this._years
    };

    for (const image of this.registrationImageFront) {
      thg.registrationImages.push(image.uploadId);
    }

    for (const image of this.registrationImageBack) {
      thg.registrationImages.push(image.uploadId);
    }

    if (this.code) {
      thg.code = this.code;
    }

    await thgService.addThg(ReportModule.partner.id || ReportModule.partner._id, thg);

    await GtagModule.setUserData();
    GtagModule.dispatchPurchaseEventVehicle();

    this.resetThg();
  }

  @Action
  async verifyEmail(code: string) {
    const dto: VerifyAuthDto = {
      username: this.contact.email.toLowerCase().replace(/\s/g, ""),
      code: code
    };

    await authService.verifyEmail(dto);
  }

  @Action
  async createThgMe() {
    /**
     * If user confirms the registration images are up to date we don't want to submit new registration images
     */
    if (this.isRegistrationValid) {
      ThgCreateModule.resetRegistrationImages();
    }

    const createDto: ThgCreateThgMeDtoGen = {
      impactType: this.impactType,
      impactFactor: this.impactFactor,
      registrationImages: [],
      isConfirmed: this._isConfirmed,
      years: this._years,
      campaign: this.campaign,
      code: this.code,
      partnerId: PartnerModule.partner.id || PartnerModule.partner._id
    };

    for (const image of this.registrationImageFront) {
      createDto.registrationImages?.push(image.uploadId);
    }

    for (const image of this.registrationImageBack) {
      createDto.registrationImages?.push(image.uploadId);
    }

    const thg = await thgService.createMyThg(this.parentId, createDto);

    await GtagModule.setUserData();
    GtagModule.dispatchPurchaseEventVehicle();

    return thg;
  }

  /**
   * Submits a new THG for a given user.
   *
   * @throws ConflictException if the user exists
   * @throws HttpException if server response is non 2xx.
   */
  @Action
  async createAnonymous(data: {
    partnerId: string;
    userNotification?: boolean;
    thgQuote: ThgCreateAnonymousThgDtoGen;
  }): Promise<ThgThgViewModelGen> {
    const thg = await thgService.createAnonymous(data.partnerId, data.userNotification, data.thgQuote);
    this.resetRegistrationImages();

    return thg;
  }
}

export async function uploadReportImage(file: File, type: ReportImageType, partnerId: string): Promise<IImageUploaded> {
  if (!partnerId) {
    throw Error("Partner not set.");
  }

  if (!file) {
    throw Error("File to upload missing.");
  }

  if (!type) {
    throw Error("Image Type missing.");
  }

  let imageResult: IImageUploaded;

  try {
    imageResult = await thgService.addImage(file, type, partnerId);
    return imageResult;
  } catch (error) {
    Vue.$log.error(error);
    const e = error as any;
    if (e.error) {
      if (e.error.statusCode == 400) {
        Vue.$toast.error("Fehler: Der Service ist zur Zeit nicht zur Verfügung.");
      }

      if (e.error.statusCode == 500) {
        Vue.$toast.error("Fehler: Der Service ist zur Zeit nicht zur Verfügung.");
      }
    }
    throw error;
  }
}

export const ThgCreateModule = getModule(ThgCreateStore);
