import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";

export class PartnerColor {
  private _DARKEN_1 = -0.2;
  private _DARKEN_2 = -0.4;
  private _DARKEN_3 = -0.6;
  private _LIGTHEN_1 = 0.2;
  private _LIGTHEN_2 = 0.4;
  private _LIGTHEN_3 = 0.6;

  private _primary = "";
  private _secondary = "";
  private _gradientStart = "";
  private _gradientEnd = "";

  constructor() {
    this._primary =
      ReportModule.partner?.settings?.color || PartnerModule.partner?.settings?.color || ConfigModule.color.primary;
    this._gradientStart =
      ReportModule.partner?.settings?.reportSettings.firstColor ||
      PartnerModule.partner?.settings?.reportSettings.firstColor ||
      ConfigModule.color.primary;

    this._gradientEnd =
      ReportModule.partner?.settings?.reportSettings.secondColor ||
      PartnerModule.partner?.settings?.reportSettings.secondColor ||
      ConfigModule.color.primary;

    this._secondary =
      ReportModule.partner?.settings?.reportSettings.secondColor ||
      PartnerModule.partner?.settings?.reportSettings.secondColor ||
      ConfigModule.color.secondary;
  }

  get primary(): string {
    return this._primary;
  }

  get primaryDarken_1(): string {
    return this.shadeColor(this._primary, this._DARKEN_1);
  }

  get primaryDarken_2(): string {
    return this.shadeColor(this._primary, this._DARKEN_2);
  }

  get primaryDarken_3(): string {
    return this.shadeColor(this._primary, this._DARKEN_3);
  }

  get primaryLighten_1(): string {
    return this.shadeColor(this._primary, this._LIGTHEN_1);
  }

  get primaryLighten_2(): string {
    return this.shadeColor(this._primary, this._LIGTHEN_2);
  }

  get primaryLighten_3(): string {
    return this.shadeColor(this._primary, this._LIGTHEN_3);
  }

  get secondary(): string {
    return this._secondary;
  }

  get secondaryDarken_1(): string {
    return this.shadeColor(this._secondary, this._DARKEN_1);
  }

  get secondaryDarken_2(): string {
    return this.shadeColor(this._secondary, this._DARKEN_2);
  }

  get secondaryDarken_3(): string {
    return this.shadeColor(this._secondary, this._DARKEN_3);
  }

  get secondaryLighten_1(): string {
    return this.shadeColor(this._secondary, this._LIGTHEN_1);
  }

  get secondaryLighten_2(): string {
    return this.shadeColor(this._secondary, this._LIGTHEN_2);
  }

  get secondaryLighten_3(): string {
    return this.shadeColor(this._secondary, this._LIGTHEN_3);
  }

  get gradientStart(): string {
    return this._gradientStart;
  }

  get gradientStartDarken_1(): string {
    return this.shadeColor(this._gradientStart, this._DARKEN_1);
  }

  get gradientStartDarken_2(): string {
    return this.shadeColor(this._gradientStart, this._DARKEN_2);
  }

  get gradientStartDarken_3(): string {
    return this.shadeColor(this._gradientStart, this._DARKEN_3);
  }

  get gradientStartLighten_1(): string {
    return this.shadeColor(this._gradientStart, this._LIGTHEN_1);
  }

  get gradientStartLighten_2(): string {
    return this.shadeColor(this._gradientStart, this._LIGTHEN_2);
  }

  get gradientStartLighten_3(): string {
    return this.shadeColor(this._gradientStart, this._DARKEN_3);
  }

  get gradientEnd() {
    return this._gradientEnd;
  }

  get gradientEndDarken_1(): string {
    return this.shadeColor(this._gradientEnd, this._DARKEN_1);
  }

  get gradientEndDarken_2(): string {
    return this.shadeColor(this._gradientEnd, -this._DARKEN_2);
  }

  get gradientEndDarken_3(): string {
    return this.shadeColor(this._gradientEnd, this._DARKEN_3);
  }

  get gradientEndLighten_1(): string {
    return this.shadeColor(this._gradientEnd, this._LIGTHEN_1);
  }

  get gradientEndLighten_2(): string {
    return this.shadeColor(this._gradientEnd, this._LIGTHEN_2);
  }

  get gradientEndLighten_3(): string {
    return this.shadeColor(this._gradientEnd, this._LIGTHEN_3);
  }

  get gradientStyle() {
    return `background-image: linear-gradient(to right, ${this._gradientStart}, ${this._gradientEnd};`;
  }

  /**
   * @param decimal lighten or darken decimal value, example 0.5 to lighten by 50% or 1.5 to darken by 50%.
   */
  shadeColor(color: string, decimal: number): string {
    try {
      return pSBC(decimal, color, "", "true") as string;
    } catch {
      return this._primary;
    }
  }
}

/**
 *
 * Makes a color daker or brighter. for More informations see:
 * https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#micro-functions-version-4
 *
 * @param p
 * @param c0
 * @param c1
 * @param l
 * @returns
 */
const pSBC = (p: number, c0: string, c1: string, l: string) => {
  let r, g, b, P, f, t, h;
  let a = 0;

  const m = Math.round;
  if (typeof p != "number" || p < -1 || p > 1 || typeof c0 != "string" || (c0[0] != "r" && c0[0] != "#") || (c1 && !a))
    return null;
  (h = c0.length > 9),
    (h = a ? (c1.length > 9 ? true : c1 == "c" ? !h : false) : h),
    (f = pSBC.pSBCr(c0)),
    (P = p < 0),
    (t = c1 && c1 != "c" ? pSBC.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }),
    (p = P ? p * -1 : p),
    (P = 1 - p);
  if (!f || !t) return null;
  if (l) (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b));
  else
    (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
      (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
      (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));

  (a = f.a), (t = t.a), (f = a >= 0 || t >= 0), (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
  if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
  else
    return (
      "#" +
      (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
    );
};

/**
 *
 * @param d
 * @returns
 */
pSBC.pSBCr = (d: string) => {
  const i = parseInt;
  let n = d.length;
  const x = {
    r: 0,
    g: 0,
    b: 0,
    a: 0
  };

  if (n > 9) {
    const [r, g, b, a] = ((d as any) = d.split(","));
    n = d.length;
    if (n < 3 || n > 4) return null;
    (x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4))), (x.g = i(g)), (x.b = i(b)), (x.a = a ? parseFloat(a) : -1);
  } else {
    if (n == 8 || n == 6 || n < 4) return null;
    if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
    d = i(d.slice(1), 16) as any;
    if (n == 9 || n == 5)
      (x.r = ((d as any) >> 24) & 255),
        (x.g = ((d as any) >> 16) & 255),
        (x.b = ((d as any) >> 8) & 255),
        (x.a = Math.round(((d as any) & 255) / 0.255) / 1000);
    else (x.r = (d as any) >> 16), (x.g = ((d as any) >> 8) & 255), (x.b = (d as any) & 255), (x.a = -1);
  }
  return x as { r: number; g: number; b: number; a: number };
};
