/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Possible types that can be filtered
 */
export enum PageFilterTypes {
  OBJECT_ID = "ObjectID",
  BOOLEAN = "Boolean",
  STRING = "String",
  NUMBER = "Number",
  ENUM = "Enum",
  DATE = "Date",

  /**
   * To filter a value of a CustomFieldValueEntity
   */
  CUSTOM_FIELD_DATE_VALUE = "CustomFieldDateValue",
  CUSTOM_FIELD_NUMBER_VALUE = "CustomFieldNumberValue",
  CUSTOM_FIELD_VALUE = "CustomFieldValue"
}
