






import { ConfigModule } from "@/store/modules/config";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TemplateEditorActionDivider extends Vue {
  get isDarkMode() {
    return ConfigModule.darkMode;
  }
}
