

































import Card from "@/components/utility/Card.vue";
import FileInput from "@/components/utility/FileInput.vue";
import { maxSizeRule } from "@/lib/rules/maxSizeRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IReport } from "@/models/report.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";
import { TimestampDocument } from "@/models/timestamp.entity";

@Component({
  components: {
    Card,
    FileInput
  }
})
export default class ReportDocumentSignRequestStepperUpload extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  @Prop()
  report?: IReport;

  file: File = {} as any;

  title = this.report ? `${this.report.numberplate} - ${this.report.customerName}` : "";

  MAX_FILE_SIZE_IN_MB = 5;

  disabled = false;

  get maxSizeRule() {
    return [requiredRule(), maxSizeRule(this.MAX_FILE_SIZE_IN_MB * 1024 * 1024)];
  }

  get requiredRule() {
    return [requiredRule()];
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.CHOICE;
  }

  @Watch("file")
  watchFile() {
    if (this.file.name && !this.title) {
      this.title = this.file.name;
    }
  }

  async next() {
    this.isLoading = true;
    const basePdf: string | ArrayBuffer | Uint8Array = URL.createObjectURL(this.file);
    const signDocument: ISignDocument = new SignDocument({
      url: basePdf,
      title: this.title,
      name: this.file.name,
      partnerId: this.$route.params.partnerId,
      timestamp: new TimestampDocument({
        created: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        modified: [new Date().toISOString()]
      })
    });
    this.$emit("setSignDocument", signDocument);
    this.currentStep = SignRequestStepperPageEnum.SIGNATURE_SELECTION;
    this.isLoading = false;
  }
}
