












































































































import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IReference, Reference } from "@/models/reference.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { SignDocumentDataAccessLayer } from "@/store/modules/access-layers/sign-document.access-layer";
import DocumentSelectionDialog from "@/views/sign/DocumentSelectionDialog.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import draggable from "vuedraggable";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { isMobile } from "@/lib/utility/isMobile";
import MDetailTable from "../utility/mmmint/MDetailTable.vue";
import { IAction } from "../utility/mmmint/MHeader.vue";
import { $t } from "@/lib/utility/t";
import { GoToHelper } from "@/lib/utility/goToHelper";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { handleError } from "@/lib/utility/handleError";
import RefsDocument from "@/components/utility/RefsDocument.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import FileInputMixin from "@/components/utility/FileInputMixin.vue";

@Component({
  components: {
    draggable,
    LatestEntriesCardEmpty,
    DocumentSelectionDialog,
    MDetailTable,
    ConfirmActionDialog,
    RefsDocument,
    Tooltip
  }
})
export default class CostCardDocuments extends mixins(PartnerFallbackMixin, FileInputMixin) {
  @Prop()
  value?: string[];

  @Prop()
  partnerId!: string;

  @Prop()
  costId!: string;

  @Prop()
  loading!: boolean;

  @Prop({ default: false })
  disabledDragAndDrop!: boolean;

  removeIndex = -1;

  isDocumentDialogActive = false;

  isRemoveDocumentDialogActive = false;

  isLoadingCreation = false;

  documents: { document: ISignDocument | null; isLoading: boolean }[] = [];

  get dropFieldDimensions() {
    const w = document.getElementById("docsContainer")?.clientWidth;
    const h = document.getElementById("docsContainer")?.clientHeight;
    return { w, h };
  }

  get costTableAction(): IAction {
    return {
      text: $t("components.fleet.FleetVehicleDetailContractTable.openNew"),
      key: "openInNew",
      icon: "open-in-new"
    };
  }

  get isMobile() {
    return isMobile();
  }

  get documentIds() {
    return this.value ?? [];
  }

  set documentIds(ids: string[]) {
    this.$emit("input", ids);
  }

  get documentReference(): IReference | undefined {
    if (!this.costId) return undefined;

    return new Reference({
      refId: this.costId,
      refType: BackendResourceEnum.COST
    });
  }

  async beforeMount() {
    if (!this.value) {
      return;
    }

    this.documents.push(...this.value.map(() => ({ document: null, isLoading: true })));
    let index = 0;
    for (const documentId of this.value) {
      try {
        const document = await (
          SignDocumentDataAccessLayer.maps.id.get(documentId)[0] ??
          new SignDocument({ id: documentId, partnerId: this.partnerId })
        ).fetch();
        this.documents.splice(index, 1, { document, isLoading: false });
      } catch (e) {
        this.$log.error(e);
        this.documents.splice(index, 1, { document: null, isLoading: false });
      }
      index++;
    }
  }

  startDrag() {
    if (this.disabledDragAndDrop) return;
    this.isDrag = true;
  }

  endDrag() {
    this.isDrag = false;
  }

  drop(event: DragEvent) {
    if (this.disabledDragAndDrop) return;
    this.onFileDrop(event);
  }

  async handleFileHook(file: File) {
    const signDocument = new SignDocument({
      name: file.name,
      title: file.name,
      isTemplate: false,
      folder: `/cost/${this.costId}`,
      refs: this.documentReference ? [this.documentReference] : [],
      file: file,
      partnerId: this.partnerId
    });

    this.isLoadingCreation = true;
    await signDocument.create().catch(handleError);
    this.isLoadingCreation = false;

    this.onCreated(signDocument);
  }

  goToDocumentDetail(document: ISignDocument) {
    new GoToHelper(this.$router).goToDocumentDetail(document.id, document.partnerId, true);
  }

  processAction(action: IAction, document: ISignDocument) {
    if (action.key === "openInNew") {
      new GoToHelper(this.$router).goToDocumentDetail(document.id, document.partnerId, true);
    }
  }

  startRemove(removeIndex: number) {
    this.removeIndex = removeIndex;
    this.isRemoveDocumentDialogActive = true;
  }

  removeDocument() {
    this.documents.splice(this.removeIndex, 1);
    this.documentIds.splice(this.removeIndex, 1);
    this.isRemoveDocumentDialogActive = false;
  }

  async onCreated(document: ISignDocument) {
    try {
      this.isLoadingCreation = true;
      await document.fetch();
      this.documents.push({ document, isLoading: false });
      const documentIds = this.documents.map(d => d.document?.id).filter(id => id) as string[];
      this.documentIds = documentIds;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingCreation = false;
    }

    this.$emit("created", document);
  }

  handleOrderChange() {
    this.documentIds = this.documents.map(d => d.document?.id).filter(id => id) as string[];
  }
}
