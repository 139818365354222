import { render, staticRenderFns } from "./TheThgPartnerList.vue?vue&type=template&id=23d1e65a&scoped=true&"
import script from "./TheThgPartnerList.vue?vue&type=script&lang=ts&"
export * from "./TheThgPartnerList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d1e65a",
  null
  
)

export default component.exports