




















import LoginComponentInputCode from "@/components/login/LoginComponentInputCode.vue";
import LoginComponentInputMail from "@/components/login/LoginComponentInputMail.vue";
import LoginComponentLayout from "@/components/login/LoginComponentLayout.vue";
import LoginComponentMessage from "@/components/login/LoginComponentMessage.vue";
import LoginComponentNavigationButton from "@/components/login/LoginComponentNavigationButton.vue";
import LoginComponentSubmitButton from "@/components/login/LoginComponentSubmitButton.vue";
import LoginErrorMixin from "@/mixins/LoginErrorMixin.vue";
import LoginMixin, { IFailureCodeContent } from "@/mixins/LoginMixin.vue";
import {
  MrfiktivUserAuthDtoGen as UserAuthDto,
  MrfiktivVerifyAuthDtoGen as VerifyAuthDto
} from "@/services/mrfiktiv/v1/data-contracts";
import { LoginModule } from "@/store/modules/login.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Mixins } from "vue-property-decorator";

@Component({
  components: {
    LoginComponentInputMail,
    LoginComponentInputCode,
    LoginComponentMessage,
    LoginComponentSubmitButton,
    LoginComponentNavigationButton,
    LoginComponentLayout
  }
})
export default class LoginCardConfirmMail extends Mixins(LoginMixin, LoginErrorMixin) {
  customFailureCodes = new Map<string, IFailureCodeContent>([
    [
      "Invalid code provided, please request a code again.",
      { message: "components.login.LoginCardConfirmMail.failConfirmationCodeInvalid" }
    ]
  ]);

  mounted() {
    this.registerCustomFailureCodes(this.customFailureCodes);
  }

  /**
   * Send mail confirmation
   */
  async submit() {
    try {
      const verifyAuthDto: VerifyAuthDto = {
        username: this.mail,
        code: this.code.trim()
      };
      this.isLoading = true;
      await UserModule.confirmMail(verifyAuthDto);
      this.isFormSubmitted = true;

      if (LoginModule.password) {
        const userAuthDto: UserAuthDto = {
          username: this.mail,
          password: this.password
        };
        await UserModule.logIn(userAuthDto);
        await this.checkIfUserIsLoggedInAndRedirect();
      } else {
        await this.handleSuccess("/login");
      }
    } catch (error) {
      this.handleFail(error);
    } finally {
      this.isLoading = false;
    }
  }
}
