



































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import ProfileFormFields from "./ProfileFormFields.vue";

@Component({
  components: { ProfileFormFields }
})
export default class ProfileConfirmationRow extends DarkModeHighlightMixin {
  @Prop()
  isLoading!: boolean;

  @Prop()
  valid!: boolean;

  save() {
    this.$emit("save");
  }

  abort() {
    this.$emit("abort");
  }
}
