/**
 * Creates an Deep Copy of an nested object and returns an clone with no references to the object transformed.
 * All object methods etc. will belost. This Function only works for data objects
 *
 * @param item
 * @returns
 */
export function deepCopy<T>(item: T): T {
  return JSON.parse(JSON.stringify(item));
}
