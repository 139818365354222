

















import { Component, Prop, Vue } from "vue-property-decorator";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";

@Component({})
export default class BookingStartHowToBook extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  @Prop({ default: false })
  dark!: boolean;

  get color() {
    return getDefaultPartnerColor();
  }

  /**
   * Items for the banner
   * The banner shows the steps of the booking process as per the new design.
   */
  get items() {
    return [
      {
        title: this.$t("bookingForm.BookingStart.item1.title")
      },
      {
        title: this.$t("bookingForm.BookingStart.item2.title")
      },
      {
        title: this.$t("bookingForm.BookingStart.item3.title")
      }
    ];
  }
}
