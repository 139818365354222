/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum ProjectCustomViewFieldEnum {
  /**
   * Refers to an value of the Ticket itself, e.g state
   */
  PROPERTY = "property",

  /**
   * Is a reference, e.g damage or vehicle
   */
  REF = "ref",

  /**
   * Refres to a custom field
   */
  CUSTOM_FIELD = "customField"
}
