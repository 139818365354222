










import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";

@Component({ components: { TheNavigationLayout } })
export default class TheThgPartnerList extends mixins(PermissionMixin) {
  loading = false;

  get partnerId() {
    return PartnerModule?.partner?._id;
  }

  get partnerItems(): IMenuListItem[] {
    const items: IMenuListItem[] = [
      {
        icon: "mdi-view-dashboard-outline",
        text: "navigation.TheThgAdminList.overview",
        link: `/partners/${this.partnerId}/home`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.PARTNER,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-car-outline",
        text: "navigation.TheThgAdminList.electricVehicles",
        link: `/partners/${this.partnerId}/electric-vehicle`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.CUSTOMER_DATA,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-ev-station",
        text: "navigation.TheThgAdminList.chargingCurrent",
        link: `/partners/${this.partnerId}/charging-detail`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.CUSTOMER_DATA,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-table-account",
        text: "navigation.TheThgAdminList.customers",
        link: `/partners/${this.partnerId}/customer`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.CUSTOMER_DATA,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-car-outline",
        text: "navigation.TheThgAdminList.thg",
        link: `/partners/${this.partnerId}/thg`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.THG,
          action: ActionEnum.MANAGE,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-email-edit-outline",
        text: "navigation.ThePartnerList.setting.templates",
        link: `/partners/${this.partnerId}/template`,
        isEnabled: FeatureModule.isTemplateEnabled,
        permission: {
          resource: ResourceEnum.TEMPLATE,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-cash-multiple",
        text: "navigation.ThePartnerList.setting.partnerBilling",
        link: `/partners/${this.partnerId}/billing`,
        isEnabled: FeatureModule.isPartnerBillingOverviewEnabled,
        permission: {
          resource: ResourceEnum.BILLING,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      },
      {
        icon: "mdi-comment-question-outline",
        text: "navigation.ThePartnerList.setting.faq",
        link: `/partners/${this.partnerId}/faq`,
        isEnabled: true,
        permission: {
          resource: ResourceEnum.PARTNER,
          action: ActionEnum.READ,
          field: this.partnerId
        }
      }
    ];

    return items;
  }

  get affiliateItems(): IMenuListItem[] {
    return [
      {
        icon: "mdi-gift-outline",
        text: "navigation.TheThgAdminList.affiliates",
        link: `/partners/${this.partnerId}/affiliate`,
        isEnabled: true
      }
    ];
  }
}
