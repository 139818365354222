import { ImageHttpClientProvider } from "../image-http-client.provider";
import { PublicImageCreatePublicImageDtoGen, PublicImageImageViewmodelGen } from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";
import { PublicImageUpload } from "../v1/PublicImageUpload";

/**
 * Communicates with thg backend
 */
class PublicImageService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: PublicImageUpload;

  /**
   * @class Initialize ThgService
   */
  constructor(clientProvider: ImageHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new PublicImageUpload(this.client);
  }

  async upload(dto: PublicImageCreatePublicImageDtoGen): Promise<PublicImageImageViewmodelGen> {
    const uploadedImage = await this.proxy.publicImageControllerUpload(dto);

    return uploadedImage.data;
  }
}

export default new PublicImageService(new ImageHttpClientProvider());
