/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SignCreateSignRequestDtoGen,
  SignDigitalSignatureRequestViewModelGen,
  SignExceptionViewmodelGen,
  SignPageViewModelGen,
  SignSignRequestControllerFindAllParamsGen,
  SignUpdateSignRequestDtoGen,
  SignUpdateSignRequestStatusDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class DigitalSignatureRequestService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Digital Signature Request Service
   * @name SignRequestControllerCreate
   * @summary Create a sign request for a partner
   * @request POST:/partner/{partnerId}/sign-request
   * @secure
   * @response `200` `SignDigitalSignatureRequestViewModelGen` The created sign request
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signRequestControllerCreate = (partnerId: string, data: SignCreateSignRequestDtoGen, params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureRequestViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Request Service
   * @name SignRequestControllerFindAll
   * @summary Get all sign requests from a partner
   * @request GET:/partner/{partnerId}/sign-request
   * @secure
   * @response `200` `(SignPageViewModelGen & { data?: (SignDigitalSignatureRequestViewModelGen)[] })`
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signRequestControllerFindAll = (
    { partnerId, ...query }: SignSignRequestControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      SignPageViewModelGen & { data?: SignDigitalSignatureRequestViewModelGen[] },
      SignExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/sign-request`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Request Service
   * @name SignRequestControllerFindOne
   * @summary Get a sign request from a partner
   * @request GET:/partner/{partnerId}/sign-request/{id}
   * @secure
   * @response `200` `SignDigitalSignatureRequestViewModelGen` The sign request
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signRequestControllerFindOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureRequestViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Request Service
   * @name SignRequestControllerUpdate
   * @summary Update a sign request from a partner
   * @request PATCH:/partner/{partnerId}/sign-request/{id}
   * @secure
   * @response `200` `SignDigitalSignatureRequestViewModelGen` The sign request document
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signRequestControllerUpdate = (
    partnerId: string,
    id: string,
    data: SignUpdateSignRequestDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDigitalSignatureRequestViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Request Service
   * @name SignRequestControllerRemove
   * @summary Delete a sign request from a partner
   * @request DELETE:/partner/{partnerId}/sign-request/{id}
   * @secure
   * @response `200` `SignDigitalSignatureRequestViewModelGen` The deleted sign request
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signRequestControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureRequestViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Request Service
   * @name SignRequestControllerUpdateStatus
   * @summary (UPDATE - SIGN) Update a status of a sign request from a partner
   * @request PATCH:/partner/{partnerId}/sign-request/{id}/status
   * @secure
   * @response `200` `SignDigitalSignatureRequestViewModelGen` The updated sign request document
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signRequestControllerUpdateStatus = (
    partnerId: string,
    id: string,
    data: SignUpdateSignRequestStatusDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDigitalSignatureRequestViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${id}/status`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
