import { FeatureModule } from "@/store/modules/feature.store";

export class CreateSignatureUrlFactory {
  constructor(
    private readonly baseUrl: string,
    private readonly signatureId: string,
    private readonly firstName?: string,
    private readonly lastName?: string
  ) {}

  /**
   * Creates the url to the signature and appends the specified query params
   */
  build(): string {
    let url = `https://${this.baseUrl}/`;
    if (FeatureModule.isSignatureFormActive) {
      url = `${url}sign.html`;
    }
    url = `${url}#/sign/${this.signatureId}?`;
    if (this.firstName) {
      url += "firstName" + "=" + encodeURI(this.firstName) + "&";
    }
    if (this.lastName) {
      url += "lastName" + "=" + encodeURI(this.lastName);
    }

    return url;
  }
}
