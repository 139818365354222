var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "full"
  }, [!_vm.isMobile && _vm.pages.length > 1 ? _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('document-template-detail-page-list', {
    attrs: {
      "outlined": false,
      "pages": _vm.pages,
      "currentPageNumber": _vm.currentPageNumber
    },
    on: {
      "setCurrentPage": _vm.setCurrentPage
    }
  })], 1) : _vm._e(), !_vm.isMobile ? _c('v-col', {
    staticClass: "full",
    attrs: {
      "cols": "5"
    }
  }, [_vm.isLoading ? _c('v-skeleton-loader', {
    staticClass: "ma-auto",
    attrs: {
      "type": "image"
    }
  }) : _c('v-img', {
    staticClass: "full",
    attrs: {
      "src": _vm.images[_vm.currentPageNumber],
      "contain": ""
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": _vm.isMobile ? 12 : _vm.pages.length > 1 ? 6 : 7
    }
  }, [_c('v-form', {
    ref: "signDocumentForm",
    staticClass: "ma-1",
    staticStyle: {
      "height": "100%",
      "overflow-y": "auto",
      "overflow-x": "hidden"
    },
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-row', {
    staticClass: "pt-4"
  }, [_c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.template.dialog.preview.sender'),
      "multiple": false,
      "disabled": "",
      "hide-selected": "",
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.partner.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.partner, "companyName", $$v);
      },
      expression: "partner.companyName"
    }
  })], 1), _c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.createSignRequestDto ? _c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('sign.SignRequestForm.email') + ' *',
      "hint": _vm.$t('sign.SignRequestForm.emailHint'),
      "persistent-hint": "",
      "outlined": ""
    },
    model: {
      value: _vm.createSignRequestDto.recipient.email,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequestDto.recipient, "email", $$v);
      },
      expression: "createSignRequestDto.recipient.email"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12"
    }
  }, [_vm.createSignRequestDto ? _c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRule,
      "label": _vm.$t('sign.SignRequestForm.title') + ' *',
      "hint": _vm.$t('sign.SignRequestForm.titleHint'),
      "outlined": "",
      "persistent-hint": ""
    },
    model: {
      value: _vm.createSignRequestDto.title,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequestDto, "title", $$v);
      },
      expression: "createSignRequestDto.title"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.createSignRequestDto ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('sign.SignRequestForm.firstName'),
      "outlined": ""
    },
    model: {
      value: _vm.createSignRequestDto.recipient.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequestDto.recipient, "firstName", $$v);
      },
      expression: "createSignRequestDto.recipient.firstName"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_vm.createSignRequestDto ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('sign.SignRequestForm.lastName'),
      "outlined": ""
    },
    model: {
      value: _vm.createSignRequestDto.recipient.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequestDto.recipient, "lastName", $$v);
      },
      expression: "createSignRequestDto.recipient.lastName"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12"
    }
  }, [_vm.createSignRequestDto ? _c('v-textarea', {
    attrs: {
      "label": _vm.$t('sign.SignRequestForm.description'),
      "hint": _vm.$t('sign.SignRequestForm.descriptionHint'),
      "persistent-hint": "",
      "outlined": ""
    },
    model: {
      value: _vm.createSignRequestDto.description,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequestDto, "description", $$v);
      },
      expression: "createSignRequestDto.description"
    }
  }) : _vm._e()], 1), false ? _c('v-col', {
    staticClass: "mb-n6",
    attrs: {
      "cols": "12"
    }
  }, [_vm.createDocumentDto ? _c('v-file-input', {
    staticClass: "pt-4 pb-4",
    attrs: {
      "prepend-icon": "",
      "accept": "application/pdf, .pdf",
      "label": _vm.$t('sign.SignDocumentForm.file'),
      "persistent-hint": "",
      "outlined": "",
      "show-size": "",
      "disabled": ""
    },
    model: {
      value: _vm.createDocumentDto.file,
      callback: function callback($$v) {
        _vm.$set(_vm.createDocumentDto, "file", $$v);
      },
      expression: "createDocumentDto.file"
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }