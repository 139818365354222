/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Describe the role of the repor creator
 */
export enum ReportRoleEnum {
  /**
   * The person who creates the response is responsible for the damage
   */
  RESPONSIBLE = "responsible",

  /**
   * The person who creates the response is not responsible for the damage and victim in the accident
   */
  VICTIM = "victim",

  /**
   * The person who creates the response is do not know if responsible or victim
   */
  UNCLEAR = "unclear",

  /**
   * Witness
   */
  WITNESS = "witness",

  /**
   * The person who reports the damage is not the one driving the car
   */
  ALTERNATIVE_DRIVER = "alternative_driver"
}
