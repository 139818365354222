import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import { Banking as ThgBanking } from "../thg/v1/Banking";
import { Banking as MrfiktivBanking } from "../mrfiktiv/v1/Banking";
import {
  ThgBankingControllerGetBankingParamsGen,
  ThgBankingControllerNewBankingParamsGen,
  ThgBankingDtoGen
} from "../thg/v1/data-contracts";
import {
  MrfiktivBankingControllerGetBankingParamsGen,
  MrfiktivBankingControllerNewBankingParamsGen,
  MrfiktivBankingDtoGen
} from "../mrfiktiv/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";

/**
 * Communicates with the thg banking module or mrfiktiv banking module depending on app context
 */
export class BankService {
  /**
   * The proxy.
   */
  mrfiktivProxy: MrfiktivBanking;

  /**
   * The proxy.
   */
  thgProxy: ThgBanking;

  /**
   * @class Initialize ThgService
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivBanking(mrfiktivHttpClientProvider.client());
    this.thgProxy = new ThgBanking(thgHttpClientProvider.client());
  }

  /**
   *
   * Gets the Bankinginformation of a User by AuthInfo
   *
   * @returns
   */
  async getMyBanking(): Promise<ThgBankingDtoGen | MrfiktivBankingDtoGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return (await this.thgProxy.bankingControllerGetMyBankingInformation()).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return (await this.mrfiktivProxy.bankingControllerGetMyBankingInformation()).data;
    }
  }

  /**
   *
   * Create or update the Bankinginformation fo a User by Authinfo
   *
   * @param dto
   * @returns
   */
  async createOrUpdateMyBanking(
    dto: ThgBankingDtoGen | MrfiktivBankingDtoGen
  ): Promise<ThgBankingDtoGen | MrfiktivBankingDtoGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return (await this.thgProxy.bankingControllerCreateOrUpdateBankingMe(dto)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return (await this.mrfiktivProxy.bankingControllerCreateOrUpdateBankingMe(dto)).data;
    }
  }

  /**
   *
   * Creates Bankinginformation for a User
   *
   * @param dto
   * @returns
   */
  async createBanking(
    query: ThgBankingControllerNewBankingParamsGen | MrfiktivBankingControllerNewBankingParamsGen,
    dto: ThgBankingDtoGen | MrfiktivBankingDtoGen
  ): Promise<ThgBankingDtoGen | MrfiktivBankingDtoGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return (await this.thgProxy.bankingControllerNewBanking(query, dto)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return (await this.mrfiktivProxy.bankingControllerNewBanking(query, dto)).data;
    }
  }

  /**
   *
   * Gets the Bankinginformation of a User by AuthInfo
   *
   * @returns
   */
  async getBanking(
    dto: ThgBankingControllerGetBankingParamsGen | MrfiktivBankingControllerGetBankingParamsGen
  ): Promise<ThgBankingDtoGen | MrfiktivBankingDtoGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return (await this.thgProxy.bankingControllerGetBanking(dto)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return (await this.mrfiktivProxy.bankingControllerGetBanking(dto)).data;
    }
  }

  /**
   *
   * Creates Bankinginformation for a Partner as a Partner
   * Permissions needed (PartnerBanking Update)
   *
   * @param dto
   * @returns
   */
  async createBankingAsPartner(
    partnerId: string,
    dto: ThgBankingDtoGen
  ): Promise<ThgBankingDtoGen | MrfiktivBankingDtoGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return (await this.thgProxy.bankingControllerCreateOrUpdateBankingForPartner(partnerId, dto)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      throw new Error("not implemented");
    }
  }

  /**
   *
   * Gets the Bankinginformation of a Partner
   * Permissions needed (PartnerBanking read)
   *
   * @returns
   */
  async getBankingAsPartner(partnerId: string): Promise<ThgBankingDtoGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return (await this.thgProxy.bankingControllerGetBankingForPartner(partnerId)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      throw new Error("not implemented");
    }
  }
}

export default new BankService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
