/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { IUser } from "@/models/User/IUser";

/**
 * @inheritdoc
 */
export class User implements IUser {
  /**
   * @inheritdoc
   */
  firstName = "";
  /**
   * @inheritdoc
   */
  lastName = "";

  constructor(user?: IUser) {
    if (user) {
      this.firstName = user.firstName;
      this.lastName = user.lastName;
    }
  }
}
