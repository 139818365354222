var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isBookingUpdated ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [!_vm.isMobile || _vm.isMobile && _vm.step == _vm.bookingStepEnum.CALENDER ? _c('v-row', [_c('v-col', {
    staticClass: "justify-center align-center text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4 text-h6 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("bookingForm.BookingUpdate.update")))]), _c('v-card-text', {
    staticClass: "mt-n6 mb-2 headline font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("bookingForm.BookingUpdate.yourBooking", {
    date: _vm.selectedSlotDate,
    time: _vm.selectedSlotStartTime
  })) + " ")])], 1)], 1) : _vm._e(), !_vm.isMobile ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('booking-calendar', {
    attrs: {
      "showNavigation": false
    },
    on: {
      "date-change": _vm.onDateChange
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('booking-slots', {
    attrs: {
      "showNavigation": false
    },
    on: {
      "select": _vm.onSlotSelect
    }
  })], 1)], 1) : _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "400",
      "min-height": "600",
      "flat": ""
    }
  }, [_c('v-window', {
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-window-item', {
    attrs: {
      "value": _vm.bookingStepEnum.CALENDER
    }
  }, [_c('booking-calendar', {
    attrs: {
      "showNavigation": false,
      "isMobile": true
    },
    on: {
      "date-change": _vm.onDateChange
    }
  })], 1), _c('v-window-item', {
    attrs: {
      "value": _vm.bookingStepEnum.SLOTS
    }
  }, [_c('booking-slots', {
    attrs: {
      "showNavigation": false,
      "isMobile": true
    },
    on: {
      "select": _vm.onSlotSelect
    }
  })], 1)], 1)], 1)], 1) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('booking-success', {
    attrs: {
      "heading": "bookingForm.BookingUpdate.updateConfirmed",
      "text": "bookingForm.BookingUpdate.updateConfirmedText"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }