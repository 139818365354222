













import { Component, Vue, Prop } from "vue-property-decorator";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";

@Component({ components: {} })
export default class FileExample extends Vue {
  @Prop()
  public example?: AssetEnum;

  get imageFromAssetRepository() {
    if (this.example) {
      return AssetRepository.getAsset(this.$vuetify.theme.dark, this.example);
    }
    return null;
  }
}
