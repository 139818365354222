














import { noWhiteSpaceRule } from "@/lib/rules/noWhiteSpaceRule";
import {
  containsCapitalLatinLetterRule,
  containsLowerLatinLetterRule,
  containsNumberRule,
  containsSpecialCharacterRule,
  minCharacterCountRule
} from "@/lib/rules/passwordRules";
import { requiredRule } from "@/lib/rules/requiredRule";
import { LoginModule } from "@/store/modules/login.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class LoginComponentInputPassword extends Vue {
  /**
   * apply only the required rule or all rules
   */
  @Prop({ default: false })
  onlyRequired?: boolean;

  /**
   * Get value of input
   */
  get password() {
    return LoginModule["password" + this.suffix];
  }

  /**
   * Set value of input.
   */
  set password(password: string) {
    LoginModule.setPasswordBySuffix({ password: password, suffix: this.suffix || "" });
  }

  /**
   * Show asterisks or plaintext
   */
  isPasswordVisible = false;

  /**
   * Type of password like "New" or "Repeat"
   */
  @Prop({ default: "" })
  suffix?: string;

  /**
   * Rule besides required
   */
  @Prop()
  rule?: Function;

  /**
   * Can be used to set a custom label to the input
   */
  @Prop()
  customLabel?: string;

  /**
   * Password rules
   */
  get rules() {
    return this.rule ? [...this.defaultRules, this.rule()] : this.defaultRules;
  }

  /**
   * Default rules that matches AWS standard @see passwordRules.ts
   */
  get defaultRules() {
    const rules = [requiredRule()];
    if (!this.onlyRequired) {
      rules.push(
        noWhiteSpaceRule(),
        minCharacterCountRule(),
        containsCapitalLatinLetterRule(),
        containsLowerLatinLetterRule(),
        containsNumberRule(),
        containsSpecialCharacterRule()
      );
    }
    return rules;
  }

  /**
   * Get input label
   */
  get label() {
    if (this.customLabel) {
      return this.customLabel;
    }

    return this.$t(`components.login.LoginComponentInputPassword.password${this.suffix}`);
  }

  /**
   * Prefill one time password using query/param
   */
  beforeMount() {
    if (this.suffix !== "") return;
    if (this.$route.query.code) {
      this.password = String(this.$route.query.code);
    } else if (this.$route.params.code) {
      this.password = String(this.$route.params.code);
    }
  }
}
