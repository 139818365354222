var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('div', [_vm.item.loading ? _c('v-skeleton-loader', {
          attrs: {
            "type": "list-item-three-line"
          }
        }) : _c('v-list-item', [_vm._t("icon"), _c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "text-truncate "
        }, [_vm.hasOpenDetailListener() ? _c('a', {
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.emitOpenDetail.apply(null, arguments);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.title || "-") + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.title || "-") + " ")]), _c('span', [_vm.item.state && _vm.item.state !== 'active' ? _c('v-chip', {
          staticClass: "mx-1",
          attrs: {
            "color": _vm.getState(_vm.item).color,
            "x-small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("views.fleet.FleetVehicleListView.state.".concat(_vm.item.state))) + " ")]) : _vm._e()], 1)]), !_vm.small ? _c('v-list-item-subtitle', {
          staticClass: "text-xs"
        }, [_vm.item.numberplate ? _c('span', [_vm._v(" " + _vm._s(_vm.item.numberplate) + " ")]) : _vm._e(), _vm.item.identificationnumber ? _c('span', [_vm._v("(" + _vm._s(_vm.item.identificationnumber) + ")")]) : _vm._e()]) : _vm._e(), _vm.item.registration && !_vm.small ? _c('v-list-item-subtitle', {
          staticClass: "text-xs text-truncate"
        }, [_vm._v(" " + _vm._s(_vm.item.registration.manufacturerName) + " " + _vm._s(_vm.item.registration.manufacturerType) + " ")]) : _vm._e()], 1), _vm.confirmable ? _c('v-list-item-action', {
          staticClass: "mx-0 px-0"
        }, [_vm.isConfirmable ? _c('tooltip', {
          attrs: {
            "text": _vm.$t('common.BackendResourceEnum.click.tooltip.vehicle')
          }
        }, [_c('v-flex', [hover ? _c('v-btn', {
          attrs: {
            "text": "",
            "color": "success",
            "elevation": "0",
            "small": ""
          },
          on: {
            "click": _vm.confirm
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("common.BackendResourceEnum.click.vehicle")) + " "), _c('v-icon', {
          staticClass: "pl-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v(" mdi-check ")])], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e()], 2)], 1)];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }