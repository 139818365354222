var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-combobox', {
    attrs: {
      "filter": _vm.filter,
      "hide-no-data": !_vm.search,
      "items": _vm.items,
      "search-input": _vm.search,
      "rules": _vm.rulesList,
      "multiple": _vm.multiple,
      "clearable": _vm.multiple,
      "label": _vm.label,
      "disabled": _vm.disabled,
      "hide-selected": "",
      "small-chips": "",
      "flat": "",
      "outlined": "",
      "dense": _vm.dense
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.search = $event;
      },
      "blur": _vm.onBlur
    },
    scopedSlots: _vm._u([{
      key: "no-data",
      fn: function fn() {
        return [_c('v-list-item', [_c('span', {
          staticClass: "subheading"
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1), _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.search) + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            item = _ref.item,
            parent = _ref.parent,
            selected = _ref.selected;
        return [_c('v-chip', _vm._b({
          attrs: {
            "input-value": selected,
            "color": _vm.disabled ? '#F3F3F3' : 'light-grey',
            "x-small": _vm.dense,
            "label": "",
            "small": "",
            "outlined": ""
          }
        }, 'v-chip', attrs, false), [_c('span', {
          staticClass: "pr-2"
        }, [_vm._v(" " + _vm._s(item) + " ")]), _vm.multiple ? _c('v-icon', {
          attrs: {
            "small": "",
            "color": _vm.disabled ? 'grey' : 'light-grey'
          },
          on: {
            "click": function click($event) {
              return _vm.removeItem(item, parent);
            }
          }
        }, [_vm._v(" $delete ")]) : _vm._e()], 1)];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-chip', {
          attrs: {
            "dark": "",
            "label": "",
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item) + " ")])];
      }
    }]),
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }