







































import { IReport } from "@/models/report.entity";
import {
  SignCreateDocumentDtoGen,
  SignCreateSignRequestDtoGen,
  SignDigitalSignatureRecipientGen,
  SignReferenceGen
} from "@/services/sign/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import ReportDocumentSignRequestSteps, { SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import PartnerReportTimeLineCardScrollMixin from "@/components/partner/PartnerReportTimeLineCardScrollMixin.vue";

export class CreateReportDocumentDtoGen implements SignCreateDocumentDtoGen {
  name = "";
  file: File = undefined as any;
  title = "";
  folder? = "/";
  description?: string;
  tags: string[] = [];
  isTemplate = false;

  constructor(document?: IReport) {
    if (document && document._id) {
      this.title = document.numberplate;
      this.folder = document._id;
      this.tags = ["report"];
    }
  }
}

class CreateReportRecipientDto implements SignDigitalSignatureRecipientGen {
  email = "";
  lastName? = "";
  firstName?: string;
  companyName?: string;

  constructor(report: IReport) {
    const firstWhiteSpace = report.customerName?.trim().indexOf(" ");

    this.firstName = report.customerName?.substring(0, firstWhiteSpace).trim() || "";
    this.lastName = report.customerName?.substring(firstWhiteSpace + 1).trim() || "";
    this.email = report.customerContact?.email;
  }
}

export class CreateReportSignRequestDto implements SignCreateSignRequestDtoGen {
  recipient: SignDigitalSignatureRecipientGen;

  title = "";
  documents: string[] = [];

  description?: string;
  validBy?: string;

  refs?: SignReferenceGen[];

  constructor(report?: IReport, documents?: ISignDocument[]) {
    if (report) {
      this.recipient = new CreateReportRecipientDto(report);
      this.title = report.numberplate;

      if (report && report._id) {
        this.refs = [
          {
            refId: report._id,
            refType: BackendResourceEnum.REPORT
          }
        ];
      }
    } else {
      this.recipient = {
        email: ""
      };
    }

    if (documents) {
      this.documents = documents.map(d => d.id);
    }
  }
}

@Component({
  components: {
    ReportDocumentSignRequestSteps: () => import("./ReportDocumentSignRequestSteps.vue")
  }
})
export default class ReportDocumentSignRequestForm extends Mixins(PartnerReportTimeLineCardScrollMixin) {
  @Ref("steps")
  steps!: ReportDocumentSignRequestSteps;

  @Prop()
  report?: IReport;

  readonly maxFileSizeInMb = 5;

  step: SignRequestStepperPageEnum = SignRequestStepperPageEnum.CHOICE;

  isValid = true;

  isLoading = false;

  isDrag = false;

  isDrop = false;

  isFailed = false;

  get hideLeft() {
    return this.step === SignRequestStepperPageEnum.CHOICE || this.step === SignRequestStepperPageEnum.SUCCESS;
  }

  get hideRight() {
    return [SignRequestStepperPageEnum.CHOICE].includes(this.step);
  }

  get rightButtonText() {
    if (this.step === SignRequestStepperPageEnum.SUCCESS || this.step === SignRequestStepperPageEnum.MAIL_SUCCESS) {
      return this.$t("components.template.dialog.preview.end");
    }

    if (this.step === SignRequestStepperPageEnum.INPUTS) {
      return this.$t("components.template.dialog.preview.create");
    }

    if (this.step === SignRequestStepperPageEnum.MAIL) {
      return this.$t("components.template.dialog.preview.send");
    }

    return this.$t("components.template.dialog.preview.next");
  }

  get SignRequestStepperPageEnum() {
    return SignRequestStepperPageEnum;
  }

  dragOver() {
    if (this.step !== SignRequestStepperPageEnum.CHOICE) {
      return;
    }
    this.isDrag = true;
  }

  dragLeave(event: DragEvent) {
    if (this.step !== SignRequestStepperPageEnum.CHOICE) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (!event.relatedTarget || !(event.currentTarget as any)?.contains(event.relatedTarget)) {
      this.isDrag = false;
    }
  }

  onFileDrop(event: DragEvent) {
    if (this.step !== SignRequestStepperPageEnum.CHOICE) {
      return;
    }

    const files = event.dataTransfer?.files;
    if (!files) {
      return;
    }
    const file = files[0];
    if (!file) {
      this.isDrop = false;
      return;
    }

    this.isDrop = true;

    let isFailed = false;
    if (file.size > 1024 * 1000 * this.maxFileSizeInMb) {
      this.$toast.error(this.$t("sign.ReportDocumentSignRequestForm.maxSize", { size: this.maxFileSizeInMb }));
      isFailed = true;
    }
    if (file.type !== "application/pdf") {
      this.$toast.error(this.$t("sign.ReportDocumentSignRequestForm.fileType"));
      isFailed = true;
    }

    if (isFailed) {
      this.isFailed = true;
      setTimeout(() => {
        this.isDrag = false;
        this.isDrop = false;
        this.isFailed = false;
      }, 1000);
      return;
    }

    this.step = SignRequestStepperPageEnum.SIGNATURE_SELECTION;
    const sign: ISignDocument = new SignDocument({
      partnerId: "",
      id: "",
      name: "",
      folder: "",
      url: URL.createObjectURL(file),
      type: "",
      title: "",
      tags: [],
      isTemplate: false,
      isSigned: false,
      timestamp: {
        created: new Date().toISOString(),
        lastModified: new Date().toISOString(),
        modified: []
      },
      tokens: []
    });
    this.steps.setSignDocument(sign);
    setTimeout(() => {
      this.isDrag = false;
      this.isDrop = false;
      this.isFailed = false;
    }, 1000);
  }

  previous() {
    (this.$refs.steps as ReportDocumentSignRequestSteps).previous();
  }

  close() {
    this.step = SignRequestStepperPageEnum.CHOICE;
  }

  async next() {
    await (this.$refs.steps as ReportDocumentSignRequestSteps).next();
    this.debounceScrollToBottom();
  }
}
