
import { Vue, Component } from "vue-property-decorator";
import { HttpException, ConflictException, TooManyRequestsException } from "@/lib/exceptions/http";

export interface IFailureCodeContent {
  message?: string;
  onFail?: () => void;
}

@Component({})
export default class LoginErrorMixin extends Vue {
  /**
   * text that is displayed when error occurs
   */
  failText = "";

  /**
   * Text that occurs when an operation goes well
   */
  successText = "";

  /**
   * list of failure codes to check
   */
  knownFailureCodes = new Map<string, IFailureCodeContent>([
    ["UNDEFINED", { message: "components.login.LoginErrorMixin.failUndefined" }],
    ["Error: Network Error", { message: "components.login.LoginErrorMixin.failNetworkError" }],
    ["LimitExceededError", { message: "components.login.LoginErrorMixin.failMaxRequestsReached" }],
    ["User marked as SPAM", { message: "components.login.LoginErrorMixin.failUserSpam" }]
  ]);

  /**
   * Add or replace error codes in the list of failure codes to check in handleFail
   *
   * @param customFailureCodes
   */
  registerCustomFailureCodes(customFailureCodes: Map<string, IFailureCodeContent>) {
    for (const key of customFailureCodes.keys()) {
      const value = customFailureCodes.get(key);
      if (value) {
        this.knownFailureCodes.set(key, value);
      }
    }
  }

  /**
   * Set the failure code depending on the exception information using the @see    map.
   * @param error the exception received from service
   */
  handleFail(error: any) {
    this.$log.error(error);

    if (error instanceof ConflictException) {
      this.$toast.error(this.$t("components.login.LoginErrorMixin.failEmailAlreadyUsed"), {
        onClick: () => {
          this.$router.push({ name: "Login" });
        }
      });
      this.setFailMessage("components.login.LoginErrorMixin.failEmailAlreadyUsed");
    } else if (error instanceof TooManyRequestsException) {
      this.$toast.error(this.$t("components.login.LoginErrorMixin.failMaxRequestsReached"));
      this.setFailMessage("components.login.LoginErrorMixin.failMaxRequestsReached");
    } else if (error instanceof HttpException || error instanceof Error) {
      const content = this.getKnownFailureCodeFromHttpException(error);
      if (content?.message) {
        this.setFailMessage(content.message);
      }
      if (content?.onFail) {
        content.onFail();
      }
    }
  }

  /**
   * Tries to get the failure code by the @see IHttpExceptionDetail error first,
   * if this is not present use the message,
   * if this is not present uses the exception base message.
   * if this is not present uses the UNDEFINED error handling.
   *
   * @param error the error to be mapped
   * @returns the FailureCodeContent or undefined if no match was found
   */
  getKnownFailureCodeFromHttpException(error: HttpException): IFailureCodeContent | undefined {
    if (error.data?.error && this.knownFailureCodes.has(error.data.error)) {
      const key = error.data.error;

      return this.knownFailureCodes.get(key);
    }
    if (error.data?.message && this.knownFailureCodes.has(error.data.message[0])) {
      const key = error.data.message[0];

      return this.knownFailureCodes.get(key);
    }
    if (error.message && this.knownFailureCodes.has(error.message)) {
      const key = error.message;

      return this.knownFailureCodes.get(key);
    }

    return this.knownFailureCodes.get("UNDEFINED");
  }

  /**
   * sets failure parameter to false, sets fail text if given
   *
   * @param text the fail message that is displayed to the user
   */
  setFailMessage(text: string) {
    this.successText = "";
    this.failText = text;
  }

  /**
   * sets success parameter to true, sets success text if given, starts countdown, executes callback after 3 seconds
   *
   * @param to: the router path to redirect to
   * @param successText: an optional text that is shown for 3 seconds before redirection takes place
   */
  async handleSuccess(to: string, onReroute?: Function) {
    if (onReroute) {
      onReroute();
    }
    await this.$router.push({ path: to });
  }
}
