var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "ma-auto card",
    attrs: {
      "flat": "",
      "align": "center"
    }
  }, [_c('v-card-title', {
    staticClass: "mb-8 align-center justify-center"
  }, [_vm.imgSrc ? _c('v-img', {
    attrs: {
      "max-width": "190px",
      "contain": "",
      "src": _vm.imgSrc
    }
  }) : _c('v-img', {
    attrs: {
      "max-width": "190px",
      "contain": "",
      "src": require("@/assets/partner_not_found.svg")
    }
  })], 1), _c('v-card-text', [_c('p', [_vm._v(_vm._s(_vm.$t("components.utility.ErrorCard.title")))]), _c('p', [_vm._v(_vm._s(_vm.$t("components.utility.ErrorCard.subtitle")))])]), _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.reload
    }
  }, [_vm._v(_vm._s(_vm.$t("components.utility.ErrorCard.btnText")))]), _vm.showLoginBtn ? _c('br') : _vm._e(), _vm.showLoginBtn ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "loading": _vm.isLoadingLogin,
      "text": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(_vm._s(_vm.$t("components.utility.ErrorCard.login")))]) : _vm._e(), _c('v-breadcrumbs', {
    staticClass: "ma-4 align-center justify-center",
    attrs: {
      "items": _vm.items
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }