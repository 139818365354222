
































import TemplateEditorPlaceholderDropdown from "@/components/template/TemplateEditorPlaceholderDropdown.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { SignDocumentTokenDtoGen } from "@/services/sign/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";
import { isSignatureToken } from "./DocumentTemplateDetailTokenList.vue";

@Component({
  components: { SideCard, TemplateEditorPlaceholderDropdown }
})
export default class DocumentTemplateDetailTokenListExpansionHeader extends mixins(DocumentDetailMixin) {
  @Prop()
  documentToken!: SignDocumentTokenDtoGen;

  /**
   * Edit values or allow dropdowns
   */
  @Prop({ default: false })
  isEditValue!: boolean;

  getIsSignature(token: string) {
    return isSignatureToken(token);
  }
}
