












import { MrfiktivCustomFieldConfigurationElementDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";

@Component({
  components: {
    Tooltip
  },
  filters: {}
})
export default class CustomFieldValueChip extends Vue {
  @Prop()
  value!: MrfiktivCustomFieldConfigurationElementDtoGen;

  @Prop({ default: true })
  lightenBackground!: boolean;

  @Prop({ default: true })
  dark!: boolean;

  @Prop({ default: "" })
  chipStyle!: string;

  @Prop({ default: "" })
  customFieldClass!: string;

  get color() {
    if (this.lightenBackground) {
      return `${this.value.color} lighten-3`;
    } else {
      return `${this.value.color}`;
    }
  }

  get textColor() {
    if (this.dark) {
      return "color:white";
    } else {
      return "";
    }
  }
}
