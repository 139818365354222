import { IEventUIDto } from "./../../lib/dto/event/event-ui.dto";
import { CreateReportAsPartnerDto } from "@/lib/dto/create-report-as-partner-dto";
import { $t } from "@/lib/utility/t";
import reportService from "@/services/mrfiktiv/services/reportService";
import ticketService from "@/services/mrfiktiv/services/ticketService";
import vehicleEventService from "@/services/mrfiktiv/services/vehicleEventService";
import vehicleService from "@/services/mrfiktiv/services/vehicleService";
import {
  MrfiktivBlueprintElementViewmodelGen,
  MrfiktivDocumentViewModelGen,
  MrfiktivLeasingContractGen,
  MrfiktivMileageGen,
  MrfiktivReferenceGen,
  MrfiktivReportViewModelGen,
  MrfiktivTicketViewModelGen,
  MrfiktivVehicleRegistrationViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { EventListModule } from "./list-event.store";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { CalendarEvent } from "../../lib/utility/calendarEvent";
import { ConfigModule } from "./config";
import { PartnerModule } from "./partner";
import { UserModule } from "./me-user.store";
import { ActionEnum } from "../enum/authActionEnum";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import { VehicleAccessLayer } from "./access-layers/vehicle.access-layer";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { Ticket } from "@/models/ticket.entity";
import { TicketModule } from "./ticket.store";
import { ISnapshot } from "@/models/snapshot.entity";

type EventVehicleExtension = { vehicle: IVehicle };

/**
 * The types of events
 */
export enum VehicleEventTypeEnum {
  /**
   * Contract start event
   */
  LEASING_START = "leasingStart",

  /**
   * Contract end event
   */
  LEASING_END = "leasingEnd",

  /**
   * Event when main inspection is due
   */
  MAIN_INSPECTION = "mainInspection",

  /**
   * Event where mileage was added
   */
  MILEAGE_UPDATE = "mileageUpdate",

  /**
   * Event where vehicle was created
   */
  CREATION = "creation",

  /**
   * Calendar event event
   */
  EVENT = "event",

  /**
   * Event where ticket is due
   */
  TICKET = "ticket",

  /**
   * Event where report is created event
   */
  REPORT = "report"
}

export const eventTypeMap: Map<VehicleEventTypeEnum, { icon: string; text: string }> = new Map([
  [VehicleEventTypeEnum.EVENT, { icon: "mdi-calendar", text: $t("components.fleet.Aggregation.EventName.event") }],
  [
    VehicleEventTypeEnum.LEASING_START,
    { icon: "mdi-file-document-multiple-outline", text: $t("components.fleet.Aggregation.EventName.leasingStart") }
  ],
  [
    VehicleEventTypeEnum.LEASING_END,
    { icon: "mdi-file-document-multiple-outline", text: $t("components.fleet.Aggregation.EventName.leasingEnd") }
  ],
  [
    VehicleEventTypeEnum.MAIN_INSPECTION,
    { icon: "mdi-wrench", text: $t("components.fleet.Aggregation.EventName.mainInspection") }
  ],
  [
    VehicleEventTypeEnum.MILEAGE_UPDATE,
    { icon: "mdi-map-marker-distance", text: $t("components.fleet.Aggregation.EventName.mileageUpdate") }
  ],
  [
    VehicleEventTypeEnum.TICKET,
    { icon: "mdi-ticket-outline", text: $t("components.fleet.Aggregation.EventName.ticket") }
  ],
  [
    VehicleEventTypeEnum.CREATION,
    { icon: "mdi-cake-variant-outline", text: $t("components.fleet.Aggregation.EventName.creation") }
  ],
  [VehicleEventTypeEnum.REPORT, { icon: "mdi-wrench", text: $t("components.fleet.Aggregation.EventName.report") }]
]);

/**
 * Lists of calendar events categorized by type
 */
export class VehicleEventCollection {
  [VehicleEventTypeEnum.EVENT]: (VehicleEventEvent & EventVehicleExtension)[] = [];
  [VehicleEventTypeEnum.LEASING_START]: (VehicleLeasingEvent & EventVehicleExtension)[] = [];
  [VehicleEventTypeEnum.LEASING_END]: (VehicleLeasingEvent & EventVehicleExtension)[] = [];
  [VehicleEventTypeEnum.MAIN_INSPECTION]: (VehicleBaseEvent & EventVehicleExtension)[] = [];
  [VehicleEventTypeEnum.MILEAGE_UPDATE]: (VehicleMileageEvent & EventVehicleExtension)[] = [];
  [VehicleEventTypeEnum.TICKET]: (VehicleTicketEvent & EventVehicleExtension)[] = [];
  [VehicleEventTypeEnum.CREATION]: (VehicleBaseEvent & EventVehicleExtension)[] = [];
  [VehicleEventTypeEnum.REPORT]: (VehicleReportEvent & EventVehicleExtension)[] = [];

  setEvents(events: VehicleEventCollection) {
    this[VehicleEventTypeEnum.EVENT].splice(
      0,
      this[VehicleEventTypeEnum.EVENT].length,
      ...(events[VehicleEventTypeEnum.EVENT] ?? [])
    );
    this[VehicleEventTypeEnum.LEASING_START].splice(
      0,
      this[VehicleEventTypeEnum.LEASING_START].length,
      ...(events[VehicleEventTypeEnum.LEASING_START] ?? [])
    );
    this[VehicleEventTypeEnum.LEASING_END].splice(
      0,
      this[VehicleEventTypeEnum.LEASING_END].length,
      ...(events[VehicleEventTypeEnum.LEASING_END] ?? [])
    );
    this[VehicleEventTypeEnum.MAIN_INSPECTION].splice(
      0,
      this[VehicleEventTypeEnum.MAIN_INSPECTION].length,
      ...(events[VehicleEventTypeEnum.MAIN_INSPECTION] ?? [])
    );
    this[VehicleEventTypeEnum.MILEAGE_UPDATE].splice(
      0,
      this[VehicleEventTypeEnum.MILEAGE_UPDATE].length,
      ...(events[VehicleEventTypeEnum.MILEAGE_UPDATE] ?? [])
    );
    this[VehicleEventTypeEnum.TICKET].splice(
      0,
      this[VehicleEventTypeEnum.TICKET].length,
      ...(events[VehicleEventTypeEnum.TICKET] ?? [])
    );
    this[VehicleEventTypeEnum.CREATION].splice(
      0,
      this[VehicleEventTypeEnum.CREATION].length,
      ...(events[VehicleEventTypeEnum.CREATION] ?? [])
    );
    this[VehicleEventTypeEnum.REPORT].splice(
      0,
      this[VehicleEventTypeEnum.REPORT].length,
      ...(events[VehicleEventTypeEnum.REPORT] ?? [])
    );
  }
}

/**
 * Types of documents that an event can be based on
 */
type CalendarEventDocumentTypes =
  | MrfiktivReportViewModelGen
  | IEventUIDto
  | IVehicle
  | MrfiktivLeasingContractGen
  | MrfiktivTicketViewModelGen
  | MrfiktivMileageGen
  | undefined;

/**
 * An event where a report was created
 */
export type VehicleReportEvent = CalendarEvent<MrfiktivReportViewModelGen> & { type: VehicleEventTypeEnum.REPORT };

/**
 * A fleet event
 */
export type VehicleEventEvent = CalendarEvent<IEventUIDto> & { type: VehicleEventTypeEnum.EVENT };

/**
 * A event refering to a contract (e.g. creation/end)
 */
export type VehicleLeasingEvent = CalendarEvent<MrfiktivLeasingContractGen> & {
  type: VehicleEventTypeEnum.LEASING_END | VehicleEventTypeEnum.LEASING_START;
};

/**
 * Event where a ticket is due
 */
export type VehicleTicketEvent = CalendarEvent<MrfiktivTicketViewModelGen> & { type: VehicleEventTypeEnum.TICKET };

/**
 * Event where mileage was added to a vehicle
 */
export type VehicleMileageEvent = CalendarEvent<MrfiktivMileageGen> & { type: VehicleEventTypeEnum.MILEAGE_UPDATE };

/**
 * A event that has no specific data
 */
export type VehicleBaseEvent = CalendarEvent<undefined>;

/**
 * Any event of the vehicle calendar
 */
export type VehicleEvent = CalendarEvent<CalendarEventDocumentTypes> & { type: VehicleEventTypeEnum };

/**
 * The month & year for which the data was aggregated
 */
export type AggregationTimeFrame = { month: number; year: number };

/**
 * Contains data of a vehicle for a month
 */
export class VehicleAggregationTimeFrame extends Vue {
  /**
   * key to check if the time frame is right
   */
  readonly key: string;

  static readonly CHANGE_IN_EVENTS_OF_TYPE = "onEventAdded";

  aggregation = {
    /**
     * List of events
     */
    [VehicleEventTypeEnum.EVENT]: new Map<string, VehicleEventEvent>(),

    /**
     * List of events of contract start
     */
    [VehicleEventTypeEnum.LEASING_START]: new Map<string, VehicleLeasingEvent>(),

    /**
     * List of events of contract end
     */
    [VehicleEventTypeEnum.LEASING_END]: new Map<string, VehicleLeasingEvent>(),

    /**
     * List of events of main inspection
     */
    [VehicleEventTypeEnum.MAIN_INSPECTION]: new Map<string, VehicleEvent>(),

    /**
     * List of events of mileage update
     */
    [VehicleEventTypeEnum.MILEAGE_UPDATE]: new Map<string, VehicleMileageEvent>(),

    /**
     * List of events of ticket due
     */
    [VehicleEventTypeEnum.TICKET]: new Map<string, VehicleTicketEvent>(),

    /**
     * List of events of vehicle creation
     */
    [VehicleEventTypeEnum.CREATION]: new Map<string, VehicleBaseEvent>()
  };

  /**
   * Generate a key to check if the time frame is right
   */
  static generateKey({ month, year }: AggregationTimeFrame) {
    return `${month}-${year}`;
  }

  /**
   * Create Time Frame
   */
  constructor(monthYear: AggregationTimeFrame) {
    super();
    this.key = VehicleAggregationTimeFrame.generateKey(monthYear);
  }

  /**
   * Check if this is the right collection for the given time
   */
  isKeyMatch(monthYear: AggregationTimeFrame) {
    return this.key === VehicleAggregationTimeFrame.generateKey(monthYear);
  }

  /**
   * Add a single event to the given event category while making sure that there are no duplicate events
   */
  addEvent(event: VehicleEvent) {
    const eventMap = this.aggregation[event.type];
    event.start = new Date(event.start);

    let id = "";
    switch (event.type) {
      case VehicleEventTypeEnum.EVENT: {
        const e = event as VehicleEventEvent;
        id = e.data[0].id ?? e.data[0].recurringEventId ?? "";
        break;
      }
      case VehicleEventTypeEnum.TICKET: {
        const e = event as VehicleTicketEvent;
        id = e.data[0].number.toString();
        break;
      }
      case VehicleEventTypeEnum.MILEAGE_UPDATE: {
        const e = event as VehicleTicketEvent;
        id = e.name;
        break;
      }
    }

    eventMap.set(event.start.toString() + id, event);
    this.$emit(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, event.type);
  }

  clear(type: VehicleEventTypeEnum) {
    this.aggregation[type].clear();
    this.$emit(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, type);
  }

  delete(type: VehicleEventTypeEnum, key: string) {
    this.aggregation[type].delete(key);
    this.$emit(VehicleAggregationTimeFrame.CHANGE_IN_EVENTS_OF_TYPE, type);
  }
}

/**
 * Union of types that can be suggested as references
 */
export type RefSelectType =
  | IVehicle
  | MrfiktivDocumentViewModelGen
  | IEventUIDto
  | MrfiktivReportViewModelGen
  | MrfiktivTicketViewModelGen
  | ISnapshot;

/**
 * Suggestions for references
 */
export interface IRefSuggestion {
  id: string;
  ref: RefSelectType;
  refType: BackendResourceEnum;
}

/**
 * Specific type of a suggestion
 */
export interface IRefSuggestionTyped<T> {
  id: string;
  ref: T;
  refType: BackendResourceEnum;
}

/**
 * Aggregation object for ticket
 */
export class VehicleAggregation {
  /**
   * Vehicle that is aggregated
   * Methods to sort the events and tickets of the vehicle by date
   */
  public vehicle: IVehicle;

  /**
   * Events and TIckets per month
   */
  vehicleAggregationTimeFrameMap = { map: new Map<string, VehicleAggregationTimeFrame>(), key: 0 };

  /**
   * Tickets without due date that are open and belong to the vehicle
   */
  public ticketsWithoutDue: MrfiktivTicketViewModelGen[];

  /**
   * Reports of the vehicle
   */
  public reports: MrfiktivReportViewModelGen[];

  /**
   * DTO to create a report for a vehicle
   */
  reportDto: CreateReportAsPartnerDto;

  static get appointmentTypeColorsMap() {
    return new Map<string, string>([
      // low-importance
      [VehicleEventTypeEnum.CREATION, "black"],
      [VehicleEventTypeEnum.MAIN_INSPECTION, "rgb(180,180,180)"],
      [VehicleEventTypeEnum.MILEAGE_UPDATE, "rgb(180,180,180)"],
      // medium-important
      [VehicleEventTypeEnum.LEASING_END, ConfigModule.color.secondary],
      [VehicleEventTypeEnum.LEASING_START, ConfigModule.color.secondary],
      // important
      [VehicleEventTypeEnum.EVENT, "info"],
      // related documents
      [VehicleEventTypeEnum.REPORT, ConfigModule.color.analyticsColors[5]],
      [VehicleEventTypeEnum.TICKET, ConfigModule.color.analyticsColors[6]]
    ]);
  }

  getEvents(current: AggregationTimeFrame): VehicleEventCollection {
    type EventVehicleExtension = { vehicle: IVehicle };
    const events = new VehicleEventCollection();

    const vehicle = this.vehicle;

    // create events for report to show them in a calendar as well
    const reports: (VehicleReportEvent & EventVehicleExtension)[] = this.reports.map(report => {
      return {
        data: [report],
        name: report.numberplate,
        start: new Date(report.timestamps.created),
        color: VehicleAggregation.appointmentTypeColorsMap.get(VehicleEventTypeEnum.REPORT),
        timed: false,
        vehicle: this.vehicle,
        type: VehicleEventTypeEnum.REPORT
      };
    });
    events[VehicleEventTypeEnum.REPORT].push(...reports);

    const prev = this.getPreviousMonth(current);
    const next = this.getNextMonth(current);

    for (const timeSlot of [prev, current, next]) {
      const timedDate = this.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(timeSlot);
      const categories = [
        VehicleEventTypeEnum.EVENT,
        VehicleEventTypeEnum.LEASING_START,
        VehicleEventTypeEnum.LEASING_END,
        VehicleEventTypeEnum.MAIN_INSPECTION,
        VehicleEventTypeEnum.MILEAGE_UPDATE,
        VehicleEventTypeEnum.TICKET,
        VehicleEventTypeEnum.CREATION
      ];
      for (const category of categories) {
        const map = timedDate.aggregation[category];
        const eventList: VehicleEvent[] = Array.from(map.values());
        const vehicleEvents: (VehicleEvent & { vehicle: IVehicle })[] = eventList.map(e => {
          return { ...e, vehicle };
        });
        (events[category] as (VehicleEvent & { vehicle: IVehicle })[]).push(...vehicleEvents);
      }
    }

    return events;
  }

  /**
   * Methods to sort the events and tickets of the vehicle by date
   * Aggregation object for ticket
   *
   * use parse methods to handle the data
   *
   */
  constructor(vehicle: IVehicle) {
    this.ticketsWithoutDue = [];
    this.reports = [];
    this.vehicle = vehicle;

    if (!this.vehicle.contracts) this.vehicle.contracts = [];
    if (!this.vehicle.mileages) this.vehicle.mileages = [];

    this.reportDto = CreateReportAsPartnerDto.createFromVehicle(this.vehicle);

    this.parseContractDates()
      .parseMileageDates()
      .parseVehicleCreateDates();
  }

  /**
   * @param monthYear
   * @returns
   */
  getOrCreateVehicleAggregationTimeFrameForMonthFromMap(monthYear: AggregationTimeFrame) {
    const key = VehicleAggregationTimeFrame.generateKey(monthYear);
    let found = this.vehicleAggregationTimeFrameMap.map.get(key);
    if (!found) {
      found = new VehicleAggregationTimeFrame(monthYear);
      this.vehicleAggregationTimeFrameMap.map.set(key, found);
      this.vehicleAggregationTimeFrameMap.key++;
    }

    return found;
  }

  /**
   * get the total length of the events and tickets for the given month
   */
  public getAmountOfEventsAndTicketsForMonth(monthYear: AggregationTimeFrame) {
    const current = this.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(monthYear);

    const tickets = current.aggregation.ticket.size;
    const events = Array.from(current.aggregation.event.values()).filter(e => e.data.length && !e.data[0].ack).length;
    const total = tickets + events;

    return { total, events, tickets };
  }

  /**
   * check if the given event is in the given month
   * @returns
   */
  private getMonthYearFromDate(date: Date) {
    return { month: date.getMonth(), year: date.getFullYear() };
  }

  /**
   * get next month
   * @returns
   */
  private getNextMonth({ month, year }: AggregationTimeFrame): AggregationTimeFrame {
    return month === 11 ? { month: 0, year: year + 1 } : { month: month + 1, year };
  }

  /**
   * get previous month
   * @returns
   */
  private getPreviousMonth({ month, year }: AggregationTimeFrame): AggregationTimeFrame {
    return month === 0 ? { month: 11, year: year - 1 } : { month: month - 1, year };
  }

  /**
   * add calendar event to the list depending on the event start date
   * @param event
   */
  private addEvent(event: VehicleEvent) {
    const monthYear = this.getMonthYearFromDate(new Date(event.start));

    const frame = this.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(monthYear);
    frame.addEvent(event);
  }

  /**
   * @param event
   */
  public addVehicleEvent(event: IEventUIDto) {
    const color = event.ack
      ? "rgb(180,180,180)"
      : new Date(event.start) <= new Date()
      ? "error"
      : VehicleAggregation.appointmentTypeColorsMap.get(VehicleEventTypeEnum.EVENT);

    const vehicleEvent: VehicleEventEvent = {
      data: [event],
      name: event.summary,
      start: event.start,
      end: event.end ? new Date(event.end) : undefined,
      color: color,
      timed: !event.isAllDay,
      type: VehicleEventTypeEnum.EVENT
    };

    this.addEvent(vehicleEvent);
  }

  /**
   * add ticket to the list depending on the due date
   * @param ticket
   * @returns
   */
  public addTicket(ticket: MrfiktivTicketViewModelGen) {
    // only add open tickets
    if (ticket.state !== TicketStatusEnum.OPEN) {
      return;
    }

    if (ticket.due) {
      const color =
        new Date(ticket.due) <= new Date()
          ? "warning"
          : VehicleAggregation.appointmentTypeColorsMap.get(VehicleEventTypeEnum.TICKET);

      const event: VehicleTicketEvent = {
        data: [new Ticket(ticket)],
        name: ticket.title ?? `#${ticket.number}`,
        start: ticket.due,
        color: color,
        timed: false,
        type: VehicleEventTypeEnum.TICKET
      };

      this.addEvent(event);

      return;
    }

    const ticketsWithoutDueIndex = this.ticketsWithoutDue.findIndex(t => ticket.number === t.number);
    if (ticketsWithoutDueIndex > -1) {
      this.ticketsWithoutDue.splice(ticketsWithoutDueIndex, 1, ticket);
    } else {
      this.ticketsWithoutDue.push(ticket);
    }
  }

  /**
   * gets latest mileage of the vehicle
   */
  get totalMileage() {
    // sort mileages by date
    const sorted = this.vehicle.mileages?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    // return latest mileage
    return sorted?.[0]?.mileage ?? 0;
  }

  /**
   * Gets appointment info when vehicle contracts start and end
   */
  parseContractDates(): this {
    for (const timeFrame of this.vehicleAggregationTimeFrameMap.map.values()) {
      timeFrame.clear(VehicleEventTypeEnum.LEASING_START);
      timeFrame.clear(VehicleEventTypeEnum.LEASING_END);
    }

    for (const contract of this.vehicle.contracts ?? []) {
      if (contract.startDate) {
        const contractStart: VehicleLeasingEvent = {
          data: [contract],
          name: contract.title,
          start: new Date(contract.startDate),
          color: VehicleAggregation.appointmentTypeColorsMap.get(VehicleEventTypeEnum.LEASING_START),
          timed: false,
          type: VehicleEventTypeEnum.LEASING_START
        };
        this.addEvent(contractStart);
      }
      if (contract.endDate) {
        const contractEnd: VehicleLeasingEvent = {
          data: [contract],
          name: contract.title,
          start: new Date(contract.endDate),
          color: VehicleAggregation.appointmentTypeColorsMap.get(VehicleEventTypeEnum.LEASING_END),
          timed: false,
          type: VehicleEventTypeEnum.LEASING_END
        };
        this.addEvent(contractEnd);
      }
    }

    return this;
  }

  /**
   * Gets info on mileage event
   */
  parseMileageDates(): this {
    for (const timeFrame of this.vehicleAggregationTimeFrameMap.map.values()) {
      timeFrame.clear(VehicleEventTypeEnum.MILEAGE_UPDATE);
    }

    for (const mileage of this.vehicle.mileages ?? []) {
      const event: VehicleEvent = {
        data: [mileage],
        name: mileage.mileage.toString(),
        start: new Date(mileage.date),
        color: VehicleAggregation.appointmentTypeColorsMap.get(VehicleEventTypeEnum.MILEAGE_UPDATE),
        timed: false,
        type: VehicleEventTypeEnum.MILEAGE_UPDATE
      };
      this.addEvent(event);
    }

    return this;
  }

  /**
   * Sort info about vehicle creation into the correct month
   */
  parseVehicleCreateDates(): this {
    for (const timeFrame of this.vehicleAggregationTimeFrameMap.map.values()) {
      timeFrame.clear(VehicleEventTypeEnum.CREATION);
    }

    const event: VehicleBaseEvent = {
      data: [],
      name: this.vehicle.numberplate,
      start: new Date(this.vehicle.timestamp.created),
      color: VehicleAggregation.appointmentTypeColorsMap.get(VehicleEventTypeEnum.CREATION),
      timed: false
    };
    this.addEvent({ ...event, type: VehicleEventTypeEnum.CREATION });

    return this;
  }

  /**
   * Parse through events
   * sort them into the correct month depending on their start date
   *
   * @param events
   */
  private parseEventDates(events: IEventUIDto[]): this {
    for (const event of events) {
      this.addVehicleEvent(event);
    }

    return this;
  }

  /**
   * Parse through tickets
   * sort them into the correct month depending on their due date
   * if they have no due date, they are added to the ticketsWithoutDue array
   *
   * @param tickets
   */
  private parseTickets(tickets: MrfiktivTicketViewModelGen[]): this {
    for (const ticket of tickets) {
      this.addTicket(ticket);
    }

    return this;
  }

  /**
   * Parse through report
   * If the report matches the vehicle id or the vehicle identification number add it to the list
   * skip reports that are closed or in finished (although this should be handled by pagination already)
   *
   * @param reports
   * @returns
   */
  addReport(report: MrfiktivReportViewModelGen): this {
    if (report.vehicleId !== this.vehicle.id) return this;

    const reportIndex = this.reports.findIndex(r => r.id === report.id);
    if (reportIndex > -1) {
      this.reports.splice(reportIndex, 1, report);
    } else {
      this.reports.push(report);
    }

    return this;
  }

  /**
   * Parse through reports
   * If the report matches the vehicle id or the vehicle identification number add it to the list
   * skip reports that are closed or in finished (although this should be handled by pagination already)
   *
   * @param reports
   * @returns
   */
  parseReports(reports: MrfiktivReportViewModelGen[]): this {
    for (const report of reports) {
      this.addReport(report);
    }

    return this;
  }

  public async initializeAsync(monthYear: AggregationTimeFrame) {
    const previousMonth = this.getPreviousMonth(monthYear);
    const nextMonth = this.getNextMonth(monthYear);
    const from = new Date(previousMonth.year, previousMonth.month);
    const to = new Date(nextMonth.year, nextMonth.month + 1);
    const partnerId = this.vehicle.partnerId;
    const vehicleId = this.vehicle.id;

    const promises = [];

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.EVENT, partnerId)) {
      const eventsAsync = vehicleEventService
        .listAll({
          partnerId,
          vehicleId,
          from: from.getTime(),
          to: to.getTime()
        })
        .then(events => {
          events = events.filter(e => !e.isRecurringRoot);
          this.parseEventDates(events ?? []);
        });

      promises.push(eventsAsync);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TICKET, partnerId)) {
      const ticketsAsync = ticketService
        .getAll({
          partnerId,
          currentPage: 1,
          filter: [
            { key: "refs.refId", operation: "$eq", value: vehicleId },
            { key: "state", operation: "$eq", value: TicketStatusEnum.OPEN }
          ]
        })
        .then(t => {
          this.parseTickets(t.data ?? []);
        });

      promises.push(ticketsAsync);
    }

    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.REPORT, partnerId)) {
      const reportsAsync = reportService
        .getReportPaginated({
          partnerId,
          currentPage: 1,
          filter: [
            { key: "vehicleId", operation: "$eq", value: vehicleId },
            { key: "progressStatus", value: ProgressStatusEnum.DELETED, operation: "$ne" }
          ]
        })
        .then(t => {
          this.parseReports(t.data ?? []);
        });

      promises.push(reportsAsync);
    }
    await Promise.all(promises);

    return this;
  }

  public async updateEvents(monthYear: AggregationTimeFrame) {
    const partnerId = this.vehicle.partnerId;
    const vehicleId = this.vehicle.id;
    const previousMonth = this.getPreviousMonth(monthYear);
    const nextMonth = this.getNextMonth(monthYear);
    const from = new Date(previousMonth.year, previousMonth.month);
    const to = new Date(nextMonth.year, nextMonth.month + 1);

    const events = await vehicleEventService.listAll({
      partnerId,
      vehicleId,
      from: from.getTime(),
      to: to.getTime()
    });

    this.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(previousMonth).clear(VehicleEventTypeEnum.EVENT);
    this.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(monthYear).clear(VehicleEventTypeEnum.EVENT);
    this.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(nextMonth).clear(VehicleEventTypeEnum.EVENT);
    this.parseEventDates(events ?? []);
  }

  getSuggestions(monthYear: AggregationTimeFrame) {
    const suggestions: IRefSuggestion[] = [
      {
        id: this.vehicle.id,
        ref: this.vehicle,
        refType: ResourceEnum.VEHICLE
      }
    ];
    const previousMonth = this.getPreviousMonth(monthYear);
    const nextMonth = this.getNextMonth(monthYear);

    // suggest events for prev, curr, next month for given vehicle
    for (const timeSlot of [previousMonth, monthYear, nextMonth]) {
      const timedData = this.getOrCreateVehicleAggregationTimeFrameForMonthFromMap(timeSlot);
      timedData.aggregation.event.forEach(e =>
        e.data.forEach(item => {
          const event = item as IEventUIDto;
          if (event.start && event.summary) {
            suggestions.push({
              id: item.id || `${event.start}+${event.summary}`,
              ref: event,
              refType: ResourceEnum.EVENT
            });
          }
        })
      );
      timedData.aggregation.ticket.forEach(e =>
        e.data.forEach(item =>
          suggestions.push({
            id: item.id,
            ref: item,
            refType: ResourceEnum.TICKET
          })
        )
      );
    }

    // suggest tickets without due for given vehicle
    this.ticketsWithoutDue.forEach(item =>
      suggestions.push({
        id: item.id,
        ref: item,
        refType: ResourceEnum.TICKET
      })
    );

    // suggest reports for given vehicle
    this.reports.map(item => {
      suggestions.push({
        id: item.id,
        ref: item,
        refType: ResourceEnum.REPORT
      });
    });

    return suggestions;
  }

  updateContracts(contracts?: MrfiktivLeasingContractGen[]) {
    if (!this.vehicle.contracts) this.vehicle.contracts = [];
    if (!contracts) contracts = [];

    this.vehicle.contracts.splice(0, this.vehicle.contracts.length, ...contracts);

    return this;
  }

  updateRegistration(registration?: MrfiktivVehicleRegistrationViewModelGen) {
    this.vehicle.registration = registration;
  }

  updateBlueprints(blueprint: MrfiktivBlueprintElementViewmodelGen[]) {
    this.vehicle.blueprints = blueprint;
  }

  updateMileages(mileages?: MrfiktivMileageGen[]) {
    if (!this.vehicle.mileages) this.vehicle.mileages = [];
    if (!mileages) mileages = [];

    this.vehicle.mileages.splice(0, this.vehicle.mileages.length, ...mileages);

    return this;
  }
}

/**
 * Store to handle aggregation of data related to vehicles of a fleet
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "fleet-aggregation",
  store
})
export class FleetAggregationStore extends VuexModule {
  /**
   * List of suggestions of references to help create a ticket for a vehicle
   * initialized via updateSuggestions
   */
  referenceSuggestions: IRefSuggestion[] = [];

  vehicleAggregationMap: Map<string, VehicleAggregation> = new Map();

  private _vehicleAggregationMapKey = 0;

  /**
   * indeicates if the vehicle aggregation map has changed
   */
  get vehicleAggregationMapKey() {
    return this._vehicleAggregationMapKey;
  }

  @Mutation
  mutateVehicleAggregationMapKey() {
    this._vehicleAggregationMapKey++;
  }

  /**
   * Returns everything everywhere all at once that can be shown in a calendar
   */
  @Action
  getEvents(timeFrame: AggregationTimeFrame): VehicleEventCollection {
    // list of events seperated by event type
    const events = new VehicleEventCollection();

    for (const vehicleAggregation of this.vehicleAggregationMap.values()) {
      const vehicleEvents = vehicleAggregation.getEvents(timeFrame);

      for (const category of Object.values(VehicleEventTypeEnum)) {
        const categoryEvents = vehicleEvents[category] ?? [];
        (events[category] as (VehicleEvent & EventVehicleExtension)[]).push(
          ...(categoryEvents as (VehicleEvent & EventVehicleExtension)[])
        );
      }
    }

    return events;
  }

  /**
   * Looks up aggregated data for a given vehicle
   */
  @Action
  getVehicleAggregation(vehicleId: string) {
    return this.vehicleAggregationMap.get(vehicleId);
  }

  /**
   * Looks up aggregated data for a given vehicle
   */
  @Action
  createVehicleAggregation(vehicle: IVehicle) {
    const helper = new VehicleAggregation(vehicle);
    this.vehicleAggregationMap.set(vehicle.id, helper);
    this.context.commit("mutateVehicleAggregationMapKey");

    return helper;
  }

  /**
   * Go over events, tickets, reports and put them into the vehicle aggregation they belong to
   */
  @Action
  async parse(vehicles: IVehicle[]) {
    for (const vehicle of vehicles) {
      const helper = await this.getVehicleAggregation(vehicle.id);
      if (!helper) {
        await this.createVehicleAggregation(vehicle);
      }
    }

    this.parseEvents(EventListModule.events);
    this.parseTickets(TicketModule.entities);
    this.parseReports(ReportPaginationModule.entities);

    return this;
  }

  /**
   * Adds suggestion to list of suggestions. Makes sure that there are no dupes
   * @param suggestion
   * @returns
   */
  @Action
  private addSuggestion(suggestion: IRefSuggestion) {
    const index = this.referenceSuggestions.findIndex(s => s.id === suggestion.id);
    if (index > -1) {
      this.referenceSuggestions.splice(index, 1, suggestion);
      return;
    }
    this.referenceSuggestions.push(suggestion);
  }

  /**
   * go over given vehicle and collect suggestions for it. Also updates the aggregated data of said vehicle
   *
   * @param param0
   */
  @Action
  private async updateSuggestionsPerVehicle({ month, year, vehicleId }: AggregationTimeFrame & { vehicleId: string }) {
    let helper = await this.getVehicleAggregation(vehicleId);

    if (!helper) {
      const partnerId = PartnerModule.partner.id;
      const res = await vehicleService.getOne(partnerId, vehicleId);
      const vehicle = new Vehicle(res);
      VehicleAccessLayer.set(vehicle);
      helper = await this.createVehicleAggregation(vehicle);
    }
    // add suggestions that are already in helper, for quick results
    for (const suggestion of helper.getSuggestions({ month, year }) ?? []) {
      this.addSuggestion(suggestion);
    }

    // update helper in background and add them once done, for slow but up-to-date results
    await helper.initializeAsync({ month, year });
    for (const suggestion of helper.getSuggestions({ month, year }) ?? []) {
      this.addSuggestion(suggestion);
    }
  }

  /**
   * go over all selected vehicles and collects suggestions based on them. Also updates the aggregated data of said vehicle
   * results available in referenceSuggestions property of the store
   *
   * @param param0
   */
  @Action
  async updateSuggestions({ month, year, selected }: AggregationTimeFrame & { selected: MrfiktivReferenceGen[] }) {
    this.referenceSuggestions.splice(0);

    const promises = [];
    for (const sel of selected) {
      if (sel.refType === BackendResourceEnum.VEHICLE) {
        promises.push(
          this.updateSuggestionsPerVehicle({
            month,
            year,
            vehicleId: sel.refId
          }).catch(Vue.$log.error)
        );
      }
    }

    await Promise.all(promises);
  }

  @Action
  removeAggregation(vehicleId: string): void {
    this.vehicleAggregationMap.delete(vehicleId);
  }

  /**
   * Go over tickets and put them into the vehicle aggregation they belong to
   */
  @Action
  async parseTickets(tickets: MrfiktivTicketViewModelGen[]) {
    if (!this.vehicleAggregationMap.size) return;

    for (const ticket of tickets) {
      if (ticket.state === TicketStatusEnum.OPEN) {
        for (const ref of ticket.refs ?? []) {
          if (ref.refType === BackendResourceEnum.VEHICLE) {
            await this.removeTicket(ticket.number);
            const helper = await this.getVehicleAggregation(ref.refId);
            helper?.addTicket(ticket);
            this.context.commit("mutateVehicleAggregationMapKey");
          }
        }
      }
    }

    return this;
  }

  /**
   * Go over all tickets and remove the one in question
   * @param ticketNumber
   */
  @Action
  async removeTicket(ticketNumber: number) {
    if (!this.vehicleAggregationMap.size) return;

    for (const aggregation of this.vehicleAggregationMap.values()) {
      const index = aggregation.ticketsWithoutDue.findIndex(t => t.number === ticketNumber);
      if (index > -1) {
        aggregation.ticketsWithoutDue.splice(index, 1);
        this.context.commit("mutateVehicleAggregationMapKey");
        // Note: A ticket may be in multiple vehicles! (continue - not return)
        continue;
      }

      for (const timeFrame of aggregation.vehicleAggregationTimeFrameMap.map.values()) {
        for (const [key, value] of timeFrame.aggregation.ticket) {
          if (value.data[0].number === ticketNumber) {
            timeFrame.delete(VehicleEventTypeEnum.TICKET, key);
            this.context.commit("mutateVehicleAggregationMapKey");
            // Note: A ticket may be in multiple vehicles! (continue - not return)
            continue;
          }
        }
      }
    }
  }

  @Action
  async replaceTicket(ticket: MrfiktivTicketViewModelGen) {
    await this.removeTicket(ticket.number);
    await this.parseTickets([ticket]);
  }

  /**
   * Go over events and put them into the vehicle aggregation they belong to
   */
  @Action
  async parseEvents(events: IEventUIDto[]) {
    if (!this.vehicleAggregationMap.size) return;

    for (const event of events) {
      if (event.vehicleId) {
        const helper = await this.getVehicleAggregation(event.vehicleId);
        helper?.addVehicleEvent(event);
        this.context.commit("mutateVehicleAggregationMapKey");
      }
    }

    return this;
  }

  /**
   * removes all events for a vehicle
   */
  @Action
  async removeEventsByVehicle(vehicleId: string) {
    if (!this.vehicleAggregationMap.size) return;

    const vehicleAggregation = await this.getVehicleAggregation(vehicleId);

    vehicleAggregation?.vehicleAggregationTimeFrameMap.map.forEach(v => {
      v.clear(VehicleEventTypeEnum.EVENT);
    });
    this.context.commit("mutateVehicleAggregationMapKey");
  }

  @Action
  async removeEvent(event: IEventUIDto) {
    if (!this.vehicleAggregationMap.size) return;
    if (!event.vehicleId) return;

    const vehicleAggregation = await this.getVehicleAggregation(event.vehicleId);

    vehicleAggregation?.vehicleAggregationTimeFrameMap.map.forEach(timeFrame => {
      const key = new Date(event.start).toString() + (event.id ?? event.recurringEventId);
      if (timeFrame.aggregation.event.get(key)) {
        timeFrame.delete(VehicleEventTypeEnum.EVENT, key);
        this.context.commit("mutateVehicleAggregationMapKey");
      }
    });
  }

  /**
   * Go over reports and put them into the vehicle aggregation they belong to
   */
  @Action
  async parseReports(reports: MrfiktivReportViewModelGen[]) {
    if (!this.vehicleAggregationMap.size) return;

    for (const report of reports) {
      if (
        report.vehicleId &&
        (report.progressStatus === ProgressStatusEnum.IN_PROGRESS || report.progressStatus === ProgressStatusEnum.NEW)
      ) {
        const helper = await this.getVehicleAggregation(report.vehicleId);

        helper?.addReport(report);
        this.context.commit("mutateVehicleAggregationMapKey");
      }
    }

    return this;
  }

  /**
   * Go through every single report of every single vehicle and remove it if it is the one we are looking to remove
   * Note that the assumption is that a report can only occur in one vehicle at most.
   * Also the implementation iterates over vehicles instead of looking into the vehicle that has the vehicleId given in the report for the off-chance that a report is moved from vehicle A to vehicle B
   * @param reportId
   */
  @Action
  async removeReport(reportId: string) {
    for (const vehicle of this.vehicleAggregationMap.values()) {
      const index = vehicle.reports.findIndex(v => v.id === reportId);
      if (index > -1) {
        vehicle.reports.splice(index, 1);
        this.context.commit("mutateVehicleAggregationMapKey");
        return;
      }
    }
  }

  @Action
  async replaceReport(report: MrfiktivReportViewModelGen) {
    await this.removeReport(report.id);
    await this.parseReports([report]);
  }
}

/**
 * Module to handle aggregation of data related to vehicles of a fleet
 */
export const FleetAggregationModule = getModule(FleetAggregationStore);
