import chargingStationAdminService from "@/services/thg/services/chargingStationAdminService";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ThgChargingStationViewModelGen, ThgUpdateChargingStationDtoGen } from "../../services/thg/v1/data-contracts";

/**
 * Store to handle charging stations for admin
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "charging-station-admin",
  store
})
export class ChargingStationAdminStore extends VuexModule {
  /**
   * A single active charing station
   */
  private _activeChargingStation: ThgChargingStationViewModelGen | undefined = undefined;

  /**
   * All fetched charing stations
   */
  private _chargingStations: ThgChargingStationViewModelGen[] = [];

  /**
   * Charging Stations
   */

  /**
   * Gets all charging stations
   */
  get charingStations(): ThgChargingStationViewModelGen[] {
    return this._chargingStations;
  }

  /**
   * mutation for all charing stations
   * @param chargingStations charging stations to put into store
   */
  @Mutation
  private _mutateChargingStations(chargingStations: ThgChargingStationViewModelGen[]): void {
    this._chargingStations = chargingStations;
  }

  /**
   * Fetches all charging stations for current user
   */
  @Action
  async getChargingStations(): Promise<void> {
    const stations = await chargingStationAdminService.getAll();
    this.context.commit("_mutateChargingStations", stations);
  }

  /**
   * Fetches all charging stations for current user
   */
  @Action
  async getManyChargingStations(ids: string[]): Promise<ThgChargingStationViewModelGen[]> {
    const stations: ThgChargingStationViewModelGen[] = [];
    while (ids.length > 0) {
      const block = ids.splice(0, 80);
      const found = await chargingStationAdminService.getMany({ ids: block });
      stations.push(...found);
    }

    this.context.commit("_mutateChargingStations", stations);
    return stations;
  }

  /**
   * Removes charing stations from store
   */
  @Action
  clearChargingStations() {
    this.context.commit("_mutateChargingStations", []);
  }

  /**
   * Active Charging Station
   */

  /**
   * Returns the active charing station
   */
  get activeChargingStation(): ThgChargingStationViewModelGen | undefined {
    return this._activeChargingStation;
  }

  /**
   * Sets the active charging station.
   *
   * @param activeChargingStation the charging station to put into active
   */
  @Mutation
  private _mutateActiveChargingStation(activeChargingStation: ThgChargingStationViewModelGen): void {
    this._activeChargingStation = activeChargingStation;
  }

  /**
   * Gets one charging station.
   *
   * @param id the id of the charging station to be fetched
   */
  @Action
  async getChargingStation(id: string): Promise<ThgChargingStationViewModelGen> {
    const station = await chargingStationAdminService.get(id);
    this.context.commit("_mutateActiveChargingStation", station);

    return station;
  }

  /**
   * Removes a charging station.
   *
   * @param id the charging station to be removed
   */
  @Action
  async removeChargingStation(id: string): Promise<void> {
    await chargingStationAdminService.remove(id);
    this.context.commit("_mutateActiveChargingStation", undefined);
  }

  /**
   * Update a charging station.
   *
   * @param id the id to be update
   * @param data the updated properties
   */
  @Action
  async updateChargingStation(data: { id: string; data: ThgUpdateChargingStationDtoGen }): Promise<void> {
    const updated = await chargingStationAdminService.update(data.id, data.data);
    this.context.commit("_mutateActiveChargingStation", updated);
  }

  /**
   * Removes all charging stations from the store.
   */
  @Action
  clearChargingStation() {
    this.context.commit("_mutateActiveChargingStation", undefined);
  }
}

export const ChargingStationAdminModule = getModule(ChargingStationAdminStore);
