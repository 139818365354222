var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-with-banner', {
    attrs: {
      "showProgress": false,
      "showFooter": false,
      "showLoginButton": _vm.showLoginButton
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_vm.createdBooking ? _c('booking-update', {
          attrs: {
            "isMobile": _vm.isMobile,
            "isBookingUpdated": _vm.isBookingUpdated,
            "booking": _vm.createdBooking,
            "partnerId": _vm.partnerId
          },
          on: {
            "click": _vm.update
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }