


























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ISignRequest } from "@/models/sign-request.entity";
import { Component, Prop } from "vue-property-decorator";
import SignInitializeCard from "./SignInitializeCard.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";

@Component({
  components: {
    SignInitializeCard,
    ConfirmActionDialog
  }
})
export default class SignInitializeDialog extends DarkModeHighlightMixin {
  /**
   * The  signature request
   */
  @Prop({})
  request?: ISignRequest;

  @Prop({ default: false })
  isBtn?: boolean;

  dialog = false;

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
  }
}
