import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";
import { VehicleStateEnum } from "@/lib/enum/vehicleState.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { GoToHelper } from "@/lib/utility/goToHelper";
import vehicleService from "@/services/mrfiktiv/services/vehicleService";
import {
  MrfiktivBlueprintElementViewmodelGen,
  MrfiktivLeasingContractGen,
  MrfiktivMileageGen,
  MrfiktivVehicleDocumentSchemaGen,
  MrfiktivVehicleRegistrationViewModelGen,
  MrfiktivVehicleViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";
import VueRouter from "vue-router";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import { VehicleRegistration } from "./vehicle-registration.entity";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";

/**
 * Ui class for vehicles
 */
@IsFilterable
class VehicleBase implements MrfiktivVehicleViewModelGen {
  /**
   * @inheritdoc
   */
  id: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.vehicle.id"
  })
  get _id() {
    return this.id;
  }

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.vehicle.partnerId"
  })
  partnerId: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.numberplate"
  })
  numberplate: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.registration.identificationnumber"
  })
  identificationnumber?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.displayName"
  })
  displayName?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.DATE,
    displayName: "objects.vehicle.commissioningDate"
  })
  commissioningDate?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(VehicleStateEnum).map(e => {
        return {
          text: `enums.VehicleStateEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    },
    displayName: "objects.vehicle.state"
  })
  state?: VehicleStateEnum;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    config: { items: [true, false] },
    displayName: "objects.vehicle.available"
  })
  available?: boolean;

  /**
   * @inheritdoc
   */
  tags?: string[];

  /**
   * @inheritdoc
   */
  registration?: MrfiktivVehicleRegistrationViewModelGen;

  /**
   * @inheritdoc
   */
  contracts?: MrfiktivLeasingContractGen[];

  /**
   * @inheritdoc
   */
  mileages?: MrfiktivMileageGen[];

  /**
   * @inheritdoc
   */
  blueprints: MrfiktivBlueprintElementViewmodelGen[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.currentDriver"
  })
  currentDriver?: string;

  /**
   * @inheritdoc
   */
  drivers?: string[];

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.vehicle.note"
  })
  note?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  /**
   * @inheritdoc
   */
  documents?: MrfiktivVehicleDocumentSchemaGen[];

  loading = false;

  get commissioningDateString() {
    if (this.commissioningDate) {
      return new Date(this.commissioningDate).toISOString().slice(0, 10);
    }

    return undefined;
  }

  set commissioningDateString(value: string | undefined) {
    if (value) {
      this.commissioningDate = new Date(value).toISOString();
    }
  }

  constructor(vehicle: Partial<MrfiktivVehicleViewModelGen>) {
    this.id = vehicle.id ?? "";
    this.partnerId = vehicle.partnerId ?? "";
    this.numberplate = vehicle.numberplate ?? "";
    this.identificationnumber = vehicle.identificationnumber;
    this.displayName = vehicle.displayName;
    this.commissioningDate = vehicle.commissioningDate;
    this.state = (vehicle.state as VehicleStateEnum) || undefined;
    this.available = vehicle.available;
    this.tags = vehicle.tags;
    this.registration = new VehicleRegistration(vehicle.registration);
    this.contracts = vehicle.contracts;
    this.mileages = vehicle.mileages;
    this.currentDriver = vehicle.currentDriver;
    this.drivers = vehicle.drivers;
    this.note = vehicle.note;
    this.timestamp = new Timestamp(vehicle.timestamp);
    this.documents = vehicle.documents;
    this.blueprints = vehicle.blueprints || [];
  }

  map(vehicle: MrfiktivVehicleViewModelGen) {
    this.id = vehicle.id;
    this.partnerId = vehicle.partnerId;
    this.numberplate = vehicle.numberplate;
    this.identificationnumber = vehicle.identificationnumber;
    this.displayName = vehicle.displayName;
    this.commissioningDate = vehicle.commissioningDate;
    this.state = vehicle.state as VehicleStateEnum;
    this.available = vehicle.available;
    this.tags = vehicle.tags;
    this.registration = new VehicleRegistration(vehicle.registration);
    this.contracts = vehicle.contracts;
    this.mileages = vehicle.mileages;
    this.currentDriver = vehicle.currentDriver;
    this.drivers = vehicle.drivers;
    this.note = vehicle.note;
    this.timestamp = new Timestamp(vehicle.timestamp || this.timestamp);
    this.documents = vehicle.documents;
    this.blueprints = vehicle.blueprints || [];
  }

  get registrationDate() {
    const registration = this.registration;
    if (
      !registration ||
      !registration.firstregistrationDay ||
      !registration.firstregistrationMonth ||
      !registration.firstregistrationYear
    ) {
      return "";
    }

    return `${registration.firstregistrationYear}-${registration.firstregistrationMonth?.padStart(
      2,
      "0"
    )}-${registration.firstregistrationDay?.padStart(2, "0")}`;
  }

  set registrationDate(registrationDate: string) {
    if (!registrationDate) {
      return;
    }

    if (registrationDate) {
      const [year, month, day] = registrationDate.split("-");

      if (!this.registration) {
        this.registration = new VehicleRegistration();
      }

      this.registration.firstregistrationDay = day;
      this.registration.firstregistrationMonth = month;
      this.registration.firstregistrationYear = year;
    }
  }

  async fetch() {
    this.loading = true;
    try {
      const vehicle = await vehicleService.getOne(this.partnerId, this.id);
      this.map(vehicle);
      VehicleAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      throw e;
    } finally {
      this.loading = false;
    }
    return this;
  }

  async openDetail($router: VueRouter, tab?: VehicleTabs, newTab?: boolean) {
    await new GoToHelper($router).goToVehicleDetail(this.id, this.partnerId, tab, newTab);
  }
}

type IVehicle = VehicleBase;
const Vehicle = Filter.createForClass(VehicleBase);

export { IVehicle, Vehicle };
