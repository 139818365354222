











































































import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { BookingSlotViewModelGen } from "@/services/booking/v1/data-contracts";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import BookingNavigation from "./BookingNavigation.vue";

@Component({ components: { BookingNavigation } })
export default class BookingCalendar extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  @Prop({ default: false })
  dark!: boolean;

  @Prop({ default: true })
  showNavigation!: boolean;

  back() {
    AvailabilityModule.setServices();
  }

  forward() {
    AvailabilityModule.setSlots();
  }

  /**
   * Show datepicker during loading on desktop but not on mobile
   */
  get displayDatePicker() {
    if (!this.isMobile) {
      return true;
    }
    if (this.isLoadingSlots) {
      return false;
    }
    return true;
  }

  get isLoadingSlots() {
    return AvailabilityModule.isLoadingSlots;
  }

  set isLoadingSlots(value: boolean) {
    AvailabilityModule.setIsLoadingSlots(value);
  }

  get selectedService() {
    return AvailabilityModule.selectedService;
  }

  get serviceId() {
    return AvailabilityModule.serviceId;
  }

  set serviceId(id: string) {
    AvailabilityModule.setServiceId(id);
  }

  get date() {
    return AvailabilityModule.date;
  }

  set date(newDate: string | null) {
    AvailabilityModule.setDate(newDate);
  }

  get pickerDate() {
    return AvailabilityModule.pickerDate;
  }

  set pickerDate(newDate: string | null) {
    AvailabilityModule.setPickerDate(newDate);
  }

  get slots(): BookingSlotViewModelGen[] {
    return AvailabilityModule.selectedService?.slots || [];
  }

  get partner() {
    return ReportModule.partner || PartnerModule.partner;
  }

  get partnerId() {
    return this.partner._id;
  }

  get today() {
    return new Date().toISOString().substring(0, 10);
  }

  get color() {
    return getDefaultPartnerColor();
  }

  /**
   * Returns true if date is within the ordered slots (0 lowest to length -1 highest).
   * @param val the date
   */
  allowedDates(val: string): boolean {
    if (!this.slots || this.slots.length < 1) {
      return false;
    }

    return this.slotsAsDates.includes(val);
  }

  get slotsAsDates(): string[] {
    // Return slots as the exact year-month-day (2023-04-13)
    return this.slots.map(slot => new Date(slot.start).toISOString().substring(0, 10));
  }

  get backGround() {
    return "background: #424242";
  }

  @Watch("pickerDate")
  dateChange(pickedMonth: string) {
    if (!isNaN(Date.parse(pickedMonth))) {
      const currentDate = new Date();
      const chosenDate = new Date(pickedMonth);

      /**
       * If we go back to the current month and year fetch with the current date(default),
       * send the chosen date otherwise
       * */
      if (chosenDate.getMonth() === currentDate.getMonth() && chosenDate.getFullYear() === currentDate.getFullYear()) {
        this.$emit("date-change");
      } else {
        this.$emit("date-change", chosenDate);
      }
    }
  }

  gotoSlots() {
    if (this.isMobile) {
      AvailabilityModule.setSlots();
    }
  }

  /**
   * On Mobile do not select first available day since its not good ux
   */
  mounted() {
    if (this.isMobile) {
      AvailabilityModule.setDate(null);
    } else {
      if (!this.date) {
        AvailabilityModule.setFirstAvailableDay();
      }
    }
  }
}
