























import { Component, Vue, Prop } from "vue-property-decorator";
import LoginCardDescription from "@/components/login/LoginCardDescription.vue";
import { LoginModule } from "@/store/modules/login.store";
import SignUpForm from "./SignUpForm.vue";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: { LoginCardDescription, SignUpForm }
})
export default class SignUpCard extends Vue {
  @Prop({ default: "logo" })
  logo!: string;

  @Prop({ default: "title" })
  title!: string;

  @Prop({ default: "text" })
  text!: string;

  @Prop({ default: false })
  isDescriptionCardActive?: boolean;

  @Prop({ default: false })
  showLogoOnMobile?: boolean;

  width = window.innerWidth;

  get isBreakpointReached() {
    return this.width >= 960;
  }

  onResize() {
    this.width = window.innerWidth;
  }

  async mounted() {
    let redirectTo = "/";

    if (this.$route.query.charging !== undefined) {
      redirectTo = "/charging-station/new";
    }

    if (this.$route.query.vehicle !== undefined) {
      redirectTo = "/" + ReportScreenEnum.thghome;
    }

    if (this.$route.query.affiliate !== undefined) {
      redirectTo = "/invite";
    }

    this.$log.info(`redirectTo ${redirectTo}`);

    LoginModule.setRedirect(redirectTo);
  }

  beforeUnmount() {
    LoginModule.purgeStore();
  }
}
