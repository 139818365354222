import { Filter, FilterTypes, IsFilterable, FilterConfig } from "@/lib/filterable";
import { MrfiktivTimestampDocumentGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgTimeStampViewModelGen } from "@/services/thg/v1/data-contracts";
import { detailedDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";

@IsFilterable
class TimestampBase implements ThgTimeStampViewModelGen {
  @FilterConfig({
    type: FilterTypes.DATE,
    displayName: "objects.timestamp.created"
  })
  created: string;

  lastModified: string;

  get createdReadable() {
    return simpleDoubleDigitDate(this.created);
  }

  get detailedDate() {
    return detailedDate(this.created);
  }

  get createdNoTime() {
    const created = new Date(this.created);
    created.setHours(0, 0, 0, 0);
    return created.toISOString();
  }

  constructor(timestamp?: ThgTimeStampViewModelGen) {
    this.created = timestamp?.created ?? "";
    this.lastModified = timestamp?.lastModified ?? "";
  }
}

type ITimestamp = TimestampBase;
const Timestamp = Filter.createForClass(TimestampBase);

export { ITimestamp, Timestamp };

@IsFilterable
class TimestampDocumentBase extends TimestampBase implements MrfiktivTimestampDocumentGen {
  modified: string[];

  constructor(timestamp?: MrfiktivTimestampDocumentGen) {
    super(timestamp);
    this.modified = timestamp?.modified ?? [];
  }
}

type ITimestampDocument = TimestampDocumentBase;
const TimestampDocument = Filter.createForClass(TimestampDocumentBase);

export { ITimestampDocument, TimestampDocument };
