import { IThg } from "@/models/thg.entity";
import { ThgBaseImageGen, ThgImageCropDataGen, ThgImageEditDataDtoGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "clipper",
  store
})
export class ClipperStore extends VuexModule {
  // Data
  private _currentImageIndex = 0;
  private _images: ThgBaseImageGen[] = [];
  private _edits: ThgImageEditDataDtoGen[] = [];
  private _previousEdit: ThgImageEditDataDtoGen | null = null;
  private _imageStyling = "";
  private _isCroppingActive = false;
  private _isCroppingInitiallyActive = true;
  private _isLoading = false;

  // Getters
  public get currentImageIndex() {
    return this._currentImageIndex;
  }
  public get src(): string {
    if (this._currentImageIndex > this._images.length - 1) {
      Vue.$log?.error("getting src", this._currentImageIndex, this._images.length);

      return "";
    }
    return this._images[this._currentImageIndex].url;
  }
  public get isMultipleImages() {
    return this._images.length > 1;
  }
  public get isImages() {
    return this._images.length !== 0;
  }
  public get crop(): ThgImageCropDataGen | undefined {
    return this._edits[this._currentImageIndex]?.crop;
  }
  public get rotation() {
    return this._edits[this._currentImageIndex]?.rotation || 0;
  }
  public get edits() {
    return this._edits;
  }
  public get previousEdit() {
    return this._previousEdit;
  }
  public get imageStyling() {
    return this._imageStyling;
  }
  public get isCroppingActive() {
    return this._isCroppingActive;
  }
  public get isCroppingInitiallyActive() {
    return this._isCroppingInitiallyActive;
  }
  public get images() {
    return this._images;
  }
  public get isLoading() {
    return this._isLoading;
  }

  // Mutations
  @Mutation
  private _mutateCurrentImageIndex(currentImageIndex: number) {
    this._currentImageIndex = currentImageIndex;
  }
  @Mutation
  private _mutateImages(images: ThgBaseImageGen[]) {
    this._images.splice(0, this._images.length, ...images);
  }
  @Mutation
  private _mutateEdits(edits: ThgImageEditDataDtoGen[]) {
    this._edits.splice(0, this._edits.length, ...edits);
  }
  @Mutation
  private _mutateCrop(crop: ThgImageCropDataGen) {
    Vue.$log.debug("_mutateCrop");
    this._edits.splice(this._currentImageIndex, 1, { ...this._edits[this._currentImageIndex], crop });
  }
  @Mutation
  private _mutateRotation(rotation: number) {
    this._edits.splice(this._currentImageIndex, 1, { ...this._edits[this._currentImageIndex], rotation });
  }
  @Mutation
  private _mutatePreviousEdit(previousEdit: ThgImageEditDataDtoGen) {
    this._previousEdit = previousEdit;
  }
  @Mutation
  private _mutateImageStyling(imageStyling: string) {
    this._imageStyling = imageStyling;
  }
  @Mutation
  private _mutateIsCroppingActive(isCroppingActive: boolean) {
    this._isCroppingActive = isCroppingActive;
  }
  @Mutation
  private _mutateIsCroppingInitiallyActive(isCroppingInitiallyActive: boolean) {
    this._isCroppingInitiallyActive = isCroppingInitiallyActive;
  }
  @Mutation
  private _mutateIsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }
  // Private actions / wrappers for mutations
  @Action
  private mutateImages(images: ThgBaseImageGen[]) {
    this.context.commit("_mutateImages", images);
  }
  @Action
  private mutateEdits(edits: ThgImageEditDataDtoGen[]) {
    this.context.commit("_mutateEdits", edits);
  }
  @Action
  private mutateCrop(crop: ThgImageCropDataGen | undefined) {
    Vue.$log.debug("_mutateCrop", crop);
    this.context.commit("_mutateCrop", crop);
  }
  @Action
  private mutateRotation(rotation: number) {
    let rotationFactor = Math.round(rotation / 90) % 4;
    rotationFactor = rotationFactor < 0 ? rotationFactor + 4 : rotationFactor;
    rotationFactor *= 90;
    this.context.commit("_mutateRotation", rotationFactor);
  }
  @Action
  private mutateCurrentImageIndex(currentImageIndex: number) {
    this.context.commit("_mutateCurrentImageIndex", currentImageIndex);
  }
  @Action
  private mutateImageStyling(imageStyling: string) {
    this.context.commit("_mutateImageStyling", imageStyling);
  }
  @Action
  private mutateIsCroppingActive(isCroppingActive: boolean) {
    this.context.commit("_mutateIsCroppingActive", isCroppingActive);
  }
  @Action
  private mutateIsCroppingInitiallyActive(isCroppingInitiallyActive: boolean) {
    this.context.commit("_mutateIsCroppingInitiallyActive", isCroppingInitiallyActive);
  }
  @Action
  private mutateIsLoading(isLoading: boolean) {
    this.context.commit("_mutateIsLoading", isLoading);
  }
  @Action
  private mutatePreviousEdit(previousEdit: ThgImageEditDataDtoGen) {
    this.context.commit("_mutatePreviousEdit", previousEdit);
  }
  // Public actions
  @Action
  public initializeForImages(images: ThgBaseImageGen[]) {
    Vue.$log.debug("mutating images", images.length);

    this.mutateImages(images);

    const edits: ThgImageEditDataDtoGen[] = [];
    images.forEach(i => {
      const editData = i.editData || {};
      edits.push(editData);
    });

    this.mutateCurrentImageIndex(0);
    this.mutateEdits(edits);
  }
  @Action
  initializeForThg(thg: IThg) {
    Vue.$log.debug("selcted thg changed. updating clipper store");
    this.initializeForImages(thg.registrationImages);
  }
  @Action
  public incrementCurrentImageIndex() {
    let newIndex = this.currentImageIndex + 1;
    if (newIndex >= this._images.length) {
      newIndex = 0;
    }
    this.mutateCurrentImageIndex(newIndex);
  }
  @Action
  public decrementCurrentImageIndex() {
    let newIndex = this.currentImageIndex - 1;
    if (newIndex < 0) {
      newIndex = this._images.length - 1;
    }
    this.mutateCurrentImageIndex(newIndex);
  }
  @Action
  public setImageStyling(imageStyling: string) {
    this.mutateImageStyling(imageStyling);
  }
  @Action
  public setIsCroppingActive(isCroppingActive: boolean) {
    this.mutateIsCroppingActive(isCroppingActive);
  }
  @Action
  public setIsCroppingInitiallyActive(isCroppingInitiallyActive: boolean) {
    this.mutateIsCroppingInitiallyActive(isCroppingInitiallyActive);
  }
  @Action
  public getCropForIndex(index: number): ThgImageCropDataGen | undefined {
    return this._edits[index].crop;
  }
  @Action
  public getRotationForIndex(index: number) {
    return this._edits[index].rotation || 0;
  }
  @Action
  public setCrop(crop: ThgImageCropDataGen | undefined) {
    this.mutateCrop(crop);
  }
  @Action
  public setRotation(rotation: number) {
    this.mutateRotation(rotation);
  }
  @Action
  public setIsLoading(isLoading: boolean) {
    this.mutateIsLoading(isLoading);
  }
  @Action
  public setPreviousEdit(previousEdit: ThgImageEditDataDtoGen) {
    this.mutatePreviousEdit(previousEdit);
  }
}

export const ClipperModule = getModule(ClipperStore);
