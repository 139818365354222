var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [!_vm.isProd ? _c('v-system-bar', {
    staticStyle: {
      "z-index": "20"
    },
    attrs: {
      "color": "orange",
      "id": "systembar",
      "fixed": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-alert-outline")]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_vm._v("Development Environment")])];
      }
    }], null, false, 716484692)
  }, [_c('span', [_vm._v("Development environment is strictly meant for testing purposes and is not bound to any SLA")])]), _c('v-spacer')], 1) : _vm._e(), !_vm.isHeadless ? _c('the-navigation-base', {
    staticClass: "no-print"
  }) : _vm._e(), _c('v-main', [!_vm.isHeadless ? _c('v-app-bar', {
    staticClass: "no-print",
    attrs: {
      "app": "",
      "flat": "",
      "color": _vm.$vuetify.theme.dark ? _vm.backgroundDarkColor : _vm.backgroundColor
    }
  }, [_c('v-app-bar-nav-icon', {
    attrs: {
      "data-test-hamburger": "",
      "color": _vm.$vuetify.theme.dark ? 'white' : 'grey darken-4'
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleDrawer.apply(null, arguments);
      }
    }
  }), _vm.isAuthenticated && _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER) ? _c('the-partner-selector-list', {
    staticClass: "no-print d-none d-md-flex",
    staticStyle: {
      "min-width": "300px",
      "z-index": "1"
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-toolbar-title', {
    style: _vm.isAuthenticated && !_vm.isMobile && _vm.partners.length > 0 && _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER) ? 'margin-left: -150px' : ''
  }, [_c('v-img', {
    staticStyle: {
      "max-height": "40px"
    },
    attrs: {
      "src": _vm.tryHeaderLogo,
      "contain": ""
    },
    on: {
      "click": _vm.logoClick
    }
  })], 1), _vm.isAuthenticated ? [_c('v-spacer'), _vm.isQuicklinks && _vm.userLoaded ? _c('quick-link-menu') : _vm._e(), _vm.isInboxEnabled && _vm.readMessage && _vm.userLoaded ? _c('v-btn', {
    attrs: {
      "icon": "",
      "fab": "",
      "color": "light"
    },
    on: {
      "click": _vm.goToInbox
    }
  }, [_c('v-icon', [_vm._v("mdi-email-outline")])], 1) : _vm._e(), _c('profile-menu', {
    attrs: {
      "isEndUser": _vm.isEndUser
    }
  })] : _vm.showLoginButton ? [_c('v-spacer'), !_vm.isAuthenticated ? _c('v-btn', {
    attrs: {
      "color": _vm.color,
      "icon": ""
    },
    on: {
      "click": _vm.goToLogin
    }
  }, [_c('v-icon', [_vm._v("mdi-account")])], 1) : _vm._e()] : [_c('v-spacer', {
    staticClass: "ml-12"
  })], _vm.progress ? _c('v-progress-linear', {
    attrs: {
      "value": _vm.progress,
      "absolute": "",
      "bottom": "",
      "color": _vm.color
    }
  }) : _vm._e()], 2) : _vm._e(), _vm.displayDivider ? _c('v-divider', {
    staticClass: "no-print"
  }) : _vm._e(), _c('v-container', {
    staticClass: "pa-0 print-at-pagestart",
    attrs: {
      "fluid": ""
    }
  }, [_vm._t("default")], 2), _vm.partner && _vm.showFooter && !_vm.isHeadless ? _c('PartnerFooter', {
    staticClass: "d-none d-md-flex",
    attrs: {
      "partnerProp": _vm.partner,
      "color": _vm.color
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }