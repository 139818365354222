



































































import { requiredRule } from "@/lib/rules/requiredRule";
import LoginErrorMixin from "@/mixins/LoginErrorMixin.vue";
import LoginMixin from "@/mixins/LoginMixin.vue";
import LoginNewPasswordMixin from "@/mixins/LoginNewPasswordMixin.vue";
import { ThgCreateUserDtoGen, ThgSignUpUserDtoGen } from "@/services/thg/v1/data-contracts";
import { LoginModule } from "@/store/modules/login.store";
import { Component, Mixins } from "vue-property-decorator";
import LoginComponentInputMail from "./LoginComponentInputMail.vue";
import LoginComponentInputPassword from "./LoginComponentInputPassword.vue";
import LoginComponentLayout from "./LoginComponentLayout.vue";
import LoginComponentMessage from "./LoginComponentMessage.vue";
import LoginComponentNavigationButton from "./LoginComponentNavigationButton.vue";
import LoginComponentPasswordRequirementList from "./LoginComponentPasswordRequirementList.vue";
import LoginComponentSubmitButton from "./LoginComponentSubmitButton.vue";

@Component({
  components: {
    LoginComponentLayout,
    LoginComponentInputMail,
    LoginComponentInputPassword,
    LoginComponentMessage,
    LoginComponentSubmitButton,
    LoginComponentPasswordRequirementList,
    LoginComponentNavigationButton
  }
})
export default class SignUpForm extends Mixins(LoginMixin, LoginNewPasswordMixin, LoginErrorMixin) {
  createUserDto: ThgCreateUserDtoGen = {
    firstName: "",
    lastName: "",
    userName: "",
    company: "",
    isMarketingOptIn: false,
    isTermsAccepted: false,
    isCompany: false,
    isTaxDeductible: false
  };

  async submit() {
    try {
      this.createUserDto.userName = this.mail;
      this.createUserDto.contact = {
        email: this.mail,
        phone: ""
      };

      const signUpDto: ThgSignUpUserDtoGen = {
        password: this.passwordRepeat,
        createUserDto: this.createUserDto
      };
      this.isLoading = true;
      await LoginModule.signUp(signUpDto);

      // set the password to enable auto login after code confirmed
      LoginModule.setPasswordBySuffix({ password: this.passwordRepeat, suffix: "" });

      this.isFormSubmitted = true;
      await this.handleSuccess("/login/confirmmail");
    } catch (error) {
      this.handleFail(error);
    } finally {
      this.isLoading = false;
    }
  }

  get requiredRule() {
    return [requiredRule()];
  }

  /**
   * Get input label
   */
  get i18n() {
    return this.$t("components.login.SignUpForm");
  }

  get isTermsAccepted(): boolean {
    return this.createUserDto.isTermsAccepted || false;
  }

  set isTermsAccepted(bool: boolean) {
    this.createUserDto.isTermsAccepted = bool;
  }

  get isMarketingContactAllowed(): boolean {
    return this.createUserDto.isMarketingOptIn || false;
  }

  set isMarketingContactAllowed(bool: boolean) {
    this.createUserDto.isMarketingOptIn = bool;
  }

  get isCompany(): boolean {
    return this.createUserDto.isCompany ?? false;
  }

  set isCompany(bool: boolean) {
    this.createUserDto.isCompany = bool;
    this.createUserDto.isTaxDeductible = bool;
    if (!this.isCompany) {
      this.createUserDto.company = "";
    }
  }
}
