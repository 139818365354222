import signService from "@/services/sign/services/sign.service";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  SignCreateSignDtoGen,
  SignDigitalSignatureRequestSignerViewModelGen,
  SignDigitalSignatureRequestViewModelGen,
  SignDigitalSignatureSignedViewModelGen
} from "../../services/sign/v1/data-contracts";

@Module({
  dynamic: true,
  namespaced: true,
  name: "sign-create",
  store
})
export class SignCreateStore extends VuexModule {
  private _request: SignDigitalSignatureRequestSignerViewModelGen = {} as any;
  private _signature: SignDigitalSignatureRequestViewModelGen = {} as any;

  private _signed: SignDigitalSignatureSignedViewModelGen | null = null;

  get request() {
    return this._request;
  }

  get signature() {
    return this._signature;
  }

  get signed() {
    return this._signed;
  }

  @Mutation
  mutateRequest(request: SignDigitalSignatureRequestSignerViewModelGen) {
    request.attachments?.map(attachment =>
      attachment.tokens.map(tokensPage => {
        return tokensPage.sort((a, b) => {
          const verticalDifference = a.coordinates.y - b.coordinates.y;
          if (Math.round(verticalDifference) === 0) {
            return a.coordinates.x - b.coordinates.x;
          }
          return verticalDifference;
        });
      })
    );

    this._request = request;
  }

  @Mutation
  mutateSignature(signature: SignDigitalSignatureRequestViewModelGen) {
    this._signature = signature;
  }

  @Mutation
  mutateSigned(signed: SignDigitalSignatureSignedViewModelGen) {
    this._signed = signed;
  }

  @Action
  async create(data: { partnerId: string; requestId: string; data: SignCreateSignDtoGen }) {
    const res = await signService.create(data.partnerId, data.requestId, data.data);

    this.context.commit("mutateRequest", res);

    return res;
  }

  @Action
  async find(data: { partnerId: string; requestId: string }) {
    const res = await signService.find(data.partnerId, data.requestId);

    this.context.commit("mutateRequest", res);

    return res;
  }

  @Action
  async findSigned({ partnerId, requestId }: { partnerId: string; requestId: string }) {
    const res = await signService.findSigned(partnerId, requestId);

    this.context.commit("mutateSigned", res);

    return res;
  }

  @Action
  async setDelivered({ partnerId, requestId }: { partnerId: string; requestId: string }) {
    await signService.setDelivered(partnerId, requestId);
  }
}

export const SignCreateModule = getModule(SignCreateStore);
