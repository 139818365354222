/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PublicImageCreatePublicImageDtoGen,
  PublicImageExceptionViewmodelGen,
  PublicImageImageViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PublicImageUpload<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags publicImageUpload
   * @name PublicImageControllerUpload
   * @summary Uploads an Image, max 2 mb
   * @request POST:/upload
   * @response `201` `PublicImageImageViewmodelGen` The id of the image
   * @response `413` `PublicImageExceptionViewmodelGen` Payload to large
   * @response `415` `PublicImageExceptionViewmodelGen` Unsupported Media Type
   * @response `500` `PublicImageExceptionViewmodelGen` Internal Server Error
   */
  publicImageControllerUpload = (data: PublicImageCreatePublicImageDtoGen, params: RequestParams = {}) =>
    this.http.request<PublicImageImageViewmodelGen, PublicImageExceptionViewmodelGen>({
      path: `/upload`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
}
