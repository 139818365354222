var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex flex-column",
    class: "ma-".concat(_vm.margin),
    attrs: {
      "outlined": _vm.outlined,
      "min-width": "".concat(_vm.minWidth, "px"),
      "width": _vm.width,
      "loading": _vm.loading,
      "elevation": 0
    }
  }, [!_vm.hideTitle ? _c('v-card-title', {
    staticClass: "title text-truncate",
    class: _vm.gradientStyle ? 'white--text' : '',
    staticStyle: {
      "min-height": "68px"
    },
    style: _vm.gradientStyle
  }, [_c('h4', {
    staticClass: "text-truncate",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm._t("header"), _vm._v(" " + _vm._s(_vm.title) + " ")], 2), _c('v-spacer'), _vm._t("actions")], 2) : _vm._e(), _vm.subtitle && !_vm.hideTitle ? _c('v-card-subtitle', {
    style: _vm.gradientStyle
  }, [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]) : !_vm.hideTitle && _vm.$slots['subtitle'] ? _c('v-card-subtitle', [_vm._t("subtitle")], 2) : _vm._e(), _vm.subtitle && _vm.hideTitle ? _c('v-row', {
    staticClass: "ma-1",
    attrs: {
      "align": "center"
    }
  }, [_vm.subtitle ? _c('v-card-subtitle', {
    style: _vm.gradientStyle
  }, [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]) : _vm._e(), _c('v-spacer'), _vm.hideTitle ? _vm._t("actions") : _vm._e()], 2) : _vm._e(), _c('v-card-text', {
    staticClass: "pt-1",
    class: _vm.slotClass,
    style: _vm.fullscreen ? '' : "max-height: ".concat(_vm.height)
  }, [_vm._t("main"), _vm._t("default")], 2), _c('v-card-actions', {
    staticClass: "px-6 py-4"
  }, [_vm._t("card-actions")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }