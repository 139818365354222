import { Action, Module, getModule } from "vuex-module-decorators";
import { ActivityLogBaseStore } from "./activity-log.store";
import store from "@/store/VuexPlugin";
import sharedActivityLogService from "@/services/shared/sharedActivityLogService";

@Module({
  dynamic: true,
  namespaced: true,
  name: "vehicle-activity-log",
  store
})
export class VehicleActivityLogStore extends ActivityLogBaseStore {
  @Action
  async loadDocumentsForVehicle(data: { partnerId: string; vehicleId: string }): Promise<any> {
    const activities = await sharedActivityLogService.getAllByReportForVehicle(data.partnerId, data.vehicleId);

    this.context.commit("mutateDocuments", activities?.data);

    return activities;
  }
}

export const VehicleActivityLogModule = getModule(VehicleActivityLogStore);
