var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('tooltip', {
    attrs: {
      "text": _vm.$t('components.template.editor.placeholder.title')
    }
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.placeholderDialog = !_vm.placeholderDialog;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-keyboard-space")])], 1)], 1), _vm.placeholderDialog ? _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.placeholderDialog,
      "title": _vm.$t('components.template.editor.placeholder.title'),
      "rightLabel": _vm.$t('components.template.editor.placeholder.confirm'),
      "supressKeyboardCancel": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.placeholderDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.placeholderDialog = $event;
      },
      "rightClick": _vm.onSelect,
      "leftClick": function leftClick($event) {
        _vm.placeholderDialog = false;
      }
    }
  }, [_c('template-editor-placeholder-dropdown', {
    ref: "dropdown",
    attrs: {
      "partnerId": _vm.partnerId
    }
  }), _c('small', [_vm._v(" " + _vm._s(_vm.$t("components.template.editor.placeholder.instruction")) + " ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }