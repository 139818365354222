var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": "Impressum"
    }
  }, [_c('v-container', {
    staticClass: "padded"
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_c('h1', [_vm._v("Impressum")])]), _c('v-card-text', [_c('p', {
    staticClass: "MsoNormal"
  }, [_c('b', [_c('span', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v("Angaben gemäß § 5 TMG")])])]), _c('p', {
    staticClass: "MsoNormal"
  }, [_c('span', {
    attrs: {
      "lang": "DE"
    }
  }, [_c('ul', {
    staticClass: "MsoNormal",
    staticStyle: {
      "list-style-type": "none"
    }
  }, [_c('li', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v("MMM Intelligence UG (haftungsbeschränkt)")]), _c('li', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v("Marie-Curie-Str. 3")]), _c('li', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v("49076 Osnabrück")]), _c('li', [_c('br')]), _c('li', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v("Telefon: +49 176 72884125")]), _c('li', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v("E-Mail: "), _c('a', {
    attrs: {
      "href": "mailto:info@mmmint.ai"
    }
  }, [_vm._v("info@mmmint.ai")])]), _c('li', [_c('br')]), _c('li', [_vm._v("Registergericht: Osnabrück")]), _c('li', [_vm._v("Registernummer: HRB 215257")]), _c('li', [_vm._v("Vertretungsberechtigter Geschäftsführer: Herr Maximilian Stein")]), _c('li', [_vm._v("Ust.ID: DE334940239")]), _c('li', [_c('br')]), _c('li', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v("Inhaltlich Verantwortlicher gem. § 55 II RStV: Maximilian Stein (Anschrift s.o.)")])])])]), _c('p', {
    staticClass: "MsoNormal"
  }, [_c('b', [_c('span', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v("Hinweis gemäß Online-Streitbeilegungs-Verordnung")])])]), _c('p', {
    staticClass: "MsoNormal"
  }, [_c('span', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v(" Nach geltendem Recht sind wir verpflichtet, Verbraucher auf die Existenz der Europäischen Online-Streitbeilegungs-Plattform hinzuweisen, die für die Beilegung von Streitigkeiten genutzt werden kann, ohne dass ein Gericht eingeschaltet werden muss. Für die Einrichtung der Plattform ist die Europäische Kommission zuständig. Die Europäische Online-Streitbeilegungs-Plattform ist hier zu finden: http://ec.europa.eu/odr. Unsere E-Mail lautet: info@mmmint.ai. Wir weisen aber darauf hin, dass wir nicht bereit sind, uns am Streitbeilegungsverfahren im Rahmen der Europäischen Online-Streitbeilegungs-Plattform zu beteiligen. Nutzen Sie zur Kontaktaufnahme bitte unsere obige E-Mail und Telefonnummer. ")])]), _c('p', {
    staticClass: "MsoNormal"
  }, [_c('b', [_c('span', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v("Hinweis gemäß Verbraucherstreitbeilegungsgesetz (VSBG)")])])]), _c('p', {
    staticClass: "MsoNormal"
  }, [_c('span', {
    attrs: {
      "lang": "DE"
    }
  }, [_vm._v(" Wir sind nicht bereit und verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. ")])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }