var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "600",
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v("Bankverbindung ändern")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-row', [_c('v-text-field', {
    staticClass: "mx-8 mt-8",
    attrs: {
      "outlined": "",
      "label": "IBAN *",
      "rules": _vm.ibanRules
    },
    model: {
      value: _vm.banking.iban,
      callback: function callback($$v) {
        _vm.$set(_vm.banking, "iban", $$v);
      },
      expression: "banking.iban"
    }
  })], 1), _c('v-row', [_c('v-text-field', {
    staticClass: "mx-8",
    attrs: {
      "outlined": "",
      "label": "Bank *",
      "rules": _vm.requiredRules
    },
    model: {
      value: _vm.banking.bank,
      callback: function callback($$v) {
        _vm.$set(_vm.banking, "bank", $$v);
      },
      expression: "banking.bank"
    }
  })], 1), _c('v-row', [_c('v-text-field', {
    staticClass: "mx-8",
    attrs: {
      "outlined": "",
      "label": "Kontoinhaber *",
      "rules": _vm.requiredRules
    },
    model: {
      value: _vm.banking.name,
      callback: function callback($$v) {
        _vm.$set(_vm.banking, "name", $$v);
      },
      expression: "banking.name"
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "ml-4 mb-4",
    attrs: {
      "disabled": !_vm.valid,
      "loading": _vm.loading,
      "outlined": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('v-icon', [_vm._v("mdi-content-save")]), _vm._v(" Änderungen speichern ")], 1), _c('v-spacer')], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }