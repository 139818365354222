import { Api } from "@/services/fahrzeugschein/api";
import { DetectionEntity } from "@/models/detectionEntity";

class DetectionService {
  ROUTE = "detection";

  async getImage(id: string): Promise<DetectionEntity> {
    const sub = "image";
    const api = new Api().instance();
    const response = await api.get(`/${this.ROUTE}/${sub}/${id}` + Api.getToken());
    const detectionImage = response.data as DetectionEntity;
    return detectionImage;
  }

  async getBoundingBoxes(id: string): Promise<DetectionEntity> {
    const sub = "boundingboxes";
    const api = new Api().instance();
    const response = await api.get(`/${this.ROUTE}/${sub}/${id}` + Api.getToken());
    const detectionImage = response.data as DetectionEntity;
    return detectionImage;
  }

  async getCroppedImages(id: string): Promise<DetectionEntity> {
    const sub = "croppedimage";
    const api = new Api().instance();
    const response = await api.get(`/${this.ROUTE}/${sub}/${id}` + Api.getToken());
    const detectionImage = response.data as DetectionEntity;
    return detectionImage;
  }
}

export default new DetectionService();
