






















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({ components: {} })
export default class ComboBox extends Vue {
  @Prop()
  items!: string[];

  @Prop()
  rules?: Function[];

  @Prop({ default: true })
  multiple?: boolean;

  @Prop({ default: true })
  isRequired?: boolean;

  @Prop()
  prefill?: string[];

  @Prop()
  label?: string;

  @Prop({ default: false })
  disabled?: string;

  @Prop()
  dense?: boolean;

  model =
    this.prefill !== undefined ? this.prefill : this.multiple ? this.items : this.items.length > 0 ? this.items[0] : "";

  search = null;

  isValid = false;

  get rulesList() {
    return [this.forEachRules];
  }

  getIndex(item: string) {
    return this.items.indexOf(item);
  }

  filter(item: any, query: any, text: any) {
    if (item.header) return false;

    return (
      (text || "")
        .toString()
        .toLowerCase()
        .indexOf((query || "").toString().toLowerCase()) > -1
    );
  }

  removeItem(item: string, parent: any) {
    if (this.multiple) {
      parent.selectItem(item);
    } else {
      this.model = "";
    }
  }

  forEachRules(values: string[]) {
    const resultMessages = [];

    for (const rule of this.rules || []) {
      let message = "";
      values.forEach(value => {
        const res = rule(value);
        if (res !== true) {
          message = res as string;
        }
      });

      if (message) {
        resultMessages.push(message);
      }
    }

    if (this.isRequired && this.model?.length === 0) {
      resultMessages.push(requiredRule()(""));
    }

    return resultMessages.length > 0 ? resultMessages.join(", ") : true;
  }

  @Watch("model")
  emitSelection() {
    this.$emit("changeSelection", this.model);
  }

  @Watch("isValid")
  emitIsValid() {
    this.$emit("changeIsValid", this.isValid);
  }

  onBlur() {
    this.$emit("blur");
  }
}
