/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateCustomFieldDtoGen,
  MrfiktivCustomFieldControllerGetParamsGen,
  MrfiktivCustomFieldViewModelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateCustomFieldDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class CustomField<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags custom-field
   * @name CustomFieldControllerEnsureIndexes
   * @summary (ADMIN) Indexes custom field keys
   * @request PATCH:/partner/{partnerId}/custom-field/ensureIndexesAsAdmin
   * @deprecated
   * @secure
   * @response `200` `void`
   */
  customFieldControllerEnsureIndexes = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/partner/${partnerId}/custom-field/ensureIndexesAsAdmin`,
      method: "PATCH",
      secure: true,
      ...params
    });
  /**
   * No description
   *
   * @tags custom-field
   * @name CustomFieldControllerCreate
   * @summary (CustomField - CREATE) Create a custom field
   * @request POST:/partner/{partnerId}/custom-field
   * @secure
   * @response `201` `MrfiktivCustomFieldViewModelGen` The created custom field
   */
  customFieldControllerCreate = (
    partnerId: string,
    data: MrfiktivCreateCustomFieldDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivCustomFieldViewModelGen, any>({
      path: `/partner/${partnerId}/custom-field`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags custom-field
   * @name CustomFieldControllerGet
   * @summary (CustomField - READ) Get all custom fields
   * @request GET:/partner/{partnerId}/custom-field
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivCustomFieldViewModelGen)[] })`
   */
  customFieldControllerGet = (
    { partnerId, ...query }: MrfiktivCustomFieldControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivCustomFieldViewModelGen[] }, any>({
      path: `/partner/${partnerId}/custom-field`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags custom-field
   * @name CustomFieldControllerGetOne
   * @summary (CustomField - READ) Get a custom field
   * @request GET:/partner/{partnerId}/custom-field/{customFieldId}
   * @secure
   * @response `200` `MrfiktivCustomFieldViewModelGen` The custom field
   */
  customFieldControllerGetOne = (partnerId: string, customFieldId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCustomFieldViewModelGen, any>({
      path: `/partner/${partnerId}/custom-field/${customFieldId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags custom-field
   * @name CustomFieldControllerUpdate
   * @summary (CustomField - READ) Updates a custom field
   * @request PATCH:/partner/{partnerId}/custom-field/{customFieldId}
   * @secure
   * @response `201` `MrfiktivCustomFieldViewModelGen` The updated custom field
   */
  customFieldControllerUpdate = (
    partnerId: string,
    customFieldId: string,
    data: MrfiktivUpdateCustomFieldDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivCustomFieldViewModelGen, any>({
      path: `/partner/${partnerId}/custom-field/${customFieldId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags custom-field
   * @name CustomFieldControllerDelete
   * @summary (CustomField - READ) Deletes a custom field
   * @request DELETE:/partner/{partnerId}/custom-field/{customFieldId}
   * @secure
   * @response `200` `MrfiktivCustomFieldViewModelGen` The deleted custom field
   */
  customFieldControllerDelete = (partnerId: string, customFieldId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivCustomFieldViewModelGen, any>({
      path: `/partner/${partnerId}/custom-field/${customFieldId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
