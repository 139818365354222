
































import BackAndForthNav from "@/components/navigation/BackAndForthNav.vue";
import BackAndForthFooter from "@/components/navigation/BackAndForthFooter.vue";
import BackAndForthFooter2 from "@/components/navigation/BackAndForthFooter2.vue";

import TheLayoutBase from "@/layouts/TheLayoutBase.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

@Component({
  components: {
    TheLayoutBase,
    BackAndForthNav,
    BackAndForthFooter,
    BackAndForthFooter2
  }
})
export default class LayoutReport extends Vue {
  @Prop({ default: false })
  private disableNext!: boolean;

  @Prop({ default: true })
  private renderBackAndForthFooter!: boolean;

  @Prop({ default: true })
  private showFooter!: boolean;

  @Prop({ default: false })
  private isProgressDone!: boolean;

  @Prop({ default: false })
  forceNextBtn!: boolean;

  @Prop({ default: false })
  private showLoginButton!: boolean;

  async mounted() {
    this.checkFavIcon();
  }

  logoClick() {
    this.$emit("logoClick", true);
    this.$log.info("Logo clicked");
  }

  get partner() {
    return ReportModule.partner;
  }

  get logoUrl() {
    return ReportModule.partner.settings?.logoUrl;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get backgroundColor() {
    switch (this.$vuetify.breakpoint.name) {
      case "md":
        return getDefaultPartnerColor();
      case "lg":
        return getDefaultPartnerColor();
      case "xl":
        return getDefaultPartnerColor();
      default:
        return "";
    }
  }

  get progress() {
    if (!this.isProgressDone) {
      const currentIndex = ReportModule.screenOder.indexOf(ReportModule.currentStep);
      return ((currentIndex + 1) / ReportModule.MAX_STEP) * 100;
    } else {
      return 100;
    }
  }

  get currentStep(): ReportScreenEnum {
    return ReportModule.currentStep;
  }

  get hasNext() {
    if (ReportModule.nextScreen) {
      return true;
    } else {
      return false;
    }
  }

  get hasPrev() {
    if (ReportModule.prevScreen) {
      return true;
    } else {
      return false;
    }
  }

  get nextAction() {
    return ReportModule.nextScreen;
  }

  get prevAction() {
    return ReportModule.prevScreen;
  }

  async next() {
    this.$emit("next");

    const next = this.nextAction;
    ReportModule.setStep(next);
    await this.$router.push({ path: `/${next}` });
  }

  async previous() {
    this.$emit("prev");

    const prev = this.prevAction;
    ReportModule.setStep(prev);
    await this.$router.push({ path: `/${prev}` });
  }

  checkFavIcon() {
    const favIcon: any = document.querySelector("link[rel~='icon']");

    if (this.partner.settings?.reportSettings?.favIconUrl) {
      favIcon.href = this.partner.settings?.reportSettings?.favIconUrl;
      return;
    }

    if (this.partner.settings?.favIconUrl) {
      favIcon.href = this.partner.settings.favIconUrl;
      return;
    }

    if (favIcon) {
      favIcon.href = this.partner.settings?.websites[0]?.link + "/favicon.ico";
    }
  }
}
