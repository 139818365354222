/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { MrfiktivExportReportMetaDtoGen, MrfiktivExportReportMetaViewModelGen } from "../v1/data-contracts";
import { Meta } from "../v1/Meta";

/**
 * Service to talk to mrfiktiv meta endpoint.
 */
export class MetaService {
  proxy: Meta;

  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.proxy = new Meta(mrfiktivHttpClientProvider.client());
  }

  /**
   * Creates a new report for the given partner and reportid
   *
   * @param reportId The report id to create the report for
   * @param partnerId the partner id to create the report for
   * @param metaData the meta data to create the report with
   * @returns the created report
   */
  async createExportReportMeta(
    reportId: string,
    partnerId: string,
    metaData: MrfiktivExportReportMetaDtoGen
  ): Promise<MrfiktivExportReportMetaViewModelGen> {
    const res = await this.proxy.exportReportMetaControllerCreate(partnerId, reportId, metaData);

    return res.data;
  }

  /**
   * Gets meta data for report export by partner and report id
   *
   * @param reportId The report id to create the report for
   * @param partnerId the partner id to create the report for
   * @returns the found metadata
   */
  async getExportReportMetaDataByReportAndPartner(
    reportId: string,
    partnerId: string
  ): Promise<MrfiktivExportReportMetaViewModelGen[]> {
    const res = await this.proxy.exportReportMetaControllerGetOne(partnerId, reportId);

    return res.data;
  }

  /**
   * Gets all meta data for report export by partner
   *
   * @param partnerId The partner the meta data belongs to
   * @returns the found metadata
   */
  async getAllExportMetaData(partnerId: string): Promise<MrfiktivExportReportMetaViewModelGen[]> {
    const res = await this.proxy.exportReportMetaControllerGetAll(partnerId);

    return res.data;
  }

  /**
   * deletes a meta document
   *
   * @param metaId the id of the meta document to delete
   * @param partnerId the id of the partner that meta document belongs to
   */
  async deleteMeta(metaId: string, partnerId: string): Promise<void> {
    const res = await this.proxy.exportReportMetaControllerDelete(partnerId, metaId);

    return res.data;
  }
}

export default new MetaService(new MrfiktivHttpClientProvider());
