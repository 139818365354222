





















import { Component, Vue, Prop } from "vue-property-decorator";
import { BookingServiceBookingViewModelGen } from "@/services/booking/v1/data-contracts";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";

@Component({
  components: {}
})
export default class BookingServicesItem extends Vue {
  @Prop({ default: {} })
  item!: BookingServiceBookingViewModelGen;

  get color() {
    return getDefaultPartnerColor();
  }
}
