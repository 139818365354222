var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ADMIN) ? _c('the-navigation-layout', {
    attrs: {
      "title": "Billing",
      "items": _vm.items
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }