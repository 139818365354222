import { UserModule } from "@/store/modules/me-user.store";
import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { handleError } from "@/lib/utility/handleError";
import availabilityService from "@/services/booking/services/availabilityService";
import {
  BookingAvailabilityControllerFindOneByPartnerIdParamsGen,
  BookingBookingAttachmentDtoGen,
  BookingBookingConfirmedViewModelGen,
  BookingBookingControllerFindAllByPartnerIdParamsGen,
  BookingBookingInformationDtoGen,
  BookingCreateBookingWithoutResourceDtoGen,
  BookingCustomFieldValueGen,
  BookingCustomerInformationDtoGen,
  BookingServiceBookingViewModelGen,
  BookingSlotViewModelGen,
  BookingUpdateBookingUnauthenticatedDtoGen,
  BookingUpdateSoftDeleteBookingDtoGen
} from "@/services/booking/v1/data-contracts";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import store from "../../store/VuexPlugin";
import { BookingScreenEnum, BookingStepEnum } from "../enum/bookingScreenEnum";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { PartnerModule } from "./partner";
import { ReportModule } from "./report.store";
import { CustomerAccountMeModule } from "./customer-account-me.store";
import Vue from "vue";

@Module({
  dynamic: true,
  namespaced: true,
  name: "availability",
  store
})
export class AvailabilityStore extends BasePagination<
  BookingServiceBookingViewModelGen,
  BookingBookingControllerFindAllByPartnerIdParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: BookingServiceBookingViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;
  filterOptions: PaginationFilterListElement[] = [
    { key: "resourceId", type: PageFilterTypes.OBJECT_ID },
    { key: "serviceId", type: PageFilterTypes.OBJECT_ID },
    { key: "partnerId", type: PageFilterTypes.OBJECT_ID },
    { key: "startInSeconds", type: PageFilterTypes.NUMBER },
    { key: "durationInSeconds", type: PageFilterTypes.NUMBER },
    { key: "_id", type: PageFilterTypes.OBJECT_ID }
  ].map(f => new PaginationFilterListElement(f));

  @Action
  protected async loadDocuments(query: BookingBookingControllerFindAllByPartnerIdParamsGen): Promise<any> {
    return availabilityService.findAllByPartner(query);
  }

  private _confirmedBooking: BookingBookingConfirmedViewModelGen | null = null;

  get confirmedBooking() {
    return this._confirmedBooking;
  }

  @Mutation
  mutateConfirmedBooking(booking: BookingBookingConfirmedViewModelGen) {
    this._confirmedBooking = booking;
  }

  @Action
  async create(data: {
    partnerId: string;
    dto: BookingCreateBookingWithoutResourceDtoGen;
  }): Promise<BookingBookingConfirmedViewModelGen> {
    const booking = await availabilityService.create(data.partnerId, data.dto);
    this.context.commit("mutateConfirmedBooking", booking);

    try {
      // Create customer account after user submits.
      await CustomerAccountMeModule.create({});
    } catch (error) {
      Vue.$log.error(error);
    }

    return booking;
  }

  @Action
  async findOneByPartner(
    query: BookingAvailabilityControllerFindOneByPartnerIdParamsGen
  ): Promise<BookingServiceBookingViewModelGen> {
    const bookableServiceDetailed = await availabilityService.findOneByPartner(query);
    this.context.commit("mutateSelectedService", bookableServiceDetailed);
    return bookableServiceDetailed;
  }

  @Action
  async deleteOneByPartner(data: { partnerId: string; id: string; data: BookingUpdateSoftDeleteBookingDtoGen }) {
    const booking = await availabilityService.deleteBookingById(data.partnerId, data.id, data.data);
    this.context.commit("mutateConfirmedBooking", booking);
    return booking;
  }

  @Action
  async updateOneByPartner(data: { partnerId: string; id: string; data: BookingUpdateBookingUnauthenticatedDtoGen }) {
    const booking = await availabilityService.updateBookingById(data.partnerId, data.id, data.data);
    this.context.commit("mutateConfirmedBooking", booking);
    return booking;
  }

  @Action
  async findOneBookingByPartner(data: { partnerId: string; id: string }) {
    const booking = await availabilityService.findOneBookingById(data.partnerId, data.id);
    this.context.commit("mutateConfirmedBooking", booking);
    return booking;
  }

  /**
   * Value store for the creation of a booking by a customer
   */

  private _selectedService: BookingServiceBookingViewModelGen | undefined = undefined;

  get selectedService() {
    return this._selectedService;
  }

  @Mutation
  private mutateSelectedService(service: BookingServiceBookingViewModelGen | undefined) {
    this._selectedService = service;
  }

  @Action
  setSelectedService(service: BookingServiceBookingViewModelGen | undefined) {
    this.context.commit("mutateSelectedService", service);
  }

  private _isLoadingServices = false;

  get isLoadingServices() {
    return this._isLoadingServices;
  }

  @Mutation
  private mutateIsloadingServices(value: boolean) {
    this._isLoadingServices = value;
  }

  @Action
  setIsloadingServices(value: boolean) {
    this.context.commit("mutateIsloadingServices", value);
  }

  private _isValid = false;

  get isValid() {
    return this._isValid;
  }

  @Mutation
  private mutateIsValid(value: boolean) {
    this._isValid = value;
  }

  @Action
  setIsValid(value: boolean) {
    this.context.commit("mutateIsValid", value);
  }

  //private _date: string | null = new Date().toISOString().substring(0, 10);
  private _date: string | null = null;

  get date() {
    return this._date;
  }

  @Mutation
  mutateDate(newDate: string | null) {
    this._date = newDate;
  }

  @Action
  setDate(newDate: string | null) {
    this.context.commit("mutateDate", newDate);
  }

  private _pickerDate: string | null = null;

  get pickerDate() {
    return this._pickerDate;
  }

  @Mutation
  mutatePickerDate(newDate: string | null) {
    this._pickerDate = newDate;
  }

  @Action
  setPickerDate(newDate: string | null) {
    this.context.commit("mutatePickerDate", newDate);
  }

  private _bookingFormDisabled = false;

  get bookingFormDisabled() {
    return this._bookingFormDisabled;
  }

  @Action
  setBookingFormDisabled(value: boolean) {
    this.context.commit("mutateBookingFormDisabled", value);
  }

  @Mutation
  mutateBookingFormDisabled(value: boolean) {
    this._bookingFormDisabled = value;
  }

  private _bookingInformation: BookingBookingInformationDtoGen = {
    notes: ""
  };

  get bookingInformation() {
    return this._bookingInformation;
  }

  @Mutation
  mutateBookingInformation(info: BookingBookingInformationDtoGen) {
    this._bookingInformation = info;
  }

  @Action
  setBookingInformation(info: BookingBookingInformationDtoGen) {
    this.context.commit("mutateBookingInformation", info);
  }

  private _attachments: string[] = [];

  get attachments() {
    return this._attachments;
  }

  @Mutation
  mutateAttachments(attachments: string[]) {
    this._attachments = attachments;
  }

  @Action
  setAttachments(attachments: string[]) {
    this.context.commit("mutateAttachments", attachments);
  }

  @Action
  async uploadImageAttachment({ partnerId, data }: { partnerId: string; data: BookingBookingAttachmentDtoGen }) {
    const response = await availabilityService.uploadImageAttachment(partnerId, { file: data.file, type: data.type });
    return response;
  }

  private _customerInformation: BookingCustomerInformationDtoGen = {
    lastName: UserModule.user.lastName || "",
    firstName: UserModule.user.firstName || "",
    email: UserModule.user.userName || "",
    numberPlate: ""
  };

  get customerInformation() {
    return this._customerInformation;
  }

  @Mutation
  mutateCustomerInformation(info: BookingCustomerInformationDtoGen) {
    this._customerInformation = info;
  }

  @Action
  setCustomerInformation(info: BookingCustomerInformationDtoGen) {
    this.context.commit("mutateCustomerInformation", info);
  }

  private _values: BookingCustomFieldValueGen[] = [];

  get values() {
    return this._values;
  }

  @Mutation
  mutateValues(values: BookingCustomFieldValueGen[]) {
    this._values = [...values];
  }

  @Action
  setValues(values: BookingCustomFieldValueGen[]) {
    this.context.commit("mutateValues", values);
  }

  private _isLoadingSlots = false;

  get isLoadingSlots() {
    return this._isLoadingSlots;
  }

  @Mutation
  mutateIsLoadingSlots(value: boolean) {
    this._isLoadingSlots = value;
  }

  @Action
  setIsLoadingSlots(value: boolean) {
    this.context.commit("mutateIsLoadingSlots", value);
  }

  private _serviceId = "";

  get serviceId() {
    return this._serviceId;
  }

  @Mutation
  mutateServiceId(id: string) {
    this._serviceId = id;
  }

  @Action
  setServiceId(id: string) {
    this.context.commit("mutateServiceId", id);
  }

  private _selectedSlot: BookingSlotViewModelGen | undefined = undefined;

  get selectedSlot() {
    return this._selectedSlot;
  }

  get slots(): BookingSlotViewModelGen[] {
    return this.selectedService?.slots || [];
  }

  @Mutation
  mutateSelectedSlot(slot: BookingSlotViewModelGen | undefined) {
    this._selectedSlot = slot;
  }

  @Action
  setSelectedSlot(slot: BookingSlotViewModelGen | undefined) {
    this.context.commit("mutateSelectedSlot", slot);
  }

  get partnerId() {
    return ReportModule.partner._id || PartnerModule.partner._id;
  }

  @Action
  setFirstAvailableDay() {
    if (this.slots.length) {
      this.setDate(new Date(this.slots[0].start).toISOString().substring(0, 10));
    }
  }

  @Action
  async refresh() {
    this.setIsloadingServices(true);
    try {
      await AvailabilityModule.fetchAllFromBeginning({
        partnerId: this.partnerId
      });
      if (this.bookableServices.length) {
        this.setServiceId(this.bookableServices[0].serviceId);
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.setIsloadingServices(false);
    }
  }

  get bookableServices(): BookingServiceBookingViewModelGen[] {
    return this.paginationList;
  }

  /**
   * For Navigation
   */

  private _currentStep = BookingStepEnum.START;

  get currentStep() {
    return this._currentStep;
  }

  @Mutation
  mutateCurrentStep(step: BookingStepEnum) {
    this._currentStep = step;
  }

  @Action
  setCurrentStep(step: BookingStepEnum) {
    this.context.commit("mutateCurrentStep", step);
  }

  private _secondWindow = BookingScreenEnum.START;

  get secondWindow() {
    return this._secondWindow;
  }

  @Mutation
  mutateSecondWindow(window: BookingScreenEnum) {
    this._secondWindow = window;
  }

  @Action
  setSecondWindow(window: BookingScreenEnum) {
    this.context.commit("mutateSecondWindow", window);
  }

  @Action
  setStart() {
    this.setCurrentStep(BookingStepEnum.START);
    this.setSecondWindow(BookingScreenEnum.START);
  }

  @Action
  setServices() {
    this.setCurrentStep(BookingStepEnum.SERVICES);
    this.setSecondWindow(BookingScreenEnum.START);
  }

  @Action
  setCalender() {
    this.setCurrentStep(BookingStepEnum.CALENDER);
    this.setSecondWindow(BookingScreenEnum.START);
  }

  @Action
  setSlots() {
    this.setCurrentStep(BookingStepEnum.SLOTS);
    this.setSecondWindow(BookingScreenEnum.START);
  }

  @Action
  setForm() {
    this.setCurrentStep(BookingStepEnum.FORM);
    this.setSecondWindow(BookingScreenEnum.FORM);
  }

  @Action
  setSuccess() {
    this.setCurrentStep(BookingStepEnum.SUCCESS);
    this.setSecondWindow(BookingScreenEnum.SUCCESS);
  }

  @Action
  setError() {
    this.setCurrentStep(BookingStepEnum.ERROR);
    this.setSecondWindow(BookingScreenEnum.ERROR);
  }
}

export const AvailabilityModule = getModule(AvailabilityStore);
