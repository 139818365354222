var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isLoading ? _c('v-form', {
    ref: "signRequestForm",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "prepend-icon": "mdi-email",
      "rules": _vm.requiredRule,
      "label": _vm.$t('sign.SignRequestForm.email') + ' *',
      "readonly": _vm.disabled,
      "hint": _vm.$t('sign.SignRequestForm.emailHint'),
      "persistent-hint": ""
    },
    model: {
      value: _vm.createSignRequest.recipient.email,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequest.recipient, "email", $$v);
      },
      expression: "createSignRequest.recipient.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "prepend-icon": "mdi-text",
      "rules": _vm.requiredRule,
      "label": _vm.$t('sign.SignRequestForm.title') + ' *',
      "hint": _vm.$t('sign.SignRequestForm.titleHint'),
      "persistent-hint": !_vm.signRequest,
      "readonly": _vm.disabled
    },
    model: {
      value: _vm.createSignRequest.title,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequest, "title", $$v);
      },
      expression: "createSignRequest.title"
    }
  }), !_vm.signRequest || _vm.createSignRequest.description ? _c('v-textarea', {
    attrs: {
      "prepend-icon": " ",
      "label": _vm.$t('sign.SignRequestForm.description'),
      "hint": _vm.$t('sign.SignRequestForm.descriptionHint'),
      "persistent-hint": !_vm.signRequest,
      "readonly": _vm.disabled
    },
    model: {
      value: _vm.createSignRequest.description,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequest, "description", $$v);
      },
      expression: "createSignRequest.description"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "prepend-icon": "mdi-account",
      "label": _vm.$t('sign.SignRequestForm.firstName'),
      "readonly": _vm.disabled
    },
    model: {
      value: _vm.createSignRequest.recipient.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequest.recipient, "firstName", $$v);
      },
      expression: "createSignRequest.recipient.firstName"
    }
  }), _c('v-text-field', {
    attrs: {
      "prepend-icon": " ",
      "label": _vm.$t('sign.SignRequestForm.lastName'),
      "readonly": _vm.disabled
    },
    model: {
      value: _vm.createSignRequest.recipient.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequest.recipient, "lastName", $$v);
      },
      expression: "createSignRequest.recipient.lastName"
    }
  }), !_vm.signRequest || _vm.signRequest.recipient.companyName ? _c('v-text-field', {
    attrs: {
      "prepend-icon": "mdi-domain",
      "label": _vm.$t('sign.SignRequestForm.companyName'),
      "readonly": _vm.disabled
    },
    model: {
      value: _vm.createSignRequest.recipient.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.createSignRequest.recipient, "companyName", $$v);
      },
      expression: "createSignRequest.recipient.companyName"
    }
  }) : _vm._e(), _vm.validBy ? _c('v-text-field', {
    attrs: {
      "prepend-icon": "mdi-calendar",
      "type": "date",
      "label": _vm.$t('sign.SignRequestForm.validBy'),
      "readonly": _vm.disabled
    },
    model: {
      value: _vm.validBy,
      callback: function callback($$v) {
        _vm.validBy = $$v;
      },
      expression: "validBy"
    }
  }) : _vm._e(), !_vm.signRequest ? _c('v-btn', {
    staticClass: "mt-4 mr-4",
    attrs: {
      "loading": _vm.isLoading,
      "large": "",
      "color": "primary",
      "disabled": !_vm.valid,
      "data-test-form-submit": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.SignRequestForm.submit")) + " ")]) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }