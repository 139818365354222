var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticStyle: {
      "margin-top": "-2px"
    }
  }, [_vm.isDifferent ? _c('span', [_c('small', {
    style: "color: ".concat(_vm.$vuetify.theme.currentTheme.error)
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailSave.unsavedChanges")) + " ")]), _c('v-icon', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v(" mdi-content-save-alert ")])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }