
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";
import { ConfigModule } from "@/store/modules/config";

@Component({})
export default class PartnerFallbackMixin extends Vue {
  /**
   * Try to set a given partner by $router (to be able to overwrite) or set the partner by store.
   * If no partner is set via router or store, try to fetch all partners and set the first as active.
   * If no active partner is found redirect to an error page.
   */
  async trySetByRouteOrDefault(): Promise<boolean> {
    // Fetch all partners if not in store.
    if (PartnerModule.partners.length === 0) {
      try {
        await PartnerModule.getPartners();
      } catch (error) {
        this.$log.error(error);
        this.$toast.error("Fehler beim Laden der Partner.");
        this.$router.push({ name: "PartnerError" });

        return false;
      }
    }

    const partnerId = this.$route.params.partnerId;

    // Set partner by param id
    if (partnerId) {
      try {
        await PartnerModule.getPartnerById(partnerId);
        this.$log.info("Partner set by param: partnerId");
      } catch {
        this.$log.warn("Partner could not set by param partnerId");
        this.$log.warn("Try to set default to partners[0]");
        if (PartnerModule.partners.length !== 0 && PartnerModule.partners[0]) {
          await PartnerModule.setPartner(PartnerModule.partners[0]);

          return true;
        } else {
          this.$log.error("Could not set Partner");
          this.$toast.error("Fehler beim Laden des Partners.");
          this.$router.push({ name: "PartnerError" });

          return false;
        }
      }

      // Set partner to default partners[0]
    } else if (PartnerModule.partners.length !== 0 && PartnerModule.partners[0]) {
      this.$log.info("Not Partner context set. Try set default partner");

      /**
       * Set the default partner.
       * On admin select a predefined if found.
       * Non admin get the first in the list.
       */
      let activePartner = PartnerModule.partners[0];
      if (UserModule.isAdmin) {
        activePartner =
          PartnerModule.partners.find(p => p._id === ConfigModule.preferredAdminThgPartner) ||
          PartnerModule.partners[0];
      }
      PartnerModule.setPartner(activePartner);

      return true;
    }

    return true;
  }
}
