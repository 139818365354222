var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    attrs: {
      "height": "100%",
      "width": "640px",
      "max-height": "125",
      "align": "center",
      "color": "grey lighten-4",
      "elevation": "0"
    }
  }, [_vm.item.file ? _c('v-img', {
    attrs: {
      "src": _vm.fileToUrl(_vm.item),
      "height": "100%",
      "max-height": "125",
      "contain": ""
    },
    on: {
      "click": _vm.openDialog
    }
  }) : _vm._e(), _vm.item.isLoading || _vm.item.isUploaded ? _c('v-btn', {
    staticClass: "topAdjusted overwriteDisbaled grey darken-4",
    attrs: {
      "loading": _vm.item.isLoading,
      "dark": "",
      "fab": "",
      "small": "",
      "absolute": "",
      "top": "",
      "right": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.openDialog
    }
  }, [_vm.item.isUploaded ? _c('v-icon', [_vm._v(" mdi-check ")]) : _vm._e()], 1) : _vm._e()], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.dialog,
      "title": _vm.item && _vm.item.file && _vm.item.file.name ? _vm.item.file.name : '',
      "leftColor": "error",
      "leftText": _vm.$t('delete'),
      "rightText": _vm.$t('ok')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "leftClick": _vm.deleteImage,
      "rightClick": function rightClick($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-img', {
    staticClass: "align-end ma-auto",
    style: "max-height: calc(100vh - ".concat(_vm.$vuetify.breakpoint.smAndDown ? '225px' : '300px', ");"),
    attrs: {
      "contain": "",
      "src": _vm.dialogImage
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }