/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 *
 */
export enum ThgVehicleTypes {
  /**
   * light commercial vehicles
   */
  N1 = "N1",

  /**
   * large goods vehicle
   */
  N2 = "N2",

  /**
   * larger large goods vehicle
   */
  N3 = "N3",

  /**
   * Car
   */
  M1 = "M1",
  /**
   * Bus
   */
  M3 = "M3",
  /**
   * Other
   */
  other = "other"
}
