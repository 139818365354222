import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IThgMeterReading, ThgMeterReading } from "@/models/thg-meter-reading.entity";

export const ThgMeterReadingDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IThgMeterReading> {
  getIdentifier(entity: IThgMeterReading): string {
    return entity.id;
  }

  protected mapEntity(entity: IThgMeterReading): IThgMeterReading {
    return new ThgMeterReading(entity);
  }
})();
