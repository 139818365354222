






























import Debug from "@/components/utility/Debug.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import InstructionCard from "@/components/instructions/InstructionCard.vue";

@Component({
  components: {
    InstructionCard,
    Debug
  }
})
export default class ReportHeader extends Vue {
  dialog = false;

  @Prop({ default: 6 })
  md!: number;

  @Prop({})
  debug!: any;

  @Prop({})
  description!: string;

  @Prop({})
  title!: string;

  @Prop({})
  image!: string;

  @Prop({})
  hasInstructions!: boolean;

  @Prop({ default: "Anleitung" })
  instructionTitle!: string;

  @Prop({ default: "Hier ist ein Beispiel wie das Foto am besten aufzunehmen ist." })
  instructionText!: string;

  @Prop()
  instructionPictures!: string[];

  @Prop({ default: 175 })
  imageHeight!: number;

  disableDialog() {
    this.dialog = false;
  }
}
