var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    attrs: {
      "label": _vm.label,
      "type": "text",
      "rules": _vm.requiredRules,
      "prepend-inner-icon": "mdi-counter",
      "id": "code",
      "outlined": ""
    },
    on: {
      "change": _vm.removeWhiteSpace
    },
    model: {
      value: _vm.code,
      callback: function callback($$v) {
        _vm.code = $$v;
      },
      expression: "code"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }