import { PromotionTypeEnum } from "@/lib/enum/promotion-type.enum";
import { ThgActivePromotionViewModelGen, ThgImageDocumentGen } from "@/services/thg/v1/data-contracts";
import { CurrencyUnitEnum } from "@/store/enum/thg/currency-unit.enum";
import { UserModule } from "@/store/modules/me-user.store";
import { ThgModule } from "@/store/modules/thg.store";
import Vue from "vue";

/**
 * @inheritdoc
 */
export class ActivePromotion implements ThgActivePromotionViewModelGen {
  type: PromotionTypeEnum;
  title: string;
  subtitle: string;
  description: string;
  hint: string;
  imageId?: string;
  image?: ThgImageDocumentGen;
  value: number;
  currency: CurrencyUnitEnum;

  constructor(promo: ThgActivePromotionViewModelGen) {
    this.type = promo.type as PromotionTypeEnum;
    this.title = promo.title;
    this.subtitle = promo.subtitle;
    this.description = promo.description;
    this.hint = promo.hint;
    this.imageId = promo.imageId;
    this.image = promo.image;
    this.value = promo.value;
    this.currency = promo.currency as CurrencyUnitEnum;
  }

  /**
   * Check if the promotion may be applied
   */
  get isApplicable() {
    Vue.$log.debug("Promo type?", this.type);
    if (this.type === PromotionTypeEnum.FIRST_PURCHASE) {
      const isSignedIn = !!UserModule.user;
      Vue.$log.debug("logged in?", isSignedIn);
      if (!isSignedIn) return true;

      const hasThgs = !!ThgModule.myThgs.length;
      Vue.$log.debug("has THGs?", hasThgs);
      if (!hasThgs) return true;
    }

    return false;
  }
}
