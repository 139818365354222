import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ConfigModule } from "@/store/modules/config";
import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreateAdminTemplateDtoGen,
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivRenderedTemplateViewModelGen,
  MrfiktivUpdateAdminTemplateDtoGen
} from "../mrfiktiv/v1/data-contracts";
import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";
import {
  ThgCreateAdminTemplateDtoGen,
  ThgAdminTemplateViewModelGen,
  ThgRenderTemplateDtoGen,
  ThgRenderedTemplateViewModelGen,
  ThgUpdateAdminTemplateDtoGen
} from "../thg/v1/data-contracts";
import { AdminTemplate as MrfiktivAdminTemplate } from "../mrfiktiv/v1/AdminTemplate";
import { AdminTemplate as ThgAdminTemplate } from "../thg/v1/AdminTemplate";

class AdminTemplateService {
  /**
   *
   */
  mrfiktivAdminTemplate: MrfiktivAdminTemplate;

  /**
   *
   */
  thgAdminTemplate: ThgAdminTemplate;

  /**
   *
   * @param mrfiktivHttpClientProvider
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivAdminTemplate = new MrfiktivAdminTemplate(mrfiktivHttpClientProvider.client());
    this.thgAdminTemplate = new ThgAdminTemplate(thgHttpClientProvider.client());
  }

  async create(
    data: MrfiktivCreateAdminTemplateDtoGen | ThgCreateAdminTemplateDtoGen
  ): Promise<MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgAdminTemplate.adminTemplateControllerCreate(data)).data;
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivAdminTemplate.adminTemplateControllerCreate(data)).data;
    }
  }

  async delete(key: string): Promise<MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgAdminTemplate.adminTemplateControllerDelete(key)).data;
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivAdminTemplate.adminTemplateControllerDelete(key)).data;
    }
  }

  async getAll(): Promise<MrfiktivAdminTemplateViewModelGen[] | ThgAdminTemplateViewModelGen[] | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgAdminTemplate.adminTemplateControllerGetAll()).data;
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivAdminTemplate.adminTemplateControllerGetAll()).data;
    }
  }

  async getByKey(key: string): Promise<MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgAdminTemplate.adminTemplateControllerGetByKey(key)).data;
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivAdminTemplate.adminTemplateControllerGetByKey(key)).data;
    }
  }

  async render(
    key: string,
    data: ThgRenderTemplateDtoGen
  ): Promise<MrfiktivRenderedTemplateViewModelGen | ThgRenderedTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgAdminTemplate.adminTemplateControllerRender(key, data)).data;
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivAdminTemplate.adminTemplateControllerRender(key, data)).data;
    }
  }

  async update(
    key: string,
    data: MrfiktivUpdateAdminTemplateDtoGen | ThgUpdateAdminTemplateDtoGen
  ): Promise<MrfiktivAdminTemplateViewModelGen | ThgAdminTemplateViewModelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgAdminTemplate.adminTemplateControllerUpdate(key, data)).data;
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivAdminTemplate.adminTemplateControllerUpdate(key, data)).data;
    }
  }
}

export default new AdminTemplateService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
