var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "disabled": _vm.disabled
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({}, on), [_vm._t("default")], 2)];
      }
    }], null, true)
  }, [_c('span', {
    staticClass: "no-print",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }