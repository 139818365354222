
import Vue from "vue";
import Component from "vue-class-component";
import { ConfigModule } from "@/store/modules/config";

@Component({})
export default class DragMixin extends Vue {
  maxFileSizeInMb = 5;

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  onDrop(file: File): void {
    this.isFailed = true;
    throw new Error("No event handler for drop implemented");
  }

  isDrag = false;

  isDrop = false;

  isFailed = false;

  acceptFile = true;

  acceptImage = true;

  dragOver() {
    this.isDrag = true;
  }

  dragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!event.relatedTarget || !(event.currentTarget as any)?.contains(event.relatedTarget)) {
      this.isDrag = false;
    }
  }

  onFileDrop(event: DragEvent) {
    const files = event.dataTransfer?.files;
    if (!files) {
      return;
    }
    const file = files[0];
    if (!file) {
      this.isDrop = false;
      return;
    }

    this.isDrop = true;

    const isTooLarge = file.size > 1024 * 1000 * this.maxFileSizeInMb;
    const isPDF = ConfigModule.pdfInputTypes.includes(file.type);
    const isImage = ConfigModule.imageInputTypes.includes(file.type);

    if (isTooLarge) {
      this.$toast.error(this.$t("sign.ReportDocumentSignRequestForm.maxSize", { size: this.maxFileSizeInMb }));
      this.isFailed = true;
    } else if (!this.acceptFile && isPDF && !isImage) {
      this.$toast.error("PDFs are not allowed");
      this.isFailed = true;
    } else if (!this.acceptImage && isImage) {
      this.$toast.error("Images are not allowed");
      this.isFailed = true;
    } else if (!isPDF && !isImage) {
      this.$toast.error("File types are not allowed");
      this.isFailed = true;
    } else {
      this.onDrop(file);
    }

    setTimeout(() => {
      this.isDrag = false;
      this.isDrop = false;
      this.isFailed = false;
    }, 1000);
  }
}
