import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const opts = {
  iconfont: "mdi",
  breakpoint: {
    mobileBreakpoint: 600 // @See https://v2.vuetifyjs.com/en/components/grids/#usage
  }
};

export default new Vuetify(opts);
