/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { AxiosInstance } from "axios";
import { MrfiktivHttpClientProvider } from "../mrfiktiv-http-client.provider";
import { MrfiktivOperationIdViewModelGen } from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * FIXME: get type from generated client
 */
export interface IPdrSetupViewModel {
  isSetup: boolean;
  isAuthenticated: boolean;
  isMandatoryConfiguration: boolean;
  error: string;
}

/**
 * FIXME: get type from generated client
 */
export interface IPdrAccessTokenRequestDto {
  email: string;
  password: string;
}

/**
 * FIXME: get type from generated client
 */
export interface IPdrExportReportDto {
  isSetup: boolean;
  isAuthenticated: boolean;
  isMandatoryConfiguration: boolean;
  error: string;
}

/**
 * Action envelope for export report
 */
export interface IPdrExportReportDtoEnvelope {
  partnerId: string;
  reportId: string;
}

/**
 * Service to talk to mrfiktiv daService endpoint.
 */
export class PdrService {
  private client: HttpClient;
  private instance: AxiosInstance;
  // private proxy: Da;

  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.client = clientProvider.client();
    // this.proxy = new Pdr(this.client);

    this.instance = clientProvider.instance();
  }

  /**
   * Setup the PDR configuration
   *
   * @param partnerId
   * @param data
   */
  async setup(partnerId: string, data: IPdrAccessTokenRequestDto): Promise<IPdrExportReportDto> {
    const res = await this.client.instance.post(`/partner/${partnerId}/pdr`, data);

    return res.data;
  }

  /**
   * Get pdr configuration
   *
   * @param partnerId the id of the partner to check the configuration of the PDR
   * @returns
   */
  async getPdrConfiguration(partnerId: string): Promise<IPdrExportReportDto> {
    const res = await this.client.instance.get(`/partner/${partnerId}/pdr`);

    return res.data;
  }

  /**
  //  * Export a report to PDR
   *
   * @param partnerId
   * @param reportId
   * @returns
   */
  async exportReportToPdr(partnerId: string, reportId: string): Promise<MrfiktivOperationIdViewModelGen> {
    const res = await this.client.instance.post(`/partner/${partnerId}/pdr/${reportId}/async`);

    return res.data;
  }
}

export default new PdrService(new MrfiktivHttpClientProvider());
