import { MrfiktivHttpClientProvider as Api } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { ConfigModule } from "@/store/modules/config";
import {
  MrfiktivAuthAccessTokenGen as AuthAccessToken,
  MrfiktivChangePasswordIfRequiredDtoGen as ChangePasswordIfRequiredDto,
  MrfiktivConfirmPasswordDtoGen as ConfirmPasswordDto,
  MrfiktivForgotPasswordDtoGen as ForgotPasswordDto,
  MrfiktivGetTokenByRefreshTokenDtoGen as GetTokenByRefreshTokenDto,
  MrfiktivIdentityInformationViewModelGen,
  MrfiktivUserAuthDtoGen as UserAuthDto,
  MrfiktivVerifyAuthDtoGen as VerifyAuthDto
} from "../v1/data-contracts";
import { AxiosInstanceProvider } from "./../../axios-instance.provider";
import { EmptyTokenProvider } from "./../../empty-token.provider";
import { ThgIdentityInformationViewModelGen } from "@/services/thg/v1/data-contracts";

class AuthService {
  ROUTE = "/auth";

  /**
   * @class Initialize SettingService
   */
  constructor() {
    // this.client = clientProvider.client();
    // this.api = this.client.instance;
    // this.proxy = new Auth(this.client); // FIXME: Refactor into Proxy
  }

  /**
   * @param signInDto
   * @returns AuthAccessToken
   */
  async signIn(signInDto: UserAuthDto): Promise<AuthAccessToken> {
    this.validateInput(signInDto.username, "username");
    this.validateInput(signInDto.password, "password");

    const api = new Api().instance();
    const response = await api.post(`${this.ROUTE}/signin`, signInDto);
    return response.data as AuthAccessToken;
  }

  /**
   * Validates if the input is valid.
   *
   * @param input the input expected
   * @param type the type of input expected
   * @throws ArgumentException if the input is not valid
   */
  private validateInput(input: string, type: string) {
    if (!input) {
      const error = `${type} not set`;
      throw Error(error);
      // throw new ArgumentException(type);
    }
  }

  /**
   * invalidates refresh token
   */
  async signOut() {
    const api = new Api().instance();

    await api.get(`${this.ROUTE}/signOut`);
  }

  /**
   * Refresh access token
   * @param getTokenByRefreshTokenDto
   * @returns AuthAccessToken
   */
  async refresh(getTokenByRefreshTokenDto: GetTokenByRefreshTokenDto): Promise<AuthAccessToken> {
    const refreshClientProvider = new AxiosInstanceProvider(new EmptyTokenProvider(), ConfigModule.mrfiktivBaseUrl);
    const api = refreshClientProvider.create();

    const response = await api.post(`${this.ROUTE}/refresh`, getTokenByRefreshTokenDto);
    return response.data as AuthAccessToken;
  }

  /**
   * Route when user forgot password.
   * @param confirmPasswordDto
   */
  async confirmPassword(confirmPasswordDto: ConfirmPasswordDto) {
    const api = new Api().instance();
    await api.post(`${this.ROUTE}/confirmPassword`, confirmPasswordDto);
  }

  /**
   * Route to verify Email Address
   * @param verifyEmail
   */
  async verifyEmail(verifyEmail: VerifyAuthDto) {
    const api = new Api().instance();
    await api.post(`${this.ROUTE}/verifyEmail`, verifyEmail);
  }

  /**
   * Route when user signs in for first time and has to reset the remporary password.
   * @param changePasswordIfRequiredDto
   * @return AuthAccessToken
   */
  async changePasswordIfRequired(changePasswordIfRequiredDto: ChangePasswordIfRequiredDto): Promise<AuthAccessToken> {
    const api = new Api().instance();
    const response = await api.post(`${this.ROUTE}/changePasswordIfRequired`, changePasswordIfRequiredDto);
    return response.data as AuthAccessToken;
  }

  /**
   * Route when user signs in for first time and has to reset the remporary password.
   * @param { ForgotPasswordDto } forgotPasswordDto
   */
  async forgotPassword(forgotPasswordDto: ForgotPasswordDto) {
    const api = new Api().instance();
    await api.post(`${this.ROUTE}/forgotPassword`, forgotPasswordDto);
  }

  /**
   * Route to confirm mail
   *
   * @param verifyAuthDto contains username and code
   */
  async confirmMail(verifyAuthDto: VerifyAuthDto): Promise<void> {
    this.validateInput(verifyAuthDto.username, "username");
    this.validateInput(verifyAuthDto.code, "code");
    const api = new Api().instance();
    await api.post(`${this.ROUTE}/verifyEmail`, verifyAuthDto);
  }

  /**
   * Route to request new code for mail confirmation
   *
   * @param resendConfirmationCode dto that contains usermail
   */
  async requestNewConfirmMailCode(resendConfirmationCode: ForgotPasswordDto): Promise<void> {
    this.validateInput(resendConfirmationCode.username, "username");
    const api = new Api().instance();
    await api.post(`${this.ROUTE}/resendConfirmationCode`, resendConfirmationCode);
  }

  /**
   * Gets identity information from the aws user pool
   *
   * @param userName the mail of the user to get data for
   * @returns
   */
  async getIdentity(
    userName: string
  ): Promise<ThgIdentityInformationViewModelGen | MrfiktivIdentityInformationViewModelGen> {
    const api = new Api().instance();

    const response = await api.get(`${this.ROUTE}/user/${userName}`);

    return response.data;
  }
}

export default new AuthService();
