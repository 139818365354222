/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateUserDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivPartnerUserControllerFindAllByPartnerIdParamsGen,
  MrfiktivPartnerUserViewModelGen,
  MrfiktivUserIdentityViewModelGen,
  MrfiktivUserPermissionDtoGen,
  MrfiktivUserViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerUser<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags PartnerUser
   * @name PartnerUserControllerCreate
   * @summary Create a user
   * @request POST:/partner/{partnerId}/user
   * @secure
   * @response `200` `MrfiktivPartnerUserViewModelGen` The created user
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `409` `MrfiktivExceptionViewmodelGen` A user with this email already exists
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerUserControllerCreate = (partnerId: string, data: MrfiktivCreateUserDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerUserViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/user`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerUser
   * @name PartnerUserControllerFindAllByPartnerId
   * @summary Get all users for a partner
   * @request GET:/partner/{partnerId}/user
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivPartnerUserViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerUserControllerFindAllByPartnerId = (
    { partnerId, ...query }: MrfiktivPartnerUserControllerFindAllByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivPartnerUserViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/user`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerUser
   * @name PartnerUserControllerFindOneByPartnerId
   * @summary Get a user by id
   * @request GET:/partner/{partnerId}/user/{id}
   * @secure
   * @response `200` `MrfiktivPartnerUserViewModelGen` The found user
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerUserControllerFindOneByPartnerId = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerUserViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/user/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerUser
   * @name PartnerUserControllerRemove
   * @summary Deletes a user for a given partner
   * @request DELETE:/partner/{partnerId}/user/{id}
   * @secure
   * @response `200` `MrfiktivPartnerUserViewModelGen` User deleted
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerUserControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerUserViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/user/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerUser
   * @name PartnerUserControllerFindOneByNameAndPartnerId
   * @summary Get a user by username
   * @request GET:/partner/{partnerId}/user/{username}/byName
   * @secure
   * @response `200` `MrfiktivPartnerUserViewModelGen` The found user
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerUserControllerFindOneByNameAndPartnerId = (partnerId: string, username: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerUserViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/user/${username}/byName`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerUser
   * @name PartnerUserControllerGetUserStatusByPartnerId
   * @summary Get a user by id
   * @request GET:/partner/{partnerId}/user/{id}/status
   * @secure
   * @response `200` `MrfiktivUserIdentityViewModelGen` The found user
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerUserControllerGetUserStatusByPartnerId = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserIdentityViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/user/${id}/status`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * @description <p>This endpoint can be used in the admin sign up flow</p> <ol> <li>Partner invites a User</li> <li>User lost invitation mail or temporary password is exceeded (Status is still FORCE_CHANGE_PASSWORD)</li> <li>Use this endpoint to resend invitation mail for a user from the user pool</li> </ol> <p>Summary: Resend invitation for an invited user in case the user lost the initial mail. Is only working is the user status is FORCE_CHANGE_PASSWORD</p>
   *
   * @tags PartnerUser
   * @name PartnerUserControllerResendInvitation
   * @summary Resend invitation for an invited user in case the user lost the initial mail. Is only working if the user status is FORCE_CHANGE_PASSWORD
   * @request POST:/partner/{partnerId}/user/{id}/resendInvitationMessage
   * @secure
   * @response `200` `MrfiktivPartnerUserViewModelGen` The reinvited user
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerUserControllerResendInvitation = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivPartnerUserViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/user/${id}/resendInvitationMessage`,
      method: "POST",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerUser
   * @name PartnerUserControllerAddPermission
   * @summary Add a permission to a user by id
   * @request PATCH:/partner/{partnerId}/user/{id}/permission/add
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The updated record
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerUserControllerAddPermission = (
    partnerId: string,
    id: string,
    data: MrfiktivUserPermissionDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivUserViewmodelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/user/${id}/permission/add`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags PartnerUser
   * @name PartnerUserControllerRemovePermission
   * @summary Removes a permission for a user by id
   * @request PATCH:/partner/{partnerId}/user/{id}/permission/remove
   * @secure
   * @response `200` `MrfiktivPartnerUserViewModelGen` The updated record
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  partnerUserControllerRemovePermission = (
    partnerId: string,
    id: string,
    data: MrfiktivUserPermissionDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPartnerUserViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/user/${id}/permission/remove`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
