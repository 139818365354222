var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('the-navigation-layout', {
    attrs: {
      "items": _vm.partnerItems
    }
  }), _vm.$can(_vm.ActionEnum.MANAGE, _vm.ResourceEnum.AFFILIATE) ? _c('the-navigation-layout', {
    attrs: {
      "items": _vm.affiliateItems
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }