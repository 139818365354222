var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": _vm.disabled ? 12 : 9
    }
  }, [_c('v-combobox', {
    staticStyle: {
      "backdrop-filter": "blur(1px)"
    },
    attrs: {
      "disabled": _vm.disabled,
      "outlined": "",
      "dense": _vm.dense,
      "items": _vm.options,
      "label": _vm.label || _vm.$t("components.template.editor.placeholder.token"),
      "autofocus": "",
      "persistent-hint": Boolean(_vm.persistentHint),
      "hint": _vm.hint
    },
    on: {
      "change": _vm.onChangeSelection
    },
    model: {
      value: _vm.option,
      callback: function callback($$v) {
        _vm.option = $$v;
      },
      expression: "option"
    }
  })], 1), !_vm.disabled ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-checkbox', {
    style: _vm.dense ? 'margin-top: 2px' : '',
    on: {
      "change": _vm.onChangeSelection
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('small', [_vm._v(" " + _vm._s(_vm.$t("components.template.editor.placeholder.format")) + " ")])];
      },
      proxy: true
    }], null, false, 1944075586),
    model: {
      value: _vm.isFormatter,
      callback: function callback($$v) {
        _vm.isFormatter = $$v;
      },
      expression: "isFormatter"
    }
  })], 1) : _vm._e(), _vm.isFormatter ? _c('v-col', {
    class: _vm.persistentHint ? 'mt-n4' : 'mt-n8',
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    staticStyle: {
      "backdrop-filter": "blur(1px)"
    },
    attrs: {
      "outlined": "",
      "dense": _vm.dense,
      "items": _vm.formatters,
      "label": _vm.$t("components.template.editor.placeholder.formatOptions")
    },
    on: {
      "change": _vm.onChangeSelection
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("components.template.editor.placeholder.formatters.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("components.template.editor.placeholder.formatters.".concat(item))) + " ")];
      }
    }], null, false, 1372063772),
    model: {
      value: _vm.formatter,
      callback: function callback($$v) {
        _vm.formatter = $$v;
      },
      expression: "formatter"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }