import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
/**
 * Helper class to lookup partner.
 */
export class LocalHostToPartnerMapper {
  /**
   * @description Maps a local host to a partner
   * @param {string} host
   * @returns {string} if partner is found
   * @author Mark Warneke
   */
  public static async tryGetPartnerByHost(
    host: string,
    mappings: { [key: string]: string } = {
      localhost: process.env.VUE_APP_LOCALHOST_PARTNER ?? "mrfiktiv"
    }
  ) {
    const partnerName = mappings[host];

    if (partnerName) {
      const partner = await PartnerModule.getPartnerByName(partnerName);
      ReportModule.setPartnerByName(partner);
    } else {
      const partner = await PartnerModule.getPartnerByHost(host);
      ReportModule.setPartnerByName(partner);
    }
  }
}
