var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.signRequest ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-email-outline")])], 1) : _vm._e(), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDialogActive,
      "title": _vm.$t('components.template.dialog.preview.title'),
      "leftText": _vm.$t('components.template.dialog.preview.back'),
      "rightText": _vm.$t('components.template.dialog.preview.send'),
      "supressKeyboardConfirm": true,
      "fullscreen": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.send
    }
  }, [_c('report-document-sign-request-stepper-send-mail', {
    ref: "templateCard",
    staticStyle: {
      "height": "calc(100vh - 180px)"
    },
    attrs: {
      "signRequest": _vm.signRequest,
      "report": _vm.report,
      "url": _vm.url,
      "source": _vm.source
    },
    on: {
      "setUrl": function setUrl(v) {
        return _vm.url = v;
      },
      "sent": function sent($event) {
        _vm.isDialogActive = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }