/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivKsrAppointmentSlotsViewModelGen,
  MrfiktivKsrContactInfoViewModelGen,
  MrfiktivKsrControllerFindSlotsParamsGen,
  MrfiktivKsrControllerGetCustomerContactInfoParamsGen,
  MrfiktivKsrControllerGetJobDetailByJobIdParamsGen,
  MrfiktivKsrControllerGetJobDetailByNumberPlateParamsGen,
  MrfiktivKsrCustomerInfoViewModelGen,
  MrfiktivKsrExportReportDtoGen,
  MrfiktivKsrJobDetailViewmodelGen,
  MrfiktivKsrSetupDtoGen,
  MrfiktivKsrSetupViewModelGen,
  MrfiktivOperationIdViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Ksr<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ksr
   * @name KsrControllerSetup
   * @summary setup the ksr configuration
   * @request POST:/partner/{partnerId}/ksr
   * @secure
   * @response `201` `MrfiktivKsrSetupViewModelGen` Setup is completed
   * @response `400` `void` Setup failed
   */
  ksrControllerSetup = (partnerId: string, data: MrfiktivKsrSetupDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivKsrSetupViewModelGen, void>({
      path: `/partner/${partnerId}/ksr`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ksr
   * @name KsrControllerIsSetup
   * @summary Check if ksr is setup for a partner
   * @request GET:/partner/{partnerId}/ksr
   * @secure
   * @response `200` `MrfiktivKsrSetupViewModelGen` Contains information if KSR is setup correctly
   */
  ksrControllerIsSetup = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivKsrSetupViewModelGen, any>({
      path: `/partner/${partnerId}/ksr`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ksr
   * @name KsrControllerGetCustomerContactInfo
   * @summary setup the ksr configuration
   * @request GET:/partner/{partnerId}/ksr/contact
   * @secure
   * @response `200` `(MrfiktivKsrContactInfoViewModelGen)[]` Query successful
   */
  ksrControllerGetCustomerContactInfo = (
    { partnerId, ...query }: MrfiktivKsrControllerGetCustomerContactInfoParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivKsrContactInfoViewModelGen[], any>({
      path: `/partner/${partnerId}/ksr/contact`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ksr
   * @name KsrControllerGetCustomerInfo
   * @summary (PUBLIC) Get customerinformation of ksr user. This route must be activated in the parter settings: "KSR_USER_DATA_ACCESS"
   * @request GET:/partner/{partnerId}/ksrcustomer
   * @response `200` `MrfiktivKsrCustomerInfoViewModelGen` Contains information of user
   */
  ksrControllerGetCustomerInfo = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivKsrCustomerInfoViewModelGen, any>({
      path: `/partner/${partnerId}/ksrcustomer`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ksr
   * @name KsrControllerExport
   * @summary Export a report to a KSR job
   * @request POST:/partner/{partnerId}/ksrjob/{reportId}
   * @secure
   * @response `201` `MrfiktivOperationIdViewModelGen` Successfully queued
   */
  ksrControllerExport = (
    partnerId: string,
    reportId: string,
    data: MrfiktivKsrExportReportDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivOperationIdViewModelGen, any>({
      path: `/partner/${partnerId}/ksrjob/${reportId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ksr
   * @name KsrControllerGetJobDetailByNumberPlate
   * @summary Get the KSR document for a number plate
   * @request GET:/partner/{partnerId}/ksrjobs
   * @secure
   * @response `200` `(MrfiktivKsrJobDetailViewmodelGen)[]` Successfully retrieved
   */
  ksrControllerGetJobDetailByNumberPlate = (
    { partnerId, ...query }: MrfiktivKsrControllerGetJobDetailByNumberPlateParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivKsrJobDetailViewmodelGen[], any>({
      path: `/partner/${partnerId}/ksrjobs`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ksr
   * @name KsrControllerGetJobDetailByJobId
   * @summary Get the KSR document for a jobId
   * @request GET:/partner/{partnerId}/ksrjob
   * @secure
   * @response `200` `MrfiktivKsrJobDetailViewmodelGen` Successfully retrieved
   */
  ksrControllerGetJobDetailByJobId = (
    { partnerId, ...query }: MrfiktivKsrControllerGetJobDetailByJobIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivKsrJobDetailViewmodelGen, any>({
      path: `/partner/${partnerId}/ksrjob`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ksr
   * @name KsrControllerFindSlots
   * @summary Get the KSR slots
   * @request GET:/partner/{partnerId}/ksrslots
   * @secure
   * @response `200` `MrfiktivKsrAppointmentSlotsViewModelGen` Successfully retrieved
   */
  ksrControllerFindSlots = (
    { partnerId, ...query }: MrfiktivKsrControllerFindSlotsParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivKsrAppointmentSlotsViewModelGen, any>({
      path: `/partner/${partnerId}/ksrslots`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
