import { ExportModule } from "../../store/modules/export.store";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { IReportHomeQueryParams } from "../interfaces/Report/IReportHomeQueryParams";
import { ConfigModule } from "@/store/modules/config";
import { ReportType } from "@/store/enum/reportType";
import i18n from "@/plugins/I18nPlugin";

export const reportHomeQueryParams: IReportHomeQueryParams[] = [
  {
    key: "firstName",
    onFound: (value: string) => ReportModule.setUser({ firstName: value }),
    get: () => ReportModule.user.firstName
  },
  {
    key: "lastName",
    onFound: (value: string) => ReportModule.setUser({ lastName: value }),
    get: () => ReportModule.user.lastName
  },
  {
    key: "companyName",
    onFound: (value: string) => ReportModule.setCompanyName(value),
    get: () => ReportModule.companyName
  },
  {
    key: "phone",
    onFound: (value: string) => ReportModule.setContact({ phone: value }),
    get: () => ReportModule.contact.phone
  },
  {
    key: "email",
    onFound: (value: string) => ReportModule.setContact({ email: value }),
    get: () => ReportModule.contact.email
  },
  {
    key: "city",
    onFound: (value: string) => ReportModule.setAddress({ city: value }),
    get: () => ReportModule.address.city
  },
  {
    key: "zip",
    onFound: (value: string) => ReportModule.setAddress({ zip: value }),
    get: () => ReportModule.address.zip
  },
  {
    key: "street",
    onFound: (value: string) => ReportModule.setAddress({ street: value }),
    get: () => ReportModule.address.street
  },
  {
    key: "campaign",
    onFound: (value: string) => ThgCreateModule.setCampaign(value),
    get: () => ThgCreateModule.campaign
  },
  {
    key: "code",
    onFound: (value: string) => ThgCreateModule.setCode(value),
    get: () => ThgCreateModule.code
  },
  {
    key: "type",
    onFound: (value: string) => ReportModule.setReportType(value as ReportType),
    get: () => ReportModule.reportType
  },
  {
    key: "externalId",
    onFound: (value: string) => ReportModule.setExternalId(value as ReportType),
    get: () => ReportModule.externalId
  },
  {
    key: "jobId",
    onFound: (value: string) => {
      // could be jobId=123,456
      if (value) {
        const jobIdDelimiter = ",";
        const jobId = value.split(jobIdDelimiter);

        ReportModule.setKsrData({ jobId: jobId[0] });
      }
    },
    get: () => ReportModule.ksrData.jobId || ""
  },
  {
    key: "numberPlate",
    onFound: (value: string) => {
      ReportModule.setNumberplate(value);
      ReportModule.setKsrData({ numberPlate: value });
      ExportModule.setDaMetaData({ numberPlate: value });
    },
    get: () => {
      let res = "";
      if (ReportModule.ksrData.numberPlate) {
        res += `KSRMeta: ${ReportModule.ksrData.numberPlate}`;
      }
      if (ExportModule.daMetaData.numberPlate) {
        if (res) {
          res += ", ";
        }
        res += `DAMeta: ${ExportModule.daMetaData.numberPlate}`;
      }
      if (ReportModule.numberplate) {
        if (res) {
          res += ", ";
        }
        res += `Report: ${ReportModule.numberplate}`;
      }
      return res;
    }
  },
  {
    key: "customerIdentityToken",
    onFound: (value: string) => ReportModule.setKsrData({ customerIdentityToken: value }),
    get: () => ReportModule.ksrData.customerIdentityToken || ""
  },
  {
    key: "serviceEventId",
    onFound: (value: string) => {
      ExportModule.setDaMetaData({ serviceEventId: value });
      ReportModule.setExternalId(value);
    },
    get: () => ExportModule.daMetaData.serviceEventId || ""
  },
  {
    key: "workshopTaskId",
    onFound: (value: string) => ExportModule.setDaMetaData({ workshopTaskId: value }),
    get: () => ExportModule.daMetaData.workshopTaskId || ""
  },
  {
    key: "headless",
    onFound: () => ConfigModule.setHeadless({ value: true }),
    get: () => (ConfigModule.headless ? "true" : "false")
  },
  {
    key: "debug",
    onFound: () => ConfigModule.setDebug(true),
    get: () => (ConfigModule.debug ? "true" : "false")
  },
  {
    key: "registration_firstname",
    onFound: (value: string) => (ReportModule.registration.firstname = value),
    get: () => ReportModule.registration.firstname || ""
  },
  {
    key: "registration_name",
    onFound: (value: string) => (ReportModule.registration.name = value),
    get: () => ReportModule.registration.name || ""
  },
  {
    key: "registration_street",
    onFound: (value: string) => (ReportModule.registration.street = value),
    get: () => ReportModule.registration.street || ""
  },
  {
    key: "registration_zipCode",
    onFound: (value: string) => (ReportModule.registration.zipCode = value),
    get: () => ReportModule.registration.zipCode || ""
  },
  {
    key: "registration_city",
    onFound: (value: string) => (ReportModule.registration.city = value),
    get: () => ReportModule.registration.city || ""
  },
  {
    key: "registration_huYear",
    onFound: (value: string) => (ReportModule.registration.huYear = value),
    get: () => ReportModule.registration.huYear || ""
  },
  {
    key: "registration_huMonth",
    onFound: (value: string) => (ReportModule.registration.huMonth = value),
    get: () => ReportModule.registration.huMonth || ""
  },
  {
    key: "registration_manufacturerNameCode",
    onFound: (value: string) => (ReportModule.registration.manufacturerNameCode = value),
    get: () => ReportModule.registration.manufacturerNameCode || ""
  },
  {
    key: "registration_manufacturerTypeCode",
    onFound: (value: string) => (ReportModule.registration.manufacturerTypeCode = value),
    get: () => ReportModule.registration.manufacturerTypeCode || ""
  },
  {
    key: "registration_driveTyp",
    onFound: (value: string) => (ReportModule.registration.driveTyp = value),
    get: () => ReportModule.registration.driveTyp || ""
  },
  {
    key: "registration_identificationnumber",
    onFound: (value: string) => (ReportModule.registration.identificationnumber = value),
    get: () => ReportModule.registration.identificationnumber || ""
  },
  {
    key: "registration_numberplate",
    onFound: (value: string) => (ReportModule.registration.numberplate = value),
    get: () => ReportModule.registration.numberplate || ""
  },
  {
    key: "registration_firstregistrationDay",
    onFound: (value: string) => (ReportModule.registration.firstregistrationDay = value),
    get: () => ReportModule.registration.firstregistrationDay || ""
  },
  {
    key: "registration_firstregistrationMonth",
    onFound: (value: string) => (ReportModule.registration.firstregistrationMonth = value),
    get: () => ReportModule.registration.firstregistrationMonth || ""
  },
  {
    key: "registration_firstregistrationYear",
    onFound: (value: string) => (ReportModule.registration.firstregistrationYear = value),
    get: () => ReportModule.registration.firstregistrationYear || ""
  },
  {
    key: "registration_manufacturerName",
    onFound: (value: string) => (ReportModule.registration.manufacturerName = value),
    get: () => ReportModule.registration.manufacturerName || ""
  },
  {
    key: "registration_manufacturerType",
    onFound: (value: string) => (ReportModule.registration.manufacturerType = value),
    get: () => ReportModule.registration.manufacturerType || ""
  },
  {
    key: "registration_vehicleClass",
    onFound: (value: string) => (ReportModule.registration.vehicleClass = value),
    get: () => ReportModule.registration.vehicleClass || ""
  },
  {
    key: "lang",
    onFound: (value: string) => (i18n.locale = value),
    get: () => i18n.locale || "de"
  }
];
