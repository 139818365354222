






























import DragMixin from "@/views/sign/DragMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";

/**
 * Component that looks like a button but is a card.
 * Displays an example image which is overwritten by the uploaded one.
 * Displays a loading animation via property.
 */
@Component({})
export default class ImageUploadButton extends mixins(DragMixin) {
  /**
   * Color of the icon.
   */
  @Prop({})
  iconColor?: string;

  /**
   * Icon to be displayed on the button card.
   */
  @Prop({ default: "mdi-camera" })
  icon: any;

  /**
   * Text to be displayed on the button card.
   */
  @Prop({ default: "defaultFoto" })
  text: any;

  /**
   * An alternative text to be displayed on @isUploade
   */
  @Prop({ default: "defaultFotoAgain" })
  textAlt: any;

  /**
   * Indicates wether a given file is uploaded or not.
   * Changes in display behaviour based on this sate, e.g. alt text.
   */
  @Prop({ default: false })
  isUploaded!: boolean;

  /**
   * Indicates wether a given file is currently uploading or not.
   * Changes in display behaviour based on this sate, e.g. loading spinner.
   */
  @Prop({ default: false })
  isLoading!: boolean;

  /**
   * handle drag over event. Do not execute if component is loading
   */
  handleDragOver() {
    if (this.isLoading) {
      return;
    }
    this.dragOver();
  }

  /**
   * handle drag leave event. Do not execute if component is loading
   */
  handleDragLeave(event: DragEvent) {
    if (this.isLoading) {
      return;
    }
    this.dragLeave(event);
  }

  /**
   * handle file drop event. Do not execute if component is loading
   */
  handleFileDrop(event: DragEvent) {
    if (this.isLoading) {
      return;
    }
    this.onFileDrop(event);
  }

  /**
   * Displays either the text of the alternative text based on the upload status.
   */
  get displayText() {
    return this.isUploaded ? this.textAlt : this.text;
  }

  onDrop(file: File) {
    this.$emit("onDrop", file);
  }
}
