import { render, staticRenderFns } from "./BookingSlots.vue?vue&type=template&id=655ac003&scoped=true&"
import script from "./BookingSlots.vue?vue&type=script&lang=ts&"
export * from "./BookingSlots.vue?vue&type=script&lang=ts&"
import style0 from "./BookingSlots.vue?vue&type=style&index=0&id=655ac003&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "655ac003",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VBtn,VCardText,VList,VListItem,VRow,VSkeletonLoader,VVirtualScroll})
