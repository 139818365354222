/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * The format of the message body
 */
export enum MessageContentFormatEnum {
  /**
   * Plaintext
   */
  PLAIN = "plain",

  /**
   * HTML Code. (When passing an html body into the template factories, it will be minified)
   */
  HTML = "html"
}
