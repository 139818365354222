export enum DetailFormComponentsEnum {
  SELECT_FIELD = "v-select",
  AUTO_COMPLETE = "v-autocomplete",
  TEXT_FIELD = "v-text-field",
  TEXT_AREA = "v-textarea",
  CUSTOM_FIELD = "custom-field",
  PARTNER_SINGLE_IMAGE_UPLOAD = "partner-single-image-upload",
  REFS_SELECT = "refs-select",
  CUSTOM_FIELD_DATE_TIME = "custom-field-date-time"
}
