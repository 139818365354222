export enum BookingScreenEnum {
  START = "start",
  SERVICES = "services",
  CALENDER = "calender",
  SLOTS = "slots",
  FORM = "form",
  SUCCESS = "success",
  ERROR = "error"
}

export enum BookingStepEnum {
  START = "start",
  SERVICES = "services",
  CALENDER = "calender",
  SLOTS = "slots",
  FORM = "form",
  SUCCESS = "success",
  ERROR = "error"
}
