import chargingStationService from "@/services/thg/services/chargingStationService";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  ThgChargingStationViewModelGen,
  ThgCreateChargingStationDtoGen,
  ThgChargingStationProofDtoGen,
  ThgChargingStationProofDocumentDtoGen,
  ThgUpdateMyChargingStationShortDtoGen
} from "../../services/thg/v1/data-contracts";
import eventBus from "@/lib/eventBus";
import { EventBusEnum } from "@/lib/enum/eventBus.enum";
/**
 * Store to handle charging stations for user
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "charging-station",
  store
})
export class ChargingStationStore extends VuexModule {
  /**
   * A single active charing station
   */
  private _activeChargingStation: ThgChargingStationViewModelGen | undefined = undefined;

  /**
   * All fetched charing stations
   */
  private _chargingStations: ThgChargingStationViewModelGen[] = [];

  private _chargingStationPdfProof: ThgChargingStationProofDocumentDtoGen = null as any;
  private _chargingStationImageProof: ThgChargingStationProofDocumentDtoGen = null as any;

  /**
   * Charging Stations
   */

  /**
   * Gets all charging stations
   */
  get charingStations(): ThgChargingStationViewModelGen[] {
    return this._chargingStations;
  }

  /**
   * mutation for all charing stations
   * @param chargingStations charging stations to put into store
   */
  @Mutation
  private _mutateChargingStations(chargingStations: ThgChargingStationViewModelGen[]): void {
    this._chargingStations = chargingStations;
  }

  /**
   * Fetches all charging stations for current user
   */
  @Action
  async getChargingStations(): Promise<void> {
    const stations = await chargingStationService.getAllForUser();
    this.context.commit("_mutateChargingStations", stations);
  }

  /**
   * Removes charing stations from store
   */
  @Action
  clearChargingStations() {
    this.context.commit("_mutateChargingStations", []);
  }

  /**
   * Active Charging Station
   */

  /**
   * Returns the active charing station
   */
  get activeChargingStation(): ThgChargingStationViewModelGen | undefined {
    return this._activeChargingStation;
  }

  /**
   * Sets the active charging station.
   *
   * @param activeChargingStation the charging station to put into active
   */
  @Mutation
  private _mutateActiveChargingStation(activeChargingStation: ThgChargingStationViewModelGen): void {
    this._activeChargingStation = activeChargingStation;
  }

  /**
   * Creates a charging station
   *
   * @param data dto to create charging station
   */
  @Action
  async createChargingStation(data: ThgCreateChargingStationDtoGen): Promise<void> {
    const created = await chargingStationService.create(data);

    this.context.commit("_mutateActiveChargingStation", created);
    eventBus.$emit(EventBusEnum.CHARGING_STATION_CREATED);
  }

  /** PROOFS */

  @Action
  async uploadChargingStationPdfProof(
    data: ThgChargingStationProofDtoGen
  ): Promise<ThgChargingStationProofDocumentDtoGen> {
    const proof = await chargingStationService.uploadPdfProof(data);
    this.context.commit("_mutateChargingStationPdfProof", proof);
    return proof;
  }

  @Action
  async uploadChargingStationImageProof(
    data: ThgChargingStationProofDtoGen
  ): Promise<ThgChargingStationProofDocumentDtoGen> {
    const proof = await chargingStationService.uploadImageProof(data);
    this.context.commit("_mutateChargingStationImageProof", proof);
    return proof;
  }

  @Mutation
  _mutateChargingStationPdfProof(data: ThgChargingStationProofDocumentDtoGen) {
    this._chargingStationPdfProof = data;
  }

  @Mutation
  _mutateChargingStationImageProof(data: ThgChargingStationProofDocumentDtoGen) {
    this._chargingStationImageProof = data;
  }

  /** END PROOFS */

  /**
   * Gets one charging station.
   *
   * @param id the id of the charging station to be fetched
   */
  @Action
  async getChargingStation(id: string): Promise<ThgChargingStationViewModelGen> {
    const station = await chargingStationService.getOneForUser(id);
    this.context.commit("_mutateActiveChargingStation", station);

    return station;
  }

  /**
   * Removes a charging station.
   *
   * @param id the charging station to be removed
   */
  @Action
  async removeChargingStation(id: string): Promise<void> {
    await chargingStationService.removeForUser(id);
    this.context.commit("_mutateActiveChargingStation", undefined);
  }

  /**
   * Update a charging station.
   *
   * @param id the id to be update
   * @param data the updated properties
   */
  @Action
  async updateChargingStation(data: { id: string; data: ThgUpdateMyChargingStationShortDtoGen }): Promise<void> {
    const updated = await chargingStationService.updateForUser(data.id, data.data);
    this.context.commit("_mutateActiveChargingStation", updated);
  }

  /**
   * Removes all charging stations from the store.
   */
  @Action
  clearChargingStation() {
    this.context.commit("_mutateActiveChargingStation", undefined);
  }
}

export const ChargingStationModule = getModule(ChargingStationStore);
