


















import { Component, Mixins } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";
import LoginMixin from "@/mixins/LoginMixin.vue";
import LoginComponentInputMail from "@/components/login/LoginComponentInputMail.vue";
import LoginComponentSubmitButton from "@/components/login/LoginComponentSubmitButton.vue";
import LoginComponentNavigationButton from "@/components/login/LoginComponentNavigationButton.vue";
import LoginComponentLayout from "@/components/login/LoginComponentLayout.vue";
import LoginComponentMessage from "@/components/login/LoginComponentMessage.vue";
import { IFailureCodeContent } from "@/mixins/LoginMixin.vue";
import LoginErrorMixin from "@/mixins/LoginErrorMixin.vue";

@Component({
  components: {
    LoginComponentInputMail,
    LoginComponentSubmitButton,
    LoginComponentNavigationButton,
    LoginComponentLayout,
    LoginComponentMessage
  }
})
export default class LoginCardConfirmMailRequestCode extends Mixins(LoginMixin, LoginErrorMixin) {
  customFailureCodes = new Map<string, IFailureCodeContent>([
    [
      "Could not resend code. Please contact Support.",
      { message: "components.login.LoginCardConfirmMailRequestCode.failResendMailConfirmationFailed" }
    ]
  ]);

  mounted() {
    this.registerCustomFailureCodes(this.customFailureCodes);
  }

  /**
   * Request mail confirmation code
   */
  async submit() {
    try {
      this.isLoading = true;
      await UserModule.requestNewConfirmMailCode(this.mail);
      this.isFormSubmitted = true;
      this.handleSuccess("/login/confirmMail");
    } catch (error) {
      this.handleFail(error);
    } finally {
      this.isLoading = false;
    }
  }
}
