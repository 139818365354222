/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 *
 * Enum that defines the foldername where the images are stored.
 *
 */
export enum PublicImageFolderEnum {
  THG = "public-thg",
  DAMAGEREPORT = "public-damagereport",
  OTHER = "public-other",
  PARTNER = "public-partner",
  MESSAGE = "public-message"
}
