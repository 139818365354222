import { Filter } from "@/lib/filterable";
import { MrfiktivPoliceDetailsGen } from "@/services/mrfiktiv/v1/data-contracts";

class PoliceDetailsBase implements MrfiktivPoliceDetailsGen {
  isRecorded: boolean;
  department?: string | undefined;
  fileReference?: string | undefined;

  constructor(policeDetails?: Partial<MrfiktivPoliceDetailsGen>) {
    this.isRecorded = policeDetails?.isRecorded ?? false;
    this.department = policeDetails?.department;
    this.fileReference = policeDetails?.fileReference;
  }
}

type IPoliceDetails = PoliceDetailsBase;
const PoliceDetails = Filter.createForClass(PoliceDetailsBase);

export { IPoliceDetails, PoliceDetails };
