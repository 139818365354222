/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Checks password to match given length of 8
 * @param password
 * @returns
 */
export const checkMinLength = (password: string): boolean => {
  return password.length >= 8;
};

/**
 * Contains a special character based on the AWS requirements
 * @param password
 * @returns
 */
export const containsSpecialCharacter = (password: string): boolean => {
  return password.match(/([\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])/) ? true : false;
};

/**
 * Contains at least one number
 * @param password
 * @returns
 */
export const containsNumber = (password: string): boolean => {
  return password.match(/[0-9]/) ? true : false;
};

/**
 * Contains at least one capital cased character in [A-Z]
 * @param password
 * @returns
 */
export const containsCapitalLatinLetter = (password: string): boolean => {
  return password.match(/[A-Z]/) ? true : false;
};

/**
 * Contains at least one lowercased cased character in [A-Z]
 * @param password
 * @returns
 */
export const containsLowerLatinLetter = (password: string): boolean => {
  return password.match(/[a-z]/) ? true : false;
};

/**
 * Returns a list of characters
 * @returns the characters from the @see containsSpecialCharacter method
 */
export function getSpecialCharsList() {
  return "^ $ * . [ ] { } ( ) ? \" ! @ # % & / \\ , > < ' : ; | _ ~ ` = + -";
}
