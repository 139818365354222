













import { SignDocumentTokenDtoGen } from "@/services/sign/v1/data-contracts";
import { ISignDocument } from "@/models/sign-document.entity";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class DocumentTemplateDetailSave extends Vue {
  @Prop()
  loading!: boolean;

  @Prop()
  document!: ISignDocument;

  initialDocument: ISignDocument | null = null;

  initialDocumentTokens: SignDocumentTokenDtoGen[][] = [];

  get documentTokens() {
    return DocumentTemplateModule.documentTokens;
  }

  get isDifferent() {
    /**
     * Attention: The order of the keys of the objects are important when stringifying and comparing.
     *
     * e.g.:
     * stringify({a: 1, b: 2}) === stringify({b: 2, a: 1}) // false
     */
    return (
      JSON.stringify(this.initialDocumentTokens) !== JSON.stringify(this.documentTokens) ||
      JSON.stringify(this.initialDocument) !== JSON.stringify(this.document)
    );
  }

  mounted() {
    this.initialDocument = JSON.parse(JSON.stringify(this.document));
    this.initialDocumentTokens = JSON.parse(JSON.stringify(this.documentTokens));
  }

  save() {
    this.$emit("save");
    this.initialDocument = JSON.parse(JSON.stringify(this.document));
    this.initialDocumentTokens = JSON.parse(JSON.stringify(this.documentTokens));
  }
}
