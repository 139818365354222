/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import publicImageService from "@/services/image/services/publicImageService";
import { PublicImageCreatePublicImageDtoGen, PublicImageImageViewmodelGen } from "@/services/image/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "publicImage",
  store
})
export class PublicImageStore extends VuexModule {
  private _publicImage: PublicImageImageViewmodelGen = {
    folderName: "",
    name: "",
    url: "",
    metaData: {
      originalName: "",
      size: 0,
      width: 0,
      height: 0,
      mimetype: ""
    },
    cdnUrl: "",
    type: "damage",
    timestamp: {}
  };

  get publicImage(): PublicImageImageViewmodelGen {
    return this._publicImage;
  }

  @Mutation
  mutatePublicImage(publicImage: PublicImageImageViewmodelGen) {
    this._publicImage = publicImage;
  }

  @Action
  async upload(dto: PublicImageCreatePublicImageDtoGen) {
    const publicImage = await publicImageService.upload(dto);
    this.context.commit("mutatePublicImage", publicImage);
  }
}

export const PublicImageModule = getModule(PublicImageStore);
