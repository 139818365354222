/**
 * Checks wether given user agent is mobile.
 *
 * @returns {boolean} true if mobile device.
 */
export function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}
