var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "transition": "dialog-bottom-transition",
      "max-width": "500"
    },
    scopedSlots: _vm._u([!_vm.hideButton ? {
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attr = _ref.attr;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "data-test-upload-image-open": ""
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attr, false), on), [_c('v-icon', [_vm._v("mdi-cloud-upload")])], 1)];
      }
    } : null], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1), _c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("components.publicImage.PublicImageUploadDialog.title")))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "loading": _vm.isLoading,
      "disabled": !_vm.valid,
      "data-test-upload-image-submit": ""
    },
    on: {
      "click": _vm.upload
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.publicImage.PublicImageUploadDialog.btnText")) + " ")])], 1), _c('v-divider'), _c('v-card-text', [!_vm.hideFolderSelect ? _c('v-select', {
    attrs: {
      "items": _vm.imageFolderName,
      "label": _vm.$t('components.publicImage.PublicImageUploadDialog.folderNameText'),
      "outlined": ""
    },
    model: {
      value: _vm.folderName,
      callback: function callback($$v) {
        _vm.folderName = $$v;
      },
      expression: "folderName"
    }
  }) : _vm._e(), _c('v-file-input', {
    staticClass: "ma-4",
    attrs: {
      "accept": _vm.imageInputTypes,
      "rules": [function (v) {
        return !!v || _vm.$t('report.thgContactData.mandatoryInput');
      }],
      "show-size": "",
      "outlined": "",
      "data-test-upload-image-input": ""
    },
    on: {
      "change": _vm.checkFileSize
    },
    model: {
      value: _vm.file,
      callback: function callback($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }