import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivContactDocumentGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
class ContactBase implements MrfiktivContactDocumentGen {
  /**
   * @inheritdoc
   */
  @FilterConfig({ type: FilterTypes.STRING })
  email: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({ type: FilterTypes.STRING })
  phone: string;

  constructor(contact?: Partial<MrfiktivContactDocumentGen>) {
    this.email = contact?.email ?? "";
    this.phone = contact?.phone ?? "";
  }
}

type IContact = ContactBase;
const Contact = Filter.createForClass(ContactBase);

export { Contact, IContact };
