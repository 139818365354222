import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { ITicket, Ticket } from "@/models/ticket.entity";
import ticketService from "@/services/mrfiktiv/services/ticketService";
import { MrfiktivTicketControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, getModule } from "vuex-module-decorators";
import { ticketStateMap } from "../mapper/ticket-state.map";
import { PaginatedBaseStore } from "../paginated-base.store";
import { TicketDataAccessLayer } from "./access-layers/ticket.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { FleetAggregationModule } from "./fleet-aggregation.store";
import { TicketPageDataProvider } from "./page-data-provider/ticket.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "ticket",
  store
})
export class TicketStore extends PaginatedBaseStore<ITicket, MrfiktivTicketControllerGetParamsGen> {
  _data = TicketDataAccessLayer;
  _pageProvider = TicketPageDataProvider;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  protected _isLoadAll = true;
  filterOptions: PaginationFilterListElement[] = [...this.originalFilterList].map(
    f => new PaginationFilterListElement(f)
  );

  get originalFilterList(): PaginationFilterListElement[] {
    return [...Ticket.filterables];
  }

  get ticketStateMap() {
    return ticketStateMap;
  }

  get tickets() {
    return this.paginationList;
  }

  @Action
  async archiveMany(data: { partnerId: string; tickets: ITicket[] }) {
    const updated = await ticketService.archiveMany(
      data.partnerId,
      data.tickets.map(t => t.id)
    );
    for (const data of updated) {
      const ticket = new Ticket(data);
      this._data.set(ticket);
      FleetAggregationModule.replaceTicket(ticket);
    }
  }

  @Action
  async deleteMany(data: { partnerId: string; tickets: ITicket[] }) {
    await ticketService.deleteMany(
      data.partnerId,
      data.tickets.map(t => t.id)
    );
    for (const ticket of data.tickets) {
      this._data.delete(ticket);
      FleetAggregationModule.removeTicket(ticket.number);
    }
  }
}

export const TicketModule = getModule(TicketStore);
