import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IThgMeterReading } from "@/models/thg-meter-reading.entity";
import meterReadingAdminService from "@/services/thg/services/meterReadingAdminService";
import { ThgPageViewModelGen, ThgThgMeterReadingControllerFindAllParamsGen } from "@/services/thg/v1/data-contracts";

export class ThgMeterReadingPageDataProvider extends AbstractPageDataProvider<
  IThgMeterReading,
  ThgThgMeterReadingControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: ThgThgMeterReadingControllerFindAllParamsGen): Promise<IPageViewModel<IThgMeterReading>> {
    const res = (await meterReadingAdminService.getAll(query)) as ThgPageViewModelGen & {
      data?: IThgMeterReading[] | undefined;
    };

    const meta = res.meta;
    const data = res.data ?? [];

    return { meta, data };
  }
}
