import { ReportRoleEnum } from "@/lib/enum/ReportRole.enum";
import { IsFilterable, Filter } from "@/lib/filterable";
import { MrfiktivAccidentDetailsGen } from "@/services/mrfiktiv/v1/data-contracts";
import { IAddressWithGeo, AddressWithGeo } from "@/models/address.entity";

@IsFilterable
class AccidentDetailsBase implements MrfiktivAccidentDetailsGen {
  role: ReportRoleEnum;
  date: string;
  comment?: string | undefined;
  address: IAddressWithGeo;

  constructor(accidentDetails?: Partial<MrfiktivAccidentDetailsGen>) {
    this.role = (accidentDetails?.role ?? ReportRoleEnum.UNCLEAR) as ReportRoleEnum;
    this.date = accidentDetails?.date ?? "";
    this.comment = accidentDetails?.comment;
    this.address = new AddressWithGeo(accidentDetails?.address);
  }
}

type IAccidentDetails = AccidentDetailsBase;
const AccidentDetails = Filter.createForClass(AccidentDetailsBase);

export { AccidentDetails, IAccidentDetails };
