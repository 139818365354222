









































import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { BookingServiceBookingViewModelGen } from "@/services/booking/v1/data-contracts";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import BookingNavigation from "./BookingNavigation.vue";
import BookingSelectedService from "./BookingSelectedService.vue";
import BookingServicesItem from "./BookingServicesItem.vue";

@Component({ components: { BookingNavigation, BookingSelectedService, BookingServicesItem } })
export default class BookingServices extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  /**
   * Flag if booking is used in an iframe(design should not change)
   */
  @Prop({ default: false })
  bookingIframe!: boolean;

  get showListView() {
    if (this.bookingIframe) {
      return false;
    }

    if (this.isMobile) {
      return false;
    }

    return true;
  }

  get image() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.outOfService);
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partnerMail() {
    return this.partner?.contact?.email || "";
  }

  back() {
    AvailabilityModule.setStart();
  }

  forward() {
    AvailabilityModule.setCalender();
  }

  get loadingAnimation() {
    if (this.isMobile) {
      return "heading, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line";
    }
    return "heading, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line";
  }

  get height() {
    return "495";
  }

  get bookableServices(): BookingServiceBookingViewModelGen[] {
    return AvailabilityModule.paginationList;
  }

  get isLoadingServices() {
    return AvailabilityModule.isLoadingServices;
  }

  get serviceId() {
    return AvailabilityModule.serviceId;
  }

  set serviceId(id: string) {
    AvailabilityModule.setServiceId(id);
  }

  selectService(item: BookingServiceBookingViewModelGen) {
    this.$log.debug(item);
    this.serviceId = item.serviceId;
    AvailabilityModule.setCalender();
  }
}
