import eventService from "@/services/mrfiktiv/services/eventService";
import { MrfiktivEventControllerListParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { FleetAggregationModule } from "./fleet-aggregation.store";
import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import vehicleEventService from "@/services/mrfiktiv/services/vehicleEventService";

/**
 * Store to handle all events in a time frame. @see RecurringEventStore
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "event-list",
  store
})
export class ListEventStore extends VuexModule {
  protected _allEvents: IEventUIDto[] = [];
  protected _allOverdueEvents: IEventUIDto[] = [];

  protected _dateRange: string[] = [
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString(),
    new Date().toISOString()
  ];

  get dateRange(): string[] {
    return this._dateRange;
  }

  @Mutation
  _mutateDateRange(dateRange: string[]) {
    this._dateRange = dateRange;
  }

  @Action
  setDateRange(dateRange: string[]) {
    this.context.commit("_mutateDateRange", dateRange);
  }

  /**
   * All events in a given time frame.
   */
  get events() {
    return this._allEvents.map(e => new EventUIDto(e));
  }

  get overdueEvents() {
    return this._allOverdueEvents.map(e => new EventUIDto(e));
  }

  @Mutation
  private _mutateAllEvents(request: IEventUIDto[]) {
    this._allEvents = request;
  }

  @Mutation
  private _mutateOverdueEvents(request: IEventUIDto[]) {
    this._allOverdueEvents = request;
  }

  @Action
  async listAll(data: MrfiktivEventControllerListParamsGen & { vehicleId?: string }) {
    let res;
    if (!data.vehicleId) {
      res = await eventService.listAll({ partnerId: data.partnerId, from: data.from, to: data.to });
    } else {
      res = await vehicleEventService.listAll({
        partnerId: data.partnerId,
        from: data.from,
        to: data.to,
        vehicleId: data.vehicleId
      });
    }

    const events = res.map(e => new EventUIDto(e));
    this.context.commit("_mutateAllEvents", events);

    Vue.$log.debug("Paged events. Parsing for fleet ", res?.length);
    FleetAggregationModule.parseEvents(events ?? []);

    return res;
  }

  @Action
  async listOverdue(data: { partnerId: string; vehicleId?: string }) {
    let res;

    if (!data.vehicleId) {
      res = await eventService.listOverdue(data.partnerId);
    } else {
      res = await vehicleEventService.listOverdue(data.partnerId, data.vehicleId);
    }

    const events = res.map(e => new EventUIDto(e));
    this.context.commit("_mutateOverdueEvents", events);

    Vue.$log.debug("Paged events. Parsing for fleet ", events?.length);
    FleetAggregationModule.parseEvents(events ?? []);

    return res;
  }

  @Action
  async reset() {
    this.context.commit("_mutateAllEvents", []);
    this.context.commit("_mutateOverdueEvents", []);
  }
}

export const EventListModule = getModule(ListEventStore);
