import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Event } from "../v1/Event";
import {
  MrfiktivAcknowledgeDateDtoGen,
  MrfiktivArchiveEventInstanceDtoGen,
  MrfiktivArchiveVirtualEventDtoGen,
  MrfiktivCreateBaseEventDtoGen,
  MrfiktivCreateEventDtoGen,
  MrfiktivInterruptSeriesDtoGen,
  MrfiktivEventControllerGetParamsGen,
  MrfiktivEventControllerListParamsGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateEventDtoGen
} from "../v1/data-contracts";
import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";

/**
 * Communicate with event API
 */
class EventService {
  mrfiktivProxy: Event;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Event(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an event
   */
  async create(partnerId: string, data: MrfiktivCreateEventDtoGen) {
    const event = (await this.mrfiktivProxy.eventControllerCreate(partnerId, data)).data;

    return new EventUIDto(event);
  }

  /**
   * Get all event
   */
  async listAll(data: MrfiktivEventControllerListParamsGen) {
    const events = (await this.mrfiktivProxy.eventControllerList(data)).data;

    return events.map(event => new EventUIDto(event));
  }

  /**
   * Get all overdue event
   */
  async listOverdue(partnerId: string) {
    const events = (await this.mrfiktivProxy.eventControllerOverdue(partnerId)).data;

    return events.map(event => new EventUIDto(event));
  }

  /**
   * Get all event
   */
  async getAll(data: MrfiktivEventControllerGetParamsGen) {
    const events = (await this.mrfiktivProxy.eventControllerGet(data)).data;

    const mapped: MrfiktivPageViewModelGen & {
      data?: IEventUIDto[] | undefined;
    } = { ...events, data: events.data?.map(event => new EventUIDto(event)) };
    return mapped;
  }

  /**
   * Get a cueventstom
   */
  async getOne(partnerId: string, eventId: string) {
    const event = (await this.mrfiktivProxy.eventControllerGetOne(partnerId, eventId)).data;

    return new EventUIDto(event);
  }

  /**
   * Update a event
   */
  async update(partnerId: string, eventId: string, data: MrfiktivUpdateEventDtoGen) {
    const event = (await this.mrfiktivProxy.eventControllerUpdate(partnerId, eventId, data)).data;

    return new EventUIDto(event);
  }

  /**
   * Delete a event
   */
  async delete(partnerId: string, eventId: string) {
    const event = (await this.mrfiktivProxy.eventControllerDelete(partnerId, eventId)).data;

    return new EventUIDto(event);
  }

  /**
   * Acknowledge a event
   */
  async acknowledge(partnerId: string, eventId: string, start: number, data: MrfiktivAcknowledgeDateDtoGen) {
    const event = (await this.mrfiktivProxy.eventControllerAcknowledge(partnerId, eventId, start, data)).data;

    return new EventUIDto(event);
  }

  /**
   * Get a from a virtual event
   */
  async createVirtual(partnerId: string, eventId: string, start: number, data: MrfiktivCreateBaseEventDtoGen) {
    const event = (await this.mrfiktivProxy.eventControllerCreateVirtual(partnerId, eventId, start, data)).data;

    return new EventUIDto(event);
  }

  /**
   * Delete a bundle of events
   * @param partnerId
   * @param ids
   */
  async deleteMany(partnerId: string, ids: string[]) {
    (await this.mrfiktivProxy.eventControllerDeleteMany(partnerId, { ids })).data;
  }

  /**
   * Remove a selection of events
   * @param partnerId
   * @param ids
   */
  async archiveMany(
    partnerId: string,
    identifier: (MrfiktivArchiveVirtualEventDtoGen | MrfiktivArchiveEventInstanceDtoGen)[]
  ) {
    return (await this.mrfiktivProxy.eventControllerArchiveMany(partnerId, { identifier })).data;
  }

  /**
   * Remove a selection of events
   * @param partnerId
   * @param ids
   */
  async interruptSeries(partnerId: string, eventId: string, data: MrfiktivInterruptSeriesDtoGen) {
    return (await this.mrfiktivProxy.eventControllerInterruptSeries(partnerId, eventId, data)).data;
  }
}

/**
 * Communicate with event API
 */
export default new EventService(new MrfiktivHttpClientProvider());
