import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { IThgMeterReading, ThgMeterReading } from "@/models/thg-meter-reading.entity";
import meterReadingAdminService from "@/services/thg/services/meterReadingAdminService";
import {
  ThgThgMeterReadingControllerFindAllParamsGen,
  ThgThgMeterReadingViewModelGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ThgMeterReadingDataAccessLayer } from "./access-layers/thg-meter-reading.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ThgMeterReadingPageDataProvider } from "./page-data-provider/thg-meter-reading.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "meterReadingPaginatedStore",
  store
})
export class MeterReadingPaginatedStore extends PaginatedBaseStore<
  IThgMeterReading,
  ThgThgMeterReadingControllerFindAllParamsGen
> {
  _data = ThgMeterReadingDataAccessLayer;
  _pageProvider = new ThgMeterReadingPageDataProvider();
  _pager = new PageDataHandler(this._data, this._pageProvider);

  private _selectedThg: IThgMeterReading = new ThgMeterReading();

  get selectedThg(): IThgMeterReading {
    return this._selectedThg;
  }

  filterOptions: PaginationFilterListElement[] = ThgMeterReading.filterables;

  @Action
  async getSelectedThgByPartnerID(data: { thgId: string; partnerId: string }): Promise<IThgMeterReading> {
    const selectedThg = await meterReadingAdminService.get(data.thgId);
    const thg = new ThgMeterReading(selectedThg);
    this._data.set(thg);
    this.context.commit("_mutateSelectedThg", thg);
    return thg;
  }

  @Mutation
  _mutateSelectedThg(thg: IThgMeterReading) {
    this._selectedThg = thg;
  }

  @Action
  replaceInList(thg: IThgMeterReading | ThgThgMeterReadingViewModelGen) {
    this._data.set(new ThgMeterReading(thg));
  }
}

export const MeterReadingPaginatedModule = getModule(MeterReadingPaginatedStore);
