








































import ErrorCard from "@/components/utility/ErrorCard.vue";
import { detailedDateWithDay, formatHoursAndMinutes } from "@/lib/utility/date-helper";
import {
  BookingBookingUnauthenticatedViewModelGen,
  BookingSlotViewModelGen
} from "@/services/booking/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import BookingCalendar from "@/components/booking/customer/BookingCalendar.vue";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { NotFoundException } from "@/lib/exceptions/http";
import { handleError } from "@/lib/utility/handleError";
import BookingSlots from "@/components/booking/customer/BookingSlots.vue";
import { BookingStepEnum } from "@/store/enum/bookingScreenEnum";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";
import BookingSuccess from "@/components/booking/customer/BookingSuccess.vue";

@Component({
  components: {
    ErrorCard,
    BookingCalendar,
    BookingSlots,
    BookingSuccess
  }
})
export default class BookingUpdate extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  @Prop({ default: false })
  isBookingUpdated!: boolean;

  @Prop()
  bookingId!: string;

  @Prop()
  partnerId!: string;

  @Prop()
  booking!: BookingBookingUnauthenticatedViewModelGen;

  @Prop()
  serviceName!: string;

  @Prop()
  serviceLocation!: string;

  @Prop({ default: false })
  bookingCancelSuccess!: boolean;

  bookingStepEnum = BookingStepEnum;

  get step() {
    return AvailabilityModule.currentStep;
  }

  set step(slot: BookingStepEnum) {
    AvailabilityModule.setCurrentStep(slot);
  }

  get image() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.bookingSuccess);
  }

  get selectedSlotDate() {
    if (this.booking) {
      return detailedDateWithDay(new Date(this.booking.start).toISOString(), this.$t("locale").toString());
    }

    return "";
  }

  get selectedSlotStartTime() {
    if (this.booking) {
      const from = formatHoursAndMinutes(new Date(this.booking.start));

      const to = formatHoursAndMinutes(new Date(this.booking.end));
      return this.$t("bookingForm.common.slot", { from, to });
    }

    return "";
  }

  async checkAvailability(date: Date) {
    AvailabilityModule.setIsLoadingSlots(true);

    try {
      await AvailabilityModule.findOneByPartner({
        partnerId: this.partnerId,
        id: AvailabilityModule.serviceId || this.booking.serviceId,
        date: date?.toISOString() || new Date().toISOString()
      });
    } catch (error) {
      if (error instanceof NotFoundException) {
        Vue.$toast.error(String(this.$t("bookingForm.BookingProcess.serviceNotFound")));
      } else {
        handleError(error);
      }
    } finally {
      AvailabilityModule.setIsLoadingSlots(false);
    }
  }

  async onDateChange(date: Date) {
    /** Fetch availability when changing dates in the calendar */
    await this.checkAvailability(date);
  }

  onSlotSelect(selectedSlot: BookingSlotViewModelGen) {
    this.$emit("click", selectedSlot);
  }
}
