/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { SignDigitalSignatureViewModelGen, SignExceptionViewmodelGen } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class DigitalSignatureService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Digital Signature Service
   * @name SignControllerFindAll
   * @summary Get all signature
   * @request GET:/partner/{partnerId}/sign-request/{signatureRequestId}/sign
   * @secure
   * @response `200` `(SignDigitalSignatureViewModelGen)[]` The signature
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signControllerFindAll = (partnerId: string, signatureRequestId: string, params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureViewModelGen[], SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${signatureRequestId}/sign`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Service
   * @name SignControllerFindOne
   * @summary Get a signature
   * @request GET:/partner/{partnerId}/sign-request/{signatureRequestId}/sign/{signId}
   * @secure
   * @response `200` `SignDigitalSignatureViewModelGen` The signature
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signControllerFindOne = (partnerId: string, signatureRequestId: string, signId: string, params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${signatureRequestId}/sign/${signId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Service
   * @name SignControllerFindOneByDocument
   * @summary Get a signature for a document
   * @request GET:/partner/{partnerId}/document/{documentId}/sign
   * @secure
   * @response `200` `SignDigitalSignatureViewModelGen` The signature
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signControllerFindOneByDocument = (partnerId: string, documentId: string, params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/document/${documentId}/sign`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
