var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-with-banner', {
    attrs: {
      "isProgressDone": true,
      "showProgress": false,
      "showFooter": false,
      "showLoginButton": _vm.showLoginButton
    },
    scopedSlots: _vm._u([!_vm.isMobile && _vm.bookingProcessStep == _vm.bookingStepEnum.START ? {
      key: "banner",
      fn: function fn() {
        return [_c('v-img', {
          attrs: {
            "src": _vm.headerImage,
            "height": "550"
          }
        }, [_c('v-overlay', {
          attrs: {
            "value": "true",
            "absolute": ""
          }
        }, [_c('v-container', {
          staticClass: "mx-auto"
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('h2', {
          staticClass: "text-md-h2 text-sm-h3 text-h4 font-weight-medium mt-sm-8 mt-md-0"
        }, [_vm._v(" " + _vm._s(_vm.$t("bookingForm.BookingStart.introText")) + " ")])]), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('h4', {
          staticClass: "text-md-h4 text-sm-h5 text-h6 font-weight-medium mt-sm-8 mt-md-0"
        }, [_vm._v(" " + _vm._s(_vm.$t("bookingForm.BookingStart.introSubText")) + " ")])])], 1)], 1)], 1)], 1), _c('booking-start-how-to-book')];
      },
      proxy: true
    } : null, {
      key: "content",
      fn: function fn() {
        return [_c('booking-process', {
          attrs: {
            "flat": ""
          }
        })];
      },
      proxy: true
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }