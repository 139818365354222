


















import { DateVuetifyHelper, formatYearsMonthDay } from "@/lib/utility/date-helper";
import { CustomFieldCreateDtoHelper } from "@/store/modules/custom-field.store";
import { Component, Prop } from "vue-property-decorator";
import CustomFieldText from "./CustomFieldText.vue";

@Component({
  components: {}
})
export default class CustomFieldDate extends CustomFieldText {
  @Prop()
  customField!: CustomFieldCreateDtoHelper;

  get parsingDate() {
    const value = this.input;
    if (!value) {
      return "";
    }

    return formatYearsMonthDay(new Date(value));
  }

  set parsingDate(date: string) {
    if (!date) {
      return;
    }

    const newDate = new DateVuetifyHelper(date);

    this.input = newDate.isoDate;
  }

  clear() {
    this.input = "";
  }
}
