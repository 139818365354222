import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Event } from "../v1/Event";
import {
  MrfiktivCreateBaseEventDtoGen,
  MrfiktivCreateEventDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivVehicleEventControllerGetParamsGen,
  MrfiktivVehicleEventControllerListParamsGen
} from "../v1/data-contracts";
import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";

/**
 * Communicate with event API
 */
class VehicleEventService {
  mrfiktivProxy: Event;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Event(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an event
   */
  async create(partnerId: string, vehicleId: string, data: MrfiktivCreateEventDtoGen) {
    const event = (await this.mrfiktivProxy.vehicleEventControllerCreate(partnerId, vehicleId, data)).data;

    return new EventUIDto(event);
  }

  /**
   * Get all event
   */
  async get(
    data: MrfiktivVehicleEventControllerGetParamsGen
  ): Promise<
    MrfiktivPageViewModelGen & {
      data?: IEventUIDto[] | undefined;
    }
  > {
    const events = (await this.mrfiktivProxy.vehicleEventControllerGet(data)).data;

    const mapped: MrfiktivPageViewModelGen & {
      data?: IEventUIDto[] | undefined;
    } = { ...events, data: events.data?.map(e => new EventUIDto(e)) };

    return mapped;
  }

  /**
   * Get all event
   */
  async listAll(data: MrfiktivVehicleEventControllerListParamsGen) {
    const events = (await this.mrfiktivProxy.vehicleEventControllerList(data)).data;

    return events.map(e => new EventUIDto(e));
  }

  /**
   * Get all event
   */
  async listOverdue(partnerId: string, vehicleId: string) {
    const events = (await this.mrfiktivProxy.vehicleEventControllerOverdue(partnerId, vehicleId)).data;

    return events.map(e => new EventUIDto(e));
  }

  /**
   * Get a event
   */
  async getOne(partnerId: string, vehicleId: string, eventId: string) {
    const event = (await this.mrfiktivProxy.vehicleEventControllerGetOne(partnerId, vehicleId, eventId)).data;

    return new EventUIDto(event);
  }

  /**
   * Get a from a virtual event
   */
  async update(
    partnerId: string,
    vehicleId: string,
    eventId: string,
    start: number,
    data: MrfiktivCreateBaseEventDtoGen
  ) {
    const event = (
      await this.mrfiktivProxy.vehicleEventControllerCreateVirtual(partnerId, vehicleId, eventId, start, data)
    ).data;

    return new EventUIDto(event);
  }

  /**
   * Delete a virtual event
   */
  async remove(partnerId: string, vehicleId: string, eventId: string, start: number) {
    const event = (await this.mrfiktivProxy.vehicleEventControllerDelete(partnerId, vehicleId, eventId, start)).data;

    return new EventUIDto(event);
  }
}

/**
 * Communicate with event API
 */
export default new VehicleEventService(new MrfiktivHttpClientProvider());
