import { authGuard } from "@/auth/authGuard";
import { permissionGuard } from "@/auth/permissionAuthGuard";
import BookingCancelView from "@/views/booking/BookingCancelView.vue";
import BookingDetailView from "@/views/booking/BookingDetailView.vue";
import BookingIFrame from "@/views/booking/BookingIFrame.vue";
import BookingLogin from "@/views/booking/BookingLogin.vue";
import BookingServiceAvailability from "@/views/booking/BookingServiceAvailability.vue";
import BookingUpdateView from "@/views/booking/BookingUpdateView.vue";
import Error from "@/views/Error.vue";
import NoRoles from "@/views/NoRoles.vue";
import PartnerPermissions from "@/views/portal/PartnerUnauthorized.vue";
import SignUp from "@/views/report/SignUp.vue";
import MyBookingView from "@/views/report/user/MyBookingView.vue";
import UserProfile from "@/views/UserProfile.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { BaseRoutes } from "./base.router";
import { getLoginRoutes } from "./login.router";
import { SettingRoutes } from "./settings.router";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/bookingiframe",
    name: "BookingIFrame",
    component: BookingIFrame
  },
  {
    path: "/booking",
    name: "BookingServiceAvailability",
    component: BookingServiceAvailability
  },
  {
    path: "/booking/:id",
    name: "BookingDetailView",
    component: BookingDetailView
  },
  {
    path: "/booking/:id/cancel",
    name: "BookingCancel",
    component: BookingCancelView
  },
  {
    path: "/booking/:id/update",
    name: "BookingUpdate",
    component: BookingUpdateView
  }
];

export const BookingRoutes = [...routes];

const appRoutes: RouteConfig[] = [
  {
    path: "/my/bookings",
    name: "MyBookingView",
    component: MyBookingView
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: PartnerPermissions
  },
  {
    path: "/no-roles",
    name: "NoRoles",
    component: NoRoles
  },
  {
    path: "/errors",
    name: "Error",
    component: Error
  }
];

SettingRoutes.forEach((route: RouteConfig) => {
  route.path = "/settings" + route.path;
  routes.push(route);
});

getLoginRoutes(BookingLogin).forEach((route: RouteConfig) => {
  route.path = "/login" + route.path;
  routes.push(route);
});

routes.push(
  {
    path: "*",
    name: "BookingHome",
    component: BookingServiceAvailability
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserProfile,
    beforeEnter: authGuard
  }
);

const router = new VueRouter({
  routes: [...routes, ...appRoutes, ...BaseRoutes],
  base: "/booking/",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(permissionGuard);

export default router;
