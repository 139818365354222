/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum ContactCustomerEnum {
  /**
   * Default
   */
  ALL = "all",

  /**
   * For respond to forms
   */
  FORM = "form",

  /**
   * For respond to reports
   */
  REPORT = "report",

  /**
   * For sending new reports to customer
   */
  INITIAL_REPORT = "initial_report",

  /**
   * For sending new reports to customer (if you are a Workshop)
   */
  INITIAL_REPORT_WORKSHOP = "initial_repprt_workshop",

  /**
   * For sending new reports to customer (if you are a fleet)
   */
  INITIAL_REPORT_FLEET = "initial_repprt_fleet"
}
