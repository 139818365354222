



































import BookingProcess from "@/components/booking/customer/BookingProcess.vue";
import BookingStart from "@/components/booking/customer/BookingStart.vue";
import BookingStartHowToBook from "@/components/booking/customer/BookingStartHowToBook.vue";
import Debug from "@/components/utility/Debug.vue";
import ErrorCard from "@/components/utility/ErrorCard.vue";
import LayoutReportWithBanner from "@/layouts/LayoutReportWithBanner.vue";
import { Component, Vue } from "vue-property-decorator";
import { PartnerModule } from "@/store/modules/partner";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { BookingStepEnum } from "@/store/enum/bookingScreenEnum";
import { UserModule } from "@/store/modules/me-user.store";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {
    LayoutReportWithBanner,
    BookingProcess,
    BookingStart,
    BookingStartHowToBook,
    Debug,
    ErrorCard
  }
})
export default class BookingServiceAvailability extends Vue {
  bookingStepEnum = BookingStepEnum;

  get showLoginButton(): boolean {
    const isCustomerSignup = PartnerModule.partner?.settings?.bookedServices.customer ?? false;
    const isThg = ConfigModule.appContext === AppContextEnum.THG;
    return isThg ? !UserModule.isAuthenticated : !UserModule.isAuthenticated && isCustomerSignup;
  }

  get headerImage() {
    if (PartnerModule?.partner?.settings?.reportSettings?.headerImageUrl) {
      return PartnerModule?.partner?.settings?.reportSettings?.headerImageUrl;
    } else if (PartnerModule?.partner?.settings?.headerImageUrl) {
      return PartnerModule?.partner?.settings?.headerImageUrl;
    } else {
      return "https://static.mmmint.ai/wirkaufendeinethg/header-image.png";
    }
  }

  get bookingProcessStep() {
    return AvailabilityModule.currentStep;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
}
