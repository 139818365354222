export function downloadFile(filename: string, text: string) {
  const element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

/**
 * Modern browsers cannot download files that aren't from same origin
 * @deprecated Use only when you want to downlad a file locatet in the same origin
 * @param url Remote URL for the file to be downloaded
 * @param name name of the downloadfile
 */
export function downloadUrl(path: string, filename: string) {
  // Create a new link
  const anchor = document.createElement("a");
  anchor.href = path;
  anchor.download = filename;
  anchor.target = "_blank";

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
}

/**
 * Modern browsers cannot download files that aren't from same origin. This is a workaround to download a remote file.
 * @param url Remote URL for the file to be downloaded
 * @param name name of the downloadfile
 */
export async function downloadUrlFromRemoteOrigin(url: string, name: string) {
  let sucess = true;
  await fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const blobURL = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobURL;
      a.setAttribute("style", "display: none");

      if (name && name.length) a.download = name;
      document.body.appendChild(a);
      a.click();
    })
    .catch(() => (sucess = false));
  return sucess;
}

/**
 * Downloads a file using an anchor tag
 * @param file The file to download (must be a File object)
 * @param fileName Optional custom name for the downloaded file (defaults to the File object's name)
 */
export function downloadFileViaAnchor(file: File, fileName?: string): void {
  // Check if the input is a File object
  if (!(file instanceof File)) {
    throw new Error("Invalid input. Expected a File object.");
  }

  // Use the provided fileName or default to the File object's name
  const downloadName = fileName || file.name;

  // Create a blob URL from the File object
  const url = URL.createObjectURL(file);

  // Create a temporary anchor element
  const link = document.createElement("a");
  link.href = url;
  link.download = downloadName;

  // Append to the document, click it, and remove it
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Revoke the blob URL to free up memory
  URL.revokeObjectURL(url);
}
