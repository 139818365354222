










































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class BackAndForthFooter extends Vue {
  @Prop({ default: true })
  hasNext!: boolean;

  @Prop({ default: true })
  hasPrev!: boolean;

  @Prop({ default: false })
  forceNextBtn!: boolean;

  @Prop({ default: false })
  disableNext!: boolean;

  emitNext() {
    this.$emit("next", true);
  }
  emitPrev() {
    this.$emit("prev", true);
  }
}
