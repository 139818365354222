import { $t } from "@/lib/utility/t";

export function transformBooleanToText(bool: string) {
  if (bool === "true") {
    return $t("yes");
  } else if (bool === "false") {
    return $t("no");
  } else {
    return "";
  }
}
