













import { Component, Vue } from "vue-property-decorator";
import LoginCard from "@/components/login/LoginCard.vue";

@Component({
  components: { LoginCard }
})
export default class Login extends Vue {
  logo = "https://www.mrfiktiv.com/images/logo.png";
  title = "accessing smart solutions";
  text =
    "Jetzt einloggen, um Zugang zu Webapplikationen wie dem Partnerbackend zu erhalten und Schadensmeldungen zu verwalten.";
  onLoginFunction = () => {
    this.$router.push({ path: "/profile" });
  };
}
