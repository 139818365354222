import { MrfiktivCustomFieldViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CustomFieldEnum } from "@/store/modules/custom-field.store";
import { CustomFieldConfiguration, ICustomFieldConfiguration } from "./custom-field-configuration.entity";

class CustomFieldBase implements MrfiktivCustomFieldViewModelGen {
  key: string;
  id: string;
  partnerId: string;
  name: string;
  label: string;
  hint?: string | undefined;
  placeholder?: string | undefined;
  type: CustomFieldEnum;
  configuration?: ICustomFieldConfiguration | undefined;

  constructor(customField?: Partial<MrfiktivCustomFieldViewModelGen>) {
    this.key = customField?.key ?? "";
    this.id = customField?.id ?? "";
    this.partnerId = customField?.partnerId ?? "";
    this.name = customField?.name ?? "";
    this.label = customField?.label ?? "";
    this.hint = customField?.hint ?? "";
    this.placeholder = customField?.placeholder;
    this.type = (customField?.type as CustomFieldEnum) ?? CustomFieldEnum.TEXT;
    this.configuration = customField?.configuration
      ? new CustomFieldConfiguration(customField.configuration)
      : undefined;
  }
}

type ICustomField = CustomFieldBase;
const CustomField = CustomFieldBase;

export { ICustomField, CustomField };
