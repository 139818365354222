import * as XLSX from "xlsx";
import { flattenObject } from "./flatten-object";
import { IFormableClass, IMDetailFormConfig } from "./formable";
import { DetailFormComponentsEnum } from "./enum/detail-form-components.enum";
import { $t } from "./utility/t";
import { IExcelImportConfig } from "./interfaces/excel-import.interface";
import { IFilterableClass, PaginationFilterListElement } from "./filterable";

/**
 *
 * @param entity
 * @returns
 */
export function getTranslationsFromEntity(entity: IFormableClass | IFilterableClass) {
  const formables = (entity as IFormableClass).formables;
  if (formables?.length) {
    return getTranslationsFromFormable(formables);
  }

  const filterables = (entity as IFilterableClass).filterables;
  if (filterables?.length) {
    return getTranslationsFromFilterable(filterables);
  }

  return new Map();
}

export function getTranslationsFromFormable(formables: IMDetailFormConfig<any>[]) {
  return new Map(
    formables.map(formable => [formable.key, formable.props?.label ? $t(formable.props.label) : formable.key])
  );
}

export function getTranslationsFromFilterable(filterables: PaginationFilterListElement[]) {
  return new Map(
    filterables.map(formable => [formable.key, formable.displayName ? $t(formable.displayName) : formable.key])
  );
}

/**
 * something like vehicle.contract.0.some.stuff.2.yup will become $t(vehicle.contract.some.stuff) [0] [2]
 */
export function transformKeyToColumnName(key: string, translations: Map<string, string>) {
  // find all numbers in the key e.g. vehicle.contract.0.some.stuff.2.yup will return [0, 2]
  const matches = key.matchAll(RegExp(/\d/, "g"));
  // create a string with the indexes e.g. [0] [2]
  const indexes =
    Array.from(matches)
      .map(d => `[${d}]`)
      .join("") || undefined;
  // remove the index numbers
  const clearedString = key.replaceAll(RegExp(/.\d/, "g"), "");

  if (!translations.get(clearedString)) {
    return key;
  }

  const translatedClearedString = translations.get(clearedString);
  const translatedClearedStringWithIndex = [translatedClearedString, indexes].join(" ");
  return `${translatedClearedStringWithIndex} (${key})`;
}

/**
 * Create a list of flattened objects where each object represents a row in a csv export
 * If an object is missing a key that another object in the input possesses, the value will be an empty string
 *
 * @param docs
 * @param headerTranslations
 * @param sort should the keys(columns) be ordered alphabetically. Defaults to true
 * @returns
 */
export function createXlsxObject(docs: Record<string, any>[], headerTranslations?: Map<string, string>, sort = true) {
  const exportData: Record<string, string>[] = [];

  const listOfFlattenedElements = docs.map(element => flattenObject(element));
  let uniqueKeys: string[] = Array.from(new Set(listOfFlattenedElements.map(e => Object.keys(e)).flat()));

  if (sort) {
    uniqueKeys = uniqueKeys.sort((a: string, b: string) => (a < b ? -1 : 1));
  }

  let uniqueKeysTranslated = new Map(uniqueKeys.map(key => [key, key]));
  if (headerTranslations) {
    uniqueKeysTranslated = new Map(
      uniqueKeys.map(key => {
        const translated = transformKeyToColumnName(key, headerTranslations);

        return [key, translated];
      })
    );
  }

  for (const element of listOfFlattenedElements) {
    const exportDataPoint: Record<string, string> = {};
    for (const [key, translation] of uniqueKeysTranslated.entries()) {
      exportDataPoint[translation] = element[key] ?? "";
    }
    exportData.push(exportDataPoint);
  }

  return exportData;
}

/**
 * Download the given data as a xslx file
 * @see createXlsxObject
 *
 * @param docs
 * @param headerTranslations
 * @param sort
 */
export function downloadAsXlsx(docs: Record<string, any>[], headerTranslations?: Map<string, string>, sort?: boolean) {
  const exportData = createXlsxObject(docs, headerTranslations, sort);

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "exp");
  XLSX.writeFile(workbook, "exp_" + new Date().toISOString() + ".xlsx", { compression: true });
}

export function getExcelImportConfig(formConfig: IMDetailFormConfig[]) {
  let importConfig: { [key: string]: IExcelImportConfig } = {};
  const headerTranslations: Map<string, string> = new Map(
    formConfig.map(formConfigItem => [formConfigItem.key, $t(formConfigItem.props?.label) ?? formConfigItem.key])
  );

  for (const formConfigItem of formConfig) {
    const identifer = transformKeyToColumnName(formConfigItem.key, headerTranslations);

    const config: IExcelImportConfig = {
      label: identifer,
      hint: formConfigItem.props.label ? $t(formConfigItem.props.hint) : "",
      required: formConfigItem.props.required ?? false,
      import: formConfigItem.props.required ?? false,
      originColumnNameInExcelFile: identifer,
      transform: (value: string | number | boolean) => {
        if (formConfigItem.props.rules) {
          const fails: string[] = [];
          for (const rule of formConfigItem.props.rules) {
            const fail = rule(value);
            if (fail !== true) fails.push(fail);
          }
          if (fails.length) {
            throw new Error(`${identifer}: ${fails.join(", ")}`);
          }
        }

        if (
          [DetailFormComponentsEnum.SELECT_FIELD, DetailFormComponentsEnum.AUTO_COMPLETE].includes(
            formConfigItem.type
          ) &&
          formConfigItem.props.items
        ) {
          if (formConfigItem.props.items) {
            const found = formConfigItem.props.items.find((item: any) => item === value);
            if (found === undefined) {
              throw new Error(
                $t("sign.Sign.error.invalidType", {
                  type: identifer,
                  expectedType: Object.values(formConfigItem.props.items).join(` ${$t("or")} `),
                  receivedType: value
                })
              );
            }
          }
        }

        if (formConfigItem.props?.type) {
          if (formConfigItem.props.type === "date") {
            return new Date(value.toString()).toISOString();
          }

          if (formConfigItem.props.type === "number") {
            return Number(value.toString());
          }

          if (formConfigItem.props.type === "boolean") {
            return Boolean(value);
          }

          return value.toString();
        }

        return value.toString();
      }
    };

    importConfig = {
      ...importConfig,
      [formConfigItem.key]: config
    };
  }

  return importConfig;
}
