import { FilterTypes } from "@/lib/filterable";
import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ILocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.interface";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { $t } from "@/lib/utility/t";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import {
  MrfiktivCreatePartnerTemplateDtoGen,
  MrfiktivPartnerTemplateViewModelGen,
  MrfiktivRenderedTemplateViewModelGen,
  MrfiktivRenderTemplateDtoGen,
  MrfiktivUpdatePartnerTemplateDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import partnerTemplateService from "@/services/shared/partnerTemplateService";
import { ThgPartnerTemplateViewModelGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { PredefinedFilterType } from "@/views/event/filter/event.filter";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { BaseStore } from "../base.store";
import { PaginationFilterListElement } from "./base-pagination.store";

export const PartnerTemplateDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<
  MrfiktivPartnerTemplateViewModelGen
> {
  getIdentifier(entity: MrfiktivPartnerTemplateViewModelGen): string {
    return entity.key;
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "partner-template",
  store
})
export class PartnerTemplateStore extends BaseStore<MrfiktivPartnerTemplateViewModelGen> {
  protected _data: ILocalDataAccessLayer<MrfiktivPartnerTemplateViewModelGen, string> = PartnerTemplateDataAccessLayer;

  filterOptions: PaginationFilterListElement[] = [
    new PaginationFilterListElement({
      key: "isAdminTemplate",
      type: FilterTypes.BOOLEAN,
      displayName: "components.template.dialog.selection.isAdminTemplate"
    }),
    new PaginationFilterListElement({
      key: "content.subject",
      type: FilterTypes.STRING,
      displayName: "Name"
    }),
    new PaginationFilterListElement({
      key: "key",
      type: FilterTypes.STRING,
      displayName: "Key"
    })
  ];

  filters: IPageFilterElement[] = [
    new PageFilterElement({
      key: "isAdminTemplate",
      operation: PageFilterOperationEnum.EQUAL,
      type: FilterTypes.BOOLEAN,
      value: false as any
    })
  ];

  private _rendered: MrfiktivRenderedTemplateViewModelGen | undefined = undefined;
  private _partnerTemplate: MrfiktivPartnerTemplateViewModelGen | undefined = undefined;
  private _partnerTemplates: MrfiktivPartnerTemplateViewModelGen[] = [];

  get rendered(): MrfiktivRenderedTemplateViewModelGen | undefined {
    return this._rendered;
  }
  get partnerTemplate(): MrfiktivPartnerTemplateViewModelGen | undefined {
    return this._partnerTemplate;
  }
  get partnerTemplates(): MrfiktivPartnerTemplateViewModelGen[] {
    return this._partnerTemplates;
  }

  get predefinedFilter(): PredefinedFilterType[] {
    return [
      {
        name: $t("components.template.dialog.selection.isAdminTemplate"),
        filter: [
          new PageFilterElement({
            key: "isAdminTemplate",
            operation: PageFilterOperationEnum.EQUAL,
            type: FilterTypes.BOOLEAN,
            value: true as any
          })
        ]
      }
    ];
  }

  @Mutation
  private _mutateRendered(rendered: MrfiktivRenderedTemplateViewModelGen): void {
    this._rendered = rendered;
  }
  @Mutation
  private _mutatePartnerTemplate(_partnerTemplate: MrfiktivPartnerTemplateViewModelGen | undefined): void {
    this._partnerTemplate = _partnerTemplate;
  }
  @Mutation
  private _mutatePartnerTemplates(_partnerTemplates: MrfiktivPartnerTemplateViewModelGen[]): void {
    this._partnerTemplates = _partnerTemplates;
  }

  @Action
  async createTemplate(args: {
    partnerId: string;
    data: MrfiktivCreatePartnerTemplateDtoGen;
  }): Promise<MrfiktivCreatePartnerTemplateDtoGen> {
    const created = await partnerTemplateService.create(args.partnerId, args.data);
    this.context.commit("_mutatePartnerTemplate", created);

    if (created) {
      this._data.set(created);
    }

    return args.data;
  }

  @Action
  async deleteTemplate(args: { partnerId: string; key: string }): Promise<void> {
    const deleted = await partnerTemplateService.delete(args.partnerId, args.key);

    if (deleted) {
      this._data.delete(deleted);
    }
    this.context.commit("_mutatePartnerTemplate", deleted);
  }

  @Action
  async getAllTemplates(partnerId: string): Promise<void> {
    const all = await partnerTemplateService.getAll(partnerId);

    all?.forEach(e => this._data.set(e));

    const templates = all?.sort((a, b) => (a.timestamp.created > b.timestamp.created ? -1 : 1));
    this.context.commit("_mutatePartnerTemplates", templates);
  }

  @Action
  async getTemplateByKey(args: {
    partnerId: string;
    key: string;
  }): Promise<MrfiktivPartnerTemplateViewModelGen | ThgPartnerTemplateViewModelGen | undefined> {
    const template = await partnerTemplateService.getByKey(args.partnerId, args.key);

    if (template) {
      this._data.set(template);
    }
    this.context.commit("_mutatePartnerTemplate", template);
    return template;
  }

  @Action
  async renderTemplate(args: { partnerId: string; key: string; data: MrfiktivRenderTemplateDtoGen }): Promise<void> {
    const template = await partnerTemplateService.render(args.partnerId, args.key, args.data);
    this.context.commit("_mutatePartnerTemplate", template);
  }

  @Action
  replaceInList(data: MrfiktivPartnerTemplateViewModelGen): void {
    const templates = this.partnerTemplates;
    const index = templates.findIndex(t => t.key === data.key);
    if (index > -1) {
      templates.splice(index, 1, data);
    }
    this.context.commit("_mutatePartnerTemplates", templates);
  }

  @Action
  async updateTemplate(args: {
    partnerId: string;
    key: string;
    data: MrfiktivUpdatePartnerTemplateDtoGen;
  }): Promise<MrfiktivPartnerTemplateViewModelGen | ThgPartnerTemplateViewModelGen | undefined> {
    const template = await partnerTemplateService.update(args.partnerId, args.key, args.data);

    if (template) {
      this._data.set(template);
    }
    this.context.commit("_mutatePartnerTemplate", template);
    return template;
  }

  @Action
  clearPartnerTemplate() {
    this.context.commit("_mutatePartnerTemplate", undefined);
  }

  @Action
  clearPartnerTemplates() {
    this.context.commit("_mutatePartnerTemplates", []);
  }
}

export const PartnerTemplateModule = getModule(PartnerTemplateStore);
