/**
 * Display message if isStisfied is false
 *
 * @param isSatisfied is message hidden
 * @param message message to show when rule is not satisfied
 * @returns
 */
export function customRule(isSatisfied: boolean, message: string): (value: any) => boolean | string {
  return () => isSatisfied || message;
}
