




import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";

@Component({
  components: {
    TheNavigationLayout
  }
})
export default class TheOrganizationList extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  route(route: string) {
    this.$router.push({ name: route });
  }

  get title() {
    return String(this.$t("navigation.TheOrganizationList.header"));
  }

  get isEnabled() {
    return false;
  }

  get items() {
    return [];
  }
}
