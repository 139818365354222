/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateHandoverDtoGen,
  MrfiktivCreateHandoverReportDtoGen,
  MrfiktivCreateInspectionDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivHandoverControllerFindAllParamsGen,
  MrfiktivHandoverViewModelGen,
  MrfiktivInspectionViewModelGen,
  MrfiktivPageViewModelGen,
  MrfiktivReportViewModelGen,
  MrfiktivUpdateHandoverDtoGen,
  MrfiktivUpdateInspectionDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Handover<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Handover
   * @name HandoverControllerCreate
   * @summary (HANDOVER-CREATE) Create a handover for a partner
   * @request POST:/partner/{partnerId}/handover
   * @secure
   * @response `200` `MrfiktivHandoverViewModelGen` The created handover
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  handoverControllerCreate = (partnerId: string, data: MrfiktivCreateHandoverDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivHandoverViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name HandoverControllerFindAll
   * @summary (HANDOVER-READ) Get all handovers for a partner
   * @request GET:/partner/{partnerId}/handover
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivHandoverViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  handoverControllerFindAll = (
    { partnerId, ...query }: MrfiktivHandoverControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivHandoverViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/handover`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name HandoverControllerFindOne
   * @summary (HANDOVER-READ) Get a handover for a partner
   * @request GET:/partner/{partnerId}/handover/{handoverId}
   * @secure
   * @response `200` `MrfiktivHandoverViewModelGen` The handover
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  handoverControllerFindOne = (partnerId: string, handoverId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivHandoverViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name HandoverControllerUpdate
   * @summary (HANDOVER-UPDATE) Update a handover for a partner
   * @request PATCH:/partner/{partnerId}/handover/{handoverId}
   * @secure
   * @response `200` `MrfiktivHandoverViewModelGen` The handover
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  handoverControllerUpdate = (
    partnerId: string,
    handoverId: string,
    data: MrfiktivUpdateHandoverDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivHandoverViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name HandoverControllerRemove
   * @summary (HANDOVER-DELETE) Delete a handover for a partner
   * @request DELETE:/partner/{partnerId}/handover/{handoverId}
   * @secure
   * @response `200` `MrfiktivHandoverViewModelGen` The deleted handover
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  handoverControllerRemove = (partnerId: string, handoverId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivHandoverViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name InspectionControllerCreate
   * @summary (HANDOVER-CREATE) Create a inspection for a handover
   * @request POST:/partner/{partnerId}/handover/{handoverId}/inspection
   * @secure
   * @response `200` `MrfiktivInspectionViewModelGen` The created inspection
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  inspectionControllerCreate = (
    partnerId: string,
    handoverId: string,
    data: MrfiktivCreateInspectionDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivInspectionViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/inspection`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name InspectionControllerFindAll
   * @summary (HANDOVER-READ) Get all inspection for a handover
   * @request GET:/partner/{partnerId}/handover/{handoverId}/inspection
   * @secure
   * @response `200` `(MrfiktivInspectionViewModelGen)[]` The inspections of the handover
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  inspectionControllerFindAll = (partnerId: string, handoverId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivInspectionViewModelGen[], MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/inspection`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name InspectionControllerFindOne
   * @summary (HANDOVER-READ) Get a inspection for a handover
   * @request GET:/partner/{partnerId}/handover/{handoverId}/inspection/{inspectionId}
   * @secure
   * @response `200` `MrfiktivInspectionViewModelGen` The inspection
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  inspectionControllerFindOne = (
    partnerId: string,
    handoverId: string,
    inspectionId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivInspectionViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/inspection/${inspectionId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name InspectionControllerUpdate
   * @summary (HANDOVER-UPDATE) Update a inspection for a handover
   * @request PATCH:/partner/{partnerId}/handover/{handoverId}/inspection/{inspectionId}
   * @secure
   * @response `200` `MrfiktivInspectionViewModelGen` The inspection
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  inspectionControllerUpdate = (
    partnerId: string,
    handoverId: string,
    inspectionId: string,
    data: MrfiktivUpdateInspectionDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivInspectionViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/inspection/${inspectionId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name InspectionControllerRemove
   * @summary (HANDOVER-DELETE) Delete a inspection for a handover
   * @request DELETE:/partner/{partnerId}/handover/{handoverId}/inspection/{inspectionId}
   * @secure
   * @response `200` `MrfiktivInspectionViewModelGen` The deleted inspection
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  inspectionControllerRemove = (
    partnerId: string,
    handoverId: string,
    inspectionId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivInspectionViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/inspection/${inspectionId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Handover
   * @name HandoverReportControllerCreateReport
   * @summary (HANDOVER&REPORT-CREATE) Create a report for an inspection
   * @request POST:/partner/{partnerId}/handover/{handoverId}/inspection/{inspectionId}/report
   * @secure
   * @response `200` `MrfiktivReportViewModelGen` The created report
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  handoverReportControllerCreateReport = (
    partnerId: string,
    handoverId: string,
    inspectionId: string,
    data: MrfiktivCreateHandoverReportDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivReportViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/handover/${handoverId}/inspection/${inspectionId}/report`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
