














import { Component, Vue } from "vue-property-decorator";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { UserModule } from "@/store/modules/me-user.store";

@Component
export default class TheUserList extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get items() {
    return [
      {
        icon: "mdi-account-outline",
        text: "Profil",
        link: "/profile"
      }
    ];
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }
}
