/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ThgCreateThgMeterReadingDtoGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Vue } from "vue-property-decorator";
import { Action, Module, VuexModule, getModule } from "vuex-module-decorators";
import { UserModule } from "./me-user.store";
import { PartnerActiveOfferModule } from "./partner-active-config.store";
import { ThgCreateModule } from "./thg.create.store";
import { FeatureModule } from "./feature.store";

/**
 * This event signifies when one or more items is purchased by a user.
 *
 * @see https://support.google.com/analytics/answer/9267735?sjid=134519823542676487-EU#online-sales
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=134519823542676487-EU#purchase
 */
type GtagPurchase = {
  /**
   * Currency of the items associated with the event, in 3-letter ISO 4217 format.
   * If you set value then currency is required for revenue metrics to be computed accurately.
   *
   * @example "EUR"
   */
  currency: string;

  /**
   * The unique identifier of a transaction.
   *  The transaction_id parameter helps you avoid getting duplicate events for a purchase.
   *
   * @example T_12345
   */
  transaction_id: string;

  /**
   * The monetary value of the event.
   * value is required for meaningful reporting and to populate the purchasers predictive audience.
   *
   * currency is required if you set value.
   * @example "12.21"
   */
  value: number;

  /**
   * The coupon name/code associated with the event.
   * Event-level and item-level coupon parameters are independent.
   *
   * @example "SUMMER_FUN"
   */
  coupon?: string;

  /**
   * Shipping cost associated with a transaction.
   *
   * @example 3.33
   */
  shipping?: number;

  /**
   * Tax cost associated with a transaction.
   * @example 1.11
   */
  tax?: number;

  /**
   * 	The items for the event.
   */
  items: GtagEcommerceItem[];
};

/**
 * Items for ecommerce
 */
type GtagEcommerceItem = {
  /**
   * The ID of the item.
   * One of item_id or item_name is required.
   *
   * @example "SKU_12345"
   */
  item_id?: string;

  /**
   * The name of the item.
   * One of item_id or item_name is required.
   *
   * @example "Stan and Friends Tee"
   */
  item_name: string;

  /**
   * 	A product affiliation to designate a supplying company or brick and mortar store location.
   * Note: `affiliation` is only available at the item-scope.
   *
   * @example "Google Store"
   */
  affiliation?: string;

  /**
   * The coupon name/code associated with the item.
   * Event-level and item-level coupon parameters are independent.
   *
   * @example SUMMER_FUN
   */
  coupon?: string;

  /**
   * The monetary discount value associated with the item.
   *
   * @example 	2.22
   */
  discount?: number;

  /**
   * 	The index/position of the item in a list.
   *
   * @example 5
   */
  index?: number;

  /**
   * The brand of the item.
   *
   * @example "Google"
   */
  item_brand?: string;

  /**
   * The category of the item. If used as part of a category hierarchy or taxonomy then this will be the first category.
   *
   * @example "Apparel"
   */
  item_category?: string;

  /**
   * 	The second category hierarchy or additional taxonomy for the item.
   *
   * @example "Adult"
   */
  item_category2?: string;

  /**
   * The third category hierarchy or additional taxonomy for the item.
   *
   * @example "Shirts"
   */
  item_category3?: string;

  /**
   * The fourth category hierarchy or additional taxonomy for the item.
   *
   * @example "Crew"
   */
  item_category4?: string;

  /**
   * The fifth category hierarchy or additional taxonomy for the item.
   *
   * @example "Short sleeve"
   */
  item_category5?: string;

  /**
   * The ID of the list in which the item was presented to the user.
   * If set, event-level item_list_id is ignored.
   * If not set, event-level item_list_id is used, if present.
   *
   * @example "related_products"
   */
  item_list_id?: string;

  /**
   * The name of the list in which the item was presented to the user.
   * If set, event-level item_list_name is ignored.
   * If not set, event-level item_list_name is used, if present.
   *
   * @example "Related products"
   */
  item_list_name?: string;

  /**
   * The item variant or unique code or description for additional item details/options.
   *
   * @example "green"
   */
  item_variant?: string;

  /**
   * The physical location associated with the item (e.g. the physical store location). It's recommended to use the Google Place ID that corresponds to the associated item. A custom location ID can also be used.
   * Note: `location id` is only available at the item-scope.
   *
   * @example "ChIJIQBpAG2ahYAR_6128GcTUEo (the Google Place ID for San Francisco)"
   */
  location_id?: string;

  /**
   * 	The monetary price of the item, in units of the specified currency parameter.
   *
   * @example 9.99
   */
  price?: number;

  /**
   * Item quantity.
   * If not set, quantity is set to 1.
   *
   * @example 1
   */
  quantity: number;
};

/**
 * Maps thg DTOs to gtag events
 */
class ThgToGtagMapper {
  static readonly VALUE = 15;
  static readonly CURRENCY = "EUR";
  static readonly VEHICLE = "vehicle";
  static readonly METER_READING = "meter_reading";
  static readonly NAME = "MWh";

  /**
   * Creates the gtag purchase event spec
   * @param items the items created via `mapVehicleToItems` or `mapMeterReadingToItems`
   * @returns the gtag purchase event spec
   */
  static map(items: GtagEcommerceItem[]): GtagPurchase {
    return {
      currency: ThgToGtagMapper.CURRENCY,
      value: ThgToGtagMapper.VALUE,
      transaction_id: ThgCreateModule.transactionId,
      coupon: ThgCreateModule.code,
      items: items
    };
  }

  /**
   * Maps a thg meter reading to the expected google item spec
   * @returns the gtag item
   */
  static mapVehicleToItems(): GtagEcommerceItem[] {
    const items = ThgCreateModule.years.map(year => {
      const priceConfig = PartnerActiveOfferModule.priceConfig.get({
        year: year,
        vehicleClass: ThgCreateModule.vehicleClass || ThgVehicleTypes.M1
      });

      return {
        item_name: ThgToGtagMapper.NAME,
        item_variant: ThgToGtagMapper.VEHICLE,
        affiliation: ThgCreateModule.code || "",
        item_category: `${priceConfig.year}`,
        item_category2: ReportModule.partner.countryCode || CountryCodeEnum.germany,
        item_category3: ThgCreateModule.vehicleClass || ThgVehicleTypes.M1,
        price: priceConfig.totalAmount / priceConfig.mwh,
        quantity: priceConfig.mwh
      };
    });

    return items;
  }

  /**
   * Maps a thg meter reading to the expected google item spec
   * @param thg the meter reading to be mapped to the Gtag spec
   * @returns the Gtag Item
   */
  static mapMeterReadingToItems(thg: ThgCreateThgMeterReadingDtoGen): GtagEcommerceItem[] {
    // Get the price based on M1 vehicle
    const priceConfig = PartnerActiveOfferModule.priceConfig.get({
      year: new Date(thg.meterReading.startDate).getFullYear(),
      vehicleClass: ThgVehicleTypes.M1
    });

    return [
      {
        item_name: ThgToGtagMapper.NAME,
        item_variant: ThgToGtagMapper.METER_READING,
        affiliation: ThgCreateModule.code || "",
        item_category: `${priceConfig.year}`,
        item_category2: ReportModule.partner.countryCode || CountryCodeEnum.germany,
        price: priceConfig.totalAmount / priceConfig.mwh,
        quantity: thg.meterReading.amountInKwh / 1000
      }
    ];
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "gtag",
  store
})
export class GtagStore extends VuexModule {
  @Action
  dispatchSelectContentEvent() {
    Vue.$log.debug("select_content");
    Vue.$gtag.set({ transaction_id: ThgCreateModule.transactionId });

    Vue.$gtag.event("select_content");
  }

  @Action
  dispatchAddToCart() {
    Vue.$log.debug("add_to_cart");
    try {
      const cart = ThgToGtagMapper.map(ThgToGtagMapper.mapVehicleToItems());

      Vue.$gtag.event("add_to_cart", { ...cart });
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Action
  dispatchAddShippingInfo() {
    Vue.$log.debug("add_shipping_info");

    try {
      const cart = ThgToGtagMapper.map(ThgToGtagMapper.mapVehicleToItems());

      Vue.$gtag.event("add_shipping_info", { ...cart });
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Action
  dispatchAddPaymentInfo() {
    Vue.$log.debug("add_payment_info");

    try {
      const cart = ThgToGtagMapper.map(ThgToGtagMapper.mapVehicleToItems());

      Vue.$gtag.event("add_payment_info", { ...cart });
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=14639158863275344665-EU&client_type=gtag#view_item
   */
  @Action
  dispatchViewItemEvent() {
    Vue.$log.debug("view_item");

    try {
      const cart = ThgToGtagMapper.map(ThgToGtagMapper.mapVehicleToItems());

      Vue.$gtag.event("view_item", { ...cart });
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  /**
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=134519823542676487-EU&client_type=gtag#begin_checkout
   */
  @Action
  dispatchBeginCheckoutEvent() {
    Vue.$log.debug("begin_checkout");

    try {
      const cart = ThgToGtagMapper.map(ThgToGtagMapper.mapVehicleToItems());

      Vue.$gtag.event("begin_checkout", { ...cart });
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Action
  dispatchSignupEvent() {
    Vue.$log.debug("sign_up");

    Vue.$gtag.event("sign_up");
  }

  /**
   * Here we track the purchase event.
   * For details and an explanation @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=134519823542676487-EU&client_type=gtag#purchase
   */
  @Action
  dispatchPurchaseEventVehicle() {
    Vue.$log.debug("purchase");

    try {
      const purchase = ThgToGtagMapper.map(ThgToGtagMapper.mapVehicleToItems());

      Vue.$log.debug(purchase);

      Vue.$gtag.event("purchase", {
        ...purchase
      });
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  /**
   * Here we track the purchase event.
   * For details and an explanation @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=134519823542676487-EU&client_type=gtag#purchase
   */
  @Action
  dispatchPurchaseEventMeterReading(data: { thg: ThgCreateThgMeterReadingDtoGen }) {
    Vue.$log.debug("purchase");

    try {
      const purchase = ThgToGtagMapper.map(ThgToGtagMapper.mapMeterReadingToItems(data.thg));

      Vue.$log.debug(purchase);

      Vue.$gtag.event("purchase", {
        ...purchase
      });
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Action
  consent() {
    Vue.$log.debug("consent");

    try {
      // Consent is a new type which is not part of vue-gtag but necessary for google consent mode v2
      Vue.$gtag.query("consent" as any, "update", {
        ad_storage: "granted",
        ad_user_data: "granted",
        ad_personalization: "granted",
        analytics_storage: "granted"
      });
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Action
  async setUserData() {
    Vue.$log.debug("ec user_data");

    try {
      const email = UserModule.user.userName ?? ThgCreateModule.contact.email;

      /**
       * @see https://support.google.com/google-ads/answer/13262500?hl=en#Code_snippet&zippy=%2Cidentify-and-define-your-enhanced-conversions-variables
       * For normalization:
       * - Remove leading or trailing whitespaces.
       * - Convert the text to lowercase.
       * - Format phone numbers according to the E.164 standard.
       */
      const normalizedEmail = email?.trim().toLocaleLowerCase();

      if (normalizedEmail) {
        if (FeatureModule.isGtmHashEmail) {
          const sha256 = async (message: string) => {
            // encode as UTF-8
            const msgBuffer = new TextEncoder().encode(message);

            // hash the message
            const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

            // convert ArrayBuffer to Array
            const hashArray = Array.from(new Uint8Array(hashBuffer));

            // convert bytes to hex string
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");

            return hashHex;
          };

          const sha256EmailAddress = await sha256(normalizedEmail);

          // adding the email to global window scope in order to access it via google ads tag via global javascript variable  ¯\_(ツ)_/¯
          (window as any).gtm_sha256_email_address = sha256EmailAddress;

          Vue.$gtag.set({
            user_data: {
              sha256_email_address: sha256EmailAddress
            }
          });
        } else {
          (window as any).gtm_email_address = normalizedEmail;

          Vue.$gtag.set({
            user_data: {
              email: normalizedEmail
            }
          });
        }
      }
    } catch (error) {
      Vue.$log.error(error);
    }
  }
}

export const GtagModule = getModule(GtagStore);
