












import FileDownload from "@/components/utility/FileDownload.vue";
import { IDownloadFile } from "@/components/utility/interface/download-file.interface";
import SideCard from "@/components/utility/SideCard.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";

@Component({
  components: {
    SideCard,
    FileDownload
  }
})
export default class DocumentTemplateDetailDownload extends mixins(DocumentDetailMixin) {
  @Prop()
  document!: ISignDocument;

  @Prop({ default: false })
  listItem!: boolean;

  loading = false;

  get downloadFiles(): IDownloadFile[] {
    return [
      {
        filename: this.document.name,
        url: this.document.url
      }
    ];
  }

  async mounted() {
    this.loading = true;
    await (this.$refs.downloader as FileDownload).download();
    this.loading = false;
    this.$emit("close");
  }
}
