








import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { CustomFieldEnum, customFieldIconTypeMap } from "@/store/modules/custom-field.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";

@Component({
  components: { Tooltip },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class CustomFieldDetailIcon extends Vue {
  @Prop()
  type!: CustomFieldEnum;

  get icon() {
    return customFieldIconTypeMap.get(this.type);
  }

  get text() {
    return this.$t(`views.CustomFieldList.types.${this.type}`);
  }
}
