/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IAddress } from "@/models/User/IAddress";
import { MrfiktivAddressWithGeoGen } from "@/services/mrfiktiv/v1/data-contracts";

/**
 * @inheritdoc
 */
export class CreateAddressDto implements MrfiktivAddressWithGeoGen {
  /**
   * @inheritdoc
   */
  state = "";
  /**
   * @inheritdoc
   */
  street = "";
  /**
   * @inheritdoc
   */
  zip = "";
  /**
   * @inheritdoc
   */
  city = "";
  /**
   * @inheritdoc
   */
  countryCode?: string = CountryCodeEnum.germany;

  /**
   * @inheritdoc
   */
  geo = {
    lat: 0,
    lng: 0
  };

  constructor(address?: MrfiktivAddressWithGeoGen) {
    if (address) {
      this.state = address.state;
      this.street = address.street;
      this.zip = address.zip;
      this.city = address.city;
      this.countryCode = address.countryCode;
      this.geo = address.geo;
    }
  }
}

/**
 * @inheritdoc
 */
export class Address implements IAddress {
  /**
   * @inheritdoc
   */
  state = "";
  /**
   * @inheritdoc
   */
  street = "";
  /**
   * @inheritdoc
   */
  zip = "";
  /**
   * @inheritdoc
   */
  city = "";
  /**
   * @inheritdoc
   */
  countryCode: string = CountryCodeEnum.germany;

  constructor(address?: IAddress) {
    if (address) {
      this.state = address.state;
      this.street = address.street;
      this.zip = address.zip;
      this.city = address.city;
      this.countryCode = address.countryCode as CountryCodeEnum;
    }
  }
}
