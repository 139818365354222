import { NotificationModule } from "@/store/modules/notification.store";
import { CustomerAccountMeModule } from "@/store/modules/customer-account-me.store";
import { NotificationFactory } from "./NotificationFactory";
import { UserModule } from "@/store/modules/me-user.store";
import Vue from "vue";

export class ReportNotificationHandler {
  static async check() {
    NotificationModule.clear();

    /** Get current account and check if notifications are accepted for the partner. */
    if (UserModule.isAuthenticated) {
      if (!CustomerAccountMeModule.currentAccount || Object.keys(CustomerAccountMeModule.currentAccount).length === 0) {
        try {
          await CustomerAccountMeModule.create({});
        } catch (error) {
          Vue.$log.error(error);
        }
      }

      if (!CustomerAccountMeModule.currentAccount?.isMarketingOptIn) {
        NotificationFactory.createCustomerAccountNotificationsMissingNotification();
      }
    }
  }
}
