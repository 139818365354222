var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isNavigationEnabled ? _c('div', {
    staticClass: "navigation"
  }, [_vm.title ? _c('v-subheader', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._l(_vm.items, function (item, i) {
    return _c('div', {
      key: i
    }, [_vm.isUserPermittedToSee(item) && item.isEnabled ? _c('v-list-item', {
      attrs: {
        "data-test-menu": item.text,
        "href": item.target ? item.link : undefined,
        "to": item.target ? undefined : item.link,
        "target": item.target
      }
    }, [_c('v-list-item-icon', [item.badge ? _c('v-badge', {
      attrs: {
        "content": item.badge,
        "color": "green",
        "overlap": ""
      }
    }, [_c('v-icon', {
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1) : _c('v-icon', {
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(_vm.$t(item.text))
      }
    })], 1)], 1) : _vm._e()], 1);
  })], 2) : _c('div');
}
var staticRenderFns = []

export { render, staticRenderFns }