/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { PartnerActiveOfferService as PartnerActiveOffer } from "../v1/PartnerActiveOfferService";
import { ThgPartnerActiveOfferViewModelGen } from "../v1/data-contracts";

/**
 * Service to check the active price and promotion config
 */
export class PartnerActiveOfferService {
  private readonly partnerActiveOffer: PartnerActiveOffer;

  constructor(clientProvider: ThgHttpClientProvider) {
    this.partnerActiveOffer = new PartnerActiveOffer(clientProvider.client());
  }

  /**
   * Gets all promotions and prices that are active for a particular partner
   * @returns
   */
  async findActive(partnerId: string): Promise<ThgPartnerActiveOfferViewModelGen> {
    return (await this.partnerActiveOffer.partnerActiveOfferControllerFindActive(partnerId)).data;
  }
}

export default new PartnerActiveOfferService(new ThgHttpClientProvider());
