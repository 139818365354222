











































import { Component, Vue, Prop } from "vue-property-decorator";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({ components: { ConfirmActionDialog } })
export default class FileUpload extends Vue {
  @Prop()
  public item!: IImageUploaded;

  dialogImage = "";
  dialog = false;

  fileToUrl(imageUploaded: IImageUploaded) {
    return URL.createObjectURL(imageUploaded.file);
  }

  openDialog() {
    this.dialog = true;
    this.dialogImage = this.fileToUrl(this.item);
  }

  deleteImage() {
    this.$emit("deleted", this.item.file);
    this.dialog = false;
    this.dialogImage = "";
  }
}
