import { IContactCustomer } from "@/components/utility/interface/contact-customer.interface";
import { ContactCustomerEnum } from "@/lib/enum/ContactCustomer.enum";
import { ICustomerContactTemplatesDto } from "@/store/interface/customer.contact.dto.interface";

/**
 * Communicates with docphant backend
 */
class CustomerContactService {
  ROUTE = "customercontact";
  TEMPLATES: IContactCustomer[] = [
    {
      id: "0",
      type: "Individuell",
      emailTitle: "",
      resource: ContactCustomerEnum.FORM,
      emailBody: "Hallo {customer},\n\n"
    },
    {
      id: "1",
      type: "Anfrage Landingpage",
      resource: ContactCustomerEnum.FORM,
      emailTitle: "Ihre Smart Repair Anfrage vom {date} bei {partnerName}",
      emailBody:
        "Hallo {customer},\n\nVielen Dank für Ihre Smart Repair Anfrage auf unserer Landingpage.\nWir haben uns die übermittelten Fotos angesehen und Ihnen ein persönliches Angebot erstellt, dieses finden Sie im Anhang dieser E-Mail.\n\nGerne möchten wir mit Ihnen nun einen Reparaturtermin vereinbaren. Sollten Sie Fragen zu dem Smart Repair Angebot oder einem unserer weiteren Services haben, sprechen Sie mich gerne an."
    },
    {
      id: "2",
      type: "Individuell",
      resource: ContactCustomerEnum.REPORT,
      emailTitle: "",
      emailBody: "Hallo {customer},\n\n"
    },
    {
      id: "3",
      type: "Antwort Schadensmeldung",
      resource: ContactCustomerEnum.REPORT,
      emailTitle: "Ihre digitale Schadensmeldung vom {date} bei {partnerName}",
      emailBody:
        "Hallo {customer},\n\nVielen Dank, dass Sie unseren Service der digitalen Schadensmeldung in Anspruch genommen haben.\n\nWir haben uns die übermittelten Fotos angesehen und Ihnen ein persönliches Angebot erstellt, welches Sie im Anhang dieser E-Mail finden.\n\nIhren Wunschtermin am {datePreference} möchten wir Ihnen hiermit gerne bestätigen. Bitte lassen Sie uns die unterschriebene Auftragsbestätigung für Ihr Fahrzeug {numberplate} zukommen. \n\nSollten Sie Fragen zu Ihrem Auftrag oder einem unserer weiteren Services haben, sprechen Sie mich gerne an.\n\n"
    },
    {
      id: "4",
      type: "Besichtigung notwendig",
      resource: ContactCustomerEnum.REPORT,
      emailTitle: "Ihre digitale Schadensmeldung vom {date} bei {partnerName}",
      emailBody:
        "Hallo {customer},\n\nVielen Dank, dass Sie unseren Service der digitalen Schadensmeldung in Anspruch genommen haben.\n\nLeider mussten wir feststellen, dass die übermittelten Bilder bei dem vorliegenden Schadenbild nicht abschließend ausreichen, um eine aussagekräftige Kalkulation erstellen zu können.\n\nGerne möchten wir mit Ihnen daher nun eine persönliche Besichtigung bei uns vor Ort vereinbaren. Zur Terminvereinbarung der Besichtigung können Sie gerne einfach auf diese E-Mail antworten oder uns telefonisch unter der {myPhone} kontaktieren. Bei Bedarf setzen wir uns auch gerne mit Ihnen telefonisch in Verbindung.\n\nFür eine persönliche Besichtigung des Schadens stehen wir Ihnen zu unseren Öffnungszeiten gerne zur Verfügung:\n\nMontag: {openingMonday}\nDienstag: {openingTuesday}\nMittwoch: {openingWednesday}\nDonnerstag: {openingThursday}\nFreitag: {openingFriday}\nSamstag: {openingSaturday}\n"
    },
    {
      id: "5",
      type: "Schadensmeldung versenden (Werkstatt)",
      resource: ContactCustomerEnum.INITIAL_REPORT_WORKSHOP,
      emailTitle: "Jetzt den Schaden an Ihrem Fahrzeug {numberplate} digital bei {partnerName} melden!",
      emailBody:
        "Hallo {customer},\n\nwir sind Ihre Werkstatt des Vertrauens und gerne zuständig für die sorgenfreie Abwicklung des Schadens an Ihrem Fahrzeug mit dem Kennzeichen {numberplate}.\n\nÜber den nachfolgenden Link können Sie bequem von zu Hause oder auch unterwegs den Schaden an Ihrem Fahrzeug melden. Durch den geführten Prozess werden Sie angeleitet, wie Sie den Schaden korrekt und einfach aufnehmen können. Die Erfassung können Sie von jedem Endgerät, egal ob Mobiltelefon, Tablet oder Computer vornehmen.\n\nLink zur digitalen Schadensmeldung von {partnerName}: {reportUrl}\n\nNachdem wir die Bilder erhalten haben, werden wir uns schnellstmöglich mit Ihnen zu einer Terminvereinbarung in Verbindung setzen. Sollten Sie Fragen haben, stehen wir Ihnen selbstverständlich jederzeit persönlich zur Verfügung.\n"
    },
    {
      id: "6",
      type: "Schadensmeldung versenden (Flotte)",
      resource: ContactCustomerEnum.INITIAL_REPORT_FLEET,
      emailTitle: "Jetzt den Schaden an Ihrem Fahrzeug {numberplate} digital melden!",
      emailBody:
        "Hallo {customer},\n\ndamit wir den Schadensumfang an Ihrem Fahrzeug {numberplate} besser bewerten können, möchten wir Sie bitten über den nachfolgenden Link den entstandenen Schaden zu erfassen.\n\nDurch den geführten Prozess werden Sie angeleitet, wie Sie den Schaden korrekt und einfach aufnehmen können. Die Erfassung können Sie von jedem Endgerät, egal ob Mobiltelefon, Tablet oder Computer vornehmen.\n\nLink zur digitalen Schadensmeldung: {reportUrl}\n\nNachdem wir die Bilder erhalten haben, werden wir uns schnellstmöglich mit Ihnen in Verbindung setzen. Sollten Sie in der Zwischenzeit Fragen haben, stehen wir Ihnen selbstverständlich jederzeit persönlich zur Verfügung."
    },
    {
      id: "6",
      type: "Schadensmeldung versenden (Werkstatt)",
      resource: ContactCustomerEnum.INITIAL_REPORT,
      emailTitle: "Jetzt den Schaden an Ihrem Fahrzeug {numberplate} digital bei {partnerName} melden!",
      emailBody:
        "Hallo {customer},\n\nwir sind Ihre Werkstatt des Vertrauens und gerne zuständig für die sorgenfreie Abwicklung des Schadens an Ihrem Fahrzeug mit dem Kennzeichen {numberplate}.\n\nÜber den nachfolgenden Link können Sie bequem von zu Hause oder auch unterwegs den Schaden an Ihrem Fahrzeug melden. Durch den geführten Prozess werden Sie angeleitet, wie Sie den Schaden korrekt und einfach aufnehmen können. Die Erfassung können Sie von jedem Endgerät, egal ob Mobiltelefon, Tablet oder Computer vornehmen.\n\nLink zur digitalen Schadensmeldung von {partnerName}: {reportUrl}\n\nNachdem wir die Bilder erhalten haben, werden wir uns schnellstmöglich mit Ihnen zu einer Terminvereinbarung in Verbindung setzen. Sollten Sie Fragen haben, stehen wir Ihnen selbstverständlich jederzeit persönlich zur Verfügung.\n"
    },
    {
      id: "7",
      type: "Schadensmeldung versenden (Flotte)",
      resource: ContactCustomerEnum.INITIAL_REPORT,
      emailTitle: "Jetzt den Schaden an Ihrem Fahrzeug {numberplate} digital melden!",
      emailBody:
        "Hallo {customer},\n\ndamit wir den Schadensumfang an Ihrem Fahrzeug {numberplate} besser bewerten können, möchten wir Sie bitten über den nachfolgenden Link den entstandenen Schaden zu erfassen.\n\nDurch den geführten Prozess werden Sie angeleitet, wie Sie den Schaden korrekt und einfach aufnehmen können. Die Erfassung können Sie von jedem Endgerät, egal ob Mobiltelefon, Tablet oder Computer vornehmen.\n\nLink zur digitalen Schadensmeldung: {reportUrl}\n\nNachdem wir die Bilder erhalten haben, werden wir uns schnellstmöglich mit Ihnen in Verbindung setzen. Sollten Sie in der Zwischenzeit Fragen haben, stehen wir Ihnen selbstverständlich jederzeit persönlich zur Verfügung."
    }
  ];

  async getAll(dto: ICustomerContactTemplatesDto): Promise<IContactCustomer[]> {
    //TODO: The filterin is done bei DB later
    return this.TEMPLATES.filter(item => item.resource === dto.resource);
  }
}

export default new CustomerContactService();
