






























import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class CameraBottomSheet extends Vue {
  /**
   * Emit functions.
   */
  public static EMITS = ["camera-clicked", "library-clicked"];

  /**
   * Display the sheet.
   */
  private sheet = false;

  /**
   * Open the sheet
   */
  public async open() {
    this.sheet = true;
  }

  /**
   * Close the sheet
   */
  public async close() {
    this.sheet = false;
  }

  /**
   * Emit camera-clicked event.
   */
  emitCamera() {
    this.$log.debug("emitCamera");
    this.$emit(CameraBottomSheet.EMITS[0]);
  }

  /**
   * Emit library-clicked event.
   */
  emitLibrary() {
    this.$log.debug("emitLibrary");
    this.$emit(CameraBottomSheet.EMITS[1]);
  }
}
