








import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";

@Component({ components: { TheNavigationLayout } })
export default class TheThgAnalyticsList extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get items(): IMenuListItem[] {
    return [
      {
        icon: "mdi-chart-line",
        text: "navigation.TheThgAdminList.analytics",
        link: "/analytics",
        isEnabled: true
      }
    ];
  }
}
