var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-btn', {
    class: _vm.$vuetify.breakpoint.mdAndDown ? 'mr-n1 ml-n1' : 'pr-0 pl-0',
    attrs: {
      "color": "success",
      "text": "",
      "small": "",
      "icon": _vm.$vuetify.breakpoint.mdAndDown
    },
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [!_vm.$vuetify.breakpoint.mdAndDown ? _c('small', {
    staticClass: "mr-1"
  }, [_vm._v("template")]) : _vm._e(), !_vm.$vuetify.breakpoint.mdAndDown ? _c('v-icon', [_vm._v(" mdi-email-multiple-outline ")]) : _c('tooltip', {
    attrs: {
      "text": "Template"
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" mdi-email-multiple-outline ")])], 1)], 1), _c('confirm-action-dialog', {
    attrs: {
      "fullscreen": true,
      "isDialogActive": _vm.isDialogActive,
      "title": _vm.$t('components.template.dialog.selection.title'),
      "hideRight": true,
      "supressKeyboardActions": true,
      "selectedItems": _vm.selectedItems
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedItems = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      }
    }
  }, [_c('div', {
    staticStyle: {
      "height": "calc(100vh - 190px)",
      "overflow": "scroll"
    }
  }, [_vm.templates ? _c('table-wrapper', {
    attrs: {
      "allItems": _vm.templates,
      "headers": _vm.headers,
      "loading": _vm.loading
    },
    on: {
      "click:row": _vm.select
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "elevation": "0",
            "small": "",
            "color": "primary"
          },
          on: {
            "click": _vm.goToTemplates
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("nav.TemplateListPartnerView.title")) + " ")])];
      },
      proxy: true
    }, {
      key: "item.isAdminTemplate",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icon(item)))])], 1)];
      }
    }, {
      key: "item.meta.description",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.getDescription(item)) + " ")];
      }
    }, {
      key: "item.meta.categories",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.meta.categories.length ? _c('div', _vm._l(item.meta.categories, function (token) {
          return _c('v-chip', {
            key: token,
            staticClass: "mr-1 mt-1 mb-1",
            attrs: {
              "small": "",
              "color": "info"
            }
          }, [_vm._v(" " + _vm._s(token) + " ")]);
        }), 1) : _c('div')];
      }
    }, {
      key: "item.context",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm.getTemplateContext(item).available.length ? _c('div', [_c('tooltip', {
          attrs: {
            "text": _vm.$t('components.template.dialog.selection.availableContext')
          }
        }, _vm._l(_vm.getTemplateContext(item).available, function (cat) {
          return _c('v-chip', {
            key: cat + item.key,
            staticClass: "mr-1 mt-1 mb-1",
            attrs: {
              "small": "",
              "color": "success"
            }
          }, [_vm._v(" " + _vm._s(cat) + " ")]);
        }), 1)], 1) : _vm._e(), _vm.getTemplateContext(item).missing.length ? _c('div', [_c('tooltip', {
          attrs: {
            "text": _vm.$t('components.template.dialog.selection.unavailableContext')
          }
        }, _vm._l(_vm.getTemplateContext(item).missing, function (cat) {
          return _c('v-chip', {
            key: cat + item.key,
            staticClass: "mr-1 mt-1 mb-1",
            attrs: {
              "small": "",
              "color": "error"
            }
          }, [_vm._v(" " + _vm._s(cat) + " ")]);
        }), 1)], 1) : _vm._e()];
      }
    }], null, false, 2561241058)
  }) : _vm._e()], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }