























import { Component, Vue, Prop } from "vue-property-decorator";
import BookingSuccess from "./BookingSuccess.vue";
import { UserGoToHelper } from "@/lib/utility/user-go-to-helper";

@Component({
  components: {
    BookingSuccess
  }
})
export default class BookingDetail extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  @Prop()
  bookingId!: string;

  async goToUpdate() {
    await new UserGoToHelper(this.$router).goToBookingCustomerUpdate(this.bookingId);
  }

  async goToCancel() {
    await new UserGoToHelper(this.$router).goToBookingCustomerCancel(this.bookingId);
  }
}
