import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { VehicleAccessLayer } from "@/store/modules/access-layers/vehicle.access-layer";

@IsFilterable
class VehicleReferenceEntity implements MrfiktivReferenceGen {
  @FilterConfig({
    displayName: "common.nouns.vehicle",
    type: FilterTypes.OBJECT_ID,
    config: {
      itemCallback: () => VehicleAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-vehicle"
    }
  })
  refId: string;

  refType: BackendResourceEnum;

  constructor(sharedContent?: Partial<IVehicleReferenceEntity>) {
    this.refId = sharedContent?.refId || "";
    this.refType = sharedContent?.refType as BackendResourceEnum;
  }
}
type IVehicleReferenceEntity = VehicleReferenceEntity;
const VehicleReference = Filter.createForClass(VehicleReferenceEntity);

export { IVehicleReferenceEntity, VehicleReference };
