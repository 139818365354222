





































































































































































































































































































import { autoCompleteCountryCodes } from "@/lib/CountryCodeHelper";
import { emailRule, extendetPhoneRule, disallowUmlaut } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IUser } from "@/models/user.entity";
import { Component, Prop } from "vue-property-decorator";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ConfigModule } from "@/store/modules/config";

/**
 * Interface which regulates the visibility of the 4 sections in the profile
 * General(names, company name, type of user)
 * Company(tax number and deduction)
 * Contact(email, phone)
 * Address(full address)
 */
export interface ProfileFormFieldsDisplayOptions {
  isGeneralEnabled: boolean;
  isCompanyEnabled: boolean;
  isContactEnabled: boolean;
  isAddressEnabled: boolean;
}

@Component({
  components: {}
})
export default class ProfileFormFields extends DarkModeHighlightMixin {
  @Prop({ default: true })
  showUserNameField!: boolean;

  @Prop()
  user!: IUser;

  @Prop()
  disabled!: boolean;

  @Prop({ default: false })
  isResubmitAddress!: boolean;

  @Prop({ default: false })
  isResubmitTaxnumber!: boolean;

  @Prop({ default: false })
  isTaxnumberRequired!: boolean;

  @Prop({ default: true })
  isTaxDeductibleEnabled!: boolean;

  @Prop({ default: false })
  isResubmitAddressEnabled!: boolean;

  @Prop({ default: false })
  isAddressRequired!: boolean;

  @Prop({ default: false })
  isResubmitTaxnumberEnabled!: boolean;

  @Prop({ default: false })
  hideState!: boolean;

  @Prop({ default: false })
  hideCountry!: boolean;

  /**
   * Object to regulate which parts of the fields are shown(Sections)
   * We have 4 sections: General, Company, Contact and Address.
   * By default all are shown.
   */

  @Prop({
    default: () => ({ isGeneralEnabled: true, isCompanyEnabled: true, isContactEnabled: true, isAddressEnabled: true })
  })
  displayOptions!: ProfileFormFieldsDisplayOptions;

  get isTHGContext() {
    return ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG;
  }

  get localIsResubmitAddress() {
    return this.isResubmitAddress;
  }
  set localIsResubmitAddress(isResubmitAddress: boolean) {
    this.$emit("update:isResubmitAddress", isResubmitAddress);
  }

  get localIsResubmitTaxnumber() {
    return this.isResubmitTaxnumber;
  }
  set localIsResubmitTaxnumber(isResubmitTaxnumber: boolean) {
    this.$emit("update:isResubmitTaxnumber", isResubmitTaxnumber);
  }

  readonly countryCodes = autoCompleteCountryCodes;

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());
    rules.push(disallowUmlaut());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(extendetPhoneRule());

    return rules;
  }

  get addressRules() {
    return this.isAddressRequired ? [requiredRule()] : [];
  }

  /**
   * Should we require state. For now not at all.
   * Expose a input to regulate this functionality.
   */
  get stateRules() {
    return [];
  }

  get taxNumberRules() {
    return this.isTaxnumberRequired && this.user.isCompany ? [requiredRule()] : [];
  }
}
