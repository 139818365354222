



































import { AvailabilityModule } from "@/store/modules/availability.store";
import { Component, Vue, Prop } from "vue-property-decorator";
import BookingNavigation from "./BookingNavigation.vue";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";
import { detailedDateWithDay, formatHoursAndMinutes } from "@/lib/utility/date-helper";
import { EventAttributes, createEvent } from "ics";
import { PartnerModule } from "@/store/modules/partner";
import { downloadFile } from "@/lib/utility/downloadFileFunc";
import LoginRegisterButton from "@/components/utility/LoginRegisterButton.vue";

@Component({ components: { BookingNavigation, LoginRegisterButton } })
export default class BookingSuccess extends Vue {
  @Prop({ default: true })
  showDownloadBtn!: boolean;

  @Prop({ default: true })
  showImage!: boolean;

  @Prop({ default: "bookingForm.BookingSuccess.bookingConfirmed" })
  heading!: string;

  @Prop({ default: "bookingForm.BookingSuccess.bookingConfirmationText" })
  text!: string;

  get confirmedBooking() {
    return AvailabilityModule.confirmedBooking;
  }

  get selectedService() {
    return AvailabilityModule.selectedService;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get customerInformation() {
    return AvailabilityModule.customerInformation;
  }

  get email() {
    return this.confirmedBooking?.customerInformation?.email || "";
  }

  get image() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.bookingSuccess);
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get selectedSlotDate() {
    if (this.confirmedBooking) {
      return detailedDateWithDay(new Date(this.confirmedBooking.start).toISOString(), this.$t("locale").toString());
    }

    return "";
  }

  get selectedSlotStartTime() {
    if (this.confirmedBooking) {
      const from = formatHoursAndMinutes(new Date(this.confirmedBooking.start));

      const to = formatHoursAndMinutes(new Date(this.confirmedBooking.end));
      return this.$t("bookingForm.common.slot", { from, to });
    }

    return "";
  }

  createIcs() {
    if (!this.confirmedBooking) {
      return undefined;
    }

    const date = new Date(this.confirmedBooking.start);

    const event: EventAttributes = {
      start: [
        date.getUTCFullYear(),
        date.getUTCMonth() + 1,
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes()
      ],
      startInputType: "utc",
      duration: { seconds: (this.confirmedBooking.end - this.confirmedBooking.start) / 1000 },
      title: this.selectedService?.name,
      description: this.selectedService?.description,
      location: `${this.selectedService?.address?.street || this.partner.address?.street || ""} ${this.selectedService
        ?.address?.zip ||
        this.partner.address?.zip ||
        ""} ${this.selectedService?.address?.city || this.partner.address?.city || ""} ${this.selectedService?.address
        ?.state ||
        this.partner.address?.state ||
        ""}`,
      status: "CONFIRMED",
      busyStatus: "BUSY",
      organizer: { name: this.partner.companyName, email: this.partner.contact?.email },
      attendees: [
        {
          name: this.partner.companyName,
          email: this.partner.contact?.email,
          rsvp: true,
          partstat: "ACCEPTED",
          role: "REQ-PARTICIPANT"
        },
        {
          name: `${this.customerInformation.firstName} ${this.customerInformation.lastName}`,
          email: this.email,
          rsvp: true,
          partstat: "ACCEPTED",
          role: "REQ-PARTICIPANT"
        }
      ]
    };

    if (this.selectedService?.address?.geo.lat && this.selectedService?.address?.geo.lng) {
      event.geo = {
        lat: this.selectedService?.address?.geo.lat,
        lon: this.selectedService?.address?.geo.lng
      };
    }

    return createEvent(event, (error, value) => {
      if (error) {
        this.$log.error(error);
        return;
      }

      this.$log.debug(value);

      downloadFile(`${this.partner.companyUsername}.ics`, value);
    });
  }
}
