import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IPartnerUser, PartnerUser } from "@/models/user.entity";

export const PartnerUserAccessLayer = new (class extends AbstractLocalDataAccessLayer<IPartnerUser> {
  getIdentifier(entity: IPartnerUser): string {
    return entity.id;
  }

  protected mapEntity(entity: IPartnerUser): IPartnerUser {
    return new PartnerUser(entity);
  }
})();
