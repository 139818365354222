import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";
import vehicleService from "@/services/mrfiktiv/services/vehicleService";
import { MrfiktivVehicleControllerGetAllParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import { FleetAggregationModule } from "../fleet-aggregation.store";

export const VehiclePageDataProvider = new (class extends AbstractPageDataProvider<
  IVehicle,
  MrfiktivVehicleControllerGetAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivVehicleControllerGetAllParamsGen): Promise<IPageViewModel<IVehicle>> {
    const res = await vehicleService.getAll(query);

    const meta = res.meta;
    const data = (res.data ?? []).map(vehicle => new Vehicle(vehicle));
    FleetAggregationModule.parse(data);

    return { meta, data };
  }
})();
