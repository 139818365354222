var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', [_vm._t("icon"), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "text-truncate"
  }, [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.title) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.item.title) + " ")])]), _vm.item.description && _vm.item.description !== 'undefined' && !_vm.small ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.description) + " ")]) : _vm._e(), !_vm.small ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.isSigned ? _vm.$t("sign.DocumentDetail.signed") : _vm.item.isTemplate ? _vm.$t("sign.DocumentDetail.template") : _vm.$t("sign.DocumentDetail.file")) + " ")]) : _vm._e(), !_vm.small ? _c('div', [!_vm.isLoading ? _c('vue-picture-swipe', {
    staticClass: "mx-2 mt-2 mb-2",
    attrs: {
      "items": _vm.images,
      "options": {
        shareEl: false,
        rotationOn: true,
        showAnimationDuration: 0,
        hideAnimationDuration: 0
      }
    }
  }) : _c('v-skeleton-loader', {
    staticClass: "mx-2 mt-2 mb-2",
    attrs: {
      "type": "image"
    }
  })], 1) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }