/**
 * Static class that keeps track of app navigation history
 */
export default class HistoryLog {
  /**
   * The visited routes
   */
  private static historyLog: string[] = [];

  /**
   * The visited routes. The topmost route is the one that is currently open
   */
  static get history(): string[] {
    return this.historyLog;
  }

  /**
   * The route that was visited before the current
   */
  static get previous(): string | undefined {
    return this.historyLog[this.historyLog.length - 2];
  }

  /**
   * Adds a route to the history
   *
   * @param route a visited route
   */
  static add(route: string) {
    this.historyLog.push(route);
  }
}
