import { IContactCustomer } from "@/components/utility/interface/contact-customer.interface";
import CustomerContactService from "@/services/mrfiktiv/services/customerContactService";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ICustomerContactTemplatesDto } from "../interface/customer.contact.dto.interface";
import { ErrorLogModule } from "./error-log";

@Module({
  dynamic: true,
  namespaced: true,
  name: "customerContactStore",
  store
})
class CustomerContactStore extends VuexModule {
  templates: IContactCustomer[] = [];

  @Mutation
  setTemplates(templates: IContactCustomer[]) {
    this.templates = templates;
  }

  @Action
  async getTemplates(dto: ICustomerContactTemplatesDto): Promise<IContactCustomer[]> {
    let templates: IContactCustomer[] = [];
    try {
      templates = await CustomerContactService.getAll(dto);
      this.context.commit("setTemplates", templates);
    } catch (error) {
      Vue.$log.error(error);
      ErrorLogModule.addErrorLog({
        name: "User Error",
        message: "Could not fetch Templates"
      });
    }

    return templates;
  }
}

export const CustomerContactModule = getModule(CustomerContactStore);
