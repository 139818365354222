


























import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { ConfigModule } from "@/store/modules/config";
import { FeatureModule } from "@/store/modules/feature.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * Layout for navigation lists in navigation bar
 */
@Component({})
export default class TheNavigationLayout extends Vue {
  /**
   * The items to show in the navigation
   */
  @Prop()
  items!: IMenuListItem[];

  /**
   * The title of the navigation block
   */
  @Prop()
  title!: string;

  /**
   * Is the navigation hidden
   */
  @Prop({ default: true })
  isEnabled?: boolean;

  /**
   * checks if user is permitted to see the item
   *
   * @param item the item to check
   */
  isUserPermittedToSee(item: IMenuListItem) {
    if (!item.permission) {
      return true;
    }

    const { resource, action, field } = item.permission;

    return UserModule.abilities.can(action, resource, field);
  }

  /**
   * Checks if the navigation is enabled or should be hidden
   *
   * the navigation is disabled if the isEnabled flag is set to false
   * the navigation is disabled if every item is hidden by permission
   * the navigation is enabled if it is not disabled
   */
  get isNavigationEnabled() {
    if (!this.isEnabled) {
      return false;
    }
    let isNavigationItemEnabled = false;
    for (const item of this.items) {
      if (item.permission === undefined) {
        // if there is an item that has no permission requirement, the item is visible
        isNavigationItemEnabled = true;
      } else if (this.isUserPermittedToSee(item)) {
        // the user is allowed to see the item
        isNavigationItemEnabled = true;
      }
    }

    return isNavigationItemEnabled;
  }

  /**
   * Set color of active navigation element in partner color
   */
  setSelectedElementColor() {
    const partnerColor = getDefaultPartnerColor();
    const navigationElements = document.getElementsByClassName("navigation");

    for (const navigationElement of navigationElements || []) {
      for (const activeNavigationElement of navigationElement.getElementsByClassName("v-list-item--active") || []) {
        (activeNavigationElement as HTMLElement).style["color"] = partnerColor;
      }
    }
  }

  mounted() {
    if (ConfigModule.appContext === AppContextEnum.THG && FeatureModule.isNavigationInPartnerColors) {
      this.setSelectedElementColor();
    }
  }
}
