/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateUserDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivSignUpUserDtoGen,
  MrfiktivUpdateUserDtoGen,
  MrfiktivUserAdminCreateViewmodelGen,
  MrfiktivUserControllerFindAllPaginatedParamsGen,
  MrfiktivUserControllerFindallParamsGen,
  MrfiktivUserControllerFindManyParamsGen,
  MrfiktivUserPermissionDtoGen,
  MrfiktivUserRoleDtoGen,
  MrfiktivUserViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class User<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags user
   * @name UserControllerCreateAdminUser
   * @summary Initially creates an adminuser
   * @request POST:/user/createAdmin
   * @response `200` `MrfiktivUserAdminCreateViewmodelGen` The updated record
   * @response `400` `void` Bad request.
   */
  userControllerCreateAdminUser = (params: RequestParams = {}) =>
    this.http.request<MrfiktivUserAdminCreateViewmodelGen, void>({
      path: `/user/createAdmin`,
      method: "POST",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerSignUp
   * @summary Creates an new user and send invitation email to new user.
   * @request POST:/user/signUp
   * @response `200` `MrfiktivUserViewmodelGen` The created user
   * @response `400` `void` Bad request.
   * @response `409` `void` Conflict.
   * @response `429` `void` Too Many Requests
   */
  userControllerSignUp = (data: MrfiktivSignUpUserDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user/signUp`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerInvite
   * @summary (ADMIN) Creates an new user and send invitation email to new user. You need Admin Access.
   * @request POST:/user/invite/asAdmin
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The updated record
   * @response `400` `void` Bad request.
   * @response `403` `void` Forbidden.
   */
  userControllerInvite = (data: MrfiktivCreateUserDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user/invite/asAdmin`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerFind
   * @summary Get an user by auth info
   * @request GET:/user
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The found record
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerFind = (params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerFindall
   * @summary (DEPRECATED - USE /page OR /many) (ADMIN) Get all users
   * @request GET:/user/all
   * @deprecated
   * @secure
   * @response `200` `(MrfiktivUserViewmodelGen)[]` The found record
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerFindall = (query: MrfiktivUserControllerFindallParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen[], void>({
      path: `/user/all`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerFindMany
   * @summary (ADMIN) Get users from list
   * @request GET:/user/many
   * @secure
   * @response `200` `(MrfiktivUserViewmodelGen)[]` The found records
   * @response `403` `void` Forbidden.
   */
  userControllerFindMany = (query: MrfiktivUserControllerFindManyParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen[], void>({
      path: `/user/many`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerFindAllPaginated
   * @summary (ADMIN) Get all users using pagination
   * @request GET:/user/page
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivUserViewmodelGen)[] })`
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerFindAllPaginated = (
    query: MrfiktivUserControllerFindAllPaginatedParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivUserViewmodelGen[] }, void>({
      path: `/user/page`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerFindOne
   * @summary (ADMIN) Get an user by id
   * @request GET:/user/{id}
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The found record
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerFindOne = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerUpdate
   * @summary Update a user by id
   * @request PATCH:/user/{id}
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The updated record
   * @response `400` `void` Bad request.
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerUpdate = (id: string, data: MrfiktivUpdateUserDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerFindByAuthInfo
   * @summary (ADMIN) Get an user by auth info id
   * @request GET:/user/{id}/username
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The found record
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerFindByAuthInfo = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user/${id}/username`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerUpdateRole
   * @summary (ADMIN) Update user roles id
   * @request PATCH:/user/{id}/role
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The updated record
   * @response `400` `void` Bad request.
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerUpdateRole = (id: string, data: MrfiktivUserRoleDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user/${id}/role`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerAddPermission
   * @summary (ADMIN) Add a permission to a user by id
   * @request PATCH:/user/{id}/permission/add
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The updated record
   * @response `400` `void` Bad request.
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerAddPermission = (id: string, data: MrfiktivUserPermissionDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user/${id}/permission/add`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerRemovePermission
   * @summary (ADMIN) Removes a permission for a user by id
   * @request PATCH:/user/{id}/permission/remove
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The updated record
   * @response `400` `void` Bad request.
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerRemovePermission = (id: string, data: MrfiktivUserPermissionDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user/${id}/permission/remove`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags user
   * @name UserControllerDelete
   * @summary (ADMIN) Deletes a User by Id
   * @request DELETE:/user/{id}/delete
   * @secure
   * @response `200` `MrfiktivUserViewmodelGen` The deleted record
   * @response `400` `void` Bad request.
   * @response `403` `void` Forbidden.
   * @response `404` `void` User not found.
   */
  userControllerDelete = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, void>({
      path: `/user/${id}/delete`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * @description <p>This endpoint can be used in the admin sign up flow</p> <ol> <li>Admin invites a User</li> <li>User lost invitationmail or temporary password is esceeded (Status is still FORCE_CHANGE_PASSWORD)</li> <li>Use this Endpoint to resend invatationmail for a user from the user pool</li> </ol> <p>Summary: (ADMIN) Resends invitation for an invited user in case he lost is mail. Is Only working is the user Status is FORCE_CHANGE_PASSWORD</p>
   *
   * @tags user
   * @name UserControllerResendInvitation
   * @summary (ADMIN) Resends invitation for an invited user in case he lost is mail. Is Only working is the user Status is FORCE_CHANGE_PASSWORD
   * @request POST:/user/{id}/resendInvitationMessage
   * @secure
   * @response `201` `MrfiktivUserViewmodelGen` The user
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` User not found
   * @response `409` `MrfiktivExceptionViewmodelGen` An conflict occured.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  userControllerResendInvitation = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivUserViewmodelGen, MrfiktivExceptionViewmodelGen>({
      path: `/user/${id}/resendInvitationMessage`,
      method: "POST",
      secure: true,
      format: "json",
      ...params
    });
}
