import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { ITicket } from "@/models/ticket.entity";
import ticketService from "@/services/mrfiktiv/services/ticketService";
import { MrfiktivTicketControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import { FleetAggregationModule } from "../fleet-aggregation.store";
import Vue from "vue";

export const TicketPageDataProvider = new (class extends AbstractPageDataProvider<
  ITicket,
  MrfiktivTicketControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivTicketControllerGetParamsGen): Promise<IPageViewModel<ITicket>> {
    const res = await ticketService.getAll({ ...query });

    const meta = res.meta;
    const data = (res.data || []) as ITicket[];

    Vue.$log.debug("Paged tickets. Parsing for fleet ", data?.length);
    FleetAggregationModule.parseTickets(data);

    return { meta, data };
  }
})();
