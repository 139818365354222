






















































import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class BookingStart extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  @Prop({ default: false })
  dark!: boolean;

  /**
   * Flag if booking is used in an iframe(design should not change)
   */
  @Prop({ default: false })
  bookingIframe!: boolean;

  start() {
    AvailabilityModule.setServices();
  }

  get color() {
    return getDefaultPartnerColor();
  }
  get items() {
    return [
      {
        icon: "mdi-checkbox-marked-circle-outline",
        title: this.$t("bookingForm.BookingStart.item1.title"),
        subtitle: this.$t("bookingForm.BookingStart.item1.subtitle")
      },
      {
        icon: "mdi-checkbox-marked-circle-outline",
        title: this.$t("bookingForm.BookingStart.item2.title"),
        subtitle: this.$t("bookingForm.BookingStart.item2.subtitle")
      },
      {
        icon: "mdi-checkbox-marked-circle-outline",
        title: this.$t("bookingForm.BookingStart.item3.title"),
        subtitle: this.$t("bookingForm.BookingStart.item3.subtitle")
      }
    ];
  }
}
