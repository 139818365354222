/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { FilterTypes } from "@/lib/filterable";
import { PageFilterElement } from "@/models/page-filter-element.entity";

/**
 * Filter configuration
 * if type is ENUM, enum value will be considered
 */
interface IPaginationFilterListElement {
  key: string;

  /**
   * In context of custom field this is the custom field id
   */
  specifier?: string;

  /**
   * Optional name to help user identify the filter
   * Can be path to translation
   * if not set key is used as identifier
   */
  displayName?: string;

  /**
   * What's this filter: string, number,...
   */
  type: FilterTypes;

  /**
   * The min-width for the header in tables and forms
   */
  width?: string;

  // /**
  //  * For filter type enum it can be specified which enum values are here
  //  */
  // enum?: any;

  config?: {
    /**
     * List of values/ objects to choose from
     */
    items?: any[];

    /**
     * Method returining List of values/ objects to choose from. Has prio over items.
     */
    itemCallback?: () => any[];

    mapItemToComponent?: (item: any) => any;

    /**
     * Which property of the object is actually filtered? e.g. "_id"
     */
    itemValue?: string;

    /**
     * which component is used to display the choices
     * e.g. refs-user, refs-vehicle, ...
     */
    component?: string;
  };
}

class PaginationFilterListElement implements IPaginationFilterListElement {
  /**
   * @inheritdoc
   */
  key: string;
  /**
   * @inheritdoc
   */
  specifier?: string;
  /**
   * @inheritdoc
   */
  displayName?: string | undefined;
  /**
   * @inheritdoc
   */
  type: FilterTypes;
  /**
   * @inheritdoc
   */
  width?: string | undefined;
  /**
   * @inheritdoc
   */
  config?: {
    /**
     * @inheritdoc
     */
    items?: any[];
    /**
     * @inheritdoc
     */
    itemCallback?: () => any[];
    mapItemToComponent?: (item: any) => any;
    /**
     * @inheritdoc
     */
    itemValue?: string;
    /**
     * @inheritdoc
     */
    component?: string;
  };

  /**
   * Unique identifier for the filter
   * Note: Key isself is not unique, as it can be used multiple times in different contexts e.g. custom fields of Ticket all have key 'values.value'
   */
  get id(): string {
    return PageFilterElement.calcId(this.key, this.specifier);
  }

  constructor(data: Omit<PaginationFilterListElement, "id">) {
    this.key = data.key;
    this.displayName = data.displayName;
    this.type = data.type;
    this.config = data.config;
    this.specifier = data.specifier;
    this.width = data.width;
  }
}

export { PaginationFilterListElement };
