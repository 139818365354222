/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Returns the property value by a given path
 * @example
 * ```ts
 * const object = {
 *  test: {
 *    a: 1,
 *    b: 1
 *  }
 * }
 *
 * getNestedObjectValues(object, "test.a")
 * // 1
 * ```
 * @param object
 * @param path
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function getNestedObjectValues(object: Object, path: string) {
  const pathBits = path.split(".");

  for (const pathBit of pathBits) {
    object = object[pathBit] ?? "";
  }

  return object;
}

/**
 * Sets the property value by a given path
 * @example
 * ```ts
 * const object = {
 *  test: {
 *    a: 1,
 *    b: 1
 *  }
 * }
 *
 * setNestedObjectValues(object, "test.a", 2)
 * // {
 * // test: {
 * //   a: 2,
 * //   b: 1
 * // }
 * }
 * ```
 * @param object
 * @param path
 * @param value
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function setNestedObjectValues(object: Object, path: string, value: any) {
  const pathBits = path.split(".");

  if (pathBits.length === 1) {
    object[pathBits[0]] = value;
  } else {
    const firstBit = pathBits.splice(0, 1);
    object[firstBit[0]] = setNestedObjectValues(object[firstBit[0]] ?? {}, pathBits.join("."), value);
  }

  return object;
}

/**
 * Given a nested object returns the path in dot notation of the object.
 * @example
 *
 * ```ts
 * const object = {
 *  test: {
 *    a: 1,
 *    b: 1
 *  }
 * }
 *
 * propertiesToArray(object)
 * // ['test.a', 'test.b']
 * ```
 * @param obj to return the property path
 * @returns the path separated by a `.`
 */
export function propertiesToArray(obj: any) {
  const isObject = (val: object) => val && typeof val === "object" && !Array.isArray(val);

  const addDelimiter = (a: string, b: string): string => (a ? `${a}.${b}` : b);

  const paths = (obj: object = {}, head = ""): string[] => {
    return Object.entries(obj).reduce((product, [key, value]) => {
      const fullPath = addDelimiter(head, key);

      return isObject(value) ? product.concat(paths(value, fullPath)) : product.concat(fullPath);
    }, [] as string[]);
  };

  return paths(obj);
}

/**
 * Returns the property value by a given path with array support
 * @param object {refs: [{refId: "1"}, {refId: "2"}]}
 * @param path "refs.refId"
 * @returns ["1", "2"]
 */
export function getArrayedNestedPath(object: any, path: string): (string | boolean | number)[] {
  const pathBits = path.split(".");
  const pathBit = pathBits[0];

  if (object === undefined || object === null) {
    return [];
  }

  let pathBitValues = object[pathBit];

  const resses: any[] = [];

  if (!Array.isArray(pathBitValues)) {
    pathBitValues = [pathBitValues];
  }

  pathBitValues.forEach((pathBitValue: any) => {
    if (pathBits.length > 1) {
      resses.push(...getArrayedNestedPath(pathBitValue, pathBits.slice(1).join(".")));
    } else if (pathBitValue || pathBitValue === false) {
      resses.push(pathBitValue);
    }
  });

  return resses;
}
