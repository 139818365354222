






















import { downloadUrlFromRemoteOrigin } from "@/lib/utility/downloadFileFunc";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDownloadFile } from "./interface/download-file.interface";
import { saveAs } from "file-saver";
import JsZip, { JSZipGeneratorOptions } from "jszip";

@Component({
  components: {}
})
export default class FileDownload extends Vue {
  @Prop()
  downloadFiles!: IDownloadFile[];

  @Prop({ default: true })
  icon!: boolean;

  @Prop({ default: false })
  listItem!: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  @Prop({ default: "" })
  filePrefix!: string;

  @Prop({ default: "Herunterladen" })
  text!: string;

  loading = false;

  get prefix() {
    let prefix = "";
    if (this.filePrefix) {
      prefix = `${this.filePrefix}_`;
    }
    return prefix;
  }

  get disabled() {
    if (this.downloadFiles.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  async downloadOne() {
    let overallSucess = true;
    for (const file of this.downloadFiles) {
      const success = await downloadUrlFromRemoteOrigin(file.url, this.prefix + file.filename);
      if (!success) {
        overallSucess = false;
      }
    }

    return overallSucess;
  }

  async downloadMultiple() {
    let overallSucess = true;
    const images = [];
    for (const file of this.downloadFiles) {
      const resp = await fetch(file.url).then(resp => resp);
      if (resp.status >= 300) {
        this.$log.error("Could not download image " + file.filename);
        overallSucess = false;
        continue;
      }
      images.push({ name: file.filename, data: await resp.blob() });
    }
    await this.saveZip(images);
    return overallSucess;
  }

  async download() {
    this.loading = true;
    let overallSucess = true;
    try {
      if (this.downloadFiles.length > 1) {
        overallSucess = await this.downloadMultiple();
      } else {
        overallSucess = await this.downloadOne();
      }
    } catch (error) {
      this.$log.error(error);
      overallSucess = false;
    }

    if (overallSucess) {
      Vue.$toast.success("Download erfolgreich.");
    } else {
      Vue.$toast.error("Fehler beim Download. Es konnten nicht alle Dateien heruntergeladen werden.");
    }
    this.loading = false;
  }

  /**
   *
   * Creates a zip file
   *
   * @param files
   */
  async saveZip(files: { name: string; data: Blob }[]) {
    const zip = JsZip();

    for (const file of files) {
      zip.file(file.name, file.data);
    }
    const options: JSZipGeneratorOptions<"blob"> = {
      type: "blob"
    };

    const zipBlob = await zip.generateAsync(options);
    const currentDate = new Date().toISOString();

    const fileName = `${this.prefix}${currentDate}.zip`;

    return saveAs(zipBlob, fileName);
  }
}
