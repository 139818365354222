var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": "views.AuthorizationOverview.title"
    }
  }, [_c('v-container', [_c('login-card-authorization-overview')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }