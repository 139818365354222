











































import LanguageDialog from "@/components/utility/LanguageDialog.vue";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: { LanguageDialog } })
export default class TheSettingsList extends Vue {
  showLanguageSelectionDialog = false;

  set debug(bo: boolean) {
    ConfigModule.setDebug(bo);
  }

  get debug() {
    return ConfigModule.debug;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get local() {
    return this.$i18n.locale;
  }

  toggle() {
    this.darkMode = !this.darkMode;
  }

  sendMail() {
    const mailText = `mailto:${ConfigModule.supportMail}`;

    window.open(mailText, "_blank");
  }

  changeLanguage() {
    this.showLanguageSelectionDialog = true;
  }

  next() {
    this.showLanguageSelectionDialog = false;
  }

  set darkMode(bo: boolean) {
    ConfigModule.setDarkMode(bo);
  }

  get darkMode() {
    return ConfigModule.darkMode;
  }

  get isMintFuture(): boolean {
    return PartnerModule?.partner?.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_MINT_FUTURE;
  }

  submitRating() {
    window.open(ConfigModule.googleThgRatingLink, "_blank");
  }
}
