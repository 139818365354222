




import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { FeatureModule } from "@/store/modules/feature.store";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";
import { PartnerModule } from "@/store/modules/partner";
import { UserModule } from "@/store/modules/me-user.store";

@Component({ components: { TheNavigationLayout } })
export default class TheReportList extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  route(route: string) {
    this.$router.push({ name: route });
  }

  get items(): IMenuListItem[] {
    return [
      {
        icon: "mdi-camera",
        text: "report.navigation.report",
        link: "/",
        isEnabled: true
      },
      {
        icon: "mdi-calendar",
        text: "report.navigation.booking",
        link: "/booking",
        isEnabled: this.isOnlineBooking
      },
      {
        icon: "mdi-car-outline",
        text: "navigation.ThePartnerList.report",
        link: "/my/damages",
        isEnabled: this.isAuthenticated
      },
      {
        icon: "mdi-calendar-multiselect",
        text: "navigation.TheOnlineBookingList.title",
        link: "/my/bookings",
        isEnabled: this.isAuthenticated && this.isOnlineBooking
      },
      {
        icon: "mdi-file-document-outline",
        text: "navigation.TheDigitalSignatureList.title",
        link: "/my/signatures",
        isEnabled: this.isAuthenticated && this.isSignature
      },
      {
        icon: "mdi-login",
        text: "navigation.TheAboutList.login",
        link: "/login",
        isEnabled: !this.isAuthenticated && this.isCustomerSignup
      },
      {
        icon: "mdi-account-plus-outline",
        text: "navigation.TheAboutList.signUp",
        link: "/sign-up",
        isEnabled: !this.isAuthenticated && this.isCustomerSignup
      }
    ];
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  /**
   */
  get isCustomerSignup(): boolean {
    return PartnerModule.partner.settings?.bookedServices.customer ?? false;
  }

  /**
   */
  get isOnlineBookingActive(): boolean {
    return FeatureModule.onlineBooking;
  }

  /**
   * Is Online Booking license enabled
   */
  get isOnlineBooking(): boolean {
    return (this.isOnlineBookingActive && PartnerModule.partner.settings?.bookedServices?.onlineBooking) ?? false;
  }

  /**
   * Is Signature license enabled
   */
  get isSignature(): boolean {
    return PartnerModule.partner.settings?.bookedServices?.sign ?? false;
  }
}
