/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Defines the types that the user can specify how he became aware of us
 */
export enum KownFromEnum {
  FRIENDS = "friends",
  INTERNET = "internet",
  UNTERNEMEN = "unternehmen",
  OTHER = "other"
}
