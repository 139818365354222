import { Filter, IsFilterable } from "@/lib/filterable";
import {
  MrfiktivBaseCollectionSchemaGen,
  MrfiktivCreateReportImageCollectionDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { IImage, Image } from "./image.entity";

@IsFilterable
class BaseCollectionBase implements MrfiktivBaseCollectionSchemaGen {
  registrations: IImage[];
  cockpits: IImage[];
  damages: IImage[];
  damagesDetail: IImage[];
  overviews: IImage[];
  plates: IImage[];

  constructor(collection?: Partial<MrfiktivBaseCollectionSchemaGen>) {
    this.registrations = (collection?.registrations ?? []).map(r => new Image(r));
    this.cockpits = (collection?.cockpits ?? []).map(r => new Image(r));
    this.damages = (collection?.damages ?? []).map(r => new Image(r));
    this.damagesDetail = (collection?.damagesDetail ?? []).map(r => new Image(r));
    this.overviews = (collection?.overviews ?? []).map(r => new Image(r));
    this.plates = (collection?.plates ?? []).map(r => new Image(r));
  }

  get imageCollectionDto() {
    return {
      registrations: this.registrations.map(r => r.id).filter(i => i),
      cockpits: this.cockpits.map(r => r.id).filter(i => i),
      damages: this.damages.map(r => r.id).filter(i => i),
      damagesDetail: this.damagesDetail.map(r => r.id).filter(i => i),
      overviews: this.overviews.map(r => r.id).filter(i => i)
    } as MrfiktivCreateReportImageCollectionDtoGen;
  }
}
type IBaseCollection = BaseCollectionBase;
const BaseCollection = Filter.createForClass(BaseCollectionBase);

export { BaseCollection, IBaseCollection };
