import About from "@/views/About.vue";
import Terms from "@/views/Terms.vue";
import Privacy from "@/views/Privacy.vue";
import Imprint from "@/views/Imprint.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Revocation from "@/views/Revocation.vue";

Vue.use(VueRouter);

export const BaseRoutes: RouteConfig[] = [
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/agbs",
    name: "Terms",
    component: Terms
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy
  },
  {
    path: "/datenschutz",
    component: Privacy,
    name: "Datenschutz"
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint
  },
  {
    path: "/revocation",
    name: "Revocation",
    component: Revocation
  }
];
