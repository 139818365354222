var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-app-bar', {
    attrs: {
      "app": "",
      "floating": "",
      "flat": "",
      "color": "transparent"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "dark": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('camera-app', {
    ref: "camera",
    staticClass: "ma-0 pa-0",
    attrs: {
      "instruction": _vm.instruction,
      "snackbarTitle": _vm.snackbarTitle,
      "overlayAsset": _vm.asset
    },
    on: {
      "image-taken": _vm.imageTaken,
      "camera-error": _vm.cameraError
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }