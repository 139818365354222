/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * @see https://linuxhint.com/remove-all-non-alphanumeric-characters-in-javascript
 * @param s
 * @returns
 */
export function removeAlphanumericCharacters(s: string) {
  return s.replace(/\W/g, "");
}

export enum FileType {
  PNG = "png"
}

/**
 * Creates a file name from a string
 *
 * @param s string to create file name from
 * @param type the extension @example 'png'
 * @returns the file name with extension
 *
 * @example `createFileName(this.signature.lastName, "png")`;
 */
export function createFileName(s: string, type: FileType) {
  return removeAlphanumericCharacters(s) + "." + type;
}

/**
 * returns substring with 3 dots in the end
 *
 * @example ("blablabla", 6) => "bla..."
 * @example ("blabla", 6) => "blabla"
 * @example ("bla", 6) => "bla"
 *
 * @param text
 * @param length
 * @returns
 */
export function dottedSubString(text: string, length = 100) {
  if (!text) {
    return "";
  }
  if (text.length <= length) {
    return text;
  }

  return text.substring(0, length - 3) + "...";
}
