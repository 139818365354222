/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { UserModule } from "@/store/modules/me-user.store";
import { AxiosRequestConfig } from "axios";
import { ITokenProvider } from "./token-provider.interface";

/**
 * Refresh token dto.
 */
export interface GetTokenByRefreshTokenDto {
  /** The username (uuid) */
  uuidUsername: string;

  /** The refreshtoken */
  refreshToken: string;
}

/**
 * Adds a token provider as a axios interceptor.
 */
export class BearerTokenProvider implements ITokenProvider {
  /**
   * Add bearer token to request.
   *
   * @param AxiosRequestConfig to add bearer token to headers.Authorization
   * @returns AxiosRequestConfig to use in axios.request
   */
  async addTokenToRequest(config: AxiosRequestConfig) {
    // User is not logged in (as in has no access token)
    if (!UserModule.authAccessTokens?.accessToken) {
      return config;
    }

    try {
      await this.tryRefreshExpiredToken();

      const token = `Bearer ${UserModule.authAccessTokens.accessToken}`;
      config.headers.Authorization = token;
    } catch (error) {
      //refresh didn't work. Remove user tokens.
      UserModule.forceLogout();
      throw error;
    }

    return config;
  }

  /**
   * Get token by refresh token using the cached credentials in <see "UserModule"/> .
   */
  async tryRefreshExpiredToken() {
    const expiresAt = UserModule.authAccessTokens.expiresAt;

    if (expiresAt) {
      //check if authAccessToken is invalid
      if (Date.now() > parseInt(expiresAt)) {
        // check if we have the necessary information to refresh a token
        if (!UserModule.user || !UserModule.user.authInfo) {
          throw Error("Unable to refresh token: user.authInfo missing.");
        }

        //token is invalid -> refresh it
        const refresh: GetTokenByRefreshTokenDto = {
          uuidUsername: UserModule.user.authInfo.username,
          refreshToken: UserModule.authAccessTokens.refreshToken
        };
        await UserModule.refresh(refresh);
      }
    }
  }
}
