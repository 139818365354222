import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "ui",
  store
})
export class UiStore extends VuexModule {
  /**
   * Stores a global loading state.
   */
  loading = true;

  /**
   * Stores the <see cref="TheLayoutBase"/> drawer state.
   */
  drawer = false;

  /**
   * Sets the drawer to open or closed
   * @param val Sets the drawer to open or closed
   */
  @Action
  setDrawer(val: boolean) {
    this.context.commit("updateDrawer", val);
  }

  /**
   * Toggles the menu drawer
   * @param val drawer open or closed
   */
  @Mutation
  updateDrawer(val: boolean) {
    this.drawer = val;
  }

  /**
   * Sets a global loading state for the ui.
   * @param loading boolean if loading is true
   * @returns loading status
   */
  @MutationAction
  async setLoading(loading: boolean) {
    return {
      loading
    };
  }
}

export const UiModule = getModule(UiStore);
