





















import CameraApp from "@/components/camera/CameraApp.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    CameraApp
  }
})
export default class CameraDialog extends Vue {
  private dialog = false;

  @Prop()
  private instruction!: IReportInstructionData;

  @Prop()
  private snackbarTitle!: string;

  @Prop()
  private asset!: AssetEnum;

  @Prop()
  private handleUploadCb!: (file: File) => Promise<void>;

  @Prop()
  private handleDeleteCb!: (file: File) => Promise<boolean>;

  async mounted() {
    this.dialog = true;
    await (this.$refs.camera as CameraApp).startRecording();
  }

  public async close() {
    this.$emit("close-camera-dialog");
    await (this.$refs.camera as CameraApp).stopRecording();
  }

  /**
   * Intercept the handleUploadCb event and pass it to the parent.
   * After that close the dialog.
   */
  public async imageTaken(file: File) {
    this.handleUploadCb(file);
    this.close();
  }

  /**
   * Handle Camera error event
   */
  public cameraError() {
    this.close();
  }
}
