import { IsFilterable, Filter } from "@/lib/filterable";
import { MrfiktivBaseImageGen, MrfiktivImageEditDataDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ReportImageType } from "./Report/ReportImageType";
import { ITimestampDocument, TimestampDocument } from "./timestamp.entity";

@IsFilterable
class ImageBase implements MrfiktivBaseImageGen {
  id?: string;
  url: string;
  name: string;
  type: ReportImageType;
  folderName: string;
  editData?: MrfiktivImageEditDataDtoGen;
  timestamp: ITimestampDocument;

  constructor(image?: Partial<MrfiktivBaseImageGen>) {
    this.id = image?.id;
    this.url = image?.url ?? "";
    this.name = image?.name ?? "";
    this.folderName = image?.folderName ?? "";
    this.type = (image?.type ?? ReportImageType.others) as ReportImageType;
    this.editData = image?.editData;
    this.timestamp = new TimestampDocument(image?.timestamp);
  }
}
type IImage = ImageBase;
const Image = Filter.createForClass(ImageBase);

export { IImage, Image };
