import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import customFieldService from "@/services/mrfiktiv/services/customFieldService";
import {
  MrfiktivCustomFieldConfigurationViewModelGen,
  MrfiktivCustomFieldControllerGetParamsGen,
  MrfiktivCustomFieldViewModelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateCustomFieldDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { MrfiktivCreateCustomFieldDtoGen } from "../../services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "../enum/authActionEnum";
import { ResourceEnum } from "../enum/authResourceEnum";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { UserModule } from "./me-user.store";
import { PartnerModule } from "./partner";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";

/**
 * Possible custom fields
 */
export enum CustomFieldEnum {
  /**
   * A custom field that is a select option.
   * Only one value is displayed based on the configured values.
   */
  SINGLE_SELECT = "single_select",

  /**
   * A custom field storing a date.
   * Date values can be compared > < ==
   */
  DATE = "date",

  /**
   * A custom field storing a time.
   * Time values can be compared > < ==
   */
  DATE_TIME = "date_time",

  /**
   * A custom fields storing a number value.
   * Number values can be compared > < == etc.
   */
  NUMBER = "number",

  /**
   * A custom field storing a true/false value.
   */
  BOOLEAN = "boolean",

  /**
   * A custom field storing a string value.
   */
  TEXT = "text"
}

export const customFieldIconTypeMap = new Map([
  [CustomFieldEnum.SINGLE_SELECT, "mdi-form-select"],
  [CustomFieldEnum.DATE, "mdi-calendar"],
  [CustomFieldEnum.DATE_TIME, "mdi-clock-outline"],
  [CustomFieldEnum.NUMBER, "mdi-counter"],
  [CustomFieldEnum.BOOLEAN, "mdi-checkbox-outline"],
  [CustomFieldEnum.TEXT, "mdi-form-textbox"]
]);

export class CustomFieldCreateDtoHelper implements MrfiktivCreateCustomFieldDtoGen {
  type: CustomFieldEnum;
  name: string;
  key: string;
  label: string;
  hint: string;
  placeholder: string;
  configuration?: MrfiktivCustomFieldConfigurationViewModelGen;

  constructor(customField?: Partial<MrfiktivCustomFieldViewModelGen>) {
    this.name = customField?.name ?? "";
    this.key = customField?.key ?? "";
    this.label = customField?.label ?? "";
    this.hint = customField?.hint ?? "";
    this.placeholder = customField?.placeholder ?? "";

    this.type = (customField?.type ?? CustomFieldEnum.TEXT) as CustomFieldEnum;

    if (customField?.type === CustomFieldEnum.SINGLE_SELECT) {
      this.configuration = customField?.configuration;
    }
  }
}
export class CustomFieldHelper extends CustomFieldCreateDtoHelper {
  id: string;
  partnerId: string;

  constructor(customField?: Partial<MrfiktivCustomFieldViewModelGen>) {
    super(customField);
    this.id = customField?.id ?? "";
    this.partnerId = customField?.partnerId ?? "";
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "custom-field",
  store
})
export class CustomFieldStore extends BasePagination<
  MrfiktivCustomFieldViewModelGen,
  MrfiktivCustomFieldControllerGetParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: MrfiktivCustomFieldViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = true;
  filterOptions: PaginationFilterListElement[] = [
    { key: "name", type: PageFilterTypes.STRING },
    {
      key: "type",
      type: PageFilterTypes.ENUM,
      config: {
        items: Object.values(CustomFieldEnum).map(e => {
          return {
            text: `enums.CustomFieldEnum.${e}`,
            value: e
          } as IVSelectItem;
        }),
        itemValue: "value"
      }
    },
    { key: "label", type: PageFilterTypes.STRING },
    { key: "hint", type: PageFilterTypes.STRING },
    { key: "_id", type: PageFilterTypes.OBJECT_ID }
  ].map(f => new PaginationFilterListElement(f));

  private _customField: MrfiktivCustomFieldViewModelGen = new CustomFieldHelper();

  get customFields() {
    return this._paginationList;
  }

  @Mutation
  private _mutateCustomField(request: MrfiktivCustomFieldViewModelGen) {
    this._customField = request;
  }

  @Action
  protected async loadDocuments(
    query: MrfiktivCustomFieldControllerGetParamsGen
  ): Promise<
    MrfiktivPageViewModelGen & {
      data?: MrfiktivCustomFieldViewModelGen[] | undefined;
    }
  > {
    return customFieldService.get(query);
  }

  @Action
  async create(data: { partnerId: string; data: MrfiktivCreateCustomFieldDtoGen }) {
    const res = await customFieldService.create(data.partnerId, data.data);

    this.context.commit("_mutateCustomField", res);

    return res;
  }

  @Action
  async delete(data: { partnerId: string; customFieldId: string }) {
    const res = await customFieldService.delete(data.partnerId, data.customFieldId);
    this.context.commit("_mutateCustomField", res);

    this.removeInList(res);

    return res;
  }

  @Action
  async getOne(data: { partnerId: string; customFieldId: string }) {
    const res = await customFieldService.getOne(data.partnerId, data.customFieldId);

    this.context.commit("_mutateCustomField", res);

    return res;
  }

  @Action
  async update(data: { partnerId: string; customFieldId: string; data: MrfiktivUpdateCustomFieldDtoGen }) {
    const res = await customFieldService.update(data.partnerId, data.customFieldId, data.data);

    this.context.commit("_mutateCustomField", res);

    return res;
  }

  /**
   * Clears store
   * if user is permitted also repopulates store
   */
  @Action
  async reset(): Promise<void> {
    this.context.commit("_mutatePaginationList", []);

    const partnerId = PartnerModule.partner.id;
    if (partnerId && UserModule.abilities.can(ActionEnum.READ, ResourceEnum.CUSTOM_FIELD, partnerId)) {
      this.setFilter([]);
      await this.fetchAllFromBeginning({ partnerId }).catch(e => Vue.$log.error(e));
    }
  }
}

export const CustomFieldModule = getModule(CustomFieldStore);
