import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreateBlueprintDtoGen,
  MrfiktivCreateLeasingContractDtoGen,
  MrfiktivCreateVehicleDocumentDtoGen,
  MrfiktivCreateVehicleDtoGen,
  MrfiktivUpdateBlueprintDtoGen,
  MrfiktivUpdateLeasingContractDtoGen,
  MrfiktivUpdateVehicleDtoGen,
  MrfiktivUpdateVehicleRegistrationDtoGen,
  MrfiktivUpdateVehicleStateDtoGen,
  MrfiktivVehicleControllerCreateBatchPayloadGen,
  MrfiktivVehicleControllerGetAllParamsGen
} from "../v1/data-contracts";
import { Vehicle } from "../v1/Vehicle";

class VehicleService {
  /**
  /**
   * The proxy.
   */
  mrfiktivProxy: Vehicle;

  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Vehicle(mrfiktivHttpClientProvider.client());
  }

  async create(partnerId: string, data: MrfiktivCreateVehicleDtoGen) {
    return (await this.mrfiktivProxy.vehicleControllerCreate(partnerId, data)).data;
  }
  async createBatch(partnerId: string, data: MrfiktivVehicleControllerCreateBatchPayloadGen) {
    return (await this.mrfiktivProxy.vehicleControllerCreateBatch(partnerId, data)).data;
  }
  async createContract(partnerId: string, vehicleId: string, data: MrfiktivCreateLeasingContractDtoGen) {
    return (await this.mrfiktivProxy.vehicleControllerCreateContract(partnerId, vehicleId, data)).data;
  }
  async createBlueprint(partnerId: string, vehicleId: string, data: MrfiktivCreateBlueprintDtoGen) {
    return (await this.mrfiktivProxy.vehicleControllerCreateBlueprint(partnerId, vehicleId, data)).data;
  }
  async createDocument(partnerId: string, vehicleId: string, data: MrfiktivCreateVehicleDocumentDtoGen) {
    return (await this.mrfiktivProxy.vehicleControllerCreateDocument(partnerId, vehicleId, data)).data;
  }
  async delete(partnerId: string, vehicleId: string) {
    return (await this.mrfiktivProxy.vehicleControllerDelete(partnerId, vehicleId)).data;
  }
  async deleteContract(partnerId: string, vehicleId: string, contractId: string) {
    return (await this.mrfiktivProxy.vehicleControllerDeleteContract(partnerId, vehicleId, contractId)).data;
  }
  async deleteDocument(partnerId: string, vehicleId: string, documentId: string) {
    return (await this.mrfiktivProxy.vehicleControllerDeleteDocument(partnerId, vehicleId, documentId)).data;
  }
  async deleteBlueprint(partnerId: string, vehicleId: string, blueprintId: string) {
    return (await this.mrfiktivProxy.vehicleControllerDeleteBlueprint(partnerId, vehicleId, blueprintId)).data;
  }
  async getAll(query: MrfiktivVehicleControllerGetAllParamsGen) {
    return (await this.mrfiktivProxy.vehicleControllerGetAll(query)).data;
  }
  async getOne(partnerId: string, vehicleId: string) {
    return (await this.mrfiktivProxy.vehicleControllerGetOne(partnerId, vehicleId)).data;
  }
  async update(partnerId: string, vehicleId: string, data: MrfiktivUpdateVehicleDtoGen) {
    return (await this.mrfiktivProxy.vehicleControllerUpdate(partnerId, vehicleId, data)).data;
  }
  async updateState(partnerId: string, vehicleId: string, data: MrfiktivUpdateVehicleStateDtoGen) {
    return (await this.mrfiktivProxy.vehicleControllerUpdateVehicleState(partnerId, vehicleId, data)).data;
  }
  async updateContract(
    partnerId: string,
    vehicleId: string,
    contractId: string,
    data: MrfiktivUpdateLeasingContractDtoGen
  ) {
    return (await this.mrfiktivProxy.vehicleControllerUpdateContract(partnerId, vehicleId, contractId, data)).data;
  }
  async updateBlueprint(
    partnerId: string,
    vehicleId: string,
    blueprintId: string,
    data: MrfiktivUpdateBlueprintDtoGen
  ) {
    return (await this.mrfiktivProxy.vehicleControllerUpdateContract(partnerId, vehicleId, blueprintId, data)).data;
  }
  async updateDocument(
    partnerId: string,
    vehicleId: string,
    documentId: string,
    data: MrfiktivCreateVehicleDocumentDtoGen
  ) {
    return (await this.mrfiktivProxy.vehicleControllerUpdateDocument(partnerId, vehicleId, documentId, data)).data;
  }
  async updateRegistration(partnerId: string, vehicleId: string, data: MrfiktivUpdateVehicleRegistrationDtoGen) {
    return (await this.mrfiktivProxy.vehicleControllerUpdateRegistration(partnerId, vehicleId, data)).data;
  }
}

export default new VehicleService(new MrfiktivHttpClientProvider());
