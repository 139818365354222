






import { Component, Vue } from "vue-property-decorator";
import LoginCardUnauthorized from "@/components/login/LoginCardUnauthorized.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";

@Component({
  components: {
    TheLayoutPortal,
    LoginCardUnauthorized
  }
})
export default class PartnerPermissions extends Vue {}
