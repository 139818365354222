
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class ReportStepMixin extends Vue {
  screen?: ReportScreenEnum;
  setCurrentStep() {
    if (this.screen) {
      ReportModule.setStep(this.screen);
    }
  }

  activateConfirmingRefresh() {
    window.onbeforeunload = function() {
      return confirm("Confirm refresh");
    };
  }

  deactivateConfirmingRefresh() {
    window.onbeforeunload = null;
  }

  mounted() {
    this.setCurrentStep();
    this.activateConfirmingRefresh();
  }

  destroyed() {
    this.deactivateConfirmingRefresh();
  }
}
