import {
  MrfiktivPageViewModelGen,
  MrfiktivSignUpUserDtoGen,
  MrfiktivUserControllerFindAllPaginatedParamsGen,
  MrfiktivUserViewmodelGen
} from "../mrfiktiv/v1/data-contracts";
import {
  ThgPageViewModelGen,
  ThgUserControllerFindAllPaginatedParamsGen,
  ThgUserControllerFindallParamsGen,
  ThgUserViewmodelGen
} from "../thg/v1/data-contracts";
/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { ConfigModule } from "@/store/modules/config";
import { User as MrfiktivUser } from "../mrfiktiv/v1/User";
import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";
import { ThgSignUpUserDtoGen } from "../thg/v1/data-contracts";
import { User as ThgUser } from "../thg/v1/User";

/**
 * Service to communicate with `/user`, shared by different backends/apps.
 */
class AdminSharedUserService {
  /**
   * The proxy for mrfiktiv backend.
   */
  mrfiktivProxy: MrfiktivUser;

  /**
   * The proxy for thg backend.
   */
  thgProxy: ThgUser;

  /**
   * @class Initialize AdminSharedUserService
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivUser(mrfiktivHttpClientProvider.client());
    this.thgProxy = new ThgUser(thgHttpClientProvider.client());
  }

  async signUp(
    data: MrfiktivSignUpUserDtoGen | ThgSignUpUserDtoGen
  ): Promise<MrfiktivUserViewmodelGen | ThgUserViewmodelGen | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT || ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.userControllerSignUp(data)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.THG || ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.userControllerSignUp(data)).data;
    }
  }

  async getAll(query: ThgUserControllerFindallParamsGen): Promise<ThgUserViewmodelGen[] | undefined> {
    if (ConfigModule.appContext === AppContextEnum.REPORT || ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      throw new Error("Not yet implemented");
    }

    if (ConfigModule.appContext === AppContextEnum.THG || ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.userControllerFindall(query)).data;
    }
  }

  async getAllPaginated(
    query: MrfiktivUserControllerFindAllPaginatedParamsGen | ThgUserControllerFindAllPaginatedParamsGen
  ): Promise<MrfiktivPageViewModelGen | ThgPageViewModelGen> {
    if (ConfigModule.appContext === AppContextEnum.REPORT || ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.userControllerFindAllPaginated(query)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.THG || ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.userControllerFindAllPaginated(query)).data;
    }

    throw new Error("Not yet implemented");
  }
}

export default new AdminSharedUserService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
