


























import Notifications from "@/components/thg/Notifications.vue";
import ProfileCard from "@/components/profile/ProfileCard.vue";
import Debug from "@/components/utility/Debug.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport, ProfileCard, Debug, Notifications }
})
export default class UserProfile extends Vue {
  isLoading = false;

  get User() {
    return UserModule.user;
  }

  async mounted() {
    try {
      this.isLoading = true;
      await UserModule.getCurrentUser();
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.isLoading = false;
    }
  }
}
