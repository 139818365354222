





















































import FilterSelection from "@/components/filter/FilterSelection.vue";
import { PaginationFilterListElement } from "@/store/modules/base-pagination.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import CustomFieldValueChip from "../report/CustomFieldValueChip.vue";
import RefsUser from "../utility/RefsUser.vue";
import RefsVehicle from "../utility/RefsVehicle.vue";
import Tooltip from "../utility/tooltip.vue";
import FilterCardPaginationMenu from "./FilterCardPaginationMenu.vue";
import FilterCardPaginationValueField from "./FilterCardPaginationValueField.vue";
import { $t } from "@/lib/utility/t";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    FilterSelection,
    RefsUser,
    RefsVehicle,
    CustomFieldValueChip,
    FilterCardPaginationValueField,
    Tooltip,
    FilterCardPaginationMenu
  }
})
export default class FilterCardPagination extends Vue {
  @Prop()
  filterList!: PaginationFilterListElement[];

  @Prop({ default: false })
  large!: boolean;

  @Prop()
  filter!: IPageFilterElement[];

  @Prop({ default: true })
  isSerachActivated!: PaginationFilterListElement[];

  @Prop({ default: false })
  hideDivider!: boolean;

  @Prop({ default: undefined })
  predefinedFilter!: [{ name: string; filter: IPageFilterElement[] }];

  @Prop({ default: true })
  padding!: boolean;

  /**
   * Content on search bar
   */
  @Prop()
  search!: string;

  @Prop()
  isSearchLoading!: boolean;

  get _filter() {
    return this.filter;
  }

  set _filter(filter: IPageFilterElement[]) {
    this.$emit("update:filter", filter);
  }

  get filterLabel() {
    const divider = "<br/>   •  ";

    if (!this.filterList) {
      return "";
    }
    const filterTexts = this.filterList.map(filter => $t(filter.displayName ?? filter.key));

    return divider + filterTexts.join(divider);
  }

  get _search() {
    return this.search;
  }

  set _search(search: string) {
    this.$emit("update:search", search);
  }

  confirmFilter() {
    this.$emit("confirmFilter");
  }

  confirmSearch() {
    this.$emit("confirmSearch");
  }
}
