var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report', {
    attrs: {
      "disableNext": !_vm.isDone,
      "hasNext": _vm.hasNext,
      "showFooter": _vm.showFooter,
      "forceNextBtn": _vm.forceNextBtn
    },
    on: {
      "logoClick": _vm.logoClick,
      "next": _vm.next,
      "prev": _vm.prev
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12",
      "md": _vm.md
    }
  }, [_c('ReportHeader', {
    attrs: {
      "debug": _vm.debug,
      "description": _vm.description,
      "image": _vm.imageFromAssetRepository,
      "hasInstructions": _vm.hasInstructions,
      "instructionPictures": _vm.instructionPictures,
      "instructionText": _vm.instructionText,
      "instructionTitle": _vm.instructionTitle,
      "title": _vm.title,
      "imageHeight": _vm.imageHeight
    }
  }, [_vm._t("header")], 2)], 1), _c('v-col', {
    staticClass: "pt-md-8 center",
    attrs: {
      "cols": "12",
      "justify": "center",
      "md": 12 - _vm.md
    }
  }, [_vm._t("default")], 2)], 1), _c('query-params-list')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }