var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    staticClass: "backButton",
    attrs: {
      "color": _vm.color,
      "text": "",
      "disabled": _vm.isDisabled
    },
    on: {
      "click": _vm.navigate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.login.LoginComponentNavigationButton.".concat(_vm.to))) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }