export enum TokenCategoriesEnum {
  SETTING = "setting",
  CUSTOM = "custom",
  USER = "user",
  REPORT = "report",
  THG = "thg",
  PARTNER = "partner",
  BILLING = "billing",
  FORM = "form",
  REQUEST = "request",
  SIGN = "sign",
  URL = "url"
}
