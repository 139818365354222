import { render, staticRenderFns } from "./CameraApp.vue?vue&type=template&id=45584752&scoped=true&"
import script from "./CameraApp.vue?vue&type=script&lang=ts&"
export * from "./CameraApp.vue?vue&type=script&lang=ts&"
import style0 from "./CameraApp.vue?vue&type=style&index=0&id=45584752&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45584752",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAlert,VBottomSheet,VBtn,VContainer,VIcon,VImg,VSnackbar})
