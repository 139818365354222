import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IThg, Thg } from "@/models/thg.entity";

export const ThgDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IThg> {
  getIdentifier(entity: IThg): string {
    return entity.id;
  }

  protected mapEntity(entity: IThg): IThg {
    return new Thg(entity);
  }
})();
