var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vertical-nav-header d-flex items-center  pt-5 pb-2"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_c('v-img', {
    staticClass: "mx-4",
    attrs: {
      "src": _vm.logo,
      "alt": "logo",
      "max-width": "275",
      "contain": ""
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }