











import { dottedSubString } from "@/lib/utility/string-helper";
import ProjectGoToMixin from "@/views/project/mixins/ProjectGoToMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { IProject } from "@/models/project.entity";

@Component({
  components: {},
  filters: {}
})
export default class RefsProject extends mixins(ProjectGoToMixin) implements IRefDetail<IProject> {
  @Prop()
  item!: IProject;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  goToDetail() {
    if (!this.item.id) return;
    this.goTo.projectDetail({ partnerId: this.item.partnerId, projectId: this.item.id, newTab: true });
  }

  dottedSubString(text?: string) {
    if (!text) return;
    return dottedSubString(text, 24);
  }
}
