
import { Component, Prop, Vue } from "vue-property-decorator";
import { SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";

@Component({})
export default class ReportDocumentSignRequestStepperMixin extends Vue {
  @Prop()
  step!: SignRequestStepperPageEnum;

  get currentStep() {
    return this.step;
  }

  set currentStep(step: SignRequestStepperPageEnum) {
    this.isValid = true;
    this.isLoading = false;
    this.$emit("update:step", step);
  }

  @Prop()
  loading!: boolean;
  get isLoading() {
    return this.loading;
  }
  set isLoading(loading: boolean) {
    this.$emit("update:loading", loading);
  }

  @Prop()
  valid!: boolean;
  get isValid() {
    return this.valid;
  }
  set isValid(disabled: boolean) {
    this.$emit("update:valid", disabled);
  }
}
