import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import vehicleEventService from "@/services/mrfiktiv/services/vehicleEventService";
import {
  MrfiktivCreateEventDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivVehicleEventControllerGetParamsGen,
  MrfiktivVehicleEventControllerListParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { VehicleEventDataAccessLayer } from "./access-layers/vehicle-event.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { FleetAggregationModule } from "./fleet-aggregation.store";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

const EventPageDataProvider = new (class extends AbstractPageDataProvider<
  IEventUIDto,
  MrfiktivVehicleEventControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivVehicleEventControllerGetParamsGen): Promise<IPageViewModel<IEventUIDto>> {
    const res = await vehicleEventService.get({ ...query });

    const meta = res.meta;
    const data = (res.data ?? []) as IEventUIDto[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "vehicle-event-field",
  store
})
export class VehicleEventStore extends PaginatedBaseStore<IEventUIDto, MrfiktivVehicleEventControllerGetParamsGen> {
  protected _data = VehicleEventDataAccessLayer;
  protected _pageProvider = EventPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = [{ key: "_id", type: PageFilterTypes.OBJECT_ID }].map(
    f => new PaginationFilterListElement(f)
  );

  private _event: IEventUIDto | undefined = undefined;
  protected _allOverdueEvents: IEventUIDto[] = [];

  get events() {
    return this.paginationList;
  }

  // Map of recurring event id and recurring event
  private _recurringEventMap: Map<string, IEventUIDto> = new Map();

  get recurringEventList() {
    return Array.from(this._recurringEventMap.values());
  }

  get recurringEventLookup() {
    return this._recurringEventMap;
  }

  /**
   * used to show the events in the list on side or on mobile view
   */
  private _eventList: IEventUIDto[] = [];
  get eventList() {
    return this._eventList;
  }

  private _eventsCurrentMonth: IEventUIDto[] = [];
  get eventsCurrentMonth() {
    return this._eventsCurrentMonth;
  }

  get overdueEvents() {
    return this._allOverdueEvents;
  }

  @Mutation
  private _mutateEventsCurrentMonth(list: IEventUIDto[]) {
    this._eventsCurrentMonth = list;
  }

  @Mutation
  private _mutateEventList(list: IEventUIDto[]) {
    this._eventList = list;
  }

  @Mutation
  private _mutateEvent(request: IEventUIDto) {
    this._event = request;
  }

  @Mutation
  private _mutateRecurringEvents(list: IEventUIDto[]) {
    this._recurringEventMap = new Map();
    for (const event of list) {
      if (!event.id) {
        continue;
      }

      this._recurringEventMap.set(event.id, event);
    }
  }

  @Mutation
  private _mutateOverdueEvents(request: IEventUIDto[]) {
    this._allOverdueEvents = request;
  }

  @Action
  async getRecurringEvents(data: {
    partnerId: string;
    vehicleId: string;
  }): Promise<
    MrfiktivPageViewModelGen & {
      data?: IEventUIDto[] | undefined;
    }
  > {
    const filter: IPageFilterElement = new PageFilterElement({
      key: "isRecurringRoot",
      operation: "$eq",
      value: "true"
    });

    const query: MrfiktivVehicleEventControllerGetParamsGen = {
      partnerId: data.partnerId,
      vehicleId: data.vehicleId,
      filter: [filter]
    };

    const events = await vehicleEventService.get(query);
    this.context.commit("_mutateRecurringEvents", events.data);

    return events;
  }

  @Action
  async listDocuments(data: MrfiktivVehicleEventControllerListParamsGen): Promise<IEventUIDto[] | undefined> {
    const documents = await vehicleEventService.listAll({
      partnerId: data.partnerId,
      vehicleId: data.vehicleId,
      from: data.from,
      to: data.to
    });

    FleetAggregationModule.parseEvents(documents);

    if (documents) {
      this.context.commit("_mutateEventList", documents);
    } else {
      this.context.commit("_mutateEventList", []);
    }

    return documents;
  }

  @Action
  async listOverdue(data: MrfiktivVehicleEventControllerListParamsGen): Promise<IEventUIDto[] | undefined> {
    const res = await vehicleEventService.listOverdue(data.partnerId, data.vehicleId);

    this.context.commit("_mutateOverdueEvents", res);

    return res;
  }

  @Action
  async getDocumentsForCurrentMonth(
    data: MrfiktivVehicleEventControllerListParamsGen
  ): Promise<IEventUIDto[] | undefined> {
    const documents = await vehicleEventService.listAll({
      partnerId: data.partnerId,
      vehicleId: data.vehicleId,
      from: data.from,
      to: data.to
    });
    if (documents) {
      this.context.commit("_mutateEventsCurrentMonth", documents);
    } else {
      this.context.commit("_mutateEventsCurrentMonth", []);
    }

    return documents;
  }

  @Action
  async create(data: { partnerId: string; vehicleId: string; data: MrfiktivCreateEventDtoGen }) {
    const res = await vehicleEventService.create(data.partnerId, data.vehicleId, data.data);

    const event = new EventUIDto(res);
    this._data.set(event);

    return event;
  }

  @Action
  async getOne(data: { partnerId: string; vehicleId: string; eventId: string }) {
    const res = await vehicleEventService.getOne(data.partnerId, data.vehicleId, data.eventId);

    const event = new EventUIDto(res);
    this._data.set(event);

    return event;
  }

  @Action
  async update(data: {
    partnerId: string;
    vehicleId: string;
    eventId: string;
    start: number;
    data: MrfiktivCreateEventDtoGen;
  }) {
    const res = await vehicleEventService.update(data.partnerId, data.vehicleId, data.eventId, data.start, data.data);

    const event = new EventUIDto(res);
    this._data.set(event);

    return event;
  }

  @Action
  async delete(data: { partnerId: string; vehicleId: string; eventId: string; start: number }) {
    const res = await vehicleEventService.remove(data.partnerId, data.vehicleId, data.eventId, data.start);

    const event = new EventUIDto(res);
    this._data.set(event);

    return event;
  }

  @Action
  replaceInList(eventUIDto: IEventUIDto) {
    this._data.set(eventUIDto);
  }
}

export const VehicleEventModule = getModule(VehicleEventStore);
