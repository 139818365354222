






















































































import TemplateEditorPlaceholderDropdown from "@/components/template/TemplateEditorPlaceholderDropdown.vue";
import SideCard from "@/components/utility/SideCard.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { Component } from "vue-property-decorator";
import DocumentTemplateDetailSave from "./DocumentTemplateDetailSave.vue";
import DocumentTemplateDetailTokenListOptions from "./DocumentTemplateDetailTokenListOptions.vue";

@Component({
  components: { SideCard, TemplateEditorPlaceholderDropdown, Tooltip, DocumentTemplateDetailSave }
})
export default class DocumentTemplateDetailTokenListOptionsMobile extends DocumentTemplateDetailTokenListOptions {
  started = false;
}
