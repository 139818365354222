



















import { Component, Vue } from "vue-property-decorator";

import LayoutSimple from "@/layouts/LayoutSimple.vue";

@Component({
  components: { LayoutSimple }
})
export default class About extends Vue {
  length = ["Teilkasko", "Vollkasko", "Haftpflicht"];
  window = 0;
}
