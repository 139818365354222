import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { $t } from "@/lib/utility/t";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import store from "@/store/VuexPlugin";
import { PredefinedFilterType } from "@/views/event/filter/event.filter";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { SignDocumentControllerFindAllParamsGen } from "./../../services/sign/v1/data-contracts";
import { SignDocumentDataAccessLayer } from "./access-layers/sign-document.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { SignDocumentPageDataProvider } from "./page-data-provider/sign-document.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "document",
  store
})
export class DocumentPaginationStore extends PaginatedBaseStore<ISignDocument, SignDocumentControllerFindAllParamsGen> {
  protected _data = SignDocumentDataAccessLayer;
  protected _pageProvider = SignDocumentPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  get commonPredefinedFilter(): PredefinedFilterType[] {
    return [
      {
        name: $t("sign.DocumentDetail.template"),
        filter: [
          new PageFilterElement({
            key: "isTemplate",
            operation: PageFilterOperationEnum.EQUAL,
            value: true as any
          })
        ]
      },
      {
        name: $t("sign.DocumentDetail.file"),
        filter: [
          new PageFilterElement({
            key: "isTemplate",
            operation: PageFilterOperationEnum.EQUAL,
            value: false as any
          }),
          new PageFilterElement({
            key: "isSigned",
            operation: PageFilterOperationEnum.EQUAL,
            value: false as any
          })
        ]
      },
      {
        name: $t("objects.document.isSigned"),
        filter: [
          new PageFilterElement({
            key: "isSigned",
            operation: PageFilterOperationEnum.EQUAL,
            value: true as any
          })
        ]
      }
    ];
  }

  filterOptions: PaginationFilterListElement[] = SignDocument.filterables;
}

export const DocumentModule = getModule(DocumentPaginationStore);
