






import SignUpCard from "@/components/login/SignUpCard.vue";
import LayoutReport from "@/layouts/LayoutReport.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutReport, SignUpCard }
})
export default class SignUp extends Vue {
  get title() {
    return this.$t("components.login.SignUpForm.title");
  }
  get text() {
    return this.$t("components.login.SignUpForm.text");
  }

  /**
   * Try to get the Logo by priority
   */
  get logo() {
    if (PartnerModule.partner.settings?.reportSettings?.logoUrl) {
      return PartnerModule.partner.settings?.reportSettings?.logoUrl;
    }

    if (PartnerModule.partner.settings?.logoUrl) {
      return PartnerModule.partner.settings?.logoUrl;
    }

    return "https://static.mmmint.ai/app/report/schadensmeldung.png";
  }
}
