import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

/**
 * Manages the machine document
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "numberplate",
  store
})
class NumberplateStore extends VuexModule {
  _isSpecialFormat = false;

  get isSepcialFormat(): boolean {
    return this._isSpecialFormat;
  }

  /**
   *
   * @param isSepcialFormat
   */
  @Mutation
  async mutateIsSepcialFormat(isSepcialFormat: boolean) {
    this._isSpecialFormat = isSepcialFormat;
  }

  /**
   *
   * @param isSepcialFormat
   */
  @Action
  async setSepcialFormat(isSepcialFormat: boolean) {
    this.context.commit("mutateIsSepcialFormat", isSepcialFormat);
  }
}

export const NumberplateModule = getModule(NumberplateStore);
