




















import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { ConfirmActionDialog }
})
export default class TemplateDialogPasteUrl extends DarkModeHighlightMixin {
  dialog = false;

  src = "";

  valid = false;

  emitUrl() {
    this.$emit("onInput", this.src);
    this.dialog = false;
  }

  initialize() {
    this.src = "";
    this.dialog = true;
  }

  get rules() {
    const rules = [];
    rules.push(requiredRule());
    return rules;
  }
}
