














import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Vue } from "vue-property-decorator";
import { FeatureModule } from "@/store/modules/feature.store";

@Component
export default class TheAdminList extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  route(route: string) {
    this.$router.push({ name: route });
  }

  get items() {
    const items = [
      {
        icon: "mdi-file-multiple-outline",
        text: "Partner",
        link: "/partners/list"
      },
      {
        icon: "mdi-account-outline",
        text: "User",
        link: "/user"
      },
      {
        icon: "mdi-card-account-details-outline",
        text: "Fahrzeugschein",
        link: "/fahrzeugschein"
      },
      {
        icon: "mdi-api",
        text: "API",
        link: "/api"
      }
    ];

    if (FeatureModule.isTemplateEnabled) {
      items.push({
        icon: "mdi-email-edit-outline",
        text: this.$t("navigation.TheThgAdminList.templates").toString(),
        link: "/template"
      });
    }

    return items;
  }
}
