

















import { requiredRule } from "@/lib/rules/requiredRule";
import { CustomFieldCreateDtoHelper } from "@/store/modules/custom-field.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class CustomFieldText extends Vue {
  @Prop()
  value!: string;

  @Prop()
  customField!: CustomFieldCreateDtoHelper;

  @Prop()
  required!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  loading?: boolean;

  get requiredRule() {
    if (this.required) {
      return [requiredRule()];
    }
    return [];
  }

  get input(): string {
    return this.value;
  }

  set input(v: string) {
    this.$emit("input", v);
  }
}
