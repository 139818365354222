import { ConfigModule } from "@/store/modules/config";
/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import {
  MrfiktivHttpClientProvider as Api,
  MrfiktivHttpClientProvider
} from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreateSettingDtoGen as CreateSettingDto,
  MrfiktivSettingPartnerInternalViewModelGen as SettingPartnerInternalViewModel,
  MrfiktivSettingViewModelGen,
  MrfiktivUpdateSettingDtoGen as UpdateSettingDto
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";
import { Setting } from "../v1/Setting";
import { AxiosInstanceProvider } from "@/services/axios-instance.provider";
import { EmptyTokenProvider } from "@/services/empty-token.provider";

/**
 * Service to talk  to mrfiktiv setting endpoint.
 */
class SettingService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: Setting;

  /**
   * @class Initialize SettingService
   */
  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Setting(this.client); // FIXME: Refactor into Proxy
  }

  /**
   * Looks for all Settings by Partner ID
   *
   * @param partnerId the Partner whose settings are returned
   */
  async getAllSettingsByPartner(partnerId: string): Promise<SettingPartnerInternalViewModel[]> {
    const api = new Api().instance();
    // response = await this.client.settingPartnerControllerFindAllByPartner(partnerId);
    const response = await api.get(`/partner/${partnerId}/setting`);

    return response.data;
  }

  /**
   * Looks for all Settings by Partner ID
   *
   * @param partnerId the Partner whose settings are returned
   */
  async getAllPublicSettingsByPartner(partnerId: string): Promise<SettingPartnerInternalViewModel[]> {
    const client = new AxiosInstanceProvider(new EmptyTokenProvider(), ConfigModule.mrfiktivBaseUrl);
    const api = client.create();
    const response = await api.get(`/partner/${partnerId}/public-setting`);

    return response.data;
  }

  /**
   *
   * @param partnerId
   * @param settingId
   * @returns
   */
  async getPublicSettingByKey(partnerId: string, key: string): Promise<SettingPartnerInternalViewModel> {
    const client = new AxiosInstanceProvider(new EmptyTokenProvider(), ConfigModule.mrfiktivBaseUrl);
    const api = client.create();
    const response = await api.get(`/partner/${partnerId}/public-setting/${key}`);

    return response.data;
  }

  /**
   * Looks for a specific Settings by Partner ID and Setting ID
   *
   * @param partnerId the id of the partner the setting is associated with
   * @param settingId the id of the setting to be returned
   * @returns the found setting entity
   */
  async getOneSettingByPartnerAndId(partnerId: string, settingId: string): Promise<SettingPartnerInternalViewModel> {
    const api = new Api().instance();
    // response = await this.client.settingPartnerControllerFindOneByPartner(settingId, partnerId);
    const response = await api.get(`/partner/${partnerId}/setting/${settingId}`);
    return response.data;
  }

  /**
   * Deletes a specific Settings by Partner ID and Setting ID
   *
   * @param partnerId the id of the partner the setting is associated with
   * @param settingId the id of the setting to be deleted
   * @returns the deleted setting entity
   */
  async deleteSettingByPartnerIdAndSettingsID(
    partnerId: string,
    settingId: string
  ): Promise<SettingPartnerInternalViewModel> {
    const api = new Api().instance();
    // response = await this.client.settingPartnerControllerRemoveByPartner(settingId, partnerId);
    const response = await api.delete(`/partner/${partnerId}/setting/${settingId}`);

    return response.data;
  }

  /**
   * Creates a specific Settings by Partner ID and Setting ID
   *
   * @param partnerId the id of the partner the setting is associated with
   * @param settingId the id of the setting to be created
   * @returns the created setting entity
   */
  async createSetting(createSettingDto: CreateSettingDto, partnerId: string): Promise<SettingPartnerInternalViewModel> {
    const api = new Api().instance();
    // response = await this.client.settingPartnerControllerCreateForPartner(partnerId, createSettingDto);
    const response = await api.post(`/partner/${partnerId}/setting`, createSettingDto);
    return response.data;
  }

  /**
   * Updates a specific Settings by Partner ID and Setting ID
   *
   * @param partnerId the id of the partner the setting is associated with
   * @param settingId the id of the setting to be updated
   * @returns the updated setting entity
   */
  async updateSetting(
    updateSettingDto: UpdateSettingDto,
    partnerId: string,
    settingId: string
  ): Promise<SettingPartnerInternalViewModel> {
    const api = new Api().instance();
    // response = await this.client.settingPartnerControllerUpdateByPartner(settingId, partnerId, updateSettingDto);
    const response = await api.patch(`/partner/${partnerId}/setting/${settingId}`, updateSettingDto);

    return response.data;
  }

  /**
   * Get setting by key
   *
   * @param key the key of the partner the setting is associated
   */
  async getSettingByKey(partnerId: string, settingId: string): Promise<SettingPartnerInternalViewModel> {
    const api = new Api().instance();
    const response = await api.get(`/partner/${partnerId}/setting/${settingId}/byKey`);

    return response.data;
  }

  /**
   * Get setting by key
   *
   * @param key the key of the partner the setting is associated
   */
  async getAdminSettingByKey(key: string): Promise<MrfiktivSettingViewModelGen> {
    return (await this.proxy.settingControllerFindByKey(key)).data;
  }
}

export default new SettingService(new MrfiktivHttpClientProvider());
