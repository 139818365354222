var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-base', {
    attrs: {
      "progress": _vm.progress,
      "showFooter": _vm.showFooter,
      "isEndUser": true,
      "showLoginButton": _vm.showLoginButton
    }
  }, [!_vm.isHeadless ? _vm._t("banner") : _vm._e(), _c('v-container', [_c('v-card', {
    staticClass: "mx-auto  rounded-0",
    staticStyle: {
      "min-height": "600px"
    },
    attrs: {
      "flat": "",
      "max-width": "1200"
    }
  }, [_vm._t("content")], 2)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }