import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import de from "../locales/de.json";
import en from "../locales/en.json";
import fr from "../locales/fr.json";
import pl from "../locales/pl.json";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";

/**
 * Registered VueI18n for multiple languages.
 */
Vue.use(VueI18n);

/**
 * Currently supported locales via import.
 */
export const SUPPORT_LOCALES: LanguageCodeEnum[] = [
  LanguageCodeEnum.DE,
  LanguageCodeEnum.EN,
  LanguageCodeEnum.FR,
  LanguageCodeEnum.PL
];

/**
 * Maps locale to @see LocaleMessages object from json files.
 *
 * TODO: Think about Lazy loading these via @see https://vue-i18n.intlify.dev/guide/advanced/lazy.html.
 *
 * @returns {LocaleMessages} localized messages that are imported from the respective json files.
 */
function loadLocaleMessages(): LocaleMessages {
  const messages: LocaleMessages = {
    en,
    de,
    fr,
    pl
  };
  return messages;
}

/**
 * Exports the VuetifyI18n plugin.
 */
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || LanguageCodeEnum.DE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || LanguageCodeEnum.EN,
  messages: loadLocaleMessages()
});
