













































import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { formatHoursAndMinutes } from "@/lib/utility/date-helper";
import { BookingServiceBookingViewModelGen, BookingSlotViewModelGen } from "@/services/booking/v1/data-contracts";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import BookingNavigation from "./BookingNavigation.vue";
import BookingSelectedService from "./BookingSelectedService.vue";

@Component({ components: { BookingSelectedService, BookingNavigation } })
export default class BookingSlots extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  @Prop({ default: true })
  showNavigation!: boolean;

  back() {
    AvailabilityModule.setCalender();
  }

  forward() {
    AvailabilityModule.setForm();
  }

  get itemHeight() {
    if (this.isMobile) {
      return 50;
    }
    return 55;
  }

  get height() {
    if (this.isMobile) {
      return "370";
    } else {
      return "395";
    }
  }

  get selectedSlot() {
    return AvailabilityModule.selectedSlot;
  }

  set selectedSlot(slot: BookingSlotViewModelGen | undefined) {
    AvailabilityModule.setSelectedSlot(slot);
  }

  get date() {
    return AvailabilityModule.date;
  }

  set date(newDate: string | null) {
    AvailabilityModule.setDate(newDate);
  }

  get isLoadingSlots() {
    return AvailabilityModule.isLoadingSlots;
  }

  set isLoadingSlots(value: boolean) {
    AvailabilityModule.setIsLoadingSlots(value);
  }

  get bookableServices(): BookingServiceBookingViewModelGen[] {
    return AvailabilityModule.paginationList;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get slotsForDay(): BookingSlotViewModelGen[] {
    if (!AvailabilityModule.slots) {
      return [];
    }

    // Slots for day should compare the hour as well
    const slotsForDay = AvailabilityModule.slots.filter(slot => {
      return new Date(slot.start).toISOString().substring(0, 10) === this.date && new Date(slot.start) >= new Date();
    });

    return slotsForDay;
  }

  goToServiceOverview() {
    AvailabilityModule.setServices();
  }

  selectSlot(item: BookingSlotViewModelGen) {
    this.$log.debug("selectSlot", item);
    this.selectedSlot = item;
    this.$emit("select", this.selectedSlot);
    AvailabilityModule.setForm();
  }

  formatSlotDate(slot: BookingSlotViewModelGen) {
    if (slot?.start) {
      const slotTime = new Date(slot.start);
      return formatHoursAndMinutes(slotTime);
    }
  }
}
