var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('login-component-layout', {
    attrs: {
      "component": "LoginCardForgotPassword"
    }
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.login.LoginCardForgotPassword.description")))])]), _c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('login-component-input-mail'), _c('login-component-message', {
    attrs: {
      "failText": _vm.failText,
      "successText": _vm.successText
    }
  }), _c('login-component-submit-button', {
    attrs: {
      "isLoading": _vm.isLoading,
      "isFormValid": !_vm.isButtonDisabled,
      "component": "LoginCardForgotPassword"
    }
  }), _c('login-component-navigation-button', {
    attrs: {
      "to": "/login",
      "isDisabled": _vm.isFormSubmitted
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }