import { Filter } from "@/lib/filterable";
import { MrfiktivLeasingDetailsGen } from "@/services/mrfiktiv/v1/data-contracts";

class LeasingDetailsBase implements MrfiktivLeasingDetailsGen {
  isLeasing: boolean;
  company?: string | undefined;
  claimNumber?: string | undefined;

  constructor(leasingDetails?: Partial<MrfiktivLeasingDetailsGen>) {
    this.isLeasing = leasingDetails?.isLeasing ?? false;
    this.company = leasingDetails?.company;
    this.claimNumber = leasingDetails?.claimNumber;
  }
}

type ILeasingDetails = LeasingDetailsBase;
const LeasingDetails = Filter.createForClass(LeasingDetailsBase);

export { ILeasingDetails, LeasingDetails };
