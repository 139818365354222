/**
 * Generate a random code
 *
 * 62*62*62*62*62 = 916.132.832 opportunities
 *
 * @returns a generate a random code
 */
export function generateCode(): string {
  let result = "";
  const codeLenght = 5;
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < codeLenght; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
