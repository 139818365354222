
import { Vue, Component } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";
import { LoginModule } from "@/store/modules/login.store";

export interface IFailureCodeContent {
  message?: string;
  onFail?: () => void;
}

@Component({})
export default class LoginMixin extends Vue {
  /**
   * disables submission
   */
  setFormInvalid() {
    this.isFormValid = false;
  }
  /**
   * shows loading animation on submit button
   */
  isLoading = false;

  /**
   * Current value of mail input
   */
  get mail() {
    return LoginModule.mail;
  }

  /**
   * Current value of code input
   */
  get code() {
    return LoginModule.code;
  }

  /**
   * Current value of password input
   */
  get password() {
    return LoginModule.password;
  }

  /**
   * Check if submit button is disabled
   * Disabled when the form is not filled out correctly
   * Disabled when form was submitted
   */
  get isButtonDisabled() {
    return !this.isFormValid || this.isFormSubmitted;
  }

  /**
   * Are all requirements to the inputs of the form satisfied
   */
  isFormValid = false;

  /**
   * Has form been submitted
   */
  isFormSubmitted = false;

  /**
   * Check if user is logged in by calling UserModule.getUserInfo();
   * if call fails, user is not logged in
   * if it works, user is logged in with valid information, and next page can be opened
   * called after form is submitted and once login component is mounted
   */
  async checkIfUserIsLoggedInAndRedirect() {
    if (UserModule.authAccessTokens?.accessToken) {
      try {
        await UserModule.getUserInfo();
        // redirect sends the user to the previous page selected page, e.g. if a user needs to authenticate this redirects after a successful login to the page the user wanted to go initially.
        if (LoginModule.redirect) {
          this.$log.info(`Redirect to ${LoginModule.redirect}`);
          const redirect = LoginModule.redirect;
          LoginModule.setRedirect("");
          this.$router.push({ path: redirect });
          return;
        }

        // Callback function that is defined in the views to redirect the user after a successful login. Can be used to go to the overview, a create view, etc.
        if (LoginModule.onLogin) {
          this.$log.info("onLogin");
          await LoginModule.onLogin();
        } else {
          this.$log.info("default");
          this.$router.push({ path: "/" });
        }
      } catch (error) {
        if (this.isLogInSubmittedSuccessful) {
          // only toast a fail of get user info, when user submitted the signin form previously, since this call is made every tim the login page mounts to see if the user already has valid tokens
          this.$log.error(error);
          this.$toast.error(this.$t("components.login.LoginErrorMixin.failUndefined"));
          this.isLogInSubmittedSuccessful = false;
        }
        Vue.$log.info("User not signed in");
      }
    }
  }

  /**
   * adjusts height of login card, so that it doesn't fill the complete height
   */
  adjustLoginContainerHeight() {
    const loginContainer = document.getElementById("loginContainer");
    const loginInner = document.getElementById("loginInner");
    if (loginContainer && loginInner) {
      loginContainer.style.height = `${loginInner.clientHeight + 30}px`;
    }
  }

  /**
   * Set to true after user submitted the login form
   */
  isLogInSubmittedSuccessful = false;

  mounted() {
    this.adjustLoginContainerHeight();
  }
}
