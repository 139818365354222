import { Api } from "@/services/fahrzeugschein/api";
import { SessionEntity } from "@/models/sessionEntity";
import { ErrorLogModule } from "@/store/modules/error-log";
import Vue from "vue";

class SessionService {
  ROUTE = "session";
  async getAll() {
    const api = new Api().instance();

    try {
      const response = await api.get(`/${this.ROUTE}` + Api.getToken());
      const uniqueSessions: SessionEntity[] = response.data as SessionEntity[];
      return uniqueSessions;
    } catch (error) {
      Vue.$log.error(error);
      ErrorLogModule.addErrorLog(error);
      throw error;
    }
  }

  sortSessions(sessions: SessionEntity[]) {
    return sessions.sort(function(firstSession: SessionEntity, secondSession: SessionEntity) {
      if (firstSession.created === "") {
        return 1;
      } else if (secondSession.created === "") {
        return -1;
      }
      return new Date(secondSession.created).getTime() - new Date(firstSession.created).getTime();
    });
  }
}

export default new SessionService();
