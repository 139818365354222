import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IThg, Thg, ThgPartner } from "@/models/thg.entity";
import chargingStationAdminService from "@/services/thg/services/chargingStationAdminService";
import meterReadingAdminService from "@/services/thg/services/meterReadingAdminService";
import meterReadingService from "@/services/thg/services/meterReadingService";
import thgService from "@/services/thg/services/thgService";
import {
  ThgBankingViewModelGen,
  ThgCalculateEmissionSavingsDtoGen,
  ThgChargingStationProofDtoGen,
  ThgCreateAnonymousChargingStationDtoGen,
  ThgCreateThgMeterReadingAdminDtoGen,
  ThgCreateThgPartnerQuickCheckoutDtoGen,
  ThgDecommissionThgDtoGen,
  ThgDuplicateThgViewModelGen,
  ThgEditThgImageDataDtoGen,
  ThgPageViewModelGen,
  ThgRegistrationDtoGen,
  ThgThgControllerFindAllParamsGen,
  ThgThgMeterReadingViewModelGen,
  ThgTransferChargingStationDtoGen,
  ThgTransferThgDtoGen,
  ThgTransferThgMeterReadingDtoGen,
  ThgUpdateThgAdminDtoGen,
  ThgUpdateThgRegistrationImagesDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import Vue from "vue";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { ThgStatusEnum } from "../enum/thg/thg-status.enum";
import { IUpdateThgMeterReadingDto, IUpdateThgStatusDto } from "../interface/thg/thg-update-status.interface";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ThgChargingStationViewModelGen } from "./../../services/thg/v1/data-contracts";
import { ThgDataAccessLayer } from "./access-layers/thg.access-layer";
import { PaginationFilterListElement, PaginationFilterOperationEnum } from "./base-pagination.store";
import { ChargingStationAdminModule } from "./charging-station-admin.store";
import { ClipperModule } from "./clipper.store";
import { MeterReadingPaginatedModule } from "./meter-reading-paginated.store";
import { PartnerModule } from "./partner";
import { ThgCreateModule } from "./thg.create.store";
import { AdminUserPaginationModule } from "./admin-user-pagination.store";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

export class ThgPartnerPageDataProvider extends AbstractPageDataProvider<IThg, ThgThgControllerFindAllParamsGen> {
  itemsPerPage = 25;

  async getPage(query: ThgThgControllerFindAllParamsGen): Promise<IPageViewModel<IThg>> {
    const res = (await thgService.getAllThgs(query)) as ThgPageViewModelGen & { data?: IThg[] | undefined };

    const meta = res.meta;
    const data = res.data ?? [];

    return { meta, data };
  }
}

/**
 * Store for partners
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "thgPortal",
  store
})
export class ThgPortalStore extends PaginatedBaseStore<IThg, ThgThgControllerFindAllParamsGen> {
  _data = ThgDataAccessLayer;
  _pageProvider = new ThgPartnerPageDataProvider();
  _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = [
    { key: "timestamp.created", type: PageFilterTypes.DATE },
    { key: "_id", type: PageFilterTypes.OBJECT_ID },
    { key: "userId", type: PageFilterTypes.OBJECT_ID },
    { key: "partnerId", type: PageFilterTypes.OBJECT_ID },
    { key: "status", type: PageFilterTypes.ENUM, config: { items: Object.values(ThgStatusEnum) } },
    { key: "impactType", type: PageFilterTypes.ENUM, config: { items: Object.values(ImpactTypeEnum) } },
    { key: "impactFactor", type: PageFilterTypes.NUMBER },
    { key: "code", type: PageFilterTypes.STRING },
    { key: "campaign", type: PageFilterTypes.STRING },
    { key: "year", type: PageFilterTypes.NUMBER },
    { key: "isBillingInformationComplete", type: PageFilterTypes.BOOLEAN },
    { key: "numberplate", type: PageFilterTypes.STRING },
    { key: "isMultiyear", type: PageFilterTypes.BOOLEAN },
    { key: "countryCode", type: PageFilterTypes.ENUM, config: { items: Object.values(CountryCodeEnum) } },
    { key: "registration.firstname", type: PageFilterTypes.STRING },
    { key: "registration.name", type: PageFilterTypes.STRING },
    { key: "registration.street", type: PageFilterTypes.STRING },
    { key: "registration.zipCode", type: PageFilterTypes.STRING },
    { key: "registration.city", type: PageFilterTypes.STRING },
    { key: "registration.huYear", type: PageFilterTypes.STRING },
    { key: "registration.huMonth", type: PageFilterTypes.STRING },
    { key: "registration.manufacturerNameCode", type: PageFilterTypes.STRING },
    { key: "registration.manufacturerTypeCode", type: PageFilterTypes.STRING },
    { key: "registration.driveTyp", type: PageFilterTypes.STRING },
    { key: "registration.identificationnumber", type: PageFilterTypes.STRING },
    { key: "registration.numberplate", type: PageFilterTypes.STRING },
    { key: "registration.firstregistrationDay", type: PageFilterTypes.STRING },
    { key: "registration.firstregistrationMonth", type: PageFilterTypes.STRING },
    { key: "registration.firstregistrationYear", type: PageFilterTypes.STRING },
    { key: "registration.manufacturerName", type: PageFilterTypes.STRING },
    { key: "registration.manufacturerType", type: PageFilterTypes.STRING },
    { key: "registration.vehicleClass", type: PageFilterTypes.STRING }
  ].map(f => new PaginationFilterListElement(f));

  /**
   * All fetched THGs
   */
  // private _allThgs: IThg[] = [];

  get allThgs(): IThg[] {
    return this.paginationList;
  }

  /**
   * true when _allThgs were loaded
   */
  private _allThgsLoading = true;

  get allThgsLoading(): boolean {
    return this._allThgsLoading;
  }

  /**
   * The selected Thg
   */
  private _selectedThg: IThg = {} as any;

  get selectedThg(): IThg {
    return this._selectedThg;
  }

  /**
   * The duplicate Thg
   */
  private _duplicateThg: ThgDuplicateThgViewModelGen[] = [];

  get duplicateThg(): ThgDuplicateThgViewModelGen[] {
    return this._duplicateThg;
  }

  /**
   * The Bankinginformation of the selected Thg
   */
  private _bankingInformation: ThgBankingViewModelGen = {} as any;

  get bankingInformation() {
    return this._bankingInformation;
  }

  /**
   * is true if there are thg work items that have to be labeled
   */
  private _hasNextWorkItem = true;
  get hasNextWorkItem(): boolean {
    return this._hasNextWorkItem;
  }

  /**
   * is true if the labeling process is started
   */
  private _isWorkStarted = false;

  get isWorkStarted(): boolean {
    return this._isWorkStarted;
  }

  /**
   * the thg workitem
   */
  private _thgWorkitem: IThg | ThgThgMeterReadingViewModelGen = {} as any;

  /**
   * get the current thg workitem
   */
  get thgWorkitem(): IThg | ThgThgMeterReadingViewModelGen {
    return this._thgWorkitem;
  }

  /**
   * filter for Workitems, default set to not austria
   */
  protected _workItemFilter: IPageFilterElement[] = [
    new PageFilterElement({
      key: "countryCode",
      operation: PaginationFilterOperationEnum.NOT_EQUAL,
      value: CountryCodeEnum.austria
    })
  ];

  get workItemFilter(): IPageFilterElement[] {
    return this._workItemFilter;
  }

  protected _workItemFilterList: PaginationFilterListElement[] = [
    { key: "userId", type: PageFilterTypes.OBJECT_ID },
    { key: "partnerId", type: PageFilterTypes.OBJECT_ID },
    { key: "status", type: PageFilterTypes.ENUM, config: { items: Object.values(ThgStatusEnum) } },
    { key: "impactType", type: PageFilterTypes.ENUM, config: { items: Object.values(ImpactTypeEnum) } },
    { key: "impactFactor", type: PageFilterTypes.NUMBER },
    { key: "code", type: PageFilterTypes.STRING },
    { key: "campaign", type: PageFilterTypes.STRING },
    { key: "year", type: PageFilterTypes.NUMBER },
    { key: "isBillingInformationComplete", type: PageFilterTypes.BOOLEAN },
    { key: "numberplate", type: PageFilterTypes.STRING },
    { key: "isMultiyear", type: PageFilterTypes.BOOLEAN },
    { key: "countryCode", type: PageFilterTypes.ENUM, config: { items: Object.values(CountryCodeEnum) } }
  ].map(f => new PaginationFilterListElement(f));

  get workItemFilterList(): PaginationFilterListElement[] {
    return this._workItemFilterList;
  }

  /**
   * The amount of the thg quote
   */
  private _amountOfCredit = 350;

  /**
   * Get the amount of credit
   */
  get amountOfCredit() {
    return this._amountOfCredit;
  }

  private _isLoadingProofs = false;

  get isLoadingProofs(): boolean {
    return this._isLoadingProofs;
  }

  @Mutation
  _mutateIsLoadingProofs(isLaoding: boolean) {
    this._isLoadingProofs = isLaoding;
  }

  @Action
  async uploadProofAdmin(dto: { data: ThgChargingStationProofDtoGen; thgId: string; partnerId: string }) {
    const thg = await thgService.uploadProofAdmin(dto);
    this.context.commit("_mutateSelectedThg", thg);
    return thg;
  }

  @Action
  async setProofLoading(isLoading: boolean) {
    this.context.commit("_mutateIsLoadingProofs", isLoading);
  }

  @Action
  setWorkItemFilter(filter: IPageFilterElement[]) {
    this.context.commit("_mutateWorkItemFilter", filter);
  }

  @Mutation
  _mutateWorkItemFilter(filter: IPageFilterElement[]) {
    this._workItemFilter = filter;
  }

  @Mutation
  _mutateAmountOfCredit(value: number) {
    this._amountOfCredit = value;
  }

  @Action
  setAmountOfCredit(value: number) {
    this.context.commit("_mutateAmountOfCredit", value);
  }

  @Mutation
  _mutateBankingInformation(bankingInformation: ThgBankingViewModelGen) {
    this._bankingInformation = bankingInformation;
  }

  @Action
  async getBankingInformation(): Promise<ThgBankingViewModelGen> {
    this.context.commit("_mutateBankingInformation", {} as any);
    const bankingViewmodel: ThgBankingViewModelGen = await thgService.getBankingInformation(
      this.selectedThg.partnerId,
      this.selectedThg.id
    );
    this.context.commit("_mutateBankingInformation", bankingViewmodel);
    return bankingViewmodel;
  }

  @Mutation
  _mutateIsWorkStarted(value: boolean) {
    this._isWorkStarted = value;
  }

  @Action
  updateIsWorkStarted(value: boolean) {
    this.context.commit("_mutateIsWorkStarted", value);
  }

  @Mutation
  _mutateHasNextWorkItem(value: boolean) {
    this._hasNextWorkItem = value;
  }

  @Action
  updateHasNextWorkItem(value: boolean) {
    this.context.commit("_mutateHasNextWorkItem", value);
  }

  @Mutation
  _mutateAllThgsLoading(loading: boolean) {
    this._allThgsLoading = loading;
  }

  @Action
  setAllThgsLoading(loading: boolean) {
    this.context.commit("_mutateAllThgsLoading", loading);
  }

  @Action
  async getThgByPartnerId(partnerId: string) {
    this.context.commit("_mutateSelectedThg", {});
    await this.fetchAll({ partnerId });
  }
  @Mutation
  _mutateSelectedThg(thg: IThg) {
    this._selectedThg = thg;
  }

  @Action
  async getSelectedThg(thgId: string): Promise<IThg> {
    const thg = await new Thg({ id: thgId, partnerId: PartnerModule.partner.id || PartnerModule.partner._id }).fetch();
    this.context.commit("_mutateSelectedThg", thg);

    return thg;
  }

  @Action
  async getMany(thgIds: string[]): Promise<IThg[]> {
    const res = await thgService.getMany(thgIds);
    const thgs = res.map(t => {
      const thg = new Thg(t);
      this._data.set(thg);

      return thg;
    });

    return thgs;
  }

  @Action
  async getDuplicateThg(year?: number): Promise<ThgDuplicateThgViewModelGen[]> {
    const dupThgs: ThgDuplicateThgViewModelGen[] = await thgService.getThgDup(year);

    this.context.commit("_mutateDuplicateThgs", dupThgs);
    return dupThgs;
  }

  @Mutation
  _mutateDuplicateThgs(dupThgs: ThgDuplicateThgViewModelGen[]) {
    this._duplicateThg = dupThgs;
  }

  @Action
  async getSelectedThgByPartnerID(data: { thgId: string; partnerId: string }): Promise<IThg> {
    const selectedThg = await thgService.getThgByPartnerId(data.thgId, data.partnerId);
    const thg = new ThgPartner(selectedThg);
    this._data.set(thg);
    this.context.commit("_mutateSelectedThg", thg);
    return thg;
  }

  @Mutation
  _mutateThgWorkitem(thg: IThg | ThgThgMeterReadingViewModelGen) {
    this._thgWorkitem = thg;
  }

  @Action
  setThgWorkItem(thg: IThg | ThgThgMeterReadingViewModelGen | null) {
    this.context.commit("_mutateThgWorkitem", thg);
  }

  @Action
  async getThgAsWorkItem(): Promise<IThg> {
    const res = await thgService.getThgWorkItem({
      filter: this._workItemFilter
    });

    const thgWorkitem: IThg = new ThgPartner(res);
    if (res) {
      this._data.set(thgWorkitem);
      ThgPortalModule.updateHasNextWorkItem(true);
      await PartnerModule.getPartnerById(thgWorkitem.partnerId);
      ClipperModule.initializeForThg(thgWorkitem);
      this.context.commit("_mutateThgWorkitem", thgWorkitem);
    } else {
      ThgPortalModule.updateHasNextWorkItem(false);
      Vue.$toast.info("Keine weiteren Workitems.");
    }

    return thgWorkitem;
  }

  @Action
  async updateThgWorkitemStatus(updateStatusStoreDto: IUpdateThgStatusDto) {
    await thgService.updateStatus(
      this._thgWorkitem.partnerId,
      this._thgWorkitem.id,
      updateStatusStoreDto.dto,
      updateStatusStoreDto.noUserNotification
    );
  }

  @Action
  async updateWorkitemRegistration(updateRegistration: ThgRegistrationDtoGen): Promise<IThg> {
    const res = await thgService.updateRegistration(
      this.thgWorkitem.partnerId,
      this.thgWorkitem.id,
      updateRegistration
    );

    const selectedThg = new ThgPartner(res);
    this._data.set(selectedThg);
    this.context.commit("_mutateSelectedThg", selectedThg);

    return selectedThg;
  }

  @Action
  async updateSelectedThgStatus(updateStatusStoreDto: IUpdateThgStatusDto): Promise<IThg> {
    const res = await thgService.updateStatus(
      this.selectedThg.partnerId,
      this.selectedThg.id,
      updateStatusStoreDto.dto,
      updateStatusStoreDto.noUserNotification
    );
    const selectedThg = new ThgPartner(res);
    this._data.set(selectedThg);
    this.context.commit("_mutateSelectedThg", selectedThg);

    return selectedThg;
  }

  @Action
  async updateThg(dto: ThgUpdateThgAdminDtoGen): Promise<IThg> {
    const res = await thgService.updateThg(this.selectedThg.partnerId, this.selectedThg.id, dto);

    const selectedThg = new ThgPartner(res);
    this._data.set(selectedThg);
    this.context.commit("_mutateSelectedThg", selectedThg);

    return selectedThg;
  }

  @Action
  async calculateEmissionSavings(dto: ThgCalculateEmissionSavingsDtoGen): Promise<IThg> {
    const res = await thgService.calculateEmissionSavings(this.selectedThg.partnerId, this.selectedThg.id, dto);

    const selectedThg = new ThgPartner(res);
    this._data.set(selectedThg);
    this.context.commit("_mutateSelectedThg", selectedThg);

    return selectedThg;
  }

  @Action
  async decomission(dto: ThgDecommissionThgDtoGen): Promise<IThg> {
    const res = await thgService.decommission(this.selectedThg.partnerId, this.selectedThg.id, dto);

    const selectedThg = new ThgPartner(res);
    this._data.set(selectedThg);
    this.context.commit("_mutateSelectedThg", selectedThg);

    return selectedThg;
  }

  @Action
  async transfer(data: { thgId: string; partnerId: string; dto: ThgTransferThgDtoGen }): Promise<IThg> {
    const res = await thgService.transfer(data.partnerId, data.thgId, data.dto);

    const selectedThg = new ThgPartner(res);
    this._data.set(selectedThg);
    this.context.commit("_mutateSelectedThg", selectedThg);

    return selectedThg;
  }

  @Action
  async updateRegistration(updateRegistration: ThgRegistrationDtoGen): Promise<IThg> {
    const res = await thgService.updateRegistration(
      this.selectedThg.partnerId,
      this.selectedThg.id,
      updateRegistration
    );

    const selectedThg = new ThgPartner(res);
    this._data.set(selectedThg);
    this.context.commit("_mutateSelectedThg", selectedThg);

    return selectedThg;
  }

  @Action
  async updateRegistrationImages(): Promise<IThg> {
    const thgUpdateRegistrationImagesDto: ThgUpdateThgRegistrationImagesDtoGen = {
      registrationImages: []
    };

    for (const image of ThgCreateModule.registrationImageFront) {
      thgUpdateRegistrationImagesDto.registrationImages.push(image.uploadId);
    }

    for (const image of ThgCreateModule.registrationImageBack) {
      thgUpdateRegistrationImagesDto.registrationImages.push(image.uploadId);
    }

    const res = await thgService.updateRegistrationImages(
      this.selectedThg.partnerId,
      this.selectedThg.id,
      thgUpdateRegistrationImagesDto
    );

    const selectedThg = new ThgPartner(res);
    this._data.set(selectedThg);
    this.context.commit("_mutateSelectedThg", selectedThg);

    ThgCreateModule.resetThg();

    return selectedThg;
  }

  @Action
  setSelectedThg(thg: IThg) {
    this.context.commit("_mutateSelectedThg", thg);
  }

  /**
   * All fetched Charging Stations
   */
  private _allChargingStations: ThgChargingStationViewModelGen[] = [];

  get allChargingStations(): ThgChargingStationViewModelGen[] {
    return this._allChargingStations;
  }

  /**
   * The selected Thg
   */
  private _selectedChargingStation: ThgChargingStationViewModelGen = {} as any;

  get selectedChargingStation(): ThgChargingStationViewModelGen {
    return this._selectedChargingStation;
  }

  @Mutation
  _mutateSelectedChargingStation(chargingStation: ThgChargingStationViewModelGen) {
    this._selectedChargingStation = chargingStation;
  }

  @Action
  setSelectedChargingStation(chargingStation: ThgChargingStationViewModelGen) {
    this.context.commit("_mutateSelectedChargingStation", chargingStation);
  }

  @Action
  async getSelectedChargingStation(id: string): Promise<ThgChargingStationViewModelGen> {
    const selectedChargingStation: ThgChargingStationViewModelGen = await chargingStationAdminService.get(id);
    this.context.commit("_mutateSelectedChargingStation", selectedChargingStation);
    return selectedChargingStation;
  }

  @Mutation
  _mutateAllChargingStation(allChargingStations: ThgChargingStationViewModelGen[]) {
    this._allChargingStations = allChargingStations;
  }

  @Action
  async getChargingStations() {
    this.context.commit("_mutateAllChargingStation", []);
    this.context.commit("_mutateSelectedChargingStation", {});
    const allChargingStations = (await chargingStationAdminService.getAll()) as ThgChargingStationViewModelGen[];

    allChargingStations.sort(function(a: any, b: any) {
      return a.timestamp.created > b.timestamp.created ? -1 : a.timestamp.created > b.timestamp.created ? 1 : 0;
    });

    this.context.commit("_mutateAllChargingStation", allChargingStations);
  }

  /**
   * All fetched meter readings
   */
  private _allMeterReadings: ThgThgMeterReadingViewModelGen[] = [];

  get allMeterReadings(): ThgThgMeterReadingViewModelGen[] {
    return this._allMeterReadings;
  }

  get openMeterReadings() {
    return this.allMeterReadings.filter(m => m.status === "created").length;
  }

  /**
   * true when _allMeterReadings were loaded
   */
  private _allMeterReadingsLoading = true;

  get allMeterReadingsLoading(): boolean {
    return this._allMeterReadingsLoading;
  }

  /**
   * The selected Thg
   */
  private _selectedMeterReading: ThgThgMeterReadingViewModelGen = {} as any;

  get selectedMeterReading(): ThgThgMeterReadingViewModelGen {
    return this._selectedMeterReading;
  }

  @Mutation
  _mutateSelectedMeterReading(object: ThgThgMeterReadingViewModelGen) {
    this._selectedMeterReading = object;
  }

  @Mutation
  _mutateSelectedMeterReadingInAllMeterReadings(thg: ThgThgMeterReadingViewModelGen) {
    const index = this._allMeterReadings.findIndex(listItem => listItem.id === thg.id);
    if (index != -1) {
      this._allMeterReadings.splice(index, 1, thg);
    }
  }

  @Action
  setSelectedMeterReading(object: ThgThgMeterReadingViewModelGen) {
    this.context.commit("_mutateSelectedMeterReading", object);
  }

  @Action
  async getSelectedMeterReading(id: string): Promise<ThgThgMeterReadingViewModelGen> {
    const selected: ThgThgMeterReadingViewModelGen = await meterReadingAdminService.get(id);
    this.context.commit("_mutateSelectedMeterReading", selected);
    return selected;
  }

  @Mutation
  _mutateAllMeterReading(all: ThgThgMeterReadingViewModelGen[]) {
    this._allMeterReadings = all;
  }

  @Mutation
  _mutateAllMeterReadingLoading(loading: boolean) {
    this._allMeterReadingsLoading = loading;
  }

  @Action
  setAllMeterReadingsLoading(loading: boolean) {
    this.context.commit("_mutateAllMeterReadingLoading", loading);
  }

  @Action
  async getMeterReadings() {
    this.context.commit("_mutateAllMeterReading", []);
    this.context.commit("_mutateSelectedMeterReading", {});
    const all = (await meterReadingAdminService.getAll()) as ThgThgMeterReadingViewModelGen[];

    all.sort(function(a: any, b: any) {
      return a.timestamp.created > b.timestamp.created ? -1 : a.timestamp.created > b.timestamp.created ? 1 : 0;
    });

    this.context.commit("_mutateAllMeterReading", all);
  }

  /**
   * The selected Thg
   */
  private _meterReadingForChargingStation: ThgThgMeterReadingViewModelGen[] = [];

  get meterReadingForChargingStation(): ThgThgMeterReadingViewModelGen[] {
    return this._meterReadingForChargingStation;
  }

  @Mutation
  _mutateMeterReadingForChargingStation(object: ThgThgMeterReadingViewModelGen[]) {
    this._meterReadingForChargingStation = object;
  }

  @Action
  setMeterReadingForChargingStation(object: ThgThgMeterReadingViewModelGen[]) {
    this.context.commit("_mutateMeterReadingForChargingStation", object);
  }

  @Action
  async getMeterReadingForChargingStation(id: string): Promise<ThgThgMeterReadingViewModelGen[]> {
    const selected: ThgThgMeterReadingViewModelGen[] = await meterReadingAdminService.getForChargingStation(id);
    this.context.commit("_mutateMeterReadingForChargingStation", selected);
    return selected;
  }

  @Action
  async transferMeterReading(data: {
    partnerId: string;
    meterReadingId: string;
    data: ThgTransferThgMeterReadingDtoGen;
  }) {
    const transferredMeterReading = await meterReadingAdminService.transferMeterReading(
      data.partnerId,
      data.meterReadingId,
      data.data
    );

    this.context.commit("_mutateSelectedMeterReading", transferredMeterReading);

    return transferredMeterReading;
  }

  @Action
  async transferChargingStation(data: { id: string; data: ThgTransferChargingStationDtoGen }) {
    const transferredChargingStation = await meterReadingAdminService.transferChargingStation(data.id, data.data);

    await this.getMeterReadingForChargingStation(transferredChargingStation.id);

    this.context.commit("_mutateSelectedChargingStation", transferredChargingStation);

    return transferredChargingStation;
  }

  @Action
  async updateSelectedThgMeterReadingStatus(
    updateStatusStoreDto: IUpdateThgMeterReadingDto
  ): Promise<ThgThgMeterReadingViewModelGen> {
    const selectedThg: ThgThgMeterReadingViewModelGen = await meterReadingAdminService.updateByPartner(
      updateStatusStoreDto
    );

    this.context.commit("_mutateSelectedMeterReading", selectedThg);
    this.context.commit("_mutateSelectedMeterReadingInAllMeterReadings", selectedThg);

    MeterReadingPaginatedModule.replaceInList(selectedThg);

    return selectedThg;
  }

  @Action
  async updateSelectedThgMeterReading(updateDto: IUpdateThgMeterReadingDto): Promise<ThgThgMeterReadingViewModelGen> {
    const selectedThg: ThgThgMeterReadingViewModelGen = await meterReadingAdminService.updateByPartner(updateDto);

    this.context.commit("_mutateSelectedMeterReading", selectedThg);
    this.context.commit("_mutateSelectedMeterReadingInAllMeterReadings", selectedThg);

    MeterReadingPaginatedModule.replaceInList(selectedThg);

    return selectedThg;
  }

  /**
   * Adds image edit data to the registration images
   *
   * @param data
   * @returns
   */
  @Action
  async editSelectedRegistrationImages(data: {
    partnerId: string;
    thgId: string;
    data: ThgEditThgImageDataDtoGen;
  }): Promise<IThg> {
    const res = await thgService.editRegistrationImages(data.partnerId, data.thgId, data.data);

    const selectedThg = new ThgPartner(res);
    this._data.set(selectedThg);
    this.context.commit("_mutateSelectedThg", selectedThg);

    return selectedThg;
  }

  /**
   * Submits a new THG for a given user.
   *
   * @throws ConflictException if the user exists
   * @throws HttpException if server response is non 2xx.
   */
  @Action
  async createAnonymous(data: {
    partnerId: string;
    dto: ThgCreateAnonymousChargingStationDtoGen;
  }): Promise<ThgChargingStationViewModelGen> {
    const chargingStation: ThgChargingStationViewModelGen = await meterReadingAdminService.createAnonymous(
      data.partnerId,
      data.dto
    );

    return chargingStation;
  }

  /**
   * Submits a new THG for a given user.
   *
   * @throws HttpException if server response is non 2xx.
   */
  @Action
  async createMeterReading(data: {
    partnerId: string;
    chargingStationId: string;
    dto: ThgCreateThgMeterReadingAdminDtoGen;
  }): Promise<ThgChargingStationViewModelGen> {
    const meterReading: ThgChargingStationViewModelGen = await meterReadingAdminService.createMeterReading(
      data.partnerId,
      data.chargingStationId,
      data.dto
    );

    return meterReading;
  }

  @Action
  async getMeterReadingAsWorkItem() {
    const thgWorkItem = await meterReadingService.getWorkItem();
    if (thgWorkItem?.id) {
      this.updateHasNextWorkItem(true);
      await AdminUserPaginationModule.getSelectedUser(thgWorkItem.userId);
      await PartnerModule.getPartnerById(thgWorkItem.partnerId);
      if (thgWorkItem.meterReading.chargingStationId) {
        await ChargingStationAdminModule.getChargingStation(thgWorkItem.meterReading.chargingStationId);
        await this.getMeterReadingForChargingStation(thgWorkItem.meterReading.chargingStationId);
      }
      this.context.commit("_mutateThgWorkitem", thgWorkItem);
    } else {
      this.updateHasNextWorkItem(false);
      Vue.$toast.info("Keine weiteren Zählerstände.");
    }

    return thgWorkItem;
  }

  @Action
  async quickcheckoutThgAsAdmin(dto: {
    thgPartendId: string;
    partnerId: string;
    userNotification: boolean;
    years: number[];
  }) {
    const createDto: ThgCreateThgPartnerQuickCheckoutDtoGen = {
      impactType: ImpactTypeEnum.payout,
      impactFactor: 0,
      isConfirmed: true,
      years: dto.years
    };

    const thg = await thgService.quickCheckoutAsAdmin(dto.thgPartendId, dto.partnerId, createDto, dto.userNotification);

    return thg;
  }
}

export const ThgPortalModule = getModule(ThgPortalStore);
