import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import eventService from "@/services/mrfiktiv/services/eventService";
import {
  MrfiktivArchiveEventInstanceDtoGen,
  MrfiktivArchiveVirtualEventDtoGen,
  MrfiktivCreateEventDtoGen,
  MrfiktivEventControllerGetParamsGen,
  MrfiktivUpdateEventDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { EventDataAccessLayer } from "./access-layers/event.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { FleetAggregationModule } from "./fleet-aggregation.store";

const EventPageDataProvider = new (class extends AbstractPageDataProvider<
  IEventUIDto,
  MrfiktivEventControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivEventControllerGetParamsGen): Promise<IPageViewModel<IEventUIDto>> {
    const res = await eventService.getAll({ ...query });

    const meta = res.meta;
    const data = res.data ?? [];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "event-field",
  store
})
export class EventStore extends PaginatedBaseStore<IEventUIDto, MrfiktivEventControllerGetParamsGen> {
  protected _data = EventDataAccessLayer;
  protected _pageProvider = EventPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = [
    { key: "_id", type: PageFilterTypes.OBJECT_ID },
    { key: "userId", type: PageFilterTypes.OBJECT_ID },
    { key: "vehicleId", type: PageFilterTypes.OBJECT_ID },
    { key: "isAllDay", type: PageFilterTypes.BOOLEAN },
    { key: "isRecurringRoot", type: PageFilterTypes.BOOLEAN }
  ].map(f => new PaginationFilterListElement(f));

  get events() {
    return this.paginationList;
  }

  @Action
  protected async loadDocuments(query: MrfiktivEventControllerGetParamsGen) {
    const res = await eventService.getAll({ ...query });

    return res;
  }

  @Action
  async create(data: { partnerId: string; data: MrfiktivCreateEventDtoGen }) {
    const res = await eventService.create(data.partnerId, data.data);

    const event = new EventUIDto(res);
    this._data.set(event);
    return res;
  }

  @Action
  async delete(data: { partnerId: string; eventId: string }) {
    const res = await eventService.delete(data.partnerId, data.eventId);

    const event = new EventUIDto(res);
    this._data.delete(event);

    return event;
  }

  @Action
  async getOne(data: { partnerId: string; eventId: string }) {
    const res = await eventService.getOne(data.partnerId, data.eventId);

    const event = new EventUIDto(res);
    this._data.set(event);

    return event;
  }

  @Action
  async update(data: { partnerId: string; eventId: string; data: MrfiktivUpdateEventDtoGen }) {
    const res = await eventService.update(data.partnerId, data.eventId, data.data);

    const event = new EventUIDto(res);
    this._data.set(event);

    return event;
  }

  @Action
  async archiveMany(data: {
    partnerId: string;
    dto: (MrfiktivArchiveVirtualEventDtoGen | MrfiktivArchiveEventInstanceDtoGen)[];
  }) {
    const ress = await eventService.archiveMany(data.partnerId, data.dto);

    const events: IEventUIDto[] = [];
    for (const res of ress) {
      const event = new EventUIDto(res);
      this._data.set(event);

      events.push(event);
    }

    return events;
  }

  @Action
  async deleteMany(data: { partnerId: string; events: IEventUIDto[] }) {
    await eventService.deleteMany(data.partnerId, data.events.filter(e => e.id).map(e => e.id) as string[]);
    for (const event of data.events) {
      FleetAggregationModule.removeEvent(event);
      this._data.delete(event);
    }
  }

  @Action
  async replaceInList(eventUIDto: IEventUIDto) {
    this._data.set(eventUIDto);
  }
}

export const EventModule = getModule(EventStore);
