import { Type } from "@/lib/utility/type";

export default {
  validateValue(value: any) {
    return Type.isPresent(value) && !Type.isBlankString(value);
  },

  validateObject(object: any) {
    return Type.isPresent(object);
  },

  showValueWithDefault(value: any, backup = "") {
    return Type.isPresent(value) && !Type.isBlankString(value) ? value : backup;
  },

  emailRules(value: any) {
    // no quotations :https://stackoverflow.com/questions/46370725/how-to-do-email-validation-using-regular-expression-in-typescript
    // https://emailregex.com/
    const regex = RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    return !!value && regex.test(value);
  }
};
