















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class LoginComponentLayout extends Vue {
  @Prop()
  component!: string;
}
