





























































import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LayoutSimple }
})
export default class Imprint extends Vue {}
