import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Project } from "../v1/Project";
import {
  MrfiktivCreateProjectDtoGen,
  MrfiktivEventControllerGetParamsGen,
  MrfiktivUpdateProjectDtoGen
} from "../v1/data-contracts";

/**
 * Communicate with project API
 */
class ProjectService {
  mrfiktivProxy: Project;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Project(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an project
   */
  async create(partnerId: string, data: MrfiktivCreateProjectDtoGen) {
    return (await this.mrfiktivProxy.projectControllerCreate(partnerId, data)).data;
  }

  /**
   * Get all projects
   */
  async getAll(data: MrfiktivEventControllerGetParamsGen) {
    return (await this.mrfiktivProxy.projectControllerGet(data)).data;
  }

  /**
   * Get a project
   */
  async getOne(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.projectControllerGetOne(partnerId, id)).data;
  }

  /**
   * Update a project
   */
  async update(partnerId: string, id: string, data: MrfiktivUpdateProjectDtoGen) {
    return (await this.mrfiktivProxy.projectControllerUpdate(partnerId, id, data)).data;
  }

  /**
   * Delete a project
   */
  async delete(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.projectControllerDelete(partnerId, id)).data;
  }
}

/**
 * Communicate with project API
 */
export default new ProjectService(new MrfiktivHttpClientProvider());
