import { render, staticRenderFns } from "./DocumentDetailCardEditor.vue?vue&type=template&id=60a7ad1a&scoped=true&"
import script from "./DocumentDetailCardEditor.vue?vue&type=script&lang=ts&"
export * from "./DocumentDetailCardEditor.vue?vue&type=script&lang=ts&"
import style1 from "./DocumentDetailCardEditor.vue?vue&type=style&index=1&id=60a7ad1a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60a7ad1a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VRow,VSkeletonLoader})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
