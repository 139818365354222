import eventBus from "@/lib/eventBus";
import { deepCopy } from "@/lib/utility/deep-copy";
import {
  MrfiktivBankingDtoGen,
  MrfiktivBankingControllerGetBankingParamsGen,
  MrfiktivBankingControllerNewBankingParamsGen
} from "@/services/mrfiktiv/v1/data-contracts";
import bankService from "@/services/shared/bankService";
import {
  ThgBankingControllerGetBankingParamsGen,
  ThgBankingControllerNewBankingParamsGen,
  ThgBankingDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "banking",
  store
})
export class BankingStore extends VuexModule {
  /**
   * Empty Banking
   */
  _emptyBanking: ThgBankingDtoGen | MrfiktivBankingDtoGen = {
    iban: "",
    bank: "",
    name: ""
  };

  /**
   * My Banking information
   */
  private _myBanking: ThgBankingDtoGen | MrfiktivBankingDtoGen = {
    name: "",
    iban: "",
    bank: ""
  };

  private _banking: ThgBankingDtoGen | MrfiktivBankingDtoGen = {
    name: "",
    iban: "",
    bank: ""
  };

  get banking(): ThgBankingDtoGen | MrfiktivBankingDtoGen {
    return this._banking;
  }

  get myBanking(): ThgBankingDtoGen | MrfiktivBankingDtoGen {
    return this._myBanking;
  }

  get emptyBanking(): ThgBankingDtoGen | MrfiktivBankingDtoGen {
    return deepCopy(this._emptyBanking);
  }

  @Mutation
  _mutateMyBanking(myBanking: ThgBankingDtoGen | MrfiktivBankingDtoGen) {
    this._myBanking = myBanking;
  }

  @Mutation
  _mutateBanking(banking: ThgBankingDtoGen | MrfiktivBankingDtoGen) {
    this._banking = banking;
  }

  @Action
  async getMyBanking() {
    const myBanking = await bankService.getMyBanking();
    this.context.commit("_mutateMyBanking", myBanking);
  }

  @Action
  async getBanking(dto: ThgBankingControllerGetBankingParamsGen | MrfiktivBankingControllerGetBankingParamsGen) {
    const banking = await bankService.getBanking(dto);
    this.context.commit("_mutateBanking", banking);
    return banking;
  }

  @Action
  async createOrUpdateMyBanking(dto: ThgBankingDtoGen | MrfiktivBankingDtoGen) {
    const myBanking = await bankService.createOrUpdateMyBanking(dto);
    this.context.commit("_mutateMyBanking", myBanking);
    eventBus.$emit("bankingUpdated");
  }

  @Action
  async createBanking(data: {
    query: ThgBankingControllerNewBankingParamsGen | MrfiktivBankingControllerNewBankingParamsGen;
    dto: ThgBankingDtoGen | MrfiktivBankingDtoGen;
  }) {
    const banking = await bankService.createBanking(data.query, data.dto);
    this.context.commit("_mutateBanking", banking);
    eventBus.$emit("bankingUpdated");
  }

  @Action
  async createBankingAsPartner(data: { partnerId: string; dto: ThgBankingDtoGen }) {
    const banking = await bankService.createBankingAsPartner(data.partnerId, data.dto);
    this.context.commit("_mutateBanking", banking);
    eventBus.$emit("bankingUpdated");
  }

  @Action
  async getBankingAsPartner(partnerId: string) {
    const banking = await bankService.getBankingAsPartner(partnerId);
    this.context.commit("_mutateBanking", banking);
  }
}

export const BankingModule = getModule(BankingStore);
