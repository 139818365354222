/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivBankingControllerGetBankingParamsGen,
  MrfiktivBankingControllerNewBankingParamsGen,
  MrfiktivBankingDtoGen,
  MrfiktivBankingViewModelGen,
  MrfiktivExceptionViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Banking<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags banking
   * @name BankingControllerGetMyBankingInformation
   * @summary Get my Banking informations
   * @request GET:/banking/me
   * @secure
   * @response `200` `MrfiktivBankingViewModelGen` The Bankinginformations of a user
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  bankingControllerGetMyBankingInformation = (params: RequestParams = {}) =>
    this.http.request<MrfiktivBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/banking/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags banking
   * @name BankingControllerCreateOrUpdateBankingMe
   * @summary Creates or Update my Bankinginformations
   * @request POST:/banking/me
   * @secure
   * @response `200` `MrfiktivBankingViewModelGen` The record has been successfully created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  bankingControllerCreateOrUpdateBankingMe = (data: MrfiktivBankingDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/banking/me`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags banking
   * @name BankingControllerNewBanking
   * @summary Creates BankingInformation as admin
   * @request POST:/banking
   * @secure
   * @response `201` `MrfiktivBankingViewModelGen` The record has been successfully created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  bankingControllerNewBanking = (
    query: MrfiktivBankingControllerNewBankingParamsGen,
    data: MrfiktivBankingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/banking`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags banking
   * @name BankingControllerGetBanking
   * @summary Gets Banking Informations as admin
   * @request GET:/banking
   * @secure
   * @response `200` `MrfiktivBankingViewModelGen` The found Record
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  bankingControllerGetBanking = (query: MrfiktivBankingControllerGetBankingParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/banking`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags banking
   * @name BankingControllerCreateOrUpdateBankingForPartner
   * @summary Create or update BankingInformation as Partner
   * @request POST:/partner/{partnerId}/banking
   * @secure
   * @response `201` `MrfiktivBankingViewModelGen` The record has been successfully created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  bankingControllerCreateOrUpdateBankingForPartner = (
    partnerId: string,
    data: MrfiktivBankingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/banking`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags banking
   * @name BankingControllerGetBankingForPartner
   * @summary Gets the Banking Information for a partner
   * @request GET:/partner/{partnerId}/banking
   * @secure
   * @response `200` `MrfiktivBankingViewModelGen` The record has been successfully created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  bankingControllerGetBankingForPartner = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivBankingViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/banking`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
