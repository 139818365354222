import { electronicFormatIBAN, isValidIBAN } from "ibantools";
import i18n from "../../plugins/I18nPlugin";

export function ibanRule(message = "report.thgContactData.noValidIban"): (value: string) => boolean | string {
  return value => validIbanFormat(value) || String(i18n.tc(message));
}

/**
 *
 * checks if an Iban is correct formated and valid
 *
 * @param iban
 */
export function validIbanFormat(iban: string): boolean | string {
  const electronicFormatedIban = electronicFormatIBAN(iban) || "";
  return isValidIBAN(electronicFormatedIban);
}
