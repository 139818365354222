import Vue, { VueConstructor } from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { routes as profileRoutes } from "./profile.router";
import Login from "@/views/Login.vue";
import { permissionGuard } from "@/auth/permissionAuthGuard";
import LoginCardEnterPassword from "@/components/login/LoginCardEnterPassword.vue";
import LoginRequestCode from "@/components/login/LoginCardConfirmMailRequestCode.vue";
import LoginCardConfirmMail from "@/components/login/LoginCardConfirmMail.vue";
import LoginCardLogout from "@/components/login/LoginCardLogout.vue";
import LoginForgot from "@/components/login/LoginCardForgotPassword.vue";
import LoginCardSet from "@/components/login/LoginCardSet.vue";
import LoginCardReset from "@/components/login/LoginCardReset.vue";
import PartnerUnauthorized from "@/views/portal/PartnerUnauthorized.vue";
import NoRoles from "@/views/NoRoles.vue";

Vue.use(VueRouter);

export function getLoginRoutes(viewComponent: VueConstructor<Vue>): RouteConfig[] {
  return [
    {
      path: "/",
      component: viewComponent,
      children: [
        {
          path: "/",
          name: "Login",
          component: LoginCardEnterPassword
        },
        {
          path: "/login",
          component: LoginCardEnterPassword
        },
        //Reset Route called when user signs in for first time after being added. initialOneTimePassword will be invalid after
        {
          path: "/login/reset/:code",
          name: "LoginCardResetWithCode",
          component: LoginCardReset
        },
        {
          path: "/login/reset",
          name: "Reset",
          component: LoginCardReset
        },
        //Set Route used when user signs in for first time after being added. initialOneTimePassword will be invalid after
        {
          path: "/login/set/:initialOneTimePassword",
          name: "SetWithInitialOneTimePassword",
          component: LoginCardSet
        },
        {
          path: "/login/set",
          name: "Set",
          component: LoginCardSet
        },
        {
          path: "/login/forgot",
          name: "Forgot",
          component: LoginForgot
        },
        {
          path: "/login/logout",
          name: "Logout",
          component: LoginCardLogout
        },
        {
          path: "/login/confirmmail",
          name: "Confirm",
          component: LoginCardConfirmMail
        },
        {
          path: "/login/requestcode",
          name: "Request",
          component: LoginRequestCode
        },
        {
          path: "/login/*",
          component: LoginCardEnterPassword
        }
      ]
    }
  ];
}

function getLoginAppRoutes(): RouteConfig[] {
  const routerRoutes: RouteConfig[] = [];
  profileRoutes.forEach(route => {
    route.path = "/profile" + route.path;
    routerRoutes.push(route);
  });
  routerRoutes.push(
    {
      path: "/unauthorized",
      name: "Unauthorized",
      component: PartnerUnauthorized
    },
    {
      path: "/no-roles",
      name: "NoRoles",
      component: NoRoles
    }
  );

  return routerRoutes.concat(getLoginRoutes(Login));
}

const router = new VueRouter({
  routes: getLoginAppRoutes(),
  base: "/"
});

router.beforeEach(permissionGuard);

export default router;
