import { Framework } from "vuetify";

export interface IColorTheme {
  primary?: string;
  secondary?: string;
  warning?: string;
  success?: string;
  error?: string;
  info?: string;
}

export interface IAppColorConfig {
  themes: {
    light?: IColorTheme;
    dark?: IColorTheme;
  };
}

export class AppColorSchema {
  theme!: IAppColorConfig;
  vuetify!: Framework;

  constructor(vuetify: Framework, theme: IAppColorConfig) {
    this.vuetify = vuetify;
    this.theme = theme;
    this.setColors();
  }

  setColors() {
    if (this.theme.themes.light?.primary) {
      this.vuetify.theme.themes.light.primary = this.theme.themes.light.primary;
    }

    if (this.theme.themes.light?.secondary) {
      this.vuetify.theme.themes.light.secondary = this.theme.themes.light.secondary;
    }

    if (this.theme.themes.light?.warning) {
      this.vuetify.theme.themes.light.warning = this.theme.themes.light.warning;
    }

    if (this.theme.themes.light?.success) {
      this.vuetify.theme.themes.light.success = this.theme.themes.light.success;
    }

    if (this.theme.themes.light?.error) {
      this.vuetify.theme.themes.light.error = this.theme.themes.light.error;
    }

    if (this.theme.themes.light?.info) {
      this.vuetify.theme.themes.light.info = this.theme.themes.light.info;
    }

    if (this.theme.themes.dark?.primary) {
      this.vuetify.theme.themes.dark.primary = this.theme.themes.dark.primary;
    }

    if (this.theme.themes.dark?.secondary) {
      this.vuetify.theme.themes.dark.secondary = this.theme.themes.dark.secondary;
    }

    if (this.theme.themes.dark?.warning) {
      this.vuetify.theme.themes.light.warning = this.theme.themes.dark.warning;
    }

    if (this.theme.themes.dark?.success) {
      this.vuetify.theme.themes.light.success = this.theme.themes.dark.success;
    }

    if (this.theme.themes.dark?.error) {
      this.vuetify.theme.themes.light.error = this.theme.themes.dark.error;
    }

    if (this.theme.themes.dark?.info) {
      this.vuetify.theme.themes.light.info = this.theme.themes.dark.info;
    }
  }
}
