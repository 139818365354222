










import { Component, Vue } from "vue-property-decorator";
import LoginComponentSubmitButton from "@/components/login/LoginComponentSubmitButton.vue";
import LoginComponentLayout from "@/components/login/LoginComponentLayout.vue";

@Component({
  components: { LoginComponentLayout, LoginComponentSubmitButton }
})
export default class LoginCardLogout extends Vue {
  /*
   * Change message for forced layout event
   */
  get reason() {
    if (this.$route.query.reason === "expired") return "LoginCardLogoutExpired";
    return "LoginCardLogout";
  }

  backToLogin() {
    this.$router.push({ path: "/login" });
  }
}
