import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
const Setting = () => import(/* webpackChunkName: "setting" */ "@/views/Setting.vue");

Vue.use(VueRouter);

export const SettingRoutes: RouteConfig[] = [
  {
    path: "/",
    name: "Settings",
    component: Setting
  }
];
