import VueI18n from "@/plugins/I18nPlugin";
import { Values } from "vue-i18n";

/**
 * Helper for internationalization
 * @param path
 * @param values
 * @returns
 */
export function $t(path: string, values?: Values | undefined): string {
  return VueI18n.t(path, values).toString();
}
