import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { IsFilterable, Filter, FilterTypes, FilterConfig } from "@/lib/filterable";
import { MrfiktivAddressGen, MrfiktivAddressWithGeoGen } from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
class AddressBase implements MrfiktivAddressGen {
  @FilterConfig({ type: FilterTypes.STRING })
  street: string;

  @FilterConfig({ type: FilterTypes.STRING })
  zip: string;

  @FilterConfig({ type: FilterTypes.STRING })
  city: string;

  @FilterConfig({ type: FilterTypes.STRING })
  state: string;

  @FilterConfig({ type: FilterTypes.ENUM, config: { items: Object.values(CountryCodeEnum) } })
  countryCode?: string | undefined;

  constructor(address?: Partial<MrfiktivAddressGen>) {
    this.street = address?.street ?? "";
    this.zip = address?.zip ?? "";
    this.city = address?.city ?? "";
    this.state = address?.state ?? "";
    this.countryCode = address?.countryCode;
  }
}
class AddressWithGeoBase extends AddressBase implements MrfiktivAddressWithGeoGen {
  geo: {
    lat: number;
    lng: number;
  };
  constructor(address?: Partial<MrfiktivAddressWithGeoGen>) {
    super(address);
    this.geo = address?.geo ?? { lat: 0, lng: 0 };
  }
}
type IAddress = AddressBase;
type IAddressWithGeo = AddressWithGeoBase;
const Address = Filter.createForClass(AddressBase);
const AddressWithGeo = Filter.createForClass(AddressWithGeoBase);

export { Address, AddressWithGeo, IAddress, IAddressWithGeo };
