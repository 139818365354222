





























import TemplateCard from "@/components/template/TemplateCard.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { CreateSignatureUrlFactory } from "@/lib/utility/createSignatureUrlFactory";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ISignRequest } from "@/models/sign-request.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";
import ReportDocumentSignRequestStepperSendMail from "./ReportDocumentSignRequestStepperSendMail.vue";

@Component({
  components: {
    ConfirmActionDialog,
    TemplateCard,
    ReportDocumentSignRequestStepperSendMail
  }
})
export default class DocumentTemplateDetailSignatureTemplateDialog extends Vue {
  @Prop()
  signRequest!: ISignRequest;

  @Prop()
  report!: MrfiktivReportViewModelGen;

  get BackendResourceEnum() {
    return BackendResourceEnum;
  }

  get source() {
    return {
      refType: BackendResourceEnum.SIGN,
      refId: this.signRequest?.id || ""
    };
  }

  url = "";

  isDialogActive = false;

  async send() {
    await (this.$refs.templateCard as ReportDocumentSignRequestStepperSendMail).next();
  }

  mounted() {
    this.url = new CreateSignatureUrlFactory(
      PartnerModule.partner.settings?.defaultUrl ?? "",
      this.signRequest.id,
      this.signRequest.recipient.firstName,
      this.signRequest.recipient.lastName
    ).build();
  }
}
