















































































import { Component, Vue, Prop } from "vue-property-decorator";
import Debug from "@/components/utility/Debug.vue";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";

@Component({
  components: { Debug }
})
export default class FilterSelection extends Vue {
  constructor() {
    super();
  }

  @Prop({ default: () => [] })
  filterList!: IFilterListElement[];

  @Prop({ default: "red" })
  closeChipColor?: string;

  @Prop({ default: "white" })
  chipFontColor?: string;

  @Prop({ default: "white" })
  closeChipFontColor?: string;

  get chipStyle() {
    return `color:${this.chipFontColor}`;
  }

  get closeChipStyle() {
    return `color:${this.closeChipFontColor}`;
  }

  remove(value: string, index: number) {
    const values = this.filterList[index].value;
    if (values) {
      const position = values.indexOf(value);
      this.filterList[index].value?.splice(position, 1);
    }
  }
}
