import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ITicket, Ticket } from "@/models/ticket.entity";

export const TicketDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ITicket, "number"> {
  getIdentifier(entity: ITicket): string {
    return (entity as any).id;
  }

  mapEntity(entity: ITicket): ITicket {
    return new Ticket(entity);
  }

  mapsConfig = {
    number: { getIdentifier: (entity: ITicket) => [entity.number.toString()] }
  };
})();
