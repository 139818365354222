








import { Component, Vue } from "vue-property-decorator";
import LoginCardAuthorizationOverview from "@/components/login/LoginCardAuthorizationOverview.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";

@Component({
  components: {
    LayoutSimple,
    LoginCardAuthorizationOverview
  }
})
export default class AuthorizationOverview extends Vue {}
