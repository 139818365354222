




import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ManufacturerModule } from "@/store/modules/manufacturer";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";

@Component({
  components: {
    TheNavigationLayout
  }
})
export default class TheDocphantList extends Vue {
  get manufacturerId() {
    return ManufacturerModule.manufacturer.id;
  }

  items: IMenuListItem[] = [
    {
      icon: "mdi-archive",
      link: "/portal/manufacturer",
      isEnabled: true,
      permission: {
        resource: ResourceEnum.MANUFACTURER,
        action: ActionEnum.READ,
        field: this.manufacturerId
      },
      text: String(this.$t("navigation.TheDocphantList.ManufacturerListPage"))
    },
    {
      icon: "mdi-plus",
      link: "/portal/manufacturer/create",
      isEnabled: true,
      permission: {
        resource: ResourceEnum.MANUFACTURER,
        action: ActionEnum.CREATE,
        field: this.manufacturerId
      },
      text: String(this.$t("navigation.TheDocphantList.ManufacturerCreationPage"))
    }
  ];

  title = String(this.$t("navigation.TheDocphantList.title"));
}
