/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivActivityLogControllerFindAllParamsGen,
  MrfiktivActivityLogViewModelGen,
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivUpdateActivityLogDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ActivityLogService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Activity Log Service
   * @name ActivityLogControllerCreate
   * @summary Create an activity entry for a partner
   * @request POST:/partner/{partnerId}/activity-log
   * @secure
   * @response `200` `MrfiktivActivityLogViewModelGen` The created activity entry
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  activityLogControllerCreate = (
    partnerId: string,
    data: MrfiktivCreateActivityLogDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivActivityLogViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/activity-log`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Activity Log Service
   * @name ActivityLogControllerFindAll
   * @summary Get all activities from a partner
   * @request GET:/partner/{partnerId}/activity-log
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivActivityLogViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  activityLogControllerFindAll = (
    { partnerId, ...query }: MrfiktivActivityLogControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivActivityLogViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/activity-log`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Activity Log Service
   * @name ActivityLogControllerFindOne
   * @summary Get an activity log entry from a partner
   * @request GET:/partner/{partnerId}/activity-log/{id}
   * @secure
   * @response `200` `MrfiktivActivityLogViewModelGen` The activity log entry
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  activityLogControllerFindOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivActivityLogViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/activity-log/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Activity Log Service
   * @name ActivityLogControllerUpdate
   * @summary Update an activity log entry from a partner
   * @request PATCH:/partner/{partnerId}/activity-log/{id}
   * @secure
   * @response `200` `MrfiktivActivityLogViewModelGen` The activity log document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  activityLogControllerUpdate = (
    partnerId: string,
    id: string,
    data: MrfiktivUpdateActivityLogDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivActivityLogViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/activity-log/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Activity Log Service
   * @name ActivityLogControllerRemove
   * @summary Delete an activity log entry from a partner
   * @request DELETE:/partner/{partnerId}/activity-log/{id}
   * @secure
   * @response `200` `MrfiktivActivityLogViewModelGen` The deleted activity log
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` NotFound.
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  activityLogControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivActivityLogViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/activity-log/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
