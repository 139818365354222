import { Api } from "@/services/fahrzeugschein/api";
import { FahrzeugscheinEntity } from "@/models/fahrzeugscheinEntity";
import { XMLEntity } from "@/models/xmlEntity";
import { ErrorLogModule } from "@/store/modules/error-log";
import Vue from "vue";

class FahrzeugscheinService {
  ROUTE = "fahrzeugschein";

  async get(id: string): Promise<FahrzeugscheinEntity> {
    const api = new Api().instance();
    try {
      const response = await api.get(`/${this.ROUTE}/${id}` + Api.getToken());
      const fahrzeugschein = response.data as FahrzeugscheinEntity;

      return fahrzeugschein;
    } catch (error) {
      Vue.$log.error(error);
      ErrorLogModule.addErrorLog(error);
      throw error;
    }
  }

  async getStatus(id: string) {
    const api = new Api().instance();
    const response = await api.get(`/${this.ROUTE}/status/${id}` + Api.getToken());
    return response.data.status;
  }

  create(data: any) {
    const formData = new FormData();

    formData.append("file", data);

    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    const api = new Api().instance();
    return api.post(`/${this.ROUTE}` + Api.getToken(), formData, config);
  }

  update(id: string, data: any): Promise<FahrzeugscheinEntity> {
    const api = new Api().instance();
    return api.put(`/${this.ROUTE}/${id}` + Api.getToken(), data);
  }

  delete(id: string) {
    const api = new Api().instance();

    try {
      api.delete(`/${this.ROUTE}/${id}` + Api.getToken());
    } catch (error) {
      Vue.$log.error(error);
      ErrorLogModule.addErrorLog(error);
      throw error;
    }
  }

  async getXML(id: string): Promise<XMLEntity> {
    const api = new Api().instance();
    try {
      const response = await api.get(`/${this.ROUTE}/${id}/xml` + Api.getToken());
      const xml = response.data as XMLEntity;
      return xml;
    } catch (error) {
      Vue.$log.error(error);
      ErrorLogModule.addErrorLog(error);
      throw error;
    }
  }
}

export default new FahrzeugscheinService();
