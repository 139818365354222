




























import { Component, Vue, Prop } from "vue-property-decorator";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";

@Component
export default class InstructionCard extends Vue {
  @Prop({ default: "Anleitung" })
  instructionTitle!: string;

  @Prop()
  instructionText!: string;

  @Prop()
  instructionPictures!: AssetEnum[];

  clicked() {
    this.$emit("clicked", true);
  }

  get hasMultipleImages(): boolean {
    if (this.instructionPictures) return this.instructionPictures.length >= 2;

    return false;
  }

  get instructionPicturesImages(): string[] {
    const images: string[] = [];

    if (!this.instructionPictures) {
      return images;
    }

    for (const picture of this.instructionPictures) {
      const asset = AssetRepository.getAsset(this.$vuetify.theme.dark, picture);
      images.push(asset);
    }

    return images;
  }
}
