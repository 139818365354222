
































import { Component, Vue } from "vue-property-decorator";
import { ErrorLogModule } from "@/store/modules/error-log";

@Component
export default class ErrorAlert extends Vue {
  sheet = false;

  openDetails() {
    this.sheet = true;
  }

  get alert() {
    return this.errors.length >= 1;
  }

  get errors() {
    return ErrorLogModule.logs;
  }

  clear() {
    return ErrorLogModule.clearErrorLog();
  }
}
