import { MrfiktivCreateMessageDtoGen, MrfiktivPartnerMessageViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ThgCreateMessageDtoGen, ThgPartnerMessageViewModelGen } from "@/services/thg/v1/data-contracts";
import messageService from "@/services/shared/messageService";

@Module({
  dynamic: true,
  namespaced: true,
  name: "message",
  store
})
export class MessageStore extends VuexModule {
  private _message?: MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen | undefined = undefined;

  get message() {
    return this._message;
  }

  @Mutation
  mutateMessage(data: MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen) {
    this._message = data;
  }

  @Action
  async sendMessage(data: {
    partnerId: string;
    data: MrfiktivCreateMessageDtoGen;
  }): Promise<MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen | undefined> {
    return messageService.sendMessage(data.partnerId, data.data);
  }

  @Action
  async sendMessages(data: {
    partnerId: string;
    data: MrfiktivCreateMessageDtoGen[] | ThgCreateMessageDtoGen[];
  }): Promise<MrfiktivPartnerMessageViewModelGen[] | ThgPartnerMessageViewModelGen[] | undefined> {
    return messageService.sendMessages(data.partnerId, data.data);
  }

  @Action
  async getMessage(data: {
    partnerId: string;
    id: string;
  }): Promise<MrfiktivPartnerMessageViewModelGen | ThgPartnerMessageViewModelGen | undefined> {
    const message = await messageService.getMessage(data.partnerId, data.id);
    this.context.commit("mutateMessage", message);

    return message;
  }
}

export const MessageModule = getModule(MessageStore);
