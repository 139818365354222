










import { GoToHelper } from "@/lib/utility/goToHelper";
import { MrfiktivBaseCreateSharedContentDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Prop, Vue } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";
import { VehicleTabs } from "@/lib/enum/vehicle-tabs.enum";

@Component({
  components: {
    Tooltip
  }
})
export default class GoToReferenceButton extends Vue {
  @Prop()
  reference!: MrfiktivBaseCreateSharedContentDtoGen;

  @Prop()
  partnerId!: string;

  @Prop()
  text!: string;

  goToDetail() {
    const goToHelper = new GoToHelper(this.$router);
    if (this.reference.refType === ResourceEnum.REPORT) {
      goToHelper.goToReportDetail(this.reference.refId, this.partnerId, true);
      return;
    }
    if (this.reference.refType === ResourceEnum.DOCUMENT) {
      goToHelper.goToDocumentDetail(this.reference.refId, this.partnerId, true);
      return;
    }

    if (this.reference.refType === ResourceEnum.VEHICLE) {
      goToHelper.goToVehicleDetail(this.reference.refId, this.partnerId, VehicleTabs.DOCUMENT, true);
      return;
    }
  }
}
