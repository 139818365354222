export function flattenObject(obj: any) {
  const toReturn = {};

  for (const i in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj.hasOwnProperty(i)) continue;

    if (typeof obj[i] == "object" && obj[i] !== null) {
      const flatObject = flattenObject(obj[i]);
      for (const x in flatObject) {
        // eslint-disable-next-line no-prototype-builtins
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = obj[i];
    }
  }

  return toReturn;
}

/**
 * Unflattens an object with dot notation keys
 * @param flattened
 * @returns
 */
export function unflattenObject(flattened: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};

  for (const key in flattened) {
    const value = flattened[key];
    const keys = key.split(".");
    let current = result;

    for (let i = 0; i < keys.length; i++) {
      const subKey = keys[i];

      if (i === keys.length - 1) {
        // Last key, assign the value
        current[subKey] = value;
      } else {
        // Create nested objects if they don't exist
        if (!isNaN(Number(keys[i + 1]))) {
          current[subKey] = current[subKey] || [];
        } else {
          current[subKey] = current[subKey] || {};
        }
        current = current[subKey];
      }
    }
  }

  return result;
}
