var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "700",
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-card', _vm._g(_vm._b({
          class: _vm.cardClass,
          attrs: {
            "icon": "",
            "height": "100px",
            "width": "100px",
            "flat": ""
          }
        }, 'v-card', attrs, false), on), [_c('v-img', {
          staticClass: "white--text",
          attrs: {
            "height": "100px",
            "gradient": "to top right, rgba(30, 30, 30, 0.58), rgba(30, 30, 30, 0.58)",
            "src": _vm.src
          }
        }, [_c('v-card-text', [_c('small', [_vm._v(_vm._s(_vm.title))])])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-card', [_c('v-img', {
    attrs: {
      "width": "700",
      "src": _vm.src
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-card-title', {
    staticClass: "white--text"
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }