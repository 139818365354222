import HistoryLog from "@/lib/historyLog";
import { reportHomeQueryParams } from "@/lib/queryParams/reportHomeQueryParams";
import { thgQueryParams } from "@/lib/queryParams/thgQueryParams";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { LoginModule } from "@/store/modules/login.store";
import { UserModule } from "@/store/modules/me-user.store";
import Vue from "vue";
import { NavigationGuard } from "vue-router";
import permissionAuthGuardConfig from "./permission-auth-guard.config.json";

/**
 * This Auth Guard contains information on required permissions per route.
 * Make sure that "router.beforeEach(authGuard)" is added before you export the router and create an entry
 * in the authGuard.config.json using @see PermissionAuthGuardConfig.
 */

/**
 * Auth Guard configuration.
 */
interface PermissionAuthGuardConfig {
  [key: string]: AbilityTuple;
}

/**
 * Based on casl.js abilities.
 */
interface AbilityTuple {
  /**
   * action defines the crud- operation the user may perform (manage means they can do all operations)
   *  */
  action: ActionEnum;
  /**
   * subject is the resource the user may handle.
   */
  subject: string;
}

/**
 * Actual Navigation Guard. It applies the authGuard.config.js configuration to the guard.
 * Only applies navigation guard on routes that are defined in the config.
 * Checks if user is authenticated as route guard is per permissions.
 */
export const permissionGuard: NavigationGuard = (to, from, next) => {
  // keep track of navigation history
  HistoryLog.add(to.path);

  const authMap = permissionAuthGuardConfig as PermissionAuthGuardConfig;

  const rule = authMap[to.name ? to.name : ""];

  try {
    thgQueryParams.forEach(param => {
      const value = to.query[param.key];

      if (value !== undefined) {
        param.onFound((value as string) || "");
        Vue.$log.warn(`${param.key} set in store`);
      }
    });

    reportHomeQueryParams.forEach(param => {
      const value = to.query[param.key];

      if (value !== undefined) {
        param.onFound((value as string) || "");
        Vue.$log.warn(`${param.key} set in store`);
      }
    });
  } catch (e) {
    Vue.$log.error(e);
  }

  if (!rule) {
    Vue.$log.info("no rule");
    return next();
  }
  Vue.$log.info(rule);

  Vue.$log.info("User logged in?");
  if (!UserModule.isAuthenticated) {
    //Save where user wanted to go so that user can be redirected back after login
    LoginModule.setRedirect(to.fullPath);

    Vue.$log.info("Login redirect");
    return next({ name: "Login" });
  }

  Vue.$log.info("User logged in! Access?");
  if (UserModule.abilities.cannot(rule.action, rule.subject)) {
    Vue.$log.info("Permission insufficient");
    return next({
      name: "Unauthorized",
      params: { action: rule.action, subject: rule.subject }
    });
  }

  Vue.$log.info("All good");
  return next();
};
