

















































































import ThePartnerSelectorList from "@/layouts/navigation/ThePartnerSelectorList.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IUser } from "@/models/user.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { ThePartnerSelectorList }
})
export default class ProfileMenu extends Vue {
  @Prop({ default: false })
  isEndUser!: boolean;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  logout() {
    this.$router.push({ name: "Logout" });
  }

  get partner() {
    return PartnerModule.partner;
  }

  get partners() {
    return PartnerModule.partners;
  }

  get companyName() {
    return PartnerModule.partner?.companyName || "";
  }

  /**
   * Returns primary color if !enduser, else returns custom partner color
   */
  get color() {
    if (this.isEndUser) {
      return getDefaultPartnerColor();
    } else {
      return "primary";
    }
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get user(): IUser {
    return UserModule.user;
  }

  get logOut() {
    const item = {
      icon: "mdi-logout",
      text: "Logout",
      link: "/login/logout"
    };
    return item;
  }

  isLogoutLoading = false;

  async performLogout() {
    this.isLogoutLoading = true;
    try {
      await UserModule.logout();
    } catch {
      this.isLogoutLoading = false;
    }
  }

  get userItem() {
    const item = {
      icon: "mdi-account-outline",
      text: "Profil",
      link: "/profile"
    };
    return item;
  }
}
