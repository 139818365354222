
















import { NavigationRoute } from "@/store/mapper/navigation.route";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class NavigationListItem extends Vue {
  @Prop()
  navigationItem!: NavigationRoute;

  get icon() {
    return this.navigationItem?.navigation?.icon || "mdi-alert-circle-outline";
  }

  get partnerId() {
    return PartnerModule.partner._id;
  }

  goTo() {
    this.$router.push({ name: this.navigationItem.name, params: { partnerId: this.partnerId } });
  }
}
