




























































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import Debug from "@/components/utility/Debug.vue";
import TableWrapper, { ITableWrapperHeader, IControlElements } from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { IPaginationParams } from "@/store/modules/base-pagination.store";
import { UserModule } from "@/store/modules/me-user.store";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import { Component, Prop } from "vue-property-decorator";
import { debounce } from "debounce";
import { handleError } from "@/lib/utility/handleError";
import { PageDefaults } from "@/lib/utility/data/page-data-provider.abstract";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { downloadAsXlsx, getTranslationsFromFilterable } from "@/lib/download-as-xlsx";
import { FeatureModule } from "@/store/modules/feature.store";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    TheLayoutPortal,
    TableWrapper,
    LatestEntriesCardEmpty,
    FilterCardPagination,
    Debug
  }
})
export default class PaginationTableNew<
  Type extends object,
  PaginationParamsType extends IPaginationParams
> extends PermissionMixin {
  /**
   * SETTING 4 TESTING Purposes
   *
   * if set to false we do not load the next page if we have enough elements
   * if set to true we always load the next page (unless we go to previous page (unless unless the previous page is the first page))
   */
  readonly LOAD_ONLY_WHEN_NECESSARY = true;

  /**
   * Store that contains the paginated data
   */
  @Prop()
  store!: PaginatedBaseStore<Type, PaginationParamsType>;

  /**
   * Title of the table
   */
  @Prop()
  title!: string;

  /**
   * Headers for the table
   */
  @Prop()
  headers!: ITableWrapperHeader[];

  /**
   * Query parametes that should be sent with each pagination request.
   *
   * @example { partnerId: "partnerId" }
   * @example { suserId: "userId" }
   */
  @Prop({
    default: () => {
      return {};
    }
  })
  baseQuery!: PaginationParamsType;

  /**
   * Predefined filters that should be shown in the filter card as suggestions
   */
  @Prop({ default: () => [] })
  predefinedFilter!: [{ name: string; filter: IPageFilterElement[] }];

  @Prop({ default: false })
  singleSelect?: boolean;

  @Prop({ default: false })
  showSelect?: boolean;

  @Prop({ default: () => [] })
  selectedItems!: Record<string, any>[];

  @Prop()
  controlElements?: IControlElements[];

  @Prop({})
  groupBy?: string;

  @Prop({ default: false })
  groupDesc?: boolean;

  @Prop()
  sortBy?: string;

  @Prop()
  initialItemsPerPage?: number;

  get isCsvExport() {
    return FeatureModule.isCsvExport;
  }

  get hasClickRowListener() {
    return Boolean(this.$listeners && this.$listeners["click:row"]);
  }

  itemsPerPage = this.initialItemsPerPage ?? this.store.pageSizes[1];

  isLoadingMore = false;

  isInterrupted = false;

  isLoadingAll = false;

  /**
   * Current page
   */
  currentPage = 1;

  get selected() {
    return this.selectedItems;
  }

  set selected(selectedItems: Record<string, any>[]) {
    this.$emit("update:selectedItems", selectedItems);
  }

  /**
   * Total number of elements available
   */
  get totalElements() {
    if (this.store.filtered.length > this.store.totalItems) {
      return this.store.filtered.length;
    }

    return this.store.totalItems;
  }

  /**
   * Options for the items per page select
   */
  get itemsPerPageOptions() {
    if (UserModule.isAdmin) {
      return this.store.pageSizes.concat(PageDefaults.MAX_ITEMS_PER_PAGE_ADMINS);
    }
    return this.store.pageSizes;
  }

  /**
   * Fetch the first page when the component is mounted
   */
  async mounted() {
    await this.onItemsPerPageChanged(this.itemsPerPage);
  }

  download() {
    let list = this.selected;
    if (!list.length) {
      list = this.store.filteredAndSorted;
    }

    const headerTranslations = getTranslationsFromFilterable(this.store.filterOptions);

    downloadAsXlsx(list, headerTranslations);
  }

  /**
   * Set the filter in the store without making backend call
   */
  async setFilter(filter: IPageFilterElement[]) {
    this.$log.debug("setFilterAndFetch", filter);
    this.store.filters.splice(0, this.store.filters.length, ...filter);
  }

  /**
   * Set the search in the store without making backend call
   */
  private setSearch(search: string) {
    this.$log.debug("setSearchAndFetch", search);
    this.store.setSearch(search);
  }

  /**
   * add debounce to setSearch
   */
  debounceSetSearch = debounce(this.setSearch, 500);

  /**
   * add debounce to fetchFirstPage. used when search or filter is confirmed

   */
  debounceFetchFirstPage = debounce(this.store.fetchFirstPage, 500);

  /**
   * Fetch the next page
   */
  async fetchNextPage() {
    try {
      this.isLoadingMore = true;
      const nextPage = this.currentPage + 1;
      if (this.LOAD_ONLY_WHEN_NECESSARY) {
        const isNeccessary = this.store.filtered.length < this.itemsPerPage * nextPage;
        // are there less elements than we need? Load more
        if (isNeccessary) {
          await this.store.fetchNextPage({ ...this.baseQuery });
        }
      } else {
        await this.store.fetchNextPage({ ...this.baseQuery });
      }

      this.currentPage = nextPage;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingMore = false;
    }
  }

  /**
   * Set the previous page
   */
  async setPreviousPage() {
    this.currentPage--;

    if (this.currentPage === 1) {
      try {
        this.isLoadingMore = true;
        await this.store.fetchFirstPage({ ...this.baseQuery });
      } catch (e) {
        handleError(e);
      } finally {
        this.isLoadingMore = false;
      }
    }
  }

  /**
   * When the items per page is changed, we might need to fetch the first page again if it is not complete yet
   * also makes sure that the items per page fetched from the backedn is not less than the default items per page
   */
  async onItemsPerPageChanged(newItemsPerPage: number) {
    if (newItemsPerPage < PageDefaults.DEFAULT_ITEMS_PER_PAGE) newItemsPerPage = PageDefaults.DEFAULT_ITEMS_PER_PAGE;

    try {
      this.isLoadingMore = true;
      this.store.setItemsPerPage(newItemsPerPage);
      const isNeccessary = this.store.filtered.length < newItemsPerPage * 1;

      if (!this.LOAD_ONLY_WHEN_NECESSARY) {
        await this.store.fetchFirstPage({ ...this.baseQuery });
      } else if (isNeccessary) {
        await this.store.fetchFirstPage({ ...this.baseQuery });
      }

      this.currentPage = 1;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingMore = false;
    }
  }

  async startLoadAll() {
    this.isInterrupted = false;
    this.isLoadingAll = true;
    this.itemsPerPage = PageDefaults.MAX_ITEMS_PER_PAGE_ADMINS;
    this.onItemsPerPageChanged(PageDefaults.MAX_ITEMS_PER_PAGE_ADMINS);
    await this.loadAll();
    this.isLoadingAll = false;
  }

  /**
   * Load EVERYTHING in small pages until we have everything
   */
  async loadAll() {
    if (this.isInterrupted) return;
    this.$log.debug("loadAll");

    try {
      this.isLoadingMore = true;
      await this.store.fetchNextPage({ ...this.baseQuery });

      if (this.store.filtered.length < this.store.totalItems) {
        await this.loadAll();
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingMore = false;
    }
  }
}
