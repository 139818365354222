/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { formatYearsMonthDay, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import Vue from "vue";
import i18n from "@/plugins/I18nPlugin";
import eventService from "@/services/mrfiktiv/services/eventService";
import vehicleEventService from "@/services/mrfiktiv/services/vehicleEventService";
import {
  MrfiktivByUserAtGen,
  MrfiktivCreateBaseEventDtoGen,
  MrfiktivCreateEventDtoGen,
  MrfiktivEventViewModelGen,
  MrfiktivTimestampGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { EventModule } from "@/store/modules/event.store";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import { VehicleEventModule } from "@/store/modules/vehicle-event.store";
import { Options, RRule, RRuleSet, rrulestr } from "rrule";
import { $t } from "@/lib/utility/t";
import { IsFilterable, FilterConfig, FilterTypes, Filter } from "@/lib/filterable";
import { VehicleModule } from "@/store/modules/vehicle.store";

/**
 * Enums for recurring event defaults
 */
export enum RecurringEventEnum {
  HU_AU_2 = "huau2",
  HU_AU_1 = "huau1",
  SP = "sp",
  UVV = "uvv",
  TP = "tp",
  DGUV = "dguv",
  CODE_XL = "codexl"
}

/**
 * Interface for recurring event templates
 */
export interface IRecurringEventTemplate {
  enum: RecurringEventEnum;
  title: string;
  description: string;
  isRecurring: boolean;
  isAllDay: boolean;
  options: Partial<Options>;
}

/**
 * The limits.
 * `count` and `until` are mutually exclusive in iCalendar RFC
 * @see https://datatracker.ietf.org/doc/html/rfc5545#section-3.3.10
 * Although you can specify both in the rrrule.js package, the first limit to match will limit the instances repetition.
 *
 * @example
 *  Create an event every day with limit, to 5 instances count, until 10th of month
 *  freq: RRule.DAILY
 *  start: 2024-01-01
 *  until: 2024-01-10
 *  count: 5  (!)
 *  -> 5 Instances
 *
 * @example
 *  Create an event every day with limit, to 10 instances count, until 06th of month
 *  freq: RRule.DAILY
 *  start: 2024-01-01
 *  until: 2024-01-06 (!)
 *  count: 10
 *  -> 6 Instances
 */
export enum RecurrenceLimit {
  /**
   * Limit by date, until
   */
  UNTIL = "until",

  /**
   * Limit by instance count
   */
  COUNT = "count",

  /**
   * No limit
   */
  NONE = "none"
}

/**
 * Class to manage events
 */
@IsFilterable
class EventUIDtoBase implements MrfiktivEventViewModelGen {
  /**
   * indicates if event isloading
   */
  loading = false;

  /**
   * @inheritdoc
   */
  @FilterConfig({ type: FilterTypes.DATE, displayName: "objects.event.startDate" })
  startDate = "";

  /**
   * @inheritdoc
   */
  @FilterConfig({ type: FilterTypes.DATE, displayName: "objects.event.endDate" })
  endDate?: string | undefined;

  /**
   * @inheritdoc
   */
  timestamp: MrfiktivTimestampGen = {} as MrfiktivTimestampGen;

  /**
   * @inheritdoc
   */
  ack?: MrfiktivByUserAtGen | undefined;

  /**
   * @inheritdoc
   */
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.event.vehicleId",
    config: {
      itemCallback: () => VehicleModule.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-vehicle"
    }
  })
  vehicleId?: string | undefined;

  /**
   * @inheritdoc
   */
  partnerId = "";

  /**
   * @inheritdoc
   */
  userId = "";

  /**
   * @inheritdoc
   */
  id?: string;

  /**
   * @inheritdoc
   */
  isVirtual = false;

  /**
   * @inheritdoc
   */
  readonly isRecurringRoot: boolean = false;

  /**
   * @inheritdoc
   */
  readonly recurringEventId?: string;

  /**
   * @inheritdoc
   */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.event.summary" })
  summary = "";

  /**
   * @inheritdoc
   */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.event.description" })
  description? = "";

  /**
   * @inheritdoc
   */
  location?: string;

  /**
   * @inheritdoc
   */
  type?: string;

  /**
   * @inheritdoc
   */
  start = new Date().getTime();

  /**
   * @inheritdoc
   */
  readonly originalStart?: number;

  /**
   * @inheritdoc
   */
  end?: number;

  /**
   * @inheritdoc
   */
  readonly timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  /**
   * @inheritdoc
   */
  isAllDay = true;

  /**
   * @inheritdoc
   */
  _rrule = EventUIDto.DEFAULT_RRULE;

  /**
   * Identifies the repeat pattern for the rule options.
   */
  _limit?: RecurrenceLimit = RecurrenceLimit.NONE;

  /**
   * Displays the rule as text.
   * Set in constructor and only contains a subset of applicable rules.
   * This needs to be the `origOptions` of the rrule, otherwise the default options will be applied.
   */
  _ruleOptions?: Partial<Options>;

  /**
   * Marks the event as recurring.
   * Enables the rule options.
   */
  _isRecurring = false;

  /**
   * Start date of the event. Internal, used for date input(v-text-field)
   */
  _startString = "";

  /**
   * Flag to mark the event as deleted
   */
  isDeleted?: boolean | undefined;

  static DEFAULT_RRULE = "RRULE:WKST=MO;FREQ=YEARLY;INTERVAL=1";

  /**
   *  Creates and instance of a UI dto for an event.
   * Depending on if it has been created based on a create dto or view model it has certain properties set.
   * It can be used to create or update an event via `createDto` function.
   *
   * @param event a create dto or a view model
   */
  constructor(event?: MrfiktivEventViewModelGen) {
    if (event) {
      this.id = event.id;

      this.partnerId = event.partnerId;
      this.vehicleId = event.vehicleId;
      this.userId = event.userId;

      this.summary = event.summary;
      this.description = event.description;
      this.location = event.location;
      this.type = event.type;

      this.start = event.start;
      this.end = event.end;
      this.timezone = event.timezone;
      this.isAllDay = event.isAllDay;
      this.recurringEventId = event.recurringEventId;

      this.isVirtual = event.isVirtual || false;
      this.isRecurringRoot = event.isRecurringRoot || false;

      this.vehicleId = event.vehicleId;
      this.userId = event.userId;
      this.timestamp = event.timestamp;
      this.ack = event.ack;

      if (this.isVirtual) {
        this.originalStart = event.start;
      }

      this.timestamp = event.timestamp;
      this.ack = event.ack;
      this.startDate = event.startDate;
      this.endDate = event.endDate;

      this.isDeleted = event.isDeleted;

      if (event.rrule) {
        this._isRecurring = true;
        // Set the ruleOptions to be the original options argument of the default rrule.
        this.ruleOptions = rrulestr(event.rrule).origOptions;
        this._rrule = event.rrule;
        this.limit = this.parseLimitFromRule(this.ruleOptions);
      }
    }

    /** start is always defined */
    this.startString = formatYearsMonthDay(new Date(this.start));
  }

  /**
   * Returns the dto for the creation of an event.
   * If its a recurring event, the rule will be added to the dto.
   * If its a virtual event the rrule is excluded.
   */
  get createDto(): MrfiktivCreateEventDtoGen {
    const event: MrfiktivCreateEventDtoGen = {
      summary: this.summary,
      description: this.description,
      start: this.start,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      isAllDay: this.isAllDay
    };

    // Only add the rule if its an instance, it will become a recurring root event
    if (!this.isVirtual && this._isRecurring) {
      event["rrule"] = this.rrule;
    }

    return event;
  }

  get allRecurrence() {
    return this.rule.all();
  }

  get nextRecurrence() {
    return this.rule.after(new Date(this.start - 1000), true);
  }

  /**
   * Returns the rule for the event.
   */
  get rule() {
    return rrulestr(this.rrule);
  }

  /**
   * Predefined repeat options for the rule.
   */
  get repeatItems(): { text: string; value: RecurrenceLimit }[] {
    return [
      {
        text: i18n.t("views.fleet.EventCreateDialog.repeatItems.atDay").toString(),
        value: RecurrenceLimit.UNTIL
      },
      {
        text: i18n.t("views.fleet.EventCreateDialog.repeatItems.repeats").toString(),
        value: RecurrenceLimit.COUNT
      },
      { text: i18n.t("views.fleet.EventCreateDialog.repeatItems.noEnd").toString(), value: RecurrenceLimit.NONE }
    ];
  }

  /**
   * Predefined frequency options for the rule.
   */
  get freqItems() {
    return [
      {
        text: i18n.t("views.fleet.EventCreateDialog.freqObjs.yearly").toString(),
        value: RRule.YEARLY
      },
      {
        text: i18n.t("views.fleet.EventCreateDialog.freqObjs.monthly").toString(),
        value: RRule.MONTHLY
      },
      {
        text: i18n.t("views.fleet.EventCreateDialog.freqObjs.weekly").toString(),
        value: RRule.WEEKLY
      },
      {
        text: i18n.t("views.fleet.EventCreateDialog.freqObjs.daily").toString(),
        value: RRule.DAILY
      }
    ];
  }

  /**
   * Displays human readable text for the rule.
   */
  get ruleText() {
    return new RRule(this._ruleOptions).toText();
  }

  get ruleTextTranslated() {
    let text = "";
    if (this._ruleOptions) {
      text += $t(`views.fleet.EventCard.rrule.${this._ruleOptions.freq}`, { x: this._ruleOptions.interval });
    }

    if (this._limit === "none") {
      text += " ";
      text += $t("views.fleet.EventCreateDialog.repeatItems.noEnd");
    } else if (this._limit === "count") {
      text += " ";
      text += $t("views.fleet.EventCreateDialog.recurringEnd");
      text += " ";
      text += this.repeatItems.find(i => i.value === this._limit)?.text;
      text += " ";
      text += this._ruleOptions?.count;
      text += " ";
      text += $t("views.fleet.EventCreateDialog.recurringRepeats");
    } else if (this._limit === "until" && this._ruleOptions?.until) {
      text += " ";
      text += $t("views.fleet.EventCreateDialog.repeatItems.atDay");
      text += " ";
      text += simpleDoubleDigitDate(this._ruleOptions.until.toISOString());
    }

    return text;
  }

  set limit(limit: RecurrenceLimit | undefined) {
    this._limit = limit;

    let options: Partial<Options> = this.ruleOptions || {};

    switch (this._limit) {
      case RecurrenceLimit.COUNT:
        options.until = undefined;
        break;

      case RecurrenceLimit.UNTIL:
        options.count = undefined;
        break;

      case RecurrenceLimit.NONE:
        options.until = undefined;
        options.count = undefined;
        break;

      default:
        options = {};
        break;
    }

    this.ruleOptions = options;
  }

  get limit() {
    return this._limit;
  }

  set ruleOptions(options: Partial<Options> | undefined) {
    if (options && !options?.dtstart) {
      options.dtstart = new Date(this.start);
    }

    this._ruleOptions = options;

    if (!this.limit) {
      this.limit = this.parseLimitFromRule(this.ruleOptions);
    }
  }

  get ruleOptions() {
    return this._ruleOptions;
  }

  get startString() {
    return this._startString;
  }

  set startString(date: string) {
    if (isNaN(Date.parse(date))) {
      return;
    }

    const d = new Date(date);
    this.start = d.getTime();

    if (this.isRecurring && this.ruleOptions) {
      this.ruleOptions.dtstart = d;
    }

    this._startString = date;
  }

  get untilString() {
    if (!this.ruleOptions?.until) {
      return "";
    }

    return formatYearsMonthDay(new Date(this.ruleOptions?.until));
  }

  set untilString(date: string) {
    if (!this.ruleOptions) {
      this.ruleOptions = {
        until: new Date(date)
      };

      return;
    }

    this.ruleOptions.until = new Date(date);
  }

  get rrule() {
    return new RRule(this.ruleOptions).toString();
  }

  get isRecurring() {
    return this._isRecurring;
  }

  set isRecurring(isRecurring: boolean) {
    this._isRecurring = isRecurring;

    if (isRecurring) {
      const ruleOptions = rrulestr(EventUIDto.DEFAULT_RRULE).origOptions;
      ruleOptions.dtstart = new Date(this.start);

      this.ruleOptions = ruleOptions;
    }
  }

  getNextEventOfSeriesAfter(date: Date) {
    return this.rule.after(date, false);
  }

  listVirtualInstances(count = 5) {
    if (!this.rrule) {
      return new Date(this.start);
    }

    const ruleSet = new RRuleSet();
    const rrule = RRule.fromString(this.rrule);
    ruleSet.rrule(rrule);

    return ruleSet.all().slice(0, count);
  }

  parseLimitFromRule(options: Partial<Options> | undefined): RecurrenceLimit | undefined {
    if (!options) {
      return undefined;
    }

    if (options.count) {
      return RecurrenceLimit.COUNT;
    } else if (options.until) {
      return RecurrenceLimit.UNTIL;
    } else {
      return RecurrenceLimit.NONE;
    }
  }

  setRuleFromTemplate(item: IRecurringEventTemplate) {
    this.summary = item.title;
    this.description = item.description;
    this.isRecurring = item.isRecurring;
    this.ruleOptions = item.options;
    this.isAllDay = item.isAllDay;
    this.type = item.enum;
  }

  /**
   * Indicates if the given ticket is overdue
   */
  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.event.isOverdue"
  })
  get isOverdue() {
    return !this.ack && this.start < new Date().getTime();
  }

  async unacknowledge() {
    if (!this.id) {
      Vue.$log.error("eventId missing");
      return;
    }

    const updated = await eventService.update(this.partnerId, this.id, { ack: null });
    this.update(updated);
  }

  async acknowledge(at: string) {
    try {
      const start = this.start;
      const partnerId = this?.partnerId;

      // if we don't have an id we can assume its virtual
      let eventId = this?.id;
      if (this?.isVirtual) {
        eventId = this?.recurringEventId;
      }

      if (!eventId) {
        Vue.$log.error("eventId missing");
        return;
      }

      if (!partnerId) {
        Vue.$log.error("partnerId missing");
        return;
      }

      if (!start) {
        Vue.$log.error("start missing");
        return;
      }

      await FleetAggregationModule.removeEvent(this);
      const updated = await eventService.acknowledge(partnerId, eventId, start, { at });
      this.update(updated);
      await EventModule.replaceInList(this);
      await FleetAggregationModule.parseEvents([this]);
    } catch (error) {
      handleError(error);
    }
  }

  async createVirtual() {
    if (!this.isVirtual) return;

    if (!this.recurringEventId) {
      Vue.$log.error("recurringEventId missing");
      return;
    }

    try {
      let updated: IEventUIDto;
      FleetAggregationModule.removeEvent(this);
      const data: MrfiktivCreateBaseEventDtoGen = {
        isAllDay: this.isAllDay,
        start: this.start,
        summary: this.summary,
        timezone: this.timezone,
        ack: this.ack,
        description: this.description,
        end: this.end,
        location: this.location,
        type: this.type
      };
      if (this.vehicleId) {
        updated = await vehicleEventService.update(
          this.partnerId,
          this.vehicleId,
          this.recurringEventId,
          this.start,
          data
        );
        const event = new EventUIDto(updated);
        VehicleEventModule.replaceInList(event);
      } else {
        updated = await eventService.createVirtual(this.partnerId, this.recurringEventId, this.start, data);
        const event = new EventUIDto(updated);
        VehicleEventModule.replaceInList(event);
      }
      this.update(updated);
      FleetAggregationModule.parseEvents([this]);

      return;
    } catch (e) {
      handleError(e);
      return;
    }
  }

  private update(event?: IEventUIDto) {
    Vue.$log.info(event);

    if (event) {
      this.id = event.id;

      this.partnerId = event.partnerId;
      this.vehicleId = event.vehicleId;
      this.userId = event.userId;

      this.summary = event.summary;
      this.description = event.description;
      this.location = event.location;
      this.type = event.type;

      this.start = event.start;
      this.end = event.end;
      this.isAllDay = event.isAllDay;

      this.isVirtual = event.isVirtual || false;

      this.vehicleId = event.vehicleId;
      this.userId = event.userId;
      this.timestamp = event.timestamp;
      this.ack = event.ack;

      this.timestamp = event.timestamp;
      this.ack = event.ack;
      this.startDate = event.startDate;
      this.endDate = event.endDate;

      if (event.rrule) {
        this._isRecurring = true;
        // Set the ruleOptions to be the original options argument of the default rrule.
        this.ruleOptions = rrulestr(event.rrule).origOptions;
        this._rrule = event.rrule;
        this.limit = this.parseLimitFromRule(this.ruleOptions);
      }
    }
  }

  /**
   * Ends the series on given date
   * @param on
   * @returns new Series
   */
  async interruptSeries(originalStart: number, newStart: number) {
    if (!this.isRecurringRoot) throw new Error("This event is not a recurring root event");
    if (!this.id) throw new Error("eventId missing");
    if (!this.partnerId) throw new Error("partnerId missing");
    if (!this._ruleOptions) throw new Error("_ruleOptions missing");

    try {
      const newSeriesRes = await eventService.interruptSeries(this.partnerId, this.id, {
        originalStart: originalStart,
        newStart: newStart,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });

      const newSeries = new EventUIDto(newSeriesRes);
      EventModule.replaceInList(newSeries);

      const thisSeriesRes = await eventService.getOne(this.partnerId, this.id);
      this.update(new EventUIDto(thisSeriesRes));
      EventModule.replaceInList(this);

      return newSeries;
    } catch (error) {
      handleError(error);
    }
  }

  async updateVehicleAggregation() {
    try {
      if (!this.vehicleId) throw new Error("vehicleId missing");

      const vehicleAggregation = await FleetAggregationModule.getVehicleAggregation(this.vehicleId);
      await vehicleAggregation?.updateEvents({
        month: new Date(this.start).getMonth(),
        year: new Date(this.start).getFullYear()
      });
    } catch (e) {
      handleError(e);
    }
  }

  async listRecurringEvents(from: number, to: number) {
    if (!this.id) {
      throw new Error("eventId missing");
    }

    if (!this.partnerId) {
      throw new Error("partnerId missing");
    }

    let events: IEventUIDto[] = [];

    try {
      if (!this.vehicleId)
        events = await eventService.listAll({
          partnerId: this.partnerId,
          recurringEventId: this.id,
          from,
          to
        });
      else
        events = await vehicleEventService.listAll({
          partnerId: this.partnerId,
          recurringEventId: this.id,
          vehicleId: this.vehicleId,
          from,
          to
        });

      return events.map((event: MrfiktivEventViewModelGen) => new EventUIDto(event));
    } catch (error) {
      handleError(error);
    }

    return [];
  }
}

type IEventUIDto = EventUIDtoBase;
const EventUIDto = Filter.createForClass(EventUIDtoBase);

export { EventUIDto, IEventUIDto };
