























































































































































































import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import FileUploadPreview from "@/components/utility/FileUploadPreview.vue";
import NumberplateForm from "@/components/utility/NumberplateForm.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { emailRule, phoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { detailedDateWithDay, formatHoursAndMinutes } from "@/lib/utility/date-helper";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import {
  BookingBookingInformationDtoGen,
  BookingCreateBookingWithoutResourceDtoGen,
  BookingCustomerFieldConfigurationOptionsDtoGen,
  BookingCustomerInformationDtoGen,
  BookingSlotViewModelGen,
  BookingCustomFieldValueGen
} from "@/services/booking/v1/data-contracts";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { FeatureModule } from "@/store/modules/feature.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import BookingBackButton from "./BookingBackButton.vue";
import BookingNavigation from "./BookingNavigation.vue";
import { LoginModule } from "@/store/modules/login.store";
import CustomFieldListForm from "@/components/report/CustomFieldListForm.vue";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";

type ReportImageTypeKeys = keyof typeof ReportImageType;
type FileUploadConfiguration = {
  [Property in ReportImageTypeKeys]: {
    example: string;
    overlay: string;
    text: string;
    textAlt: string;
    handleUploadCb: (file: File) => boolean;
    handleDeleteCb: (file: File) => boolean;
    displayImages: IImageUploaded[];
    filesCb: IImageUploaded[];
    isRequired?: boolean;
    isMultiple?: boolean;
    maxFiles: number;
  };
};

@Component({
  components: {
    BookingNavigation,
    CustomFieldListForm,
    BookingBackButton,
    FileUploadPreview,
    NumberplateForm,
    FileUploadMulti
  }
})
export default class BookingForm extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  /** Attachments properties */
  fileUploadConfiguration: FileUploadConfiguration = {} as any;

  /**
   * Customer fields config
   */
  customerFieldConfigMap: Map<
    keyof BookingCustomerInformationDtoGen,
    BookingCustomerFieldConfigurationOptionsDtoGen
  > = new Map<keyof BookingCustomerInformationDtoGen, BookingCustomerFieldConfigurationOptionsDtoGen>();

  numberPlateValid = false;

  isBookingCustomFieldsValid = false;

  /**
   * Privacy policy fields
   */
  acceptedPrivacyPolicy = false;

  get description() {
    return String(
      this.$t("report.summary.description", {
        privacy: "<a target='blank()' href='#/privacy'>" + this.$t("report.summary.privacyPolicy") + "</a>."
      })
    );
  }

  get isAttachmentsEnabled() {
    return FeatureModule.attachmentsOnlineBooking;
  }

  get partner() {
    return ReportModule.partner || PartnerModule.partner;
  }

  get partnerId() {
    return this.partner._id;
  }

  get height() {
    if (this.isMobile) {
      return 530;
    }
    return 5000;
  }

  get isScrollable() {
    if (this.isMobile) {
      return "scrollable-content";
    }
    return undefined;
  }

  get titleClass() {
    if (!this.isMobile) {
      return "headline font-weight-bold mx-4";
    }
    return "headline font-weight-bold ml-2 mr-1";
  }

  get disabled() {
    return AvailabilityModule.bookingFormDisabled;
  }

  /**
   * mounted is not executing when we change to another service without refreshing
   */
  @Watch("selectedServiceCustomerFieldConfig", { deep: true })
  async updateCustomerFieldConfigMap() {
    await this.generateCustomerFieldConfigMapAndSetupFields();
  }

  async mounted() {
    this.createFileUploadConfigurations();

    await this.generateCustomerFieldConfigMapAndSetupFields();
  }

  createFileUploadConfigurations() {
    if (!this.imageConfig || this.imageConfig?.length === 0) {
      return;
    }

    this.imageConfig.forEach(imageConfiguration => {
      /** Create file upload for each image config type */
      this.fileUploadConfiguration[imageConfiguration.type] = {
        example: this.$t(`image.${imageConfiguration.type}.example`).toString(),
        overlay: this.$t(`image.${imageConfiguration.type}.overlay`).toString(),
        text: `image.${imageConfiguration.type}.text`,
        textAlt: `image.${imageConfiguration.type}.alt`,
        handleUploadCb: async (file: File) => {
          await this.handleUpload(file, imageConfiguration.type);
        },
        handleDeleteCb: async (file: IImageUploaded) => {
          await this.handleDelete(file, imageConfiguration.type);
        },
        isRequired: imageConfiguration.configuration.isRequired,
        isMultiple: imageConfiguration.configuration.isMultiple,
        maxFiles: imageConfiguration.configuration.isMultiple ? null : 1,
        filesCb: [],
        displayImages: []
      };
    });
  }

  /** Return array by image type */
  getDisplayImagesByType(type: string) {
    return this.fileUploadConfiguration[type]?.displayImages?.slice() || [];
  }

  /** Return array by image type */
  getFilesCbByType(type: string) {
    return this.fileUploadConfiguration[type]?.filesCb.slice() || [];
  }

  back() {
    if (this.isMobile) {
      AvailabilityModule.setSlots();
      return;
    }
    AvailabilityModule.setCalender();
  }

  forward() {
    this.bookSlot();
  }

  get serviceId() {
    return AvailabilityModule.serviceId;
  }

  set serviceId(id: string) {
    AvailabilityModule.setServiceId(id);
  }

  get customerInformation() {
    return AvailabilityModule.customerInformation;
  }

  set customerInformation(info: BookingCustomerInformationDtoGen) {
    AvailabilityModule.setCustomerInformation(info);
  }

  get attachments() {
    return AvailabilityModule.attachments;
  }

  set attachments(fileIds: string[]) {
    AvailabilityModule.setAttachments(fileIds);
  }

  get bookingInformation() {
    return AvailabilityModule.bookingInformation;
  }

  set bookingInformation(info: BookingBookingInformationDtoGen) {
    AvailabilityModule.setBookingInformation(info);
  }

  get values() {
    return AvailabilityModule.values;
  }

  set values(values: BookingCustomFieldValueGen[]) {
    AvailabilityModule.setValues(values);
  }

  get isValid() {
    /** Include the number plate in the overall form validation */
    if (this.customerFieldConfigMap.get("numberPlate")?.isRequired) {
      return AvailabilityModule.isValid && this.numberPlateValid;
    }

    // FIXME: check for valid images
    // return AvailabilityModule.isValid && this.imagesValid;
    return AvailabilityModule.isValid && this.isBookingCustomFieldsValid;
  }

  set isValid(value: boolean) {
    AvailabilityModule.setIsValid(value);
  }

  get imageConfig() {
    return this.selectedService?.imageConfig || [];
  }

  selectedSlotDate() {
    if (this.selectedSlot) {
      return detailedDateWithDay(this.displayTimeSlot(this.selectedSlot).toISOString(), this.$t("locale").toString());
    }
    return "";
  }

  selectedSlotStartTime() {
    if (this.selectedSlot) {
      const from = formatHoursAndMinutes(this.displayTimeSlot(this.selectedSlot));
      const to = formatHoursAndMinutes(new Date(this.selectedSlot.end));
      return this.$t("bookingForm.common.slot", { from, to });
    }
    return "";
  }

  displayTimeSlot(slot: BookingSlotViewModelGen): Date {
    const slotTime = new Date(slot.start);

    return slotTime;
  }

  storeCurrentEmail(event: any) {
    LoginModule.setCurrentMail(event.target.value);
  }

  async handleUpload(fileToUpload: File, type: string) {
    const data = {
      file: fileToUpload,
      type: type as any
    };

    const response = await AvailabilityModule.uploadImageAttachment({ partnerId: this.partnerId, data });

    this.fileUploadConfiguration[type].displayImages.push({
      file: fileToUpload,
      uploadId: response.imageId,
      isUploaded: true,
      isLoading: false
    });

    this.$forceUpdate();
    return true;
  }

  async handleDelete(file: IImageUploaded, type: string) {
    const displayFilesArray: IImageUploaded[] = this.fileUploadConfiguration[type].displayImages;
    const indexOfFile = displayFilesArray.indexOf(file);
    displayFilesArray.splice(indexOfFile, 1);
    this.$forceUpdate();
    return true;
  }

  get selectedSlot() {
    return AvailabilityModule.selectedSlot;
  }

  set selectedSlot(slot: BookingSlotViewModelGen | undefined) {
    AvailabilityModule.setSelectedSlot(slot);
  }

  get date() {
    return AvailabilityModule.date;
  }

  set date(newDate: string | null) {
    AvailabilityModule.setDate(newDate);
  }

  get selectedService() {
    return AvailabilityModule.selectedService;
  }

  //#region Customer field config

  get selectedServiceCustomerFieldConfig() {
    return this.selectedService?.customerFieldConfig;
  }

  /**
   * Setup the column width and order based on the configuration we have for the service.
   * If names are inactive(first, last) email should be full width(col-12)
   */
  setupColumnWidthAndOrder() {
    // Reset the email field 50% width class
    const emailClassList = (this.$refs["email-col"] as HTMLElement).classList;

    if (!emailClassList.contains("col-md-6")) {
      emailClassList.add("col-md-6");
    }

    /**
     * Take all refs(column elements).
     * If we have some fields set as `isActive: false`, the $refs will not store the element(as it is not visible).
     * So, in this function, we don't make any use of the configuration object.
     * Basically, make the form to look good. For now, this means
     * to not have fields "hanging in the air".
     * Email is always visible, so we'll at least 1 field and at most 4 fields.
     * If we have 2 fields, it looks good, but if we have 3, we have to make the last one
     * full width. In our case we can make a field full width by removing the class col-md-6.
     */
    const values = Object.values(this.$refs).filter(r => !!r);

    if (values.length % 2 !== 0) {
      // Sort ref HTML elements by order
      values.sort((refOne, refTwo) => {
        const refOneClassListArray = Array.from((refOne as HTMLElement).classList);
        const refTwoClassListArray = Array.from((refTwo as HTMLElement).classList);

        // Example: order-1, order-2, order-8
        // Here lowest number means higher order
        const refOneOrderClass = refOneClassListArray.find(refOneClass => refOneClass.includes("order")) || "";
        const refTwoOrderClass = refTwoClassListArray.find(refTwoClass => refTwoClass.includes("order")) || "";

        return refOneOrderClass > refTwoOrderClass ? 1 : -1;
      });

      const lastColElement = values[values.length - 1] as HTMLElement;

      if (lastColElement) {
        lastColElement.classList.remove("col-md-6");
      }
    }
  }

  //#endregion

  get color() {
    return getDefaultPartnerColor();
  }

  get slotsForDay(): BookingSlotViewModelGen[] {
    if (!AvailabilityModule.slots) {
      return [];
    }

    // Slots for day should compare the hour as well
    const slotsForDay = AvailabilityModule.slots.filter(slot => {
      return new Date(slot.start).toISOString().substring(0, 10) === this.date && new Date(slot.start) >= new Date();
    });

    return slotsForDay;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get firstNameRule() {
    return this.customerFieldConfigMap.get("firstName")?.isRequired ? this.requiredRule : [];
  }

  get lastNameRule() {
    return this.customerFieldConfigMap.get("lastName")?.isRequired ? this.requiredRule : [];
  }

  get emailRule() {
    return [requiredRule(), emailRule()];
  }

  get phoneRule() {
    return this.customerFieldConfigMap.get("phone")?.isRequired ? [requiredRule(), phoneRule()] : [phoneRule()];
  }

  get loading() {
    return AvailabilityModule.isLoadingServices;
  }

  get hasRequiredImages() {
    const hasAnyImagesRequired = this.imageConfig.some(imageCon => imageCon?.configuration?.isRequired);

    return hasAnyImagesRequired;
  }

  /**
   * FIXME: Not triggered on upload/delete
   * check if: Every image type which is required also has images stored
   * Example:
   * 'registration' : {
   *  ...config,
   *  isRequired: true
   *  displayImages: []
   * }
   * this check should return false in this case. when we add a file it should return true
   * FIXME 2: after handling isMultiple we should check for length > 1 as well
   */
  get imagesValid() {
    if (!this.hasRequiredImages) {
      return true;
    }

    const isValid = Object.values(this.fileUploadConfiguration).every(
      value => value.isRequired && value.displayImages.length > 0
    );

    return isValid;
  }

  bookSlot() {
    if (!this.isValid) {
      this.$toast.error("Not valid");
      throw new Error("not valid");
    }

    if (!this.selectedSlot) {
      this.$toast.error("No slot");
      throw new Error("no slot");
    }

    // Check for required images

    // store uploadIds to attachments
    const fileConfigurations = Object.values(this.fileUploadConfiguration);
    const uploadImageIds = fileConfigurations.map(value => value.displayImages.map(d => d.uploadId));

    this.attachments = uploadImageIds?.flat() || [];

    const dto: BookingCreateBookingWithoutResourceDtoGen = {
      serviceId: this.serviceId,
      start: this.selectedSlot.start,
      end: this.selectedSlot.end,
      customerInformation: this.customerInformation,
      bookingInformation: this.bookingInformation,
      values: this.values,
      attachments: this.attachments,
      language: this.$i18n.locale as LanguageCodeEnum
    };

    this.$emit("submit", dto);
  }

  private async generateCustomerFieldConfigMapAndSetupFields() {
    /** create map form config */
    this.selectedServiceCustomerFieldConfig?.forEach(serviceConfiguration =>
      this.customerFieldConfigMap.set(
        serviceConfiguration.field as keyof BookingCustomerInformationDtoGen,
        serviceConfiguration.configuration
      )
    );

    // Update all refs after we know the config for the fields
    await this.$nextTick();

    // We need grid adjustments only for desktop
    if (!this.isMobile) {
      this.setupColumnWidthAndOrder();
    }
  }
}
