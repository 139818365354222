











































































import BookingCalendar from "@/components/booking/customer/BookingCalendar.vue";
import BookingForm from "@/components/booking/customer/BookingForm.vue";
import BookingServices from "@/components/booking/customer/BookingServices.vue";
import BookingSlots from "@/components/booking/customer/BookingSlots.vue";
import BookingStart from "@/components/booking/customer/BookingStart.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import {
  BookingServiceBookingViewModelGen,
  BookingCreateBookingWithoutResourceDtoGen
} from "@/services/booking/v1/data-contracts";
import { BookingScreenEnum, BookingStepEnum } from "@/store/enum/bookingScreenEnum";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BookingSuccess from "./BookingSuccess.vue";
import { handleError } from "@/lib/utility/handleError";
import { ReportModule } from "@/store/modules/report.store";
import { PartnerModule } from "@/store/modules/partner";
import { NotFoundException } from "@/lib/exceptions/http";

@Component({
  components: {
    LayoutSimple,
    BookingStart,
    BookingCalendar,
    BookingServices,
    BookingSlots,
    BookingForm,
    BookingSuccess
  }
})
export default class BookingProcess extends Vue {
  /**
   * Navigation on Desktop:
   * Start (and Services) -> Calender (and Slots) -> Form
   *
   * Navigation on Mobile:
   * Start -> Services -> Calender -> Slots -> Form
   */

  bookingScreenEnum = BookingScreenEnum;
  bookingStepEnum = BookingStepEnum;

  @Prop({ default: false })
  flat!: boolean;

  @Prop({ default: "" })
  bgColor!: string;

  @Prop({ default: false })
  dark!: boolean;

  /**
   * Flag if booking is used in an iframe(design should not change)
   */
  @Prop({ default: false })
  bookingIframe!: boolean;

  availabilityOptions: { date?: Date; isSetDate?: boolean } = { date: undefined, isSetDate: undefined };

  get partner() {
    return ReportModule.partner || PartnerModule.partner;
  }

  get partnerId() {
    return this.partner._id;
  }

  get step() {
    return AvailabilityModule.currentStep;
  }

  set step(slot: BookingStepEnum) {
    AvailabilityModule.setCurrentStep(slot);
  }

  get secondWindow() {
    return AvailabilityModule.secondWindow;
  }

  set secondWindow(value: BookingScreenEnum) {
    AvailabilityModule.setSecondWindow(value);
  }

  get bookableServices(): BookingServiceBookingViewModelGen[] {
    return AvailabilityModule.paginationList;
  }

  get isMobile() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return true;
      case "sm":
        return true;
      case "md":
        return false;
      case "lg":
        return false;
      case "xl":
        return false;
      default:
        return false;
    }
  }

  @Watch("step")
  async fetchAvailableSlotsWhenNeeded(step: BookingStepEnum, prevStep: BookingStepEnum) {
    /**
     * fetch slots if we are landing on the calendar view or on the slots view(mobile) and we're
     * navigating back from the booking form
     */
    if (step === BookingStepEnum.CALENDER || (step === BookingStepEnum.SLOTS && prevStep === BookingStepEnum.FORM)) {
      await this.checkAvailability(this.availabilityOptions);
    }
  }

  async mounted() {
    this.availabilityOptions.isSetDate = !this.isMobile;
    await AvailabilityModule.refresh();
  }

  async checkAvailability(options: { date?: Date; isSetDate?: boolean }) {
    AvailabilityModule.setIsLoadingSlots(true);

    try {
      await AvailabilityModule.findOneByPartner({
        partnerId: this.partnerId,
        id: AvailabilityModule.serviceId,
        date: options?.date?.toISOString() || new Date().toISOString()
      });
      /**
       * Only set first date initial on desktop
       */
      if (AvailabilityModule.slots.length && options.isSetDate) {
        AvailabilityModule.setFirstAvailableDay();
      }

      if (!options.isSetDate && this.step !== BookingStepEnum.SLOTS) {
        AvailabilityModule.setDate(null);
      }
    } catch (error) {
      if (error instanceof NotFoundException) {
        Vue.$toast.error(String(this.$t("bookingForm.BookingProcess.serviceNotFound")));
        AvailabilityModule.setServices();
      } else {
        handleError(error);
      }
    } finally {
      AvailabilityModule.setIsLoadingSlots(false);
    }
  }

  async onDateChange(date: Date) {
    this.availabilityOptions = {
      ...this.availabilityOptions,
      date
    };

    /** Fetch availability when changing dates in the calendar */
    await this.checkAvailability(this.availabilityOptions);
  }

  async onBookSlot(booking: BookingCreateBookingWithoutResourceDtoGen) {
    AvailabilityModule.setIsloadingServices(true);
    AvailabilityModule.setBookingFormDisabled(true);

    try {
      await AvailabilityModule.create({ partnerId: this.partnerId, dto: booking });

      // redirect to booking success view
      AvailabilityModule.setSuccess();
    } catch (error) {
      if (error instanceof NotFoundException) {
        Vue.$toast.error(String(this.$t("bookingForm.BookingProcess.slotMissingErrorMessage")));
      } else {
        handleError(error);
      }
    } finally {
      AvailabilityModule.setIsloadingServices(false);
      AvailabilityModule.setBookingFormDisabled(false);
    }
  }
}
