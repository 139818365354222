

































import { Component, Vue, Prop } from "vue-property-decorator";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { UserModule } from "@/store/modules/me-user.store";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {}
})
export default class LoginRegisterButton extends Vue {
  @Prop({ default: "0" })
  elevation!: string;
  @Prop({ default: true })
  dark!: boolean;
  @Prop({ default: true })
  outlined!: boolean;
  @Prop({ default: true })
  large!: boolean;
  @Prop({ default: false })
  small!: boolean;
  @Prop({ default: false })
  rounded!: boolean;
  @Prop({ default: false })
  block!: boolean;

  @Prop({ default: "report.home.login" })
  loginButtonText!: string;
  @Prop({ default: "report.home.createAccount" })
  createAccountButtonText!: string;

  @Prop({ default: "flex-grow-1" })
  htmlClass!: string;

  @Prop({ default: true })
  showRegisterButton!: boolean;

  get showLoginButton(): boolean {
    const isCustomerSignup = PartnerModule.partner?.settings?.bookedServices.customer ?? false;
    const isThg = ConfigModule.appContext === AppContextEnum.THG;
    return isThg ? !UserModule.isAuthenticated : !UserModule.isAuthenticated && isCustomerSignup;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  mounted() {
    if (ConfigModule.appContext === AppContextEnum.THG) {
      this.htmlClass += " hidden-xs-only";
    }
  }

  goToLogin() {
    this.$router.push({ path: "/login" });
  }

  goToRegister() {
    this.$router.push({ path: "/sign-up" });
  }
}
