import { ISetPasswordBySuffix } from "@/lib/interfaces/User/setPasswordBySuffix.interface";
import { MrfiktivSignUpUserDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import AdminSharedUserService from "@/services/shared/adminSharedUserService";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ThgSignUpUserDtoGen } from "./../../services/thg/v1/data-contracts";
import { GtagModule } from "./gtag.store";
import { UserModule } from "./me-user.store";

/**
 * Manages login inputs to prefill input fields
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "login",
  store
})
class Login extends VuexModule {
  /**
   * Value of mail field
   */
  _mail = "";

  /**
   * Value of an email entered either in a report or booking submission
   * This is used to prefill the login form.
   * Deleted on successful login.
   */
  _currentMail = "";

  /**
   * Value of code field for confirm mail/ reset password
   */
  _code = "";

  /**
   * Value of password field
   */
  _password = "";

  /**
   * Value of password repeat field for set/ reset password
   */
  _passwordRepeat = "";

  /**
   * Value of initial password field for set password
   */
  _passwordNew = "";

  /**
   * Url to redirect user to when he signed in after trying to access a resource that is protected by the permission guard
   */
  _redirect = "";

  /**
   * Method that is executed on login
   */
  _onLogin: Function | undefined = undefined;

  @Mutation
  private _mutateMail(mail: string) {
    this._mail = mail;
  }

  @Mutation
  private _mutateCurrentMail(currentMail: string) {
    this._currentMail = currentMail;
  }

  @Mutation
  private _mutateCode(code: string) {
    this._code = code;
  }

  @Mutation
  private _mutatePassword(password: string) {
    this._password = password;
  }

  @Mutation
  private _mutatePasswordRepeat(passwordRepeat: string) {
    this._passwordRepeat = passwordRepeat;
  }

  @Mutation
  private _mutatePasswordNew(passwordNew: string) {
    this._passwordNew = passwordNew;
  }

  @Mutation
  private _mutateOnLogin(onLogin: Function) {
    this._onLogin = onLogin;
  }

  @Mutation
  private _mutateRedirect(redirect: string) {
    this._redirect = redirect;
  }

  @Action
  setMail(mail: string) {
    this.context.commit("_mutateMail", mail);
  }

  @Action
  setCurrentMail(currentMail: string) {
    // If the user is logged in and we are not resetting the current mail field
    if (UserModule.isAuthenticated && currentMail) {
      return;
    }

    this.context.commit("_mutateCurrentMail", currentMail);
    this.context.commit("_mutateMail", currentMail);
  }

  @Action
  setCode(code: string) {
    this.context.commit("_mutateCode", code);
  }

  @Action
  setPassword(password: string) {
    this.context.commit("_mutatePassword", password);
  }
  @Action
  setPasswordRepeat(passwordRepeat: string) {
    this.context.commit("_mutatePasswordRepeat", passwordRepeat);
  }

  @Action
  setPasswordNew(passwordNew: string) {
    this.context.commit("_mutatePasswordNew", passwordNew);
  }

  @Action
  setPasswordBySuffix(setBySuffix: ISetPasswordBySuffix) {
    this.context.commit(`_mutatePassword${setBySuffix.suffix}`, setBySuffix.password);
  }

  @Action
  setOnLogin(onLogin: Function) {
    this.context.commit("_mutateOnLogin", onLogin);
  }

  @Action
  setRedirect(redirect: string) {
    this.context.commit("_mutateRedirect", redirect);
  }

  @Action
  purgeStore() {
    this.setMail("");
    this.setCode("");
    this.setPassword("");
    this.setPasswordNew("");
    this.setPasswordRepeat("");
  }

  get mail() {
    return this._mail;
  }

  get currentMail() {
    return this._currentMail;
  }

  get code() {
    return this._code;
  }

  get password() {
    return this._password;
  }

  get passwordNew() {
    return this._passwordNew;
  }

  get passwordRepeat() {
    return this._passwordRepeat;
  }

  get onLogin() {
    return this._onLogin;
  }

  get redirect() {
    return this._redirect;
  }

  @Action
  async signUp(data: MrfiktivSignUpUserDtoGen | ThgSignUpUserDtoGen) {
    await AdminSharedUserService.signUp(data);
    GtagModule.dispatchSignupEvent();
  }
}

export const LoginModule = getModule(Login);
