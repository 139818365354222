import { SignHttpClientProvider } from "../sign-http-client.provider";
import { DigitalSignatureActivityService } from "../v1/DigitalSignatureActivityService";
import { DigitalSignatureService } from "../v1/DigitalSignatureService";
import { DigitalSignatureSignerService } from "../v1/DigitalSignatureSignerService";
import { HttpClient } from "../v1/http-client";
import { AbstractHttpClientProvider } from "./../sign-http-client.provider";
import { SignCreateSignActivityDtoGen, SignCreateSignDtoGen } from "./../v1/data-contracts";

/**
 * Service to communicate with backend.
 */
export class SignService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for communicating with backend.
   */
  proxy: DigitalSignatureService;

  proxySigner: DigitalSignatureSignerService;

  proxySignActivity: DigitalSignatureActivityService;

  /**
   * @class Initialize Service
   */
  constructor(clientProvider: AbstractHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new DigitalSignatureService(this.client);
    this.proxySigner = new DigitalSignatureSignerService(this.client);
    this.proxySignActivity = new DigitalSignatureActivityService(this.client);
  }

  /**
   * Sets a new proxy based on the client
   *
   * @param clientProvider the client provider
   */
  setProxy(clientProvider: AbstractHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new DigitalSignatureService(this.client);
  }

  /**
   * Creates a signature.
   *
   * @param partnerId the partner
   * @param signRequestId the request
   * @param data the document dto
   */
  async create(partnerId: string, signRequestId: string, data: SignCreateSignDtoGen) {
    return this.proxySigner.signerControllerCreate(partnerId, signRequestId, data).then(value => value.data);
  }

  /**
   * Creates a signature activity.
   *
   * @param signRequestId the request
   * @param data the document dto
   */
  async createActivity(signRequestId: string, data: SignCreateSignActivityDtoGen) {
    return this.proxySignActivity.signActivityControllerCreate(signRequestId, data).then(value => value.data);
  }

  /**
   * Gets signatures by request for a partner.
   *
   * @returns the documents of the partner
   */
  async getAllForPartner(partnerId: string, signRequestId: string) {
    return this.proxy.signControllerFindAll(partnerId, signRequestId).then(value => value.data);
  }

  /**
   * Gets one signature for a request of the partner.
   *
   * @param id the document id
   * @returns one document of the partner
   */
  async getOneForPartner(partnerId: string, signRequestId: string, id: string) {
    return this.proxy.signControllerFindOne(partnerId, signRequestId, id).then(value => value.data);
  }

  /**
   * Gets one signature for a request of the partner that references a document.
   *
   * @param partnerId the partnerId
   * @param documentId the documentId
   * @returns one document of the partner
   */
  async findOneByDocument(partnerId: string, documentId: string) {
    return this.proxy.signControllerFindOneByDocument(partnerId, documentId).then(value => value.data);
  }

  /**
   * Gets one signature for a request of the partner.
   *
   * @param partnerId the partner id
   * @param signRequestId the document id
   * @returns one document of the partner
   */
  async find(partnerId: string, signRequestId: string) {
    return this.proxySigner
      .signerControllerFindRequestForSignerByPartnerId(partnerId, signRequestId)
      .then(value => value.data);
  }

  /**
   * Gets a signature for a signed request of the partner.
   *
   * @param partnerId the partner id
   * @param signRequestId the document id
   * @returns one document of the partner
   */
  async findSigned(partnerId: string, signRequestId: string) {
    return this.proxySigner.signerControllerFindSignedRequest(partnerId, signRequestId).then(value => value.data);
  }

  /**
   * Update a status to delivered
   * @param partnerId
   * @param signRequestId
   * @returns
   */
  async setDelivered(partnerId: string, signRequestId: string) {
    return this.proxySigner
      .signerControllerSetSignedDocumentDelivered(partnerId, signRequestId)
      .then(value => value.data);
  }
}

/**
 * Service to communicate with backend.
 */
export default new SignService(new SignHttpClientProvider());
