



































































import { ICustomRouteConfig } from "@/store/interface/custom.route.config.interface";
import { NavigationModule } from "@/store/modules/navigation.store";
import { PartnerModule } from "@/store/modules/partner";
import Fuse from "fuse.js";
import { Component, Prop, Vue } from "vue-property-decorator";
import NavigationListItem from "./NavigationListItem.vue";

@Component({
  components: {
    NavigationListItem
  }
})
export default class QuickLinkList extends Vue {
  @Prop({ default: false })
  limitDisplay!: boolean;

  @Prop({ default: false })
  parseQuery!: boolean;

  searchPattern = "";

  goTo(route: ICustomRouteConfig) {
    this.$router.push({ name: route.name, params: { partnerId: this.partnerId } });
  }

  goToQuickLinks() {
    this.$router.push({ name: "QuickLinks", params: { partnerId: this.partnerId } });
  }

  get navigationCon() {
    return NavigationModule.navigationConfiguration;
  }

  mounted() {
    if (this.parseQuery) {
      const value = this.$route.query["search"];

      if (Array.isArray(value) && value.length) {
        this.searchPattern = value[0] as string;
      } else if (value) {
        this.searchPattern = value as string;
      }
    }
  }

  get partnerId() {
    return PartnerModule.partner._id;
  }

  get filteredList() {
    const fuseOptions = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ["title", "description", "tags", "category"]
    };

    const fuse = new Fuse(this.allQuickLinks, fuseOptions);

    if (this.limitDisplay) {
      return fuse.search(this.searchPattern, { limit: 5 });
    } else {
      return fuse.search(this.searchPattern);
    }
  }

  /**
   *
   */
  get forUserContextAvailableQuickLinksWithSubheaders() {
    return NavigationModule.quickLinksWithSubheader;
  }

  get quickLinkCategories() {
    return NavigationModule.quickLinkCategories;
  }

  get favLinks() {
    if (this.limitDisplay) {
      if (this.allQuickLinks.length > 5) {
        return this.allQuickLinks.slice(0, 5);
      }
    }
    return this.allQuickLinks;
  }

  get allQuickLinks() {
    return NavigationModule.quickLinks;
  }
}
