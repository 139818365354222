import { IManufacturerDto } from "@/lib/interfaces/Docphant/manufacturer.dto.interface";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import ManufacturerService from "@/services/mrfiktiv/services/manufacturerService";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { IManufacturerUpdateStoreDto } from "../interface/manufacturer.update.store.interface";

/**
 * Manages the machine document
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "manufacturer",
  store
})
class Manufacturer extends VuexModule {
  manufacturerList: IManufacturer[] = [];
  manufacturer: IManufacturer = {
    id: "",
    name: "",
    address: {
      state: "",
      street: "",
      zip: "",
      city: ""
    },
    contact: {
      email: "",
      phone: ""
    },
    logoUrl: "",
    timestamps: {
      created: new Date()
    }
  } as any;

  get getCurrentManufacturer(): IManufacturer {
    return this.manufacturer;
  }

  /**
   * Updates the stored manufacturer
   * @param machine
   */
  @Mutation
  setManufacturer(manufacturer: IManufacturer) {
    this.manufacturer = manufacturer;
  }
  /**
   * Gets a manufacturer from the API and sets it to the local store
   * @param manufacturerId the id of the manufacturer that the machine belongs to
   * @param machineId the id of the machine
   */
  @Action
  async getManufacturer(id: string) {
    const manufacturer = await ManufacturerService.getOne(id);
    this.context.commit("setManufacturer", manufacturer);
  }

  /**
   * Updates the stored manufacturer
   * @param machine
   */
  @Mutation
  setAllManufacturer(manufacturerList: IManufacturer[]) {
    this.manufacturerList = manufacturerList;
  }

  /**
   * Creates a Manufacturer and sets to store
   * @param manufacturerId the id of the manufacturer that the machine belongs to
   * @param machineId the id of the machine
   */
  @Action
  async createManufacturer(manufacturerDto: IManufacturerDto) {
    const manufacturer = await ManufacturerService.create(manufacturerDto);
    this.context.commit("setManufacturer", manufacturer);
  }

  /**
   * Updates a Manufacturer and refresh Store
   * @param manufacturerId the id of the manufacturer that the machine belongs to
   * @param machineId the id of the machine
   */
  @Action
  async updateManufacturer(manufacturerUpdateStoreDto: IManufacturerUpdateStoreDto) {
    const updatedManufacturer = await ManufacturerService.update(
      manufacturerUpdateStoreDto.manufacturerId,
      manufacturerUpdateStoreDto.dto
    );
    this.context.commit("setManufacturer", updatedManufacturer);
  }

  /**
   * Deletes a manufacturer
   * Returns true if sucessfull, otherwise false
   * @param manufacturerId the id of the manufacturer that the machine belongs to
   * @param machineId the id of the machine
   */
  @Action
  async deleteManufacturer(manufacturerId: string): Promise<boolean> {
    const isDeleted = await ManufacturerService.delete(manufacturerId);
    await this.getAllManufacturer();
    return isDeleted;
  }

  /**
   * Gets a manufacturer from the API and sets it to the local store
   * @param manufacturerId the id of the manufacturer that the machine belongs to
   * @param machineId the id of the machine
   */
  @Action
  async getAllManufacturer() {
    const manufacturerList = await ManufacturerService.getAll();

    this.context.commit("setAllManufacturer", manufacturerList);
  }
}

export const ManufacturerModule = getModule(Manufacturer);
