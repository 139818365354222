/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BookingBookingDetailViewModelGen,
  BookingBookingUnauthenticatedViewModelGen,
  BookingExceptionViewmodelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class MyBookingService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags My Booking Service
   * @name BookingMeControllerFindMyBookings
   * @summary Gets all bookings for a user.
   * @request GET:/booking/me
   * @secure
   * @response `200` `(BookingBookingUnauthenticatedViewModelGen)[]` Bookings for a customer
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  bookingMeControllerFindMyBookings = (cancelled: boolean, params: RequestParams = {}) =>
    this.http.request<BookingBookingUnauthenticatedViewModelGen[], BookingExceptionViewmodelGen>({
      path: `/booking/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags My Booking Service
   * @name BookingMeControllerFindMyBooking
   * @summary Gets a booking for a user.
   * @request GET:/booking/me/{id}
   * @secure
   * @response `200` `BookingBookingDetailViewModelGen` Booking for a customer
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `401` `BookingExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `BookingExceptionViewmodelGen` Forbidden.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  bookingMeControllerFindMyBooking = (id: string, params: RequestParams = {}) =>
    this.http.request<BookingBookingDetailViewModelGen, BookingExceptionViewmodelGen>({
      path: `/booking/me/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
