var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('booking-selected-service', {
    attrs: {
      "showBackButton": _vm.showNavigation
    }
  }), _vm.isLoadingSlots ? _c('v-row', {
    staticStyle: {
      "height": "500px"
    }
  }, [_c('v-skeleton-loader', {
    attrs: {
      "width": "100%",
      "type": "article, list-item-two-line, list-item-two-line,list-item-two-line ,list-item-two-line"
    }
  })], 1) : !_vm.slotsForDay.length && _vm.date ? _c('v-row', {
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("bookingForm.BookingSlots.noAppointments")) + " ")])], 1) : !_vm.date ? _c('v-row', {
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-card-text', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("bookingForm.BookingSlots.selectDate")) + " ")])], 1) : _c('div', {
    staticStyle: {
      "max-height": "500px"
    }
  }, [_c('v-card-text', {
    staticClass: "title font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("bookingForm.BookingSlots.selectTime")))]), _c('v-virtual-scroll', {
    staticClass: "overflow-y-auto mb-4",
    attrs: {
      "height": _vm.height,
      "item-height": _vm.itemHeight,
      "items": _vm.slotsForDay
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-list', [_c('v-list-item', {
          key: "item"
        }, [item && item.start ? _c('v-btn', {
          attrs: {
            "block": "",
            "large": !_vm.isMobile,
            "elevation": "0",
            "outlined": "",
            "color": _vm.color
          },
          on: {
            "click": function click($event) {
              return _vm.selectSlot(item);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatSlotDate(item)) + " ")]) : _vm._e()], 1)], 1)];
      }
    }])
  })], 1), _vm.showNavigation ? _c('booking-navigation', {
    attrs: {
      "isMobile": _vm.isMobile
    },
    on: {
      "back": _vm.back,
      "forward": _vm.forward
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }