

















import { BookingSlotViewModelGen } from "@/services/booking/v1/data-contracts";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { Component, Vue, Prop } from "vue-property-decorator";
import BookingBackButton from "./BookingBackButton.vue";
@Component({
  components: {
    BookingBackButton
  }
})
export default class BookingSelectedService extends Vue {
  @Prop({ default: true })
  showBackButton!: boolean;

  get selectedService() {
    return AvailabilityModule.selectedService;
  }

  goToServiceOverview() {
    AvailabilityModule.setServices();
  }

  get selectedSlot() {
    return AvailabilityModule.selectedSlot;
  }

  set selectedSlot(slot: BookingSlotViewModelGen | undefined) {
    AvailabilityModule.setSelectedSlot(slot);
  }
}
