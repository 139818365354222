























import { Component, Vue, Prop } from "vue-property-decorator";
import LoginCardDescription from "@/components/login/LoginCardDescription.vue";
import { LoginModule } from "@/store/modules/login.store";

@Component({
  components: { LoginCardDescription }
})
export default class LoginCard extends Vue {
  @Prop({})
  onLogin!: Function;

  @Prop({ default: "logo" })
  logo!: string;

  @Prop({ default: "title" })
  title!: string;

  @Prop({ default: "text" })
  text!: string;

  @Prop({ default: false })
  isDescriptionCardActive?: boolean;

  @Prop({ default: false })
  showLogoOnMobile?: boolean;

  width = window.innerWidth;

  get isBreakpointReached() {
    return this.width >= 960;
  }

  onResize() {
    this.width = window.innerWidth;
  }

  beforeMount() {
    LoginModule.setOnLogin(this.onLogin);
  }

  beforeUnmount() {
    LoginModule.purgeStore();
  }
}
