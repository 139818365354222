/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { MrfiktivContactDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

/**
 * @inheritdoc
 */
export class CreateContactDto implements MrfiktivContactDtoGen {
  /**
   * @inheritdoc
   */
  email = "";
  /**
   * @inheritdoc
   */
  phone = "";

  constructor(contact?: MrfiktivContactDtoGen) {
    if (contact) {
      this.email = contact.email;
      this.phone = contact.phone || "";
    }
  }
}
