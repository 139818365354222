import { SignDocumentTokenDtoGen } from "@/services/sign/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface IPageAndToken {
  page: number;
  documentToken: SignDocumentTokenDtoGen;
}
@Module({
  dynamic: true,
  namespaced: true,
  name: "document-template",
  store
})
class DocumentTemplateStore extends VuexModule {
  /**
   * token index on current page that is hightlighted
   */
  private _highlightedToken = -1;

  get highlightedToken() {
    return this._highlightedToken;
  }

  /**
   * List of tokens per page
   */
  private _documentTokens: SignDocumentTokenDtoGen[][] = [];

  get documentTokens() {
    const highlightedToken = this._documentTokens[this._currentPage]?.[this.highlightedToken];

    const newTokenOrder = this._documentTokens.map(documentTokensPage =>
      documentTokensPage.sort((a, b) => {
        const verticalDifference = a.coordinates.y - b.coordinates.y;
        if (Math.round(verticalDifference) === 0) {
          return a.coordinates.x - b.coordinates.x;
        }
        return verticalDifference;
      })
    );

    if (highlightedToken) {
      const newHighlightedTokenIndex = this._documentTokens[this._currentPage].findIndex(
        t =>
          t.token === highlightedToken.token &&
          t.coordinates.y === highlightedToken.coordinates.y &&
          t.coordinates.x === highlightedToken.coordinates.x
      );
      if (newHighlightedTokenIndex !== this.highlightedToken) {
        DocumentTemplateModule.setHighlightedToken(newHighlightedTokenIndex);
      }
    }

    return newTokenOrder;
  }

  private _currentPage = 0;

  get currentPage() {
    return this._currentPage;
  }

  /**
   * Adds an empty list of document tokens for each page
   * @param pages
   */
  @Action
  initializeEmptyDocumentTokens(pages: number) {
    const emptyTokens: SignDocumentTokenDtoGen[][] = new Array(pages);
    for (let i = 0; i < pages; i++) {
      emptyTokens[i] = [];
    }
    this._documentTokens.splice(0, this._documentTokens.length, ...emptyTokens);
  }

  /**
   * Adds list of document tokens for each page
   */
  @Action
  initializeDocumentTokens(tokens: SignDocumentTokenDtoGen[][]) {
    this._documentTokens.splice(0, this._documentTokens.length, ...tokens);
  }

  @Action
  addDocumentToken({ page, documentToken }: IPageAndToken) {
    this._documentTokens[page].push(documentToken);

    return { token: documentToken, position: this._documentTokens[page].length - 1 };
  }

  @Mutation
  private _mutateHighlightedToken(index: number) {
    this._highlightedToken = index;
  }

  @Action
  setHighlightedToken(index: number) {
    this.context.commit("_mutateHighlightedToken", index);
  }

  @Action
  removeDocumentToken({ page, documentToken }: IPageAndToken) {
    const documentTokens = this.documentTokens[page];
    const index = documentTokens.findIndex(t => JSON.stringify(t) === JSON.stringify(documentToken));

    if (index > -1) {
      this._documentTokens[page].splice(index, 1);
    }
  }

  @Mutation
  private _mutateCurrentPage(page: number) {
    this._currentPage = page;
  }

  @Action
  setCurrentPage(page: number) {
    this.context.commit("_mutateCurrentPage", page);
  }
}

export const DocumentTemplateModule = getModule(DocumentTemplateStore);
