import { IdentityStatusEnum } from "@/lib/enum/identity-status.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import {
  MrfiktivIdentityInformationViewModelGen,
  MrfiktivShortUserViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";

@IsFilterable
class ShortUserBase implements MrfiktivShortUserViewModelGen, Partial<MrfiktivIdentityInformationViewModelGen> {
  @FilterConfig({
    displayName: "objects.user.id",
    type: FilterTypes.OBJECT_ID
  })
  id: string;

  @FilterConfig({
    displayName: "objects.user.userName",
    type: FilterTypes.STRING
  })
  userName: string;

  @FilterConfig({
    displayName: "objects.user.firstName",
    type: FilterTypes.STRING
  })
  firstName: string;

  @FilterConfig({
    displayName: "objects.user.lastName",
    type: FilterTypes.STRING
  })
  lastName: string;

  partnerId: string;

  @FilterConfig({
    displayName: "objects.user.status",
    type: FilterTypes.ENUM,
    config: {
      items: Object.values(IdentityStatusEnum)
    }
  })
  status?: IdentityStatusEnum;

  constructor(user?: Partial<MrfiktivShortUserViewModelGen & { partnerId: string }>) {
    this.id = user?.id ?? "";
    this.userName = user?.userName ?? "";
    this.firstName = user?.firstName ?? "";
    this.lastName = user?.lastName ?? "";
    this.partnerId = user?.partnerId ?? "";
  }
}

type IShortUser = ShortUserBase;
const ShortUser = Filter.createForClass(ShortUserBase);

export { IShortUser, ShortUser };
