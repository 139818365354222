/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Possible operations to compare values when filtering
 */
export enum PageFilterOperationEnum {
  EQUAL = "$eq",
  NOT_EQUAL = "$ne",
  LESS = "$lt",
  GREATER = "$gt",
  LESS_EQUAL = "$lte",
  GREATER_EQUAL = "$gte"
}
