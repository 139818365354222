
































































import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";
import { IUser } from "@/models/user.entity";
import { PartnerModule } from "@/store/modules/partner";
import { MrfiktivPermissionDtoGen as PermissionDto } from "@/services/mrfiktiv/v1/data-contracts";

@Component({
  components: {}
})
export default class LoginCardAuthorizationOverview extends Vue {
  constructor() {
    super();
  }

  get user(): IUser {
    return UserModule.user;
  }

  get permissions() {
    return this.user.permission || [];
  }

  get abilities() {
    return UserModule.abilities["$"];
  }

  getResourceName(permission: PermissionDto): string {
    const partner = PartnerModule.partnersMap.get(permission.id);

    if (partner) {
      return partner?.companyName;
    }

    return permission.id;
  }
}
