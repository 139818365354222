














import { detailedDateWithDay, formatHoursAndMinutes, simpleDate } from "@/lib/utility/date-helper";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { PartnerModule } from "@/store/modules/partner";
import { IAdminUser, IPartnerUser } from "@/models/user.entity";

@Component({
  components: {},
  filters: {
    simpleDate,
    detailedDateWithDay,
    formatHoursAndMinutes
  }
})
export default class RefsUser extends Vue implements IRefDetail<IPartnerUser | IAdminUser> {
  @Prop()
  item!: IPartnerUser | IAdminUser;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  goToDetail() {
    // FIXME: Go to detail of user
    new GoToHelper(this.$router).goToPartnerUser(PartnerModule.partner.id, true);
  }
}
