




















































import { IContactCustomer } from "@/components/utility/interface/contact-customer.interface";
import { ContactCustomerEnum } from "@/lib/enum/ContactCustomer.enum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { CustomerContactModule } from "@/store/modules/customer.contact.store";
import { PartnerModule } from "@/store/modules/partner";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Prop } from "vue-property-decorator";
import { ContactEntity } from "@/models/contactEntity";

@Component({
  components: {}
})
export default class ContactCustomerDialog extends DarkModeHighlightMixin {
  /**
   * Defines what templates are loaded
   */
  @Prop({})
  resource!: ContactCustomerEnum;

  /**
   * Defines if the Button to open the dialog is an icon or standard button
   */
  @Prop({ default: true })
  isIconButton!: boolean;

  /**
   * Defines if the Button to open the dialog is disabled or not
   */
  @Prop({ default: false })
  disabled!: boolean;

  /**
   * Customeremail where the email will be send
   */
  customerEmail = "";

  /**
   * Customeremail where the email will be send
   */
  @Prop()
  customerContact!: ContactEntity;

  /**
   * Defines the Text of the abort button
   */
  @Prop({ default: "Abbrechen" })
  abortButtonText!: string;

  /**
   * Defines the Url of an invitation of the report
   */
  @Prop({ default: "" })
  reportUrl!: string;

  /**
   * Name of the customer for the emailtemplate
   */
  @Prop({ default: "" })
  customerName!: string;

  /**
   * Name of the numberplate for the emailtemplate
   */
  @Prop({ default: "" })
  numberplate!: string;

  /**
   * Preference for a Workshop termin
   */
  @Prop({ default: "" })
  datePreference!: string;

  /**
   * Date of the request
   */
  @Prop({ default: "" })
  date!: string;

  dialog = false;
  isLoading = false;

  /**
   * Title of the Email
   */
  emailTitle = "";

  /**
   * Body of the Email
   */
  emailBody = "";

  /**
   * List of Email Templates
   */
  contactOpportunities: IContactCustomer[] = [];

  /**
   * Selected EmailTemplate
   */
  contactType: IContactCustomer = {
    id: "0",
    resource: ContactCustomerEnum.ALL,
    type: "custom",
    emailTitle: "",
    emailBody: "Hallo {customer},\n\n"
  };

  /**
   * Mapping of different Placeholders
   */
  placeholders = {
    date: this.date,
    customer: this.customerName,
    numberplate: this.numberplate,
    datePreference: this.datePreference,
    reportUrl: this.reportUrl || "",
    // This properties comes from store because they are the same if it's a report or form
    myName: UserModule?.user?.firstName + " " + UserModule?.user?.lastName,
    myEmail: UserModule?.user?.contact?.email || "",
    myPhone: UserModule?.user?.contact?.phone || "",
    openingMonday: PartnerModule?.partner?.settings?.openingHours?.monday || "",
    openingTuesday: PartnerModule?.partner?.settings?.openingHours?.tuesday || "",
    openingWednesday: PartnerModule?.partner?.settings?.openingHours?.wednesday || "",
    openingThursday: PartnerModule?.partner?.settings?.openingHours?.thursday || "",
    openingFriday: PartnerModule?.partner?.settings?.openingHours?.friday || "",
    openingSaturday: PartnerModule?.partner?.settings?.openingHours?.saturday || "",
    openingSunday: PartnerModule?.partner?.settings?.openingHours?.sunday || "",
    partnerName: PartnerModule?.partner?.companyName || ""
  };

  get loading() {
    return this.isLoading;
  }

  /**
   * Initialize the placeholders (and controll the state of the dialog)
   */
  actualizePlaceholder() {
    this.placeholders.date = this.date;
    this.placeholders.customer = this.customerName;
    this.placeholders.numberplate = this.numberplate;
    this.placeholders.datePreference = this.datePreference;
    this.placeholders.reportUrl = this.reportUrl || "";
    this.placeholders.myName = UserModule.user?.firstName + " " + UserModule.user?.lastName;
    this.placeholders.myEmail = UserModule.user?.contact?.email || "";
    this.placeholders.myPhone = UserModule.user?.contact?.phone || "";
    this.placeholders.openingMonday = PartnerModule.partner.settings?.openingHours.monday || "";
    this.placeholders.openingTuesday = PartnerModule.partner.settings?.openingHours.tuesday || "";
    this.placeholders.openingWednesday = PartnerModule.partner.settings?.openingHours.wednesday || "";
    this.placeholders.openingThursday = PartnerModule.partner.settings?.openingHours.thursday || "";
    this.placeholders.openingFriday = PartnerModule.partner.settings?.openingHours.friday || "";
    this.placeholders.openingSaturday = PartnerModule.partner.settings?.openingHours.saturday || "";
    this.placeholders.openingSunday = PartnerModule.partner.settings?.openingHours.sunday || "";
    this.placeholders.partnerName = PartnerModule.partner.companyName || "";
  }

  /**
   * Initialize the dialog and fetches the correct templates due to set ressourcetype
   */
  async initialize() {
    this.isLoading = true;

    this.customerEmail = this.customerContact?.email || "";

    this.contactOpportunities = await CustomerContactModule.getTemplates({
      partnerId: PartnerModule.partner._id,
      resource: this.resource
    });

    this.contactType = this.contactOpportunities[0];
    this.changeTemplate();
    this.isLoading = false;
  }

  /**
   * Changes the templates and replaces the placeholders
   */
  async changeTemplate() {
    this.isLoading = true;

    this.emailTitle = this.contactType.emailTitle;
    this.emailBody = this.contactType.emailBody;

    this.actualizePlaceholder();
    this.replacePlaceholders();

    this.isLoading = false;
  }

  /**
   * Changes all given Placeholders in the Temaplate
   */
  replacePlaceholders() {
    for (const key of Object.keys(this.placeholders)) {
      const placeholder = `{${key}}`;
      this.emailBody = this.emailBody.replaceAll(placeholder, this.placeholders[key]);
      this.emailTitle = this.emailTitle.replaceAll(placeholder, this.placeholders[key]);
    }
  }

  /**
   * Closes the dialog and set default values
   */
  close() {
    this.emailTitle = "";
    this.emailTitle = "";
    this.contactType = {
      id: "0",
      resource: ContactCustomerEnum.ALL,
      type: "custom",
      emailTitle: "",
      emailBody: "Hallo {customer},\n\n"
    };
    this.dialog = false;
  }

  /**
   * Opens an emailtool and prefill email, title and body
   */
  contactCustomer() {
    const mailBodyEncoded = this.emailBody.replaceAll("\n", "%0D%0A");
    const mailText = `mailto:${this.customerEmail}?subject=${this.emailTitle}&body=${mailBodyEncoded}`;
    this.$emit("click", true);
    window.open(mailText, "_blank");

    this.close();
  }
}
