import { ReportType } from "../enum/reportType";
import { VehicleTypes } from "../enum/vehicle-types.enum";

export const vehicleClassToReportTypeMap = new Map([
  [VehicleTypes.N, ReportType.CAR],
  [VehicleTypes.N1, ReportType.TRUCK],
  [VehicleTypes.N2, ReportType.TRUCK],
  [VehicleTypes.N3, ReportType.TRUCK],
  [VehicleTypes.M, ReportType.CAR],
  [VehicleTypes.M1, ReportType.CAR],
  [VehicleTypes.M2, ReportType.CAR],
  [VehicleTypes.M3, ReportType.CAR],
  [VehicleTypes.O1, ReportType.TRAILER],
  [VehicleTypes.O2, ReportType.TRAILER],
  [VehicleTypes.O3, ReportType.SEMITRAILER],
  [VehicleTypes.O4, ReportType.SEMITRAILER],
  [VehicleTypes.L, ReportType.MOTORBIKE],
  [VehicleTypes.L1, ReportType.MOTORBIKE],
  [VehicleTypes.L2, ReportType.MOTORBIKE],
  [VehicleTypes.L3, ReportType.MOTORBIKE],
  [VehicleTypes.L4, ReportType.MOTORBIKE],
  [VehicleTypes.L5, ReportType.MOTORBIKE],
  [VehicleTypes.L6, ReportType.MOTORBIKE],
  [VehicleTypes.T, ReportType.TRACTOR_UNIT],
  [VehicleTypes.R, ReportType.TRAILER],
  [VehicleTypes.S, ReportType.OTHER],
  [VehicleTypes.G, ReportType.OTHER],
  [VehicleTypes.other, ReportType.OTHER]
]);
