var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tooltip', {
    attrs: {
      "text": _vm.$t('timeLine.GoToReferenceButton.tooltip')
    }
  }, [_vm.reference ? _c('v-btn', {
    attrs: {
      "icon": !_vm.text,
      "block": Boolean(_vm.text),
      "text": Boolean(_vm.text)
    },
    on: {
      "click": _vm.goToDetail
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), _vm.text ? _c('v-spacer') : _vm._e(), _c('v-icon', [_vm._v("mdi-file-link-outline")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }