import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IMapConfig } from "@/lib/utility/data/local-data-access-layer.interface";
import { IVehicle, Vehicle } from "@/models/vehicle.entity";

export const VehicleAccessLayer = new (class extends AbstractLocalDataAccessLayer<IVehicle, "identificationnumber"> {
  getIdentifier(entity: IVehicle): string {
    return entity.id;
  }

  protected mapEntity(entity: IVehicle): IVehicle {
    return new Vehicle(entity);
  }

  mapsConfig: Record<"identificationnumber", IMapConfig> = {
    identificationnumber: {
      getIdentifier: (entity: IVehicle) => (entity.identificationnumber ? [entity.identificationnumber] : [])
    }
  };
})();
