
























import { autoCompleteCountryCodes } from "@/lib/CountryCodeHelper";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { emailRule, extendetPhoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivUpdateUserDtoGen, MrfiktivUserViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Watch } from "vue-property-decorator";
import ProfileConfirmationRow from "./ProfileConfirmationRow.vue";
import ProfileFormFields from "./ProfileFormFields.vue";
import { IContact, Contact } from "@/models/contact.entity";
import { Address, IAddress } from "@/models/address.entity";

@Component({
  components: { ProfileFormFields, ProfileConfirmationRow }
})
export default class ProfileForm extends DarkModeHighlightMixin {
  @Prop()
  userToUpdate!: MrfiktivUserViewmodelGen;

  @Prop({ default: false })
  isLoading!: boolean;

  @Prop()
  disabled!: boolean;

  get isDisabled() {
    return this.disabled;
  }

  set isDisabled(disabled: boolean) {
    this.$emit("update:disabled", disabled);
  }

  countryCodes = autoCompleteCountryCodes;

  valid = false;

  user: MrfiktivUpdateUserDtoGen = {
    firstName: "",
    lastName: "",
    contact: {
      phone: "",
      email: ""
    },
    address: {
      street: "",
      city: "",
      zip: "",
      state: "",
      countryCode: CountryCodeEnum.germany
    },
    isCompany: false,
    company: "",
    isTaxDeductible: false,
    taxnumber: ""
  };

  emptyContact: IContact = new Contact();

  emptyAddress: IAddress = new Address({
    countryCode: CountryCodeEnum.germany
  });

  initialize() {
    this.user = deepCopy(this.userToUpdate);

    if (this.userToUpdate.company && this.userToUpdate.isCompany === undefined) {
      this.user.isCompany = true;
    }

    if ((this.userToUpdate.company || this.userToUpdate.isCompany) && this.userToUpdate.isTaxDeductible === undefined) {
      this.user.isTaxDeductible = true;
    }

    if (this.userToUpdate.taxnumber) {
      this.user.isCompany = true;
      this.user.isTaxDeductible = true;
    }

    if (!this.user?.contact) {
      this.user.contact = this.emptyContact;
    }

    if (!this.user?.contact?.email) {
      this.user.contact.email = "";
    }

    if (!this.user?.contact?.phone) {
      this.user.contact.phone = "";
    }

    if (!this.user?.address) {
      this.user.address = this.emptyAddress;
    }

    if (!this.user?.address?.street) {
      this.user.address.street = "";
    }

    if (!this.user?.address?.state) {
      this.user.address.state = "";
    }

    if (!this.user?.address?.city) {
      this.user.address.city = "";
    }

    if (!this.user?.address?.zip) {
      this.user.address.zip = "";
    }

    if (!this.user?.address?.countryCode) {
      this.user.address.countryCode = CountryCodeEnum.germany;
    }
  }

  /**
   * Added because we have the alert with the add phone on the profile page as well.
   * If you update your phone through the alert, the new information is not loaded into the profile form
   * without refresh. This way we reinitialize the user by making a copy of the updatedUser again.
   */
  @Watch("userToUpdate")
  reInitializeUserWhenUserIsUpdatedFromTheOutside() {
    this.initialize();
  }

  mounted() {
    this.initialize();
  }

  save(): MrfiktivUpdateUserDtoGen {
    this.$emit("save", this.user);

    return this.user;
  }

  abort() {
    this.initialize();
    this.isDisabled = true;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(extendetPhoneRule());

    return rules;
  }
}
