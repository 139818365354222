export enum ImpactTypeEnum {
  /**
   * plant trees
   */
  trees = "trees",

  /**
   * invest in renewable energies
   */
  renewable = "renewable",

  /**
   * invest in renewable charging infrastructure
   */
  charging = "charging",

  /**
   * payout
   */
  payout = "payout",

  /**
   * Load credits to customer account
   */
  customerAccount = "customerAccount",

  /**
   * retire
   *
   * The carbon credit can be retired by submitting it to the UBA so that it has been used – ensuring the claiming and impact is traceable, and avoiding any double claiming.
   * Retired credits should and will not be sold.
   */
  retire = "retire"
}
