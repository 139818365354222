










import BookingProcess from "@/components/booking/customer/BookingProcess.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    BookingProcess
  }
})
export default class BookingIFrame extends Vue {
  get bgColor() {
    return "#1E1E1E";
  }
}
