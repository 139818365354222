





















import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";

@Component({
  components: {
    SideCard,
    ConfirmActionDialog
  }
})
export default class DocumentTemplateDetailDeleteDialog extends mixins(DocumentDetailMixin) {
  @Prop()
  loading!: boolean;

  @Prop({ default: "" })
  text!: string;

  @Prop()
  disabled!: boolean;

  isDialogActive = false;

  get title() {
    return this.$t("sign.DocumentTemplateDetailDelete.title");
  }

  get subtitle() {
    return this.$t("sign.DocumentTemplateDetailDelete.sure");
  }

  closeDialog() {
    this.isDialogActive = false;
  }

  startDelete() {
    this.$emit("startDelete");
    this.isDialogActive = true;
  }
}
