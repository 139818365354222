
import { Vue, Component } from "vue-property-decorator";
import { customRule } from "@/lib/rules/customRule";
import { LoginModule } from "@/store/modules/login.store";

@Component({})
export default class LoginNewPasswordMixin extends Vue {
  get passwordRepeatRule() {
    return customRule(this.passwordRepeat === this.passwordNew, "Die Passwörter müssen übereinstimmen");
  }

  get passwordNew() {
    return LoginModule.passwordNew;
  }

  get passwordRepeat() {
    return LoginModule.passwordRepeat;
  }

  passwordNewRule = customRule(true, "Die Anforderungen an das Passwort müssen erfüllt sein");

  /**
   * Sync fullfillment of requirements with password requirements list
   */
  setPass(isFullfilled: boolean) {
    this.passwordNewRule = customRule(isFullfilled, "Die Anforderungen an das Passwort müssen erfüllt sein");
  }
}
