import { Api } from "@/services/fahrzeugschein/api";
import { ErrorLogModule } from "@/store/modules/error-log";
import Vue from "vue";

class ValidationService {
  ROUTE = "validate";

  async validateFin(fin: string) {
    const api = new Api().instance();

    try {
      const response = await api.get(`/${this.ROUTE}/${fin}` + Api.getToken());
      return response;
    } catch (error) {
      Vue.$log.error(error);
      ErrorLogModule.addErrorLog(error);
      throw error;
    }
  }
}

export default new ValidationService();
