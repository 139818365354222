













import { Component, Vue } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";
import { emailRule } from "@/lib/rules/contactRule";
import { LoginModule } from "@/store/modules/login.store";
import { noWhiteSpaceRule } from "@/lib/rules/noWhiteSpaceRule";

@Component({
  components: {}
})
export default class LoginComponentInputMail extends Vue {
  /**
   * get current mail value
   */
  get mail() {
    return LoginModule.mail;
  }

  /**
   * set current mail value
   */
  set mail(mail: string) {
    LoginModule.setMail(mail);
  }

  /**
   * Email validation rules
   */
  get emailRules() {
    return [requiredRule(), emailRule(), noWhiteSpaceRule()];
  }

  /**
   * Get input label
   */
  get label() {
    return this.$t("components.login.LoginComponentInputMail.mail");
  }

  /**
   * removes whitespace from mail
   */
  removeWhiteSpace() {
    this.mail = this.mail.replace(/\s/g, "");
  }

  /**
   * Prefill mail using query/param
   */
  beforeMount() {
    if (this.$route.query.mail) {
      this.mail = String(this.$route.query.mail);
    } else if (this.$route.params.mail) {
      this.mail = String(this.$route.params.mail);
    }
  }
}
