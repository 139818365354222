var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "color": "#E0E0E0",
      "width": "310"
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.interalValid,
      callback: function callback($$v) {
        _vm.interalValid = $$v;
      },
      expression: "interalValid"
    }
  }, [_c('div', {
    staticClass: "container my-n1 ml-n1"
  }, [!_vm.specialFormat ? [_vm.numberplateImage ? _c('div', {
    staticStyle: {
      "max-height": "40px"
    }
  }, [_c('img', {
    staticStyle: {
      "max-height": "40px"
    },
    attrs: {
      "src": _vm.numberplateImage
    }
  })]) : _vm._e(), _c('div', {
    staticStyle: {
      "width": "80px"
    }
  }, [_c('v-text-field', {
    ref: "cityField",
    staticClass: "mb-n6 mx-1 centered-input",
    attrs: {
      "background-color": "white",
      "single-line": "",
      "dense": "",
      "maxlength": "3",
      "autocomplete": "off",
      "type": "text",
      "outlined": "",
      "data-test-form-numberplate-city": "",
      "disabled": _vm.disabled,
      "light": ""
    },
    on: {
      "keyup": _vm.keyUpCityField,
      "keydown": _vm.onkeydownCity
    },
    model: {
      value: _vm.city,
      callback: function callback($$v) {
        _vm.city = $$v;
      },
      expression: "city"
    }
  })], 1), _vm.numberplateBadge ? _c('div', {
    staticStyle: {
      "max-height": "40px"
    }
  }, [_c('img', {
    staticStyle: {
      "max-height": "40px"
    },
    attrs: {
      "src": _vm.numberplateBadge
    }
  })]) : _vm._e(), _c('div', {
    staticStyle: {
      "width": "80px"
    }
  }, [_c('v-text-field', {
    ref: "letterField",
    staticClass: "mb-n6 mx-1 centered-input",
    attrs: {
      "background-color": "white",
      "maxlength": "2",
      "autocomplete": "off",
      "single-line": "",
      "dense": "",
      "outlined": "",
      "data-test-form-numberplate-name": "",
      "disabled": _vm.disabled,
      "light": ""
    },
    on: {
      "keyup": _vm.keyUpLetterField,
      "keydown": _vm.onkeydown
    },
    model: {
      value: _vm.letters,
      callback: function callback($$v) {
        _vm.letters = $$v;
      },
      expression: "letters"
    }
  })], 1), _c('div', {
    staticStyle: {
      "width": "113px"
    }
  }, [_c('v-text-field', {
    ref: "numberField",
    staticClass: "mb-n6 ml-1 mr-n2 centered-input",
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      "background-color": "white",
      "maxlength": "6",
      "autocomplete": "off",
      "single-line": "",
      "dense": "",
      "outlined": "",
      "data-test-form-numberplate-number": "",
      "disabled": _vm.disabled,
      "light": ""
    },
    on: {
      "keyup": _vm.keyUpNumberField
    },
    model: {
      value: _vm.numbers,
      callback: function callback($$v) {
        _vm.numbers = $$v;
      },
      expression: "numbers"
    }
  })], 1)] : [_vm.numberplateImage ? _c('div', {
    staticStyle: {
      "max-height": "40px"
    }
  }, [_c('img', {
    staticStyle: {
      "max-height": "40px"
    },
    attrs: {
      "src": _vm.numberplateImage
    }
  })]) : _vm._e(), _c('div', {
    staticStyle: {
      "width": "313px"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n6 mx-2",
    attrs: {
      "background-color": "white",
      "maxlength": "20",
      "autocomplete": "off",
      "single-line": "",
      "dense": "",
      "outlined": "",
      "data-test-form-numberplate-special": "",
      "disabled": _vm.disabled,
      "light": ""
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.keyUpSpecialNumberplate();
      }
    },
    model: {
      value: _vm.numberPlate,
      callback: function callback($$v) {
        _vm.numberPlate = $$v;
      },
      expression: "numberPlate"
    }
  })], 1), _vm.numberplateBadge ? _c('div', {
    staticClass: "mr-n2",
    staticStyle: {
      "max-height": "40px"
    }
  }, [_c('img', {
    staticStyle: {
      "max-height": "40px"
    },
    attrs: {
      "src": _vm.numberplateBadge
    }
  })]) : _vm._e()]], 2)])], 1), _vm.isGermanNumberplate && !_vm.disabled ? _c('v-card', {
    attrs: {
      "flat": "",
      "color": "transparent"
    }
  }, [_c('v-card-text', {
    staticClass: "ml-n4 mt-n2",
    attrs: {
      "data-test-form-numberplate-change": ""
    }
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.toggle
    }
  }, [!_vm.specialFormat ? _c('span', [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "info"
    }
  }, [_vm._v("mdi-autorenew")]), _vm._v(_vm._s(_vm.$t("components.numberplateForm.hint")))], 1) : _c('span', [_c('v-icon', {
    attrs: {
      "small": "",
      "color": "info"
    }
  }, [_vm._v("mdi-autorenew")]), _vm._v(_vm._s(_vm.$t("components.numberplateForm.hintAlt")))], 1)])])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }