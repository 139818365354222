

























































import CameraBottomSheet from "@/components/camera/CameraBottomSheet.vue";
import CameraDialog from "@/components/camera/CameraDialog.vue";
import FileExample from "@/components/files/FileExample.vue";
import FileUpload from "@/components/files/FileUpload.vue";
import ImageUploadButton from "@/components/files/ImageUploadButton.vue";
import PdfUploadDialog from "@/components/files/PdfUploadDialog.vue";
import PdfViewer from "@/components/files/PdfViewer.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {
    PdfViewer,
    LayoutReportStep,
    ImageUploadButton,
    FileExample,
    FileUpload,
    CameraDialog,
    CameraBottomSheet,
    PdfUploadDialog
  }
})
/**
 * Uploads images as overview images
 */
export default class FileUploadPreview extends mixins(ReportImageSubmission) {
  @Prop()
  example!: AssetEnum;

  @Prop()
  displayFiles!: IImageUploaded[];

  @Prop({ default: null })
  public maxFiles!: number | null;

  @Prop({ default: 1 })
  public minFiles!: number;

  @Prop()
  onUpload!: (file: File) => Promise<boolean>;

  @Prop()
  onDelete!: (file: File) => Promise<boolean>;

  @Prop()
  asset!: AssetEnum;

  @Prop()
  instruction!: IReportInstructionData;

  /**
   * Text to be displayed on the button card.
   */
  @Prop({ default: "defaultFoto" })
  text: any;

  /**
   * An alternative text to be displayed on @isUploade
   */
  @Prop({ default: "defaultFotoAgain" })
  textAlt: any;

  get inputTypes() {
    return ConfigModule.pdfInputTypesString;
  }

  get files(): IImageUploaded[] {
    return this.displayFiles;
  }

  onFileDrag(file: File) {
    this.handleInput(file);
  }

  async handleUpload(file: File): Promise<boolean> {
    await this.onUpload(file);
    return true;
  }

  async handleDelete(file: File): Promise<boolean> {
    await this.onDelete(file);

    return true;
  }
}
