import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";

export const SignDocumentDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<ISignDocument> {
  protected mapEntity(entity: ISignDocument): ISignDocument {
    return new SignDocument(entity);
  }

  getIdentifier(entity: ISignDocument): string {
    return entity.id;
  }
})();
