













































































import { PaginationFilterListElement } from "@/lib/utility/data/page-filter-list-element.interface";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { PaginationFilterOperationEnum } from "@/store/modules/base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { Component, Prop, Vue } from "vue-property-decorator";
import FilterCardPaginationValueField from "./FilterCardPaginationValueField.vue";
import { $t } from "@/lib/utility/t";
import Debug from "@/components/utility/Debug.vue";

@Component({
  components: {
    FilterCardPaginationValueField,
    Debug
  }
})
export default class FilterCardPaginationMenuFilterElement extends Vue {
  @Prop()
  value!: IPageFilterElement;

  @Prop()
  filterList!: PaginationFilterListElement[];

  get selectedFilterElement() {
    return this.filterList.find(f => f.id === this.value.id) as PaginationFilterListElement;
  }

  set selectedFilterElement(selectedFilterElement: PaginationFilterListElement) {
    this.value.key = selectedFilterElement.key;
    this.value.type = selectedFilterElement.type;
    this.value.specifier = selectedFilterElement.specifier;
  }

  get isComparatorSelection() {
    return ![PageFilterTypes.BOOLEAN].includes(this.selectedFilterElement?.type as PageFilterTypes);
  }

  get typeInputWidthStyle() {
    if (!this.selectedFilterElement) {
      return "min-width:  calc(100% - 24px)";
    }

    const isComparatorSelection = this.isComparatorSelection;

    return `min-width: calc(40% - 24px ${isComparatorSelection ? "- 45px" : ""}) !important`;
  }

  get valueInputWidthStyle() {
    return "min-width: calc(60% - 24px) !important";
  }

  get allOperations() {
    return Object.values(PaginationFilterOperationEnum);
  }

  get availableOperations() {
    if (PageFilterTypes.DATE === this.selectedFilterElement?.type) {
      // Not equal does not work for dates in backend
      return this.allOperations.filter(op => op !== PaginationFilterOperationEnum.NOT_EQUAL);
    }

    return this.allOperations;
  }

  keyFilter(item: PaginationFilterListElement, queryText: string) {
    return (JSON.stringify(item) + $t(item.displayName ?? "objects." + item.key))
      .toLowerCase()
      .includes(queryText.toLowerCase());
  }
}
