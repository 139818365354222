import i18n from "../../plugins/I18nPlugin";
import {
  checkMinLength,
  containsCapitalLatinLetter,
  containsLowerLatinLetter,
  containsNumber,
  containsSpecialCharacter
} from "../passwordValidator";

/**
 * This file contains all the password rules for AWS cognito @see https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_passwords_account-policy.html
 * All the validators are found in @see passwordValidator
 */

/**
 * String contains at least one [A-Z] (latin latter in capital)
 * @param message input to apply the rule
 * @returns rule for form
 */
export function containsCapitalLatinLetterRule(
  message = "components.login.LoginCardPasswordRequirementList.upperCase"
): (value: any) => boolean | string {
  return value => containsCapitalLatinLetter(value) || String(i18n.tc(message));
}

/**
 * String contains at least one [a-z] (latin latter in lower case)
 * @param message input to apply the rule
 * @returns rule for form
 */
export function containsLowerLatinLetterRule(
  message = "components.login.LoginCardPasswordRequirementList.lowerCase"
): (value: any) => boolean | string {
  return value => containsLowerLatinLetter(value) || String(i18n.tc(message));
}

/**
 * String contains at least one number
 * @param message input to apply the rule
 * @returns rule for form
 */
export function containsNumberRule(
  message = "components.login.LoginCardPasswordRequirementList.number"
): (value: any) => boolean | string {
  return value => containsNumber(value) || String(i18n.tc(message));
}

/**
 * String contains at least one of the special characters from @see containsSpecialCharacter
 * @param message input to apply the rule
 * @returns rule for form
 */
export function containsSpecialCharacterRule(
  message = "components.login.LoginCardPasswordRequirementList.specialCharacter"
): (value: any) => boolean | string {
  return value => containsSpecialCharacter(value) || String(i18n.tc(message));
}

/**
 * String contains a min length of 8
 * @param message input to apply the rule
 * @returns rule for form
 */
export function minCharacterCountRule(
  message = "components.login.LoginCardPasswordRequirementList.minLength"
): (value: any) => boolean | string {
  return value => checkMinLength(value) || String(i18n.tc(message));
}
