

































import { Component } from "vue-property-decorator";
import CustomFieldText from "./CustomFieldText.vue";
import CustomFieldValueChip from "./CustomFieldValueChip.vue";

@Component({
  components: { CustomFieldValueChip }
})
export default class CustomFieldSingleSelect extends CustomFieldText {
  get values() {
    return this.customField.configuration?.values ?? [];
  }
}
