var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [_c('LoginCard', {
    attrs: {
      "onLogin": _vm.onLoginFunction,
      "logo": _vm.logo,
      "title": _vm.title,
      "text": _vm.text,
      "isDescriptionCardActive": true,
      "showLogoOnMobile": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }