/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SignCreateSignDtoGen,
  SignDigitalSignatureRequestSignerViewModelGen,
  SignDigitalSignatureSignedViewModelGen,
  SignDigitalSignatureViewModelGen,
  SignExceptionViewmodelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class DigitalSignatureSignerService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Digital Signature Signer Service
   * @name SignerControllerCreate
   * @summary Create a signature by the signer for a sign request
   * @request POST:/partner/{partnerId}/sign-request/{signatureRequestId}/sign
   * @response `201` `SignDigitalSignatureViewModelGen` The created signature
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signerControllerCreate = (
    partnerId: string,
    signatureRequestId: string,
    data: SignCreateSignDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDigitalSignatureViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${signatureRequestId}/sign`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Signer Service
   * @name SignerControllerFindRequestForSigner
   * @summary Deprecated: Get a signature request for the signer
   * @request GET:/sign-request/{signatureRequestId}/sign
   * @deprecated
   * @response `200` `SignDigitalSignatureRequestSignerViewModelGen` The signature request for the signer
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signerControllerFindRequestForSigner = (signatureRequestId: string, params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureRequestSignerViewModelGen, SignExceptionViewmodelGen>({
      path: `/sign-request/${signatureRequestId}/sign`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Signer Service
   * @name SignerControllerFindRequestForSignerByPartnerId
   * @summary Get a signature request for the signer
   * @request GET:/partner/{partnerId}/sign-request/{signatureRequestId}/signer
   * @response `200` `SignDigitalSignatureRequestSignerViewModelGen` The signature request for the signer
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signerControllerFindRequestForSignerByPartnerId = (
    partnerId: string,
    signatureRequestId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDigitalSignatureRequestSignerViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${signatureRequestId}/signer`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Signer Service
   * @name SignerControllerFindSignedRequest
   * @summary Get a signed document
   * @request GET:/partner/{partnerId}/sign-request/{signatureRequestId}/signed
   * @response `200` `SignDigitalSignatureSignedViewModelGen` The signature request for the signer
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signerControllerFindSignedRequest = (partnerId: string, signatureRequestId: string, params: RequestParams = {}) =>
    this.http.request<SignDigitalSignatureSignedViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${signatureRequestId}/signed`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Signer Service
   * @name SignerControllerSetSignedDocumentDelivered
   * @summary Update the status of a signature document to delivered as a user
   * @request PATCH:/partner/{partnerId}/sign-request/{signatureRequestId}/setDelivered
   * @response `200` `SignDigitalSignatureSignedViewModelGen` Update the status of a signature document
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signerControllerSetSignedDocumentDelivered = (
    partnerId: string,
    signatureRequestId: string,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDigitalSignatureSignedViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/sign-request/${signatureRequestId}/setDelivered`,
      method: "PATCH",
      format: "json",
      ...params
    });
}
