/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Type of status of an THG Document.
 */
export enum ThgStatusEnum {
  /**
   * Type of status for a THG document for a battery electric vehicle
   */

  /**
   * User submitted the registration details themselves
   */
  USER_ADDED_REGISTRATION = "user_added_registration",

  /**
   * Use this status if registration images are not readable
   */
  REGISTRATION_IMAGES_REQUESTED = "registration_images_requested",

  /**
   * FIXME: Change typo to @see ThgBevStatusEnum.REGISTRATION_IMAGES_REQUESTED
   * Use this status if registration images are not readable
   */
  REQUEST_REGISTRATION_IMAGES = "requst_registration_images",

  /**
   * The Car is no electric vehicle
   */
  NO_BEV = "no_battery_electric_vehicle",

  /**
   * No German registration Document
   */
  NO_GERMAN_REGISTRATION_DOCUMENT = "no_german_registration_document",

  /**
   * Type of status for a THG document for a charing station meter reading
   */

  /**
   * The charging station is not publicly accessible, which is a requirement for the THG quota
   */
  NO_PUBLIC_ACCESS = "no_public_access",

  /**
   * Use this status if registration images are not readable
   */
  METER_READING_CHANGE_REQUESTED = "meter_reading_change_requested",

  /**
   * Implausible amount for the time range
   */
  IMPLAUSIBLE = "implausible",

  /**
   * Type of status of an THG Document.
   */

  /**
   * Created
   */
  CREATED = "created",

  /**
   * The THG quota is confirmed and waits for the export to be registered by the the federal environmental agency (UBA)
   */
  CONFIRMED = "confirmed",

  /**
   * The THG quota is submitted to the the federal environmental agency (UBA)
   */
  SUBMITTED = "submitted",

  /**
   * thg quote has been submitted but is waiting for at the federal environmental agency (in-between status)
   */
  WAIT_FOR_AGENCY = "wait_for_agency",

  /**
   * thg quote is been confirmed by federal environmental agency
   */
  CONFIRMED_BY_AGENCY = "confirmed_by_agency",

  /**
   * The thg quote has been rejected by the federal environmental agency (UBA)
   */
  REJECTED_BY_AGENCY = "rejected_by_agency",

  /**
   * The THG quote is paid out
   */
  PAYED = "payed",

  /**
   * The user canceled the THG quota (wiederruf)
   */
  CANCELED = "canceled",

  /**
   * The THG quota is in deleted state
   */
  DELETED = "deleted",

  /**
   * The car was already registered in the current year
   */
  DUPLICATE = "duplicate",

  /**
   * The THG is spam
   */
  SPAM = "spam",

  HYBRID_AT = "hybrid_at",

  /**
   * This is a test THG quota
   */
  TEST = "test",

  /**
   * FIXME: Remove this typo and use @see ThgStatusEnum.DATA_REQUESTED
   * For completion of the THG Quota additional data is necessary
   */
  REQUEST_DATA = "requst_data",

  /**
   *
   * For completion of the THG quota additional data is necessary
   */
  DATA_REQUESTED = "data_requested",

  /**
   *
   * The request is in pending state, we are waiting for something
   */
  PENDING = "pending",

  /**
   *
   * The request has been requested to be reviewed by the Agency - something is to be clarified
   */
  INQUIRY_TO_AGENCY = "inquiry_to_agency",

  /**
   * There is no ghg quota for the requested Vehicleclass
   */
  INVALID_VEHICLE_CLASS = "invalid_vehicle_class",

  /**
   * Payed and not permitted for ghg quota anymore
   */
  PAYED_AND_INVALID_VEHICLE_CLASS = "payed_and_invalid_vehicle_class",

  /**
   * Stati for austria BEV to request details for co2 calculation
   */
  DATA_REQUESTED_BEV_AT_QUOTA_DETAILS = "data_requested_bev_at_quota_details",

  /**
   * Stati for austria BEV to request details for co2 calculation
   */
  DATA_UPDATED_BEV_AT_QUOTA_DETAILS = "data_updated_bev_at_quota_details",

  /**
   * Stati for austria Hybrid to request details for co2 calculation
   */
  DATA_REQUESTED_HYBRID_AT_QUOTA_DETAILS = "data_requested_hybrid_at_quota_details",

  /**
   * Stati for austria Hybrid to request details for co2 calculation
   */
  DATA_UPDATED_HYBRID_AT_QUOTA_DETAILS = "data_updated_hybrid_at_quota_details",

  /**
   * Statis for austriy Hybrid if no charging details are available
   */
  NO_CHARGING_DETAILS_HYBRID_AT_QUOTA_DETAILS = "no_charging_details_hybrid_at_quota_details"
}
