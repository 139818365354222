




























































































import GoToReferenceButton from "@/components/partner/GoToReferenceButton.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { ISignDocument } from "@/models/sign-document.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({
  components: {
    SideCard,
    GoToReferenceButton
  }
})
export default class DocumentTemplateDetailInformation extends mixins(DocumentDetailMixin) {
  @Prop()
  document!: ISignDocument;

  edit = false;
  isValid = false;

  get partnerId() {
    return this.document.partnerId;
  }

  get subtitle() {
    return `
      ${this.$t("sign.DocumentTemplateDetailInformation.created")}:
      ${simpleDoubleDigitDate(this.document.timestamp?.created)}
    `;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  save() {
    this.edit = false;
    this.$emit("save");
  }

  removeWhiteSpace() {
    if (this.document.folder) {
      this.document.folder = this.document.folder.replace(/\s/g, "");
    }
  }
}
