






import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ErrorLogModule } from "@/store/modules/error-log";
import { Component } from "vue-property-decorator";

@Component({})
export default class ErrorList extends DarkModeHighlightMixin {
  get headers() {
    return [
      {
        text: "Name",
        value: "name"
      },
      {
        text: "Message",
        value: "message"
      }
    ];
  }

  get errors() {
    return ErrorLogModule.logs;
  }
}
