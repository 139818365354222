














import {
  checkMinLength,
  containsCapitalLatinLetter,
  containsLowerLatinLetter,
  containsNumber,
  containsSpecialCharacter,
  getSpecialCharsList
} from "@/lib/passwordValidator";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class LoginComponentPasswordRequirementList extends Vue {
  @Prop()
  password!: string;

  @Prop()
  setPass!: Function;

  fail = "✘";

  working = "✔";

  specialCharacters = getSpecialCharsList();

  setIsAllCorrect(reqs: any[]) {
    if (this.setPass) {
      let correct = true;
      reqs.forEach(req => {
        correct = correct && req.test;
        if (!correct) {
          return correct;
        }
      });
      this.setPass(correct);
    }
  }

  get requirementList() {
    const reqs = [
      {
        test: checkMinLength(this.password),
        text: this.$t("components.login.LoginCardPasswordRequirementList.minLength")
      },
      {
        test: containsCapitalLatinLetter(this.password),
        text: this.$t("components.login.LoginCardPasswordRequirementList.upperCase")
      },
      {
        test: containsLowerLatinLetter(this.password),
        text: this.$t("components.login.LoginCardPasswordRequirementList.lowerCase")
      },
      {
        test: containsNumber(this.password),
        text: this.$t("components.login.LoginCardPasswordRequirementList.number")
      },
      {
        test: containsSpecialCharacter(this.password),
        text: this.$t("components.login.LoginCardPasswordRequirementList.specialCharacter")
      }
    ];
    this.setIsAllCorrect(reqs);
    return reqs;
  }
}
