import Vue from "vue";
import { Module, VuexModule, getModule, Mutation, Action, MutationAction } from "vuex-module-decorators";

import store from "@/store/VuexPlugin";
import { FahrzeugscheinEntity } from "@/models/fahrzeugscheinEntity";
import FahrzeugscheinService from "@/services/fahrzeugschein/service/fahrzeugscheinService";
import DetectionService from "@/services/fahrzeugschein/service/detectionService";
import ValidationService from "@/services/fahrzeugschein/service/validationService";
import { DetectionEntity } from "@/models/detectionEntity";
import { CachedFahrzeugschein } from "../../models/cachedFahrzeugschein";
import { SessionEntity } from "../../models/sessionEntity";
import { checkSessionStatus } from "@/lib/utility/checkSessionStatusFunc";
import { Type } from "@/lib/utility/type";

@Module({
  dynamic: true,
  namespaced: true,
  name: "fahrzeugschein",
  store
})
class Fahrzeugschein extends VuexModule {
  fahrzeugschein = {} as FahrzeugscheinEntity;
  fahrzeugscheinImage = {} as DetectionEntity;
  cachedFahrzeugscheine: Map<string, CachedFahrzeugschein> = new Map<string, CachedFahrzeugschein>();
  status = "";
  sessionId = "";
  xml = "";
  isFinCorrect = false;
  finErrorMessage = "";

  @MutationAction
  async getFahrzeugschein(sessionId: string) {
    let fahrzeugschein = {} as FahrzeugscheinEntity;

    if (FahrzeugscheinModule.cachedFahrzeugscheine.has(sessionId)) {
      const cachedFahrzeugschein = FahrzeugscheinModule.cachedFahrzeugscheine.get(sessionId);

      if (cachedFahrzeugschein != undefined) {
        fahrzeugschein = cachedFahrzeugschein.fahrzeugschein;
      }
    }

    if (!Type.isPresent(fahrzeugschein)) {
      fahrzeugschein = await FahrzeugscheinService.get(sessionId);
    }

    Vue.$log.info(fahrzeugschein);

    return {
      fahrzeugschein
    };
  }

  @MutationAction
  async getFahrzeugscheinImage(sessionId: string) {
    let fahrzeugscheinImage = {} as DetectionEntity;

    if (FahrzeugscheinModule.cachedFahrzeugscheine.has(sessionId)) {
      const cachedFahrzeugschein = FahrzeugscheinModule.cachedFahrzeugscheine.get(sessionId);
      if (cachedFahrzeugschein != undefined) {
        fahrzeugscheinImage = cachedFahrzeugschein.fahrzeugscheinImage;
      }
    }

    if (!Type.isPresent(fahrzeugscheinImage)) {
      fahrzeugscheinImage = await DetectionService.getImage(sessionId);
    }

    Vue.$log.info(fahrzeugscheinImage);

    return {
      fahrzeugscheinImage
    };
  }

  @Action
  async getXML(sessionId: string) {
    const xml = await FahrzeugscheinService.getXML(sessionId);

    Vue.$log.info(xml);

    this.context.commit("setXML", xml);
  }

  @Action
  async validateFin(fin: string) {
    const response = await ValidationService.validateFin(fin);
    Vue.$log.info(response);

    if (response.data.status === "error") {
      this.context.commit("setFinCorrect", false);
      this.context.commit("setFinErrorMessage", "Bitte überprüfen Sie die Identifizierungsnummer.");
    } else {
      this.context.commit("setFinCorrect", true);
      this.context.commit("setFinErrorMessage", "");
    }
  }

  @Action
  async updateFahrzeugschein(sessionId: string) {
    const fahrzeugschein = await FahrzeugscheinService.update(sessionId, this.fahrzeugschein);
    Vue.$log.info(fahrzeugschein);

    this.context.commit("setFahrzeugschein", this.fahrzeugschein);
  }

  @Action
  async deleteFahrzeugschein(sessionId: string) {
    await FahrzeugscheinService.delete(sessionId);

    this.context.commit("setFahrzeugschein", {});
  }

  @Action
  async createFahrzeugschein(file: any) {
    const response = await FahrzeugscheinService.create(file);

    this.context.commit("setStatus", response.data.status);
    this.context.commit("setSessionId", response.data.session_id);
  }

  @Action
  async reloadFahrzeugscheinStatus(sessionId: string) {
    const status = await FahrzeugscheinService.getStatus(sessionId);

    this.context.commit("setStatus", status);
  }

  @Action
  async updateCachedFahrzeugscheine(sessions: SessionEntity[]) {
    const cachedFahrzeugscheine: Map<string, CachedFahrzeugschein> = this.cachedFahrzeugscheine;

    for (const session of sessions) {
      if (checkSessionStatus(session.status)) {
        const fahrzeugschein = await FahrzeugscheinService.get(session.session_id);
        const fahrzeugscheinImage = await DetectionService.getImage(session.session_id);

        if (Type.isPresent(this.fahrzeugschein) && Type.isPresent(this.fahrzeugscheinImage)) {
          cachedFahrzeugscheine.set(session.session_id, {
            fahrzeugschein: fahrzeugschein,
            fahrzeugscheinImage: fahrzeugscheinImage
          });
        }
      }
    }

    this.context.commit("setCachedFahrzeugscheine", cachedFahrzeugscheine);
  }

  // ##### MUTATIONS #####

  @Mutation
  setCachedFahrzeugscheine(cachedFahrzeugscheine: Map<string, CachedFahrzeugschein>) {
    this.cachedFahrzeugscheine = cachedFahrzeugscheine;

    Vue.$log.info(this.cachedFahrzeugscheine);
  }

  @Mutation
  setFahrzeugschein(updatedFahrzeugschein: FahrzeugscheinEntity) {
    this.fahrzeugschein = updatedFahrzeugschein;
  }

  @Mutation
  setXML(xml: string) {
    this.xml = xml;
  }

  @Mutation
  setFinCorrect(correct: boolean) {
    this.isFinCorrect = correct;
  }

  @Mutation
  setFinErrorMessage(message: string) {
    this.finErrorMessage = message;
  }

  @Mutation setStatus(status: string) {
    this.status = status;
  }

  @Mutation setSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }
}

export const FahrzeugscheinModule = getModule(Fahrzeugschein);
