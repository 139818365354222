var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    attrs: {
      "padless": "",
      "data-test-footer": ""
    }
  }, [_c('v-card', {
    attrs: {
      "img": _vm.overlaySrc,
      "flat": "",
      "tile": "",
      "width": "100%"
    }
  }, [_c('v-container', {
    staticClass: "pb-14"
  }, [_c('v-card', {
    staticClass: "mx-auto card-outter pb-5 rounded-0",
    staticStyle: {
      "min-height": "600px"
    },
    attrs: {
      "flat": "",
      "color": "transparent",
      "max-width": "1200"
    }
  }, [_vm.showLogo ? _c('v-row', {
    staticClass: "mt-16"
  }, [_c('v-img', {
    staticClass: "mx-6",
    attrs: {
      "src": _vm.logoDamageReport,
      "contain": "",
      "max-width": "300"
    }
  })], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "flat": ""
    }
  }, [_c('v-card-text', [_vm._v(" " + _vm._s(_vm.partnerProp.companyName)), _c('br'), _vm.address ? _c('span', [_vm._v(_vm._s(_vm.address.street))]) : _vm._e(), _c('br'), _vm.address ? _c('span', [_vm._v(" " + _vm._s(_vm.address.zip) + " " + _vm._s(_vm.address.city) + " ")]) : _vm._e()]), _c('v-card-text', [_vm.settings ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.mail", {
    mail: _vm.partnerProp.settings.contact.email
  })))]) : _vm._e(), _c('br'), _vm.settings ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.phone", {
    phone: _vm.partnerProp.settings.contact.phone
  })))]) : _vm._e(), _c('br'), _vm.isWebsitePresent ? _c('span', [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.web")) + " "), _c('a', {
    style: "color:".concat(_vm.color, "; text-decoration: none;"),
    attrs: {
      "href": "".concat(_vm.partnerProp.settings.websites[0].link),
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.partnerProp.settings.websites[0].text) + " ")]), _c('br')]) : _vm._e()])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "flat": ""
    }
  }, [_vm.isOpeningTimeInformationPresent ? _c('v-card-text', [_c('span', [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.monday")) + " " + _vm._s(_vm.partnerProp.settings.openingHours.monday || _vm.$t("partner.PartnerFooter.openingHoursDefault")) + " ")]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.$t("partner.PartnerFooter.tuesday")) + " " + _vm._s(_vm.partnerProp.settings.openingHours.tuesday || _vm.$t("partner.PartnerFooter.openingHoursDefault")) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.wednesday")) + " " + _vm._s(_vm.partnerProp.settings.openingHours.wednesday || _vm.$t("partner.PartnerFooter.openingHoursDefault")) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.thursday")) + " " + _vm._s(_vm.partnerProp.settings.openingHours.thursday || _vm.$t("partner.PartnerFooter.openingHoursDefault")) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.friday")) + " " + _vm._s(_vm.partnerProp.settings.openingHours.friday || _vm.$t("partner.PartnerFooter.openingHoursDefault")) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.saturday")) + " " + _vm._s(_vm.partnerProp.settings.openingHours.saturday || _vm.$t("partner.PartnerFooter.openingHoursDefault")) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.sunday")) + " " + _vm._s(_vm.partnerProp.settings.openingHours.sunday || _vm.$t("partner.PartnerFooter.openingHoursDefault")) + " ")])]) : _vm._e()], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card-text', {
    staticClass: "font-weight-light "
  }, [_vm._v(" " + _vm._s(_vm.poweredBy)), _c('br'), _vm._v(" All rights reserved. ")]), _c('v-card-text', {
    staticClass: "font-weight-light"
  }, [_c('a', {
    style: "padding-bottom:10px; color:".concat(_vm.color, "; text-decoration: none;"),
    on: {
      "click": _vm.goToImprint
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.imprint")) + " ")]), _vm._v(" | "), _c('a', {
    style: "padding-bottom:10px; color:".concat(_vm.color, "; text-decoration: none;"),
    on: {
      "click": _vm.goToPrivacy
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("partner.PartnerFooter.privacy")) + " ")])])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }