/**
 * A class for a token which can be flagged if it is cancelled
 */
export class CancelToken {
  /**
   * the cancellation flag
   */
  private cancellationFlag = false;

  /**
   * Burn a token and mark it as cancelled
   */
  requestCancellation() {
    this.cancellationFlag = true;
  }

  /**
   * Check if the cancellation is requested
   *
   * @returns the status of the tokens
   */
  isCancellationRequested() {
    return this.cancellationFlag;
  }
}
