var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mb-2 mt-2"
  }, [_c('vue-picture-swipe', {
    attrs: {
      "items": _vm.images,
      "options": {
        shareEl: false,
        rotationOn: true
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }