class AccessTokenMissing extends Error {
  public static MISSING = "Access Token is not set.";

  constructor(public message: string = AccessTokenMissing.MISSING) {
    super(message);
    this.name = "AccessTokenMissing";
    this.stack = new Error().stack;
  }
}

export default AccessTokenMissing;
