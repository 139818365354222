/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { ConfigModule } from "@/store/modules/config";
import { AxiosInstance } from "axios";
import { AxiosInstanceProvider } from "../axios-instance.provider";
import { BearerTokenProvider } from "../bearer-token.provider";
import { HttpClient } from "./v1/http-client";

/**
 * Provider to create a HttpClient or AxiosInstance.
 */
export class BookingClientProvider {
  /**
   * Creates a new HttpClient.
   * @returns HttpClient to be used as HttpClient
   */
  client(): HttpClient {
    const client = new HttpClient();
    client.instance = this.instance();

    return client;
  }

  /**
   * Creates a new instance of Axios.
   * @returns AxiosInstance to be used as HttpClient
   */
  instance(): AxiosInstance {
    const baseUrl = ConfigModule.bookingBaseUrl;
    const timeout = 60000;

    const axiosInstance = new AxiosInstanceProvider(new BearerTokenProvider(), baseUrl, timeout).create();
    return axiosInstance;
  }
}
