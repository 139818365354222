var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', {
    staticClass: "pt-7 pb-7",
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_c('h3', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.login.".concat(_vm.component, ".title"))) + " ")])]), _c('v-card-text', [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }