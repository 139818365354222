





























































































































































import { convertUrlToFile } from "@/lib/files/PdfHelper";
import { convertPdfToImagePromises } from "@/lib/utility/convertPdfToImage";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
const PDFJSWorker = () => import("pdfjs-dist/legacy/build/pdf.worker.entry");
const pdfJs = () => import("pdfjs-dist/legacy/build/pdf");

@Component({
  components: {}
})
export default class PdfViewer extends DarkModeHighlightMixin {
  /**
   * The pdf file to display
   */
  @Prop()
  pdf!: File;

  /**
   * A list of promises that each return a page of the pdf as an image
   */
  images: Promise<string>[] = [];

  /**
   * previous page
   */
  previousPage = "";

  /**
   * current page
   */
  currentPage = "";

  /**
   * next page
   */
  nextPage = "";

  /**
   * is snackbar visible
   */
  isSnackbar = true;

  /**
   * The currently displayed page
   */
  currentPageNumber = 0;

  /**
   * Flag if current page is loading
   */
  currentLoading = true;

  /**
   * Flag if previous page is loading
   */
  previousLoading = true;

  /**
   * Flag if next page is loading
   */
  nextLoading = true;

  /**
   * amount of pages in pdf
   */
  get pageCount() {
    return this.images.length;
  }

  /**
   * get the number of the next page
   */
  get nextPageNumber() {
    let nextPage = this.currentPageNumber + 1;

    if (this.pageCount - 1 < nextPage) {
      nextPage = 0;
    }

    return nextPage;
  }

  /**
   * Get the number of the previous page
   */
  get previousPageNumber() {
    let nextPage = this.currentPageNumber - 1;

    if (nextPage < 0) {
      nextPage = this.pageCount - 1;
    }

    return nextPage;
  }

  /**
   * Get if display is large
   */
  get mdAndUp() {
    return this.$vuetify.breakpoint.mdAndUp;
  }

  /**
   * Handles swiping gestures
   */
  get handleGesture() {
    return {
      right: () => this.openPreviousPage(),
      left: () => this.openNextPage()
    };
  }

  /**
   * Converts each page of the pdf to an image
   */
  async mounted() {
    if (!this.pdf) {
      return;
    }

    const GlobalWorkerOptions = (await pdfJs()).GlobalWorkerOptions;
    GlobalWorkerOptions.workerSrc = await PDFJSWorker();

    const pdf: Uint8Array = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(this.pdf);
      reader.onload = e => {
        resolve(new Uint8Array(e.target?.result as any));
      };
    });

    const images = await convertPdfToImagePromises(pdf);
    this.images.splice(0, this.images.length, ...images);

    this.showPdfAsImage();

    document.addEventListener("keyup", this.handleKeyDown);
  }

  /**
   * removes key event listener
   */
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyDown);
  }

  /**
   * navigate the pdf using keys
   */
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === "ArrowLeft") {
      this.openPreviousPage();
    }

    if (event.key === "ArrowRight") {
      this.openNextPage();
    }

    if (event.key === "Enter" || event.key === " ") {
      this.exportPDF();
    }
  }

  /**
   * Opens next page (or first when on last)
   */
  openNextPage() {
    if (this.pageCount === 1) {
      return;
    }

    this.currentPageNumber = this.nextPageNumber;

    this.showPdfAsImage();
  }

  /**
   * opens previous page (or last when on first)
   */
  openPreviousPage() {
    if (this.pageCount === 1) return;

    this.currentPageNumber = this.previousPageNumber;

    this.showPdfAsImage();
  }

  /**
   * sets current/previous/next pages of pdf
   */
  showPdfAsImage() {
    this.currentLoading = true;
    this.previousLoading = true;
    this.nextLoading = true;

    const previous = this.images[this.previousPageNumber];
    const current = this.images[this.currentPageNumber];
    const next = this.images[this.nextPageNumber];

    if (previous) {
      previous
        .then(v => {
          this.previousLoading = false;
          this.previousPage = v;
        })
        .catch(handleError);
    }
    if (current) {
      current
        .then(v => {
          this.currentLoading = false;
          this.currentPage = v;
        })
        .catch(handleError);
    }
    if (next) {
      next
        .then(v => {
          this.nextLoading = false;
          this.nextPage = v;
        })
        .catch(handleError);
    }
  }

  /**
   * converts current page to image
   */
  async exportPDF() {
    if (this.currentPage) {
      const file = await convertUrlToFile(
        this.currentPage,
        `${this.pdf.name}_${this.currentPageNumber}.jpg`,
        "image/jpg"
      );
      this.$emit("onImageExport", file);
    }
  }
}
