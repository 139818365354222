/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BookingAvailabilityControllerFindAllByPartnerIdParamsGen,
  BookingAvailabilityControllerFindOneByPartnerIdParamsGen,
  BookingBookingAttachmentDtoGen,
  BookingBookingConfirmedViewModelGen,
  BookingBookingUnauthenticatedViewModelGen,
  BookingCreateBookingWithoutResourceDtoGen,
  BookingExceptionViewmodelGen,
  BookingImageIdViewmodelGen,
  BookingPageViewModelGen,
  BookingServiceBookingSummaryViewModelGen,
  BookingServiceBookingViewModelGen,
  BookingUpdateBookingUnauthenticatedDtoGen,
  BookingUpdateSoftDeleteBookingDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Availability<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags availability
   * @name AvailabilityControllerCreate
   * @summary Creates a booking request on a given service for a partner
   * @request POST:/partner/{partnerId}/booking
   * @response `201` `BookingBookingConfirmedViewModelGen` Booking created
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  availabilityControllerCreate = (
    partnerId: string,
    data: BookingCreateBookingWithoutResourceDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingBookingConfirmedViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/booking`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags availability
   * @name AvailabilityControllerUploadImageAttachment
   * @summary Attach an image to a booking
   * @request POST:/partner/{partnerId}/booking/attachment/image
   * @response `200` `BookingImageIdViewmodelGen` the image document
   * @response `400` `void` General error if request is not working.
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  availabilityControllerUploadImageAttachment = (
    partnerId: string,
    data: BookingBookingAttachmentDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingImageIdViewmodelGen, void | BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/booking/attachment/image`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags availability
   * @name AvailabilityControllerFindAllByPartnerId
   * @summary Gets available services for a partner
   * @request GET:/partner/{partnerId}/availability
   * @response `200` `(BookingPageViewModelGen & { data?: (BookingServiceBookingSummaryViewModelGen)[] })`
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  availabilityControllerFindAllByPartnerId = (
    { partnerId, ...query }: BookingAvailabilityControllerFindAllByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      BookingPageViewModelGen & { data?: BookingServiceBookingSummaryViewModelGen[] },
      BookingExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/availability`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags availability
   * @name AvailabilityControllerFindOneByPartnerId
   * @summary Gets slots for a particular service from a given partner starting at a particular day
   * @request GET:/partner/{partnerId}/availability/{id}
   * @response `200` `BookingServiceBookingViewModelGen` The service booking view model
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  availabilityControllerFindOneByPartnerId = (
    { partnerId, id, ...query }: BookingAvailabilityControllerFindOneByPartnerIdParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingServiceBookingViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/availability/${id}`,
      method: "GET",
      query: query,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags availability
   * @name AvailabilityControllerGetById
   * @summary Retrieves a booking by id
   * @request GET:/partner/{partnerId}/booking/{id}/get
   * @response `200` `BookingBookingUnauthenticatedViewModelGen` Booking for a customer
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  availabilityControllerGetById = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<BookingBookingUnauthenticatedViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/booking/${id}/get`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags availability
   * @name AvailabilityControllerDeleteOne
   * @summary Deletes a booking by id
   * @request DELETE:/partner/{partnerId}/booking/{id}/remove
   * @response `200` `BookingBookingUnauthenticatedViewModelGen` Booking deleted
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  availabilityControllerDeleteOne = (
    partnerId: string,
    id: string,
    data: BookingUpdateSoftDeleteBookingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingBookingUnauthenticatedViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/booking/${id}/remove`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags availability
   * @name AvailabilityControllerUpdate
   * @summary Updates a booking request on a given service
   * @request PATCH:/partner/{partnerId}/booking/{id}/update
   * @response `200` `BookingBookingUnauthenticatedViewModelGen` Booking created
   * @response `400` `BookingExceptionViewmodelGen` Bad Request
   * @response `404` `BookingExceptionViewmodelGen` Not Found
   * @response `500` `BookingExceptionViewmodelGen` Internal Server Error
   */
  availabilityControllerUpdate = (
    partnerId: string,
    id: string,
    data: BookingUpdateBookingUnauthenticatedDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<BookingBookingUnauthenticatedViewModelGen, BookingExceptionViewmodelGen>({
      path: `/partner/${partnerId}/booking/${id}/update`,
      method: "PATCH",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
