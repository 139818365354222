var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.editStep === _vm.EditSteps.INITIAL ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm._l(_vm.options, function (option) {
    return _c('v-col', {
      key: option.icon,
      staticClass: "pa-1",
      attrs: {
        "cols": _vm.showPlaceholderOption ? 4 : 6,
        "justify": "center",
        "align": "center"
      }
    }, [_c('tooltip', {
      attrs: {
        "text": option.text
      }
    }, [_c('v-sheet', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "outlined": "",
        "rounded": "",
        "color": option.disabled || _vm.disabled ? 'grey' : 'info'
      },
      on: {
        "click": option.action
      }
    }, [_c('v-card-actions', {
      staticClass: "pt-2 pb-4"
    }, [_c('v-spacer'), _c('v-icon', {
      attrs: {
        "large": "",
        "color": "white"
      }
    }, [_vm._v(" " + _vm._s(option.disabled ? "mdi-lock" : option.icon) + " ")]), _c('v-spacer')], 1), _c('h4', {
      class: ['mt-n5 v-btn v-btn--block v-btn--has-bg theme--light elevation-0 v-size--default', option.disabled || _vm.disabled ? 'grey' : 'info'],
      staticStyle: {
        "color": "white",
        "font-size": "xx-small"
      }
    }, [_vm._v(" " + _vm._s(option.short) + " ")])], 1)], 1)], 1);
  }), _vm.showSave ? _c('v-col', {
    staticClass: "pl-1 pr-1 pb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('document-template-detail-save', {
    ref: "save",
    attrs: {
      "disabled": _vm.disabled,
      "hidden": "",
      "document": _vm.document
    }
  }), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "success",
      "elevation": 0,
      "height": 78,
      "disabled": _vm.disabled,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailSave.save")) + " ")])], 1) : _vm._e()], 2) : _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('document-template-detail-save', {
    ref: "save",
    attrs: {
      "document": _vm.document,
      "hidden": ""
    }
  }), _c('v-btn', {
    attrs: {
      "block": "",
      "color": "success",
      "elevation": 0,
      "disabled": _vm.disabled,
      "loading": _vm.loading,
      "height": _vm.showSave ? '78' : ''
    },
    on: {
      "click": _vm.addField
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.ReportDocumentSignRequestStepperForm.confirmTokenShort")) + " ")])], 1), _c('v-col', {
    staticClass: "pl-1 pr-1 pb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "elevation": 0,
      "outlined": "",
      "disabled": _vm.disabled,
      "height": _vm.showSave ? '78' : ''
    },
    on: {
      "click": _vm.discard
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.discard")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }