/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgChargingStationViewModelGen,
  ThgCreateAnonymousChargingStationDtoGen,
  ThgCreateThgMeterReadingAdminDtoGen,
  ThgCreateThgMeterReadingDtoGen,
  ThgExceptionViewmodelGen,
  ThgOperationIdViewModelGen,
  ThgPageViewModelGen,
  ThgThgChargingStationViewModelGen,
  ThgThgMeterReadingControllerFindAllParamsGen,
  ThgThgMeterReadingControllerUpdateMeterReadingParamsGen,
  ThgThgMeterReadingControllerUpdateStatusAsyncParamsGen,
  ThgThgMeterReadingViewModelGen,
  ThgThgMeterReadingViewModelPopulatedGen,
  ThgTransferChargingStationDtoGen,
  ThgTransferThgMeterReadingDtoGen,
  ThgUpdateThgMeterReadingDtoGen,
  ThgUpdateThgStatusOfThgIdsDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ThgMeterReading<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerCreate
   * @summary Creates a meter reading
   * @request POST:/partner/{partnerId}/meter-reading
   * @secure
   * @response `201` `ThgThgMeterReadingViewModelGen` The created meter reading
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerCreate = (
    partnerId: string,
    data: ThgCreateThgMeterReadingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgMeterReadingViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/meter-reading`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerCreateAnonymous
   * @summary Creates a charging station for an anonymous user
   * @request POST:/partner/{partnerId}/charging-station/anonymous
   * @secure
   * @response `201` `ThgChargingStationViewModelGen` The created charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerCreateAnonymous = (
    partnerId: string,
    data: ThgCreateAnonymousChargingStationDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/charging-station/anonymous`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerTransferChargingStation
   * @summary (ADMIN) Transfer a charging station by userId
   * @request PATCH:/charging-station/{id}/transfer
   * @secure
   * @response `200` `ThgChargingStationViewModelGen` The transferred charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerTransferChargingStation = (
    id: string,
    data: ThgTransferChargingStationDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/charging-station/${id}/transfer`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerCreateMeterReading
   * @summary Creates a meter reading for an charging station for a user
   * @request POST:/partner/{partnerId}/charging-station/{chargingStationId}
   * @secure
   * @response `201` `ThgChargingStationViewModelGen` The created charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerCreateMeterReading = (
    partnerId: string,
    chargingStationId: string,
    data: ThgCreateThgMeterReadingAdminDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/charging-station/${chargingStationId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerUpdateMeterReading
   * @summary (ADMIN) Updates a meter reading
   * @request PATCH:/partner/{partnerId}/meter-reading/{meterReadingId}
   * @secure
   * @response `201` `ThgThgMeterReadingViewModelGen` The update meter reading
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerUpdateMeterReading = (
    { partnerId, meterReadingId, ...query }: ThgThgMeterReadingControllerUpdateMeterReadingParamsGen,
    data: ThgUpdateThgMeterReadingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgMeterReadingViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/meter-reading/${meterReadingId}`,
      method: "PATCH",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerTransferMeterReading
   * @summary (ADMIN) Transfer a meter reading to another partner or user
   * @request PATCH:/partner/{partnerId}/meter-reading/{meterReadingId}/transfer
   * @secure
   * @response `201` `ThgThgMeterReadingViewModelGen` The transferred meter reading
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerTransferMeterReading = (
    partnerId: string,
    meterReadingId: string,
    data: ThgTransferThgMeterReadingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgThgMeterReadingViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/meter-reading/${meterReadingId}/transfer`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerUpdateStatusAsync
   * @summary Update Status of batch
   * @request PATCH:/meter-reading/status/async
   * @secure
   * @response `200` `ThgOperationIdViewModelGen` The id of the operation
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerUpdateStatusAsync = (
    query: ThgThgMeterReadingControllerUpdateStatusAsyncParamsGen,
    data: ThgUpdateThgStatusOfThgIdsDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgOperationIdViewModelGen, ThgExceptionViewmodelGen>({
      path: `/meter-reading/status/async`,
      method: "PATCH",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerFindAllMeterReadingsByChargingStationAndUser
   * @summary List all meter readings by charging station for current user
   * @request GET:/charging-station/me/{id}/meter-reading
   * @secure
   * @response `200` `(ThgThgMeterReadingViewModelGen)[]` The charging stations
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerFindAllMeterReadingsByChargingStationAndUser = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgThgMeterReadingViewModelGen[], ThgExceptionViewmodelGen>({
      path: `/charging-station/me/${id}/meter-reading`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerFindAllMine
   * @summary List all meter reading
   * @request GET:/meter-reading/me
   * @secure
   * @response `200` `(ThgThgMeterReadingViewModelGen)[]` The meter readings
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerFindAllMine = (params: RequestParams = {}) =>
    this.http.request<ThgThgMeterReadingViewModelGen[], ThgExceptionViewmodelGen>({
      path: `/meter-reading/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerFindOneOfMine
   * @summary Get a meter reading
   * @request GET:/meter-reading/me/{id}
   * @secure
   * @response `200` `ThgThgMeterReadingViewModelGen` The meter reading
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerFindOneOfMine = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgThgMeterReadingViewModelGen, ThgExceptionViewmodelGen>({
      path: `/meter-reading/me/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerFindAll
   * @summary (ADMIN) List all meter reading
   * @request GET:/meter-reading
   * @secure
   * @response `200` `((ThgPageViewModelGen & { data?: (ThgThgMeterReadingViewModelPopulatedGen)[] }) | (ThgThgMeterReadingViewModelGen)[])`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerFindAll = (
    query: ThgThgMeterReadingControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      (ThgPageViewModelGen & { data?: ThgThgMeterReadingViewModelPopulatedGen[] }) | ThgThgMeterReadingViewModelGen[],
      ThgExceptionViewmodelGen
    >({
      path: `/meter-reading`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerGetWorkItem
   * @summary (ADMIN) Returns a list of meter readings to be confirmed
   * @request GET:/meter-reading/nextworkitem
   * @secure
   * @response `200` `ThgThgMeterReadingViewModelGen` The to be confirmed meter readings
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerGetWorkItem = (params: RequestParams = {}) =>
    this.http.request<ThgThgMeterReadingViewModelGen, ThgExceptionViewmodelGen>({
      path: `/meter-reading/nextworkitem`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerFindAllConfirmedMeterReadingsWithChargingStations
   * @summary (ADMIN) Returns meter readings and charging station information for UBA export
   * @request GET:/meter-reading/confirmed
   * @secure
   * @response `200` `(ThgThgChargingStationViewModelGen)[]` The charging stations
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerFindAllConfirmedMeterReadingsWithChargingStations = (params: RequestParams = {}) =>
    this.http.request<ThgThgChargingStationViewModelGen[], ThgExceptionViewmodelGen>({
      path: `/meter-reading/confirmed`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerFindOne
   * @summary (ADMIN) Get a meter reading
   * @request GET:/meter-reading/{id}
   * @secure
   * @response `200` `ThgThgMeterReadingViewModelGen` The meter reading
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerFindOne = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgThgMeterReadingViewModelGen, ThgExceptionViewmodelGen>({
      path: `/meter-reading/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerUpdate
   * @summary (ADMIN) Updates a meter reading
   * @request PATCH:/meter-reading/{id}
   * @secure
   * @response `200` `ThgThgMeterReadingViewModelGen` The updated meter reading
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerUpdate = (id: string, data: ThgUpdateThgMeterReadingDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgThgMeterReadingViewModelGen, ThgExceptionViewmodelGen>({
      path: `/meter-reading/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerRemove
   * @summary (ADMIN) Removes a meter reading
   * @request DELETE:/meter-reading/{id}
   * @secure
   * @response `200` `ThgThgMeterReadingViewModelGen` The removed meter reading
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerRemove = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgThgMeterReadingViewModelGen, ThgExceptionViewmodelGen>({
      path: `/meter-reading/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ThgMeterReading
   * @name ThgMeterReadingControllerFindAllMeterReadingsByChargingStation
   * @summary (ADMIN) List all meter readings by charging station
   * @request GET:/charging-station/{id}/meter-reading
   * @secure
   * @response `200` `(ThgThgMeterReadingViewModelGen)[]` The charging stations
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  thgMeterReadingControllerFindAllMeterReadingsByChargingStation = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgThgMeterReadingViewModelGen[], ThgExceptionViewmodelGen>({
      path: `/charging-station/${id}/meter-reading`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
