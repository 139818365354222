


























































































import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import FileInput from "@/components/utility/FileInput.vue";
import { beforeCurrentDateRule, dateRule } from "@/lib/rules/dateRule";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { maxSizeRule } from "@/lib/rules/maxSizeRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { SignCreateDocumentDtoGen } from "@/services/sign/v1/data-contracts";
import { ISignDocument } from "@/models/sign-document.entity";
import { Component, Vue, Prop } from "vue-property-decorator";

class CreateDocumentDtoGen implements SignCreateDocumentDtoGen {
  name = "";
  file: File = undefined as any;
  title = "";
  folder? = "/";
  description?: string;
  tags: string[] = [];
  isTemplate = false;

  constructor(document?: ISignDocument) {
    if (document) {
      this.name = document.name;
      this.title = document.title;
      this.folder = document.folder;
      this.description = document.description;
      this.tags = document.tags;
      this.isTemplate = document.isTemplate;
    }
  }
}

@Component({
  components: { FileUploadMulti, FileInput }
})
export default class SignDocumentForm extends Vue {
  @Prop({ default: false })
  isLoading!: boolean;

  @Prop({})
  document!: ISignDocument;

  @Prop({ default: false })
  hideTemplateOption?: boolean;

  @Prop({ default: false })
  hideButton?: boolean;

  @Prop({ default: "/" })
  folder?: string;

  isValid = false;

  readonly maxFileSizeInMb = 5;

  get valid() {
    this.$emit("updateValid", this.isValid);
    return this.isValid;
  }

  set valid(valid: boolean) {
    this.isValid = valid;
    this.$emit("updateValid", this.isValid);
  }

  createDocumentDto: SignCreateDocumentDtoGen = (() => {
    const doc = new CreateDocumentDtoGen(this.document);

    if (!this.document) {
      doc.folder = this.folder;
    }

    return doc;
  })();

  get disabled() {
    return this.isLoading || typeof this.document !== "undefined";
  }

  get maxSizeRule() {
    return [maxSizeRule(this.maxFileSizeInMb * 1000 * 1024), requiredRule()];
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get numberRule() {
    return [isNumberRule(), requiredRule()];
  }

  get dateRule() {
    return [dateRule(), beforeCurrentDateRule()];
  }

  save() {
    this.$emit("save", this.createDocumentDto);
  }

  resetForm() {
    this.createDocumentDto = new CreateDocumentDtoGen(this.document);
  }

  onChange(file: File) {
    this.createDocumentDto.name = file.name;
    this.createDocumentDto.title = file.name;
  }

  removeWhiteSpace() {
    if (this.createDocumentDto.folder) {
      this.createDocumentDto.folder = this.createDocumentDto.folder.replace(/\s/g, "");
    }
  }
}
