import {
  ThgCreateThgMeterReadingDtoGen,
  ThgOperationIdViewModelGen,
  ThgThgMeterReadingControllerUpdateStatusAsyncParamsGen,
  ThgThgMeterReadingViewModelGen,
  ThgUpdateThgStatusOfThgIdsDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";
import { ThgHttpClientProvider } from "./../thg-http-client.provider";
import { ThgMeterReading } from "./../v1/ThgMeterReading";

/**
 * Service to communicate with backend.
 */
export class MeterReadingService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for communicating with backend.
   */
  meterReadingProxy: ThgMeterReading;

  /**
   * @class Initialize Service
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.meterReadingProxy = new ThgMeterReading(this.client);
  }

  /**
   * Creates a meter reading on a partner
   *
   * @param partnerId the partner id
   * @param data the meter reading dto
   */
  async create(partnerId: string, data: ThgCreateThgMeterReadingDtoGen) {
    return this.meterReadingProxy.thgMeterReadingControllerCreate(partnerId, data).then(value => value.data);
  }

  /**
   * Gets current users meter readings
   *
   * @returns the meter readings of the current user
   */
  async getAllForUser() {
    return this.meterReadingProxy.thgMeterReadingControllerFindAllMine().then(value => value.data);
  }

  /**
   * Gets one meter reading of current user.
   *
   * @param id the meter readings id
   * @returns one meter reading of the current user
   */
  async getOneForUser(id: string) {
    return this.meterReadingProxy.thgMeterReadingControllerFindOneOfMine(id).then(value => value.data);
  }

  /**
   *
   * @param id Gets all meter reading for current user by charging station
   * @returns list of meter reading of the current user
   */
  async getMeterReadingByChargingStation(id: string) {
    return this.meterReadingProxy
      .thgMeterReadingControllerFindAllMeterReadingsByChargingStationAndUser(id)
      .then(value => value.data);
  }

  /**
   * Creates operation to update the status of meter readings
   * @param query
   * @param data
   * @returns
   */
  async updateStatuses(
    query: ThgThgMeterReadingControllerUpdateStatusAsyncParamsGen,
    data: ThgUpdateThgStatusOfThgIdsDtoGen
  ): Promise<ThgOperationIdViewModelGen> {
    return (await this.meterReadingProxy.thgMeterReadingControllerUpdateStatusAsync(query, data)).data;
  }

  /**
   * Gets a meter reading work item
   * @returns
   */
  async getWorkItem(): Promise<ThgThgMeterReadingViewModelGen> {
    return (await this.meterReadingProxy.thgMeterReadingControllerGetWorkItem()).data;
  }
}

/**
 * Service to communicate with backend for current user.
 */
export default new MeterReadingService(new ThgHttpClientProvider());
