






































































































import { PaginationFilterListElement, PaginationFilterOperationEnum } from "@/store/modules/base-pagination.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import FilterCardPaginationMenuFilterElement from "./FilterCardPaginationMenuFilterElement.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: {
    FilterCardPaginationMenuFilterElement
  }
})
export default class FilterCardPagination extends Vue {
  @Prop({ default: [] })
  filterList!: PaginationFilterListElement[];

  @Prop({ default: false })
  large!: boolean;

  @Prop({ default: () => [] })
  filter!: IPageFilterElement[];

  @Prop({ default: undefined })
  predefinedFilter!: [{ name: string; filter: IPageFilterElement[] }];

  /**
   * Content on search bar
   */
  @Prop()
  search!: string;

  menu = false;

  isValid = false;

  tempFilter: IPageFilterElement[] = [];

  get mdSizeFilter() {
    if (this.predefinedFilter) {
      return 8;
    }
    return 12;
  }

  get _filter() {
    return this.filter;
  }

  set _filter(filter: IPageFilterElement[]) {
    this.$emit("update:filter", filter);
  }

  get operations() {
    return Object.values(PaginationFilterOperationEnum);
  }

  setPredefinedFilter(filter: IPageFilterElement[]) {
    this.tempFilter.splice(0);
    this.$nextTick(() => {
      this.tempFilter.push(...filter);
      this.confirmFilter();
    });
  }

  removeItem(index: number) {
    this.tempFilter.splice(index, 1);
  }

  addFilter() {
    this.tempFilter.push(new PageFilterElement({ key: "", operation: PaginationFilterOperationEnum.EQUAL, value: "" }));
  }

  confirmFilter() {
    this.filter.splice(0, this.filter.length, ...this.tempFilter);
    this.$emit("confirmFilter");
    this.menu = false;
  }

  removeAll() {
    this.tempFilter.splice(0);
    this._filter.splice(0);
    this.confirmFilter();
  }

  /**
   *
   */
  initTempFilter() {
    this.tempFilter.splice(0);
    // Copy filter as new object. Otherwise if the value is changed the changes are applied immediately as the reference is the same
    this._filter.forEach(f => this.tempFilter.push(new PageFilterElement(f)));
  }
}
