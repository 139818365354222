











































import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBankingDtoGen } from "@/services/thg/v1/data-contracts";
import { BankingModule } from "@/store/modules/banking.store";
import { Component } from "vue-property-decorator";
import { ibanRule } from "@/lib/rules/ibanRule";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({
  components: {}
})
export default class CreateOrUpdateBankDialog extends DarkModeHighlightMixin {
  dialog = false;
  loading = false;
  valid = false;

  banking: ThgBankingDtoGen = {
    iban: "",
    bank: "",
    name: ""
  };

  initialize() {
    this.banking = deepCopy(BankingModule.emptyBanking);
  }

  get ibanRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(ibanRule());

    return rules;
  }

  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  closeDialog() {
    this.dialog = false;
  }

  async save() {
    this.loading = true;
    try {
      await BankingModule.createOrUpdateMyBanking(this.banking);
    } catch (error) {
      this.$toast.error("Bankverbindung konnte nicht geändert werden.");
    } finally {
      this.loading = false;
      this.dialog = false;
    }
  }
}
