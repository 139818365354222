


























import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";

@Component({
  components: {}
})
export default class LoginCardUnauthorized extends Vue {
  constructor() {
    super();
  }

  get requiredPermission() {
    return this.$route.params;
  }

  async mounted() {
    if (this.$route.params.action && this.$route.params.subject) {
      try {
        this.$log.info("Update User.");
        await UserModule.getUserInfo();
      } catch (error) {
        this.$log.error(error);
      }
      if (UserModule.abilities.can(this.$route.params.action, this.$route.params.subject)) {
        this.$log.info("User has permission.");
        this.$router.go(-1);
      }
    }
  }

  async backToLogin() {
    await UserModule.logout();
    this.$router.push({ path: "/login" });
  }
}
