

























































































import ContactCustomerDialog from "@/components/utility/ContactCustomerDialog.vue";
import { ContactCustomerEnum } from "@/lib/enum/ContactCustomer.enum";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { emailRule, phoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import { CreateSignatureUrlFactory } from "@/lib/utility/createSignatureUrlFactory";

@Component({
  components: {
    ContactCustomerDialog
  }
})
export default class SignInitializeCard extends DarkModeHighlightMixin {
  /**
   * The id to the sign request
   */
  @Prop()
  id?: string;

  /**
   * The firstName to prefill the report
   */
  @Prop({})
  recipientFirstName?: string;

  /**
   * The lastName to prefill the report
   */
  @Prop({})
  recipientLastName?: string;

  /**
   * The first name that could be overwritten
   */
  firstName = "";

  /**
   * The last name that could be overwritten
   */
  lastName = "";

  /**
   * The Url of the report, e.g. app.schadensmeldung.digital
   */
  baseReportUrl = "";

  /**
   * Get the base report URl of the Partner from the Partnermodulke
   */
  get baseReportUrls() {
    if (!PartnerModule.partner.settings?.urls) {
      return [];
    }

    return PartnerModule.partner.settings.urls;
  }

  /**
   * Creates the Customername from first and lastname
   */
  get customerName() {
    return this.firstName + " " + this.lastName;
  }

  /**
   * Creates the url to the report and appends the specified query params
   */
  get createUrlWithQuery() {
    return new CreateSignatureUrlFactory(this.baseReportUrl, this.id ?? "", this.firstName, this.lastName).build();
  }

  mounted() {
    this.clear();
  }

  /**
   * Clear the dialog and set default values
   */
  async clear() {
    this.firstName = this.recipientFirstName || "";
    this.lastName = this.recipientLastName || "";
    if (this.baseReportUrls[0]) {
      this.baseReportUrl = PartnerModule.partner.settings?.defaultUrl || PartnerModule.partner.settings?.urls[0] || "";
    }
  }

  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.createUrlWithQuery);
    this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
    this.$emit("close");
  }

  /**
   * Appends an query to an Url
   */
  appendQuery(queryParamName: string, value: string): string {
    if (value) {
      return queryParamName + "=" + encodeURI(value) + "&";
    } else {
      return "";
    }
  }

  /**
   * Returns the actual date as an simple date
   */
  get date() {
    return simpleDate(new Date().toISOString());
  }

  /**
   * Rules for the Email Input
   */
  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  /**
   * Rules for the firstname and lastname input
   */
  get nameRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  /**
   * Rules for the numberplate and lastname input
   */
  get numberPlateRules() {
    const rules = [];
    rules.push(numberplateRule());

    return rules;
  }

  /**
   * Rules for the phonenumber and lastname input
   */
  get phoneNumberRules() {
    const rules = [];

    rules.push(phoneRule());

    return rules;
  }

  /**
   * Gets the correct templates ressource depending on partnerType, e.g. workshop or fleet
   */
  get resource() {
    if (PartnerModule.partner.partnerType === PartnerTypeEnum.WORKSHOP) {
      return ContactCustomerEnum.INITIAL_REPORT_WORKSHOP;
    } else if (PartnerModule.partner.partnerType === PartnerTypeEnum.FLEET) {
      return ContactCustomerEnum.INITIAL_REPORT_FLEET;
    } else {
      return ContactCustomerEnum.INITIAL_REPORT;
    }
  }
}
