import { resizeImage } from "@/lib/files/imageResizer";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { Thg } from "../v1/Thg";
import {
  ThgBankingViewModelGen,
  ThgCalculateEmissionSavingsDtoGen,
  ThgChargingStationProofDtoGen,
  ThgCreateAnonymousThgDtoGen,
  ThgCreateThgAndUserDtoGen,
  ThgCreateThgMeDtoGen,
  ThgCreateThgPartnerQuickCheckoutDtoGen,
  ThgCreateThgVehicleDtoGen,
  ThgDecommissionThgDtoGen,
  ThgDuplicateThgViewModelGen,
  ThgEditThgImageDataDtoGen,
  ThgOperationIdViewModelGen,
  ThgPageViewModelGen,
  ThgRegistrationDtoGen,
  ThgThgBonusViewmodelGen,
  ThgThgControllerFindAllPaginatedParamsGen,
  ThgThgControllerFindAllParamsGen,
  ThgThgControllerGetWorkItemParamsGen,
  ThgThgControllerUpdateStatusAsyncParamsGen,
  ThgThgMeViewModelGen,
  ThgThgViewModelGen,
  ThgTransferThgDtoGen,
  ThgUpdateThgAdminDtoGen,
  ThgUpdateThgDtoGen,
  ThgUpdateThgRegistrationImagesDtoGen,
  ThgUpdateThgStatusDtoGen,
  ThgUpdateThgStatusOfThgIdsDtoGen
} from "../v1/data-contracts";
import { ThgCreateThgImageDtoGen } from "./../v1/data-contracts";
import { HttpClient } from "./../v1/http-client";

/**
 * Communicates with thg backend
 */
class ThgService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: Thg;

  /**
   * @class Initialize ThgService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Thg(this.client);
  }

  async createNewThgAndUserRegistration(partnerId: string, body: ThgCreateThgAndUserDtoGen) {
    return await this.proxy.thgControllerNewThg(partnerId, body);
  }

  async createAnonymous(partnerId: string, userNotification = true, body: ThgCreateAnonymousThgDtoGen) {
    const response = await this.proxy.thgControllerCreateAnonymous({ partnerId, userNotification }, body);

    return response.data;
  }

  async createMyThg(thgId: string, data: ThgCreateThgMeDtoGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerCreateMyThg(thgId, data);
    return response.data;
  }

  async quickCheckoutAsAdmin(
    thgId: string,
    partnerId: string,
    data: ThgCreateThgPartnerQuickCheckoutDtoGen,
    userNotification: boolean
  ): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerQuickCheckoutAsAdmin({ thgId, partnerId, userNotification }, data);
    return response.data;
  }

  /**
   * Upload a proof for a thg
   * @param data the file
   */
  async uploadProof(dto: { data: ThgChargingStationProofDtoGen; thgId: string }) {
    return this.proxy.thgControllerUploadImageProof(dto.thgId, dto.data).then(value => value.data);
  }

  /**
   * Upload a proof for a thg
   * @param data the file
   */
  async uploadProofAdmin(dto: { data: ThgChargingStationProofDtoGen; thgId: string; partnerId: string }) {
    return this.proxy.thgControllerUploadImageProofAdmin(dto.partnerId, dto.thgId, dto.data).then(value => value.data);
  }

  async getMyThgs(): Promise<ThgThgMeViewModelGen[]> {
    const response = await this.proxy.thgControllerFindMyThgs();
    return response.data;
  }

  async getMyThg(thgId: string): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerGetMyThgById(thgId);
    return response.data;
  }

  async getAllThgs(
    params: ThgThgControllerFindAllParamsGen
  ): Promise<
    | (ThgPageViewModelGen & {
        data?: ThgThgViewModelGen[] | undefined;
      })
    | ThgThgViewModelGen[]
  > {
    const response = await this.proxy.thgControllerFindAll(params);
    return response.data;
  }

  async getAllThgsPaginated(query: ThgThgControllerFindAllPaginatedParamsGen): Promise<ThgPageViewModelGen> {
    const response = await this.proxy.thgControllerFindAllPaginated(query);

    return response.data;
  }

  async getThgsByStatusAndDateRange(
    status: ThgStatusEnum,
    isGeneratingSastoken: boolean,
    from: string,
    to: string
  ): Promise<ThgThgViewModelGen[]> {
    const response = await this.proxy.thgControllerFindAllAsAdmin({
      status: status,
      isGeneratingSastoken: isGeneratingSastoken,
      from,
      to
    });
    return response.data;
  }

  async getThg(thgId: string): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerFind(PartnerModule.partner.id || PartnerModule.partner._id, thgId);
    return response.data;
  }

  async getMany(ids: string[]): Promise<ThgThgViewModelGen[]> {
    const allIds = [...ids];
    const results: ThgThgViewModelGen[] = [];

    while (allIds.length) {
      const idBatch = allIds.splice(0, 80);
      const response = await this.proxy.thgControllerFindMany({ ids: idBatch });
      results.push(...response.data);
    }

    return results;
  }

  async getThgByPartnerId(thgId: string, partnerId: string): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerFind(partnerId, thgId);
    return response.data;
  }

  async getThgDup(year?: number): Promise<ThgDuplicateThgViewModelGen[]> {
    const response = await this.proxy.thgControllerFindDupVin({ year });
    return response.data;
  }

  async getThgWorkItem(query: ThgThgControllerGetWorkItemParamsGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerGetWorkItem(query);
    return response.data;
  }

  async addThg(partnerId: string, body: ThgCreateThgVehicleDtoGen) {
    return await this.proxy.thgControllerAddThg(partnerId, body);
  }

  async updateMyThg(thgId: string, dto: ThgUpdateThgDtoGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerUpdateMyThg(thgId, dto);
    return response.data;
  }

  async updateThg(partnerId: string, thgId: string, dto: ThgUpdateThgAdminDtoGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerUpdate(partnerId, thgId, dto);
    return response.data;
  }

  async transfer(partnerId: string, thgId: string, dto: ThgTransferThgDtoGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerTransfer(partnerId, thgId, dto);
    return response.data;
  }

  async updateStatus(
    partnerId: string,
    thgId: string,
    dto: ThgUpdateThgStatusDtoGen,
    noUserNotification = false
  ): Promise<ThgThgViewModelGen> {
    const params = {
      noUserNotification: noUserNotification,
      partnerId: partnerId,
      thgId: thgId
    };

    const response = await this.proxy.thgControllerUpdateStatus(params, dto);
    return response.data;
  }

  /**
   * Updates status of thgs asynchronously in background
   *
   * @param query
   * @param data
   * @returns
   */
  async updateStatuses(
    query: ThgThgControllerUpdateStatusAsyncParamsGen,
    data: ThgUpdateThgStatusOfThgIdsDtoGen
  ): Promise<ThgOperationIdViewModelGen> {
    const response = await this.proxy.thgControllerUpdateStatusAsync(query, data);
    return response.data;
  }

  async updateRegistration(partnerId: string, thgId: string, dto: ThgRegistrationDtoGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerAddRegistration(partnerId, thgId, dto);
    return response.data;
  }

  async decommission(partnerId: string, thgId: string, dto: ThgDecommissionThgDtoGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerDecommission(partnerId, thgId, dto);
    return response.data;
  }

  async calculateEmissionSavings(
    partnerId: string,
    thgId: string,
    dto: ThgCalculateEmissionSavingsDtoGen
  ): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerCalculateEmissionSavings(partnerId, thgId, dto);
    return response.data;
  }

  async calculateMyEmissionSavings(thgId: string, dto: ThgCalculateEmissionSavingsDtoGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerCalculateMyEmissionSavings(thgId, dto);
    return response.data;
  }

  async getBankingInformation(partnerId: string, thgId: string): Promise<ThgBankingViewModelGen> {
    const response = await this.proxy.thgControllerGetBankingInformation(partnerId, thgId);
    return response.data;
  }

  async updateMyRegistration(thgId: string, dto: ThgRegistrationDtoGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerAddRegistrationToMyThg(thgId, dto);
    return response.data;
  }

  async updateMyRegistrationImages(
    thgId: string,
    dto: ThgUpdateThgRegistrationImagesDtoGen
  ): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerUpdateMyRegistrationImages(thgId, dto);
    return response.data;
  }

  async decommissionMy(thgId: string, dto: ThgDecommissionThgDtoGen): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerDecommissionMyThg(thgId, dto);
    return response.data;
  }

  async editRegistrationImages(
    partnerId: string,
    thgId: string,
    data: ThgEditThgImageDataDtoGen
  ): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerEditRegistrationImages(partnerId, thgId, data);
    return response.data;
  }

  async updateRegistrationImages(
    partnerId: string,
    thgId: string,
    dto: ThgUpdateThgRegistrationImagesDtoGen
  ): Promise<ThgThgViewModelGen> {
    const response = await this.proxy.thgControllerUpdateRegistrationImages(partnerId, thgId, dto);
    return response.data;
  }

  async addImage(image: File, type: ReportImageType, partnerId: string): Promise<IImageUploaded> {
    if (ConfigModule.isResizingImagesReport) {
      image = (await resizeImage(image, ConfigModule.registrationImageResizingOptions)) as File;
    }

    const body: ThgCreateThgImageDtoGen = {
      image: image,
      type: type as any
    };

    const uploadedImage = await this.proxy.thgControllerUploadImageQuery({ partnerId }, body);

    return {
      uploadId: uploadedImage.data.imageId,
      file: image,
      isUploaded: true
    } as IImageUploaded;
  }

  async getBonus(): Promise<ThgThgBonusViewmodelGen> {
    return this.proxy.thgControllerBonus().then(r => r.data);
  }
}

export default new ThgService(new ThgHttpClientProvider());
