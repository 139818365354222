var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "colContainer",
    attrs: {
      "outlined": _vm.outlined,
      "elevation": 0
    }
  }, _vm._l(_vm.pages, function (page, index) {
    return _c('v-sheet', {
      key: 'pageNumber' + index,
      staticClass: "ma-2",
      attrs: {
        "outlined": _vm.outlined,
        "rounded": "",
        "color": index === _vm.currentPageNumber ? 'info' : ''
      }
    }, [_c('v-card', {
      staticStyle: {
        "margin": "2px"
      },
      attrs: {
        "flat": "",
        "outlined": index === _vm.currentPageNumber
      },
      on: {
        "click": function click($event) {
          return _vm.setCurrentPage(index);
        }
      }
    }, [_c('v-img', {
      staticClass: "ma-auto",
      attrs: {
        "contain": "",
        "src": page.src
      }
    })], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }