
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class FileInputMixin extends Vue {
  @Prop({ default: 2 })
  maxFileSizeInMb!: number;

  @Prop({ default: true })
  onlyPdf!: boolean;

  isDrag = false;

  isDrop = false;

  isFailed = false;

  get prependIcon() {
    if (!this.isDrag) {
      return "mdi-paperclip";
    }

    if (this.isDrag && !this.isDrop) {
      return "mdi-file-upload";
    }

    if (this.isFailed) {
      return "mdi-close";
    }

    return "mdi-check";
  }

  newFile!: File;

  async handleFileHook(file: File) {
    this.newFile = file;
  }

  get file() {
    return this.newFile;
  }

  set file(file: File) {
    this.setFile(file);
  }

  async setFile(file: File) {
    let isFailed = false;
    if (file.size > 1024 * 1000 * this.maxFileSizeInMb) {
      this.$toast.error(this.$t("sign.ReportDocumentSignRequestForm.maxSize", { size: this.maxFileSizeInMb }));
      isFailed = true;
    }
    if (this.onlyPdf && file.type !== "application/pdf") {
      this.$toast.error(this.$t("sign.ReportDocumentSignRequestForm.fileType"));
      isFailed = true;
    }

    if (isFailed) {
      this.isFailed = true;
      setTimeout(() => {
        this.isDrag = false;
        this.isDrop = false;
        this.isFailed = false;
      }, 1000);
      return;
    }

    await this.handleFileHook(file);
  }

  dragOver() {
    this.isDrag = true;
  }

  dragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (!event.relatedTarget || !(event.currentTarget as any)?.contains(event.relatedTarget)) {
      this.isDrag = false;
    }
  }

  onFileDrop(event: DragEvent) {
    const files = event.dataTransfer?.files;
    if (!files || !files[0]) {
      this.isDrag = false;
      return;
    }
    const file = files[0];

    this.isDrop = true;

    this.file = file;

    setTimeout(() => {
      this.isDrag = false;
      this.isDrop = false;
      this.isFailed = false;
    }, 1000);
  }
}
