







import { Component, Prop, Vue } from "vue-property-decorator";

import { ConfigModule } from "@/store/modules/config";

@Component
export default class Debug extends Vue {
  @Prop({})
  debug: any;

  get isDebug() {
    return ConfigModule.debug;
  }
}
