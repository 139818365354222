import { ConfigModule } from "@/store/modules/config";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { IReportHomeQueryParams } from "../interfaces/Report/IReportHomeQueryParams";

export const thgQueryParams: IReportHomeQueryParams[] = [
  {
    key: "numberPlate",
    onFound: (value: string) => ThgCreateModule.setNumberplate(value),
    get: () => ThgCreateModule.numberplate
  },
  {
    key: "firstName",
    onFound: (value: string) => ThgCreateModule.setUser({ firstName: value }),
    get: () => ThgCreateModule.user.firstName
  },
  {
    key: "lastName",
    onFound: (value: string) => ThgCreateModule.setUser({ lastName: value }),
    get: () => ThgCreateModule.user.lastName
  },
  {
    key: "phone",
    onFound: (value: string) => ThgCreateModule.setContact({ phone: value }),
    get: () => ThgCreateModule.contact.phone
  },
  {
    key: "email",
    onFound: (value: string) => ThgCreateModule.setContact({ email: value }),
    get: () => ThgCreateModule.contact.email
  },
  {
    key: "city",
    onFound: (value: string) => ThgCreateModule.setAddress({ city: value }),
    get: () => ThgCreateModule.address.city
  },
  {
    key: "zip",
    onFound: (value: string) => ThgCreateModule.setAddress({ zip: value }),
    get: () => ThgCreateModule.address.zip
  },
  {
    key: "street",
    onFound: (value: string) => ThgCreateModule.setAddress({ street: value }),
    get: () => ThgCreateModule.address.street
  },
  {
    key: "campaign",
    onFound: (value: string) => ThgCreateModule.setCampaign(value),
    get: () => ThgCreateModule.campaign
  },
  {
    key: "code",
    onFound: (value: string) => ThgCreateModule.setCode(value),
    get: () => ThgCreateModule.code
  },
  {
    key: "headless",
    onFound: () => ConfigModule.setHeadless({ value: true }),
    get: () => (ConfigModule.headless ? "true" : "false")
  },
  {
    key: "accountowner",
    onFound: (value: string) => ThgCreateModule.setBanking({ name: value }),
    get: () => ThgCreateModule.banking.name
  },
  {
    key: "iban",
    onFound: (value: string) => ThgCreateModule.setBanking({ iban: value }),
    get: () => ThgCreateModule.banking.iban
  },
  {
    key: "bankname",
    onFound: (value: string) => ThgCreateModule.setBanking({ bank: value }),
    get: () => ThgCreateModule.banking.bank
  }
];
