var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('login-component-layout', {
    attrs: {
      "component": "LoginCardReset"
    }
  }, [!(_vm.$route.query.code || _vm.$route.params.code) ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("components.login.LoginCardReset.instructions")) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t("components.login.LoginCardReset.shortInstructions")) + " ")]), _c('v-form', {
    staticClass: "mt-7",
    attrs: {
      "id": "form"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('login-component-input-mail'), _c('login-component-input-code'), _c('login-component-input-password', {
    attrs: {
      "suffix": "New",
      "rule": _vm.passwordNewRule
    }
  }), _c('login-component-input-password', {
    attrs: {
      "suffix": "Repeat",
      "rule": _vm.passwordRepeatRule
    }
  }), _c('login-component-password-requirement-list', {
    attrs: {
      "password": _vm.passwordNew,
      "setPass": _vm.setPass
    }
  }), _c('login-component-message', {
    attrs: {
      "failText": _vm.failText,
      "successText": _vm.successText
    }
  }), _c('login-component-submit-button', {
    attrs: {
      "isLoading": _vm.isLoading,
      "isFormValid": !_vm.isButtonDisabled,
      "component": "LoginCardReset"
    }
  }), _c('login-component-navigation-button', {
    attrs: {
      "to": "/login/forgot/"
    }
  }), _c('login-component-navigation-button', {
    attrs: {
      "to": "/login"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }