/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * The payout option selected by the user.
 * - **Regular**: Payment 5 days after entity approval.
 * - **Express**: Immediate payment after processing.
 * - **Deferred**: Payment upon product carry over.
 *
 * This choice affects the payout configuration and purchasing price.
 * 	@default PayoutOptionEnum.REGULAR
 */
export enum PayoutOptionEnum {
  /**
   * Regular payment 5 days after certifying entity approval
   */
  REGULAR = "regular",

  /**
   * Represents the “Sofort Auszahlung” option, offering an immediate payment, typically at a lower amount.
   */
  EXPRESS = "express",

  /**
   * Payment at a much later time (typically in March next year when the product is carried over to the customer)
   *
   */
  DEFERRED = "deferred"
}
