import { MrfiktivCustomFieldConfigurationViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import {
  CustomFieldConfigurationElement,
  ICustomFieldConfigurationElement
} from "./custom-field-configuration-element.entity";

class CustomFieldConfigurationBase implements MrfiktivCustomFieldConfigurationViewModelGen {
  values: ICustomFieldConfigurationElement[];

  constructor(config?: Partial<MrfiktivCustomFieldConfigurationViewModelGen>) {
    this.values = config?.values?.map(value => new CustomFieldConfigurationElement(value)) ?? [];
  }
}

type ICustomFieldConfiguration = CustomFieldConfigurationBase;
const CustomFieldConfiguration = CustomFieldConfigurationBase;

export { ICustomFieldConfiguration, CustomFieldConfiguration };
