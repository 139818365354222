import { EventUIDto, IEventUIDto } from "@/lib/dto/event/event-ui.dto";
import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";

export const EventDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IEventUIDto> {
  getIdentifier(entity: IEventUIDto): string {
    return entity.id || `${entity.recurringEventId}-${entity.start}`;
  }

  protected mapEntity(entity: IEventUIDto): IEventUIDto {
    return new EventUIDto(entity);
  }
})();
