import { TemplateFormatterEnum } from "../enum/TemplateFormatters.enum";
import { ITemplateExtendedContext } from "../interfaces/template/templateContext.interface";

const Mustache = (() => require("mustache"))();

export function renderTemplate(tempalte: string, input: ITemplateExtendedContext): string {
  return Mustache.render(tempalte, {
    ...input,
    [TemplateFormatterEnum.DDMMYYYY]: function() {
      return (timestamp: string) => {
        const dateString = Mustache.render(timestamp, input).trim();

        return new Date(dateString).toLocaleDateString("de-de", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit"
        });
      };
    },
    [TemplateFormatterEnum.HHMM]: function() {
      return (timestamp: string) => {
        const dateString = Mustache.render(timestamp, input).trim();

        return `${new Date(dateString)
          .getHours()
          .toString()
          .padStart(2, "0")}:${new Date(dateString)
          .getMinutes()
          .toString()
          .padStart(2, "0")}`;
      };
    }
  });
}
