import { MrfiktivVehicleRegistrationViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Registration } from "./registration.entity";

class VehicleRegistrationBase extends Registration implements MrfiktivVehicleRegistrationViewModelGen {
  transmission?: "automatic" | "manual" | "" = "";
  model?: string = "";

  constructor(registration?: Partial<IVehicleRegistration>) {
    super(registration);

    this.transmission = registration?.transmission;
    this.model = registration?.model;
  }
}

type IVehicleRegistration = VehicleRegistrationBase;
const VehicleRegistration = VehicleRegistrationBase;

export { IVehicleRegistration, VehicleRegistration };
