
import { ISignDocument } from "@/models/sign-document.entity";
import { Component, Vue } from "vue-property-decorator";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { DocumentDetailSideBarElements } from "./DocumentDetailCardEditor.vue";
const PDFJSWorker = () => import("pdfjs-dist/legacy/build/pdf.worker.entry");
const pdfJs = () => import("pdfjs-dist/legacy/build/pdf");

export interface IDocumentPageMeta {
  scaleX: number;
  scaleY: number;
  src: string;
  pdfWidth: number;
  pdfHeight: number;
}

@Component({})
export default class DocumentDetailEditorMixin extends Vue {
  pages: IDocumentPageMeta[] = [];

  currentPageNumber = 0;

  get currentPage() {
    return this.pages[this.currentPageNumber];
  }

  async initialize(signDocument: ISignDocument) {
    const pdfjs = await pdfJs();
    const GlobalWorkerOptions = pdfjs.GlobalWorkerOptions;
    GlobalWorkerOptions.workerSrc = await PDFJSWorker();
    const pdf = await pdfjs.getDocument(signDocument.url).promise;

    const pagesAsync = [];
    const totalPages = pdf.numPages;
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      pagesAsync.push(this.processPage(pageNumber, pdf));
    }
    const pages = await Promise.all(pagesAsync);

    this.$log.debug(pages.length);

    this.pages.splice(0, this.pages.length, ...pages);

    if (signDocument.tokens?.length) {
      DocumentTemplateModule.initializeDocumentTokens(signDocument.tokens);
    } else {
      DocumentTemplateModule.initializeEmptyDocumentTokens(pages.length);
    }
  }

  async processPage(pageNumber: number, pdf: any) {
    const page = await pdf.getPage(pageNumber);
    const viewport = page.getViewport({ scale: 3 });
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext = { canvasContext: context, viewport: viewport };
    const renderTask = page.render(renderContext);

    await renderTask.promise;

    const widthInMM = (viewport.viewBox[2] / 72) * 25.4;
    const heightInMM = (viewport.viewBox[3] / 72) * 25.4;

    return {
      src: canvas.toDataURL("image/png"),
      scaleX: widthInMM / viewport.width,
      scaleY: heightInMM / viewport.height,
      pdfWidth: widthInMM,
      pdfHeight: heightInMM
    };
  }

  get DocumentDetailSideBarElements() {
    return DocumentDetailSideBarElements;
  }
}
