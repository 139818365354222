




import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class BookingBackButton extends Vue {
  onClick() {
    this.$emit("click");
  }
}
