export enum MenuStyleOptionEnum {
  // FontEffects
  Bold = "toggleBold",
  Italic = "toggleItalic",
  Strike = "toggleStrike",
  Heading = "toggleHeading",
  SetFontFamily = "setFontFamily",
  UnsetFontFamily = "unsetFontFamily",
  Underline = "toggleUnderline",

  // Positioning
  Subscript = "toggleSubscript",
  Superscript = "toggleSuperscript",
  TextAlign = "setTextAlign",

  // coloring
  SetColor = "setColor",
  UnsetColor = "unsetColor",
  ToggleHighlight = "toggleHighlight",
  UnsetHighlight = "unsetHighlight",

  // inserting
  HorizontalRule = "setHorizontalRule",
  SetImage = "setImage", //xxx installed todo ({ src: 'https://example.com/foobar.png' }),
  SetLink = "setLink", //xxx installed 'https://tiptap.dev/api/marks/link',

  // list
  BulletList = "toggleBulletList",
  OrderedList = "toggleOrderedList",
  TaskList = "toggleTaskList" // Sucks
}
