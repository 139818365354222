import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { PaginationFilterListElement } from "./utility/data/page-filter-list-element.interface";

export interface IFilterableClass {
  filterables: PaginationFilterListElement[];
}

type IIsFilterableConfiguration = Omit<PaginationFilterListElement, "key" | "type" | "id"> & {
  type: PageFilterTypes | IFilterableClass;
};

/**
 * Class decorator that adds a getter for searchable properties of the class
 *
 * @param constructor
 * @returns
 */
function IsFilterable<T extends { new (...args: any[]): {} }>(constructor: T) {
  return class extends constructor {
    /**
     * List of searchable properties of the class
     */
    static get filterables(): PaginationFilterListElement[] {
      return (constructor.prototype.filterables ?? []).map(
        (f: PaginationFilterListElement) => new PaginationFilterListElement(f)
      );
    }

    constructor(...args: any[]) {
      super(...args);

      // filterables getter is not enumerable
      Object.defineProperty(constructor, "filterables", {
        enumerable: false
      });
    }
  };
}

/**
 * Property decorator that adds a property to the prototype that contains the filterables
 * method-decorator @see https://www.typescriptlang.org/docs/handbook/decorators.html#method-decorators
 *
 * @param config
 * @returns
 */
function FilterConfig(config: IIsFilterableConfiguration) {
  return function(target: any, propertyKey: string) {
    if (!target.filterables) {
      target.filterables = [];
    }

    if (Object.values(PageFilterTypes).includes(config.type as PageFilterTypes)) {
      // add the searchable to the filterables array of the object
      target.filterables.push({ ...config, key: propertyKey });
    } else {
      // in case we have a nesting add the nested filterables to the filterables array of the parent
      (config.type as IFilterableClass).filterables.forEach(nestedSearchableConfig => {
        const nestedKey = `${propertyKey}.${nestedSearchableConfig.key}`;
        target.filterables.push({ ...nestedSearchableConfig, key: nestedKey });
      });
    }
  };
}

/**
 * Apply type of IsFilterable class
 *
 * @see IsFilterable
 */
class Filter {
  private static assertIsFilterableClass<T extends Function>(c: T): asserts c is T & IFilterableClass {
    if (!((c as any) as IFilterableClass).filterables) {
      ((c as any) as IFilterableClass).filterables = [];
    }
  }

  /**
   * Apply type of IsFilterable class
   *
   * @see IsFilterable
   */
  static createForClass<Class extends Function>(c: Class): Class & IFilterableClass {
    this.assertIsFilterableClass(c);
    return c;
  }
}

export { PageFilterTypes as FilterTypes, PaginationFilterListElement, Filter, IsFilterable, FilterConfig };
