


































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Card from "./Card.vue";

@Component({
  components: { Card }
})
export default class SideCard extends Vue {
  @Prop({ default: false })
  fullscreen!: boolean;

  /**
   * Applies absolute position to side card
   */
  @Prop({ default: false })
  absolute!: boolean;

  @Prop({ default: false })
  showGradient?: boolean;

  @Prop({ default: "100%" })
  height!: string;

  @Prop({ default: "400px" })
  width!: string;

  @Prop()
  title!: string;

  @Prop({ default: false })
  hideTitle!: string;

  @Prop()
  subtitle?: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  hideButton!: boolean;

  get style() {
    if (this.fullscreen) {
      return `position: fixed; top:0; right:0; bottom:0; width: ${
        this.$vuetify.breakpoint.smAndDown ? "100%" : this.width
      }; height: calc(${this.height}+64px); z-index:6; `;
    } else if (this.absolute) {
      return `
        height: 100%;
        position: fixed;
        margin-top: 64px;
        top: 0;
        right: 0;
        box-shadow: -4px 4px 8px -2px rgba(0,0,0,0.2);
        -webkit-box-shadow: -4px 4px 8px -2px rgba(0,0,0,0.2);
        -moz-box-shadow: -4px 4px 8px -2px rgba(0,0,0,0.2);
        width: ${this.$vuetify.breakpoint.smAndDown ? "100%" : this.width};
        height: ${this.height};
        z-index:6;
      `;
    } else {
      return `
        position: absolute;
        right:0;
        bottom:0;
        box-shadow: -4px 4px 8px -2px rgba(0,0,0,0.2);
        -webkit-box-shadow: -4px 4px 8px -2px rgba(0,0,0,0.2);
        -moz-box-shadow: -4px 4px 8px -2px rgba(0,0,0,0.2);
        width: ${this.$vuetify.breakpoint.smAndDown ? "100%" : this.width};
        height: ${this.height};
        z-index:6;
      `;
    }
  }
}
