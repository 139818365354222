











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Tooltip extends Vue {
  @Prop()
  text!: string;

  @Prop({ default: false })
  disabled!: boolean;
}
