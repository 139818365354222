































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import ProfileConfirmationRow from "./ProfileConfirmationRow.vue";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import Tooltip from "@/components/utility/tooltip.vue";

@Component({
  components: { ProfileConfirmationRow, Tooltip }
})
export default class ProfileNotificationForm extends DarkModeHighlightMixin {
  @Prop()
  isSystemEmail!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop({ default: false })
  isLoading!: boolean;

  @Prop()
  isMarketingOptIn!: boolean;

  @Prop()
  partnerNotifications!: boolean;

  @Prop({ default: false })
  partnerNotificationsEnabled!: boolean;

  @Prop({ default: false })
  isAssigneeEmail!: boolean;

  valid = false;

  get partnerCompanyName() {
    return PartnerModule.partner?.companyName || ReportModule.partner?.companyName;
  }

  get marketingOptIn() {
    return this.isMarketingOptIn;
  }

  set marketingOptIn(isMarketingOptIn: boolean) {
    this.$emit("update:isMarketingOptIn", isMarketingOptIn);
  }

  get assigneeEmail() {
    return this.isAssigneeEmail;
  }

  set assigneeEmail(isAssigneeEmail: boolean) {
    this.$emit("update:isAssigneeEmail", isAssigneeEmail);
  }

  get partnerNotificationsLocal() {
    return this.partnerNotifications;
  }

  set partnerNotificationsLocal(value: boolean) {
    this.$emit("update:partnerNotifications", value);
  }

  get isDisabled() {
    return this.disabled;
  }

  set isDisabled(disabled: boolean) {
    this.$emit("update:disabled", disabled);
  }

  get isReportPortalContext() {
    return ConfigModule.appContext === AppContextEnum.REPORT_PORTAL;
  }

  save() {
    this.$emit("save");
  }

  abort() {
    this.$emit("update:disabled", true);
  }
}
