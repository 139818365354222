/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { User as MrfiktivUser } from "@/services/mrfiktiv/v1/User";
import { User as ThgUser } from "@/services/thg/v1/User";
import { ThgUserControllerFindallParamsGen, ThgUserViewmodelGen } from "@/services/thg/v1/data-contracts";
import { AuthRolesEnum } from "@/store/enum/authRolesEnum";
import { ConfigModule } from "@/store/modules/config";
import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCreateUserDtoGen,
  MrfiktivPermissionDtoGen,
  MrfiktivUpdateUserDtoGen,
  MrfiktivUserViewmodelGen
} from "../mrfiktiv/v1/data-contracts";
import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";

class AdminUserService {
  _thgUser: ThgUser | undefined;
  _mrfiktivUser: MrfiktivUser | undefined;

  private get thgUser() {
    // Use this pattern instead of constructor injection to avoid failing spec tests. Workaround.
    if (!this._thgUser) {
      this._thgUser = new ThgUser(new ThgHttpClientProvider().client());
    }

    return this._thgUser;
  }

  private get mrfiktivUser() {
    // Use this pattern instead of constructor injection to avoid failing spec tests. Workaround.
    if (!this._mrfiktivUser) {
      this._mrfiktivUser = new MrfiktivUser(new MrfiktivHttpClientProvider().client());
    }

    return this._mrfiktivUser;
  }

  private get user() {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return this.thgUser;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return this.mrfiktivUser;
    }

    throw new Error("not implemented");
  }

  async get(id: string): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    return (await this.user.userControllerFindOne(id)).data;
  }

  async resendInvitation(id: string): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    return (await this.user.userControllerResendInvitation(id)).data;
  }

  async getAll(query: ThgUserControllerFindallParamsGen): Promise<ThgUserViewmodelGen[] | MrfiktivUserViewmodelGen[]> {
    return (await this.user.userControllerFindall(query)).data;
  }

  async getByAuthInfo(): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    return (await this.user.userControllerFind()).data;
  }

  async update(id: string, dto: MrfiktivUpdateUserDtoGen): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    return (await this.user.userControllerUpdate(id, dto)).data;
  }

  async removePermission(
    id: string,
    permission: MrfiktivPermissionDtoGen[]
  ): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    return (await this.user.userControllerRemovePermission(id, { permission })).data;
  }

  async addPermissions(
    userId: string,
    permission: MrfiktivPermissionDtoGen[]
  ): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    return (await this.user.userControllerAddPermission(userId, { permission })).data;
  }

  async addPermissionsByUser(user: MrfiktivUserViewmodelGen): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    const dto = {
      permission: user.permission ?? []
    };

    return (await this.user.userControllerAddPermission(user.id, dto)).data;
  }

  async updateRole(userId: string, roles: AuthRolesEnum[]): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    return (await this.user.userControllerUpdateRole(userId, { roles })).data;
  }

  async invite(createUser: MrfiktivCreateUserDtoGen): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    return (await this.user.userControllerInvite(createUser)).data;
  }

  async delete(id: string): Promise<ThgUserViewmodelGen | MrfiktivUserViewmodelGen> {
    return (await this.user.userControllerDelete(id)).data;
  }
}

export default new AdminUserService();
