import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IReport, Report } from "@/models/report.entity";

export const ReportDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<
  IReport,
  "assignees" | "vehicleId"
> {
  protected mapEntity(entity: IReport): IReport {
    return new Report(entity);
  }

  getIdentifier(entity: IReport): string {
    return entity.id;
  }

  mapsConfig = {
    assignees: {
      getIdentifier: (entity: IReport) => entity.assignees
    },
    vehicleId: {
      getIdentifier: (entity: IReport) => (entity.vehicleId ? [entity.vehicleId] : [])
    }
  };
})();
