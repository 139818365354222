





















import { Component, Mixins } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";
import { LoginModule } from "@/store/modules/login.store";
import { MrfiktivChangePasswordIfRequiredDtoGen as ChangePasswordIfRequiredDto } from "@/services/mrfiktiv/v1/data-contracts";
import LoginMixin from "@/mixins/LoginMixin.vue";
import LoginNewPasswordMixin from "@/mixins/LoginNewPasswordMixin.vue";
import LoginComponentPasswordRequirementList from "@/components/login/LoginComponentPasswordRequirementList.vue";
import LoginComponentInputMail from "@/components/login/LoginComponentInputMail.vue";
import LoginComponentInputPassword from "@/components/login/LoginComponentInputPassword.vue";
import LoginComponentMessage from "@/components/login/LoginComponentMessage.vue";
import LoginComponentNavigationButton from "@/components/login/LoginComponentNavigationButton.vue";
import LoginComponentSubmitButton from "@/components/login/LoginComponentSubmitButton.vue";
import LoginComponentLayout from "@/components/login/LoginComponentLayout.vue";
import LoginErrorMixin from "@/mixins/LoginErrorMixin.vue";
import { IFailureCodeContent } from "@/mixins/LoginErrorMixin.vue";

@Component({
  components: {
    LoginComponentPasswordRequirementList,
    LoginComponentInputMail,
    LoginComponentInputPassword,
    LoginComponentMessage,
    LoginComponentNavigationButton,
    LoginComponentSubmitButton,
    LoginComponentLayout
  }
})
export default class LoginCardSet extends Mixins(LoginMixin, LoginNewPasswordMixin, LoginErrorMixin) {
  beforeMount() {
    LoginModule.setPasswordNew("");
    LoginModule.setPasswordRepeat("");
  }

  customFailure = new Map<string, IFailureCodeContent>([
    [
      "Passwordchange is not forced.",
      {
        message: "components.login.LoginCardSet.failNotRequired",
        onFail: () => {
          this.setFormInvalid();
        }
      }
    ]
  ]);

  mounted() {
    this.registerCustomFailureCodes(this.customFailure);
  }

  /*
   * Set password on first login
   */
  async submit() {
    const changePasswordIfRequired: ChangePasswordIfRequiredDto = {
      previousPassword: this.password,
      proposedPassword: this.passwordRepeat,
      username: this.mail
    };
    try {
      this.isLoading = true;
      await UserModule.changePasswordIfRequired(changePasswordIfRequired);
      this.checkIfUserIsLoggedInAndRedirect();
    } catch (error) {
      this.handleFail(error);
    } finally {
      this.isLoading = false;
    }
  }
}
