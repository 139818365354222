

















































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import {
  displayMsAsMinutesAndHours,
  formatHoursAndMinutes,
  simpleDate,
  simpleDoubleDigitDate
} from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { UserGoToHelper } from "@/lib/utility/user-go-to-helper";
import { BookingBookingDetailViewModelGen } from "@/services/booking/v1/data-contracts";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import SideCard from "@/components/utility/SideCard.vue";
import BookingAttachmentList from "@/components/booking/shared/BookingAttachmentList.vue";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    ConfirmActionDialog,
    SideCard,
    BookingAttachmentList
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class MyBookingDetailSideCard extends Vue {
  @Prop()
  booking!: BookingBookingDetailViewModelGen;

  @Prop()
  loading!: boolean;

  isCancelDialogActive = false;
  isCancelDialogLoading = false;

  get i18n() {
    return this.$t("report.user.MyBookingDetail");
  }

  get items() {
    return [
      {
        text: this.i18n["date"],
        value: this.startDateString || ""
      },
      {
        text: this.i18n["startTime"],
        value: this.startTimeString || ""
      },
      {
        text: this.i18n["endTime"],
        value: this.endTimeString || ""
      },
      {
        text: this.i18n["duration"],
        value: this.duration || ""
      },
      {
        text: this.i18n["service"],
        value: this.service?.name || ""
      },
      {
        text: this.i18n["serviceActive"],
        value: this.service?.isActive ? this.$t("yes") : this.$t("no")
      },
      {
        text: this.i18n["address"],
        value: `${this.service?.address?.street}, ${this.service?.address?.zip} ${this.service?.address?.city}` || ""
      },
      {
        text: this.i18n["partner"],
        value: this.partner?.companyName || ""
      },
      {
        text: this.i18n["partnerEmail"],
        value: this.partner?.contact?.email || ""
      },
      {
        text: this.i18n["partnerPhone"],
        value: this.partner?.contact?.phone || ""
      }
    ];
  }

  get width() {
    return this.$vuetify.breakpoint.mobile ? "" : 500;
  }

  get creationDate() {
    return simpleDate(this.booking?.timestamp.created) || "";
  }

  get startDate() {
    return new Date(this.booking?.start);
  }

  get startDateString() {
    return simpleDate(this.startDate.toString());
  }

  get startTimeString() {
    return formatHoursAndMinutes(this.startDate);
  }

  get duration() {
    return displayMsAsMinutesAndHours(this.booking.end - this.booking.start);
  }

  get endDate() {
    return new Date(this.booking?.end);
  }

  get endDateString() {
    return simpleDate(this.endDate.toString());
  }

  get endTimeString() {
    return formatHoursAndMinutes(this.endDate);
  }

  onCancel() {
    this.isCancelDialogActive = true;
  }

  get service() {
    if (!this.booking.serviceId) {
      return undefined;
    }

    return this.booking.service;
  }

  get partner() {
    if (!this.booking.partnerId) {
      return undefined;
    }

    if (this.booking.partnerId !== ReportModule.partner.id) {
      return undefined;
    }

    return ReportModule.partner;
  }

  async cancel() {
    try {
      await AvailabilityModule.deleteOneByPartner({
        partnerId: this.booking.partnerId,
        id: this.booking.id,
        data: {
          isDeleted: true,
          deletedAt: new Date().toISOString()
        }
      });

      this.$toast.success("👍");
      this.$emit("close");
      this.$emit("reload");
    } catch (error) {
      handleError(error);
    }
  }

  async update() {
    await new UserGoToHelper(this.$router).goToBookingCustomerUpdate(this.booking.id);
  }
}
