import bookingMeService from "@/services/booking/services/bookingMeService";
import {
  BookingBookingDetailViewModelGen,
  BookingBookingUnauthenticatedViewModelGen
} from "@/services/booking/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "bookingMeStore",
  store
})
export class BookingMeStore extends VuexModule {
  _map: Map<string, BookingBookingUnauthenticatedViewModelGen> = new Map();

  get all(): BookingBookingUnauthenticatedViewModelGen[] {
    return Array.from(this._map.values());
  }

  get map() {
    return this._map;
  }

  @Mutation
  private _mutateAll(request: BookingBookingUnauthenticatedViewModelGen[]) {
    this._map = new Map();
    request.map(r => this._map.set(r.id, r));
  }

  @Mutation
  private _mutateOne(request: BookingBookingDetailViewModelGen) {
    this._map.set(request.id, request as BookingBookingUnauthenticatedViewModelGen);
  }

  @Action
  async getAll(): Promise<BookingBookingUnauthenticatedViewModelGen[]> {
    const reports = await bookingMeService.findAll(false);

    this.context.commit("_mutateAll", reports.data);

    return reports.data;
  }

  @Action
  async getOne(data: { id: string }): Promise<BookingBookingDetailViewModelGen> {
    const booking = await bookingMeService.findOne(data.id);

    this.context.commit("_mutateOne", booking.data);

    return booking.data;
  }
}

export const BookingMeModule = getModule(BookingMeStore);
