var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticStyle: {
      "border-width": "1px",
      "z-index": "1",
      "border-style": "solid",
      "border-radius": "5px",
      "margin-left": "4px",
      "margin-right": "4px",
      "padding-bottom": "2px"
    },
    style: _vm.isDarkMode ? 'border-color: rgb(98,98,98)' : 'border-color: rgb(198,198,198)'
  });
}
var staticRenderFns = []

export { render, staticRenderFns }