import { ThgActivePriceViewModelGen } from "@/services/thg/v1/data-contracts";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import store from "../VuexPlugin";
import { ActivePrice } from "../models/thg/active-price";
import { PromotionTypeEnum } from "@/lib/enum/promotion-type.enum";
import { ActivePromotion } from "../models/thg/active-promotion";
import partnerActiveOfferService from "@/services/thg/services/partnerActiveOfferService";

@Module({
  dynamic: true,
  namespaced: true,
  name: "active-config",
  store
})
export class PartnerActiveOfferStore extends VuexModule {
  /**
   * set if the prices is loading
   */
  private _loading = false;

  /**
   * All active prices
   */
  private _prices: ThgActivePriceViewModelGen[] = [];

  /**
   * All active promotions
   */
  private _promotions: ActivePromotion[] = [];

  /**
   * Current years price
   */
  private _currentPrice?: ThgActivePriceViewModelGen = undefined;

  /**
   * Current years promotion
   */
  private _currentSignUpPromotion?: ActivePromotion = undefined;

  /**
   * Prices by year
   */
  private _priceLookup: Map<number, ThgActivePriceViewModelGen> = new Map();

  /**
   * Promos by type
   */
  private _promotionLookup: Map<PromotionTypeEnum, ActivePromotion> = new Map();

  /**
   * Returns the current price of the year
   */
  get currentPrice(): ThgActivePriceViewModelGen | undefined {
    return this._currentPrice;
  }

  get currentSignUpPromotion(): ActivePromotion | undefined {
    if (!this._currentSignUpPromotion) return undefined;

    return this._currentSignUpPromotion;
  }

  /**
   * All available prices
   */
  get prices(): ThgActivePriceViewModelGen[] {
    return this._prices;
  }

  /**
   * All available promotions
   */
  get promotions(): ActivePromotion[] {
    return this._promotions;
  }

  /**
   * Returns the price by year
   */
  get priceLookup(): Map<number, ThgActivePriceViewModelGen> | undefined {
    return this._priceLookup;
  }

  /**
   * Returns the price by year
   */
  get promotionLookup(): Map<PromotionTypeEnum, ActivePromotion> | undefined {
    return this._promotionLookup;
  }

  /**
   * All available prices
   */
  get priceConfig(): ActivePrice {
    return new ActivePrice(this._prices);
  }

  @Mutation
  private _mutatePrices(prices: ThgActivePriceViewModelGen[]) {
    this._prices = prices;

    for (const price of prices) {
      this._priceLookup.set(price.year, price);
    }

    this._currentPrice = this._priceLookup.get(new Date().getFullYear());
  }

  @Mutation
  private _mutatePromotions(promos: ActivePromotion[]) {
    this._promotions = promos;

    for (const promo of promos) {
      this._promotionLookup.set(promo.type, promo);
    }

    this._currentSignUpPromotion = this._promotionLookup.get(PromotionTypeEnum.FIRST_PURCHASE);
  }

  @Action
  public async loadDocuments(partnerId: string) {
    this.context.commit("mutateLoading", true);

    const config = await partnerActiveOfferService.findActive(partnerId);
    const prices = config.prices;
    const promos = config.promotions.map(promo => new ActivePromotion(promo));

    this.context.commit("_mutatePrices", prices);
    this.context.commit("_mutatePromotions", promos);
    this.context.commit("mutateLoading", false);
  }

  get loading() {
    return this._loading;
  }

  @Mutation
  mutateLoading(loading: boolean) {
    this._loading = loading;
  }

  @Action
  setLoading(loading: boolean) {
    this.context.commit("mutateLoading", loading);
  }
}

export const PartnerActiveOfferModule = getModule(PartnerActiveOfferStore);
