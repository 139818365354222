import { BookingAvailabilityGen } from "@/services/booking/v1/data-contracts";
import { getAvailableDaysAsWeekDayString, stringifyWeekDays } from "./availabilityDisplayHelper";

/**
 * Parses a given date to a simple string representation: dd.mm.yyyy.
 * @param date - to be parsed to a string
 * @returns date string dd.mm.yyy
 */
export function simpleDate(date: string, locale = "de-de"): string {
  return new Date(date).toLocaleDateString(locale, {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  });
}

/**
 * Returns DD.MM
 * @param date the string to parse to date
 * @returns
 */
export function dayMonthDate(date: string): string {
  const d = new Date(date);
  return `${d
    .getDate()
    .toString()
    .padStart(2, "0")}.${(d.getMonth() + 1).toString().padStart(2, "0")}`;
}

/**
 * Returns a simple date where 1..9 becomes 01...09
 * @param date - to be parsed to a string
 * @returns date string dd.mm.yyy
 */
export function simpleDoubleDigitDate(date: string, locale = "de-de"): string {
  const simple = simpleDate(date, locale);

  return doubleDigit(simple).join(".");
}

function doubleDigit(simple: string) {
  const simplyDoubleDigited: string[] = [];
  simple.split(".").forEach(s => {
    if (Number(s) < 10) {
      simplyDoubleDigited.push(`0${s}`);
    } else {
      simplyDoubleDigited.push(s);
    }
  });
  return simplyDoubleDigited;
}

/**
 * Parses a given date to a simple string representation: dd.M yyyy, hh:mm.
 * @param date - to be parsed to a string
 * @returns date string dd.M yyyy, hh:mm.
 */
export function detailedDate(date: string | number, locale = "de-de") {
  return new Date(date).toLocaleDateString(locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
}

/**
 * Parses a given date to a simple string representation: dd.M yyyy, hh:mm.
 * @param date - to be parsed to a string
 * @returns date string dd.M yyyy, hh:mm.
 */
export function ddmmyyyhhmm(date: string | number, locale = "de-de") {
  return new Date(date).toLocaleDateString(locale, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
}

/**
 * Parses a given date to a simple string representation: dd.M yyyy
 * @param date - to be parsed to a string
 * @returns date string d, dd.M yyyy.
 */
export function detailedDateWithDay(date: string, locale = "de-de") {
  return new Date(date).toLocaleDateString(locale, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  });
}

/**
 * Parses a given date to the ISO date used in the date picker.
 * @param date - to be parsed to a string.
 * @returns date string om ISO format.
 */
export function toISOString(date: string) {
  return new Date(date).toISOString().substr(0, 10);
}

/**
 * Helper to transform the date when it is given as "yyyy-mm-dd" into "dd.mm.yyyy"
 * @param date date given as yyyy-mm-dd
 * @returns date in "dd.mm.yyyy"
 */
export function convertDashedToDotted(date: string) {
  return date.slice(8, 10) + "." + date.slice(5, 7) + "." + date.slice(0, 4);
}

/**
 * Calculate the difference between today and the given month and years in months
 *
 * @param month (1 is january)
 * @param year
 */
export function calculateMonthDifference(month: number, year: number) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const yearDifference = year - currentYear;
  const monthDifference = month - currentMonth;

  return yearDifference * 12 + monthDifference - 1;
}

/**
 * Calculate the difference between today and the given month and years in days
 *
 * @param date
 */
export function calculateDayDifference(date: Date, currentDate = new Date()) {
  const secondDifference = date.getTime() - currentDate.getTime();
  const dayDiffernence = secondDifference / (1000 * 3600 * 24);

  return Math.floor(dayDiffernence + 1);
}

export function getDays(date: Date) {
  return date.getTime() / (1000 * 3600 * 24);
}

/**
 * Returns HH:MM
 * @param date the date containing the time
 */
export function formatHoursAndMinutes(date: Date) {
  return doubleDigit(`${date.getHours()}.${date.getMinutes()}`).join(":");
}

/**
 * Returns HH:MM
 * @param date the date containing the time
 */
export function formatUTCHoursAndMinutes(date: Date) {
  return doubleDigit(`${date.getUTCHours()}.${date.getUTCMinutes()}`).join(":");
}

/**
 * Returns YYYY-MM-DD
 * @param date the date containing the date string
 * @returns YYYY-MM-DD
 */
export function formatYearsMonthDay(date: Date) {
  return `${date
    .getFullYear()
    .toString()
    .padStart(4, "0")}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
    .getDate()
    .toString()
    .padStart(2, "0")}`;
}

export function displayMsAsMinutesAndHours(ms: number) {
  return displaySecondsAsMinutesAndHours(ms / 1000);
}

export function displaySecondsAsMinutesAndHours(seconds: number) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  const hoursToDisplay = h > 0 ? `${h}h` : "";
  const minutesToDisplay = m > 0 ? `${m}m` : "";

  return hoursToDisplay + minutesToDisplay;
}

/**
 * Returns HH:MM:SS
 * @param seconds time we want to display in ms
 * @returns
 */
export function msToTime(ms: number): string {
  const seconds = ms / 1000;
  const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

  return h + ":" + m + ":" + s;
}

/**
 * Like nsToTime but without the seconds.
 * .slice(0,5) will return only hh:mm of the string.
 */
export function msToHoursAndMinutes(ms: number) {
  return msToTime(ms).slice(0, 5);
}

/**
 * Returns ms as a number
 * @param timeString hour and minute time expressed as hh:mm
 */
export function timeToMs(timeString: string): number {
  const [hours, minutes] = timeString.split(":");
  return (+hours * 60 * 60 + +minutes * 60) * 1000;
}

/**
 * Format a timestamp as YYYY-MM-DD HH:MM
 * @param msSinceEpochTime Unix epoch time. The timestamp is multiplied by 1000 internally.
 * @example 1682447335
 * @returns
 */
export function formatAsFullDateAndFullHour(msSinceEpochTime: number): string {
  const fullDate = new Date(msSinceEpochTime);
  const justDate = formatYearsMonthDay(fullDate);

  // Get just the hour and the minutes(calendar requirements)
  const justTime = fullDate.toLocaleTimeString("de-DE", { hour12: false }).slice(0, -3);

  return justDate + " " + justTime;
}

/**
 * Function to display the available days in the availability array as short week days separated by a character. Default separator is " | ".
 * Example: "mon | tue | wed"
 * Used for OnlineBooking
 */
export function displayAvailableWeekDaysAsShortString(
  availability: BookingAvailabilityGen[],
  separator = " | "
): string {
  const weekDays = getAvailableDaysAsWeekDayString(availability);
  // Get not "monday" but "Mo"
  const shortenWeekDays = weekDays.map(weekDay => weekDay.charAt(0).toLocaleUpperCase() + weekDay[1]);

  return stringifyWeekDays(shortenWeekDays, separator);
}

/**
 *
 */
export class DateVuetifyHelper {
  /**
   * date that shoul be manupulated
   */
  originalDate!: Date;

  constructor(date: string) {
    this.originalDate = new Date(date);
  }

  /**
   * time in HH:MM
   */
  get time() {
    return formatHoursAndMinutes(this.originalDate);
  }

  /**
   * time in HH:MM
   */
  set time(time: string) {
    let newDate = new Date(this.originalDate);
    newDate = new Date(new Date(newDate).setHours(+time.substring(0, 2)));
    newDate = new Date(new Date(newDate).setMinutes(+time.substring(3, 5)));

    this.originalDate = new Date(newDate);
  }

  /**
   * date in format YYYY-MM-DD
   */
  get date(): string {
    return this.originalDate.toISOString().substring(0, 10);
  }

  /**
   * date in format YYYY-MM-DD
   */
  set date(date: string) {
    const copy = new Date(date);

    const newDate = new Date(date);
    newDate.setDate(copy.getDate());
    newDate.setMonth(copy.getMonth());
    newDate.setFullYear(copy.getFullYear());
    newDate.setMinutes(copy.getMinutes());
    newDate.setHours(copy.getHours());
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    this.originalDate = new Date(newDate);
  }

  /**
   * date in format YYYY-MM-DD
   */
  get localeDate(): string {
    return simpleDoubleDigitDate(this.originalDate.toISOString());
  }

  /**
   * isodatestring
   */
  get isoDate(): string {
    return this.originalDate.toISOString();
  }

  /**
   * isodatestring
   */
  set isoDate(isoDate: string) {
    this.originalDate = new Date(isoDate);
  }
}

export function getLastChanged(
  lastModified: string,
  translate: (v: string, p?: Record<string, string>) => string
): string {
  const millisecondDifference = new Date().getTime() - new Date(lastModified).getTime();
  const days = Math.floor(millisecondDifference / (1000 * 3600 * 24));

  if (days) {
    if (days === 1) {
      return translate("sign.DocumentDetail.yesterday");
    }

    return translate("sign.DocumentDetail.dayDifference", { days: days.toString() });
  }

  const hoursInDecimal = millisecondDifference / (1000 * 3600);
  const hours = Math.floor(hoursInDecimal);
  if (hours) {
    if (hours === 1) {
      return translate("sign.DocumentDetail.anHourAgo");
    }

    return translate("sign.DocumentDetail.hourDifference", { hours: hours.toString() });
  }

  const minutesInDecimal = hoursInDecimal - hours;
  const minutes = Math.floor(minutesInDecimal * 60);
  if (minutes) {
    return translate("sign.DocumentDetail.minuteDifference", { minutes: minutes.toString() });
  }

  return translate("sign.DocumentDetail.noDifference");
}

/**
 * Checks if string is iso date encoding
 * @param str
 * @returns
 */
export function isIsoDateString(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date
}
