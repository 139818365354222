import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IAdminUser, AdminUser } from "@/models/user.entity";

export const AdminUserDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IAdminUser> {
  getIdentifier(entity: IAdminUser): string {
    return entity._id;
  }

  mapEntity(entity: IAdminUser): IAdminUser {
    return new AdminUser(entity);
  }
})();
