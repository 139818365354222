















import BookingUpdate from "@/components/booking/customer/BookingUpdate.vue";
import LayoutReportWithBanner from "@/layouts/LayoutReportWithBanner.vue";
import { handleError } from "@/lib/utility/handleError";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { Component, Vue } from "vue-property-decorator";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { BookingSlotViewModelGen } from "@/services/booking/v1/data-contracts";
import { toISOString } from "@/lib/utility/date-helper";
import { UserModule } from "@/store/modules/me-user.store";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";

@Component({
  components: {
    LayoutReportWithBanner,
    BookingUpdate
  }
})
export default class BookingUpdateView extends Vue {
  bookingUpdated = false;

  get showLoginButton(): boolean {
    const isCustomerSignup = PartnerModule.partner?.settings?.bookedServices.customer ?? false;
    const isThg = ConfigModule.appContext === AppContextEnum.THG;
    return isThg ? !UserModule.isAuthenticated : !UserModule.isAuthenticated && isCustomerSignup;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get bookingId() {
    return this.$route.params["id"];
  }

  get partnerId() {
    return ReportModule.partner?._id || PartnerModule.partner?._id;
  }

  get service() {
    return AvailabilityModule.selectedService;
  }

  get createdBooking() {
    return AvailabilityModule.confirmedBooking;
  }

  get isBookingUpdated() {
    return this.bookingUpdated;
  }

  async mounted() {
    try {
      const booking = await AvailabilityModule.findOneBookingByPartner({
        partnerId: this.partnerId,
        id: this.bookingId
      });

      // Initialize store with booking data
      AvailabilityModule.setServiceId(booking.serviceId);
      AvailabilityModule.setDate(toISOString(new Date(booking.start).toString()));
      AvailabilityModule.setPickerDate(toISOString(new Date(booking.start).toString()));

      await AvailabilityModule.findOneByPartner({
        partnerId: this.partnerId,
        id: AvailabilityModule.serviceId,
        date: new Date(booking.start).toISOString()
      });
    } catch (error) {
      handleError(error);
    }
  }

  async update(data: BookingSlotViewModelGen) {
    this.bookingUpdated = false;

    try {
      await AvailabilityModule.updateOneByPartner({
        partnerId: this.partnerId,
        id: this.bookingId,
        data: {
          start: data.start,
          end: data.end
        }
      });
      this.bookingUpdated = true;
      this.$toast.success("👍");
    } catch (error) {
      this.bookingUpdated = false;
      handleError(error);
    }
  }
}
