

















import { Component } from "vue-property-decorator";
import CustomFieldText from "./CustomFieldText.vue";

@Component({
  components: {}
})
export default class CustomFieldNumber extends CustomFieldText {}
