import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { PaginationFilterListElement } from "@/lib/filterable";
import { CancelToken } from "@/lib/utility/cancelToken";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import { MrfiktivPageMetaViewModelGen, MrfiktivPageViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgPageMetaViewModelGen, ThgPageViewModelGen } from "@/services/thg/v1/data-contracts";
import { Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface IPaginationParams {
  currentPage?: number;
  itemsPerPage?: number;
  order?: PageOrderEnum | "descending" | "ascending";
  startId?: string;
}

/**
 * Possible operations to compare values when filtering
 */
export enum PaginationFilterOperationEnum {
  EQUAL = "$eq",
  NOT_EQUAL = "$ne",
  LESS = "$lt",
  GREATER = "$gt",
  LESS_EQUAL = "$lte",
  GREATER_EQUAL = "$gte"
}

/**
 * Filter configuration
 */
export { PaginationFilterListElement };

/**
 * Base class that can be extended to create stores to paginate data.
 *
 * @template DocumentType The type of the document that is listed in the Pagination
 * @template PaginationParamsType The parameters that are needed to make a pagination request
 *
 * @deprecated use BasePagination
 */
export abstract class BasePagination<DocumentType, PaginationParamsType extends IPaginationParams> extends VuexModule {
  /**
   * @deprecated this store is deprecated use `BasePagination` instead
   */
  get isLegacy() {
    return true;
  }

  protected abstract _paginationList: DocumentType[];
  protected abstract _currentPage: number;
  protected abstract _itemsPerPage: number;
  protected abstract _totalItems: number;
  protected abstract _totalPages: number;
  protected abstract _pageOrder: PageOrderEnum;
  protected abstract _isLoadAll: boolean;
  protected abstract filterOptions: PaginationFilterListElement[];
  protected _filter: IPageFilterElement[] = [];
  hiddenFilter: IPageFilterElement[] = [];
  protected _search: string | undefined = undefined;
  cancelToken: CancelToken | undefined;

  /**
   * Template method to "establish the backend connection".
   * This method should implement only the backend call with the given query and return the data that the backend gives us.
   * The method is integrated into the pagination logic, so it will take care of filling out the pagination list and the maps. This should not be done manually.
   * @param query
   */
  @Action
  protected async loadDocuments(
    query: PaginationParamsType
  ): Promise<
    (ThgPageViewModelGen | MrfiktivPageViewModelGen) & {
      data?: DocumentType[] | undefined;
    }
  > {
    throw new Error(`loadDocuments not implemented by parent class. Cannot process query ${query}`);
  }

  /**
   * Hook to configure for document maps
   * key must be a property of the document
   * map is a reference to a map for the document.
   * By configuring a key and a map, the document will be added/removed to the map when being added/removed to/from the pagination list
   */
  protected _documentMapConfig: Record<string, Map<string | number, DocumentType>> = {};

  get maps() {
    return this._documentMapConfig;
  }

  /**
   * Create document maps
   * @param documents
   */
  @Mutation
  private setDocumentMaps(documents: DocumentType[]): void {
    if (!this._documentMapConfig) {
      return;
    }
    for (const document of documents) {
      for (const config of Object.entries(this._documentMapConfig)) {
        const key = document[config[0]];
        const map = config[1];

        if (key) {
          map.set(key, document);
        }
      }
    }
  }

  /**
   * Rremove specific document from maps
   */
  @Mutation
  private deleteFromDocumentMaps(document: DocumentType): void {
    if (!this._documentMapConfig) {
      return;
    }
    for (const config of Object.entries(this._documentMapConfig)) {
      const key = document[config[0]];
      const map = config[1];

      if (key) {
        map.delete(key);
      }
    }
  }

  /**
   * Reset document maps
   */
  @Mutation
  private clearDocumentMaps(): void {
    if (!this._documentMapConfig) {
      return;
    }
    for (const config of Object.entries(this._documentMapConfig)) {
      const key = document[config[0]];
      const map = config[1];

      if (key) {
        map.clear();
      }
    }
  }

  @Action
  protected async filterLoadDocuments(
    query: PaginationParamsType
  ): Promise<
    (ThgPageViewModelGen | MrfiktivPageViewModelGen) & {
      data?: DocumentType[] | undefined;
    }
  > {
    const filter = this.filter;
    let search: string | undefined;
    if (this.search) {
      search = this.search;
    }

    const loaded = (await this.context.dispatch("loadDocuments", {
      ...query,
      filter: [...filter, ...this.hiddenFilter].map(d => d.dtoPagination),
      search
    })) as ThgPageViewModelGen | MrfiktivPageViewModelGen;

    return loaded;
  }

  get search(): string | undefined {
    return this._search;
  }
  get filterList(): PaginationFilterListElement[] {
    return this.filterOptions;
  }

  get paginationList(): DocumentType[] {
    return this._paginationList;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  get itemsPerPage(): number {
    return this._itemsPerPage;
  }
  get totalItems(): number {
    return this._totalItems;
  }
  get totalPages(): number {
    return this._totalPages;
  }
  get pageOrder(): PageOrderEnum {
    return this._pageOrder;
  }
  get isLoadAll() {
    return this._isLoadAll;
  }
  get isComplete() {
    return this.paginationList.length >= this.totalItems;
  }
  get lastId() {
    if (this.paginationList.length === 0) {
      return undefined;
    }
    const lastDocument = this.paginationList[this.paginationList.length - 1] as any;
    const lastId = lastDocument._id || lastDocument.id;

    return lastId;
  }
  get lastIdIndex() {
    const lastId = this.lastId;
    if (!lastId) {
      return -1;
    }

    const lastIdIndex = this._paginationList.findIndex(el => {
      const id = (el as any)._id || (el as any).id;
      return id === lastId;
    });

    return lastIdIndex;
  }
  get filter() {
    return this._filter;
  }

  @Mutation
  private _mutateSearch(search: string | undefined) {
    this._search = search;
  }

  @Mutation
  private _mutatePaginationList(paginationList: DocumentType[]) {
    this._paginationList.splice(0, this._paginationList?.length, ...paginationList);
  }
  @Mutation
  private _mutateCurrentPage(currentPage: number) {
    this._currentPage = currentPage;
  }
  @Mutation
  private _mutateItemsPerPage(itemsPerPage: number) {
    this._itemsPerPage = itemsPerPage;
  }
  @Mutation
  private _mutateTotalItems(totalItems: number) {
    this._totalItems = totalItems;
  }
  @Mutation
  private _mutateTotalPages(totalPages: number) {
    this._totalPages = totalPages;
  }
  @Mutation
  private _mutatePageOrder(pageOrder: PageOrderEnum) {
    this._pageOrder = pageOrder;
  }
  @Mutation
  private _mutateIsLoadAll(isLoadAll: boolean) {
    this._isLoadAll = isLoadAll;
  }
  @Mutation _mutateCancelToken(cancelToken: CancelToken) {
    this.cancelToken = cancelToken;
  }

  @Mutation _mutateFilter(filter: IPageFilterElement[]) {
    this._filter = filter;
  }
  @Mutation _mutateHiddenFilter(filter: IPageFilterElement[]) {
    this.hiddenFilter = filter;
  }

  @Action
  prependToList(document: DocumentType) {
    const paginationList = [document, ...this.paginationList];
    this.context.commit("_mutatePaginationList", paginationList);
    this.context.commit("setDocumentMaps", paginationList);
  }
  @Action
  setSearch(search: string | undefined) {
    this.context.commit("_mutateSearch", search);
  }
  @Action
  setPaginationList(paginationList: DocumentType[]): void {
    this.context.commit("_mutatePaginationList", paginationList);
    this.context.commit("setDocumentMaps", paginationList);
  }
  @Action
  setCurrentPage(currentPage: number): void {
    this.context.commit("_mutateCurrentPage", currentPage);
  }
  @Action
  setItemsPerPage(itemsPerPage?: number) {
    this.context.commit("_mutateItemsPerPage", itemsPerPage);
  }
  @Action
  setTotalItems(totalItems: number): void {
    this.context.commit("_mutateTotalItems", totalItems);
  }
  @Action
  setTotalPages(totalPages: number): void {
    this.context.commit("_mutateTotalPages", totalPages);
  }
  @Action
  setPageOrder(pageOrder: PageOrderEnum) {
    this.context.commit("_mutatePageOrder", pageOrder);
  }
  @Action
  setIsLoadAll(isLoadAll: boolean) {
    this.context.commit("_mutateIsLoadAll", isLoadAll);
  }
  @Action
  setCancelToken(cancelToken: CancelToken) {
    this.context.commit("_mutateCancelToken", cancelToken);
  }
  @Action
  setFilter(filter: IPageFilterElement[]) {
    this.context.commit("_mutateFilter", filter);
  }
  @Action
  setHiddenFilter(filter: IPageFilterElement[]) {
    this.context.commit("_mutateHiddenFilter", filter);
  }

  @Action
  replaceInList(document: DocumentType) {
    const paginationList = this._paginationList;

    const documentId = (document as any).id ?? (document as any)._id;
    const index = this._paginationList.findIndex((el: any) => {
      const elementId = el.id || (el as any)._id;

      return elementId === documentId;
    });

    if (index >= 0) {
      paginationList.splice(index, 1, document);
      this.context.commit("_mutatePaginationList", paginationList);
      // replace in maps
      this.context.commit("setDocumentMaps", paginationList);
    }
  }

  @Action
  removeInList(document: DocumentType) {
    const paginationList = this._paginationList;

    const documentId = (document as any).id ?? (document as any)._id;
    const index = this._paginationList.findIndex((el: any) => {
      const elementId = el.id || (el as any)._id;

      return elementId === documentId;
    });

    // remove in maps
    this.context.commit("deleteFromDocumentMaps", document);

    if (index >= 0) {
      paginationList.splice(index, 1);
      this.context.commit("_mutatePaginationList", paginationList);
      this.context.commit("setDocumentMaps", paginationList);
      this.context.commit("_mutateTotalItems", this.totalItems - 1);
    }
  }

  @Action
  addToList(document: DocumentType) {
    this._paginationList.unshift(document);
    this.context.commit("setDocumentMaps", [document]);
  }

  /**
   * Returns the item that follows the passed item, or returns the first item, if this item is not found, or there is no following item
   */
  @Action
  getNextItemInList(document: DocumentType) {
    const documentId = (document as any).id ?? (document as any)._id;
    const index = this._paginationList.findIndex((el: any) => {
      const elementId = el.id || (el as any)._id;

      return elementId === documentId;
    });

    const next = this._paginationList[index + 1];
    if (next) {
      return next;
    }

    return this._paginationList[0];
  }

  @Action
  setToList(documents: DocumentType[]) {
    const paginationList = this.paginationList;
    if (!this.lastId) {
      this.context.dispatch("setPaginationList", documents);
    } else {
      paginationList.splice(this.lastIdIndex + 1, paginationList.length, ...(documents || []));
      this.context.dispatch("setPaginationList", paginationList);
    }
  }

  @Action
  setMeta(value: ThgPageMetaViewModelGen | MrfiktivPageMetaViewModelGen) {
    this.context.dispatch("setItemsPerPage", value.itemsPerPage);
    this.context.dispatch("setTotalItems", value.totalItems);
    this.context.dispatch("setTotalPages", value.totalPages);
    this.context.dispatch("setCurrentPage", value.currentPage);
  }

  /**
   * Loads the first page of items
   *
   * @param query
   * @returns
   */
  @Action
  async fetchFirst(query: PaginationParamsType): Promise<DocumentType[]> {
    // reset maps
    this.context.commit("clearDocumentMaps");

    const documents = await this.context.dispatch("filterLoadDocuments", {
      itemsPerPage: this.itemsPerPage,
      order: this.pageOrder,
      ...query,
      currentPage: 1,
      startId: undefined
    });

    // set meta information
    this.context.dispatch("setMeta", documents.meta);

    // set items
    this.context.dispatch("setPaginationList", documents.data);

    return documents.data;
  }

  /**
   * Sets the query page to 1, in order to start fetching from beginning.
   * Then runs fetch rest in the background to start fetching the documents from last the page id that was loaded.
   *
   * Fetch rest will call itself until all pages are loaded, or the cancelation token is set.
   *
   * @param query page dto to
   * @returns
   */
  @Action
  async fetchAllFromBeginning(query: PaginationParamsType) {
    // cancel active request
    this.cancelToken?.requestCancellation();

    // create new cancel token for next series of requests
    const cancelToken = new CancelToken();

    this.context.dispatch("setCurrentPage", 1);
    this.context.dispatch("setTotalPages", 0);
    this.context.dispatch("setTotalItems", 0);

    // replace active cancel token
    this.context.dispatch("setCancelToken", cancelToken);

    // request first page
    const docs: DocumentType[] = await this.context.dispatch("fetchFirst", query);

    // check if all are supposed to be loaded
    if (!this.isLoadAll) {
      return;
    }

    // request remaining pages
    if (!cancelToken.isCancellationRequested() && !(docs.length < this.itemsPerPage)) {
      this.context.dispatch("fetchRest", { query: query, cancelToken: cancelToken });
    }
  }

  @Action
  async fetchRest(data: { query: PaginationParamsType; cancelToken: CancelToken }) {
    // fetch next page
    let docs: DocumentType[] = await this.context.dispatch("fetchNext", data.query);

    // check if all are supposed to be loaded
    if (!this.isLoadAll) {
      return;
    }

    // get rest while there is something to get and request is not interrupted
    while (!data.cancelToken.isCancellationRequested() && this.itemsPerPage && !(docs.length < this.itemsPerPage)) {
      docs = await this.context.dispatch("fetchNext", data.query);
    }
  }

  @Action
  async fetchNext(query: PaginationParamsType): Promise<DocumentType[]> {
    const documents = await this.context.dispatch("filterLoadDocuments", {
      itemsPerPage: this.itemsPerPage,
      order: this.pageOrder,
      currentPage: this.currentPage,
      ...query,
      startId: this.lastId
    });

    // set meta information
    this.context.dispatch("setMeta", documents.meta);

    // set items
    this.context.dispatch("setToList", documents.data);

    return documents.data;
  }

  @Action
  emptyList() {
    this.context.dispatch("setPaginationList", []);
  }
}
