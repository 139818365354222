import { IUser } from "@/models/user.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { AuthRolesEnum } from "@/store/enum/authRolesEnum";
import { AbilityBuilder, Ability } from "@casl/ability";

/**
 * @param user contains details about logged in user: its id, name, email, etc
 */
export function defineAbilitiesFor(user?: IUser) {
  const { can, rules } = new AbilityBuilder(Ability);
  if (!user) {
    return rules;
  }

  if (user.roles.includes(AuthRolesEnum.ADMIN)) {
    can(ActionEnum.MANAGE, ResourceEnum.ALL);
    return rules;
  }

  if (user.roles.includes(AuthRolesEnum.DOCPHANT_ADMIN)) {
    can(ActionEnum.MANAGE, ResourceEnum.MANUFACTURER);
    can(ActionEnum.MANAGE, ResourceEnum.MACHINE);
    can(ActionEnum.MANAGE, ResourceEnum.MACHINE_ATTACHMENT);
  }

  if (user.roles.includes(AuthRolesEnum.THG_USER)) {
    can(ActionEnum.READ, ResourceEnum.THG);
  }

  if (user.roles.includes(AuthRolesEnum.USER)) {
    can(ActionEnum.READ, ResourceEnum.LOGGED_IN_USER);
  }

  if (user.roles.includes(AuthRolesEnum.ANALYTICS_ADMIN)) {
    can(ActionEnum.READ, ResourceEnum.ANALYTICS_ADMIN);
    can(ActionEnum.READ, ResourceEnum.DASHBOARD);
  }

  // if (user.roles.includes(AuthRolesEnum.THG_PROCESSING)) {
  //   can(ActionEnum.MANAGE, ResourceEnum.THG_WORKITEMS);
  //   can(ActionEnum.MANAGE, ResourceEnum.THG);
  //   can(ActionEnum.READ, ResourceEnum.DASHBOARD);
  // }

  if (user.roles.includes(AuthRolesEnum.PARTNER)) {
    can(ActionEnum.READ, ResourceEnum.DASHBOARD);
  }

  user.permission?.forEach(perm => {
    perm.action?.forEach((action: string | string[]) => can(action, perm.type, perm.id));
  });
  return rules;
}
