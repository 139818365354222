var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('components.template.TemplateDialogPasteUrl.title'),
      "isDialogActive": _vm.dialog,
      "supressKeyboardCancel": true,
      "rightDisabled": !_vm.valid,
      "rightLabel": _vm.$t('components.template.TemplateDialogPasteUrl.btn')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.dialog = false;
      },
      "rightClick": _vm.emitUrl
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.template.TemplateDialogPasteUrl.label'),
      "outlined": ""
    },
    model: {
      value: _vm.src,
      callback: function callback($$v) {
        _vm.src = $$v;
      },
      expression: "src"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }