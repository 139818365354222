







































import { convertPdfToImg } from "@/lib/utility/convertPdfToImage";
import { detailedDateWithDay, formatHoursAndMinutes, simpleDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { ISignDocument } from "@/models/sign-document.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import RefsVehicle from "./RefsVehicle.vue";

@Component({
  components: { RefsVehicle },
  filters: {
    simpleDate,
    detailedDateWithDay,
    formatHoursAndMinutes
  }
})
export default class RefsDocument extends Vue implements IRefDetail<ISignDocument> {
  @Prop()
  item!: ISignDocument;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  isLoading = false;

  images: {
    src: string;
    thumbnail: string;
    w: number;
    h: number;
    title: string;
  }[] = [];

  async mounted() {
    if (this.small) return;

    try {
      this.isLoading = true;

      const pages = await convertPdfToImg(this.item.url);
      this.images.slice(0);

      pages.forEach((i, index) => {
        this.images.push({
          src: i,
          thumbnail: i,
          w: 0,
          h: 0,
          title: `${document?.title} ${index + 1}/${pages.length}`
        });
      });

      this.isLoading = false;
    } catch (e) {
      this.$log.error(e);
    }
  }

  goToDetail() {
    new GoToHelper(this.$router).goToDocumentDetail(this.item.id, this.item.partnerId, true);
  }
}
