export enum VehicleTabs {
  HOME = "home",
  TICKET = "ticket",
  COST = "cost",
  EVENT = "event",
  DRIVER = "driver",
  POOL = "pool",
  CONTRACT = "contract",
  DOCUMENT = "document",
  ACTIVITY = "activity",
  REPORT = "report",
  MILEAGE = "mileage",
  BLUEPRINTS = "blueprints",
  HANDOVER = "handover"
}
