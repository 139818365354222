import {
  MrfiktivProjectCustomViewViewmodelGen,
  MrfiktivProjectCustomViewDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ProjectCustomViewTypeEnum } from "@/views/project/enum/ProjectCustomViewTypeEnum";
import { IPageFilterElement, PageFilterElement } from "./page-filter-element.entity";
import { IProjectCustomViewField, ProjectCustomViewField } from "./project-custom-view-field.entity";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";

@IsFilterable
class ProjectCustomViewBase implements MrfiktivProjectCustomViewViewmodelGen {
  id?: string | undefined;
  type: ProjectCustomViewTypeEnum;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.project.configuration.views.title"
  })
  title: string;
  boardColumn?: IProjectCustomViewField | undefined;
  boardColumnOrder: string[];
  groupBy?: IProjectCustomViewField | undefined;
  calendarStart?: IProjectCustomViewField | undefined;
  calendarEnd?: IProjectCustomViewField | undefined;
  values: IProjectCustomViewField[];
  filters: IPageFilterElement[];

  get dto(): MrfiktivProjectCustomViewDtoGen {
    return {
      type: this.type,
      title: this.title,
      boardColumn: this.boardColumn,
      boardColumnOrder: this.boardColumnOrder,
      groupBy: this.groupBy,
      calendarStart: this.calendarStart,
      calendarEnd: this.calendarEnd,
      values: this.values,
      filters: this.filters.map(f => f.dtoCustomView)
    };
  }

  constructor(customView?: Partial<MrfiktivProjectCustomViewViewmodelGen>) {
    this.id = customView?.id;
    this.type = (customView?.type as ProjectCustomViewTypeEnum) ?? ProjectCustomViewTypeEnum.BOARD;
    this.title = customView?.title ?? "";
    this.boardColumn = customView?.boardColumn ? new ProjectCustomViewField(customView?.boardColumn) : undefined;
    this.boardColumnOrder = customView?.boardColumnOrder ?? [];
    this.groupBy = customView?.groupBy ? new ProjectCustomViewField(customView?.groupBy) : undefined;
    this.calendarStart = customView?.calendarStart ? new ProjectCustomViewField(customView?.calendarStart) : undefined;
    this.calendarEnd = customView?.calendarEnd ? new ProjectCustomViewField(customView?.calendarEnd) : undefined;
    this.values = customView?.values?.map(value => new ProjectCustomViewField(value)) ?? [];
    this.filters = (customView?.filters ?? []).map(f => new PageFilterElement(f));
  }
}

type IProjectCustomView = ProjectCustomViewBase;
const ProjectCustomView = Filter.createForClass(ProjectCustomViewBase);

export { IProjectCustomView, ProjectCustomView };
