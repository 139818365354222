











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class LoginComponentMessage extends Vue {
  @Prop({ required: true })
  failText!: string;

  @Prop({ required: true })
  successText!: string;
}
