
import { Component, Vue } from "vue-property-decorator";
import debounce from "debounce";

@Component({})
export default class PartnerReportTimeLineCardScrollMixin extends Vue {
  readonly MARKER_ID = "PartnerReportDetailInfoTimeLineCardBottomMarkerId";

  debounceScrollToBottom = debounce(this.scrollToBottom, 500);

  private scrollToBottom() {
    this.$log.debug("scrollToBottom");
    document.getElementById(this.MARKER_ID)?.scrollIntoView({ behavior: "smooth" });
  }
}
