/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * The exception that is thrown when one of the arguments provided to a method is not valid.
 * @see https://docs.microsoft.com/en-us/dotnet/api/system.argumentexception?view=net-6.0
 */
export class ArgumentException extends Error {
  /**
   * @class Initialize ArgumentException
   */
  constructor(message: string) {
    super(`Invalid argument provided: ${message}`);
    this.name = this.constructor.name;

    Object.setPrototypeOf(this, ArgumentException.prototype);
  }
}
