

















































import { renderTemplate } from "@/lib/utility/renderTemplate";
import DocumentTemplateMixin from "@/mixins/DocumentTemplateMixin.vue";
import { IReport } from "@/models/report.entity";
import { ISignDocument } from "@/models/sign-document.entity";
import { SignDocumentTokenDtoGen } from "@/services/sign/v1/data-contracts";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { PartnerModule } from "@/store/modules/partner";
import debounce from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref } from "vue-property-decorator";
import DocumentDetailCardEditor, { DocumentDetailSideBarElements } from "./DocumentDetailCardEditor.vue";
import DocumentDetailEditorMixin from "./DocumentDetailEditorMixin.vue";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";
import DocumentTemplateDetailTokenList, { TextFieldConfig } from "./DocumentTemplateDetailTokenList.vue";
import DocumentTemplateDetailTokenListOptions from "./DocumentTemplateDetailTokenListOptions.vue";
import DocumentTemplateDetailTokenListOptionsMobile from "./DocumentTemplateDetailTokenListOptionsMobile.vue";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";

@Component({
  components: {
    DocumentDetailCardEditor,
    DocumentTemplateDetailTokenList,
    DocumentTemplateDetailTokenListOptions,
    DocumentTemplateDetailTokenListOptionsMobile
  }
})
export default class ReportDocumentSignRequestStepperForm
  extends mixins(
    ReportDocumentSignRequestStepperMixin,
    DocumentDetailEditorMixin,
    DocumentDetailMixin,
    DocumentTemplateMixin
  )
  implements IStepper {
  @Prop()
  signDocument!: ISignDocument;

  @Prop()
  fullscreen!: boolean;

  @Prop()
  height!: string;

  @Ref("detailCardEditor")
  detailCardEditor!: DocumentDetailCardEditor;

  @Prop()
  report?: IReport;

  sideBarElement: DocumentDetailSideBarElements = DocumentDetailSideBarElements.EDIT;

  snackbar = true;

  key = 0;

  edit = 0;

  currentPageNumber = 0;

  debounceOnResize = debounce(this.onResize, 100, false);

  get documentTokens() {
    return DocumentTemplateModule.documentTokens;
  }

  get pageTokens() {
    return this.documentTokens[this.currentPageNumber];
  }

  get currentDocumentToken() {
    return this.documentTokens[this.currentPageNumber];
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async mounted() {
    this.isLoading = true;
    await this.initialize(this.signDocument);

    for (const documentTokenPage of this.signDocument.tokens) {
      for (const documentToken of documentTokenPage) {
        let input = documentToken.value ?? "";
        if (!input && documentToken.token !== TextFieldConfig.token) {
          input = renderTemplate(documentToken.token, {
            report: this.report,
            partner: this.partner
          });
        }

        documentToken.value = input;
      }
    }

    this.isValid = true;
    this.isLoading = false;
  }

  resetHighlighted() {
    DocumentTemplateModule.setHighlightedToken(-1);
  }

  setCurrentPage(currentPageNumber: number) {
    this.currentPageNumber = currentPageNumber;
    DocumentTemplateModule.setCurrentPage(this.currentPageNumber);
  }

  onResize() {
    this.key++;
  }

  startAdd() {
    this.detailCardEditor.addToken();
    this.edit = 1;
  }

  startAddSignature() {
    this.detailCardEditor.addToken();
    this.edit = 2;
  }

  remove(e: SignDocumentTokenDtoGen) {
    const index = this.currentDocumentToken.findIndex(t => JSON.stringify(t) === JSON.stringify(e));
    if (index < 0) {
      return;
    }
    this.currentDocumentToken.splice(index, 1);
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.CHOICE;
  }

  next() {
    const signDocument = this.signDocument;
    signDocument.tokens = this.documentTokens;
    this.$emit("setSignDocument", signDocument);
    this.currentStep = SignRequestStepperPageEnum.INPUTS;
  }
}
