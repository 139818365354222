






import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { Component, Prop, Vue } from "vue-property-decorator";
import { FeatureModule } from "@/store/modules/feature.store";

@Component({
  components: {}
})
export default class LoginComponentNavigationButton extends Vue {
  @Prop()
  to!: string;

  @Prop({ default: false })
  isDisabled?: boolean;

  navigate() {
    this.$router.push({ path: this.to });
  }

  get color() {
    if (FeatureModule.isNavigationInPartnerColors) {
      return getDefaultPartnerColor();
    }

    return "primary";
  }
}
