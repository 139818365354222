











import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class LoginCardDescription extends Vue {
  @Prop()
  logo!: string;

  @Prop()
  title!: string;

  @Prop()
  text!: string;

  get displayTitle() {
    if (this.$route.path.indexOf("/logout") != -1) {
      return this.$t("components.login.LoginCardDescription.logoutTitle");
    }
    return this.title;
  }

  constructor() {
    super();
  }
}
