export class Type {
  /**
   * @param {*} value
   * @returns {Boolean} True if "value" is'undefined' or 'null' , otherwise false.
   */
  public static isUndefinedOrNull(value: any): boolean {
    return value === undefined || value === null;
  }

  /**
   * Conveniencemethod for !Type.isUndefinedOrNull(value).
   */
  public static isPresent(value: any): boolean {
    return !Type.isUndefinedOrNull(value);
  }

  /**
   * Execute the given method with the value as parameter, if it is present.
   *
   * @param {Any} value
   * @param {Function} callback
   * @param {Function} callbackElse
   */
  public static ifPresent(value: any, callback: Function, callbackElse: Function): void {
    if (Type.isPresent(value)) {
      callback(value);
    } else if (Type.isPresent(callbackElse)) {
      callbackElse();
    }
  }

  /**
   * @param {String} value
   * @returns {Boolean} If "value" is undefined or null or an empty String.
   */
  public static isBlankString(value: string): boolean {
    if (Type.isUndefinedOrNull(value)) return true;
    if (typeof value == "string") return value.trim().length === 0;
    return false;
  }
}
