const PDFJSWorker = () => import("pdfjs-dist/legacy/build/pdf.worker.entry");
const pdfJs = () => import("pdfjs-dist/legacy/build/pdf");

/**
 * Helper to convert a single page of a pdf to an image
 * @param pageNumber
 * @param pdf
 * @returns
 */
async function convertPage(pageNumber: number, pdf: any, scale: number) {
  const page = await pdf.getPage(pageNumber);
  const viewport = page.getViewport({ scale });
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  const renderContext = { canvasContext: context, viewport: viewport };
  await page.render(renderContext as any).promise;

  return canvas.toDataURL("image/png");
}

/**
 * Converts a pdf to a list of promises for each page
 * @param url url to a pdf
 * @returns one image per page of the pdf sorted by pdf pages
 */
export async function convertPdfToImagePromises(url: string | Uint8Array, scale = 1.5) {
  const pages: Promise<string>[] = [];
  const pdfjs = await pdfJs();
  const GlobalWorkerOptions = pdfjs.GlobalWorkerOptions;
  GlobalWorkerOptions.workerSrc = await PDFJSWorker();
  const pdf = await pdfjs.getDocument(url).promise;

  const totalPages = pdf.numPages;
  for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
    pages.push(convertPage(pageNumber, pdf, scale));
  }

  return pages;
}

/**
 * Converts a pdf to a list of promises for each page
 * @param url url to a pdf
 * @returns page of pdf starts at 1
 * @returns one image per page of the pdf sorted by pdf pages
 */
export async function convertPdfPageToImage(url: string | Uint8Array, scale = 1.5, pageNumber: number) {
  const pdfjs = await pdfJs();
  const GlobalWorkerOptions = pdfjs.GlobalWorkerOptions;
  GlobalWorkerOptions.workerSrc = await PDFJSWorker();
  const pdf = await pdfjs.getDocument(url).promise;

  return convertPage(pageNumber, pdf, scale);
}

/**
 * Converts a pdf to images
 * @param url url to a pdf
 * @returns one image per page of the pdf sorted by pdf pages
 */
export async function convertPdfToImg(url: string | Uint8Array, scale = 1.5) {
  return await Promise.all(await convertPdfToImagePromises(url, scale));
}
