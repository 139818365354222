/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateSettingDtoGen,
  MrfiktivSettingPartnerInternalViewModelGen,
  MrfiktivSettingViewModelGen,
  MrfiktivUpdateSettingDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Setting<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags setting
   * @name SettingControllerCreate
   * @summary Admin: Create a new setting
   * @request POST:/setting
   * @secure
   * @response `201` `MrfiktivSettingViewModelGen` The record has been successfully created
   * @response `400` `void` Key/ Value contains invalid chars.
   * @response `403` `void` Forbidden.
   * @response `409` `void` Key already taken.
   */
  settingControllerCreate = (data: MrfiktivCreateSettingDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingViewModelGen, void>({
      path: `/setting`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingControllerFindAll
   * @summary Admin: Get all settings
   * @request GET:/setting
   * @secure
   * @response `200` `(MrfiktivSettingViewModelGen)[]` The found records
   * @response `403` `void` Forbidden.
   */
  settingControllerFindAll = (params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingViewModelGen[], void>({
      path: `/setting`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingControllerFindOne
   * @summary Admin: Get a settings by id
   * @request GET:/setting/{id}
   * @secure
   * @response `200` `MrfiktivSettingViewModelGen` The found record
   * @response `403` `void` Forbidden.
   */
  settingControllerFindOne = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingViewModelGen, void>({
      path: `/setting/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingControllerUpdate
   * @summary Admin: Update a setting by id
   * @request PATCH:/setting/{id}
   * @secure
   * @response `200` `MrfiktivSettingViewModelGen` The updated record
   * @response `400` `void` Can not update encrypted setting or invalid chars in key/ value.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Setting not found.
   */
  settingControllerUpdate = (id: string, data: MrfiktivUpdateSettingDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingViewModelGen, void>({
      path: `/setting/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingControllerRemove
   * @summary Admin: Delete a setting by id
   * @request DELETE:/setting/{id}
   * @secure
   * @response `200` `MrfiktivSettingViewModelGen` The deleted record
   * @response `403` `void` Forbidden.
   */
  settingControllerRemove = (id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingViewModelGen, void>({
      path: `/setting/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingControllerFindByKey
   * @summary Admin: Get a settings by key
   * @request GET:/setting/{key}/byKey
   * @secure
   * @response `200` `MrfiktivSettingViewModelGen` The found record
   * @response `403` `void` Forbidden.
   */
  settingControllerFindByKey = (key: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingViewModelGen, void>({
      path: `/setting/${key}/byKey`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingPartnerControllerCreateForPartner
   * @summary Create a new setting
   * @request POST:/partner/{partnerId}/setting
   * @secure
   * @response `201` `MrfiktivSettingPartnerInternalViewModelGen` The record has been successfully created
   * @response `400` `void` Key/ Value contains invalid chars.
   * @response `403` `void` Forbidden.
   * @response `409` `void` Key is already taken.
   */
  settingPartnerControllerCreateForPartner = (
    partnerId: string,
    data: MrfiktivCreateSettingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivSettingPartnerInternalViewModelGen, void>({
      path: `/partner/${partnerId}/setting`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingPartnerControllerFindAllByPartner
   * @summary Get all settings
   * @request GET:/partner/{partnerId}/setting
   * @secure
   * @response `200` `(MrfiktivSettingPartnerInternalViewModelGen)[]` The found records
   * @response `403` `void` Forbidden.
   */
  settingPartnerControllerFindAllByPartner = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingPartnerInternalViewModelGen[], void>({
      path: `/partner/${partnerId}/setting`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingPartnerControllerFindOneByPartner
   * @summary Get a settings by id
   * @request GET:/partner/{partnerId}/setting/{id}
   * @secure
   * @response `200` `MrfiktivSettingPartnerInternalViewModelGen` The found record
   * @response `403` `void` Forbidden.
   * @response `404` `void` Not found.
   */
  settingPartnerControllerFindOneByPartner = (id: string, partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingPartnerInternalViewModelGen, void>({
      path: `/partner/${partnerId}/setting/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingPartnerControllerUpdateByPartner
   * @summary Update a setting by id and partner id
   * @request PATCH:/partner/{partnerId}/setting/{id}
   * @secure
   * @response `200` `MrfiktivSettingPartnerInternalViewModelGen` The updated record
   * @response `400` `void` Can not update encrypted setting or key/vaue contains invalid chars.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Setting not found.
   */
  settingPartnerControllerUpdateByPartner = (
    id: string,
    partnerId: string,
    data: MrfiktivUpdateSettingDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivSettingPartnerInternalViewModelGen, void>({
      path: `/partner/${partnerId}/setting/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingPartnerControllerRemoveByPartner
   * @summary Delete a setting by id and partner id
   * @request DELETE:/partner/{partnerId}/setting/{id}
   * @secure
   * @response `200` `MrfiktivSettingPartnerInternalViewModelGen` The deleted record
   * @response `403` `void` Forbidden.
   * @response `404` `void` Not found.
   */
  settingPartnerControllerRemoveByPartner = (id: string, partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingPartnerInternalViewModelGen, void>({
      path: `/partner/${partnerId}/setting/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingPartnerControllerFindOneByKey
   * @summary Get a settings by id
   * @request GET:/partner/{partnerId}/setting/{key}/byKey
   * @secure
   * @response `200` `MrfiktivSettingPartnerInternalViewModelGen` The found record
   * @response `403` `void` Forbidden.
   * @response `404` `void` Not found.
   */
  settingPartnerControllerFindOneByKey = (key: string, partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingPartnerInternalViewModelGen, void>({
      path: `/partner/${partnerId}/setting/${key}/byKey`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingPartnerControllerFindAllPublicByPArtnerId
   * @summary Get a settings by id
   * @request GET:/partner/{partnerId}/public-setting
   * @response `200` `(MrfiktivSettingPartnerInternalViewModelGen)[]` The found record
   * @response `403` `void` Forbidden.
   * @response `404` `void` Not found.
   */
  settingPartnerControllerFindAllPublicByPArtnerId = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingPartnerInternalViewModelGen[], void>({
      path: `/partner/${partnerId}/public-setting`,
      method: "GET",
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags setting
   * @name SettingPartnerControllerFindPublicOneByKey
   * @summary Get a settings by id
   * @request GET:/partner/{partnerId}/public-setting/{key}
   * @response `200` `MrfiktivSettingPartnerInternalViewModelGen` The found record
   * @response `403` `void` Forbidden.
   * @response `404` `void` Not found.
   */
  settingPartnerControllerFindPublicOneByKey = (key: string, partnerId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivSettingPartnerInternalViewModelGen, void>({
      path: `/partner/${partnerId}/public-setting/${key}`,
      method: "GET",
      format: "json",
      ...params
    });
}
