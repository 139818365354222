
























import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Tooltip from "../utility/tooltip.vue";
import TemplateEditorPlaceholderDropdown from "./TemplateEditorPlaceholderDropdown.vue";

@Component({
  components: { Tooltip, ConfirmActionDialog, TemplateEditorPlaceholderDropdown }
})
export default class TemplateEditorAddPlaceholder extends Vue {
  @Prop()
  partnerId?: string;

  placeholderDialog = false;

  /**
   * Verify the token category and emit
   */
  onSelect() {
    const placeholder = (this.$refs.dropdown as TemplateEditorPlaceholderDropdown).select();

    this.$emit("addPlaceholder", placeholder);
    this.placeholderDialog = false;
  }
}
