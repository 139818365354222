import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { IProject, Project } from "@/models/project.entity";

export const ProjectDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IProject> {
  getIdentifier(entity: IProject): string {
    return (entity as any).id;
  }

  mapEntity(entity: IProject): IProject {
    return new Project(entity);
  }

  mapsConfig = {};
})();
