














































































import FileUploadMulti from "@/components/files/FileUploadMulti.vue";
import { beforeCurrentDateRule, dateRule } from "@/lib/rules/dateRule";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { SignCreateSignRequestDtoGen, SignDigitalSignatureRecipientGen } from "@/services/sign/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { toISOString } from "@/lib/utility/date-helper";
import { ISignDocument } from "@/models/sign-document.entity";
import { ISignRequest } from "@/models/sign-request.entity";

export class CreateRecipientDto implements SignDigitalSignatureRecipientGen {
  email = "";
  lastName? = "";
  firstName?: string;
  companyName?: string;

  constructor(recipient?: SignDigitalSignatureRecipientGen) {
    if (recipient) {
      this.lastName = recipient.lastName;
      this.email = recipient.email;
      this.firstName = recipient.firstName;
      this.companyName = recipient.companyName;
    }
  }
}

export class CreateSignRequestDto implements SignCreateSignRequestDtoGen {
  recipient: SignDigitalSignatureRecipientGen = new CreateRecipientDto();

  title = "";
  documents: string[] = [];

  description?: string;
  validBy?: string;

  constructor(signRequest?: ISignRequest, documents?: ISignDocument[]) {
    if (documents) {
      this.documents = documents.map(d => d.id);
    }

    if (signRequest) {
      this.recipient = new CreateRecipientDto(signRequest.recipient);
      this.title = signRequest.title;
      this.description = signRequest.description;
      this.documents = signRequest.documents;
      this.validBy = signRequest.validBy;
    }
  }
}

@Component({
  components: { FileUploadMulti }
})
export default class SignRequestForm extends Vue {
  @Prop({ default: false })
  isLoading!: boolean;

  @Prop({})
  signRequest!: ISignRequest;

  @Prop()
  documents!: ISignDocument[];

  valid = false;

  createSignRequest: SignCreateSignRequestDtoGen = new CreateSignRequestDto(this.signRequest, this.documents);

  get requiredRule() {
    return [requiredRule()];
  }

  get numberRule() {
    return [isNumberRule(), requiredRule()];
  }

  get dateRule() {
    return [dateRule(), beforeCurrentDateRule()];
  }

  get disabled() {
    return this.isLoading || typeof this.signRequest !== "undefined";
  }

  get validBy() {
    if (!this.signRequest?.validBy) {
      return "";
    }

    return toISOString(this.signRequest.validBy);
  }

  set validBy(date: string) {
    this.signRequest.validBy = date;
  }

  save() {
    this.$emit("save", this.createSignRequest);
  }

  resetForm() {
    this.createSignRequest = new CreateSignRequestDto(this.signRequest, this.documents);
  }
}
