






import TheLayoutBase from "@/layouts/TheLayoutBase.vue";
import { AssetRepository } from "@/lib/AssetRepository";
import { ConfigModule } from "@/store/modules/config";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutBase
  }
})
export default class TheLayoutPortal extends Vue {
  @Prop({ default: "" })
  logo!: string;

  get logoUrl() {
    if (this.logo) {
      return this.logo;
    }
    return AssetRepository.getAsset(ConfigModule.darkMode, ConfigModule._portalLogo);
  }
}
