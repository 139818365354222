import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";

/**
 * Map of ticket status to color and label
 */
export const ticketStateMap = new Map<TicketStatusEnum, { color: string; label: string }>([
  [TicketStatusEnum.DRAFT, { color: "blue-grey lighten-4", label: "Draft" }],
  [TicketStatusEnum.OPEN, { color: "green lighten-1", label: "Open" }],
  [TicketStatusEnum.ARCHIVED, { color: "deep-purple lighten-4", label: "Archived" }],
  [TicketStatusEnum.CLOSED, { color: "deep-purple accent-2", label: "Closed" }]
]);
