/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { BookingClientProvider } from "../booking-http-client.provider";
import { Availability } from "../v1/Availability";
import {
  BookingAvailabilityControllerFindOneByPartnerIdParamsGen,
  BookingBookingConfirmedViewModelGen,
  BookingBookingControllerFindAllByPartnerIdParamsGen,
  BookingCreateBookingWithoutResourceDtoGen,
  BookingPageViewModelGen,
  BookingServiceBookingViewModelGen,
  BookingBookingAttachmentDtoGen,
  BookingUpdateBookingUnauthenticatedDtoGen,
  BookingUpdateSoftDeleteBookingDtoGen,
  BookingServiceBookingSummaryViewModelGen
} from "../v1/data-contracts";

/**
 * Service to talk to the Availability endpoint.
 */
export class AvailabilityService {
  private readonly availability: Availability;

  constructor(clientProvider: BookingClientProvider) {
    this.availability = new Availability(clientProvider.client());
  }

  /**
   * Creates a booking request on a given service for a partner
   *
   * @param partnerId
   * @param data
   * @returns
   */
  async create(
    partnerId: string,
    data: BookingCreateBookingWithoutResourceDtoGen
  ): Promise<BookingBookingConfirmedViewModelGen> {
    return (await this.availability.availabilityControllerCreate(partnerId, data)).data;
  }

  /**
   * Get booking by id
   * @param partnerId
   * @param id
   */
  async findOneBookingById(partnerId: string, id: string) {
    return (await this.availability.availabilityControllerGetById(partnerId, id)).data;
  }

  /**
   * Delete(cancel) a booking
   * @param partnerId
   * @param id
   */
  async deleteBookingById(partnerId: string, id: string, data: BookingUpdateSoftDeleteBookingDtoGen) {
    return (await this.availability.availabilityControllerDeleteOne(partnerId, id, data)).data;
  }

  /**
   * Update a booking
   * @param partnerId
   * @param id
   * @param data
   */
  async updateBookingById(partnerId: string, id: string, data: BookingUpdateBookingUnauthenticatedDtoGen) {
    return (await this.availability.availabilityControllerUpdate(partnerId, id, data)).data;
  }

  /**
   * @param partnerId
   * @param data
   * @returns
   */
  async uploadImageAttachment(partnerId: string, data: BookingBookingAttachmentDtoGen) {
    return (await this.availability.availabilityControllerUploadImageAttachment(partnerId, data)).data;
  }

  /**
   * Gets available booking slots for a service for a partner
   *
   * @param query
   * @returns
   */
  async findAllByPartner(
    query: BookingBookingControllerFindAllByPartnerIdParamsGen
  ): Promise<BookingPageViewModelGen & { data?: BookingServiceBookingSummaryViewModelGen[] }> {
    return (await this.availability.availabilityControllerFindAllByPartnerId(query)).data;
  }

  /**
   *  Gets a particular slots for a service from a given partner
   *
   * @param query
   * @returns
   */
  async findOneByPartner(
    query: BookingAvailabilityControllerFindOneByPartnerIdParamsGen
  ): Promise<BookingServiceBookingViewModelGen> {
    return (await this.availability.availabilityControllerFindOneByPartnerId(query)).data;
  }
}

export default new AvailabilityService(new BookingClientProvider());
