
































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ImageDialog extends DarkModeHighlightMixin {
  @Prop()
  src!: string;

  @Prop({ default: "mx-4 mb-4" })
  cardClass!: string;

  @Prop({ default: "" })
  title!: string;

  dialog = false;

  close() {
    this.dialog = false;
  }
}
