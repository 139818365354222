var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.failText ? _c('h3', {
    staticClass: "mt-n4 mb-4",
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.failText)) + " ")]) : _vm._e(), _vm.successText ? _c('h3', {
    staticClass: "mt-n4 mb-4",
    staticStyle: {
      "color": "green"
    }
  }, [_vm._v(" " + _vm._s(_vm.successText) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }