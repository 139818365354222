var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-navigation-layout', {
    attrs: {
      "items": _vm.items,
      "title": _vm.$t('navigation.TheAboutList.header')
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }