import { BaseGoToHelper } from "./goToHelper";
import VueRouter from "vue-router";

export class UserGoToHelper extends BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  // #region Signature
  async goToMySignatureView(newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "MySignatureView"
      });
    }

    const routeData = this.router.resolve({
      name: "MySignatureView"
    });

    return window.open(routeData.href, "_blank");
  }

  async goToMySignatureRequestDetail(partnerId: string, signRequestId: string, newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "MySignatureRequestDetail",
        params: { partnerId, signRequestId }
      });
    }

    const routeData = this.router.resolve({
      name: "MySignatureRequestDetail",
      params: { partnerId, signRequestId }
    });
    return window.open(routeData.href, "_blank");
  }

  async goToSignature(signRequestId: string) {
    const href = `/sign.html#/sign/${signRequestId}`;
    return window.open(href, "_blank");
  }
  // #endregion

  // #region Booking
  async goToMyBookingView(newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "MyBookingView"
      });
    }

    const routeData = this.router.resolve({
      name: "MyBookingView"
    });

    return window.open(routeData.href, "_blank");
  }

  async goToBookingCustomerUpdate(id: string, newTab = false) {
    await this.goTo("BookingUpdate", { id }, newTab);
  }

  async goToBookingCustomerCancel(id: string, newTab = false) {
    await this.goTo("BookingCancel", { id }, newTab);
  }

  async goToBookingServiceAvailability(newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "BookingServiceAvailability"
      });
    }

    const routeData = this.router.resolve({ name: "BookingServiceAvailability" });
    return window.open(routeData.href, "_blank");
  }
  // #endregion

  // #region Damage
  async goToMyDamageView(newTab = false) {
    if (!newTab) {
      return this.router.push({
        name: "MyDamageView"
      });
    }

    const routeData = this.router.resolve({
      name: "MyDamageView"
    });

    return window.open(routeData.href, "_blank");
  }
  // #endregion
}
