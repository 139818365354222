/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SignCreateDocumentDtoGen,
  SignDocumentControllerFindAllParamsGen,
  SignDocumentControllerFindOneWithAccessSignatureParamsGen,
  SignDocumentViewModelGen,
  SignExceptionViewmodelGen,
  SignPageViewModelGen,
  SignUpdateDocumentDtoGen,
  SignUpdateDocumentTokenDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Document<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Document
   * @name DocumentControllerCreate
   * @summary Create a document for a partner
   * @request POST:/partner/{partnerId}/document
   * @secure
   * @response `200` `SignDocumentViewModelGen` The created document
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  documentControllerCreate = (partnerId: string, data: SignCreateDocumentDtoGen, params: RequestParams = {}) =>
    this.http.request<SignDocumentViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/document`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Document
   * @name DocumentControllerFindAll
   * @summary Get all documents from a partner
   * @request GET:/partner/{partnerId}/document
   * @secure
   * @response `200` `(SignPageViewModelGen & { data?: (SignDocumentViewModelGen)[] })`
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  documentControllerFindAll = (
    { partnerId, ...query }: SignDocumentControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<SignPageViewModelGen & { data?: SignDocumentViewModelGen[] }, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/document`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Document
   * @name DocumentControllerFindOne
   * @summary Get a document from a partner
   * @request GET:/partner/{partnerId}/document/{id}
   * @secure
   * @response `200` `SignDocumentViewModelGen` The document
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  documentControllerFindOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<SignDocumentViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/document/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Document
   * @name DocumentControllerUpdate
   * @summary Update a document from a partner
   * @request PATCH:/partner/{partnerId}/document/{id}
   * @secure
   * @response `200` `SignDocumentViewModelGen` The updated document
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  documentControllerUpdate = (
    partnerId: string,
    id: string,
    data: SignUpdateDocumentDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDocumentViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/document/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Document
   * @name DocumentControllerRemove
   * @summary Delete a document from a partner
   * @request DELETE:/partner/{partnerId}/document/{id}
   * @secure
   * @response `200` `SignDocumentViewModelGen` The deleted document
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  documentControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<SignDocumentViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/document/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Document
   * @name DocumentControllerFindOneWithAccessSignature
   * @summary Get a report by id with a shared content token.
   * @request GET:/{partnerId}/document/{documentId}/access
   * @secure
   * @response `200` `SignDocumentViewModelGen` The found record
   * @response `400` `void` Bad Request.
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `void` Forbidden.
   * @response `404` `void` Report not found.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  documentControllerFindOneWithAccessSignature = (
    { partnerId, documentId, ...query }: SignDocumentControllerFindOneWithAccessSignatureParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDocumentViewModelGen, void | SignExceptionViewmodelGen>({
      path: `/${partnerId}/document/${documentId}/access`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Document
   * @name DocumentControllerAddDocumentTokens
   * @summary Update the tokens in a document from a partner
   * @request PATCH:/partner/{partnerId}/document/{id}/tokens
   * @secure
   * @response `200` `SignDocumentViewModelGen` The updated document
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  documentControllerAddDocumentTokens = (
    partnerId: string,
    id: string,
    data: SignUpdateDocumentTokenDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDocumentViewModelGen, SignExceptionViewmodelGen>({
      path: `/partner/${partnerId}/document/${id}/tokens`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
