import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { CustomField } from "../v1/CustomField";
import {
  MrfiktivCreateCustomFieldDtoGen,
  MrfiktivCustomFieldControllerGetParamsGen,
  MrfiktivUpdateCustomFieldDtoGen
} from "../v1/data-contracts";

/**
 * Communicate with custom field API
 */
class CustomFieldService {
  mrfiktivProxy: CustomField;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new CustomField(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create a custom field
   */
  async create(partnerId: string, data: MrfiktivCreateCustomFieldDtoGen) {
    return (await this.mrfiktivProxy.customFieldControllerCreate(partnerId, data)).data;
  }

  /**
   * Get all custom fields
   */
  async get(data: MrfiktivCustomFieldControllerGetParamsGen) {
    return (await this.mrfiktivProxy.customFieldControllerGet(data)).data;
  }

  /**
   * Get a custom field
   */
  async getOne(partnerId: string, customFieldId: string) {
    return (await this.mrfiktivProxy.customFieldControllerGetOne(partnerId, customFieldId)).data;
  }

  /**
   * Update a custom field
   */
  async update(partnerId: string, customFieldId: string, data: MrfiktivUpdateCustomFieldDtoGen) {
    return (await this.mrfiktivProxy.customFieldControllerUpdate(partnerId, customFieldId, data)).data;
  }

  /**
   * Delete a custom field
   */
  async delete(partnerId: string, customFieldId: string) {
    return (await this.mrfiktivProxy.customFieldControllerDelete(partnerId, customFieldId)).data;
  }
}

/**
 * Communicate with custom field API
 */
export default new CustomFieldService(new MrfiktivHttpClientProvider());
