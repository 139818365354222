import { PartnerModule } from "./../store/modules/partner";
import { ConfigModule } from "@/store/modules/config";
import { ReportModule } from "@/store/modules/report.store";

export function getDefaultPartnerColor() {
  const color =
    ReportModule.partner?.settings?.color || PartnerModule.partner?.settings?.color || ConfigModule.color.primary;
  return color;
}

export function getOpaquePartnerColor() {
  let opaquePartnerColor = getDefaultPartnerColor();
  if (opaquePartnerColor.length === 9) {
    opaquePartnerColor = opaquePartnerColor.slice(0, -2);
  }
  opaquePartnerColor += "01";
  return opaquePartnerColor;
}
