/**
 * Default states of a ticket
 * TicketStateEnum
 */
export enum TicketStatusEnum {
  /**
   * Ticket is in a draft state
   */
  DRAFT = "draft",

  /**
   * Ticket is open
   */
  OPEN = "open",
  /**
   * Ticket is closed
   */
  CLOSED = "closed",

  /**
   * Ticket was archived
   */
  ARCHIVED = "archived"
}
