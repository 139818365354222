import Vue from "vue";
import store from "@/store/VuexPlugin";
import SessionService from "@/services/fahrzeugschein/service/sessionService";
import { SessionEntity } from "@/models/sessionEntity";
import { ErrorLogModule } from "@/store/modules/error-log";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ConfigModule } from "./config";
import { FahrzeugscheinModule } from "./fahrzeugschein";

@Module({
  dynamic: true,
  namespaced: true,
  name: "session",
  store
})
class Session extends VuexModule {
  sessions: SessionEntity[] = [];

  @Action
  async fetchSessions() {
    try {
      let sessions = await SessionService.getAll();
      if (sessions != undefined) {
        sessions = SessionService.sortSessions(sessions);
        this.context.commit("setSessions", sessions);

        ConfigModule.setFahrzeugscheinTokenValid(true);

        FahrzeugscheinModule.updateCachedFahrzeugscheine(sessions.slice(0, 5));
      }
    } catch (error) {
      Vue.$log.error(error);

      this.context.commit("setSessions", []);

      ConfigModule.setFahrzeugscheinTokenValid(false);

      ErrorLogModule.addErrorLog({
        name: "Fetch Session Error",
        message: error
      });
    }
  }

  @Mutation
  setSessions(sessions: SessionEntity[]) {
    this.sessions = sessions;
  }
}

export const SessionModule = getModule(Session);
