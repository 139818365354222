var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    on: {
      "dragover": function dragover($event) {
        $event.preventDefault();
        return _vm.dragOver.apply(null, arguments);
      },
      "dragleave": function dragleave($event) {
        $event.preventDefault();
        return _vm.dragLeave.apply(null, arguments);
      },
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.onFileDrop($event);
      }
    }
  }, [_vm.isDrag ? _c('div', {
    staticClass: "drag fadeIn"
  }, [!_vm.isDrop ? _c('v-icon', {
    staticStyle: {
      "zoom": "3"
    },
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("mdi-file")]) : _vm.isFailed ? _c('v-icon', {
    staticClass: "fade",
    staticStyle: {
      "zoom": "3"
    },
    attrs: {
      "x-large": "",
      "color": "error"
    }
  }, [_vm._v("mdi-close")]) : _c('v-icon', {
    staticClass: "fade",
    staticStyle: {
      "zoom": "3"
    },
    attrs: {
      "x-large": "",
      "color": "success"
    }
  }, [_vm._v("mdi-check-bold")])], 1) : _vm._e(), _c('v-card-text', [_c('ReportDocumentSignRequestSteps', {
    ref: "steps",
    attrs: {
      "report": _vm.report,
      "fullscreen": false,
      "step": _vm.step,
      "loading": _vm.isLoading,
      "valid": _vm.isValid
    },
    on: {
      "update:step": function updateStep($event) {
        _vm.step = $event;
      },
      "update:loading": function updateLoading($event) {
        _vm.isLoading = $event;
      },
      "update:valid": function updateValid($event) {
        _vm.isValid = $event;
      },
      "close": _vm.close
    }
  })], 1), _c('v-card-actions', {}, [!_vm.hideLeft ? _c('v-btn', {
    attrs: {
      "text": "",
      "loading": _vm.isLoading,
      "small": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.previous
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.template.dialog.preview.back")) + " ")]) : _vm._e(), !_vm.hideRight ? _c('v-btn', {
    attrs: {
      "color": "success",
      "disabled": !_vm.isValid || _vm.isLoading,
      "loading": _vm.isLoading,
      "small": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v(" " + _vm._s(_vm.rightButtonText) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }