export function checkSessionStatus(status: string) {
  switch (status) {
    case "finished":
      return true;
    case "corrected":
      return true;
    default:
      return false;
  }
}
