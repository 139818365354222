import { MrfiktivCustomFieldListElementConfigurationViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";

class CustomFieldListElementConfigurationBase implements MrfiktivCustomFieldListElementConfigurationViewModelGen {
  isRequired: boolean;

  constructor(configuration?: Partial<MrfiktivCustomFieldListElementConfigurationViewModelGen>) {
    this.isRequired = configuration?.isRequired ?? false;
  }
}

type ICustomFieldListElementConfiguration = CustomFieldListElementConfigurationBase;
const CustomFieldListElementConfiguration = CustomFieldListElementConfigurationBase;

export { ICustomFieldListElementConfiguration, CustomFieldListElementConfiguration };
