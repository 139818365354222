/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgChargingStationControllerFindAllMineParamsGen,
  ThgChargingStationControllerFindAllParamsGen,
  ThgChargingStationControllerFindManyParamsGen,
  ThgChargingStationProofDocumentDtoGen,
  ThgChargingStationProofDtoGen,
  ThgChargingStationViewModelGen,
  ThgCreateChargingStationDtoGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen,
  ThgUpdateChargingStationDtoGen,
  ThgUpdateMyChargingStationShortDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ChargingStation<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerCreate
   * @summary Creates a charging station
   * @request POST:/charging-station
   * @secure
   * @response `201` `ThgChargingStationViewModelGen` The created charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerCreate = (data: ThgCreateChargingStationDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/charging-station`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerFindAll
   * @summary (ADMIN) List all charging station
   * @request GET:/charging-station
   * @secure
   * @response `200` `((ThgPageViewModelGen & { data?: (ThgChargingStationViewModelGen)[] }) | (ThgChargingStationViewModelGen)[])`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerFindAll = (
    query: ThgChargingStationControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      (ThgPageViewModelGen & { data?: ThgChargingStationViewModelGen[] }) | ThgChargingStationViewModelGen[],
      ThgExceptionViewmodelGen
    >({
      path: `/charging-station`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerUploadPdfProof
   * @summary Submit a file proof for a charging station
   * @request POST:/charging-station/me/proof/pdf
   * @secure
   * @response `200` `ThgChargingStationProofDocumentDtoGen` the image or the pdf document
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerUploadPdfProof = (data: ThgChargingStationProofDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgChargingStationProofDocumentDtoGen, void | ThgExceptionViewmodelGen>({
      path: `/charging-station/me/proof/pdf`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerUploadImageProof
   * @summary Submit a file proof for a charging station
   * @request POST:/charging-station/me/proof/image
   * @secure
   * @response `200` `ThgChargingStationProofDocumentDtoGen` the image or the pdf document
   * @response `400` `void` General error if request is not working.
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerUploadImageProof = (data: ThgChargingStationProofDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgChargingStationProofDocumentDtoGen, void | ThgExceptionViewmodelGen>({
      path: `/charging-station/me/proof/image`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerFindAllMine
   * @summary List all charging station of requesting user
   * @request GET:/charging-station/me
   * @secure
   * @response `200` `((ThgPageViewModelGen & { data?: (ThgChargingStationViewModelGen)[] }) | (ThgChargingStationViewModelGen)[])`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerFindAllMine = (
    query: ThgChargingStationControllerFindAllMineParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      (ThgPageViewModelGen & { data?: ThgChargingStationViewModelGen[] }) | ThgChargingStationViewModelGen[],
      ThgExceptionViewmodelGen
    >({
      path: `/charging-station/me`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerFindOneOfMine
   * @summary Get a charging station
   * @request GET:/charging-station/me/{id}
   * @secure
   * @response `200` `ThgChargingStationViewModelGen` The charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerFindOneOfMine = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/charging-station/me/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerUpdateMine
   * @summary Updates a charging station
   * @request PATCH:/charging-station/me/{id}
   * @secure
   * @response `200` `ThgChargingStationViewModelGen` The updated charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerUpdateMine = (
    id: string,
    data: ThgUpdateMyChargingStationShortDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/charging-station/me/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerRemoveMine
   * @summary Removes a charging station
   * @request DELETE:/charging-station/me/{id}
   * @secure
   * @response `200` `ThgChargingStationViewModelGen` The removed charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerRemoveMine = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/charging-station/me/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerFindMany
   * @summary (ADMIN) Gets all charging stations from a list of ids
   * @request GET:/charging-station/many
   * @secure
   * @response `200` `(ThgChargingStationViewModelGen)[]` List of charging stations
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerFindMany = (
    query: ThgChargingStationControllerFindManyParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgChargingStationViewModelGen[], ThgExceptionViewmodelGen>({
      path: `/charging-station/many`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerFindOne
   * @summary (ADMIN) Get a charging station
   * @request GET:/charging-station/{id}
   * @secure
   * @response `200` `ThgChargingStationViewModelGen` The charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerFindOne = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/charging-station/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerUpdate
   * @summary (ADMIN) Updates a charging station
   * @request PATCH:/charging-station/{id}
   * @secure
   * @response `200` `ThgChargingStationViewModelGen` The updated charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerUpdate = (id: string, data: ThgUpdateChargingStationDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/charging-station/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags ChargingStation
   * @name ChargingStationControllerRemove
   * @summary (ADMIN) Removes a charging station
   * @request DELETE:/charging-station/{id}
   * @secure
   * @response `200` `ThgChargingStationViewModelGen` The removed charging station
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  chargingStationControllerRemove = (id: string, params: RequestParams = {}) =>
    this.http.request<ThgChargingStationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/charging-station/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
