/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import HistoryLog from "@/lib/historyLog";
import VueI18n from "@/plugins/I18nPlugin";
import { LoginModule } from "@/store/modules/login.store";
import { UserModule } from "@/store/modules/me-user.store";
import Vue from "vue";
import { NavigationGuard } from "vue-router";

/**
 * Auth Guard.
 * Checks if user is authenticated and redirects to login if not.
 */
export const authGuard: NavigationGuard = (to, from, next) => {
  HistoryLog.add(to.path);
  Vue.$log.info("User logged in?");

  if (!UserModule.isAuthenticated) {
    //Save where user wanted to go so that user can be redirected back after login
    LoginModule.setRedirect(to.fullPath);

    Vue.$log.info("Login redirect");
    Vue.$toast.info(VueI18n.t("authGuard.login"));

    return next({ name: "Login" });
  }

  return next();
};

/**
 * Auth Guard.
 * Checks if user is authenticated and redirects to login if not.
 */
export const authGuardCharging: NavigationGuard = (to, from, next) => {
  HistoryLog.add(to.path);
  Vue.$log.info("User logged in?");

  if (!UserModule.isAuthenticated) {
    //Save where user wanted to go so that user can be redirected back after login
    LoginModule.setRedirect(to.fullPath);

    Vue.$log.info("SignUp redirect");
    Vue.$toast.info(VueI18n.t("authGuard.signUp"));
    return next({ name: "SignUp", query: { charging: "true" } });
  }

  return next();
};
