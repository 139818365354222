import { WeekDaysEnum } from "@/lib/enum/weekDays.enum";

/**
 * Mapper from one order of the week(Monday-Sunday) to javascript order (Sunday-Saturday)
 */
export const daysOfTheWeekMap: Map<WeekDaysEnum, WeekDaysEnum> = new Map([
  [WeekDaysEnum.MONDAY, WeekDaysEnum.SUNDAY],
  [WeekDaysEnum.TUESDAY, WeekDaysEnum.MONDAY],
  [WeekDaysEnum.WEDNESDAY, WeekDaysEnum.TUESDAY],
  [WeekDaysEnum.THURSDAY, WeekDaysEnum.WEDNESDAY],
  [WeekDaysEnum.FRIDAY, WeekDaysEnum.THURSDAY],
  [WeekDaysEnum.SATURDAY, WeekDaysEnum.FRIDAY],
  [WeekDaysEnum.SUNDAY, WeekDaysEnum.SATURDAY]
]);

/**
 * Indicate Monday-Sunday as Javascript and
 * Monday-Sunday as Normal
 */
export enum DaysOfTheWeekOrder {
  Javascript,
  Normal
}

export class DaysOfTheWeekHelper {
  /**
   * Get week ordered in 2 ways: Monday-Sunday or Sunday-Saturday
   * @param type
   * @returns
   */
  static getDaysOfTheWeek(type: DaysOfTheWeekOrder) {
    return type === DaysOfTheWeekOrder.Javascript
      ? Array.from(daysOfTheWeekMap.values())
      : Array.from(daysOfTheWeekMap.keys());
  }
}
