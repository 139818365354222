





























import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/utility/Card.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { ConfigModule } from "@/store/modules/config";
import Tooltip from "@/components/utility/tooltip.vue";
import ErrorList from "@/components/utility/ErrorList.vue";
import { UserModule } from "@/store/modules/me-user.store";

@Component({
  components: {
    Card,
    LayoutSimple,
    Tooltip,
    ErrorList
  }
})
export default class NoRoles extends Vue {
  sendMail() {
    const mailText = `mailto:${ConfigModule.supportMail}`;

    window.open(mailText, "_blank");
  }

  backToLogin() {
    this.$router.push({ path: "/login" });
  }

  mounted() {
    UserModule.deactivateTokens();
    UserModule.removeUserTokens();
  }
}
