



















import { Component, Prop, Vue } from "vue-property-decorator";
import PdfViewer from "@/components/files/PdfViewer.vue";

@Component({
  components: {
    PdfViewer
  }
})
export default class PdfUploadDialog extends Vue {
  @Prop()
  isDialog!: boolean;

  @Prop()
  pdf!: File;

  closePdfDialog(data: any) {
    this.$emit("closePdfDialog", data);
  }

  onImageExport(data: any) {
    this.$emit("onImageExport", data);
  }
}
