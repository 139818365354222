









































































import InstructionCard from "@/components/instructions/InstructionCard.vue";
import Debug from "@/components/utility/Debug.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { ConfigModule } from "@/store/modules/config";
import { ErrorLogModule } from "@/store/modules/error-log";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

/**
 * Camera app component that handles the camera and image processing.
 * Emits the events: "image-taken" that is emitted when the "grab" function is executed and an image is taken.
 */
@Component({
  components: {
    InstructionCard,
    Debug
  }
})
export default class CameraApp extends Vue {
  public static EMITS = ["image-taken", "camera-error"];

  /**
   * The overlay asset.
   */
  @Prop()
  private readonly overlayAsset!: AssetEnum;

  /**
   * The instructions to display.
   */
  @Prop()
  private instruction!: IReportInstructionData;

  /**
   * Camera button color
   */
  @Prop({ default: ConfigModule.color.secondary })
  private color!: string;

  /**
   * Snackbar title.
   */
  @Prop({ default: "" })
  private snackbarTitle!: string;

  /**
   * Display the asset overlay.
   */
  displayOverlay = true;

  /**
   * Display the snackbar.
   */
  snackbar = true;

  /**
   * Display the dialog.
   */
  dialog = false;

  /**
   * Opened connections to webcam
   */
  private tracks: any[] = [];

  /**
   * The active track of the webcam
   */
  private activeTrack: any;

  private mediaQuery: MediaQueryList | null = null;

  isPortrait = true;

  @Ref("cameraView")
  private readonly cameraView!: HTMLVideoElement;

  @Ref("cameraSensor")
  private readonly cameraSensor!: HTMLCanvasElement;

  /**
   * The overlay image source. From the @see AssetRepository.
   */
  get overlaySrc() {
    return AssetRepository.getAsset(false, this.overlayAsset);
  }

  /**
   * Get the snackbar title or if empty returns the instruction title.
   */
  get snackbarTitleOrInstructionTitle() {
    return this.snackbarTitle || this.instruction.title;
  }

  /**
   * Loads the default camera position based on any given Video input media devices.
   * Otherwise displays an error toast.
   *
   * @description  starts the recording.
   * @see https://vuejs.org/v2/api/#mounted
   */
  async mounted() {
    let userMedia = null;
    try {
      // prompts the user for permission to use a media input which produces a MediaStream with tracks containing the requested types of media.
      userMedia = await navigator.mediaDevices.getUserMedia;
    } catch (error) {
      this.$log.error(error);
    }

    if (navigator.mediaDevices && userMedia) {
      await this.startRecording();
      this.mediaQuery = window.matchMedia("(orientation: portrait)");
      this.mediaQuery.addEventListener("change", this.handleOrientationChange);
    } else {
      this.throwCameraError();
    }
  }

  /**
   * Lifecycle Hook: Called when a kept-alive component is deactivated.
   *
   * @description Stops the recording.
   * @see https://vuejs.org/v2/api/#deactivated
   */
  deactivated() {
    this.$log.debug("deactivated");
    this.stopRecording();
  }

  /**
   * Lifecycle Hook: Called right before a Vue instance is destroyed.
   *
   * @description  Stops the recording.
   * @see https://vuejs.org/v2/api/#beforeDestroy
   */
  beforeDestroy() {
    this.$log.debug("beforeDestroy");
    this.stopRecording();

    if (this.mediaQuery) {
      this.mediaQuery.removeEventListener("change", this.handleOrientationChange);
    }
  }

  /**
   * Sets the isPortrait on orientation change
   * @param e the media query list
   */
  handleOrientationChange(e: MediaQueryListEvent) {
    this.isPortrait = e.matches;
  }

  /**
   * Takes a picture from the webcam.
   * emits "image-taken" with the image data.
   */
  grab() {
    this.$log.warn(this.cameraView.videoHeight, this.cameraView.videoWidth);

    this.cameraSensor.width = this.cameraView.videoWidth;
    this.cameraSensor.height = this.cameraView.videoHeight;

    const context = this.cameraSensor.getContext("2d");

    if (!context) {
      this.$log.error("CameraSensor context not found");
      return;
    }

    context.drawImage(this.cameraView, 0, 0);
    const dataUrl = this.cameraSensor.toDataURL("image/jpeg");

    if (!dataUrl) {
      this.$log.error("Error: Image could not be taken. Detail: DataURL empty.");
      return;
    }

    const blobBin = atob(dataUrl.split(",")[1]);
    const array = [];
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }

    const file = new File([new Uint8Array(array)], uuidv4(), { type: "image/jpeg" });

    if (!file) {
      this.$log.error("Error: Image could not be taken. Detail: Blob empty.");
      return;
    }

    this.$emit(CameraApp.EMITS[0], file);
  }

  /**
   * Loads a the default camera and starts a camera feed track.
   */
  public async startRecording() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    this.$log.debug("Media Devices: ", devices);

    const videoDevices = devices.filter(device => device.kind == "videoinput");
    this.$log.debug("Video Devices: ", videoDevices);

    /**
     * Video Constraint. Smartphone typically has 4/3 aspect ratio.
     * HD 16/9 min (webcam) and 4/3 WQHD (phone) is ideal
     *
     * Size 16/9 -> 4/3
     * HD 1920 x 1080 -> 1920 x 1440 or down sampled (4/3) 1440x1080
     * WQHD 2560 x 1440 --> 2560x1920 or down sampled (4/3) 1920x1440
     * 4K UHD 3840 x 2160 --> 3840 x 2880 or down sampled (4/3) 2880x2160
     */
    const constraints: MediaStreamConstraints = {
      video: {
        facingMode: "environment",
        width: { min: 1920, ideal: 2560 },
        height: { min: 1080, ideal: 1920 }
      },
      audio: false
    };
    this.$log.warn("Media Stream Constraints: ", constraints);

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        this.tracks = stream.getTracks();
        this.activeTrack = this.tracks[0];
        (this.cameraView as HTMLVideoElement).srcObject = stream;
      })
      .catch(error => {
        this.$log.error(error);

        if (error?.name === "OverconstrainedError") {
          // Camera resolution is too low.
          this.throwCameraError("components.camera.CameraApp.error.constraint");
          return;
        }
        this.throwCameraError("components.camera.CameraApp.error.camera");
      });

    this.$log.debug("Active Track: ", this.activeTrack);
    this.$log.debug("Video ELement: ", this.cameraView);
  }

  /**
   * Stops the image tracks.
   */
  public stopRecording() {
    if (this.tracks) {
      this.tracks.forEach(track => {
        try {
          track.stop();
        } catch (e) {
          this.$log.error("Error stopping track", e);
        }
      });
      this.$log.debug("All Tracks stopped");

      this.cameraView.srcObject = null;

      const context = this.cameraSensor.getContext("2d");
      if (context) {
        context.clearRect(0, 0, this.cameraSensor.width, this.cameraSensor.height);
        this.$log.debug("Context cleared");
      }
    }
  }

  /**
   * Sets the interactive camera to disabled (don't show overlay).
   * Log an error if the camera is not available.
   * Display an error toast to the user.
   * Emit camera-unavailable event.
   */
  private throwCameraError(text = "components.camera.CameraApp.error.mount") {
    ConfigModule.setIsCameraAvailable(false);

    ErrorLogModule.addErrorLog({
      name: this.$t(text).toString(),
      message: this.$t(text).toString()
    });

    this.$toast.error(this.$t(text));
    this.$emit(CameraApp.EMITS[1]);
  }
}
