/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Creates a report meta document.
 * The meta documents are used to correlate a report with a third party system (e.g. KSR).
 */
export enum ExportReportMetaTypeEnum {
  /**
   * Creates a new KSR job.
   */
  KSR_NEW = "ksrNew",

  /**
   * Adds to an existing KSR job.
   */
  KSR_EXISTING = "ksrExisting",

  /**
   * Adds to an existing DA job.
   */
  DA_EXISTING = "daExisting",

  /**
   * Notify unfallnavi in case customer wishes to get into contact with them
   */
  UNFALLNAVI = "unfallnavi"
}

export const ExportReportMetaTypeMap = new Map<ExportReportMetaTypeEnum, string>([
  [ExportReportMetaTypeEnum.KSR_NEW, "KSR"],
  [ExportReportMetaTypeEnum.KSR_EXISTING, "KSR"],
  [ExportReportMetaTypeEnum.DA_EXISTING, "DA"],
  [ExportReportMetaTypeEnum.UNFALLNAVI, "UNFALLNAVI"]
]);
