













import { Component, Vue } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";
import { LoginModule } from "@/store/modules/login.store";
import { noWhiteSpaceRule } from "@/lib/rules/noWhiteSpaceRule";

@Component({
  components: {}
})
export default class LoginComponentInputCode extends Vue {
  /**
   * get current value of input
   */
  get code() {
    return LoginModule.code;
  }

  /**
   * set current value of input
   */
  set code(code: string) {
    LoginModule.setCode(code);
  }

  /**
   * required validation
   */
  get requiredRules() {
    return [requiredRule(), noWhiteSpaceRule()];
  }

  /**
   * Get input label
   */
  get label() {
    return this.$t("components.login.LoginComponentInputCode.code");
  }

  /**
   * removes whitespace from mail
   */
  removeWhiteSpace() {
    this.code = this.code.replace(/\s/g, "");
  }

  /**
   * Prefill previous password using query/param
   */
  beforeMount() {
    if (this.$route.query.code) {
      this.code = String(this.$route.query.code);
    } else if (this.$route.params.code) {
      this.code = String(this.$route.params.code);
    }
  }
}
