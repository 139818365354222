var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "width": "328"
    }
  }, [_c('v-card-subtitle', {
    staticClass: "mr-3 pa-0"
  }, [_c('h4', [_c('span', [_vm._v(" " + _vm._s(_vm.getTokenText(_vm.documentToken.token)) + " ")]), !_vm.documentToken.token || !_vm.documentToken.isEditable && _vm.isEditValue && !_vm.documentToken.value && !_vm.getIsSignature(_vm.documentToken.token) ? _c('v-chip', {
    attrs: {
      "outlined": "",
      "color": "error"
    }
  }, [_c('v-icon', {
    staticClass: "mb-n1 mt-n1 ml-n1 mr-1",
    attrs: {
      "color": "error",
      "x-small": ""
    }
  }, [_vm._v(" mdi-alert ")]), _c('small', {
    staticClass: "mb-n1 mt-n1 mr-n1"
  }, [_vm._v(" " + _vm._s(_vm.$t("sign.DocumentTemplateDetailTokenList.inputMissing")) + " ")])], 1) : _vm._e()], 1)]), _vm.documentToken.title || _vm.documentToken.description ? _c('v-card-subtitle', {
    staticClass: "mr-3 pa-0"
  }, [_c('span', {
    domProps: {
      "textContent": _vm._s(_vm.documentToken.title)
    }
  }), _vm.documentToken.title && _vm.documentToken.description ? _c('span', {
    domProps: {
      "textContent": _vm._s('-')
    }
  }) : _vm._e(), _c('span', {
    domProps: {
      "textContent": _vm._s(_vm.documentToken.description)
    }
  })]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }