import { MrfiktivCustomFieldConfigurationElementDtoGen } from "@/services/mrfiktiv/v1/data-contracts";

class CustomFieldConfigurationElementBase implements MrfiktivCustomFieldConfigurationElementDtoGen {
  color: string;
  description?: string | undefined;
  value: string;

  constructor(config?: Partial<MrfiktivCustomFieldConfigurationElementDtoGen>) {
    this.color = config?.color ?? "";
    this.description = config?.description;
    this.value = config?.value ?? "";
  }
}

type ICustomFieldConfigurationElement = CustomFieldConfigurationElementBase;
const CustomFieldConfigurationElement = CustomFieldConfigurationElementBase;

export { ICustomFieldConfigurationElement, CustomFieldConfigurationElement };
