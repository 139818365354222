




import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";
import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
@Component({ components: { TheNavigationLayout } })
export default class TheThgAboutList extends Vue {
  color = this.$store.state.config.color;

  get isWhiteLabelPartner(): boolean {
    return PartnerModule?.partner.partnerType === PartnerTypeEnum.THG_WHITE_LABEL_QUOTE_AS_A_SERVICE;
  }

  get isGermany(): boolean {
    return PartnerModule?.partner.countryCode === CountryCodeEnum.germany;
  }

  get isAustria(): boolean {
    return PartnerModule?.partner.countryCode === CountryCodeEnum.austria;
  }

  get items(): IMenuListItem[] {
    const items: IMenuListItem[] = [];

    let termsLink = "/agbs";
    let target = "";
    if (
      PartnerModule.partner.settings?.agbUrl?.link &&
      ![AppContextEnum.THG_PORTAL, AppContextEnum.REPORT_PORTAL].includes(ConfigModule.appContext)
    ) {
      termsLink = PartnerModule.partner.settings?.agbUrl?.link;
      target = "_self";
    }

    items.push(
      {
        text: "navigation.TheAboutList.website",
        link: "https://www.wirkaufendeinethg.de/",
        target: "_blank",
        icon: "mdi-earth",
        isEnabled: !this.isWhiteLabelPartner
      },
      {
        text: "navigation.TheAboutList.faq",
        link: "/faq",
        icon: "mdi-frequently-asked-questions",
        isEnabled: this.isGermany
      },
      {
        text: "navigation.TheAboutList.faq",
        link: "/faqat",
        icon: "mdi-frequently-asked-questions",
        isEnabled: this.isAustria
      },
      {
        text: "navigation.TheAboutList.imprint",
        link: "/impressum",
        icon: "mdi-information-outline",
        isEnabled: true
      },
      {
        text: "navigation.TheAboutList.privacy",
        link: "/datenschutz",
        icon: "mdi-shield-sun-outline",
        isEnabled: true
      },
      {
        text: "navigation.TheAboutList.terms",
        link: termsLink,
        icon: "mdi-handshake-outline",
        target: target,
        isEnabled: true
      }
    );

    return items;
  }
}
