import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { INotification } from "../interface/notification.interface";

/**
 * Module to display notification messages.
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "notification",
  store
})
class NotificationStore extends VuexModule {
  /**
   * Notification store.
   */
  private _notifications: INotification[] = [];

  /**
   * indicates whether the notifications have already been loaded
   */
  private _isNotificationsLoaded = false;

  /**
   * indicates whether the notifications have already been loaded
   */
  get isNotificationsLoaded() {
    return this._isNotificationsLoaded;
  }

  /**
   * The notifications to be displayed
   */
  get notifications(): INotification[] {
    return this._notifications;
  }

  /**
   * Are there notifications to be displayed?
   */
  get isNotification(): boolean {
    return this.notifications.length >= 1;
  }

  /**
   * Changes the store.
   * @param notifications - Array of notifications
   */
  @Mutation
  _mutateNotification(notifications: INotification[]) {
    this._notifications = notifications;
  }

  /**
   * Changes the store.
   * @param isNotificationsLoaded - boolean
   */
  @Mutation
  _mutateIsNotificationsLoaded(isNotificationsLoaded: boolean) {
    this._isNotificationsLoaded = isNotificationsLoaded;
  }

  /**
   * Changes the flag isNotificationLoaded
   *
   * @param value new notification
   */
  @Action
  async setIsNotificationsLoaded(value: boolean) {
    this.context.commit("_mutateIsNotificationsLoaded", value);
  }

  /**
   * Adds a notification to the stack.
   *
   * @param value new notification
   */
  @Action
  async addNotification(value: INotification) {
    this.notifications.push(value);
    this.context.commit("_mutateNotification", this.notifications);
  }

  /**
   * Removes an notification from the store
   *
   * @param index Index of the notification
   */
  @Action
  async popNotification(index: number) {
    this.notifications.splice(index, 1);
    this.context.commit("_mutateNotification", this.notifications);
  }

  @Action
  clear() {
    this.context.commit("_mutateNotification", []);
  }
}

export const NotificationModule = getModule(NotificationStore);
