var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "max-width": "800",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('card', {
    attrs: {
      "flat": "",
      "outlined": "",
      "margin": 0,
      "title": _vm.$t('components.profile.EditProfileDialog.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          },
          on: {
            "click": _vm.closeDialog
          }
        }, [_vm._v("mdi-close")])], 1)];
      },
      proxy: true
    }])
  }, [_c('profile-form', {
    ref: "profileForm",
    attrs: {
      "userToUpdate": _vm.userToUpdate,
      "disabled": _vm.disabled
    },
    on: {
      "save": _vm.save,
      "update:disabled": function updateDisabled($event) {
        _vm.disabled = $event;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }