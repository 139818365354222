
import { SignDocumentTokenDtoGen } from "@/services/sign/v1/data-contracts";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class DocumentTemplateMixin extends Vue {
  readonly keyDelimiter = "~";

  get schemas() {
    return this.getSchemasFromDocumentTokens(DocumentTemplateModule.documentTokens);
  }

  getTokenFromKey(token: string) {
    return token.split(this.keyDelimiter)[0];
  }

  getKey(documentToken: SignDocumentTokenDtoGen, page: number) {
    return `${documentToken.token}${this.keyDelimiter}${page}-${documentToken.coordinates.x}-${documentToken.coordinates.y}`;
  }

  getSchemasFromDocumentTokens(documentTokens: SignDocumentTokenDtoGen[][]) {
    const schema: any[] = [];

    documentTokens?.forEach(documentTokensPage => {
      let schemaPage = {};

      let page = 0;
      for (const documentToken of documentTokensPage) {
        page++;
        schemaPage = {
          ...schemaPage,
          [this.getKey(documentToken, page)]: {
            type: "text",
            position: { x: documentToken.coordinates.x, y: documentToken.coordinates.y },
            width: documentToken.coordinates.w,
            height: documentToken.coordinates.h
          }
        };
      }

      schema.push(schemaPage);
    });

    return schema;
  }
}
