


























































































import { MenuActionOptionEnum } from "@/lib/enum/templateEnums/menuActionOptionEnum.enum";
import { MenuStyleOptionEnum } from "@/lib/enum/templateEnums/menuStyleOptionEnum";
import { PublicImageImageViewmodelGen } from "@/services/image/v1/data-contracts";
import { Editor, EditorContent } from "@tiptap/vue-2";
import { Component, Prop, Vue } from "vue-property-decorator";
import PublicImageUploadDialog from "../public-image/PublicImageUploadDialog.vue";
import Card from "../utility/Card.vue";
import TemplateDialogPasteUrl from "./TemplateDialogPasteUrl.vue";
import TemplateEditorActionDivider from "./TemplateEditorActionDivider.vue";
import TemplateEditorAddPlaceholder from "./TemplateEditorAddPlaceholder.vue";
import { PublicImageFolderEnum } from "@/store/enum/public-image/publicImageFolderEnum";

export enum TemplateActionSpecialItemType {
  "LINE",
  "ADD_PLACEHOLDER"
}
export interface IDisplayIcon {
  icon: string;
}
export interface IDisplayText {
  text: string;
}
export interface IDisplayColor {
  color: string;
}
export interface IActionSpecialItem {
  item: TemplateActionSpecialItemType;
}
export interface ITemplateSublistConfig {
  sublist: ITemplateEditorConfig[];
  display: IDisplayIcon | IDisplayText | IDisplayColor;
}
export interface ITemplateActionConfig {
  content: MenuStyleOptionEnum | MenuActionOptionEnum;
  param?: any;
  display: IDisplayIcon | IDisplayText | IDisplayColor;
}
export type ITemplateEditorConfig = IActionSpecialItem | ITemplateSublistConfig | ITemplateActionConfig;

@Component({
  name: "TemplateEditorActions",
  components: {
    EditorContent,
    Card,
    TemplateEditorActionDivider,
    TemplateEditorAddPlaceholder,
    TemplateDialogPasteUrl,
    PublicImageUploadDialog
  }
})
export default class TemplateEditorActions extends Vue {
  menu = true;
  get TemplateActionSpecialItemType() {
    return TemplateActionSpecialItemType;
  }
  @Prop()
  editConfig!: ITemplateEditorConfig[];

  @Prop()
  partnerId!: string;

  @Prop()
  editor!: Editor;

  @Prop()
  hideOutline!: boolean;

  @Prop({ default: true })
  root!: boolean;

  sublist: ITemplateEditorConfig[] = [];

  imageUpload = false;

  clickedIndex = -1;

  get folderName() {
    return PublicImageFolderEnum.MESSAGE;
  }

  /**
   * executed on image upload
   */
  upload(image: PublicImageImageViewmodelGen) {
    this.$log.debug(JSON.stringify(image));
    if (image && image.cdnUrl && this.onImageUpload) {
      this.onImageUpload(image.cdnUrl);
    }
  }

  /**
   * Callback executed on image upload
   */
  onImageUpload?: (url: string) => void;

  /**
   *
   * @param callback the method that is executed once the image was uploaded
   */
  startImageUpload(callback: (url: string) => void) {
    this.imageUpload = true;
    this.onImageUpload = callback;
    (this.$refs.publicImageUploadDialog as PublicImageUploadDialog)?.initialize();
  }

  resetSublist() {
    const refs = this.$refs.sublist as TemplateEditorActions | TemplateEditorActions[];

    if (refs instanceof Array) {
      refs.forEach(ref => ref.resetSublist());
    } else {
      refs?.resetSublist();
    }

    this.sublist.splice(0, this.sublist.length);
  }

  /**
   * executed on url paste
   */
  paste(url: string) {
    if (this.onPaste) {
      this.onPaste(url);
    }
  }

  /**
   * callback executed on url paste
   */
  onPaste?: (url: string) => void;

  /**
   * @param callback the method that is executed once the url was pasted
   */
  startUrlUpload(callback: (url: string) => void) {
    this.onPaste = callback;
    (this.$refs.pasteImageDialog as TemplateDialogPasteUrl)?.initialize();
  }

  isActiveSublist(sublist: ITemplateEditorConfig[]): boolean {
    if (this.sublist?.length !== sublist.length) {
      return false;
    }

    for (const sublistItemIndex in this.sublist) {
      const activeItem = this.sublist[sublistItemIndex];
      const sublistItem = sublist[sublistItemIndex];

      if (activeItem !== sublistItem) {
        return false;
      }
    }

    return true;
  }

  updateSublist(editConfig: ITemplateSublistConfig) {
    const isActive = this.isActiveSublist(editConfig.sublist);

    this.resetSublist();
    if (!isActive) {
      this.sublist.splice(0, this.sublist.length, ...editConfig.sublist);
    }
  }

  click(index: number, editConfig?: ITemplateEditorConfig) {
    this.clickedIndex = index;
    const templateSublistConfig = editConfig as ITemplateSublistConfig;
    if (templateSublistConfig.sublist) {
      this.updateSublist(templateSublistConfig);
    }

    const templateActionConfig = editConfig as ITemplateActionConfig;
    if (templateActionConfig?.content) {
      this.bubbleClick(editConfig);
    }
  }

  bubbleClick(editConfig?: ITemplateEditorConfig) {
    this.$emit("click", editConfig);
  }

  isActionButtonActive(menuOption: MenuStyleOptionEnum | MenuActionOptionEnum | undefined) {
    if (!menuOption) {
      return false;
    }

    if (Object.values(MenuStyleOptionEnum).includes(menuOption as MenuStyleOptionEnum)) {
      return true;
    }

    if (!this.editor || !Object.values(MenuActionOptionEnum).includes(menuOption as MenuActionOptionEnum)) {
      return true;
    }

    return this.editor.can()[menuOption.toLowerCase()]();
  }

  menuSublistButtonColor(editConfig?: ITemplateEditorConfig): string | undefined {
    const templateActionConfig = editConfig as ITemplateActionConfig;
    if (
      templateActionConfig &&
      templateActionConfig.content &&
      this.isActionButtonActive(templateActionConfig.content)
    ) {
      return this.isActionButtonActive(templateActionConfig.content);
    }

    const templateSublistConfig = editConfig as ITemplateSublistConfig;
    for (const element of templateSublistConfig?.sublist || []) {
      const templateActionConfig = element as ITemplateActionConfig;
      if (templateActionConfig.content && this.menuStyleButtonColor(templateActionConfig)) {
        return this.menuStyleButtonColor(templateActionConfig);
      }

      const templateSublistConfig = element as ITemplateSublistConfig;
      if (templateSublistConfig.sublist && this.menuSublistButtonColor(templateSublistConfig)) {
        return this.menuSublistButtonColor(templateSublistConfig);
      }
    }
  }

  menuStyleButtonColor(editConfig?: ITemplateActionConfig): string | undefined {
    if (!editConfig || !editConfig.content) {
      return "";
    }

    if (!this.editor || !Object.values(MenuStyleOptionEnum).includes(editConfig.content as MenuStyleOptionEnum)) {
      return "";
    }

    if (this.isActive(editConfig)) {
      return "info";
    }
  }

  /**
   * unsetColor -> color
   * toggleStrike -> strike
   *
   * @param content
   */
  mapOptionToNodeName(content: string) {
    content = content.replace("unset", "");
    content = content.replace("set", "");
    content = content.replace("toggle", "");

    const start = content[0]?.toLocaleLowerCase();
    const end = content.substring(1);

    return start + end;
  }

  /**
   * Hack to find out which stylings are active.
   * usually this can be checked by calling this.editor.isActive(bold) or something like that.
   * We can quite easily derive this from the used operation. in case of fontFamily nodes this is a bit more complicated.
   * hence this is hacked a little bit here
   *
   * @param editConfig
   */
  isActive(editConfig: ITemplateActionConfig) {
    if (!editConfig.content) {
      return false;
    }
    const nodeName = this.mapOptionToNodeName(editConfig.content);
    if (nodeName === "color") {
      return this.editor.getAttributes("textStyle").color === editConfig.param;
    }
    if (nodeName === "fontFamily") {
      if (!editConfig.param) {
        return false;
      }
      return this.editor.getAttributes("textStyle").fontFamily === editConfig.param;
    }
    if (nodeName === "textAlign") {
      return this.editor.isActive({ textAlign: editConfig.param });
    }

    return this.editor.isActive(nodeName, editConfig.param);
  }

  addPlaceholder(ph: string) {
    this.$emit("addPlaceholder", ph);
  }
}
