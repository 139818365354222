import { IManufacturerDto } from "@/lib/interfaces/Docphant/manufacturer.dto.interface";
import { IManufacturer } from "@/lib/interfaces/Docphant/manufacturer.schema.interface";
import { MrfiktivHttpClientProvider as Api } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import Vue from "vue";

/**
 * Communicates with docphant backend
 */
class ManufacturerService {
  ROUTE = "docphant";

  /**
   * Gets machine document from docphant backend
   * @returns a manufacturer document
   */
  async getOne(id: string): Promise<IManufacturer> {
    const api = new Api().instance();
    let c: any;
    try {
      const response = await api.get(`/${this.ROUTE}/manufacturer/${id}/`);
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return c;
  }

  async delete(id: string): Promise<boolean> {
    const api = new Api().instance();
    try {
      await api.delete(`/${this.ROUTE}/manufacturer/${id}/`);
      return true;
    } catch (error) {
      Vue.$log.error(error);
      return false;
    }
  }

  async create(newManufacturer: IManufacturerDto): Promise<IManufacturer> {
    const api = new Api().instance();
    let c: any;
    try {
      const response = await api.post(`/${this.ROUTE}/manufacturer/`, newManufacturer);
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      Vue.$toast.error("Could not create Manufacturer.");
      throw error;
    }
    return c;
  }

  async update(manufacturerId: string, newManufacturer: IManufacturerDto): Promise<IManufacturer> {
    const api = new Api().instance();
    let c: any;
    try {
      const response = await await api.patch(`/${this.ROUTE}/manufacturer/${manufacturerId}/`, newManufacturer);
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }
    return c;
  }
  /**
   * Gets machine document from docphant backend
   * @returns a manufacturer document
   */
  async getAll(): Promise<IManufacturer[]> {
    const api = new Api().instance();
    let c: any;
    try {
      const response = await api.get(`/${this.ROUTE}/manufacturer/`);
      c = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }
    return c;
  }
}

export default new ManufacturerService();
