import { Module, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store/VuexPlugin";
import {
  MrfiktivCustomerAccountViewModelGen,
  MrfiktivCustomerAccountMeControllerFindAllParamsGen,
  MrfiktivCreateCustomerAccountDtoGen,
  MrfiktivUpdateCustomerAccountDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import customerAccountMeService from "@/services/shared/customerAccountMeService";
import { PaginationFilterListElement, BasePagination } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { PartnerModule } from "./partner";
import { ReportModule } from "./report.store";
import { UserModule } from "./me-user.store";
import Vue from "vue";
import { ConfigModule } from "./config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";

/**
 * Store for the end user customer account data.
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "customerAccountMeStore",
  store
})
export class CustomerAccountMeStore extends BasePagination<
  MrfiktivCustomerAccountViewModelGen,
  MrfiktivCustomerAccountMeControllerFindAllParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: MrfiktivCustomerAccountViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = true;

  filterOptions: PaginationFilterListElement[] = [
    { key: "_id", type: PageFilterTypes.OBJECT_ID },
    { key: "userId", type: PageFilterTypes.OBJECT_ID },
    { key: "timestamp.created", type: PageFilterTypes.DATE },
    { key: "isActive", type: PageFilterTypes.BOOLEAN },
    { key: "isMarketingOptIn", type: PageFilterTypes.BOOLEAN }
  ].map(f => new PaginationFilterListElement(f));

  /**
   * Customer account with the current partner
   */
  _currentAccount: MrfiktivCustomerAccountViewModelGen | null = null;

  /**
   * All of the accounts of the user across partners
   */
  get accounts() {
    return this._paginationList;
  }

  get currentAccount() {
    return this._currentAccount;
  }

  get partnerId() {
    return ReportModule.partner._id || PartnerModule.partner._id;
  }

  get isEnabled() {
    return ConfigModule.appContext === AppContextEnum.REPORT;
  }

  @Mutation
  private _mutateCurrentAccount(account: MrfiktivCustomerAccountViewModelGen) {
    this._currentAccount = account;
  }

  @Action
  protected async loadDocuments(query: MrfiktivCustomerAccountMeControllerFindAllParamsGen) {
    const res = await customerAccountMeService.findAll({ ...query });

    return res;
  }

  @Action
  async getOne() {
    const account = await customerAccountMeService.findOne(this.partnerId);
    this.context.commit("_mutateCurrentAccount", account);
    return account;
  }

  @Action
  removeCurrentAccount() {
    this.context.commit("_mutateCurrentAccount", {} as MrfiktivCustomerAccountViewModelGen);
  }

  @Action
  async create(data: Partial<MrfiktivCreateCustomerAccountDtoGen>) {
    if (!UserModule.isAuthenticated) {
      throw new Error("User not signed in");
    }

    const dataWithPartnerId = { ...data, partnerId: this.partnerId };

    const createdAccount = await customerAccountMeService.create(dataWithPartnerId);
    this.context.commit("_mutateCurrentAccount", createdAccount);
    return createdAccount;
  }

  @Action
  async update(data: MrfiktivUpdateCustomerAccountDtoGen) {
    /** If we don't have the current account, try to fetch it again */
    if (!this.currentAccount || Object.keys(this.currentAccount).length === 0) {
      try {
        await this.getOne();
      } catch (error) {
        Vue.$log.error(error);
      }
    }

    if (!this.currentAccount) {
      throw new Error("No current account");
    }

    const updatedAccount = await customerAccountMeService.update(this.currentAccount.id, data);
    this.context.commit("_mutateCurrentAccount", updatedAccount);
    return updatedAccount;
  }
}

export const CustomerAccountMeModule = getModule(CustomerAccountMeStore);
