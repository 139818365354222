import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "../enum/appContext.enum";
import { MissingInformationHandler } from "./MissingUserInformationHandler";
import { ReportNotificationHandler } from "./ReportNotificationHandler";

/**
 * Class to decide which notification handler to use.
 * For THG we check things which are not applicable in the report app.
 * TODO(?): Rename to ReportNotificationHandler and ThgNotificationHandler.
 */
export class NotificationHandler {
  static async check() {
    if (ConfigModule.appContext === AppContextEnum.REPORT) {
      return ReportNotificationHandler.check();
    } else if (ConfigModule.appContext === AppContextEnum.THG) {
      return MissingInformationHandler.check();
    }
  }
}
