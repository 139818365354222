/**
 * All of the seven days of the week
 */
export enum WeekDaysEnum {
  /**
   * light
   */
  MONDAY = "monday",
  /**
   * sky
   */
  TUESDAY = "tuesday",
  /**
   * dry land, seas, plants, trees
   */
  WEDNESDAY = "wednesday",
  /**
   * Sun, moon and stars
   */
  THURSDAY = "thursday",
  /**
   * fishes and birds
   */
  FRIDAY = "friday",
  /**
   * land animals, humans
   */
  SATURDAY = "saturday",
  /**
   * Rest day
   */
  SUNDAY = "sunday"
}
