import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { config } from "vuex-module-decorators";

Vue.use(Vuex);

// Set rawError to true by default on all @Action decorators
config.rawError = true;

// Reducer defines which state items are persistet in localstorage. The object returned by reducer is synced with localStorage.
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: any) => {
    return {
      state: state.userStore
    };
  }
});

const vuexLocalFeature = new VuexPersistence({
  key: "feature",
  storage: window.localStorage,
  reducer: (state: any) => {
    return {
      state: state.feature
    };
  }
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  plugins: [vuexLocal.plugin, vuexLocalFeature.plugin]
});
