

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class LoginComponentSubmitButton extends Vue {
  @Prop()
  component!: string;

  @Prop()
  isFormValid!: string;

  @Prop({ default: false })
  isLoading?: boolean;
}
