/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgCreatePartnerTemplateDtoGen,
  ThgPartnerTemplateViewModelGen,
  ThgRenderedTemplateViewModelGen,
  ThgRenderTemplateDtoGen,
  ThgUpdatePartnerTemplateDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerTemplate<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags partner-template
   * @name PartnerTemplateControllerGetAll
   * @summary (Partner) Get all templates that are available for the partner
   * @request GET:/partner/{partnerId}/template
   * @secure
   * @response `200` `(ThgPartnerTemplateViewModelGen)[]` The found ressources
   */
  partnerTemplateControllerGetAll = (partnerId: string, params: RequestParams = {}) =>
    this.http.request<ThgPartnerTemplateViewModelGen[], any>({
      path: `/partner/${partnerId}/template`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-template
   * @name PartnerTemplateControllerCreate
   * @summary (Partner) Post a partner template
   * @request POST:/partner/{partnerId}/template
   * @secure
   * @response `201` `ThgPartnerTemplateViewModelGen` The created resource
   */
  partnerTemplateControllerCreate = (
    partnerId: string,
    data: ThgCreatePartnerTemplateDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPartnerTemplateViewModelGen, any>({
      path: `/partner/${partnerId}/template`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-template
   * @name PartnerTemplateControllerGetByKey
   * @summary (Partner) Get a template by key
   * @request GET:/partner/{partnerId}/template/{key}
   * @secure
   * @response `200` `ThgPartnerTemplateViewModelGen` The found resource
   */
  partnerTemplateControllerGetByKey = (partnerId: string, key: string, params: RequestParams = {}) =>
    this.http.request<ThgPartnerTemplateViewModelGen, any>({
      path: `/partner/${partnerId}/template/${key}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-template
   * @name PartnerTemplateControllerUpdate
   * @summary (Partner) Update a partner template
   * @request PATCH:/partner/{partnerId}/template/{key}
   * @secure
   * @response `200` `ThgPartnerTemplateViewModelGen` The updated resource
   */
  partnerTemplateControllerUpdate = (
    partnerId: string,
    key: string,
    data: ThgUpdatePartnerTemplateDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPartnerTemplateViewModelGen, any>({
      path: `/partner/${partnerId}/template/${key}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-template
   * @name PartnerTemplateControllerDelete
   * @summary (Partner) Delete a partner template by key
   * @request DELETE:/partner/{partnerId}/template/{key}
   * @secure
   * @response `200` `ThgPartnerTemplateViewModelGen` The deleted resource
   */
  partnerTemplateControllerDelete = (partnerId: string, key: string, params: RequestParams = {}) =>
    this.http.request<ThgPartnerTemplateViewModelGen, any>({
      path: `/partner/${partnerId}/template/${key}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags partner-template
   * @name PartnerTemplateControllerRender
   * @summary (Partner) Render a partner template
   * @request POST:/partner/{partnerId}/template/{key}/render
   * @secure
   * @response `201` `ThgRenderedTemplateViewModelGen` The rendered template
   */
  partnerTemplateControllerRender = (
    partnerId: string,
    key: string,
    data: ThgRenderTemplateDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgRenderedTemplateViewModelGen, any>({
      path: `/partner/${partnerId}/template/${key}/render`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
