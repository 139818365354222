










import { Component, Vue } from "vue-property-decorator";

import LayoutSimple from "@/layouts/LayoutSimple.vue";
import ErrorList from "@/components/utility/ErrorList.vue";

@Component({
  components: { LayoutSimple, ErrorList }
})
export default class Error extends Vue {}
