import { ArgumentException } from "@/lib/exceptions/ArgumentException";
import { Utils } from "@/lib/utility/utils";
import { FormEntity } from "@/models/formEntity";
import { PartnerContactEntity } from "@/models/partnerContactEntity";
import { PartnerEntity } from "@/models/partnerEntity";
import { RatingEntity } from "@/models/ratingEntitity";
import { RegistrationResultsEntity } from "@/models/registrationResultsEntity";
import { IReport } from "@/models/report.entity";
import { AxiosInstanceProvider } from "@/services/axios-instance.provider";
import { EmptyTokenProvider } from "@/services/empty-token.provider";
import { MrfiktivHttpClientProvider as Api } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { IPartnerUpdateDto } from "@/store/interface/partner-update.dto.interface";
import { IUpdateProgressStatusDto } from "@/store/interface/update-progress-status-dto.interface";
import { ConfigModule } from "@/store/modules/config";
import { AxiosInstance } from "axios";
import { MrfiktivCreatePartnerDtoGen } from "../v1/data-contracts";

/**
 * The service to communicate with the partner backend.
 */
class PartnerService {
  ROUTE = "partner";
  /**
   * The axios instance.
   */
  api: AxiosInstance;

  /**
   * @class Initialize PartnerService.
   */
  constructor(axiosInstance: AxiosInstance) {
    if (!axiosInstance) throw new ArgumentException(axiosInstance);
    this.api = axiosInstance;
  }

  async contact(partnerContact: PartnerContactEntity, partnerName: string): Promise<any> {
    const formData = new FormData();
    Utils.buildFormData(formData, partnerContact);

    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    return await this.api.post(`/${this.ROUTE}/${partnerName}/contact`, formData, config);
  }

  async getPartnerByName(partnerName: string): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :()

    /**
     *   The by name route is unprotected, so we do not need an auth token. If we send it anyways, this can lead to undesired sideeffects
     *   If a user that is signed in with an expired auth AND refresh token tries to sign in, the TokenProvider would throw an error and the app would not load the partner
     *   However the partner should be loaded still because otherwise we can't show the user the proper logo and texts
     */
    const refreshClientProvider = new AxiosInstanceProvider(new EmptyTokenProvider(), ConfigModule.mrfiktivBaseUrl);
    const api = refreshClientProvider.create();

    return await api.get(`/partner/byName?partnerName=${partnerName}`);
  }

  async getPartnerByHost(host: string): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(

    /**
     *   The by name route is unprotected, so we do not need an auth token. If we send it anyways, this can lead to undesired sideeffects
     *   If a user that is signed in with an expired auth AND refresh token tries to sign in, the TokenProvider would throw an error and the app would not load the partner
     *   However the partner should be loaded still because otherwise we can't show the user the proper logo and texts
     */
    const refreshClientProvider = new AxiosInstanceProvider(new EmptyTokenProvider(), ConfigModule.mrfiktivBaseUrl);
    const api = refreshClientProvider.create();

    return await api.get(`/partner/byUrl?url=${host}`);
  }

  async addRatingForPartner(partnerId: string, rating: RatingEntity): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    return await this.api.post(`/partner/${partnerId}/rating`, rating);
  }

  async getPartnerById(partnerId: string): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    return await this.api.get(`/partner/${partnerId}`);
  }

  async getAllPartners(): Promise<PartnerEntity[]> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.get("/partner");

    const partnerList: PartnerEntity[] = [];
    for (let i = 0; i < response.data.length; i++) {
      partnerList.push(response.data[i]);
    }

    return partnerList;
  }

  async create(partner: MrfiktivCreatePartnerDtoGen): Promise<PartnerEntity> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.post("/partner", partner);
    return response.data;
  }

  async updatePartner(partnerUpdateDto: IPartnerUpdateDto): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    return await this.api.patch(`/partner/${partnerUpdateDto.id}`, partnerUpdateDto.partnerUpdateEntity);
  }

  async deletePartner(partnerId: string): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    return await this.api.delete(`/partner/${partnerId}`);
  }

  // ### FORM ###

  async getAllFormsForPartner(partnerId: string): Promise<FormEntity[]> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.get(`/partner/${partnerId}/contact`);
    return response.data as FormEntity[];
  }

  async getFormById(partnerId: string, formId: string): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.get(`/partner/${partnerId}/contact/${formId}`);
    return response.data as FormEntity;
  }

  async getExportForFormById(partnerId: string, formId: string, exportType: string): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.get(`/partner/${partnerId}/contact/${formId}/export/${exportType}`);
    return response;
  }

  // ### REPORT ###

  async getAllReportsForPartner(partnerId: string): Promise<IReport[]> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.get(`/partner/${partnerId}/report`);
    return response.data as IReport[];
  }

  async getReportById(partnerId: string, reportId: string): Promise<IReport> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.get(`/partner/${partnerId}/report/${reportId}`);

    return response.data as IReport;
  }

  async getExportForReportById(partnerId: string, reportId: string, exportType: string): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.get(`/partner/${partnerId}/report/${reportId}/export/${exportType}`);

    return response;
  }

  async addRegistrationDetailsForForm(
    partnerId: string,
    formId: string,
    registrationResult: RegistrationResultsEntity
  ): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.post(`/partner/${partnerId}/contact/${formId}/registration`, registrationResult);
    return response.data as FormEntity;
  }

  async addRegistrationDetailsForReport(
    partnerId: string,
    reportId: string,
    registrationResult: RegistrationResultsEntity
  ): Promise<any> {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.post(`/partner/${partnerId}/report/${reportId}/registration`, registrationResult);
    return response.data as IReport;
  }

  async updateProgressStatus(partnerId: string, reportId: string, updateProgressStatusDto: IUpdateProgressStatusDto) {
    // FIXME: This needs to be fixed because of the THG logic :(
    this.api = new Api().instance();
    const response = await this.api.patch(`/partner/${partnerId}/report/${reportId}/status`, updateProgressStatusDto);
    return response.data;
  }
}

export default new PartnerService(new Api().instance());
