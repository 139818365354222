


















































































import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import ProfileMenu from "@/components/profile/ProfileMenu.vue";
import TheNavigationBase from "@/layouts/TheNavigationBase.vue";
import { AssetRepository } from "@/lib/AssetRepository";
import eventBus from "@/lib/eventBus";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ConfigModule } from "@/store/modules/config";
import { NotificationModule } from "@/store/modules/notification.store";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { UiModule } from "@/store/modules/ui.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Prop } from "vue-property-decorator";
import ThePartnerSelectorList from "./navigation/ThePartnerSelectorList.vue";
import QuickLinkMenu from "./navigation/QuickLinkMenu.vue";
import { NavigationModule } from "@/store/modules/navigation.store";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { FeatureModule } from "@/store/modules/feature.store";

@Component({
  components: {
    ThePartnerSelectorList,
    TheNavigationBase,
    PartnerFooter,
    ProfileMenu,
    QuickLinkMenu
  }
})
export default class TheLayoutBase extends PermissionMixin {
  @Prop({ default: "white" })
  backgroundColor!: string;

  @Prop({ default: false })
  showLoginButton!: boolean;

  @Prop({ default: "grey darken-3" })
  backgroundDarkColor!: string;

  @Prop({ default: 0 })
  progress!: number;

  @Prop({ default: true })
  showFooter!: boolean;

  @Prop({ default: false })
  displayDivider!: boolean;

  @Prop({})
  headerLogo?: string;

  @Prop({ default: false })
  isEndUser!: boolean;

  get userLoaded() {
    return UserModule.user._id;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get isQuicklinks(): boolean {
    return NavigationModule.navigationConfiguration.isQuicklinks;
  }

  get isProd() {
    return ConfigModule.isProd;
  }

  get navigationSettings() {
    return ConfigModule.navigationSettings;
  }

  get partner() {
    return ReportModule.partner;
  }

  get partners() {
    return PartnerModule.partners;
  }

  set drawer(val: any) {
    UiModule.setDrawer(val);
  }

  get drawer() {
    return UiModule.drawer;
  }

  get notificationCount() {
    return NotificationModule.notifications.length;
  }

  get isHeadless() {
    return ConfigModule.headless;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get displayPartnerName() {
    if (!this.isAuthenticated) {
      return false;
    }

    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return false;
      case "sm":
        return false;
      case "md":
        return true;
      case "lg":
        return true;
      case "xl":
        return true;
    }

    return false;
  }

  get breadcrumbs() {
    return [
      {
        text: this.companyName,
        disabled: true
      }
    ];
  }

  get companyName() {
    return PartnerModule.partner?.companyName || "";
  }

  toggleDrawer() {
    UiModule.setDrawer(!UiModule.drawer);
  }

  goToLogin() {
    this.$router.push({ path: "/login" });
  }

  logoClick() {
    this.$emit("logoClick", true);
    this.$log.info("Logo clicked");
  }

  /**
   * Try to set the Header Logo by priority
   *
   */
  get tryHeaderLogo() {
    if (this.headerLogo) {
      return this.headerLogo;
    }

    if (this.partner.settings?.reportSettings?.logoUrl) {
      return this.partner.settings?.reportSettings?.logoUrl;
    }

    if (this.partner.settings?.logoUrl) {
      return this.partner.settings?.logoUrl;
    }

    return this.logoUrl;
  }

  get logoUrl() {
    return AssetRepository.getAsset(ConfigModule.darkMode, ConfigModule._portalLogo);
  }

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get isNotification() {
    return NotificationModule.isNotification;
  }

  notificationButtonClicked(event: any) {
    eventBus.$emit("notificationButtonClicked", event);
    this.$router.push({ name: "ThgOverview" });
  }

  get isInboxEnabled() {
    return (
      [AppContextEnum.THG_PORTAL, AppContextEnum.REPORT_PORTAL].includes(ConfigModule.appContext) &&
      FeatureModule.isInboxEnabled
    );
  }

  goToInbox() {
    this.$router.push({ name: "PartnerMessages" });
  }

  items = [
    {
      text: "Login",
      disabled: false,
      href: "#/login"
    },
    {
      text: "Impressum",
      disabled: false,
      href: "#/impressum"
    },
    {
      text: "Datenschutz",
      disabled: false,
      href: "#/datenschutz"
    },
    {
      text: "AGB",
      disabled: false,
      href: "#/agbs"
    }
  ];
}
