/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * @see https://alternative-fuels-observatory.ec.europa.eu/general-information/vehicle-types
 */
export enum VehicleTypes {
  /**
   * Power-driven vehicles having at least four wheels and used for the carriage of goods
   */
  "N" = "N",

  /**
   * light commercial vehicles
   */
  "N1" = "N1",

  /**
   * large goods vehicle
   */
  "N2" = "N2",

  /**
   * larger large goods vehicle
   */
  "N3" = "N3",

  /**
   * Power-driven vehicles having at least four wheels and used for the carriage of passengers.
   */
  "M" = "M",

  /**
   * Vehicles used for the carriage of passengers and comprising not more than eight seats in addition to the driver's seat.
   */
  "M1" = "M1",

  /**
   * Vehicles used for the carriage of passengers, comprising more than eight seats in addition to the driver's seat, and having a maximum mass not exceeding 5 tonnes.
   */
  "M2" = "M2",

  /**
   * Vehicles used for the carriage of passengers, comprising more than eight seats in addition to the driver's seat, and having a maximum mass exceeding 5 tonnes.
   */
  "M3" = "M3",

  /**
   * Trailers
   */
  "O" = "O",

  /**
   * Trailers with a maximum mass not exceeding 0.75 tonnes.
   */
  "O1" = "O1",

  /**
   * Trailers with a maximum mass exceeding 0.75 tonnes, but not exceeding 3.5 tonnes.
   */
  "O2" = "O2",

  /**
   * Trailers with a maximum mass exceeding 3.5 tonnes, but not exceeding 10 tonnes.
   */
  "O3" = "O3",

  /**
   * Trailers with a maximum mass exceeding 10 tonnes.
   */
  "O4" = "O4",

  /**
   * Motor vehicles with less than four wheels and some lightweight four-wheelers.
   */
  "L" = "L",

  /**
   * A two-wheeled vehicle with an engine cylinder capacity in the case of a thermic engine not exceeding 50 cm3 and whatever the means of propulsion a maximum design speed not exceeding 50 km/h.
   */
  "L1" = "L1",

  /**
   * A three-wheeled vehicle of any wheel arrangement with an engine cylinder capacity in the case of a thermic engine not exceeding 50 cm3 and whatever the means of propulsion a maximum design speed not exceeding 50 km/h.
   */
  "L2" = "L2",

  /**
   * A two-wheeled vehicle with an engine cylinder capacity in the case of a thermic engine exceeding 50 cm3 or whatever the means of propulsion a maximum design speed exceeding 50 km/h.
   */
  "L3" = "L3",

  /**
   * A vehicle with three wheels asymmetrically arranged in relation to the longitudinal median plane with an engine cylinder capacity in the case of a thermic engine exceeding 50 cm3 or whatever the means of propulsion a maximum design speed exceeding 50 km/h (motor cycles with sidecars).
   */
  "L4" = "L4",

  /**
   * A vehicle with three wheels symmetrically arranged in relation to the longitudinal median plane with an engine cylinder capacity in the case of a thermic engine exceeding 50 cm3 or whatever the means of propulsion a maximum design speed exceeding 50 km/h.
   */
  "L5" = "L5",

  /**
   * A vehicle with four wheels whose unladen mass is not more than 350 kg, not including the mass of the batteries in case of electric vehicles, whose maximum design speed is not more than 45 km/h, and whose engine cylinder capacity does not exceed 50 cm3 for spark (positive) ignition engines, or whose maximum net power output does not exceed 4 kW in the case of other internal combustion engines, or whose maximum continuous rated power does not exceed 4 kW in the case of electric engines.
   */
  "L6" = "L6",

  /**
   * Motorised, wheeled or tracked agricultural or forestry vehicle having at least two axles and a maximum design speed of not less than 6 km/h, the main function of which lies in its tractive power and which has been especially designed to pull, push, carry and actuate certain interchangeable equipment designed to perform agricultural or forestry work, or to tow agricultural or forestry trailers or equipment; it may be adapted to carry a load in the context of agricultural or forestry work and/or may be equipped with one or more passenger seats.
   */
  "T" = "T",

  /**
   * Agricultural trailer: means any agricultural or forestry vehicle intended mainly to be towed by a tractor and intended mainly to carry loads or to process materials and where the ratio of the technically permissible maximum laden mass to the unladen mass of that vehicle is equal to or greater than 3.0.
   */
  "R" = "R",

  /**
   * Interchangeable towed equipment: means any vehicle used in agriculture or forestry which is designed to be towed by a tractor, changes or adds to its functions, permanently incorporates an implement or is designed to process materials, which may include a load platform designed and constructed to receive any tools and appliances needed for those purposes and to store temporarily any materials produced or needed during work and where the ratio of the technically permissible maximum laden mass to the unladen mass of that vehicle is less than 3.0.
   */
  "S" = "S",

  /**
   * Off-road vehicles.
   */
  "G" = "G",

  /**
   * Other
   */
  "other" = "other"
}
