


















import { Component, Mixins } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";
import { MrfiktivUserAuthDtoGen as UserAuthDto } from "@/services/mrfiktiv/v1/data-contracts";
import LoginMixin, { IFailureCodeContent } from "@/mixins/LoginMixin.vue";
import LoginComponentInputMail from "@/components/login/LoginComponentInputMail.vue";
import LoginComponentInputPassword from "@/components/login/LoginComponentInputPassword.vue";
import LoginComponentMessage from "@/components/login/LoginComponentMessage.vue";
import LoginComponentNavigationButton from "@/components/login/LoginComponentNavigationButton.vue";
import LoginComponentSubmitButton from "@/components/login/LoginComponentSubmitButton.vue";
import LoginComponentLayout from "@/components/login/LoginComponentLayout.vue";
import LoginErrorMixin from "@/mixins/LoginErrorMixin.vue";
import { ConfigModule } from "@/store/modules/config";
import { AppContextEnum } from "@/lib/enum/appContext.enum";

@Component({
  components: {
    LoginComponentInputMail,
    LoginComponentInputPassword,
    LoginComponentMessage,
    LoginComponentSubmitButton,
    LoginComponentNavigationButton,
    LoginComponentLayout
  }
})
export default class LoginCardEnterPassword extends Mixins(LoginMixin, LoginErrorMixin) {
  get isAppWithCustomerRegistration() {
    return ConfigModule.appContext === AppContextEnum.THG || ConfigModule.appContext === AppContextEnum.REPORT;
  }

  goToSet() {
    this.$router.push({ path: "/login/set" });
  }

  goToConfirm() {
    this.$router.push({ path: "/login/confirmMail" });
  }

  customFailureCodes = new Map<string, IFailureCodeContent>([
    ["NEW_PASSWORD_REQUIRED", { onFail: this.goToSet }],
    ["UNDEFINED", { message: "components.login.LoginCardEnterPassword.failUndefined" }],
    [
      "Password attempts exceeded",
      {
        message: "components.login.LoginCardEnterPassword.failMaxRequestsReached",
        onFail: this.setFormInvalid
      }
    ],
    [
      "User is not confirmed.",
      {
        message: "components.login.LoginCardEnterPassword.failConfirmationChallenged",
        onFail: this.goToConfirm
      }
    ]
  ]);

  /**
   * Check if user is already logged in and redirect
   */
  async mounted() {
    await this.checkIfUserIsLoggedInAndRedirect();
    this.registerCustomFailureCodes(this.customFailureCodes);
  }

  /*
   * Logs user in, fetch userInfo
   */
  async submit() {
    const userAuthDto: UserAuthDto = {
      username: this.mail,
      password: this.password
    };
    try {
      this.isLoading = true;
      await UserModule.logIn(userAuthDto);
      this.isLogInSubmittedSuccessful = true;
      await this.checkIfUserIsLoggedInAndRedirect();
    } catch (error) {
      this.handleFail(error);
    } finally {
      this.isLoading = false;
    }
  }
}
