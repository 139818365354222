/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateProjectDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivProjectControllerGetParamsGen,
  MrfiktivProjectViewModelGen,
  MrfiktivUpdateProjectDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Project<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags project
   * @name ProjectControllerCreate
   * @summary (Project - CREATE) Create a project
   * @request POST:/partner/{partnerId}/project
   * @secure
   * @response `201` `MrfiktivProjectViewModelGen` The created project
   */
  projectControllerCreate = (partnerId: string, data: MrfiktivCreateProjectDtoGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivProjectViewModelGen, any>({
      path: `/partner/${partnerId}/project`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags project
   * @name ProjectControllerGet
   * @summary (Project - READ) Get all projects
   * @request GET:/partner/{partnerId}/project
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivProjectViewModelGen)[] })`
   */
  projectControllerGet = ({ partnerId, ...query }: MrfiktivProjectControllerGetParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivProjectViewModelGen[] }, any>({
      path: `/partner/${partnerId}/project`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags project
   * @name ProjectControllerGetOne
   * @summary (Project - READ) Get a project
   * @request GET:/partner/{partnerId}/project/{projectId}
   * @secure
   * @response `200` `MrfiktivProjectViewModelGen` The project
   */
  projectControllerGetOne = (partnerId: string, projectId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivProjectViewModelGen, any>({
      path: `/partner/${partnerId}/project/${projectId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags project
   * @name ProjectControllerUpdate
   * @summary (Project - UPDATE) Updates a project
   * @request PATCH:/partner/{partnerId}/project/{projectId}
   * @secure
   * @response `201` `MrfiktivProjectViewModelGen` The updated project
   */
  projectControllerUpdate = (
    partnerId: string,
    projectId: string,
    data: MrfiktivUpdateProjectDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivProjectViewModelGen, any>({
      path: `/partner/${partnerId}/project/${projectId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags project
   * @name ProjectControllerDelete
   * @summary (Project - DELETE) Deletes a project
   * @request DELETE:/partner/{partnerId}/project/{projectId}
   * @secure
   * @response `200` `MrfiktivProjectViewModelGen` The deleted project
   */
  projectControllerDelete = (partnerId: string, projectId: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivProjectViewModelGen, any>({
      path: `/partner/${partnerId}/project/${projectId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
