var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "1200px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_vm.isIconButton ? _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": "",
            "disabled": _vm.disabled
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-email-outline")])], 1) : _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.disabled
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v("Kunden kontaktieren")])]), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.contactOpportunities,
      "item-text": "type",
      "item-value": "id",
      "label": "Template",
      "outlined": "",
      "dense": "",
      "return-object": "",
      "single-line": ""
    },
    on: {
      "change": _vm.changeTemplate
    },
    model: {
      value: _vm.contactType,
      callback: function callback($$v) {
        _vm.contactType = $$v;
      },
      expression: "contactType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Kunden Email Addresse",
      "outlined": "",
      "disabled": "",
      "dense": ""
    },
    model: {
      value: _vm.customerEmail,
      callback: function callback($$v) {
        _vm.customerEmail = $$v;
      },
      expression: "customerEmail"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Betreff",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.emailTitle,
      callback: function callback($$v) {
        _vm.emailTitle = $$v;
      },
      expression: "emailTitle"
    }
  }), _c('v-textarea', {
    attrs: {
      "type": "text",
      "label": "Emailbody",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.emailBody,
      callback: function callback($$v) {
        _vm.emailBody = $$v;
      },
      expression: "emailBody"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" " + _vm._s(_vm.abortButtonText) + " ")]), _c('v-btn', {
    on: {
      "click": _vm.contactCustomer
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }