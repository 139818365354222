/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { MyBookingService } from "@/services/booking/v1/MyBookingService";
import { BookingClientProvider } from "../booking-http-client.provider";

export class BookingMeService {
  /**
   * The proxy.
   */
  proxy: MyBookingService;

  /**
   * @class Initialize service
   */
  constructor(clientProvider: BookingClientProvider) {
    this.proxy = new MyBookingService(clientProvider.client());
  }

  findAll(cancelled: boolean) {
    return this.proxy.bookingMeControllerFindMyBookings(cancelled);
  }

  findOne(id: string) {
    return this.proxy.bookingMeControllerFindMyBooking(id);
  }
}

export default new BookingMeService(new BookingClientProvider());
