import { MrfiktivPermissionDtoGen as PermissionDto } from "@/services/mrfiktiv/v1/data-contracts";

const validPartnerTypes = ["form", "report"];

export function getPartnersFromUserPermissions(permissions: PermissionDto[]) {
  const res: string[] = [];
  permissions.forEach(per => {
    if (validPartnerTypes.includes(per.type)) {
      if (!res.includes(per.id)) {
        res.push(per.id);
      }
    }
  });
  return res;
}
