import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Ticket } from "../v1/Ticket";
import {
  MrfiktivCreateTicketDtoGen,
  MrfiktivEventControllerGetParamsGen,
  MrfiktivTicketMeControllerGetMyParamsGen,
  MrfiktivUpdateTicketDtoGen
} from "../v1/data-contracts";
import { TicketMe } from "../v1/TicketMe";

/**
 * Communicate with ticket API
 */
class TicketService {
  mrfiktivProxy: Ticket;
  mrfiktivMeProxy: TicketMe;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Ticket(mrfiktivHttpClientProvider.client());
    this.mrfiktivMeProxy = new TicketMe(mrfiktivHttpClientProvider.client());
  }

  /**
   * Create an ticket
   */
  async create(partnerId: string, data: MrfiktivCreateTicketDtoGen) {
    return (await this.mrfiktivProxy.ticketControllerCreate(partnerId, data)).data;
  }

  /**
   * Get all tickets
   */
  async getAll(data: MrfiktivEventControllerGetParamsGen) {
    return (await this.mrfiktivProxy.ticketControllerGet(data)).data;
  }

  /**
   * Get all tickets
   */
  async getMine(data: MrfiktivTicketMeControllerGetMyParamsGen) {
    return (await this.mrfiktivMeProxy.ticketMeControllerGetMy(data)).data;
  }

  /**
   * Get a ticket
   */
  async getOne(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.ticketControllerGetOne(partnerId, id)).data;
  }

  /**
   * Get a ticket
   */
  async getOneByNumber(partnerId: string, number: number) {
    return (await this.mrfiktivProxy.ticketControllerGetOneByNumber(partnerId, number)).data;
  }

  /**
   * Gets documents by report by partner.
   *
   * @param partnerId
   * @param vehicleId
   * @returns the documents of the partner
   */
  async getAllByVehicleForPartner(partnerId: string, vehicleId: string) {
    const query: MrfiktivEventControllerGetParamsGen = {
      partnerId: partnerId,
      filter: [
        {
          key: "source.refId",
          operation: "$eq",
          value: vehicleId
        },
        {
          key: "source.refType",
          operation: "$eq",
          value: "vehicle"
        }
      ]
    };
    return this.mrfiktivProxy.ticketControllerGet(query);
  }

  /**
   * Update a ticket
   */
  async update(partnerId: string, id: string, data: MrfiktivUpdateTicketDtoGen) {
    return (await this.mrfiktivProxy.ticketControllerUpdate(partnerId, id, data)).data;
  }

  /**
   * Delete a ticket
   */
  async delete(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.ticketControllerDelete(partnerId, id)).data;
  }

  /**
   * Archive a bunch of tickets
   */
  async archiveMany(partnerId: string, ids: string[]) {
    return (await this.mrfiktivProxy.ticketControllerArchiveMany(partnerId, { ids })).data;
  }

  /**
   * Remove a set of tickets
   * @param partnerId
   * @param ids
   * @returns
   */
  async deleteMany(partnerId: string, ids: string[]) {
    return (await this.mrfiktivProxy.ticketControllerDeleteMany(partnerId, { ids })).data;
  }
}

/**
 * Communicate with ticket API
 */
export default new TicketService(new MrfiktivHttpClientProvider());
