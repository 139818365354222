/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum PartnerTypeEnum {
  WORKSHOP = "workshop",
  FLEET = "fleet",
  INSURANCE = "insurance",
  CLAIM = "claim",
  OTHER = "other",
  TRAIN = "train",
  THG_WHITE_LABEL_MINT_FUTURE = "thg_white_label_mint_future",
  THG_WHITE_LABEL_QUOTE_AS_A_SERVICE = "thg_white_label_quote_as_a_service",
  THG_WHITE_LABEL_WITHOUT_SERVICE = "thg_white_label_without_service"
}
