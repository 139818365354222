import { TokenCategoriesEnum } from "../enum/templateEnums/tokenCategories.enum";

class TokenConfig {
  readonly settingCategory = TokenCategoriesEnum.SETTING;
  readonly customCategory = TokenCategoriesEnum.CUSTOM;
  readonly userCategories = [this.settingCategory, this.customCategory];
  readonly objectCategories = [
    TokenCategoriesEnum.URL,
    TokenCategoriesEnum.USER,
    TokenCategoriesEnum.REPORT,
    TokenCategoriesEnum.THG,
    TokenCategoriesEnum.PARTNER,
    TokenCategoriesEnum.BILLING,
    TokenCategoriesEnum.FORM,
    TokenCategoriesEnum.REQUEST,
    TokenCategoriesEnum.SIGN
  ];
  readonly predefinedCategories = [...this.objectCategories, ...this.userCategories];
}

export const TemplateTokenConfig = new TokenConfig();
