/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { Filter } from "@/lib/filterable";
import { MrfiktivPreExistingDamageDetailsGen } from "@/services/mrfiktiv/v1/data-contracts";

class PreExistingDamageDetailsBase implements MrfiktivPreExistingDamageDetailsGen {
  /**
   * @inheritdoc
   */
  description?: string | undefined;

  constructor(damageDetails?: Partial<MrfiktivPreExistingDamageDetailsGen>) {
    this.description = damageDetails?.description;
  }
}

type IPreExistingDamageDetails = PreExistingDamageDetailsBase;
const PreExistingDamageDetails = Filter.createForClass(PreExistingDamageDetailsBase);

export { IPreExistingDamageDetails, PreExistingDamageDetails };
