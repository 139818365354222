var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-2 mb-2"
  }, [_c('v-btn', {
    staticClass: "button",
    attrs: {
      "disabled": !_vm.isFormValid,
      "type": "submit",
      "loading": _vm.isLoading,
      "x-large": "",
      "block": "",
      "outlined": "",
      "data-test-login": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.login.".concat(_vm.component, ".submit"))) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }