






import { Component, Vue, Prop } from "vue-property-decorator";
import { BookingBaseImageGen } from "@/services/booking/v1/data-contracts";
import ImageDialog from "@/components/utility/ImageDialog.vue";
@Component({
  components: {
    ImageDialog
  }
})
export default class BookingAttachmentList extends Vue {
  @Prop()
  attachments!: BookingBaseImageGen[];

  get images() {
    return this.attachments.map(bookingImage => {
      return {
        src: bookingImage.url,
        thumbnail: bookingImage.url,
        w: 0,
        h: 0,
        title: this.$t(`timeLine.PartnerReportDetailImageTimeLineElement.imageTypes.${bookingImage.type}`)
      };
    });
  }
}
