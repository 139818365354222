/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivPageViewModelGen,
  MrfiktivTicketMeControllerGetMyParamsGen,
  MrfiktivTicketViewModelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class TicketMe<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ticket-me
   * @name TicketMeControllerGetMy
   * @summary Get all tickets assigned to a user
   * @request GET:/ticket/me
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivTicketViewModelGen)[] })`
   */
  ticketMeControllerGetMy = (query: MrfiktivTicketMeControllerGetMyParamsGen, params: RequestParams = {}) =>
    this.http.request<MrfiktivPageViewModelGen & { data?: MrfiktivTicketViewModelGen[] }, any>({
      path: `/ticket/me`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
