
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { PartnerModule } from "@/store/modules/partner";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue } from "vue-property-decorator";
import { PartnerTypeEnum } from "@/store/enum/partner/partner.type.enum";

@Component({})
export default class PermissionMixin extends Vue {
  get isAdmin() {
    return UserModule.isAdmin;
  }

  get ActionEnum() {
    return ActionEnum;
  }
  get ResourceEnum() {
    return ResourceEnum;
  }

  get permissionPartnerId() {
    return PartnerModule.partner && PartnerModule.partner.id ? PartnerModule.partner.id : "";
  }

  get canReadForm() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.FORM, this.permissionPartnerId);
  }

  get canReadReport() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.REPORT, this.permissionPartnerId);
  }

  get canReadMessage() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.MESSAGE, this.permissionPartnerId);
  }

  get canCreateMessage() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.MESSAGE, this.permissionPartnerId);
  }

  get canCreateImage() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.IMAGE, this.permissionPartnerId);
  }

  get canManageBooking() {
    return UserModule.abilities.can(ActionEnum.MANAGE, ResourceEnum.BOOKING, this.permissionPartnerId);
  }

  get readMessage() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.MESSAGE, this.permissionPartnerId);
  }

  get canUpdateReport() {
    return UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.REPORT, this.permissionPartnerId);
  }

  get canCreateSign() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.SIGN, this.permissionPartnerId);
  }

  get canCreateDocument() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.DOCUMENT, this.permissionPartnerId);
  }

  get canShareContent() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.SHARED_CONTENT, this.permissionPartnerId);
  }

  get canReadTemplate() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TEMPLATE, this.permissionPartnerId);
  }

  get canEditTemplate() {
    return UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.TEMPLATE, this.permissionPartnerId);
  }

  get canUpdateActivity() {
    return UserModule.abilities.can(ActionEnum.UPDATE, ResourceEnum.ACTIVITY, this.permissionPartnerId);
  }

  get deleteUpdateActivity() {
    return UserModule.abilities.can(ActionEnum.DELETE, ResourceEnum.ACTIVITY, this.permissionPartnerId);
  }

  get canReadVehicle() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.VEHICLE, this.permissionPartnerId);
  }

  get canCreateVehicle() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.VEHICLE, this.permissionPartnerId);
  }

  get canReadContract() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.CONTRACT, this.permissionPartnerId);
  }

  get canCreateContract() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.CONTRACT, this.permissionPartnerId);
  }

  get canReadEvent() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.EVENT, this.permissionPartnerId);
  }

  get canCreateEvent() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.EVENT, this.permissionPartnerId);
  }

  get canReadAnalytics() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS, this.permissionPartnerId);
  }

  get canReadTicket() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.PROJECT, this.permissionPartnerId);
  }

  get canCreateTicket() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.PROJECT, this.permissionPartnerId);
  }

  get canCreateCost() {
    return UserModule.abilities.can(ActionEnum.CREATE, ResourceEnum.COST, this.permissionPartnerId);
  }

  can(i: ActionEnum, a: string | ResourceEnum) {
    return UserModule.abilities.can(i, a, this.permissionPartnerId);
  }

  get isFleetPartner() {
    return [PartnerTypeEnum.FLEET, PartnerTypeEnum.TRAIN].includes(
      PartnerModule.partner.partnerType as PartnerTypeEnum
    );
  }
}
