import { AbstractHttpClientProvider, SignHttpClientProvider } from "../sign-http-client.provider";
import {
  SignCreateSignRequestDtoGen,
  SignSignRequestControllerFindAllParamsGen,
  SignUpdateSignRequestDtoGen,
  SignUpdateSignRequestStatusDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";
import { DigitalSignatureRequestService } from "./../v1/DigitalSignatureRequestService";

/**
 * Service to communicate with backend.
 */
export class SignRequestService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for communicating with backend.
   */
  proxy: DigitalSignatureRequestService;

  /**
   * @class Initialize Service
   */
  constructor(clientProvider: AbstractHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new DigitalSignatureRequestService(this.client);
  }

  /**
   * Sets a new proxy based on the client
   *
   * @param clientProvider the client provider
   */
  setProxy(clientProvider: AbstractHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new DigitalSignatureRequestService(this.client);
  }

  /**
   * Creates a sign request.
   *
   * @param data the sign request dto
   */
  async create(partnerId: string, data: SignCreateSignRequestDtoGen) {
    return this.proxy.signRequestControllerCreate(partnerId, data).then(value => value.data);
  }

  /**
   * Gets sign requests by partner.
   *
   * @returns the sign requests of the partner
   */
  async getAllForPartner(query: SignSignRequestControllerFindAllParamsGen) {
    return this.proxy.signRequestControllerFindAll(query).then(value => value.data);
  }

  /**
   * Gets one sign request of the partner.
   *
   * @param id the sign request id
   * @returns one sign request of the partner
   */
  async getOneForPartner(partnerId: string, id: string) {
    return this.proxy.signRequestControllerFindOne(partnerId, id).then(value => value.data);
  }

  /**
   * Removes a sign request
   *
   * @param partnerId of the partner
   * @param id the sign request id
   * @returns removed sign request
   */
  async removeForPartner(partnerId: string, id: string) {
    return this.proxy.signRequestControllerRemove(partnerId, id).then(value => value.data);
  }

  /**
   * Update Request status as partner
   *
   * @param partnerId
   * @param id
   * @param data
   * @returns
   */
  async updateState(partnerId: string, id: string, data: SignUpdateSignRequestStatusDtoGen) {
    return this.proxy.signRequestControllerUpdateStatus(partnerId, id, data).then(value => value.data);
  }

  /**
   * Updates a sign request
   *
   * @param partnerId of the partner
   * @param id of the sign request
   * @returns updated sign request of the partner
   */
  async updateForPartner(partnerId: string, id: string, dto: SignUpdateSignRequestDtoGen) {
    return this.proxy.signRequestControllerUpdate(partnerId, id, dto).then(value => value.data);
  }
}

/**
 * Service to communicate with backend.
 */
export default new SignRequestService(new SignHttpClientProvider());
