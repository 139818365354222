


















import { Component } from "vue-property-decorator";
import CustomFieldText from "./CustomFieldText.vue";

@Component({
  components: {}
})
export default class CustomFieldDateTime extends CustomFieldText {
  clear() {
    this.input = "";
  }
}
