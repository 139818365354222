import { IImageUploaded } from "@/models/Image/IImageUploaded";

/**
 * Checks wether a given file is an image.
 * @param file to be checked if its an image.
 * @returns if given file is an image.
 */
export function isImage(file: File): boolean {
  return file.type.indexOf("image/") >= 0;
}

/**
 * Returns a given file as an URL string.
 * @param file input file converted to a URL string
 * @returns file as URL string
 */
export function fileAsUrl(file: File): string {
  return URL.createObjectURL(file);
}

/**
 * Returns image.png as {start:"image", end:"png"}
 *
 * @param fileName
 * @returns
 */
function splitFileNameType(fileName: string) {
  if (fileName.endsWith(".jpg") || fileName.endsWith(".png") || fileName.endsWith(".pdf")) {
    return {
      start: fileName.substring(0, fileName.length - 4),
      end: fileName.substring(fileName.length - 4)
    };
  }

  return {
    start: fileName,
    end: ""
  };
}

/**
 * returns a unique name. if existing contains image.png and file is image.png, method returns image(1).png
 *
 * @param file
 * @param existing
 * @returns
 */
export function uniqueImageName(file: File, existing: IImageUploaded[]): string {
  const name = splitFileNameType(file.name);
  // pattern matches name, name(1), name(2),...
  const pattern = new RegExp(name.start + "((d))?");

  // count how many images there are with the same name ending in a digit
  let matches = 0;
  existing.forEach(o => {
    const existingFileName = splitFileNameType(o.file.name);
    if (existingFileName && pattern.test(existingFileName.start)) {
      matches++;
    }
  });

  const count = matches ? `(${matches})` : "";
  return `${name.start}${count}${name.end}`;
}
