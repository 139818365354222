import { permissionGuard } from "@/auth/permissionAuthGuard";
import AuthorizationOverview from "@/views/AuthorizationOverview.vue";
import NoRoles from "@/views/NoRoles.vue";
import Profile from "@/views/Profile.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Profile",
    component: Profile
  },
  {
    path: "/permissions",
    name: "Permissions",
    component: AuthorizationOverview
  },
  {
    path: "/no-roles",
    name: "NoRoles",
    component: NoRoles
  }
];

const router = new VueRouter({
  routes,
  base: "/"
});

router.beforeEach(permissionGuard);

export default router;
