
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class DarkModeHighlightMixin extends Vue {
  /** Get highlight color based on dark mode */
  get color() {
    return this.$vuetify.theme.dark ? "darken-4" : "lighten-4";
  }

  get colorVariantBack() {
    return this.$vuetify.theme.dark ? "darken-4" : "lighten-4";
  }

  get colorVariantTop() {
    return !this.$vuetify.theme.dark ? "darken-1" : "lighten-4";
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  /**
   * sets cards background color depending on dark mode
   */
  get backgroundColor() {
    return `background-color: ${this.$vuetify.theme.dark ? "#121212" : "#f5f5f5"}`;
  }
}
