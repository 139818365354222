var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-footer', {
    staticClass: "hidden-sm-and-up",
    attrs: {
      "inset": "",
      "fixed": "",
      "flat": "",
      "padless": ""
    }
  }, [_c('v-card', {
    staticClass: "text-center",
    attrs: {
      "flat": "",
      "tile": "",
      "width": "100%",
      "color": _vm.$vuetify.theme.dark ? 'grey darken-3' : 'white lighten-1 '
    }
  }, [_c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-around"
    }
  }, [_c('v-col', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-around"
    }
  }, [_vm.hasPrev ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "elevation": "0",
      "rounded": "",
      "color": _vm.$vuetify.theme.dark ? 'grey darken-3' : 'white'
    },
    on: {
      "click": _vm.emitPrev
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]) : _vm._e()], 1)], 1), _c('v-col', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-around"
    }
  }, [_vm.hasNext ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "rounded": "",
      "color": "success",
      "disabled": _vm.disableNext
    },
    on: {
      "click": _vm.emitNext
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('v-container', [_vm.hasPrev ? _c('v-card-actions', {
    staticClass: " progressContainer card-actions hidden-sm-and-down d-sm-inline"
  }, [_c('v-row', [_c('v-col', [_c('v-row', {
    attrs: {
      "justify": "start"
    }
  }, [_vm.hasPrev ? _c('v-btn', {
    attrs: {
      "id": "prev",
      "large": "",
      "rounded": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.emitPrev
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$t("back")) + " ")], 1) : _vm._e()], 1)], 1), _c('v-col', [_c('v-row', {
    attrs: {
      "justify": "end"
    }
  }, [_vm.hasNext ? _c('v-btn', {
    attrs: {
      "id": "next",
      "disabled": _vm.disableNext,
      "large": "",
      "rounded": "",
      "color": "green",
      "elevation": "0"
    },
    on: {
      "click": _vm.emitNext
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " "), _c('v-icon', [_vm._v("mdi-arrow-right")])], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }