















import BookingCancel from "@/components/booking/customer/BookingCancel.vue";
import LayoutReportWithBanner from "@/layouts/LayoutReportWithBanner.vue";
import { handleError } from "@/lib/utility/handleError";
import { AvailabilityModule } from "@/store/modules/availability.store";
import { Component, Vue } from "vue-property-decorator";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { UserModule } from "@/store/modules/me-user.store";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {
    LayoutReportWithBanner,
    BookingCancel
  }
})
export default class BookingCancelView extends Vue {
  bookingCanceled = false;

  get showLoginButton(): boolean {
    const isCustomerSignup = PartnerModule.partner?.settings?.bookedServices.customer ?? false;
    const isThg = ConfigModule.appContext === AppContextEnum.THG;
    return isThg ? !UserModule.isAuthenticated : !UserModule.isAuthenticated && isCustomerSignup;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get bookingId() {
    return this.$route.params["id"];
  }

  get partnerId() {
    return ReportModule.partner?._id || PartnerModule.partner?._id;
  }

  get service() {
    return AvailabilityModule.selectedService;
  }

  get createdBooking() {
    return AvailabilityModule.confirmedBooking;
  }

  get isBookingCancelled() {
    return this.bookingCanceled;
  }

  async mounted() {
    this.bookingCanceled = false;
    const booking = await AvailabilityModule.findOneBookingByPartner({ partnerId: this.partnerId, id: this.bookingId });

    await AvailabilityModule.findOneByPartner({
      partnerId: this.partnerId,
      id: booking.serviceId || "",
      date: new Date().toISOString()
    });
  }

  async cancel() {
    this.bookingCanceled = false;

    try {
      await AvailabilityModule.deleteOneByPartner({
        partnerId: this.partnerId,
        id: this.bookingId,
        data: {
          isDeleted: true,
          deletedAt: new Date().toISOString()
        }
      });

      this.bookingCanceled = true;
      this.$toast.success("👍");
    } catch (error) {
      this.bookingCanceled = false;
      handleError(error);
    }
  }
}
