




import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";

@Component({ components: { TheNavigationLayout } })
export default class TheThgUnauthenticatedList extends Vue {
  color = this.$store.state.config.color;

  get isAuthenticated() {
    return UserModule.isAuthenticated;
  }

  get isChargingStationEnabled(): boolean {
    if (!PartnerModule.partner.settings) {
      return false;
    }

    if (!PartnerModule.partner.settings.bookedServices["chargingStation"]) {
      return false;
    }

    return PartnerModule.partner.settings.bookedServices.chargingStation;
  }

  get items(): IMenuListItem[] {
    return [
      {
        text: "navigation.TheAboutList.home",
        link: "/",
        icon: "mdi-home-outline",
        isEnabled: true
      },
      {
        icon: "mdi-ev-station",
        text: "report.thgOverview.chargingStation.title",
        link: "/charging-station",
        isEnabled: this.isChargingStationEnabled
      },
      {
        text: "navigation.TheAboutList.login",
        link: "/login",
        icon: "mdi-login",
        isEnabled: true
      },
      {
        text: "navigation.TheAboutList.signUp",
        link: "/sign-up",
        icon: "mdi-account-plus-outline",
        isEnabled: true
      }
    ].filter(i => i.isEnabled);
  }
}
