/**
  They consist of an area code of one, two or three letters,            3
  a dash,                                                               1
  followed by an identifier sequence of one or two letters              2
  a space,                                                              1
  one to four digits.                                                   4
  one letter for electic or vintage.                                    1
                                                                       ---
  The total quantity of letters on the plate must not exceed eight.    12
  But we must asume there are special cases, so we add additonal 3  ¯\_(ツ)_/¯
 */

import { characterCountRule } from "./characterCountRule";

export function numberplateRule(): (value: any) => boolean | string {
  const numberplateCounter = 3 + 1 + 2 + 1 + 4 + 1 + 3;
  return characterCountRule(numberplateCounter);
}

/*
    They consist of an area code of one, two or three letters,             [a-zA-Z]{1,3}
    followed by an identifier sequence of one or two letters and            [a-zA-Z]{1,2}
    one to four digits.                                                     \d{1,4}
    The total quantity of characters on the plate must not exceed eight.
*/
export function numberplateRuleComplex(
  message = "Bitte ein valides Kennzeichen eingeben. Die Eingabe sollte aus dem Muster [AAZ-ZZ 999H] bestehen. Die Ortskennung beträgt 1-3 Zeichen, gefolgt von einem Bindestrich, einer Identifizierung von 1-2 Zeichen, gefolgt von einem Leerzeichen, und einer Zahlenfolge von 1-4 Zahlen. Zusätzlich ist die Eingabe eines 'H' bzw. eines 'E' für Vintage- bzw. Elektroautos erlaubt. Zum Beispiel: A-A 1, ZZZ-ZZ 1234H, A-ZZ 1E"
): (value: any) => boolean | string {
  const regularNumberPlateRegex = RegExp(/^[a-zA-Z]{1,3}-[a-zA-Z]{1,2} \d{1,4}[HE]{0,1}$/);
  return value => regularNumberPlateRegex.test(value) || message;
}
