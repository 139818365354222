import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import { ActivityLogService as MrfiktivActivityLogService } from "../mrfiktiv/v1/ActivityLogService";
import { ActivityLogService as ThgActivityLogService } from "../thg/v1/ActivityLogService";
import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { ConfigModule } from "@/store/modules/config";
import {
  MrfiktivActivityLogControllerFindAllParamsGen,
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivReferenceGen,
  MrfiktivUpdateActivityLogDtoGen
} from "../mrfiktiv/v1/data-contracts";
import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";
import { PaginationFilterOperationEnum } from "@/store/modules/base-pagination.store";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ThgReferenceGen } from "../thg/v1/data-contracts";

/**
 * Service to communicate with backend.
 */
export class SharedActivityLogService {
  /**
   * The proxy for communicating with mrfiktiv backend.
   */
  mrfiktivProxy: MrfiktivActivityLogService;

  /**
   * The proxy for communicating with thg backend.
   */
  thgProxy: ThgActivityLogService;

  /**
   * @class Initialize Service
   */
  constructor(mrfiktivClientProvider: MrfiktivHttpClientProvider, thgClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivActivityLogService(mrfiktivClientProvider.client());
    this.thgProxy = new ThgActivityLogService(thgClientProvider.client());
  }

  /**
   * Creates a document.
   *
   * @param data the document dto
   */
  async create(partnerId: string, data: MrfiktivCreateActivityLogDtoGen) {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.activityLogControllerCreate(partnerId, data).then(value => value.data);
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.activityLogControllerCreate(partnerId, data).then(value => value.data);
    }

    throw new Error("Not available");
  }

  /**
   * Gets documents by partner.
   *
   * @returns the documents of the partner
   */
  async getAllForPartner(query: MrfiktivActivityLogControllerFindAllParamsGen) {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.activityLogControllerFindAll(query).then(value => value.data);
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.activityLogControllerFindAll(query).then(value => value.data);
    }
    throw new Error("Not available");
  }

  /**
   * Gets one document of the partner.
   *
   * @param id the document id
   * @returns one document of the partner
   */
  async getOneForPartner(partnerId: string, id: string) {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.activityLogControllerFindOne(partnerId, id).then(value => value.data);
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.activityLogControllerFindOne(partnerId, id).then(value => value.data);
    }
    throw new Error("Not available");
  }

  /**c
   * Removes a document
   *
   * @param partnerId of the partner
   * @param id the document id
   * @returns removed document
   */
  async removeForPartner(partnerId: string, id: string) {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.activityLogControllerRemove(partnerId, id).then(value => value.data);
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.activityLogControllerRemove(partnerId, id).then(value => value.data);
    }
    throw new Error("Not available");
  }

  /**
   * Updates a document
   *
   * @param partnerId of the partner
   * @param id of the document
   * @returns updated document of the partner
   */
  async updateForPartner(partnerId: string, id: string, dto: MrfiktivUpdateActivityLogDtoGen) {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.activityLogControllerUpdate(partnerId, id, dto).then(value => value.data);
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.activityLogControllerUpdate(partnerId, id, dto).then(value => value.data);
    }
    throw new Error("Not available");
  }

  /**
   * Gets documents by report by partner.
   *
   * @param partnerId
   * @param reportId
   * @returns the documents of the partner
   */
  async getAllByReportForPartner(partnerId: string, reportId: string) {
    const query: MrfiktivActivityLogControllerFindAllParamsGen = {
      partnerId: partnerId,
      filter: [
        {
          key: "source.refId",
          operation: "$eq",
          value: reportId
        },
        {
          key: "source.refType",
          operation: "$eq",
          value: "report"
        }
      ]
    };
    return this.mrfiktivProxy.activityLogControllerFindAll(query).then(value => value.data);
  }

  /**
   * Gets documents for a source
   */
  async getAllBySource(partnerId: string, source: MrfiktivReferenceGen | ThgReferenceGen) {
    const query: MrfiktivActivityLogControllerFindAllParamsGen = {
      partnerId: partnerId,
      filter: [
        {
          key: "source.refId",
          operation: "$eq",
          value: source.refId
        },
        {
          key: "source.refType",
          operation: PaginationFilterOperationEnum.EQUAL,
          value: source.refType
        }
      ]
    };

    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return this.thgProxy.activityLogControllerFindAll(query).then(value => value.data);
    }
    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return this.mrfiktivProxy.activityLogControllerFindAll(query).then(value => value.data);
    }
    throw new Error("Not available");
  }

  /**
   * Gets documents for a vehicle by partner.
   *
   * @param partnerId
   * @param vehicleId
   * @returns the documents of the partner and vehicle
   */
  async getAllByReportForVehicle(partnerId: string, vehicleId: string) {
    const query: MrfiktivActivityLogControllerFindAllParamsGen = {
      partnerId: partnerId,
      filter: [
        {
          key: "source.refId",
          operation: "$eq",
          value: vehicleId
        },
        {
          key: "source.refType",
          operation: PaginationFilterOperationEnum.EQUAL,
          value: BackendResourceEnum.VEHICLE
        }
      ]
    };
    return this.mrfiktivProxy.activityLogControllerFindAll(query).then(value => value.data);
  }

  /**
   * Gets documents for a thg by partner.
   *
   * @param partnerId
   * @param thgId
   * @returns the documents of the partner and vehicle
   */
  async getAllByReportForThg(partnerId: string, thgId: string) {
    const query: MrfiktivActivityLogControllerFindAllParamsGen = {
      partnerId: partnerId,
      filter: [
        {
          key: "source.refId",
          operation: "$eq",
          value: thgId
        },
        {
          key: "source.refType",
          operation: PaginationFilterOperationEnum.EQUAL,
          value: BackendResourceEnum.THG
        }
      ]
    };
    return this.thgProxy.activityLogControllerFindAll(query).then(value => value.data);
  }
}

/**
 * Service to communicate with backend.
 */
export default new SharedActivityLogService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
