import thgService from "@/services/thg/services/thgService";
import {
  ThgCalculateEmissionSavingsDtoGen,
  ThgChargingStationProofDtoGen,
  ThgDecommissionThgDtoGen,
  ThgOperationIdViewModelGen,
  ThgThgControllerUpdateStatusAsyncParamsGen,
  ThgThgMeViewModelGen,
  ThgThgViewModelGen,
  ThgThgViewModelPopulatedGen,
  ThgUpdateThgRegistrationImagesDtoGen,
  ThgUpdateThgStatusOfThgIdsDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { UpdateMyRegistrationStoreDto } from "../interface/thg/update-my-registration-store-dto.interface";
import { ThgCreateModule } from "./thg.create.store";

@Module({
  dynamic: true,
  namespaced: true,
  name: "thg",
  store
})
export class ThgStore extends VuexModule {
  /**
   * Defines the amount of the bonus in euro
   */
  private _myThgs: ThgThgMeViewModelGen[] = [];

  private _myThg: ThgThgViewModelPopulatedGen | undefined = undefined;

  get myThg(): ThgThgViewModelGen | undefined {
    return this._myThg;
  }

  get myThgs(): ThgThgMeViewModelGen[] {
    return this._myThgs;
  }

  private _isLoading = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  private _isLoadingProofs = false;

  get isLoadingProofs(): boolean {
    return this._isLoadingProofs;
  }

  @Mutation
  _mutateMyThgs(myThgs: ThgThgMeViewModelGen[]) {
    this._myThgs = myThgs;
  }

  @Mutation
  _mutateMyThg(myThg: ThgThgViewModelPopulatedGen) {
    this._myThg = myThg;
  }

  @Mutation
  _mutateSelectedThg(thg: ThgThgMeViewModelGen) {
    const index = this._myThgs.findIndex((el: any) => {
      const elementId = el.id || (el as any)._id;

      return elementId === thg.id;
    });

    if (index >= 0) {
      this._myThgs.splice(index, 1, thg);
    }
  }

  @Mutation
  _mutateIsLoading(isLaoding: boolean) {
    this._isLoading = isLaoding;
  }

  @Mutation
  _mutateIsLoadingProofs(isLaoding: boolean) {
    this._isLoadingProofs = isLaoding;
  }

  @Action
  async getMyThgs() {
    const myThgs: ThgThgMeViewModelGen[] = await thgService.getMyThgs();
    myThgs.sort(function(a, b) {
      return a.timestamp.created > b.timestamp.created ? -1 : a.timestamp.created > b.timestamp.created ? 1 : 0;
    });
    this.context.commit("_mutateMyThgs", myThgs);
  }

  @Action
  async getMyThg(thgId: string) {
    const myThg: ThgThgViewModelGen = await thgService.getMyThg(thgId);

    this.context.commit("_mutateMyThg", myThg);
  }

  @Action
  async addRegistrationToMyThg(dto: UpdateMyRegistrationStoreDto) {
    try {
      this.context.commit("_mutateIsLoading", true);
      await thgService.updateMyRegistration(dto.thdId, dto.registrationDto);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    } finally {
      await this.getMyThgs();
      this.context.commit("_mutateIsLoading", false);
    }
  }

  @Action
  async updateMyRegistrationImages(thg: ThgThgViewModelGen): Promise<ThgThgViewModelGen> {
    const thgUpdateRegistrationImagesDto: ThgUpdateThgRegistrationImagesDtoGen = {
      registrationImages: []
    };

    for (const image of ThgCreateModule.registrationImageFront) {
      thgUpdateRegistrationImagesDto.registrationImages.push(image.uploadId);
    }

    for (const image of ThgCreateModule.registrationImageBack) {
      thgUpdateRegistrationImagesDto.registrationImages.push(image.uploadId);
    }

    const selectedThg: ThgThgViewModelGen = await thgService.updateMyRegistrationImages(
      thg.id,
      thgUpdateRegistrationImagesDto
    );

    ThgCreateModule.resetThg();

    return selectedThg;
  }

  @Action
  async calculateEmissionSavings(data: {
    thgId: string;
    dto: ThgCalculateEmissionSavingsDtoGen;
  }): Promise<ThgThgViewModelGen> {
    const selectedThg: ThgThgViewModelGen = await thgService.calculateMyEmissionSavings(data.thgId, data.dto);
    this.context.commit("_mutateSelectedThg", selectedThg);
    return selectedThg;
  }

  @Action
  async decomission(data: { thgId: string; dto: ThgDecommissionThgDtoGen }): Promise<ThgThgViewModelGen> {
    const selectedThg: ThgThgViewModelGen = await thgService.decommissionMy(data.thgId, data.dto);
    this.context.commit("_mutateSelectedThg", selectedThg);
    return selectedThg;
  }

  /**
   * creates async action to operate status of thgs
   *
   * @param data
   * @returns
   */
  @Action
  async updateStatus(data: {
    query: ThgThgControllerUpdateStatusAsyncParamsGen;
    data: ThgUpdateThgStatusOfThgIdsDtoGen;
  }): Promise<ThgOperationIdViewModelGen> {
    const operation = await thgService.updateStatuses(data.query, data.data);

    return operation;
  }

  @Action
  async uploadProof(dto: { data: ThgChargingStationProofDtoGen; thgId: string }) {
    const proof = await thgService.uploadProof(dto);
    this.context.commit("_mutateMyThg", proof);
    return proof;
  }

  @Action
  async setProofLoading(isLoading: boolean) {
    this.context.commit("_mutateIsLoadingProofs", isLoading);
  }

  @Action
  async clearMyThgs() {
    this.context.commit("_mutateMyThgs", []);
    this.context.commit("_mutateMyThg", undefined);
  }
}

export const ThgModule = getModule(ThgStore);
