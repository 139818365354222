


































import { Component, Prop, Vue } from "vue-property-decorator";

import LayoutReport from "@/layouts/LayoutReport.vue";
import ReportHeader from "@/components/report/ReportHeader.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import QueryParamsList from "@/components/settings/QueryParamsList.vue";

@Component({
  components: {
    LayoutReport,
    ReportHeader,
    QueryParamsList: QueryParamsList
  }
})
export default class LayoutReportStep extends Vue {
  @Prop({ default: 6 })
  md!: number;

  @Prop({})
  debug!: any;

  @Prop({ default: true })
  showFooter!: boolean;

  @Prop({ default: false })
  forceNextBtn!: boolean;

  @Prop({})
  description!: string;

  @Prop({})
  title!: string;

  @Prop({})
  image!: AssetEnum;

  @Prop({})
  hasInstructions!: boolean;

  @Prop({ default: "Anleitung" })
  instructionTitle!: string;

  @Prop({ default: "Hier ist ein Beispiel wie das Foto am besten aufzunehmen ist." })
  instructionText!: string;

  @Prop()
  instructionPictures!: AssetEnum[];

  @Prop({ default: true })
  isDone!: boolean;

  @Prop({ default: true })
  hasNext!: boolean;

  @Prop()
  imageHeight!: number;

  logoClick() {
    this.$emit("logoClick", true);
    this.$log.info("Logo clicked");
  }

  get imageFromAssetRepository() {
    if (this.image) {
      return AssetRepository.getAsset(this.$vuetify.theme.dark, this.image);
    }
    return null;
  }

  next() {
    this.$emit("next");
  }

  prev() {
    this.$emit("prev");
  }
}
