export enum AuthRolesEnum {
  ADMIN = "ADMINISTRATOR",
  USER = "USER",
  THG_USER = "THG_USER",
  PARTNER = "PARTNER",
  EDITOR = "EDITOR",
  APPRAISAL = "APPRAISAL",
  DOCPHANT_ADMIN = "DOCPHANT_ADMIN",
  ANALYTICS_ADMIN = "ANALYTICS_ADMIN"
}
