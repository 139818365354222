import { MrfiktivCustomFieldListElementViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import {
  CustomFieldListElementConfiguration,
  ICustomFieldListElementConfiguration
} from "./custom-field-list-element-configuration.entity";
import { CustomField, ICustomField } from "./custom-field.entity";

class CustomFieldListElementBase implements MrfiktivCustomFieldListElementViewModelGen {
  customField: ICustomField;
  configuration: ICustomFieldListElementConfiguration;

  constructor(customFieldListElement?: Partial<MrfiktivCustomFieldListElementViewModelGen>) {
    this.customField = new CustomField(customFieldListElement?.customField);
    this.configuration = new CustomFieldListElementConfiguration(customFieldListElement?.configuration);
  }
}

type ICustomFieldListElement = CustomFieldListElementBase;
const CustomFieldListElement = CustomFieldListElementBase;

export { ICustomFieldListElement, CustomFieldListElement };
