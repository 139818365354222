import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { AdminUser, IAdminUser } from "@/models/user.entity";
import { MrfiktivUserControllerFindAllPaginatedParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import AdminSharedUserService from "@/services/shared/adminSharedUserService";
import { ThgUserControllerFindAllPaginatedParamsGen, ThgUserViewmodelGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { AdminUserDataAccessLayer } from "./access-layers/admin-user.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ErrorLogModule } from "./error-log";

const AdminUserPageDataProvider = new (class extends AbstractPageDataProvider<
  IAdminUser,
  ThgUserControllerFindAllPaginatedParamsGen | MrfiktivUserControllerFindAllPaginatedParamsGen
> {
  itemsPerPage = 25;

  async getPage(
    query: ThgUserControllerFindAllPaginatedParamsGen | MrfiktivUserControllerFindAllPaginatedParamsGen
  ): Promise<IPageViewModel<IAdminUser>> {
    const res = (await AdminSharedUserService.getAllPaginated(query)) as IPageViewModel<IAdminUser>;

    const meta = res.meta;
    const data = res.data ?? [];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "adminUserPagination",
  store
})
export class AdminUserPaginationStore extends PaginatedBaseStore<
  IAdminUser,
  ThgUserControllerFindAllPaginatedParamsGen | MrfiktivUserControllerFindAllPaginatedParamsGen
> {
  protected _data = AdminUserDataAccessLayer;
  protected _pageProvider = AdminUserPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = AdminUser.filterables;

  @Action
  async getUsers(ids?: string[]) {
    try {
      if (ids) {
        // must not request too many ids
        const users: ThgUserViewmodelGen[] = [];
        while (ids.length > 0) {
          const partialIds = ids.splice(0, 50);
          users.push(...((await AdminSharedUserService.getAll({ ids: partialIds })) || []));
        }

        const userEntity = users.map(user => {
          const entity = new AdminUser(user);

          this._data.set(entity);
          return entity;
        });

        return userEntity;
      } else {
        await this.fetchAll({});
      }
    } catch (error) {
      Vue.$log.error(error);
      ErrorLogModule.addErrorLog({
        name: "User Error",
        message: "Could not load Users"
      });
    }
  }

  @Action
  async getSelectedUser(id: string) {
    const selectedUser = this.maps.id.get(id)[0] ?? (await new AdminUser({ id }).fetch());

    return selectedUser;
  }
}

export const AdminUserPaginationModule = getModule(AdminUserPaginationStore);
