import i18n from "../../plugins/I18nPlugin";

export function requiredRule(message = "report.formPersonalData.mandatoryInput"): (value: any) => boolean | string {
  return value => !!value || value === 0 || String(i18n.tc(message));
}

export function requiredRuleArray(
  message = "report.formPersonalData.mandatoryInput"
): (value: any) => boolean | string {
  return value => value?.length > 0 || String(i18n.tc(message));
}
