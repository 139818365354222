



















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { UserModule } from "@/store/modules/me-user.store";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class ErrorCard extends DarkModeHighlightMixin {
  EVENT_RELOAD = "reload";

  @Prop()
  imgSrc!: string;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  showLoginBtn!: boolean;

  @Prop()
  items!: [];

  reload() {
    this.$emit(this.EVENT_RELOAD, true);
  }

  isLoadingLogin = false;

  async logout() {
    try {
      this.isLoadingLogin = true;
      await this.performLogout();
      this.$log.info("Logged out succesfully");
    } catch {
      this.$log.info("Logged out not succesfully");
    } finally {
      this.isLoadingLogin = false;
      this.$router.push({ name: "Login" });
    }
  }

  async performLogout() {
    await UserModule.logout();
  }
}
