import { MyCustomerAccountService } from "../mrfiktiv/v1/MyCustomerAccountService";
import { MrfiktivHttpClientProvider } from "../mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivCustomerAccountMeControllerFindAllParamsGen,
  MrfiktivCreateCustomerAccountDtoGen,
  MrfiktivUpdateCustomerAccountDtoGen
} from "../mrfiktiv/v1/data-contracts";

export class CustomerAccountMeService {
  /**
   * The proxy.
   */
  proxy: MyCustomerAccountService;

  /**
   * Initialize service
   * @param clientProvider
   */
  constructor(clientProvider: MrfiktivHttpClientProvider) {
    this.proxy = new MyCustomerAccountService(clientProvider.client());
  }

  async findAll(query: MrfiktivCustomerAccountMeControllerFindAllParamsGen) {
    return (await this.proxy.customerAccountMeControllerFindAll(query)).data;
  }

  async findOne(id: string) {
    return (await this.proxy.customerAccountMeControllerFindOne(id)).data;
  }

  async create(data: MrfiktivCreateCustomerAccountDtoGen) {
    return (await this.proxy.customerAccountMeControllerCreate(data)).data;
  }

  async update(id: string, data: MrfiktivUpdateCustomerAccountDtoGen) {
    return (await this.proxy.customerAccountMeControllerUpdate(id, data)).data;
  }
}

export default new CustomerAccountMeService(new MrfiktivHttpClientProvider());
