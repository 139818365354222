













































import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PublicImageFolderEnum } from "@/store/enum/public-image/publicImageFolderEnum";
import { PublicImageModule } from "@/store/modules/public.image.store";
import { Component, Prop } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {}
})
export default class PublicImageUploadDialog extends DarkModeHighlightMixin {
  EMIT_ON_UPLOAD = "onUpload";
  valid = false;

  dialog = false;
  isLoading = false;

  @Prop({ default: false })
  hideButton?: boolean;

  @Prop({ default: true })
  hideFolderSelect?: boolean;

  @Prop({ default: PublicImageFolderEnum.THG })
  folder!: PublicImageFolderEnum;

  /**
   * Max File Size in Mb
   */
  @Prop({ default: 2 })
  maxFileSize!: number;

  file: File | undefined = {} as any;

  folderName: PublicImageFolderEnum = PublicImageFolderEnum.THG;

  imageFolderName = Object.keys(PublicImageFolderEnum).map(k => PublicImageFolderEnum[k as any]);

  @Prop({ default: "default" })
  prefix!: string;

  get imageInputTypes() {
    return ConfigModule.imageInputTypes;
  }

  get isDisabled() {
    return this.file !== undefined;
  }

  mounted() {
    this.folderName = this.folder || PublicImageFolderEnum.THG;
  }

  emitImage() {
    this.$emit(this.EMIT_ON_UPLOAD, PublicImageModule.publicImage);
  }

  async upload() {
    try {
      this.isLoading = true;
      if (this.file) {
        await PublicImageModule.upload({
          image: this.file,
          prefix: this.prefix,
          foldername: this.folderName
        });
      }
      this.emitImage();
    } catch (error) {
      this.$log.error(error);

      this.$toast.error(this.$t("components.publicImage.PublicImageUploadDialog.errorMessage"));
    } finally {
      this.isLoading = false;
      this.close();
    }
  }

  initialize() {
    this.dialog = true;
    this.file = undefined;
  }

  checkFileSize() {
    if (this.file) {
      if (this.file?.size > 1024 * 1000 * this.maxFileSize) {
        this.$toast.error(
          this.$t("components.publicImage.PublicImageUploadDialog.maxSizeMessage", { size: this.maxFileSize })
        );
      }
    }
  }

  get rules() {
    const rules = [];
    rules.push(requiredRule());
    return rules;
  }

  /**
   * closes the dialog
   */
  close() {
    this.file = undefined;
    this.dialog = false;
  }
}
