import { render, staticRenderFns } from "./BookingServices.vue?vue&type=template&id=1c1806fe&scoped=true&"
import script from "./BookingServices.vue?vue&type=script&lang=ts&"
export * from "./BookingServices.vue?vue&type=script&lang=ts&"
import style0 from "./BookingServices.vue?vue&type=style&index=0&id=1c1806fe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c1806fe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VCardText,VCol,VImg,VRow,VSkeletonLoader,VVirtualScroll})
