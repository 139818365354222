















import { AssetEnum } from "@/lib/AssetRepository";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ErrorLogModule } from "@/store/modules/error-log";
import { Component, Prop, Vue } from "vue-property-decorator";
import FileUploadPreview from "../utility/FileUploadPreview.vue";

@Component({
  components: {
    FileUploadPreview
  }
})
export default class FileUploadMulti extends Vue implements IViewImageUploadable {
  @Prop({ default: null })
  public maxFiles!: number | null;

  @Prop({ default: 1 })
  public minFiles!: number;

  @Prop()
  public example!: AssetEnum;

  @Prop()
  public overlay!: AssetEnum;

  @Prop()
  public instruction!: IReportInstructionData;

  @Prop()
  public handleUploadCb!: (file: File) => Promise<void>;

  @Prop()
  public handleDeleteCb!: (file: File) => Promise<boolean>;

  @Prop()
  public filesCb!: IImageUploaded[];

  @Prop()
  public displayImages!: IImageUploaded[];

  @Prop()
  public text?: string;

  @Prop()
  public textAlt?: string;

  @Prop()
  public snackbarTitle?: string;

  get hasFile(): boolean {
    if (!this.displayImages) {
      return false;
    }
    return this.displayImages.length >= 1;
  }

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    // #FIXME: This check is done twice
    // if (this.maxFiles && this.filesCb.length >= this.maxFiles) {
    //   console.log("[FileUploadMulti] handleUpload delete excess files");
    //   const toDelete = this.filesCb[this.filesCb.length - 1].file;
    //   await this.handleDeleteCb(toDelete);
    // }

    try {
      await this.handleUploadCb(file);
    } catch (error) {
      this.$log.error(error);
      ErrorLogModule.addErrorLog(error);
      this.$toast.error(this.$t("mixins.ReportImageSubmissionMixin.defaultError"));
    }

    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    await this.handleDeleteCb(file);
    return true;
  }

  get files(): IImageUploaded[] {
    if (this.displayImages) {
      return this.displayImages.slice().reverse();
    } else {
      return this.displayImages;
    }
  }
}
