/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SignCreateSignActivityDtoGen,
  SignDigitalSignatureActivityViewModelGen,
  SignExceptionViewmodelGen,
  SignPageViewModelGen,
  SignSignActivityControllerFindAllParamsGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class DigitalSignatureActivityService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Digital Signature Activity Service
   * @name SignActivityControllerCreate
   * @summary Creates an activity event on signature request
   * @request POST:/sign-request/{signRequestId}/sign-activity
   * @response `200` `(SignDigitalSignatureActivityViewModelGen)[]` The activity
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signActivityControllerCreate = (
    signRequestId: string,
    data: SignCreateSignActivityDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<SignDigitalSignatureActivityViewModelGen[], SignExceptionViewmodelGen>({
      path: `/sign-request/${signRequestId}/sign-activity`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Digital Signature Activity Service
   * @name SignActivityControllerFindAll
   * @summary Get all activity events for a signature request
   * @request GET:/sign-request/{signRequestId}/sign-activity
   * @secure
   * @response `200` `(SignPageViewModelGen & { data?: (SignDigitalSignatureActivityViewModelGen)[] })`
   * @response `400` `SignExceptionViewmodelGen` Bad Request
   * @response `401` `SignExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `SignExceptionViewmodelGen` Forbidden.
   * @response `404` `SignExceptionViewmodelGen` NotFound.
   * @response `500` `SignExceptionViewmodelGen` Internal Server Error
   */
  signActivityControllerFindAll = (
    { signRequestId, ...query }: SignSignActivityControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      SignPageViewModelGen & { data?: SignDigitalSignatureActivityViewModelGen[] },
      SignExceptionViewmodelGen
    >({
      path: `/sign-request/${signRequestId}/sign-activity`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
