
















































import TableWrapper from "@/components/utility/TableWrapper.vue";
import LayoutReportWithBanner from "@/layouts/LayoutReportWithBanner.vue";
import { Component, Vue } from "vue-property-decorator";
import { BookingMeModule } from "@/store/modules/booking-me.store";
import { formatHoursAndMinutes, msToTime, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { BookingBookingUnauthenticatedViewModelGen } from "@/services/booking/v1/data-contracts";
import MyBookingDetailSideCard from "@/components/booking/customer/MyBookingDetailSideCard.vue";
import { ReportModule } from "@/store/modules/report.store";
import { AvailabilityModule } from "@/store/modules/availability.store";
import Notifications from "@/components/thg/Notifications.vue";

@Component({
  components: {
    LayoutReportWithBanner,
    Notifications,
    TableWrapper,
    MyBookingDetailSideCard
  },
  filters: {
    simpleDoubleDigitDate,
    formatHoursAndMinutes,
    msToTime
  }
})
export default class MyBookingView extends Vue {
  loading = false;
  selectedBookingId: string | null = null;

  get partnerId() {
    return ReportModule.partner.id;
  }

  get selectedBooking() {
    return BookingMeModule.map.get(this.selectedBookingId ?? "");
  }

  get items() {
    return BookingMeModule.all.filter(s => s.partnerId === this.partnerId);
  }

  get i18n() {
    return this.$t("views.report.my.MyBookingView");
  }

  get headers() {
    return [
      { text: this.$t("views.booking.BookingTable.startInSeconds"), align: "start", width: 230, value: "start" },
      { text: this.$t("views.booking.BookingTable.startTime"), align: "start", width: 230, value: "startTime" },
      { text: this.$t("views.booking.BookingTable.endTime"), align: "start", width: 230, value: "endTime" },
      { text: this.$t("views.booking.BookingTable.durationInSeconds"), align: "start", width: 230, value: "duration" },
      {
        text: this.i18n["created"],
        value: "timestamp.created"
      },
      { text: "", value: "controls", align: "right", sortable: false }
    ];
  }

  async mounted() {
    await this.load();
  }

  async load() {
    this.loading = true;
    try {
      BookingMeModule.getAll();
      AvailabilityModule.fetchAllFromBeginning({ partnerId: ReportModule.partner.id });
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async openBooking(item: BookingBookingUnauthenticatedViewModelGen) {
    this.loading = true;
    this.selectedBookingId = null;

    try {
      const promises: Promise<any>[] = [];

      promises.push(BookingMeModule.getOne({ id: item.id }));

      promises.push(AvailabilityModule.fetchAllFromBeginning({ partnerId: item.partnerId }));

      await Promise.all(promises);

      this.selectedBookingId = item.id;
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
