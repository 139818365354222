


















import { Component, Mixins } from "vue-property-decorator";
import LoginMixin from "@/mixins/LoginMixin.vue";
import LoginComponentInputMail from "@/components/login/LoginComponentInputMail.vue";
import LoginComponentMessage from "@/components/login/LoginComponentMessage.vue";
import LoginComponentSubmitButton from "@/components/login/LoginComponentSubmitButton.vue";
import LoginComponentNavigationButton from "@/components/login/LoginComponentNavigationButton.vue";
import LoginComponentLayout from "@/components/login/LoginComponentLayout.vue";
import { UserModule } from "@/store/modules/me-user.store";
import LoginErrorMixin from "@/mixins/LoginErrorMixin.vue";

@Component({
  components: {
    LoginComponentInputMail,
    LoginComponentMessage,
    LoginComponentNavigationButton,
    LoginComponentSubmitButton,
    LoginComponentLayout
  }
})
export default class LoginCardForgotPassword extends Mixins(LoginMixin, LoginErrorMixin) {
  /**
   * Request password reset mail
   */
  async submit() {
    try {
      this.isFormSubmitted = true;
      this.isLoading = true;
      await UserModule.forgotPassword(this.mail);
      await this.handleSuccess("/login/reset");
    } catch (error) {
      this.handleFail(error);
    } finally {
      this.isLoading = false;
    }
  }
}
