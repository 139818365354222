import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";

export enum CostRouteNames {
  COST_TABLE = "COST_TABLE",
  COST_DETAIL = "COST_DETAIL",
  COST_GROUP_DETAIL = "COST_GROUP_DETAIL",
  COST_GROUP_TABLE = "COST_GROUP_TABLE",
  COST_GROUP_CUSTOM_VIEW = "COST_GROUP_CUSTOM_VIEW"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type Cost = { costId: string };
type CostGroup = { costGroupId: string };
type View = { viewId: string };
type Query = { query?: Record<string, string> };

export class CostGoToHelper extends BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    costTable: (d: Partner & Query) => {
      return {
        name: CostRouteNames.COST_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    costDetail: (d: Partner & Cost & Query) => {
      return {
        name: CostRouteNames.COST_DETAIL,
        params: { partnerId: d.partnerId, costId: d.costId },
        query: d.query
      };
    },
    costGroupDetail: (d: Partner & CostGroup & Query) => {
      return {
        name: CostRouteNames.COST_GROUP_DETAIL,
        params: { partnerId: d.partnerId, costGroupId: d.costGroupId },
        query: d.query
      };
    },
    costGroupTable: (d: Partner & Query) => {
      return {
        name: CostRouteNames.COST_GROUP_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    costGroupCustomViewHome: (d: Partner & CostGroup & Query) => {
      return {
        name: CostRouteNames.COST_GROUP_CUSTOM_VIEW,
        params: { partnerId: d.partnerId, costGroupId: d.costGroupId },
        query: d.query
      };
    },
    costGroupCustomView: (d: Partner & CostGroup & View & Query) => {
      return {
        name: CostRouteNames.COST_GROUP_CUSTOM_VIEW,
        params: { partnerId: d.partnerId, costGroupId: d.costGroupId, viewId: d.viewId },
        query: d.query
      };
    }
  };

  goToCostTable(d: Tab & Partner) {
    this.go(CostGoToHelper.locations.costTable(d), d.newTab);
  }

  goToCostDetail(d: Tab & Partner & Cost) {
    this.go(CostGoToHelper.locations.costDetail(d), d.newTab);
  }

  goToCostGroupTable(d: Tab & Partner) {
    this.go(CostGoToHelper.locations.costGroupTable(d), d.newTab);
  }

  goToCostGroupDetail(d: Tab & Partner & CostGroup) {
    this.go(CostGoToHelper.locations.costGroupDetail(d), d.newTab);
  }

  goToCostGroupCustomView(d: Tab & Partner & CostGroup & View) {
    this.go(CostGoToHelper.locations.costGroupCustomView(d), d.newTab);
  }
}
