








import { IMenuListItem } from "@/lib/interfaces/menu-list-item.interface";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { Component, Vue } from "vue-property-decorator";
import TheNavigationLayout from "./TheNavigationLayout.vue";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";

@Component({ components: { TheNavigationLayout } })
export default class TheThgProcessingList extends Vue {
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get items(): IMenuListItem[] {
    return [
      {
        icon: "mdi-note-search-outline",
        text: "Workitems",
        link: "/work",
        isEnabled: true,
        badge: `${ThgAnalyticsModule.openWorkItemsCount}`
      }
    ];
  }
}
