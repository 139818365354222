import { Filter, IsFilterable } from "@/lib/filterable";
import {
  MrfiktivWitnessGen,
  MrfiktivContactDocumentGen,
  MrfiktivCreateInsuranceDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { Contact } from "./contact.entity";

@IsFilterable
class WitnessBase implements MrfiktivWitnessGen {
  /**
   * @inheritdoc
   */
  firstName: string;

  /**
   * @inheritdoc
   */
  lastName: string;

  /**
   * @inheritdoc
   */
  contact: MrfiktivContactDocumentGen;

  /**
   * @inheritdoc
   */
  role?: "witness" | "responsible" | "victim" | "unclear" | "alternative_driver" | undefined;

  /**
   * @inheritdoc
   */
  imageIds: string[];

  /**
   * @inheritdoc
   */
  isInjured?: boolean | undefined;

  /**
   * @inheritdoc
   */
  note?: string | undefined;

  /**
   * @inheritdoc
   */
  numberplate?: string | undefined;

  /**
   * @inheritdoc
   */
  insurances?: MrfiktivCreateInsuranceDtoGen[] | undefined;

  constructor(witness?: Partial<MrfiktivWitnessGen>) {
    this.firstName = witness?.firstName ?? "";
    this.lastName = witness?.lastName ?? "";
    this.contact = new Contact(witness?.contact);
    this.role = witness?.role;
    this.imageIds = witness?.imageIds ?? [];
    this.isInjured = witness?.isInjured;
    this.note = witness?.note;
    this.numberplate = witness?.numberplate;
    this.insurances = witness?.insurances;
  }
}

type IWitness = WitnessBase;
const Witness = Filter.createForClass(WitnessBase);

export { IWitness, Witness };
