













import { emailRule, extendetPhoneRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { MrfiktivUserViewmodelGen, MrfiktivUpdateUserDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ProfileForm from "./ProfileForm.vue";

@Component({
  components: { ProfileForm, Card }
})
export default class EditProfileDialog extends DarkModeHighlightMixin {
  @Prop()
  userToUpdate!: MrfiktivUserViewmodelGen;

  @Prop({ default: false })
  isLoading!: boolean;

  @Ref("profileForm")
  form!: ProfileForm;

  @Prop({ default: false })
  value!: boolean;

  get dialog() {
    return this.value;
  }

  set dialog(value: boolean) {
    this.$emit("input", value);
  }

  disabled = true;

  @Watch("value")
  initialize() {
    if (this.value) {
      this.form.initialize();
      this.disabled = true;
    }
  }

  save(user: MrfiktivUpdateUserDtoGen): MrfiktivUpdateUserDtoGen {
    this.$emit("updateUser", user);
    this.closeDialog();

    return user;
  }

  closeDialog() {
    this.dialog = false;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(extendetPhoneRule());

    return rules;
  }
}
