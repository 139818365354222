var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('template-card', {
    ref: "templateCard",
    attrs: {
      "partnerId": _vm.partnerId,
      "from": [_vm.partner],
      "context": _vm.context,
      "to": _vm.to
    },
    on: {
      "sent": function sent(v) {
        return _vm.$emit('sent', v);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }