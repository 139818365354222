export enum DigitalSignatureRequestStatusEnum {
  CREATED = "created",
  SENT = "sent",
  DELIVERED = "delivered",
  PROCESSED = "processed",
  ACCEPTED = "accepted",
  SIGNED = "signed",
  DECLINED = "declined",
  VOIDED = "voided",
  DELETED = "deleted"
}
