import { MrfiktivCustomFieldValueGen } from "@/services/mrfiktiv/v1/data-contracts";

class CustomFieldValueBase implements MrfiktivCustomFieldValueGen {
  id: string;
  value?: string | undefined;
  timezone?: string | undefined;

  constructor(config?: Partial<MrfiktivCustomFieldValueGen>) {
    this.id = config?.id ?? "";
    this.value = config?.value;
    this.timezone = config?.timezone;
  }
}

type ICustomFieldValue = CustomFieldValueBase;
const CustomFieldValue = CustomFieldValueBase;

export { ICustomFieldValue, CustomFieldValue };
