export class Utils {
  // This function retrieves a Form Data Object and a data Object and transforms the Object recursively to a Form Data Object.
  public static buildFormData(formData: FormData, data: any, parentKey?: any): void {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        if (data instanceof Array) {
          Utils.buildFormData(formData, data[key], parentKey ? `${parentKey}` : key);
        } else {
          Utils.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        }
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  }
}
