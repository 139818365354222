/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { NotificationType } from "@/store/enum/notificationType.enum";
import { NotificationModule } from "@/store/modules/notification.store";
import { UserModule } from "@/store/modules/me-user.store";
import Vue from "vue";
import VueI18n from "@/plugins/I18nPlugin";
import { CustomerAccountMeModule } from "@/store/modules/customer-account-me.store";
import { PartnerModule } from "@/store/modules/partner";

/**
 * Factory to create notifications
 */
export class NotificationFactory {
  static createTaxIdMissingNotification() {
    NotificationModule.addNotification({
      type: NotificationType.WARNING,
      actionText: "components.thg.ThgOverview.notifications.btnText",
      dismissText: "components.thg.ThgOverview.notifications.dismissText",
      message: "components.thg.ThgOverview.notifications.taxNumberMissing",
      isVisible: true,
      location: { name: "Profile" }
    });
  }

  static createCompanyNameMissingNotification() {
    NotificationModule.addNotification({
      type: NotificationType.WARNING,
      actionText: "components.thg.ThgOverview.notifications.btnText",
      dismissText: "components.thg.ThgOverview.notifications.dismissText",
      message: "components.thg.ThgOverview.notifications.companyNameMissing",
      isVisible: true,
      location: { name: "Profile" }
    });
  }

  static createPhoneMissingNotification() {
    NotificationModule.addNotification({
      type: NotificationType.ERROR,
      actionText: "components.thg.ThgOverview.notifications.btnText",
      message: "components.thg.ThgOverview.notifications.phoneMissing",
      isVisible: true,
      hasTextField: true,
      textFieldPlaceholder: "components.thg.ThgOverview.notifications.phonePlaceholder",
      textFieldBgColor: "white",
      textFieldType: "tel",
      action: async (phone: string) => {
        if (phone) {
          try {
            await UserModule.setPhone(phone);
          } catch (error) {
            Vue.$toast.error(VueI18n.t("components.thg.ThgOverview.notifications.userPhoneErrorMsg"));
          }
        }
      }
    });
  }

  static createAddressMissingNotification() {
    NotificationModule.addNotification({
      type: NotificationType.WARNING,
      actionText: "components.thg.ThgOverview.notifications.btnText",
      dismissText: "components.thg.ThgOverview.notifications.dismissText",
      message: "components.thg.ThgOverview.notifications.addressMissing",
      isVisible: true,
      location: { name: "Profile" }
    });
  }

  static createIbanMissingNotification() {
    NotificationModule.addNotification({
      type: NotificationType.WARNING,
      actionText: "components.thg.ThgOverview.notifications.btnText",
      dismissText: "components.thg.ThgOverview.notifications.dismissText",
      message: "components.thg.ThgOverview.notifications.bankingMissing",
      isVisible: true,
      location: { name: "Profile" }
    });
  }

  static createNewGhghQuotaNotification() {
    NotificationModule.addNotification({
      type: NotificationType.SUCCESS,
      actionText: "components.thg.ThgOverview.notifications.ghgQuotaBtnText",
      dismissText: "components.thg.ThgOverview.notifications.dismissText",
      message: "components.thg.ThgOverview.notifications.ghgQuota",
      isVisible: true,
      location: { name: "ThgVehicleOverview" },
      action: async () => {
        Vue.$log.info("User clicked on GHG Notification.");
      }
    });
  }

  static createMarketingOptinMissingNotification() {
    NotificationModule.addNotification({
      type: NotificationType.INFO,
      actionText: "components.thg.ThgOverview.notifications.updateMarketingOptIn",
      dismissText: "components.thg.ThgOverview.notifications.updateMarketingOptInDismiss",
      message: "components.thg.ThgOverview.notifications.isMarketingOptinMissing",
      isVisible: true,
      action: async () => {
        try {
          await UserModule.setIsMarketingOptin();
        } catch (error) {
          Vue.$toast.error(VueI18n.t("components.profile.EditProfileDialog.errorMsg"));
        }
      }
    });
  }

  static createCustomerAccountNotificationsMissingNotification() {
    NotificationModule.addNotification({
      type: NotificationType.SUCCESS,
      actionText: "report.home.notifications.customerAccount.enableNotifications",
      dismissText: "report.home.notifications.customerAccount.dismissText",
      message: VueI18n.t("report.home.notifications.customerAccount.notificationsMissing", {
        partnerName: PartnerModule.partner?.companyName
      }).toString(),
      isVisible: true,
      closeOnSuccess: true,
      action: async () => {
        try {
          await CustomerAccountMeModule.update({
            isMarketingOptIn: true
          });
          Vue.$toast.success(VueI18n.t("report.home.notifications.customerAccount.notificationsSuccessMsg"));
        } catch (error) {
          Vue.$toast.error(VueI18n.t("report.home.notifications.customerAccount.notificationsErrorMsg"));
        }
      }
    });
  }
}
