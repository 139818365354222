var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main', {
    attrs: {
      "id": "camera"
    }
  }, [_vm.snackbarTitleOrInstructionTitle ? _c('v-snackbar', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.snackbar = false;
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" " + _vm._s(_vm.$t("components.camera.CameraApp.snackbar.button")) + " ")])];
      }
    }], null, false, 1152905501),
    model: {
      value: _vm.snackbar,
      callback: function callback($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.snackbarTitleOrInstructionTitle)) + " ")]) : _vm._e(), _vm.isPortrait ? _c('v-alert', {
    staticClass: "mb-8",
    attrs: {
      "bottom": "",
      "type": "warning",
      "elevation": "3",
      "dense": "",
      "prominent": "",
      "text": "",
      "icon": "mdi-phone-rotate-landscape"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.camera.CameraApp.alert.landscape")) + " ")]) : _vm._e(), _c('canvas', {
    ref: "cameraSensor",
    attrs: {
      "id": "camera--sensor"
    }
  }), _c('v-container', {
    staticClass: "ma-0 pa-0"
  }, [_vm.displayOverlay ? _c('v-img', {
    staticClass: "overlay",
    attrs: {
      "contain": "",
      "src": _vm.overlaySrc
    }
  }) : _vm._e()], 1), _c('video', {
    ref: "cameraView",
    attrs: {
      "id": "camera--view",
      "autoplay": "",
      "playsinline": ""
    }
  }), _c('v-btn', {
    ref: "cameraTrigger",
    staticClass: "mx-2 actions",
    attrs: {
      "id": "camera--trigger",
      "fab": "",
      "dark": "",
      "large": "",
      "color": _vm.color,
      "data-test-camera-trigger": "",
      "elevation": "3"
    },
    on: {
      "click": _vm.grab
    }
  }, [_c('v-icon', [_vm._v(" mdi-image-filter-center-focus ")])], 1), _vm.instruction ? _c('v-bottom-sheet', {
    attrs: {
      "inset": "",
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "actions",
          attrs: {
            "dark": "",
            "icon": "",
            "id": "camera--settings",
            "data-test-instruction-toggle": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v(" mdi-help-box ")])], 1)];
      }
    }], null, false, 967227931),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('instruction-card', {
    attrs: {
      "instructionTitle": _vm.instruction.title,
      "instructionText": _vm.instruction.text,
      "instructionPictures": _vm.instruction.pictures
    },
    on: {
      "clicked": function clicked($event) {
        _vm.dialog = !_vm.dialog;
      }
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }