import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  MrfiktivCreateSettingDtoGen as CreateSettingDto,
  MrfiktivDaSetupDtoGen,
  MrfiktivSettingPartnerInternalViewModelGen,
  MrfiktivSettingPartnerInternalViewModelGen as SettingPartnerInternalViewModel,
  MrfiktivUpdateSettingDtoGen as UpdateSettingDto
} from "../../services/mrfiktiv/v1/data-contracts";
import { ErrorLogModule } from "./error-log";
import { PartnerModule } from "@/store/modules/partner";
import settingService from "@/services/mrfiktiv/services/settingService";
import { IKSRSetupInformation } from "@/lib/interfaces/setting/IKSRSetupInformation.interface";
import { ManageStepperEnum } from "../enum/partner/manage-stepper.enum";
import { default as ksrService } from "@/services/mrfiktiv/services/ksrService";
import daService from "@/services/mrfiktiv/services/daService";
import pdrService from "@/services/mrfiktiv/services/pdrService";
import { IPDRSetupInformation } from "@/lib/interfaces/setting/IPDRSetupInformation.interface";
import { ExportModule } from "./export.store";

@Module({
  dynamic: true,
  namespaced: true,
  name: "setting",
  store
})
class Setting extends VuexModule {
  /**
   * A specific partner setting
   */
  private _partnerSetting: SettingPartnerInternalViewModel = {} as SettingPartnerInternalViewModel;
  get partnerSetting() {
    return this._partnerSetting;
  }
  /**
   * All settings of partner
   */
  private _allPartnerSettings: SettingPartnerInternalViewModel[] = [];
  get allPartnerSettings() {
    return this._allPartnerSettings;
  }

  /**
   * true if partner setting is loaded
   */
  private _allPartnerSettingsLoaded = false;
  get allPartnerSettingsLoaded() {
    return this._allPartnerSettingsLoaded;
  }

  /**
   * true if partner setting is loaded
   */
  private _partnerSettingLoaded = false;
  get partnerSettingLoaded() {
    return this._partnerSettingLoaded;
  }

  /**
   * true if post setting returned "409 - Key is already taken."
   */
  private _duplicateSettingKey = false;
  get duplicateSettingKey() {
    return this._duplicateSettingKey;
  }

  /**
   * the setup verification results of verification step
   */
  private _setupResults = true;
  get setupResults(): boolean {
    return this._setupResults;
  }

  /**
   * the settings required for the setup of the Digitales Autohaus
   */
  private _daSetupInformation: MrfiktivDaSetupDtoGen = {} as MrfiktivDaSetupDtoGen;
  get daSetupInformation(): MrfiktivDaSetupDtoGen {
    return this._daSetupInformation;
  }

  /**
   * the settings required for the setup of the PDR Cloud
   */
  private _pdrSetupInformation: IPDRSetupInformation = {} as IPDRSetupInformation;
  get pdrSetupInformation(): IPDRSetupInformation {
    return this._pdrSetupInformation;
  }

  /**
   * the settings required for the setup of KSR
   */
  private _ksrSetupInformation: IKSRSetupInformation = {} as IKSRSetupInformation;
  get ksrSetupInformation(): IKSRSetupInformation {
    return this._ksrSetupInformation;
  }

  @Mutation
  mutateKsrSetupInformation(ksrSetupInformation: IKSRSetupInformation) {
    this._ksrSetupInformation = ksrSetupInformation;
  }

  @Mutation
  mutateDaSetupInformation(daSetupInformation: MrfiktivDaSetupDtoGen) {
    this._daSetupInformation = daSetupInformation;
  }

  @Mutation
  mutatePdrSetupInformation(pdrSetupInformation: IPDRSetupInformation) {
    this._pdrSetupInformation = pdrSetupInformation;
  }

  @Mutation
  mutateSetupResults(setupResults: boolean) {
    this._setupResults = setupResults;
  }

  @Mutation
  mutateAllPartnerSettingsLoaded(loaded: boolean) {
    this._allPartnerSettingsLoaded = loaded;
  }

  @Mutation
  mutatePartnerSettingLoaded(loaded: boolean) {
    this._partnerSettingLoaded = loaded;
  }

  @Mutation
  mutatePartnerSetting(partnerSetting: SettingPartnerInternalViewModel) {
    this._partnerSetting = partnerSetting;
  }

  @Mutation
  mutateAllPartnerSettings(partnerSettings: SettingPartnerInternalViewModel[]) {
    this._allPartnerSettings = partnerSettings;
  }

  @Mutation
  mutateDuplicateSettingKey(duplicateSettingKey: boolean) {
    this._duplicateSettingKey = duplicateSettingKey;
  }

  @Action
  setKsrSetupInformation(ksrSetupInformation: IKSRSetupInformation) {
    this.context.commit("mutateKsrSetupInformation", ksrSetupInformation);
  }

  @Action
  resetSetupInformation(context: ManageStepperEnum) {
    this.context.commit(`mutate${context.charAt(0).toUpperCase() + context.slice(1)}SetupInformation`, {} as any);
  }

  @Action
  setDaSetupInformation(daSetupInformation: MrfiktivDaSetupDtoGen) {
    this.context.commit("mutateDaSetupInformation", daSetupInformation);
  }

  @Action
  setPdrSetupInformation(pdrSetupInformation: IPDRSetupInformation) {
    this.context.commit("mutatePdrSetupInformation", pdrSetupInformation);
  }

  @Action
  setSetupResults(setupResults: boolean) {
    this.context.commit("mutateSetupResults", setupResults);
  }

  @Action
  setAllPartnerSettingsLoaded(loaded: boolean) {
    this.context.commit("mutateAllPartnerSettingsLoaded", loaded);
  }

  @Action
  setPartnerSettingLoaded(loaded: boolean) {
    this.context.commit("mutatePartnerSettingLoaded", loaded);
  }

  @Action
  setPartnerSetting(partnerSetting: SettingPartnerInternalViewModel) {
    this.context.commit("mutatePartnerSetting", partnerSetting);
    this.setPartnerSettingLoaded(true);
  }

  @Action
  clearPartnerSetting() {
    this.context.commit("mutatePartnerSetting", {
      id: "",
      partnerId: "",
      key: "",
      isEncrypted: false,
      timestamp: { created: "", lastModified: "", modified: [] },
      isActive: false
    });
  }

  @Action
  setAllPartnerSettings(allPartnerSettings: SettingPartnerInternalViewModel[]) {
    this.context.commit("mutateAllPartnerSettings", allPartnerSettings);
  }

  @Action
  setDuplicateSettingKey(duplicateSettingKey: boolean) {
    this.context.commit("mutateDuplicateSettingKey", duplicateSettingKey);
  }

  @Action
  findSettingsInPartnerSettings(key: string): string | undefined {
    const setting = SettingModule.allPartnerSettings.filter(item => item.key == key);
    if (!setting.length) {
      return undefined;
    }
    return setting[0].value;
  }

  /**
   * Creates and verifies Settings from setup process
   *
   * if successful sets setup results to success
   * else to false
   */
  @Action
  async setup(context: ManageStepperEnum) {
    try {
      let isSetupSuccessful = false;
      if (context === ManageStepperEnum.KSR) {
        isSetupSuccessful = (await ksrService.setup(PartnerModule.partner._id, this.ksrSetupInformation)).isSetup;
        ExportModule.setIsKsrSetup(isSetupSuccessful);
      }
      if (context === ManageStepperEnum.DA) {
        isSetupSuccessful = (await daService.setup(PartnerModule.partner._id, this.daSetupInformation)).isAuthenticated;
      }

      if (context === ManageStepperEnum.DA2) {
        isSetupSuccessful = (await daService.setup(PartnerModule.partner._id, this.daSetupInformation)).isSetup;
        ExportModule.setIsDaSetup(isSetupSuccessful);
      }

      if (context === ManageStepperEnum.PDR) {
        isSetupSuccessful = (await pdrService.setup(PartnerModule.partner._id, this.pdrSetupInformation)).isSetup;
        ExportModule.setIsPdrSetup(isSetupSuccessful);
      }

      this.setSetupResults(isSetupSuccessful);
    } catch (error) {
      Vue.$log.error(error);
      this.setSetupResults(false);
    }
  }

  @Action
  async getPartnerSetting(partnerAndSettingId: any) {
    this.setPartnerSettingLoaded(false);
    const setting = await settingService.getOneSettingByPartnerAndId(
      partnerAndSettingId.partnerId,
      partnerAndSettingId.settingId
    );
    this.setPartnerSetting(setting);
    this.setPartnerSettingLoaded(true);
  }

  /**
   * Gets list of all settings associated with a partner
   * sets the allPartnerSettingsLoaded flag to true when loading is completed
   *
   * @param partnerId the id of partner to get settings for
   */
  @Action
  async getSettingsByPartnerId(partnerId: string) {
    try {
      this.setAllPartnerSettingsLoaded(false);
      const settings = await settingService.getAllSettingsByPartner(partnerId);

      settings.sort((a, b) => {
        return a.timestamp.created > b.timestamp.created ? -1 : 1;
      });

      this.setAllPartnerSettings(settings);
    } catch (error) {
      Vue.$log.error(error);
    } finally {
      this.setAllPartnerSettingsLoaded(true);
    }
  }

  /**
   * Gets setting of current partner by key
   *
   * @param partnerId the id of partner to get settings for
   */
  @Action
  async getSettingByKey(data: {
    key: string;
    partnerId?: string;
  }): Promise<MrfiktivSettingPartnerInternalViewModelGen | undefined> {
    let setting: MrfiktivSettingPartnerInternalViewModelGen | undefined = undefined;
    try {
      this.setPartnerSettingLoaded(false);
      setting = await settingService.getSettingByKey(
        data.partnerId ? data.partnerId : PartnerModule.partner._id,
        data.key
      );
      this.setPartnerSetting(setting);
    } catch (error) {
      Vue.$log.error(error);
    } finally {
      this.setPartnerSettingLoaded(true);
    }
    return setting;
  }

  /**
   * Gets setting of current partner by key
   *
   * @param partnerId the id of partner to get settings for
   */
  @Action
  async getPublicSettingByKey(data: {
    key: string;
    partnerId: string;
  }): Promise<MrfiktivSettingPartnerInternalViewModelGen | undefined> {
    let setting: MrfiktivSettingPartnerInternalViewModelGen | undefined = undefined;
    try {
      this.setPartnerSettingLoaded(false);
      setting = await settingService.getPublicSettingByKey(data.partnerId, data.key);
      this.setPartnerSetting(setting);
    } catch (error) {
      Vue.$log.error(error);
    } finally {
      this.setPartnerSettingLoaded(true);
    }
    return setting;
  }

  /**
   * Gets list of all settings associated with a partner
   * sets the allPartnerSettingsLoaded flag to true when loading is completed
   *
   * @param partnerId the id of partner to get settings for
   */
  @Action
  async getPublicSettingsByPartnerId(partnerId: string) {
    try {
      this.setAllPartnerSettingsLoaded(false);
      const settings = await settingService.getAllPublicSettingsByPartner(partnerId);

      this.setAllPartnerSettings(settings);
    } catch (error) {
      Vue.$log.error(error);
    } finally {
      this.setAllPartnerSettingsLoaded(true);
    }
  }

  /**
   * Gets setting of current partner by key
   *
   * @param partnerId the id of partner to get settings for
   */
  @Action
  async getAdminSettingByKey(key: string) {
    const setting = await settingService.getAdminSettingByKey(key);

    return setting;
  }

  /**
   * Gets list of all settings associated with currently loaded partner
   * sets the allPartnerSettingsLoaded flag to true when loading is completed
   */
  @Action
  async getSettings() {
    await this.getSettingsByPartnerId(PartnerModule.partner._id);
  }

  /**
   * Deletes a setting for current partner
   * sets partnerSettingLoaded to true once request is handled
   * sets partnerSetting to empty object if delete is successfull
   *
   * @param settingId the id of the setting to delete
   */
  @Action
  async deleteSetting(settingId: string) {
    try {
      this.setPartnerSettingLoaded(false);
      await settingService.deleteSettingByPartnerIdAndSettingsID(PartnerModule.partner._id, settingId);
      this.setPartnerSetting({} as SettingPartnerInternalViewModel);
    } catch (error) {
      Vue.$log.error(error);
    } finally {
      this.setPartnerSettingLoaded(true);
    }
  }

  /**
   * creates a new setting for current partner
   *
   * @param createSettingDto the new setting
   * @returns the created setting
   */
  @Action
  async createSetting(createSettingDto: CreateSettingDto) {
    const setting = await settingService.createSetting(createSettingDto, PartnerModule.partner._id);
    this.context.commit("mutatePartnerSetting", setting);
  }

  /**
   * Creates a new setting for the current partner
   * sets duplicateSettingKey to true in case setting is already taken
   * sets partnerSettingLoaded to true when request is finished
   * sets partnerSetting to the created setting
   *
   * @param createSettingDto the setting to create
   */
  @Action
  async handleCreateSetting(createSettingDto: CreateSettingDto) {
    try {
      this.setPartnerSettingLoaded(false);
      this.setDuplicateSettingKey(false);

      const setting = await settingService.createSetting(createSettingDto, PartnerModule.partner._id);
      this.setPartnerSetting(setting);
    } catch (error) {
      if ((error as any).response.data.statusCode === 409) {
        this.setDuplicateSettingKey(true);
        ErrorLogModule.addErrorLog(error as Error);
      }
      Vue.$log.error(error);
    } finally {
      this.setPartnerSettingLoaded(true);
    }
  }

  /**
   * Updates a new setting for the current partner
   *
   * @param updateSettingDto the setting to update
   */
  @Action
  async updateSetting(updateSettingDto: UpdateSettingDto) {
    const setting = await settingService.updateSetting(
      updateSettingDto,
      PartnerModule.partner._id,
      this.partnerSetting.id
    );
    this.setPartnerSetting(setting);
  }

  /**
   * Updates a new setting for the current partner
   * sets partnerSettingLoaded to true when request is finished
   * sets partnerSetting to the updated setting
   *
   * @param updateSettingDto the setting to update
   */
  @Action
  async handleUpdateSetting(updateSettingDto: UpdateSettingDto) {
    try {
      this.setPartnerSettingLoaded(false);
      const setting = await settingService.updateSetting(
        updateSettingDto,
        PartnerModule.partner._id,
        this.partnerSetting.id
      );
      this.setPartnerSetting(setting);
    } catch (error) {
      Vue.$log.error(error);
    } finally {
      this.setPartnerSettingLoaded(true);
    }
  }

  /**
   * updates a setting and gets all settings for current partner
   */
  @Action
  async updateSettingAndRefreshSettings(updateSettingDto: UpdateSettingDto) {
    this.setPartnerSettingLoaded(false);
    this.setAllPartnerSettingsLoaded(false);

    await this.handleUpdateSetting(updateSettingDto);
    await this.getSettings();

    this.setPartnerSettingLoaded(true);
    this.setAllPartnerSettingsLoaded(true);
  }
}

export const SettingModule = getModule(Setting);
