var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    attrs: {
      "label": _vm.label,
      "type": "mail",
      "rules": _vm.emailRules,
      "prepend-inner-icon": "mdi-account-circle",
      "id": "mail",
      "outlined": ""
    },
    on: {
      "change": _vm.removeWhiteSpace
    },
    model: {
      value: _vm.mail,
      callback: function callback($$v) {
        _vm.mail = $$v;
      },
      expression: "mail"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }