















































































import { Component, Vue } from "vue-property-decorator";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: { LayoutSimple }
})
export default class Revocation extends Vue {
  get partner(): PartnerEntity {
    return ReportModule?.partner;
  }
}
