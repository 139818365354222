






















import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class BookingNavigation extends Vue {
  @Prop({ default: false })
  isMobile!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: "bookingForm.common.prev" })
  prevBtnText!: string;

  @Prop({ default: "bookingForm.common.next" })
  nextBtnText!: string;

  @Prop({ default: false })
  showNext!: boolean;

  @Prop({ default: "pa-4 mx-auto no-top-round-corners mt-n8" })
  cardClass!: string;

  back() {
    this.$emit("back", true);
  }

  forward() {
    this.$emit("forward", true);
  }

  get color() {
    return getDefaultPartnerColor();
  }
}
