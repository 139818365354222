








import { Component, Vue } from "vue-property-decorator";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";

@Component({
  components: {}
})
export default class TheNavigationDrawerHeader extends Vue {
  get logo() {
    return AssetRepository.getAsset(false, AssetEnum.damageReportLogo);
  }
}
