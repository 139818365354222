var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isMobile ? _c('v-row', {
    attrs: {
      "align": "stretch"
    }
  }, [_c('v-col', {
    staticClass: "d-flex justify-center align-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-avatar', {
    staticClass: "mt-4",
    attrs: {
      "color": _vm.color,
      "size": "80"
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": "",
      "x-large": ""
    }
  }, [_vm._v(" mdi-calendar-check-outline ")])], 1)], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', {
    class: ['headline text-h4 font-weight-bold text-center', _vm.dark ? 'white--text' : '']
  }, [_vm._v(" " + _vm._s(_vm.$t("bookingForm.BookingStart.introText")) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.isMobile ? _c('v-list-item-subtitle', {
    class: ['text-center', 'text-h6', _vm.dark ? 'white--text' : '']
  }, [_vm._v(_vm._s(_vm.$t("bookingForm.BookingStart.introSubText")))]) : _vm._e()], 1)], 1), _c('v-row', {
    staticClass: "px-4  d-flex flex-column ",
    style: _vm.isMobile ? 'min-height:290px' : '',
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.isMobile || _vm.bookingIframe ? _c('v-list', {
    attrs: {
      "dark": _vm.dark
    }
  }, _vm._l(_vm.items, function (item) {
    return _c('v-list-item', {
      key: item.title
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "dark": _vm.dark
      }
    }, [_vm._v(" " + _vm._s(item.icon) + " ")])], 1), _c('v-list-item-content', {
      staticClass: "text-left"
    }, [_c('v-list-item-title', {}, [_vm._v(_vm._s(item.title))]), _c('v-list-item-subtitle', {}, [_vm._v(_vm._s(item.subtitle))])], 1)], 1);
  }), 1) : _vm._e()], 1), _c('v-row', {
    staticStyle: {
      "min-height": "60px"
    }
  }, [_c('v-col')], 1), _c('div', {
    staticClass: "px-4"
  }, [_vm.isMobile ? _c('v-btn', {
    staticClass: "mb-4",
    attrs: {
      "block": "",
      "color": _vm.color,
      "dark": "",
      "elevation": "0"
    },
    on: {
      "click": _vm.start
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("bookingForm.BookingStart.bookButton")) + " ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }